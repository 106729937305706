var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-input-group", { staticClass: "mb-4", attrs: { label: "Notes" } }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localFormData.notes,
              expression: "localFormData.notes",
            },
          ],
          staticClass: "input-field w-full h-32",
          attrs: { placeholder: "Enter your notes here", rows: "5" },
          domProps: { value: _vm.localFormData.notes },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.localFormData, "notes", $event.target.value)
              },
              function ($event) {
                return _vm.updateLocalFormData()
              },
            ],
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4 flex justify-end space-x-2" },
        [
          _c(
            "sw-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submitNotes.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      Save Notes\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }