var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitMetho.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    to: "/admin/dashboard",
                    title: _vm.$t("general.home"),
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    to: "/admin/corePBX/reports/departaments/",
                    title: _vm.$t("tickets.menu_title.departaments"),
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "corepbx.DepartamentsPbxEdit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "#",
                        title: _vm.$t("tickets.departaments.edit_departament"),
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "#",
                        title: _vm.$t("tickets.departaments.new_departament"),
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "flex justify-center md:w-auto",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("tickets.departaments.update_departament")
                            : _vm.$t("tickets.departaments.save_departament")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      variant: "primary-outline",
                      type: "button",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          \n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "grid grid-cols-12" }, [
          _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c(
                "sw-card",
                { staticClass: "mb-8" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("tickets.departaments.name"),
                        error: _vm.nameError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                          name: "name",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("tickets.departaments.description"),
                      },
                    },
                    [
                      _c("sw-editor", {
                        attrs: {
                          "set-editor": _vm.formData.description,
                          rows: "2",
                          name: "description",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.description.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("pbx_services.tenant"),
                        error: _vm.pbx_tenant_idError,
                      },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.pbxTenantsOptions,
                          searchable: true,
                          invalid: _vm.$v.formData.pbx_tenant_id.$error,
                          "show-labels": false,
                          "allow-empty": true,
                          "track-by": "id",
                          label: "name",
                          disabled: _vm.isEdit,
                        },
                        on: { select: _vm.tenantSelectedMethod },
                        model: {
                          value: _vm.formData.pbx_tenant_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pbx_tenant_id", $$v)
                          },
                          expression: "formData.pbx_tenant_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "my-8" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:w-1/2",
                          attrs: {
                            label: _vm.$t("corePbx.extensions.add_extensions"),
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.extensionOptionsComputed,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              placeholder: _vm.$tc(
                                "corePbx.extensions.add_extensions"
                              ),
                              label: "name",
                              "track-by": "id",
                              tabindex: 9,
                            },
                            on: { select: _vm.extensionSelectedMethod },
                            model: {
                              value: _vm.extensionSelected,
                              callback: function ($$v) {
                                _vm.extensionSelected = $$v
                              },
                              expression: "extensionSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "ext_table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.formData.extensionAdded,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("pbx_services.name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.name) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("pbx_services.number"),
                          show: "ext",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("pbx_services.location"),
                          show: "location",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("pbx_services.status"),
                          show: "status",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c("x-icon", {
                                  staticClass:
                                    "h-5 mr-3 text-gray-600 cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeExtension(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-6 mb-4" }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }