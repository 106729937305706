var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "relative" }, [
    _c(
      "form",
      { attrs: { action: "" } },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c(
              "sw-input-group",
              { attrs: { label: _vm.$t("expenses.docs") } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "hover:bg-gray-200 py-2 cursor-pointer md:w-1/3 mt-5 bg-transparent border-2 border-gray-400 border-dashed rounded-md",
                    attrs: { id: "docs-box " },
                  },
                  [
                    _c("label", { attrs: { for: "uploadDocs" } }, [
                      _c("input", {
                        staticClass: "form-control hidden uploadDocs",
                        attrs: { id: "uploadDocs", type: "file", multiple: "" },
                        on: { change: _vm.onChangeDocs },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex flex-col items-center" },
                        [
                          _c("cloud-upload-icon", {
                            staticClass:
                              "h-5 mb-2 text-xl leading-6 text-gray-400",
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-xs leading-4 text-center text-gray-400",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-primary-500",
                                  attrs: { id: "pick-avatar" },
                                },
                                [_vm._v("browse")]
                              ),
                              _vm._v(
                                "\n                to choose a file\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  _vm._l(_vm.formData.docs, function (file, indexTr) {
                    return _c(
                      "div",
                      {
                        key: indexTr,
                        staticClass: "px-1 py-2 w-full md:w-1/2 lg:w-1/3",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "bg-transparent border-2 border-gray-400 border-dashed rounded-md",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "p-3 flex flex-wrap" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mb-4",
                                    attrs: { label: "Name" },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        placeholder: file.file_name,
                                        type: "text",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.updateFile(file)
                                        },
                                      },
                                      model: {
                                        value: file.file_name,
                                        callback: function ($$v) {
                                          _vm.$set(file, "file_name", $$v)
                                        },
                                        expression: "file.file_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mb-4",
                                    attrs: { label: "Description" },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        placeholder: file.file_description,
                                        type: "text",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.updateFile(file)
                                        },
                                      },
                                      model: {
                                        value: file.file_description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            file,
                                            "file_description",
                                            $$v
                                          )
                                        },
                                        expression: "file.file_description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "mb-2 w-full" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("expenses.size")) +
                                      ": " +
                                      _vm._s(_vm.sizeFile(file.size)) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "mb-2 w-full" }, [
                                  _vm._v("Type: " + _vm._s(file.typeFile)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex w-full" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pointer",
                                      class: { downloadFilename: _vm.isEdit },
                                      on: {
                                        click: function ($event) {
                                          return _vm.OpenSeeDocumentExpensesModal(
                                            file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("eye-icon", {
                                        staticClass: "h-6 mr-3 text-gray-600",
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(file)
                                        },
                                      },
                                    },
                                    [
                                      _c("download-icon", {
                                        staticClass: "h-6 mr-3 text-gray-600",
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFile(file)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-6 mr-3 text-gray-600",
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("sw-input-group"),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "my-8" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }