var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showServices ||
      _vm.showPBX ||
      _vm.showInvoices ||
      _vm.showQuotes ||
      _vm.showPayments
        ? _c(
            "sw-card",
            { staticClass: "flex flex-col mt-8" },
            [
              _c(
                "sw-tabs",
                {
                  attrs: { "active-tab": _vm.activeMainTab },
                  on: { update: _vm.setMainTabFilter },
                },
                [
                  _vm.showServices
                    ? _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$t("customers.services"),
                          filter: "Services",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPBX
                    ? _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$t("customers.services_pbx"),
                          filter: "PBX Services",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showInvoices
                    ? _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$tc("invoices.invoice", 2),
                          filter: "Invoices",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showQuotes
                    ? _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$tc("estimates.estimate", 2),
                          filter: "Estimates",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPayments
                    ? _c("sw-tab-item", {
                        attrs: {
                          title: _vm.$tc("payments.payment", 2),
                          filter: "Payments",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeMainTab === "Services"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "text-grey-darkest" },
                        [
                          _c(
                            "sw-tabs",
                            {
                              attrs: { "active-tab": _vm.activeTab },
                              on: { update: _vm.setStatusFilter },
                            },
                            [
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.active"),
                                  filter: "A",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.pending"),
                                  filter: "P",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.suspend"),
                                  filter: "S",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.cancelled"),
                                  filter: "C",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activeMainTab === "Services"
                        ? _c(
                            "sw-table-component",
                            {
                              key: "Services",
                              ref: "table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchServicesData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("services.service_number"),
                                  show: "code",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "services.service_number"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: {
                                                to: `/customer/service/${row.customer_package_id}/view`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(row.code) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1026158258
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: "APPLICABLE SERVICE",
                                  show: "name",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.amount"),
                                  "sort-as": "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.amount"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.total,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4112549247
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.term"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.term"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.capitalizeFirstLetter(
                                                  row.term
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3884038541
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.activation_date"),
                                  "sort-as": "activation_date",
                                  show: "formattedActivationDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  "sort-as": "customer_packages.renewal_date",
                                  label: _vm.$t("customers.renewal_date"),
                                  show: "formattedRenewalDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown no-click",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.actions"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown-item",
                                                {
                                                  attrs: {
                                                    to: `/customer/service/${row.id}/view`,
                                                    "tag-name": "router-link",
                                                  },
                                                },
                                                [
                                                  _c("cog-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.$t("general.manage")
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2415020388
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeMainTab === "PBX Services"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "text-grey-darkest" },
                        [
                          _c(
                            "sw-tabs",
                            {
                              attrs: { "active-tab": _vm.activeServicesPbxTab },
                              on: { update: _vm.setServicesPbxStatusFilter },
                            },
                            [
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.active"),
                                  filter: "A",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.pending"),
                                  filter: "P",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.suspend"),
                                  filter: "S",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-tab-item", {
                                attrs: {
                                  title: _vm.$t("customers.cancelled"),
                                  filter: "C",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activeMainTab === "PBX Services"
                        ? _c(
                            "sw-table-component",
                            {
                              key: "PBX Services",
                              ref: "services_pbx_table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchPbxServicesData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("services.service_number"),
                                  show: "pbx_services_number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "services.service_number"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: {
                                                to: `/customer/pbx-service/${row.id}/view`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    row.pbx_services_number
                                                  ) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  430351297
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$tc("packages.package", 1),
                                  show: "pbx_package_name",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.amount"),
                                  "sort-as": "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.amount"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.total,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4112549247
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.term"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.term"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.capitalizeFirstLetter(
                                                  row.term
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3884038541
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.activation_date"),
                                  "sort-as": "pbx_services.date_begin",
                                  show: "formattedActivationDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  "sort-as": "pbx_services.renewal_date",
                                  label: _vm.$t("customers.renewal_date"),
                                  show: "formattedRenewalDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown no-click",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.actions"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown-item",
                                                {
                                                  attrs: {
                                                    to: `/customer/pbx-service/${row.id}/view`,
                                                    "tag-name": "router-link",
                                                  },
                                                },
                                                [
                                                  _c("cog-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.$t("general.manage")
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3589606979
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeMainTab === "Invoices"
                ? _c(
                    "div",
                    [
                      _c(
                        "sw-tabs",
                        {
                          attrs: { "active-tab": _vm.activeInvoiceTab },
                          on: { update: _vm.setInvoiceStatusFilter },
                        },
                        [
                          _c("sw-tab-item", {
                            attrs: { title: _vm.$t("general.all"), filter: "" },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.due"),
                              filter: "DUE",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.overdue"),
                              filter: "OVERDUE",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.completed"),
                              filter: "COMPLETED",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.archived"),
                              filter: "ARCHIVED",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activeMainTab === "Invoices"
                        ? _c(
                            "sw-table-component",
                            {
                              key: "Invoices",
                              ref: "invoices_table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchInvoicesData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.date"),
                                  "sort-as": "invoice_date",
                                  show: "formattedInvoiceDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.number"),
                                  show: "invoice_number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("invoices.number"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: { to: `#` },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(row.invoice_number) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3089639070
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.status"),
                                  "sort-as": "status",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("invoices.status"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-badge",
                                            {
                                              attrs: {
                                                "bg-color":
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.status
                                                  ).bgColor,
                                                color:
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.status
                                                  ).color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    row.status.replace("_", " ")
                                                  ) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1872987790
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.paid_status"),
                                  "sort-as": "paid_status",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("invoices.paid_status")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-badge",
                                            {
                                              attrs: {
                                                "bg-color":
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.paid_status
                                                  ).bgColor,
                                                color:
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.paid_status
                                                  ).color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    row.paid_status.replace(
                                                      "_",
                                                      " "
                                                    )
                                                  ) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2340167694
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.total"),
                                  "sort-as": "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("invoices.total"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.total,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3504236644
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("invoices.amount_due"),
                                  "sort-as": "due_amount",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("invoices.amount_due")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.due_amount,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1927697636
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown no-click",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("invoices.action"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `invoice/${row.id}/view`,
                                                      },
                                                    },
                                                    [
                                                      _c("eye-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.view"
                                                            )
                                                          ) +
                                                          "\n              "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  row.status === "SENT" ||
                                                  row.status === "VIEWED" ||
                                                  row.status === "OVERDUE"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                {
                                                                  name: "paymentsCustomer.create",
                                                                  params: {
                                                                    invoiceItem:
                                                                      row,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "credit-card-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "payments.make_payment"
                                                                )
                                                              ) +
                                                              "\n              "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  666801534
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeMainTab === "Quotes"
                ? _c(
                    "div",
                    [
                      _c(
                        "sw-tabs",
                        {
                          attrs: { "active-tab": _vm.activeEstimateTab },
                          on: { update: _vm.setEstimateStatusFilter },
                        },
                        [
                          _c("sw-tab-item", {
                            attrs: { title: "Pending", filter: "SENT" },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: { title: _vm.$t("general.all"), filter: "" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activeMainTab === "Quotes"
                        ? _c(
                            "sw-table-component",
                            {
                              key: "Quotes",
                              ref: "estimates_table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchEstimatesData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("estimates.date"),
                                  "sort-as": "estimate_date",
                                  show: "formattedEstimateDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$tc("estimates.estimate", 1),
                                  show: "estimate_number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc("estimates.estimate", 1)
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: {
                                                "tag-name": "router-link",
                                                to: `estimates/${row.id}/view`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(row.estimate_number) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3533244157
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("estimates.status"),
                                  show: "status",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("estimates.status"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-badge",
                                            {
                                              staticClass: "px-3 py-1",
                                              attrs: {
                                                "bg-color":
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.status
                                                  ).bgColor,
                                                color:
                                                  _vm.$utils.getBadgeStatusColor(
                                                    row.status
                                                  ).color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(row.status) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  889009018
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("estimates.total"),
                                  "sort-as": "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("estimates.total"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.total,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  181981859
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("estimates.action"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            {
                                              attrs: { containerClass: "w-56" },
                                            },
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown-item",
                                                {
                                                  attrs: {
                                                    "tag-name": "router-link",
                                                    to: `estimates/${row.id}/view`,
                                                  },
                                                },
                                                [
                                                  _c("eye-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.$t("general.view")
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              row.status !== "ACCEPTED"
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onMarkAsAccepted(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("check-circle-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n              " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.mark_as_accepted"
                                                            )
                                                          ) +
                                                          "\n            "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.status !== "REJECTED"
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onMarkAsRejected(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("x-circle-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n              " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.mark_as_rejected"
                                                            )
                                                          ) +
                                                          "\n            "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2374927563
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeMainTab === "Payments"
                ? _c(
                    "div",
                    [
                      _c(
                        "sw-tabs",
                        {
                          attrs: {
                            "active-tab": _vm.activePaymentTransactionTab,
                          },
                          on: { update: _vm.setPaymentsStatusFilter },
                        },
                        [
                          _c("sw-tab-item", {
                            attrs: { title: _vm.$t("general.all"), filter: "" },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.void"),
                              filter: "Void",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.unapply"),
                              filter: "Unapply",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.refunded"),
                              filter: "Refunded",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-tab-item", {
                            attrs: {
                              title: _vm.$t("general.approved"),
                              filter: "Approved",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activeMainTab === "Payments"
                        ? _c(
                            "sw-table-component",
                            {
                              key: "Payments",
                              ref: "payments_table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchPaymentsData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("payments.date"),
                                  "sort-as": "payment_date",
                                  show: "formattedPaymentDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("payments.payment_number"),
                                  show: "payment_number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.payment_number"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: { to: `#` },
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(row.payment_number) +
                                                  "\n          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1421985147
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("payments.payment_mode"),
                                  show: "payment_mode",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("payments.payment_mode")
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.payment_mode
                                                  ? row.payment_mode
                                                  : "Not selected"
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2950810553
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("payments.invoice"),
                                  "sort-as": "invoice_id",
                                  show: "invoice_number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.invoice_number"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.invoice_number
                                                  ? row.invoice_number
                                                  : "For customer credit"
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3966598007
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("payments.amount"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("payments.amount"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.amount,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  875628239
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("payments.action"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3425878464
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }