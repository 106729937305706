var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "5" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "px-5 py-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                        },
                        [_c("drag-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("item-select", {
                        ref: "itemSelect",
                        attrs: {
                          invalid: _vm.$v.item.name.$error,
                          item: _vm.item,
                        },
                        on: {
                          search: _vm.searchVal,
                          select: _vm.onSelectItem,
                          deselect: _vm.deselectItem,
                          onSelectItem: function ($event) {
                            _vm.isSelected = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-center" }, [
                  _c("div", { staticClass: "items-center text-sm" }, [
                    _c("span", [
                      _c("div", [_vm._v(_vm._s(_vm.item.unit_name))]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end text-sm" },
                    [
                      _c("span", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                _vm.item.price,
                                _vm.currency
                              )
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                        },
                        [
                          _vm.showRemoveItemIcon
                            ? _c("trash-icon", {
                                staticClass: "h-5 text-gray-700",
                                on: { click: _vm.removeItem },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "50%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "20%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }