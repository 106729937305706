var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c("div", { staticClass: "p-8 sm:p-6" }, [
      !_vm.imageData
        ? _c(
            "div",
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: { label: _vm.$t("payments.cameras") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.devices,
                      searchable: true,
                      tabindex: 16,
                      "allow-empty": false,
                      label: "label",
                      "track-by": "deviceId",
                    },
                    model: {
                      value: _vm.deviceSelect,
                      callback: function ($$v) {
                        _vm.deviceSelect = $$v
                      },
                      expression: "deviceSelect",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "camera-container" },
                [
                  _c("WebCam", {
                    ref: "webcam",
                    attrs: { deviceId: _vm.deviceSelect.deviceId },
                    on: { cameras: _vm.onCameras },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "overlayTakePhoto" }, [
                    _c("div", {
                      staticClass: "circle",
                      class: { "no-face": !_vm.faceDetected },
                    }),
                    _vm._v(" "),
                    !_vm.faceDetected
                      ? _c("div", { staticClass: "message" }, [
                          _vm._v("No Face Detected"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex justify-center mt-5" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline", type: "button" },
                      on: { click: _vm.closeTakeModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mx-4",
                      attrs: { type: "button" },
                      on: { click: _vm.onCapture },
                    },
                    [
                      _c("CameraIcon", { staticClass: "w-6 h-6 mr-2" }),
                      _vm._v("\n          Take\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imageData
        ? _c("div", [
            _c("img", { attrs: { src: _vm.imageData, alt: "Foto tomada" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-center mt-5" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline", type: "button" },
                    on: { click: _vm.onCapture },
                  },
                  [_c("CameraIcon", { staticClass: "w-6 h-6" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mx-4",
                    attrs: { type: "button" },
                    on: { click: _vm.saveImage },
                  },
                  [_c("CheckIcon", { staticClass: "w-6 h-6" })],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }