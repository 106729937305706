var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.EventSubir.apply(null, arguments)
        },
      },
    },
    [
      !_vm.banCsv
        ? _c("div", [
            _c("div", { staticClass: "py-4 px-6 form-group-row" }, [
              _c(
                "h3",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-style": "italic",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("general.text_csv_inv")))]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "py-4 px-6 form-group-row" }, [
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("input", {
                    ref: "file",
                    staticClass: "form-control mb-2",
                    attrs: { type: "file", id: "file", accept: ".CSV" },
                    on: {
                      change: function ($event) {
                        return _vm.handleFileUpload()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "relative my-0 ml-0 text-sm font-medium cursor-pointer text-primary-500 inline",
                      staticStyle: {
                        "text-transform": "uppercase",
                        "margin-left": "2em",
                      },
                      attrs: { href: "#" },
                      on: { click: _vm.downloadCsv },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("expenses.download_exmaple")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "relative my-0 ml-0 text-sm font-medium cursor-pointer text-primary-500 inline",
                      staticStyle: {
                        "text-transform": "uppercase",
                        "margin-left": "2em",
                      },
                      attrs: { href: "#" },
                      on: { click: _vm.downloadGuide },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.download_guide")))]
                  ),
                  _vm._v(" "),
                  _vm.banCargArch
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "flex mr-2 w-50",
                          attrs: { variant: "primary", type: "button" },
                          on: { click: _vm.cancelLoadArch },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.remove")) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            this.showErrors == true && this.listErrors != null
              ? _c(
                  "div",
                  {
                    staticClass: "error-box",
                    staticStyle: {
                      "max-height": "400px",
                      "overflow-y": "scroll",
                    },
                  },
                  _vm._l(this.listErrors, function (error, key) {
                    return _c("div", { key: key }, [
                      key == "date_due"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.due_date"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "date_invoice"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.date_invoice"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "date_service"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.date_service"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "discount_type"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.discount_type_e"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "users_no_found"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.users_no_found"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "status"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.statust"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "paid_status"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.paid_status"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "per_discount"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.per_discount"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "tax_type"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.tax_type"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "users_multiple_diff"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("general.users_multiple_diff"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "users_multiple_same"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("general.users_multiple_same"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "invoice"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.invoice_inv"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "due_biggest"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.due_biggest"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "discounts_invoice_positive"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("general.discounts_invoice_positive")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "status_unpaid_total_diff"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("general.status_unpaid_total_diff"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "taxes_no_found"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.taxes_no_found"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "discount_line_int"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.discount_line_int"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "discount_line_val"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.discount_line_val"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "total_int"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.total_int"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      key == "quantity_no_int"
                        ? _c("h2", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("general.quantity_no_int"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("general.list_error")) + " :"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(error.title))]),
                      _vm._v(" "),
                      _c(
                        "ol",
                        { attrs: { type: "1" } },
                        _vm._l(error.items, function (item) {
                          return _c("li", { key: item }, [_vm._v(_vm._s(item))])
                        }),
                        0
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      this.banCsv
        ? _c("div", {}, [
            _c(
              "div",
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 xl:mx-8",
                    attrs: { label: _vm.$t("general.warningdate") },
                  },
                  [
                    _c("sw-select", {
                      staticStyle: {
                        "max-width": "300px",
                        "margin-bottom": "10px",
                      },
                      attrs: {
                        options: _vm.dateOptions,
                        placeholder: _vm.$t("general.warningdate"),
                        "track-by": "name",
                        label: "name",
                      },
                      model: {
                        value: _vm.formData.formatDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "formatDate", $$v)
                        },
                        expression: "formData.formatDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            this.banCsv
              ? _c(
                  "div",
                  { staticStyle: { "overflow-y": "scroll", height: "500px" } },
                  [
                    _c("table", { attrs: { id: "tabla-especial" } }, [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(
                            _vm.formData.listCsv[0],
                            function (column, index) {
                              return _c("th", { key: index }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(column) +
                                    "\n                "
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.formData.listCsv.slice(1),
                          function (row, rowIndex) {
                            return _c(
                              "tr",
                              { key: rowIndex },
                              _vm._l(row, function (cell, cellIndex) {
                                return _c("td", { key: cellIndex }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(cell) +
                                      "\n                "
                                  ),
                                ])
                              }),
                              0
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "z-0 flex justify-end p-4" },
        [
          _c(
            "sw-button",
            {
              staticClass: "flex my-4 ml-6 mr-2 w-50",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closePaymentModeModal },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "flex my-4 my-4 mr-6 ml-2 w-50",
              attrs: {
                variant: "primary",
                type: "submit",
                size: "lg",
                loading: _vm.isLoading,
              },
            },
            [
              this.banCsv
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n        " +
                  _vm._s(
                    this.banCsv
                      ? _vm.$t("general.save")
                      : _vm.$t("general.next")
                  ) +
                  "\n\n        "
              ),
              !this.banCsv
                ? _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }