var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("expenses.expense", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/expenses`,
                    variant: "primary-outline",
                    size: "lg",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalTemplateExpense,
                      expression: "totalTemplateExpense",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "expenses-template/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("expenses.add_expense_template")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "w-25",
              staticStyle: { "margin-left": "1em", "margin-right": "1em" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("expenses.customer") },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("expenses.provider") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.providers,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("expenses.provider_select"),
                          label: "title",
                        },
                        on: {
                          select: _vm.onSelectProvider,
                          deselect: _vm.clearProviderSearch,
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.provider,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "provider", $$v)
                          },
                          expression: "filters.provider",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: {
                        label: _vm.$t("expenses.expense_template_number"),
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.expense_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "expense_number", $$v)
                            },
                            expression: "filters.expense_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("expenses.category") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.categories,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "expenses.categories.select_a_category"
                          ),
                          label: "name",
                        },
                        on: {
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "category", $$v)
                          },
                          expression: "filters.category",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("expenses.from_date") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "mt-2",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("expenses.to_date") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "mt-2",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("expenses.no_expenses"),
            description: _vm.$t("expenses.list_of_expenses"),
          },
        },
        [
          _c("observatory-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/expenses-template/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("expenses.add_new_expense")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllExpenses },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm",
                },
                on: { change: _vm.selectAllExpenses },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: { "show-filter": false, data: _vm.fetchData },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm",
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function ($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField",
                            },
                          }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.date_expense_template"),
                  "sort-as": "expense_date",
                  show: "expense_date",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.expense_template_number"),
                  show: "template_expense_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("expenses.expense_template_number"))
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: {
                                    path: `expenses-template/${row.id}/edit`,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.template_expense_number) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.template_expense_number) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$tc("expenses.categories.category", 1),
                  "sort-as": "category_name",
                  show: "category_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "           \n          " +
                            _vm._s(row.category_name) +
                            "      \n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.customer"),
                  "sort-as": "user_name",
                  show: "user_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("expenses.customer"))),
                        ]),
                        _vm._v(" "),
                        row.customer_id == null
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.user_name
                                        ? row.user_name
                                        : _vm.$t(
                                            "expenses.customer_no_selected"
                                          )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.customer_id != null
                          ? _c(
                              "div",
                              [
                                _vm.permissionModule.read
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "font-medium text-primary-500",
                                        attrs: {
                                          to: {
                                            path: `customers/${row.customer_id}/view`,
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                row.user_name
                                                  ? row.user_name
                                                  : _vm.$t(
                                                      "expenses.customer_no_selected"
                                                    )
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.user_name
                                              ? row.user_name
                                              : _vm.$t(
                                                  "expenses.customer_no_selected"
                                                )
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                _vm._v(" "),
                                row.customcode != null
                                  ? _c(
                                      "p",
                                      { staticStyle: { "font-size": "15px" } },
                                      [_vm._v(" " + _vm._s(row.customcode))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.provider"),
                  "sort-as": "provider_title",
                  show: "provider_title",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("expenses.provider"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          row.providers_id == null
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.provider_title
                                        ? row.provider_title
                                        : _vm.$t(
                                            "expenses.provider_no_selected"
                                          )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          row.providers_id != null
                            ? _c(
                                "div",
                                [
                                  _vm.permissionModule.read
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "font-medium text-primary-500",
                                          attrs: {
                                            to: {
                                              path: `providers/${row.providers_id}/view`,
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                row.provider_title
                                                  ? row.provider_title
                                                  : _vm.$t(
                                                      "expenses.provider_no_selected"
                                                    )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              row.provider_title
                                                ? row.provider_title
                                                : _vm.$t(
                                                    "expenses.provider_no_selected"
                                                  )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  row.providers_number != null
                                    ? _c(
                                        "p",
                                        {
                                          staticStyle: { "font-size": "15px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.providers_number)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.amount"),
                  "sort-as": "amount",
                  show: "category.amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.amount")))]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.amount,
                                _vm.defaultCurrency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.last_date"),
                  show: "last_date",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("expenses.last_date"))),
                        ]),
                        _vm._v(
                          "\n\n            " +
                            _vm._s(row.last_date == null ? "" : row.last_date) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("expenses.note"),
                  "sort-as": "expense_date",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.note")))]),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.description
                                ? row.description
                                : _vm.$t("item_groups.empty")
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("expenses.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `expenses-template/${row.id}/edit`,
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeExpense(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }