var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "px-8 py-10 sm:p-6 relative overflow-auto sw-scroll h-96 grid grid-cols-12 gap-5",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_name"),
                    error: _vm.nameError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_voicemail_pin"),
                    error: _vm.pinError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.pin.$error,
                      type: "text",
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      pattern: "[0-9]{4}",
                      maxlength: "4",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.pin.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.pin,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "pin", $$v)
                      },
                      expression: "formData.pin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_protocol"),
                    error: _vm.protocolError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.protocol.$error,
                      options: _vm.potrocolOptions,
                      searchable: true,
                      "show-labels": false,
                      tabindex: 2,
                      "allow-empty": true,
                      label: "name",
                      "track-by": "value",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.protocol.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.protocol,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "protocol", $$v)
                      },
                      expression: "formData.protocol",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("login.email"),
                    error: _vm.emailError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-1 md:mt-0",
                    attrs: {
                      invalid: _vm.$v.formData.email.$error,
                      type: "text",
                      name: "email",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.email.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_extension"),
                    error: _vm.extError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.ext.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.ext.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.ext,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "ext", $$v)
                      },
                      expression: "formData.ext",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_uad"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.uadOptions,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      label: "name",
                    },
                    model: {
                      value: _vm.formData.ua_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "ua_id", $$v)
                      },
                      expression: "formData.ua_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_uad_location"),
                    error: _vm.uadLocationError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.location.$error,
                      options: _vm.uadLocationOptions,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.location.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "location", $$v)
                      },
                      expression: "formData.location",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_status"),
                    variant: "horizontal",
                    error: _vm.statusError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.status.$error,
                      options: _vm.statuses,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      placeholder: _vm.$t("pbx_services.ext_select_status"),
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.status.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_voicemail"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "-mt-3",
                    model: {
                      value: _vm.formData.voicemail,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "voicemail", $$v)
                      },
                      expression: "formData.voicemail",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$tc("users.password"),
                    error: _vm.secretError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "col-span-12 md:col-span-12",
                    attrs: {
                      type: _vm.getInputType,
                      autocomplete: "new-password",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "rightIcon",
                        fn: function () {
                          return [
                            _vm.isShowPassword
                              ? _c("eye-off-icon", {
                                  staticClass:
                                    "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.isShowPassword = !_vm.isShowPassword
                                    },
                                  },
                                })
                              : _c("eye-icon", {
                                  staticClass:
                                    "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.isShowPassword = !_vm.isShowPassword
                                    },
                                  },
                                }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.formData.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "secret", $$v)
                      },
                      expression: "formData.secret",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_incoming_limit"),
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "number", min: 0, max: 9999 },
                    model: {
                      value: _vm.formData.incoming_limit,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "incoming_limit", $$v)
                      },
                      expression: "formData.incoming_limit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_outgoing_limit"),
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "number", min: 0, max: 9999 },
                    model: {
                      value: _vm.formData.outgoing_limit,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "outgoing_limit", $$v)
                      },
                      expression: "formData.outgoing_limit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_auto_provisioning"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "-mt-3",
                    on: { change: _vm.changeAutoProvisioning },
                    model: {
                      value: _vm.formData.auto_provisioning,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "auto_provisioning", $$v)
                      },
                      expression: "formData.auto_provisioning",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData.auto_provisioning
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t("pbx_services.ext_mac_address"),
                        error: _vm.macAddressError,
                        variant: "horizontal",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.mac_address.$error,
                          type: "text",
                          maxLength: "12",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.mac_address.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.mac_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mac_address", $$v)
                          },
                          expression: "formData.mac_address",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData.auto_provisioning
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t("pbx_services.ext_time_zone"),
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.optionsTimeZone,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          "max-height": 200,
                          label: "name",
                        },
                        model: {
                          value: _vm.formData.time_zone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "time_zone", $$v)
                          },
                          expression: "formData.time_zone",
                        },
                      }),
                      _vm._v(" "),
                      _vm.formData.auto_provisioning
                        ? _c(
                            "span",
                            { staticClass: "text-yellow-700 text-xs" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pbx_services.ext_time_zone_message_warning"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData.auto_provisioning
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t("pbx_services.ext_DHCP"),
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("sw-switch", {
                        staticClass: "-mt-3",
                        model: {
                          value: _vm.formData.dhcp,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "dhcp", $$v)
                          },
                          expression: "formData.dhcp",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData.dhcp == 0
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t("pbx_services.ext_IP_static"),
                        error: _vm.staticIpError,
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.static_ip.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.static_ip.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.static_ip,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "static_ip", $$v)
                          },
                          expression: "formData.static_ip",
                        },
                      }),
                      _vm._v(" "),
                      _vm.formData.dhcp == 0
                        ? _c(
                            "span",
                            { staticClass: "text-yellow-700 text-xs" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pbx_services.ext_IP_static_message_warning"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeExtensionModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                { attrs: { variant: "primary", type: "submit" } },
                [
                  _c("save-icon", { staticClass: "mr-2" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }