var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "7" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "px-5 py-4 text-center" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-start text-sm" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-5 h-5 mt-2 text-gray-400 cursor-move handle mr-2",
                        },
                        [_c("drag-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "whitespace-nowrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.showFromTo(_vm.prefix.typecustom))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      _c("prefix-select", {
                        ref: "prefixSelect",
                        attrs: { prefix: _vm.prefix },
                        on: {
                          search: _vm.searchVal,
                          select: _vm.onSelectPrefix,
                          unselect: _vm.unselectPrefix,
                          onSelectPrefix: function ($event) {
                            _vm.isSelected = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-center" }, [
                  _c("div", { staticClass: "items-center text-sm" }, [
                    _c("span", [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            `${_vm.prefix.from || ""}${
                              _vm.prefix.to ? " / " : ""
                            }${_vm.prefix.to || ""}`
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-center" }, [
                  _c("div", { staticClass: "items-center text-sm" }, [
                    _c("span", [
                      _c("div", [_vm._v(_vm._s(_vm.prefix.prefix))]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-center" }, [
                  _c("div", { staticClass: "items-center text-sm" }, [
                    _c("span", [
                      _c("div", [_vm._v(_vm._s(_vm.prefix.country_name))]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end text-sm" },
                    [
                      _c("span", [
                        _vm.prefix.rate_per_minute != null
                          ? _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.defaultCurrency.symbol +
                                      " " +
                                      _vm.prefix.rate_per_minute
                                  ) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                        },
                        [
                          _vm.showRemoveItemIcon
                            ? _c("trash-icon", {
                                staticClass: "h-5 text-gray-700",
                                on: { click: _vm.removePrefix },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "25%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }