var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("corePbx.submenu_title.custom_app_rate"),
                  to: "/admin/corePBX/billing-templates/custom-app-rate",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _vm.isView
                ? _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePBX/billing-templates/custom-app-rate`,
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.go_back")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isView
                ? _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: { variant: "primary", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.removeCustomAppRate()
                        },
                      },
                    },
                    [
                      _c("XIcon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isView
                ? _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePBX/billing-templates/custom-app-rate/${_vm.$route.params.id}/edit`,
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _c("PencilIcon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "flex mt-2 col-span-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: {
                  label: _vm.$t("general.name"),
                  error: _vm.nameError,
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  class: { "pointer-events-none": _vm.isView },
                  attrs: {
                    invalid: _vm.$v.formData.name.$error,
                    placeholder: _vm.$t("general.name"),
                    focus: "",
                    type: "text",
                    name: "name",
                    tabindex: "1",
                    placer: "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "flex flex-wrap" }, [
          _c("div", { staticClass: "w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.office,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "office", $$v)
                          },
                          expression: "formData.office",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.office")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.office
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.office_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.office_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "office_price", $$v)
                            },
                            expression: "formData.office_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.bussiness,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bussiness", $$v)
                          },
                          expression: "formData.bussiness",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.bussiness")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.bussiness
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.bussiness_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.bussiness_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "bussiness_price", $$v)
                            },
                            expression: "formData.bussiness_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.web,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "web", $$v)
                          },
                          expression: "formData.web",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.web")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.web
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.web_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.web_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "web_price", $$v)
                            },
                            expression: "formData.web_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.agent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "agent", $$v)
                          },
                          expression: "formData.agent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.agent")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.agent
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.agent_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.agent_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "agent_price", $$v)
                            },
                            expression: "formData.agent_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.supervisor,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "supervisor", $$v)
                          },
                          expression: "formData.supervisor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.supervisor")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.supervisor
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.agent_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.supervisor_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supervisor_price", $$v)
                            },
                            expression: "formData.supervisor_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.mobile")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.mobile
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.mobile_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.mobile_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "mobile_price", $$v)
                            },
                            expression: "formData.mobile_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.crm,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "crm", $$v)
                          },
                          expression: "formData.crm",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.crm")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.crm
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.crm_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.crm_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "crm_price", $$v)
                            },
                            expression: "formData.crm_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-8 mb-4 md:pr-5" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-20px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.formData.call_pop_up,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "call_pop_up", $$v)
                          },
                          expression: "formData.call_pop_up",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black box-title",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.call_pop_up")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formData.call_pop_up
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$t("general.call_pop_up_price"),
                          error: _vm.nameError,
                        },
                      },
                      [
                        _c("sw-money", {
                          class: { "pointer-events-none": _vm.isView },
                          attrs: {
                            currency: _vm.customerCurrency,
                            name: "price",
                          },
                          model: {
                            value: _vm.formData.call_pop_up_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "call_pop_up_price", $$v)
                            },
                            expression: "formData.call_pop_up_price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        !_vm.isView
          ? _c("div", { staticClass: "pt-4 py-2" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-md-row" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mb-2 mb-md-0 flex-fill",
                      attrs: {
                        loading: _vm.isLoading,
                        type: "submit",
                        variant: "primary",
                        size: "lg",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitCustomAppRate()
                        },
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("general.update")
                              : _vm.$t("general.save")
                          ) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mt-2 mt-md-0 ml-md-4 flex-fill",
                      attrs: {
                        variant: "primary-outline",
                        type: "button",
                        size: "lg",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancelForm()
                        },
                      },
                    },
                    [
                      _c("x-circle-icon", {
                        staticClass: "w-6 h-6 mr-1 -ml-2",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isView
          ? _c(
              "div",
              [
                _c("h6", { staticClass: "sw-section-title mt-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("corePbx.packages.list_of_associated_packages")
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    attrs: {
                      data: _vm.fetchDataPackage,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("packages.item.package_id"),
                        show: "packages_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("packages.item.package_id"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "font-medium text-primary-500",
                                    attrs: {
                                      to: {
                                        path: `/admin/corePBX/packages/${row.id}/view`,
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.packages_number) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        899071057
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("packages.item.name"),
                        show: "pbx_package_name",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("packages.item.type_service"),
                        show: "status_payment",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                row.status_payment == "postpaid"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("packages.item.postpaid")
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status_payment == "prepaid"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("packages.item.prepaid")
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2344685200
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("packages.item.status"),
                        show: "status",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                row.status == "A"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.active")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "I"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.inactive")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2776009887
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }