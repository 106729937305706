var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chartData
    ? _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c(
              "div",
              { staticClass: "col-span-12 xl:col-span-9 xxl:col-span-10" },
              [
                _c("div", { staticClass: "flex justify-between mt-1 mb-6" }, [
                  _c(
                    "h6",
                    { staticClass: "flex items-center sw-section-title" },
                    [
                      _c("chart-square-bar-icon", {
                        staticClass: "h-5 text-primary-400",
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.monthly_chart.title")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-40 h-10" },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.years,
                          "allow-empty": false,
                          "show-labels": false,
                          placeholder: _vm.$t("dashboard.select_year"),
                        },
                        on: { select: _vm.onChangeYear },
                        model: {
                          value: _vm.selectedYear,
                          callback: function ($$v) {
                            _vm.selectedYear = $$v
                          },
                          expression: "selectedYear",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("line-chart", {
                  staticClass: "sm:w-full",
                  attrs: {
                    "format-money": _vm.$utils.formatMoney,
                    "format-graph-money": _vm.$utils.formatGraphMoney,
                    invoices: _vm.getChartInvoices,
                    expenses: _vm.getChartExpenses,
                    receipts: _vm.getReceiptTotals,
                    income: _vm.getNetProfits,
                    labels: _vm.getChartMonths,
                    refresh: _vm.refresh,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-2 col-span-10 text-center border-t border-l border-gray-200 border-solid lg:border-t-0 lg:text-right lg:col-span-3 xl:col-span-2 lg:grid-cols-1",
              },
              [
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.total_sales")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedSalesTotal),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.total_receipts")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#00c99c" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalReceipts),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.total_expense")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#fb7178" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalExpenses),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.net_income")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#5851d8" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalNetProfit),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.credit_add")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#900c3f" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedpaymentcredits),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("dashboard.chart_info.total_balance")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#900c3f" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalBalance),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "px-6 py-2" },
                  [
                    _c(
                      "sw-dropdown",
                      { staticStyle: { width: "100%", cursor: "pointer" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "grid grid-cols-2 gap-3",
                            attrs: { slot: "activator" },
                            slot: "activator",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-span-2 content-end" },
                              [
                                _c("plus-circle-icon", {
                                  staticClass: "h-5 text-gray-600",
                                  staticStyle: { display: "inline" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-xs leading-5 lg:text-sm",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "dashboard.chart_info.callRegister"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "col-span-2 text-xl font-semibold leading-8",
                                staticStyle: { color: "#900c3f" },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getFormattedAccountBalance
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.invoice_total.invoice_total"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v("  "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getFormattedTotalInvoices
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?status=DUE`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                "  " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.invoice_total.invoice_due"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v("  "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getFormattedDueInvoices),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?status=OVERDUE`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.invoice_total.invoice_overdue"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v("   "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getFormattedOverdueInvoices
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?paid_status=UNPAID`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "dashboard.invoice_total.invoice_unpaid"
                                  )
                                )
                              ),
                            ]),
                            _vm._v("   "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getFormattedUnpaidInvoices
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?status=DUE`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.invoice_total.service_unpaid"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v("    "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getFormattedService),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?status=COMPLETED`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.invoice_total.service_unpaid_pbx"
                                    )
                                  )
                              ),
                            ]),
                            _vm._v("   "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getFormattedpbxService),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            attrs: {
                              to: `/admin/invoices?status=COMPLETED`,
                              "tag-name": "router-link",
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("dashboard.invoice_total.cdr_balance")
                                )
                              ),
                            ]),
                            _vm._v("   "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getFormattedcallRegister),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("customer-info"),
        ],
        1
      )
    : !_vm.isLoaded
    ? _c("base-loader")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }