var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chartData
    ? _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [_c("basic-info")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }