var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "relative h-full",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateCompanyData.apply(null, arguments)
        },
      },
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c("div", [
                  _c("h6", { staticClass: "sw-section-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("settings.company_info.company_info")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      staticStyle: { "max-width": "680px" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("settings.company_info.section_description")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-shrink-0 ml-4 justify-end" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 hidden xl:block" },
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline" },
                          on: { click: _vm.toggleSideBar },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tickets.departaments.menu")) +
                              "\n              "
                          ),
                          _c(_vm.listIcon, {
                            tag: "component",
                            staticClass: "w-4 h-4 ml-2 -mr-1",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid mb-2 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_logo"),
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                      attrs: { id: "logo-box" },
                    },
                    [
                      _vm.previewLogo
                        ? _c("img", {
                            staticClass: "absolute opacity-100 preview-logo",
                            staticStyle: {
                              "max-height": "80%",
                              animation: "fadeIn 2s ease",
                            },
                            attrs: { src: _vm.previewLogo },
                          })
                        : _c(
                            "div",
                            { staticClass: "flex flex-col items-center" },
                            [
                              _c("cloud-upload-icon", {
                                staticClass:
                                  "h-5 mb-2 text-xl leading-6 text-gray-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-xs leading-4 text-center text-gray-400",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.drag_file"
                                        )
                                      ) +
                                      "\n              \n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-pointer text-primary-500",
                                      attrs: { id: "pick-avatar" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$tc(
                                              "settings.company_info.browser"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.choose_file"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-avatar", {
                    staticClass: "mb-2",
                    attrs: {
                      trigger: "#logo-box",
                      "preview-avatar": _vm.previewLogo,
                    },
                    on: {
                      changed: _vm.onChange,
                      uploadHandler: _vm.onUploadHandler,
                      handleUploadError: _vm.onHandleUploadError,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.supported_files"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.$tc("settings.company_info.file_size"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.maximun_file"))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid mb-6 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.wallpaper_login"),
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                      attrs: { id: "wallpaper-box" },
                    },
                    [
                      _vm.previewWallpaper
                        ? _c("img", {
                            staticClass: "absolute opacity-100 preview-logo",
                            staticStyle: {
                              "max-height": "80%",
                              animation: "fadeIn 2s ease",
                            },
                            attrs: { src: _vm.previewWallpaper },
                          })
                        : _c(
                            "div",
                            { staticClass: "flex flex-col items-center" },
                            [
                              _c("cloud-upload-icon", {
                                staticClass:
                                  "h-5 mb-2 text-xl leading-6 text-gray-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-xs leading-4 text-center text-gray-400",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.drag_file"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-pointer text-primary-500",
                                      attrs: { id: "pick-avatar" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$tc(
                                              "settings.company_info.browser"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.choose_file"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-avatar", {
                    staticClass: "mb-2",
                    attrs: {
                      trigger: "#wallpaper-box",
                      "preview-avatar": _vm.previewWallpaper,
                    },
                    on: {
                      changed: _vm.onChangeWallpaper,
                      uploadHandler: _vm.onUploadHandlerWallpaper,
                      handleUploadError: _vm.onHandleUploadError,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.supported_files"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.file_size_2"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.maximun_file_2"))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_name"),
                    error: _vm.nameError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      placeholder: _vm.$t("settings.company_info.company_name"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_identifier"),
                    error: _vm.company_identifierError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.company_identifier.$error,
                      placeholder: _vm.$t(
                        "settings.company_info.company_identifier"
                      ),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.company_identifier.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.company_identifier,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "company_identifier", $$v)
                      },
                      expression: "formData.company_identifier",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.company_info.phone") } },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$t("settings.company_info.phone"),
                    },
                    model: {
                      value: _vm.formData.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "phone", $$v)
                      },
                      expression: "formData.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.country"),
                    error: _vm.countryError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.country_id.$error },
                    attrs: {
                      options: _vm.countries,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$t("general.select_country"),
                      label: "name",
                      "track-by": "id",
                    },
                    on: {
                      select: function ($event) {
                        return _vm.countrySelected($event)
                      },
                    },
                    model: {
                      value: _vm.country,
                      callback: function ($$v) {
                        _vm.country = $$v
                      },
                      expression: "country",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.state"),
                    error: _vm.statusCustomerError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.billing_states,
                      invalid: _vm.$v.formData.billing_state.$error,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      tabindex: 8,
                      placeholder: _vm.$t("general.select_state"),
                      label: "name",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.formData.billing_state,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "billing_state", $$v)
                      },
                      expression: "formData.billing_state",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.city"),
                    error: _vm.cityError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.city.$error,
                      placeholder: _vm.$tc("settings.company_info.city"),
                      name: "city",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "city", $$v)
                      },
                      expression: "formData.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.county"),
                    error: _vm.countyError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.county.$error,
                      placeholder: _vm.$tc("settings.company_info.county"),
                      name: "county",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.county,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "county", $$v)
                      },
                      expression: "formData.county",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.zip"),
                    error: _vm.zipError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.zip.$error,
                      placeholder: _vm.$tc("settings.company_info.zip"),
                    },
                    model: {
                      value: _vm.formData.zip,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "zip", $$v)
                      },
                      expression: "formData.zip",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$tc("settings.company_info.address"),
                        error: _vm.address1Error,
                      },
                    },
                    [
                      _c("sw-textarea", {
                        class: {
                          invalid: _vm.$v.formData.address_street_1.$error,
                        },
                        attrs: {
                          placeholder: _vm.$tc("general.street_1"),
                          rows: "2",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.address_street_1.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.address_street_1,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "address_street_1", $$v)
                          },
                          expression: "formData.address_street_1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "my-2",
                      attrs: { error: _vm.address2Error },
                    },
                    [
                      _c("sw-textarea", {
                        class: {
                          invalid: _vm.$v.formData.address_street_2.$error,
                        },
                        attrs: {
                          placeholder: _vm.$tc("general.street_2"),
                          rows: "2",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.address_street_2.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.address_street_2,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "address_street_2", $$v)
                          },
                          expression: "formData.address_street_2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAvalaraAvailable
                ? _c(
                    "sw-input-group",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            variant: "primary-outline",
                            size: "lg",
                            type: "button",
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.checkBilling },
                        },
                        [
                          !_vm.isLoading
                            ? _c("check-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.billing_validation")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_page_title"),
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$t(
                        "settings.company_info.company_page_title"
                      ),
                    },
                    model: {
                      value: _vm.formData.company_page_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "company_page_title", $$v)
                      },
                      expression: "formData.company_page_title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_favicon"),
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                      attrs: { id: "favicon-box" },
                    },
                    [
                      _vm.previewFavicon
                        ? _c("img", {
                            staticClass: "absolute opacity-100 preview-logo",
                            staticStyle: {
                              "max-height": "80%",
                              animation: "fadeIn 2s ease",
                            },
                            attrs: { src: _vm.previewFavicon },
                          })
                        : _c(
                            "div",
                            { staticClass: "flex flex-col items-center" },
                            [
                              _c("cloud-upload-icon", {
                                staticClass:
                                  "h-5 mb-2 text-xl leading-6 text-gray-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-xs leading-4 text-center text-gray-400",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.drag_file"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-pointer text-primary-500",
                                      attrs: { id: "pick-avatar" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$tc(
                                              "settings.company_info.browser"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "settings.company_info.choose_file"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-avatar", {
                    attrs: {
                      trigger: "#favicon-box",
                      "preview-avatar": _vm.previewFavicon,
                    },
                    on: {
                      changed: _vm.onChange2,
                      uploadHandler: _vm.onUploadHandler2,
                      handleUploadError: _vm.onHandleUploadError2,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.company_info.company_report_info"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.formData.company_report_info,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "company_report_info", $$v)
                  },
                  expression: "formData.company_report_info",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.title_header"),
                    error: _vm.tittleHeadeError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$tc(
                        "settings.company_info.title_header"
                      ),
                      name: "title_header",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.title_header,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title_header", $$v)
                      },
                      expression: "formData.title_header",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.subtitle_header"),
                    error: _vm.subtittleHeadeError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$tc(
                        "settings.company_info.subtitle_header"
                      ),
                      name: "subtitle_header",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.subtitle_header,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "subtitle_header", $$v)
                      },
                      expression: "formData.subtitle_header",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.footer_text"),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.footer_text_value.$error,
                      placeholder: _vm.$tc("settings.company_info.footer_text"),
                      name: "footer_text_value",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.footer_text_value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "footer_text_value", $$v)
                      },
                      expression: "formData.footer_text_value",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mt-1 ml-1 p-0 m-0 text-xs leading-4 text-gray-500",
                      staticStyle: { "max-width": "480px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tc("settings.company_info.example_powered")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.footer_url"),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.footer_url_value.$error,
                      placeholder: _vm.$tc("settings.company_info.footer_url"),
                      name: "footer_url_value",
                      type: "url",
                    },
                    model: {
                      value: _vm.formData.footer_url_value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "footer_url_value", $$v)
                      },
                      expression: "formData.footer_url_value",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mt-1 ml-1 p-0 m-0 text-xs leading-4 text-gray-500",
                      staticStyle: { "max-width": "480px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tc("settings.company_info.example_website")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.footer_url_name"),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.footer_url_name.$error,
                      placeholder: _vm.$tc(
                        "settings.company_info.footer_url_name"
                      ),
                      name: "footer_url_name",
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.footer_url_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "footer_url_name", $$v)
                      },
                      expression: "formData.footer_url_name",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mt-1 ml-1 p-0 m-0 text-xs leading-4 text-gray-500",
                      staticStyle: { "max-width": "480px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tc(
                              "settings.company_info.example_company_name"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex mt-10 ml-1" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-switch", {
                      staticClass: "absolute",
                      staticStyle: { top: "-20px" },
                      model: {
                        value: _vm.formData.current_year,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "current_year", $$v)
                        },
                        expression: "formData.current_year",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-2" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("settings.company_info.include_current_year")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex mt-10 ml-1" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-switch", {
                      staticClass: "absolute",
                      staticStyle: { top: "-20px" },
                      model: {
                        value: _vm.formData.hide_title,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "hide_title", $$v)
                        },
                        expression: "formData.hide_title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-2" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("settings.company_info.hide_title")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mb-5 md:my-8" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.header_color"),
                  },
                },
                [
                  _vm.showPicker
                    ? _c("vue-tailwind-color-picker", {
                        ref: "colorPickerHeader",
                        attrs: {
                          swatches: _vm.swatches,
                          "hide-swatches": true,
                        },
                        on: {
                          "update:swatches": function ($event) {
                            _vm.swatches = $event
                          },
                          addSwatch: _vm.swatchAdded,
                          deleteSwatch: _vm.swatchDeleted,
                        },
                        model: {
                          value: _vm.formData.header_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "header_color", $$v)
                          },
                          expression: "formData.header_color",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$tc("settings.company_info.primary_color"),
                  },
                },
                [
                  _vm.showPicker
                    ? _c("vue-tailwind-color-picker", {
                        ref: "colorPickerPrimary",
                        attrs: {
                          swatches: _vm.swatches,
                          "hide-swatches": true,
                        },
                        on: {
                          "update:swatches": function ($event) {
                            _vm.swatches = $event
                          },
                          change: _vm.changedColorPrimary,
                          addSwatch: _vm.swatchAdded,
                          deleteSwatch: _vm.swatchDeleted,
                        },
                        model: {
                          value: _vm.formData.primary_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "primary_color", $$v)
                          },
                          expression: "formData.primary_color",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$tc("settings.company_info.color_invoice"),
                  },
                },
                [
                  _vm.showPicker
                    ? _c("vue-tailwind-color-picker", {
                        ref: "colorPickerInvoice",
                        attrs: {
                          swatches: _vm.swatches,
                          "hide-swatches": true,
                        },
                        on: {
                          "update:swatches": function ($event) {
                            _vm.swatches = $event
                          },
                          addSwatch: _vm.swatchAdded,
                          deleteSwatch: _vm.swatchDeleted,
                        },
                        model: {
                          value: _vm.formData.color_invoice,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "color_invoice", $$v)
                          },
                          expression: "formData.color_invoice",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tc("settings.company_info.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }