var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "dashboard" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("customers.customer", 2),
                  to: "#",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalCustomers,
                      expression: "totalCustomers",
                    },
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("customers.display_nametable") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.display_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "display_name", $$v)
                      },
                      expression: "filters.display_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.contact_name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "address_name",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "contact_name", $$v)
                      },
                      expression: "filters.contact_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.phone") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "phone", autocomplete: "off" },
                    model: {
                      value: _vm.filters.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "phone", $$v)
                      },
                      expression: "filters.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: {
                    label: _vm.$t("customers.status"),
                    color: "black-light",
                  },
                },
                [
                  _c("base-status-select", {
                    ref: "statusSelect",
                    on: {
                      select: _vm.onSelectStatus,
                      deselect: _vm.clearStatusSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full flex justify-end" },
        [
          _c(
            "sw-button",
            {
              attrs: { size: "lg", variant: "primary-outline" },
              on: {
                click: function ($event) {
                  return _vm.notificationsToAllCustomers()
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$tc("settings.mobile.messaging.send_notification", 2)
                  ) +
                  "\n        \n      "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("customers.no_customers"),
            description: _vm.$t("customers.list_of_customers"),
          },
        },
        [
          _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/customers/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("customers.add_new_customer")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.messagingCustomersTo))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.messagingCustomersTotal))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllCustomers },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm",
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function ($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField",
                            },
                          }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("customers.customer_number"),
                  show: "customcode",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.customer_number"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-medium text-primary-500 cursor-pointer",
                            attrs: { to: `/admin/customers/${row.id}/view` },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.customcode) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("customers.display_nametable"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.display_nametable"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-medium text-primary-500 cursor-pointer",
                            attrs: { to: `/admin/customers/${row.id}/view` },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.contact_name"),
                  show: "contact_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.contact_name"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.contact_name
                                  ? row.contact_name
                                  : _vm.$t("customers.no_contact_name")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.phone"),
                  show: "phone",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("customers.phone")))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.phone
                                  ? row.phone
                                  : _vm.$t("customers.no_contact")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.status"),
                  show: "status_customer",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.status"))),
                        ]),
                        _vm._v(" "),
                        row.status_customer === "A"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("customers.active")) +
                                  "\n          "
                              ),
                            ])
                          : row.status_customer === "I"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("customers.inactive")) +
                                  "\n          "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("customers.archive")) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("customers.action"),
                  show: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "sw-button",
                          {
                            staticClass: "mr-3",
                            attrs: {
                              variant: "primary-outline",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showModal(row.firebase_code)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$tc(
                                    "settings.mobile.messaging.send_notification",
                                    1
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-modal",
            {
              ref: "seenNotificationModal",
              attrs: { variant: "primary" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "absolute flex content-center justify-center w-5 cursor-pointer",
                          staticStyle: { top: "20px", right: "15px" },
                          on: { click: _vm.closeModal },
                        },
                        [_c("x-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("general.notification"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createNotification.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "p-6" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: _vm.$t("general.notification_title"),
                            required: "",
                            error: _vm.notificationTitleError,
                          },
                        },
                        [
                          _c("sw-input", {
                            ref: "title",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.notificationData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.notificationData, "title", $$v)
                              },
                              expression: "notificationData.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: _vm.$t("general.notification_text"),
                            required: "",
                            error: _vm.notificationTextError,
                          },
                        },
                        [
                          _c("sw-textarea", {
                            ref: "title",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.notificationData.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.notificationData, "text", $$v)
                              },
                              expression: "notificationData.text",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: _vm.$t("general.notification_image"),
                          },
                        },
                        [
                          _c("sw-input", {
                            ref: "title",
                            attrs: {
                              type: "text",
                              placeholder: "https://example.com/image.jpg",
                            },
                            model: {
                              value: _vm.notificationData.image,
                              callback: function ($$v) {
                                _vm.$set(_vm.notificationData, "image", $$v)
                              },
                              expression: "notificationData.image",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: { label: _vm.$t("general.notification_name") },
                        },
                        [
                          _c("sw-input", {
                            ref: "title",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.notificationData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.notificationData, "name", $$v)
                              },
                              expression: "notificationData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                    },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: { variant: "primary-outline", type: "button" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.cancel")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            loading: _vm.createNotificationLoading,
                            variant: "primary",
                            type: "submit",
                            disabled: _vm.createNotificationLoading,
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-2 w-6 h-6",
                              attrs: {
                                mlns: "http://www.w3.org/2000/svg",
                                fill: "none",
                                viewBox: "0 0 24 24",
                                "stroke-width": "1.5",
                                stroke: "currentColor",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  d: "M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75",
                                },
                              }),
                            ]
                          ),
                          _vm._v("\n                Send\n            "),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }