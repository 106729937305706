var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("div", { staticClass: "flex-grow" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.payment_gateways.title")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.payment_gateways.description")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-shrink-0 ml-4 justify-end" }, [
            _c(
              "div",
              { staticClass: "mb-3 hidden xl:block" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.toggleSideBar },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tickets.departaments.menu")) +
                        "\n          "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            variant: "gray",
          },
        },
        [
          _c("sw-table-column", {
            staticClass: "margin-width",
            attrs: {
              sortable: false,
              label: _vm.$t("settings.payment_gateways.payment_gateway"),
              show: "url_img",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", { staticClass: "mt-6 margin-width" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.payment_gateways.payment_gateway")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [
                      _c("img", {
                        staticClass: "margin-span",
                        attrs: { src: row.url_img, width: "150px" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.payment_gateways.payment_gateway_name"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.payment_gateways.payment_gateway_name"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_c("span", [_vm._v(_vm._s(row.name))])]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t(
                "settings.payment_gateways.payment_gateway_description"
              ),
              show: "description",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.payment_gateways.payment_gateway_description"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      },
                      [_vm._v(_vm._s(row.description))]
                    ),
                    _c("br"),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("avalara.item.status"),
              show: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("avalara.item.status")))]),
                    _vm._v(" "),
                    _c(
                      "sw-badge",
                      {
                        attrs: {
                          "bg-color": _vm.$utils.getBadgeStatusColor(
                            row.status == "A" ? "A" : "I"
                          ).bgColor,
                          color: _vm.$utils.getBadgeStatusColor(
                            row.status == "A" ? "A" : "I"
                          ).color,
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.status == "A" ? "Active" : "Inactive") +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("authorize.default"),
              show: "default",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("authorize.default")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          attrs: { disabled: false },
                          on: {
                            change: function ($event) {
                              return _vm.updateDefault(row)
                            },
                          },
                          model: {
                            value: row.default,
                            callback: function ($$v) {
                              _vm.$set(row, "default", $$v)
                            },
                            expression: "row.default",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _vm.permissionModule.update
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `/admin/settings/${row.name}`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.manage")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }