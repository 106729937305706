var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("invoices.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("invoices.invoice", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end gato" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalInvoices,
                      expression: "totalInvoices",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0 gato",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n          "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0 gato",
                  attrs: { size: "lg", variant: "primary" },
                  on: { click: _vm.openImportModal },
                },
                [
                  _c("upload-icon", {
                    staticClass: "h-4 mr-1 -ml-2 font-bold",
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.import")) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create && _vm.isSuperAdmin
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/invoices/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", {
                        staticClass: "w-6 h-6 mr-1 -ml-2 gatos",
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invoices.new_invoice")) +
                          "\n          "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "div",
                { staticClass: "w-50 mx-0 md:mx-5" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.customer", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("customers.customcode") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.customcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "customcode", $$v)
                            },
                            expression: "filters.customcode",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        size: "lg",
                        variant: "primary",
                        disabled: _vm.isDownloadReport,
                      },
                      on: { click: _vm.exportInvoices },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invoices.btn_export_invoices")) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25 mx-0 md:mx-5" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: { label: _vm.$t("invoices.invoice_number") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.invoice_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "invoice_number", $$v)
                            },
                            expression: "filters.invoice_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:mx-8",
                      attrs: { label: _vm.$t("invoices.status") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.status,
                          "group-select": false,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("general.select_a_status"),
                          "allow-empty": false,
                          "group-values": "options",
                          "group-label": "label",
                          "track-by": "name",
                          label: "name",
                        },
                        on: {
                          remove: function ($event) {
                            return _vm.clearStatusSearch()
                          },
                          select: _vm.setActiveTab,
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25 mx-0 md:mx-5" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.from") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.to") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25 mx-0 md:mx-5" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:mx-8",
                      attrs: { label: _vm.$t("invoices.label_range_dates") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.optionRangeTimeFromDateTodate,
                          searchable: true,
                          "track-by": "name",
                          "show-labels": false,
                          "allow-empty": true,
                          label: "name",
                        },
                        on: { input: _vm.calculateRangeFromDateToDate },
                        model: {
                          value: _vm.selectRangeFromDateToDate,
                          callback: function ($$v) {
                            _vm.selectRangeFromDateToDate = $$v
                          },
                          expression: "selectRangeFromDateToDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("invoices.no_invoices"),
            description: _vm.$t("invoices.list_of_invoices"),
          },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/invoices/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("invoices.new_invoice")) +
                  "\n      "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _vm.IsArchivedActived == false
                ? _c("span", [
                    _c(
                      "p",
                      { staticClass: "absolute -mt-3 md:mt-12 text-sm" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.showing")) +
                            ": "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.invoices.length))]),
                        _vm._v(
                          "\n\n            " +
                            _vm._s(_vm.$t("general.of")) +
                            " "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.totalInvoices))]),
                      ]
                    ),
                  ])
                : _vm.IsArchivedActived == true
                ? _c("span", [
                    _c("p", { staticClass: "absolute -mt-5 text-sm" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.showing")) +
                          ": "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.archived.length))]),
                      _vm._v(
                        "\n\n            " + _vm._s(_vm.$t("general.of")) + " "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.totalArchived))]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  ref: "tabsStatusInvoice",
                  staticClass: "hidden md:inline",
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter },
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.due"), filter: "DUE" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.overdue"),
                      filter: "OVERDUE",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.completed"),
                      filter: "COMPLETED",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.save_as_draft"),
                      filter: "SAVE_DRAFT",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.archived"),
                      filter: "ARCHIVED",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-transition",
            { attrs: { type: "fade" } },
            [
              _vm.selectedInvoices.length
                ? _c(
                    "sw-dropdown",
                    { staticClass: "absolute float-right -mt-3 md:mt-2" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                          staticStyle: { "font-size": "17px" },
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.actions")) +
                              "\n            "
                          ),
                          _c("chevron-down-icon", { staticClass: "h-5" }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown-item",
                        { on: { click: _vm.removeMultipleInvoices } },
                        [
                          _c("trash-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoices && _vm.invoices.length,
                  expression: "invoices && invoices.length",
                },
              ],
              staticClass:
                "absolute z-10 items-center pl-4 select-none md:mt-10",
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllInvoices },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden mt-7",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm",
                },
                on: { change: _vm.selectAllInvoices },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "inv_tab_sm -mt-6 md:mt-3",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm",
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function ($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField",
                            },
                          }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.number")))]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "div",
                              [
                                row.deleted_at != null
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "font-medium text-primary-500",
                                        attrs: {
                                          to: {
                                            path: `invoices/${row.id}/view/${row.deleted_at}`,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.invoice_number) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "font-medium text-primary-500",
                                        attrs: {
                                          to: {
                                            path: `invoices/${row.id}/view`,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.invoice_number) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("span", [_vm._v(_vm._s(row.invoice_number))]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.customer"),
                  width: "20%",
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.customer"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "div",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "font-medium text-primary-500 truncate md:overflow-visible md:whitespace-normal",
                                    attrs: {
                                      to: {
                                        path: `customers/${row.user_id}/view`,
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "hidden md:relative",
                                    staticStyle: { "font-size": "15px" },
                                  },
                                  [_vm._v(" " + _vm._s(row.user.customcode))]
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "truncate md:overflow-visible md:whitespace-normal",
                                  staticStyle: { "font-size": "15px" },
                                },
                                [_vm._v(_vm._s(row.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "hidden md:relative",
                                  staticStyle: { "font-size": "15px" },
                                },
                                [_vm._v(" " + _vm._s(row.user.customcode))]
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("estimates.status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            staticClass: "px-3 py-1",
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.status) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.paid_status"),
                  "sort-as": "paid_status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.paid_status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.paid_status.replace("_", " ")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.total"),
                  "sort-as": "total",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v("Total")]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.amount_due"),
                  "sort-as": "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.amount_due"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, filterable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.IsArchivedActived != true
                              ? _c(
                                  "span",
                                  [
                                    _vm.permissionModule.update
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin &&
                                            row.noeditable == 0
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoices/${row.id}/edit`,
                                                    },
                                                  },
                                                  [
                                                    _c("pencil-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("general.edit")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.read
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoices/${row.id}/view`,
                                                    },
                                                  },
                                                  [
                                                    _c("eye-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.view"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoice/${row.id}/view`,
                                                    },
                                                  },
                                                  [
                                                    _c("eye-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.view"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (row.status == "DRAFT" ||
                                      row.status == "SAVE_DRAFT") &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.send_invoice"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status != "DRAFT"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendSMSInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.send_invoice_sms"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status != "DRAFT" &&
                                    row.status != "SAVE_DRAFT" &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.resend_invoice"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (row.status == "DRAFT" ||
                                      row.status == "SAVE_DRAFT") &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.markInvoiceAsSent(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("check-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.mark_as_sent"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.createPayments
                                      ? _c(
                                          "div",
                                          [
                                            row.status === "SENT" ||
                                            row.status === "VIEWED" ||
                                            (row.status === "OVERDUE" &&
                                              _vm.isSuperAdmin)
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `/admin/payments/${row.id}/invoices`,
                                                    },
                                                  },
                                                  [
                                                    _c("credit-card-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payments.record_payment"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.createPayments &&
                                    _vm.activate_pay_button
                                      ? _c(
                                          "div",
                                          [
                                            row.status === "SENT" ||
                                            row.status === "VIEWED" ||
                                            (row.status === "OVERDUE" &&
                                              _vm.isSuperAdmin)
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `/admin/payments/multiple/customer/${row.user_id}/invoice/${row.id}/create`,
                                                    },
                                                  },
                                                  [
                                                    _c("credit-card-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payments.pos_record_payment"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            attrs: {
                                              "tag-name": "router-link",
                                              to: `#`,
                                            },
                                          },
                                          [
                                            _c("credit-card-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "payments.record_payment"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.create
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onCloneInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "document-duplicate-icon",
                                                      {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.clone_invoice"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.delete
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin &&
                                            row.status != "COMPLETED" &&
                                            row.paid_status === "UNPAID"
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("trash-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.delete"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.delete
                                      ? _c("div", [
                                          row.inv_avalara_bool
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeInvoiceAvalara(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("trash-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.invoice_delete_avalara"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.IsArchivedActived
                              ? _c(
                                  "span",
                                  [
                                    row.is_recuperable
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.Restore(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("save-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("general.restore")
                                                ) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.inv_avalara_bool == 1 &&
                                    row.avalara_invoice != null &&
                                    row.avalara_invoice.status != 3 &&
                                    row.avalara_invoice.status != 2
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.AvalaraVoid(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("trash-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                  Avalara Void\n                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inv_tab" },
        [
          _c(
            "sw-table-component",
            {
              ref: "table2",
              staticClass: "inv_tab mt-6 md:mt-3",
              staticStyle: { display: "grid" },
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
                "table-id": "table" + 0,
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "relative block" },
                          [
                            _c("sw-checkbox", {
                              attrs: {
                                id: row.id,
                                value: row.id,
                                variant: "primary",
                                size: "sm",
                              },
                              model: {
                                value: _vm.selectField,
                                callback: function ($$v) {
                                  _vm.selectField = $$v
                                },
                                expression: "selectField",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.total"),
                  "sort-as": "total",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", {
                          staticClass: "div1",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "div2",
                            attrs: {
                              sortable: true,
                              label: _vm.$t("invoices.amount_due"),
                              "sort-as": "due_amount",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "div2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.due_amount,
                                    row.user.currency
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "div3",
                            attrs: {
                              sortable: true,
                              label: _vm.$t("invoices.customer"),
                              show: "name",
                            },
                          },
                          [
                            _vm.permissionModule.read
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "font-medium truncate md:overflow-visible md:whitespace-normal",
                                        attrs: {
                                          to: {
                                            path: `customers/${row.user_id}/view`,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "hidden md:relative",
                                        staticStyle: { "font-size": "15px" },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.user.customcode)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "truncate md:overflow-visible md:whitespace-normal",
                                      staticStyle: { "font-size": "15px" },
                                    },
                                    [_vm._v(_vm._s(row.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "hidden md:relative",
                                      staticStyle: { "font-size": "15px" },
                                    },
                                    [_vm._v(" " + _vm._s(row.user.customcode))]
                                  ),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "div4",
                            attrs: {
                              sortable: true,
                              label: _vm.$t("invoices.date"),
                              "sort-as": "invoice_date",
                              show: "formattedInvoiceDate",
                            },
                          },
                          [
                            _c("p", { staticClass: "divd" }, [
                              _vm._v(
                                "Due: " + _vm._s(row.formattedInvoiceDate)
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "custom-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleRowVisibility(row.id, 0)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n    " +
                                    _vm._s(
                                      _vm.isRowVisible(row.id, 0)
                                        ? "Less"
                                        : "More"
                                    ) +
                                    "\n    "
                                ),
                                _c("span", { staticClass: "arrow" }, [
                                  !_vm.isRowVisible(row.id, 0)
                                    ? _c(
                                        "svg",
                                        {
                                          staticClass: "icon icon-chevron-down",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M19 9l-7 7-7-7" },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "svg",
                                        {
                                          staticClass: "icon icon-chevron-up",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M5 15l7-7 7 7" },
                                          }),
                                        ]
                                      ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          {
                            class: _vm.isRowVisible(row.id, 0)
                              ? "table-row-visible"
                              : "table-row-hidden",
                          },
                          [
                            _c("p", { staticClass: "p2" }, [
                              _vm._v("Status: "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "div6" },
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        row.status
                                      ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.status) +
                                        " \n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, filterable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.IsArchivedActived != true
                              ? _c(
                                  "span",
                                  [
                                    _vm.permissionModule.update
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin &&
                                            row.noeditable == 0
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoices/${row.id}/edit`,
                                                    },
                                                  },
                                                  [
                                                    _c("pencil-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t("general.edit")
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.read
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoices/${row.id}/view`,
                                                    },
                                                  },
                                                  [
                                                    _c("eye-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.view"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `invoice/${row.id}/view`,
                                                    },
                                                  },
                                                  [
                                                    _c("eye-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.view"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (row.status == "DRAFT" ||
                                      row.status == "SAVE_DRAFT") &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.send_invoice"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status != "DRAFT"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendSMSInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.send_invoice_sms"
                                                  )
                                                ) +
                                                "\n          "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status != "DRAFT" &&
                                    row.status != "SAVE_DRAFT" &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendInvoice(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("paper-airplane-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.resend_invoice"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (row.status == "DRAFT" ||
                                      row.status == "SAVE_DRAFT") &&
                                    _vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.markInvoiceAsSent(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("check-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoices.mark_as_sent"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.createPayments
                                      ? _c(
                                          "div",
                                          [
                                            row.status === "SENT" ||
                                            row.status === "VIEWED" ||
                                            (row.status === "OVERDUE" &&
                                              _vm.isSuperAdmin)
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `/admin/payments/${row.id}/create`,
                                                    },
                                                  },
                                                  [
                                                    _c("credit-card-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payments.record_payment"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.createPayments &&
                                    _vm.activate_pay_button
                                      ? _c(
                                          "div",
                                          [
                                            row.status === "SENT" ||
                                            row.status === "VIEWED" ||
                                            (row.status === "OVERDUE" &&
                                              _vm.isSuperAdmin)
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    attrs: {
                                                      "tag-name": "router-link",
                                                      to: `/admin/payments/multiple/customer/${row.user_id}/invoice/${row.id}/create`,
                                                    },
                                                  },
                                                  [
                                                    _c("credit-card-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payments.pos_record_payment"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isSuperAdmin
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            attrs: {
                                              "tag-name": "router-link",
                                              to: `#`,
                                            },
                                          },
                                          [
                                            _c("credit-card-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "payments.record_payment"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.create
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onCloneInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "document-duplicate-icon",
                                                      {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.clone_invoice"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.delete
                                      ? _c(
                                          "div",
                                          [
                                            _vm.isSuperAdmin &&
                                            row.status != "COMPLETED" &&
                                            row.paid_status === "UNPAID"
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("trash-icon", {
                                                      staticClass:
                                                        "h-5 mr-3 text-gray-600",
                                                    }),
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.delete"
                                                          )
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.delete
                                      ? _c("div", [
                                          row.inv_avalara_bool
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeInvoiceAvalara(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("trash-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.invoice_delete_avalara"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.IsArchivedActived
                              ? _c(
                                  "span",
                                  [
                                    row.is_recuperable
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.Restore(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("save-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("general.restore")
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.inv_avalara_bool == 1 &&
                                    row.avalara_invoice != null &&
                                    row.avalara_invoice.status != 3 &&
                                    row.avalara_invoice.status != 2
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.AvalaraVoid(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("trash-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              Avalara Void\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }