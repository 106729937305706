var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/item-groups",
                  title: _vm.$t("item_groups.item_group"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.itemGroup ? _vm.itemGroup.name : "",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/item-groups`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/item-groups/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeGroup(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("item_groups.basic_info")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("general.name")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.itemGroup ? _vm.itemGroup.name : "") +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.item_category_name != null
              ? _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.name")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item_category_name) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("general.description")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.itemGroup ? _vm.itemGroup.description : ""
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.itemGroup ? _vm.itemGroup.description : "") +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("items.title")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("sw-empty-table-placeholder", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyTable,
                  expression: "showEmptyTable",
                },
              ],
              attrs: {
                title: _vm.$t("item_groups.no_items"),
                description: _vm.$t("item_groups.list_of_items"),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showEmptyTable,
                    expression: "!showEmptyTable",
                  },
                ],
                staticClass: "mt-5",
              },
              [
                _c("table", { staticClass: "w-full text-center item-table" }, [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "50%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "50%" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "thead",
                    {
                      staticClass:
                        "bg-white border border-gray-200 border-solid",
                    },
                    [
                      _c(
                        "th",
                        {
                          staticClass:
                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _c("span", { staticClass: "pl-12" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$tc("items.item", 2)) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass:
                            "px-5 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _c("span", { staticClass: "pr-10" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("item_groups.item.price")) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.itemGroup.items, function (item, index) {
                      return _c("tr", { staticClass: "border" }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-5 py-4 text-left align-top border-b border-gray-200 border-solid",
                          },
                          [
                            _c("div", { staticClass: "items-left text-sm" }, [
                              _c("span", { staticClass: "pl-6" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.name) +
                                    "\n                  "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-5 py-4 text-right align-top border-b border-gray-200 border-solid",
                          },
                          [
                            _c("div", { staticClass: "items-center text-sm" }, [
                              _c("span", {
                                staticClass: "pr-10",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      item.price,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }