var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-destination" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "px-8 py-8 sm:p-6" }, [
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "p",
                { staticClass: "p-0 mb-0 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("general.add_group")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-switch", {
                model: {
                  value: _vm.add_or_delete_bool,
                  callback: function ($$v) {
                    _vm.add_or_delete_bool = $$v
                  },
                  expression: "add_or_delete_bool",
                },
              }),
              _vm._v(" "),
              _vm.add_or_delete_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        error: _vm.statusError,
                        label: _vm.$t("invoices.status"),
                      },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.status.$error,
                          options: _vm.status,
                          "group-select": false,
                          searchable: true,
                          "show-labels": false,
                          placeholder: "Choose an option",
                          "allow-empty": false,
                          "group-values": "options",
                          "group-label": "label",
                          "track-by": "name",
                          label: "name",
                        },
                        model: {
                          value: _vm.formData.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.add_or_delete_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        error: _vm.prefixRateGroupsIdError,
                        label: "Custom Destination Groups",
                      },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.prefixrate_groups.$error,
                          options: _vm.prefixrate_groups,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: "Select Group",
                          multiple: true,
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.formData.prefixrate_groups,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "prefixrate_groups", $$v)
                          },
                          expression: "formData.prefixrate_groups",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.country_bool ? _c("br") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "p",
                {
                  staticClass: "p-0 mb-0 mt text-base leading-snug text-black",
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("corePbx.prefix_groups.category")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-switch", {
                model: {
                  value: _vm.category_bool,
                  callback: function ($$v) {
                    _vm.category_bool = $$v
                  },
                  expression: "category_bool",
                },
              }),
              _vm._v(" "),
              _vm.category_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4 mt-6",
                      attrs: { error: _vm.categoryError, required: "" },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          invalid: _vm.$v.formData.category.$error,
                          options: _vm.categories,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "corePbx.prefix_groups.select_a_category"
                          ),
                          label: "name",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.category.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "category", $$v)
                          },
                          expression: "formData.category",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.category_bool ? _c("br") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "p",
                { staticClass: "p-0 mb-0 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$tc("settings.company_info.country")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-switch", {
                model: {
                  value: _vm.country_bool,
                  callback: function ($$v) {
                    _vm.country_bool = $$v
                  },
                  expression: "country_bool",
                },
              }),
              _vm._v(" "),
              _vm.country_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4 mt-6",
                      attrs: { error: _vm.countryIdError },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          invalid: _vm.$v.formData.country_id.$error,
                          options: _vm.countries,
                          searchable: true,
                          "show-labels": false,
                          "max-height": 200,
                          placeholder: "Select a country",
                          label: "name",
                          "track-by": "id",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.country_id.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.country_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "country_id", $$v)
                          },
                          expression: "formData.country_id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.rate_bool ? _c("br") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c(
                "p",
                { staticClass: "p-0 mb-0 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.$tc("corePbx.internacional.rate_per_minutes")
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-switch", {
                model: {
                  value: _vm.rate_bool,
                  callback: function ($$v) {
                    _vm.rate_bool = $$v
                  },
                  expression: "rate_bool",
                },
              }),
              _vm._v(" "),
              _vm.rate_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4 mt-6",
                      attrs: { error: _vm.rateError, required: "" },
                    },
                    [
                      _c("sw-money", {
                        attrs: {
                          currency: _vm.defaultCurrency,
                          invalid: _vm.$v.formData.rate_per_minutes.$error,
                          name: "rate_per_minutes_selected",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.rate_per_minutes.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.rate_per_minutes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "rate_per_minutes", $$v)
                          },
                          expression: "formData.rate_per_minutes",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.order_bool ? _c("br") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "p",
                { staticClass: "p-0 mb-0 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("general.order")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-switch", {
                model: {
                  value: _vm.order_bool,
                  callback: function ($$v) {
                    _vm.order_bool = $$v
                  },
                  expression: "order_bool",
                },
              }),
              _vm._v(" "),
              _vm.order_bool
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4 mt-6",
                      attrs: { required: "", error: _vm.orderError },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.order.$error,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.order.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.order,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "order", $$v)
                          },
                          expression: "formData.order",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeItemModal },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.cancel")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("general.save")) + "\n        "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }