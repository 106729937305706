var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("invoices.no_invoices"),
            description: _vm.$t("invoices.list_of_invoices"),
          },
        },
        [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative mt-5",
        },
        [
          _c(
            "p",
            {
              staticClass: "absolute right-0 m-0 text-sm",
              staticStyle: { top: "50px" },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.$t("general.showing")) + ": "),
              _c("b", [_vm._v(_vm._s(_vm.archived.length))]),
              _vm._v("\n\n      " + _vm._s(_vm.$t("general.of")) + " "),
              _c("b", [_vm._v(_vm._s(_vm.totalArchived))]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.number")))]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.invoice_number) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.customer"),
                  width: "20%",
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.Restore(row.id)
                              },
                            },
                          },
                          [
                            _c("save-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.restore")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closeArchivedModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }