var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-96": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("bandwidth.desconnect") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("bandwidth.desconnect"),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "actions" }, [
            _c(
              "div",
              { staticClass: "mr-3 hidden xl:block" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.toggleListCustomers },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tickets.departaments.menu")) +
                        "\n          "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", [_vm._v("Coming Soon")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }