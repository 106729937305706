var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-card", { staticClass: "mb-6" }, [
        _c("h2", { staticClass: "text-xl font-semibold mb-6" }, [
          _vm._v("Quick Add"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex space-x-2" },
          _vm._l([10, 25, 50, 100], function (amount) {
            return _c(
              "button",
              {
                key: amount,
                staticClass: "quick-add-btn w-20",
                on: {
                  click: function ($event) {
                    return _vm.quickAddCredit(amount)
                  },
                },
              },
              [_vm._v("\n        $" + _vm._s(amount) + "\n      ")]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("sw-card", { staticClass: "mb-6" }, [
        _c("h2", { staticClass: "text-xl font-semibold mb-6" }, [
          _vm._v("Credit Summary"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-4" },
          [
            _c("div", { staticClass: "flex items-center justify-between" }, [
              _c(
                "label",
                {
                  staticClass: "text-lg font-medium text-gray-700",
                  attrs: { for: "amountToAdd" },
                },
                [_vm._v("Amount to Add")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "relative rounded-md shadow-sm w-1/2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none",
                    },
                    [
                      _c("span", { staticClass: "text-gray-500 sm:text-lg" }, [
                        _vm._v("$"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formattedAmount,
                        expression: "formattedAmount",
                      },
                    ],
                    ref: "amountInput",
                    staticClass:
                      "focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-lg border-gray-300 rounded-md text-right",
                    attrs: {
                      type: "text",
                      name: "amountToAdd",
                      id: "amountToAdd",
                      placeholder: "0.00",
                    },
                    domProps: { value: _vm.formattedAmount },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.formattedAmount = $event.target.value
                        },
                        _vm.handleInput,
                      ],
                      keydown: _vm.handleKeydown,
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.fees.length > 0
              ? _c(
                  "div",
                  { staticClass: "space-y-2" },
                  _vm._l(_vm.fees, function (fee) {
                    return _c(
                      "div",
                      {
                        key: fee.id,
                        staticClass:
                          "flex justify-between items-center text-sm w-full",
                      },
                      [
                        fee.type == "fixed"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center w-full",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-gray-600 text-left" },
                                  [_vm._v(_vm._s(fee.name))]
                                ),
                                _vm._v(" "),
                                fee.type == "fixed"
                                  ? _c("div", {
                                      staticClass: "text-right",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            fee.amount,
                                            _vm.defaultCurrency
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        fee.type == "percentage"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center w-full",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-gray-600 text-left" },
                                  [_vm._v(_vm._s(fee.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "font-medium text-right" },
                                  [_vm._v(_vm._s(fee.amount / 100) + " %")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-between items-center font-semibold text-lg pt-2 border-t",
              },
              [
                _c("span", [_vm._v("Total Charge:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("$" + _vm._s(_vm.formatCurrency(_vm.summaryAmount))),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.cardSaveText && _vm.Last4digittext
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-info border border-gray-300 rounded-lg p-2 flex items-center",
                  },
                  [
                    _vm.creditCardImages[_vm.cardSaveText]
                      ? _c("img", {
                          staticClass: "h-5 mr-2",
                          attrs: {
                            src: _vm.creditCardImages[_vm.cardSaveText].src,
                            width: _vm.creditCardImages[_vm.cardSaveText].width,
                            alt: "Credit Card Logo",
                          },
                        })
                      : _c(
                          "svg",
                          {
                            staticClass: "h-5 mr-2",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              width: "24px",
                              height: "24px",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z",
                              },
                            }),
                          ]
                        ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.cardSaveText) +
                          " ending in " +
                          _vm._s(_vm.Last4digittext)
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "w-full",
                attrs: {
                  disabled: _vm.isPayDisabled,
                  variant: "primary-outline",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleButtonClick()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }