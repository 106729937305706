var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("general.corepos_history") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("general.corepos_history"),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-50",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.customer", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("general.item") },
                    },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.items,
                          searchable: true,
                          "show-labels": true,
                          multiple: false,
                          placeholder: _vm.$t("general.item"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.filters.item,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "item", $$v)
                          },
                          expression: "filters.item",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$t("coreposhistory.document_number"),
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.document_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "document_number", $$v)
                            },
                            expression: "filters.document_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-50",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("general.user") },
                    },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.usersOptions,
                          searchable: true,
                          "show-labels": true,
                          multiple: false,
                          placeholder: _vm.$t("general.select_user"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.filters.user,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "user", $$v)
                          },
                          expression: "filters.user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$t("coreposhistory.action") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.actions,
                          "group-select": false,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("coreposhistory.action"),
                          "allow-empty": false,
                          "track-by": "name",
                          label: "name",
                        },
                        model: {
                          value: _vm.filters.action,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "action", $$v)
                          },
                          expression: "filters.action",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.from") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.to") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-50",
                staticStyle: { "margin-left": "1em", "margin-right": "1em" },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("estimates.no_estimates"),
            description: _vm.$t("estimates.list_of_estimates"),
          },
        },
        [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.totalLine))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalRecord))]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative table-container" },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.record_date"),
                  show: "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.record_date"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.formattedCreatedAt) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: true,
                  label: _vm.$t("coreposhistory.record_type"),
                  show: "record_type",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.record_type"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(row.formattedDocumentNumberType)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.document_number"),
                  show: "document_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("coreposhistory.document_number"))
                          ),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.document_number) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.customer"),
                  show: "customer_id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.customer"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.formattedDocumentCustomer) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.user"),
                  show: "creator_id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.user"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.formattedDocumentCreator) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.cashregister"),
                  show: "cash_register_id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.cashregister"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.formattedDocumentCashRegister) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("coreposhistory.action"),
                  show: "action",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.action"))),
                        ]),
                        _vm._v(" "),
                        row.formattedDocumentColorAction == "V"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            row.formattedDocumentLangAction
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.formattedDocumentColorAction == "P"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            row.formattedDocumentLangAction
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.formattedDocumentColorAction == "R"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            row.formattedDocumentLangAction
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: true,
                  label: _vm.$t("coreposhistory.information"),
                  show: "information",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("coreposhistory.information"))),
                        ]),
                        _vm._v(" "),
                        row.item_id != null
                          ? _c("div", [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.item")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(
                                  " " + _vm._s(row.formattedDocumentItems)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.cant")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.item_quantity)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.price")) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.item_price,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("p"),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.total")) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.item_total,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("p"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.tax_id != null
                          ? _c("div", [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.tax")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(
                                  " " + _vm._s(row.formattedDocumentTaxtype)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("coreposhistory.tax_percent")
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.tax_type_percent)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("coreposhistory.tax_amount")
                                      ) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.tax_type_amount,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("p"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.discount_type != null && row.discount_amount != null
                          ? _c("div", [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("coreposhistory.discount")
                                      ) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("coreposhistory.discount_type")
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.discount_type)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("coreposhistory.discount_amount")
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.discount_amount) + " "),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.tables != null
                          ? _c("div", [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.tables")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.tables)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.cant")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.qty_persons) + " "),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.notes != null
                          ? _c("div", [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("coreposhistory.notes")) +
                                      ": "
                                  ),
                                ]),
                                _vm._v(" " + _vm._s(row.notes)),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }