var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
    },
    [
      _c("div", { staticClass: "heading-section" }, [
        _c(
          "p",
          {
            staticClass:
              "text-2xl not-italic font-semibold leading-7 text-black",
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("customers.pbxservices_resume")) +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12" }, [
          this.parameters
            ? _c(
                "div",
                { staticClass: "mt-8" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("packages.name")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  this.parameters
                                    ? this.parameters.package.pbx_package_name
                                    : ""
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("packages.status")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        }),
                        this.parameters
                          ? _c("div", [
                              this.parameters.status === "A"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.$t("general.active")) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.parameters.status === "P"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.$t("general.pending")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.parameters.status == "S"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "SENT"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "SENT"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n           " +
                                              _vm._s(
                                                _vm.$t("general.suspended")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.parameters.status == "C"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.$t("general.cancelled")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.term")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  this.parameters ? this.parameters.term : ""
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("invoices.discount")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              \n\n" +
                                _vm._s(
                                  this.parameters &&
                                    this.parameters.allow_discount
                                    ? _vm.$t("general.yes")
                                    : _vm.$t("general.not")
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.date_begin")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    this.parameters.date_begin
                                  )
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isEdit
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("customers.date_renow")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      this.parameters.renewal_date.length > 0
                                        ? this.parameters.renewal_date
                                        : "None"
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.server")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  this.parameters.package.server.server_label
                                    .length > 0
                                    ? this.parameters.package.server
                                        .server_label
                                    : "None"
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.tenant")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  this.parameters.tenant.name.length > 0
                                    ? this.parameters.tenant.name
                                    : "None"
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.type_service")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        }),
                        this.parameters
                          ? _c("div", [
                              this.parameters.package.status_payment.length > 0
                                ? _c("div", [
                                    this.parameters.package.status_payment ==
                                    "postpaid"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("packages.item.postpaid")
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    this.parameters.package.status_payment ==
                                    "prepaid"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("packages.item.prepaid")
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  this.parameters.package.extensions
                    ? _c(
                        "div",
                        [
                          _c("sw-divider", { staticClass: "my-8" }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-gray-500 uppercase sw-section-title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("customers.pbxservices_extensions")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full mb-8 bg-white rounded p-8 relative package-details",
                            },
                            [
                              _c(
                                "sw-table-component",
                                {
                                  ref: "table",
                                  attrs: {
                                    data: _vm.fetchDataExtension,
                                    "show-filter": false,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t(
                                        "corePbx.extensions.extension"
                                      ),
                                      show: "name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.extension"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.ext
                                                        ? row.ext
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              row.date_prorate !== null
                                                ? _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "whitespace-nowrap",
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Prorate Date: "
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatDate"
                                                              )(
                                                                row.date_prorate
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "whitespace-nowrap",
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Prorate Price: "
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$utils.formatMoney(
                                                                row.prorate,
                                                                _vm.defaultCurrency
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      482145210
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("corePbx.extensions.email"),
                                      show: "email",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.email"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.email
                                                        ? row.email
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2754865583
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t(
                                        "corePbx.extensions.location"
                                      ),
                                      show: "location",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.location"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.location
                                                        ? row.location
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      438747550
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t(
                                        "corePbx.extensions.ua_fullname"
                                      ),
                                      show: "ua_fullname",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.ua_fullname"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.ua_fullname
                                                        ? row.ua_fullname
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2157410716
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t(
                                        "corePbx.extensions.extension_price"
                                      ),
                                      show: "price",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.extension_price"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.isEdit
                                                ? _c("span", [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            row.price * 100,
                                                            _vm.defaultCurrency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ])
                                                : _c("span", [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            row.profile_rate *
                                                              100,
                                                            _vm.defaultCurrency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2915688380
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t(
                                        "corePbx.extensions.status"
                                      ),
                                      show: "status",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "corePbx.extensions.status"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.status
                                                        ? row.status
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      552649463
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.parameters.package.did
                    ? _c(
                        "div",
                        [
                          _c("sw-divider", { staticClass: "my-8" }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-gray-500 uppercase sw-section-title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("did.title")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-table-component",
                            {
                              ref: "table",
                              attrs: {
                                data: _vm.fetchDataDID,
                                "show-filter": false,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("corePbx.did.did_channel"),
                                  show: "number",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.did.did_channel"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  row.number
                                                    ? row.number
                                                    : "Not selected"
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          row.date_prorate !== null
                                            ? _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "whitespace-nowrap",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Prorate Date: "),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("formatDate")(
                                                            row.date_prorate
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "whitespace-nowrap",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Prorate Price: "),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            row.prorate,
                                                            _vm.defaultCurrency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  997169671
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("corePbx.did.destination"),
                                  show: "ext",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                row.ext == null
                                                  ? "Ext Not Found"
                                                  : row.ext
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2309574313
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("corePbx.did.type"),
                                  show: "type",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                row.type == null
                                                  ? "No Type"
                                                  : row.type
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2563373530
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("corePbx.did.did_rate"),
                                  show: "rate_per_minute",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _vm.isEdit
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.price != undefined
                                                        ? row.price
                                                        : row.rate_per_minute
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.rate_per_minute ===
                                                        null
                                                        ? "No Rate"
                                                        : row.rate_per_minute
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1171518774
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("corePbx.did.status"),
                                  show: "status",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                row.status == null
                                                  ? "Not Selected"
                                                  : row.status
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  475214751
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.parameters.custom_app_rate_id != null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-full mb-8 mt-3 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("general.warning_service15"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-table-component",
                            {
                              ref: "table",
                              attrs: {
                                data: this.corePbxServicesIncludedData
                                  .pbx_services_app_rate,
                                "show-filter": false,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: "APPS",
                                  show: "app_name",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [_vm._v(_vm._s("APPS"))]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(row.app_name) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  749759583
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: "TOTAL",
                                  show: "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [_vm._v(_vm._s("TOTAL"))]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(row.total) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3000824788
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("general.warning_service16"),
                                  show: "in_use",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "general.warning_service16"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(row.in_use) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  483713033
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("general.warning_service18"),
                                  show: "price",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "general.warning_service18"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.price * 100,
                                                  _vm.defaultCurrency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3798621047
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("general.warning_service19"),
                                  show: "quantity",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "general.warning_service19"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(row.quantity) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3270366310
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: "COSTO",
                                  show: "costo",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [_vm._v(_vm._s("Total"))]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.price *
                                                    row.quantity *
                                                    100,
                                                  _vm.defaultCurrency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2123947202
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "my-8" }),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("items.title")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-8" }, [
                    _c(
                      "table",
                      { staticClass: "w-full text-center item-table" },
                      [
                        _c("colgroup", [
                          _c("col", { staticStyle: { width: "40%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "10%" } }),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "15%" } }),
                          _vm._v(" "),
                          _vm.discountPerItem === "YES"
                            ? _c("col", { staticStyle: { width: "15%" } })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("col", { staticStyle: { width: "15%" } }),
                        ]),
                        _vm._v(" "),
                        _c("thead", { staticClass: "bg-white" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                            },
                            [
                              _c("span", {}, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$tc("items.item", 1)) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700",
                            },
                            [
                              _c("span", {}, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("item_groups.item.qty")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                            },
                            [
                              _c("span", {}, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("item_groups.item.price")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.discountPerItem === "YES"
                            ? _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("invoices.item.discount")) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700",
                            },
                            [
                              _c("span", {}, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("invoices.item.total")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.pbxService.itemsIncluded,
                            function (item, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass:
                                    "border-t border-gray-200 border-solid",
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "p-0 text-left align-top",
                                      attrs: { colspan: "5" },
                                    },
                                    [
                                      _c("table", { staticClass: "w-full" }, [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "10%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "15%" },
                                          }),
                                          _vm._v(" "),
                                          _vm.discountPerItem === "YES"
                                            ? _c("col", {
                                                staticStyle: { width: "15%" },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "15%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-5 py-4 text-left align-top",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex justify-start",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name)),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-5 py-4 text-right align-top",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.isView
                                                        ? item.pivot.quantity
                                                        : item.quantity
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-5 py-4 text-left align-top",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex flex-col",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-auto flex-fill bd-highlight",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "relative w-full",
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.$utils.formatMoney(
                                                                      item.price,
                                                                      _vm.defaultCurrency
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.discountPerItem === "YES"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "px-5 py-4 text-left align-top",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex flex-col",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex flex-auto",
                                                            attrs: {
                                                              role: "group",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.discount
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "flex",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                  " +
                                                                    _vm._s(
                                                                      item.discount_type ===
                                                                        "fixed"
                                                                        ? _vm
                                                                            .defaultCurrency
                                                                            .symbol
                                                                        : "%"
                                                                    ) +
                                                                    "\n                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-5 py-4 text-right align-top",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex items-center justify-end text-sm",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$utils.formatMoney(
                                                              _vm.isView
                                                                ? item.pivot
                                                                    .total
                                                                : item.total,
                                                              _vm.defaultCurrency
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _c("sw-switch", {
                                                    staticClass: "relative",
                                                    staticStyle: {
                                                      top: "-10px",
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: { disabled: true },
                                                    model: {
                                                      value:
                                                        item.end_period_act,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "end_period_act",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.end_period_act",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                    "margin-left": "15px",
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "p-0 mb-1 text-base leading-snug text-black",
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "packages.end_period_act"
                                                              )
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "p-0 m-0 text-xs leading-tight text-gray-500",
                                                        staticStyle: {
                                                          "max-width": "480px",
                                                          "margin-left": "10px",
                                                          "margin-bottom":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "packages.end_period_act_desp"
                                                              )
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _c("sw-input", {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "packages.end_period_num"
                                                      ),
                                                      disabled: true,
                                                      type: "number",
                                                      min: "1",
                                                      small: "",
                                                      onkeydown:
                                                        "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                    },
                                                    model: {
                                                      value:
                                                        item.end_period_number,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "end_period_number",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.end_period_number",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.taxPerItem === "YES"
                                            ? _c(
                                                "tr",
                                                { staticClass: "tax-tr" },
                                                [
                                                  _c("td", {
                                                    staticClass:
                                                      "px-5 py-4 text-left align-top",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "px-5 py-4 text-left align-top",
                                                      attrs: { colspan: "4" },
                                                    },
                                                    _vm._l(
                                                      item.taxes,
                                                      function (tax, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "flex items-center justify-between mb-3",
                                                          },
                                                          [
                                                            tax.amount
                                                              ? _c("div", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex items-center",
                                                                      staticStyle:
                                                                        {
                                                                          flex: "4",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "pr-2 mb-0",
                                                                          attrs:
                                                                            {
                                                                              align:
                                                                                "right",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "general.tax"
                                                                                )
                                                                              ) +
                                                                              "\n                                  "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              "(" +
                                                                                tax.name +
                                                                                " - " +
                                                                                tax.percent +
                                                                                "%)"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-sm text-right ms-1",
                                                                          staticStyle:
                                                                            {
                                                                              flex: "3",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.$utils.formatMoney(
                                                                                    tax.amount,
                                                                                    _vm.defaultCurrency
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "my-8" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("corePbx.charges")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-slide" },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "table",
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.additionalChargesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.name"),
                                      show: "description",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc("pbx_services.name")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(row.description)),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                row.isNew
                                                  ? _c("span", [
                                                      _vm._v("New Charge"),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2639291134
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.price"),
                                      show: "amount",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "pbx_services.price"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.defaultCurrency.symbol +
                                                      " " +
                                                      row.amount
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1897716022
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.type_from"),
                                      show: "type_from",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.template"),
                                      show: "profile_name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.isEdit
                                    ? _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          label: _vm.$tc(
                                            "pbx_services.quantity"
                                          ),
                                          show: "quantity",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isEdit
                                    ? _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          label: _vm.$tc("pbx_services.price"),
                                          show: "total",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (row) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "pbx_services.total"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.defaultCurrency
                                                          .symbol +
                                                          " " +
                                                          row.total
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3171922327
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown no-click",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("general.actions")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3616591436
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                                },
                                [
                                  _c("div", { staticClass: "w-full lg:w-1/2" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-between w-full",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$t("pbx_services.count")
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(this.chargesCount)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$t("pbx_services.total")
                                                  ) +
                                                  "\n                          " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pbx_services.amount"
                                                    )
                                                  ) +
                                                  ":\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center text-lg uppercase text-primary-400",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.defaultCurrency
                                                        .symbol +
                                                        " " +
                                                        this.aditionalCharges.toFixed(
                                                          2
                                                        )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
                    },
                    [
                      _c("div", { staticClass: "w-full lg:w-1/2" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("customers.price")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.price * 100,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("customers.total_apps")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.totalCustomAppRate * 100,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("customers.total_items"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.totalItems * 100,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("customers.cap_total")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.capTotal) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t("customers.extension_qty")
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  !_vm.isView
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.corePbxServicesIncludedData.ext
                                              .length
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isView
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.parameters.extensions.length
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("customers.extension_price"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          this.extensionsPrice * 100,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("customers.did_qty")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  !_vm.isView
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            this.corePbxServicesIncludedData.did
                                              .length
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isView
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(this.parameters.did.length)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("customers.did_price")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          this.didPrice,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("customers.aditional_charges")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.aditionalCharges * 100,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("invoices.sub_total")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.subtotal,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          (_vm.discountPerItem === "NO" ||
                            _vm.discountPerItem === null) &&
                          this.parameters.allow_discount
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between w-full mb-10",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("invoices.discount"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center m-0 text-lg text-black",
                                      attrs: { role: "group" },
                                    },
                                    [
                                      this.corePbxServicesIncludedData
                                        .discount_calc === "fixed"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n" +
                                                _vm._s(
                                                  _vm.$t("general.fixed")
                                                ) +
                                                " - " +
                                                _vm._s(_vm.discount) +
                                                "\n"
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n" +
                                                _vm._s(
                                                  _vm.$t("general.percentage")
                                                ) +
                                                " - " +
                                                _vm._s(_vm.discount) +
                                                "\n"
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n" +
                                            _vm._s(
                                              this.corePbxServicesIncludedData
                                                .discount_calc === "fixed"
                                                ? _vm.defaultCurrency.symbol
                                                : "%"
                                            ) +
                                            "\n"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.discountPerItem === "NO" ||
                            _vm.discountPerItem === null) &&
                          this.parameters.allow_discount
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between w-full mb-10",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("invoices.discount_app"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center m-0 text-lg text-black",
                                      attrs: { role: "group" },
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              _vm.discountapplied,
                                              _vm.defaultCurrency
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.discountPerItem === "NO" ||
                            _vm.discountPerItem === null) &&
                          this.parameters.allow_discount
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between w-full mb-10",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center m-0",
                                      staticStyle: { "margin-top": "2px" },
                                      attrs: { role: "group" },
                                    },
                                    [
                                      this.showdiscountwarning === true
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-red-500 italic font-bold text-sm",
                                            },
                                            [
                                              _vm._v(
                                                "\n" +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.message_discount_warning_r"
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.period_discount"
                                                    )
                                                  ) +
                                                    ": " +
                                                    _vm._s(this.discountbegin) +
                                                    " - " +
                                                    _vm._s(this.discountend)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text-green-500" },
                                            [
                                              _vm._v(
                                                "\n" +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.message_discount_warning_a"
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.period_discount"
                                                    )
                                                  ) +
                                                    ": " +
                                                    _vm._s(this.discountbegin) +
                                                    " - " +
                                                    _vm._s(this.discountend)
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pbxService.taxes.length
                            ? _c(
                                "div",
                                [
                                  _c("sw-divider", { staticClass: "my-8" }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n       \n\n                    " +
                                            _vm._s(
                                              _vm.$t("general.general_taxes")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.pbxService.taxes,
                                    function (tax, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "mt-4 flex items-center justify-between w-full text-sm",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(tax.name) +
                                                  " (" +
                                                  _vm._s(tax.percent) +
                                                  "%)\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center text-lg text-black",
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      tax.amount,
                                                      _vm.defaultCurrency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.itemTaxes.length
                            ? _c(
                                "div",
                                [
                                  _c("sw-divider", { staticClass: "my-8" }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("general.item_taxes")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.itemTaxes, function (tax, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "mt-4 flex items-center justify-between w-full text-sm",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "font-semibold leading-5 text-gray-500 uppercase",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(tax.name) +
                                                " (" +
                                                _vm._s(tax.percent) +
                                                "%)\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "flex items-center justify-center text-lg text-black",
                                          },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$utils.formatMoney(
                                                    tax.amount,
                                                    _vm.defaultCurrency
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("sw-divider", { staticClass: "my-8" }),
                          _vm._v(" "),
                          _vm.isEdit
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between w-full mb-10",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("general.total_prorateo")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "flex items-center justify-center m-0 text-lg text-black uppercase",
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              _vm.totalProrrate,
                                              _vm.defaultCurrency
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mb-10",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.parameters.term) +
                                      " Fee\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass:
                            "flex justify-center w-full md:w-auto align-bottom",
                          attrs: {
                            disabled: _vm.isLoading,
                            variant: "primary-outline",
                            size: "lg",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.back()
                            },
                          },
                        },
                        [
                          _c("arrow-left-icon", {
                            staticClass: "h-5 mr-2 -ml-1",
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.back")) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isView
                        ? _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full md:w-auto ml-4 align-bottom",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "button",
                                size: "lg",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.savePbxService()
                                },
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t("general.update")
                                      : _vm.$t("services.add_service")
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }