var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid gap-8 md:grid-cols-12" }, [
    _c(
      "div",
      { staticClass: "col-span-8 mt-12 md:col-span-4" },
      [
        _c(
          "div",
          { staticClass: "grid grid-cols-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: {
                  label: _vm.$t("reports.sales.date_range"),
                  error: _vm.dateRangeError,
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.dateRange,
                    "allow-empty": false,
                    "show-labels": false,
                  },
                  on: { input: _vm.onChangeDateRange },
                  model: {
                    value: _vm.selectedRange,
                    callback: function ($$v) {
                      _vm.selectedRange = $$v
                    },
                    expression: "selectedRange",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2" },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("reports.sales.from_date"),
                  error: _vm.fromDateError,
                },
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.from_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.from_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.from_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "from_date", $$v)
                    },
                    expression: "formData.from_date",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5 md:mt-0",
                attrs: {
                  label: _vm.$t("reports.sales.to_date"),
                  error: _vm.toDateError,
                },
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.to_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.to_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.to_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "to_date", $$v)
                    },
                    expression: "formData.to_date",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-1",
                attrs: { label: _vm.$t("reports.sales.report_type") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.reportTypes,
                    "allow-empty": false,
                    "show-labels": false,
                    placeholder: _vm.$t("reports.sales.report_type"),
                  },
                  on: { input: _vm.getInitialReport },
                  model: {
                    value: _vm.selectedType,
                    callback: function ($$v) {
                      _vm.selectedType = $$v
                    },
                    expression: "selectedType",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
          [
            _c(
              "sw-input-group",
              { staticClass: "mt-1", attrs: { label: "Paid Status" } },
              [
                _c("sw-select", {
                  staticClass: "mt-1",
                  attrs: {
                    options: _vm.paidStatusOptions,
                    searchable: true,
                    "show-labels": true,
                    multiple: true,
                    placeholder: "Select a Status",
                    label: "name",
                    "track-by": "value",
                  },
                  model: {
                    value: _vm.paidStatusSelected,
                    callback: function ($$v) {
                      _vm.paidStatusSelected = $$v
                    },
                    expression: "paidStatusSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.allow_invoice_form_pos
          ? _c(
              "div",
              { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-1",
                    attrs: { label: _vm.$t("core_pos.store") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.stores,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        "track-by": "id",
                        label: "name",
                        tabindex: 1,
                      },
                      model: {
                        value: _vm.stores_selected,
                        callback: function ($$v) {
                          _vm.stores_selected = $$v
                        },
                        expression: "stores_selected",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedType === "By Customer"
          ? _c("div", [
              _c(
                "div",
                { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
                [
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("expenses.customer") } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.customers,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: _vm.$t("customers.select_a_customer"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.customersSelected,
                          callback: function ($$v) {
                            _vm.customersSelected = $$v
                          },
                          expression: "customersSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("general.user") } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.users,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: _vm.$t("general.select_user"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.usersSelected,
                          callback: function ($$v) {
                            _vm.usersSelected = $$v
                          },
                          expression: "usersSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("customers.country") },
                    },
                    [
                      _vm.country
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-gray-400 cursor-pointer",
                              staticStyle: {
                                top: "38px",
                                right: "5px",
                                "z-index": "999999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.country = null
                                  _vm.states = []
                                  _vm.state = null
                                },
                              },
                            },
                            [_c("x-circle-icon", { staticClass: "h-5" })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("sw-select", {
                        attrs: {
                          options: _vm.countries,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_country"),
                          label: "name",
                          "track-by": "id",
                        },
                        on: {
                          select: function ($event) {
                            return _vm.countrySelected($event)
                          },
                        },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("customers.state") },
                    },
                    [
                      _vm.state
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-gray-400 cursor-pointer",
                              staticStyle: {
                                top: "38px",
                                right: "5px",
                                "z-index": "999999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.state = null
                                },
                              },
                            },
                            [_c("x-circle-icon", { staticClass: "h-5" })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("sw-select", {
                        attrs: {
                          options: _vm.states,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_state"),
                          label: "name",
                          "track-by": "id",
                        },
                        on: {
                          select: function ($event) {
                            return _vm.stateSelected($event)
                          },
                        },
                        model: {
                          value: _vm.state,
                          callback: function ($$v) {
                            _vm.state = $$v
                          },
                          expression: "state",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
                [
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("expenses.customer") } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.customersByItem,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: _vm.$t("customers.select_a_customer"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.customersByItemSelected,
                          callback: function ($$v) {
                            _vm.customersByItemSelected = $$v
                          },
                          expression: "customersByItemSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: "User" } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.usersByItem,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: "Select an user",
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.usersByItemSelected,
                          callback: function ($$v) {
                            _vm.usersByItemSelected = $$v
                          },
                          expression: "usersByItemSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("items.unit") } },
                    [
                      _c("sw-select", {
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.units,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: _vm.$t("items.select_a_unit"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.filters.unitsSelected,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "unitsSelected", $$v)
                          },
                          expression: "filters.unitsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: "Item Category" } },
                    [
                      _c("sw-select", {
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.itemsCategories,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: "Select an Item Category",
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.filters.itemsCategoriesSelected,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters,
                              "itemsCategoriesSelected",
                              $$v
                            )
                          },
                          expression: "filters.itemsCategoriesSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("items.items_groups") } },
                    [
                      _c("sw-select", {
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.itemsGroups,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: "Select an unit item group",
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.filters.itemsGroupsSelected,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "itemsGroupsSelected", $$v)
                          },
                          expression: "filters.itemsGroupsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: "Item" } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.items,
                          searchable: true,
                          "show-labels": true,
                          multiple: true,
                          placeholder: _vm.$t("customers.select_a_customer"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.itemsSelected,
                          callback: function ($$v) {
                            _vm.itemsSelected = $$v
                          },
                          expression: "itemsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "content-center hidden mt-0 w-md md:flex md:mt-8",
            attrs: { variant: "primary-outline", type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.getReports()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("reports.update_report")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-span-8 mt-0 md:mt-12" }, [
      _c("iframe", {
        staticClass:
          "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
        attrs: { src: _vm.getReportUrl },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500",
          on: { click: _vm.viewReportsPDF },
        },
        [
          _c("document-text-icon"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }