var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: this.$t("avalara.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("general.home"),
                  to: "/admin/dashboard",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("avalara.title"),
                  to: "/admin/avalara/configs",
                },
              }),
              _vm._v(" "),
              _vm.$route.name === "avalara.edit"
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.edit"),
                      to: "#",
                      active: "",
                    },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("avalara.add_new_config"),
                      to: "#",
                      active: "",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12 md:col-span-8" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitAvalara.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-card",
                [
                  _c("div", { staticClass: "flex mb-4 md:grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-full" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          on: { change: _vm.setStatus },
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-12" }, [
                          _c("p", {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("avalara.item.status")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 m-0 text-xs leading-tight text-gray-500",
                              staticStyle: { "max-width": "480px" },
                            },
                            [
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      _vm.formData.status == "A" ? "A" : "I"
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      _vm.formData.status == "A" ? "A" : "I"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.formData.status == "A"
                                          ? "Active"
                                          : "Inactive"
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c("div", { staticClass: "relative w-full" }, [
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticClass:
                                    "flex justify-center w-full md:w-auto",
                                  attrs: {
                                    loading: _vm.isLoading,
                                    variant: "primary",
                                    type: "button",
                                    size: "lg",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.testConection()
                                    },
                                  },
                                },
                                [
                                  !_vm.isLoading
                                    ? _c("save-icon", {
                                        staticClass: "mr-2 -ml-1",
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("avalara.test_conection")) +
                                      "\n                "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          on: { change: _vm.setConexion },
                          model: {
                            value: _vm.production,
                            callback: function ($$v) {
                              _vm.production = $$v
                            },
                            expression: "production",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: { textContent: _vm._s(_vm.conexion) },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-tight text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("avalara.conexion_description")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex md:col-start-1 md:col-span-3 my-5 mb-4",
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black box-title",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("avalara.account_information")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4 mt-4",
                      attrs: {
                        label: _vm.$t("avalara.user_name"),
                        error: _vm.userNameError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.user_name.$error,
                          focus: "",
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.user_name.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.user_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "user_name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.user_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$tc("general.password"),
                        error: _vm.passwordError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.password.$error,
                          focus: "",
                          type: _vm.showPassword ? "text" : "password",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                          },
                          input: function ($event) {
                            return _vm.$v.formData.password.$touch()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "rightIcon",
                            fn: function () {
                              return [
                                _vm.showPassword
                                  ? _c("eye-off-icon", {
                                      staticClass:
                                        "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          _vm.showPassword = !_vm.showPassword
                                        },
                                      },
                                    })
                                  : _c("eye-icon", {
                                      staticClass:
                                        "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          _vm.showPassword = !_vm.showPassword
                                        },
                                      },
                                    }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.formData.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "password", $$v)
                          },
                          expression: "formData.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("avalara.client_id"),
                        error: _vm.clientError,
                        horizontal: "",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.client_id.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.client_id.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.client_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "client_id", $$v)
                          },
                          expression: "formData.client_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("avalara.account_reference"),
                        error: _vm.account_referenceError,
                        horizontal: "",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.account_reference.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.account_reference.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.account_reference,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "account_reference", $$v)
                          },
                          expression: "formData.account_reference",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("avalara.profile_id"),
                        error: _vm.profileError,
                        horizontal: "",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.profile_id.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.profile_id.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.profile_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "profile_id", $$v)
                          },
                          expression: "formData.profile_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t(
                          "settings.company_info.company_identifier"
                        ),
                        error: _vm.company_identifierError,
                        horizontal: "",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.company_identifier.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.company_identifier.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.company_identifier,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "company_identifier", $$v)
                          },
                          expression: "formData.company_identifier",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: "Url",
                        error: _vm.urlError,
                        horizontal: "",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.url.$error,
                          type: "url",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.url.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "url", $$v)
                          },
                          expression: "formData.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: "Host",
                        horizontal: "",
                        required: "",
                        error: _vm.hostError,
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.host.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.host.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.host,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "host", $$v)
                          },
                          expression: "formData.host",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4 mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.formData.invm,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "invm", $$v)
                            },
                            expression: "formData.invm",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: { textContent: _vm._s("Invm") },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(
                          "\n                This value should always be true, unless used for testing. If it is left false it may affect the billing process.                  \n              "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex md:col-start-1 md:col-span-3 my-5 mb-4",
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black box-title",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("avalara.response_return")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.dtl,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "dtl", $$v)
                            },
                            expression: "formData.dtl",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("avalara.response_detail")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.summ,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "summ", $$v)
                            },
                            expression: "formData.summ",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("avalara.response_summary")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.retnb,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "retnb", $$v)
                            },
                            expression: "formData.retnb",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("avalara.response_billable")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.retext,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "retext", $$v)
                            },
                            expression: "formData.retext",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("avalara.response_extended_data")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.incrf,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "incrf", $$v)
                            },
                            expression: "formData.incrf",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-15" }, [
                      _c("p", {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("avalara.response_reporting_information")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex md:col-start-1 md:col-span-3 my-5 mb-4",
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black box-title",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("avalara.company")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-start-1 md:col-span-3 mt-5",
                          attrs: {
                            label: _vm.$t("customers.business_class"),
                            error: _vm.companyBsclError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.avalara_company_bscl_options,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.companySelected($event, "bscl")
                              },
                              input: function ($event) {
                                return _vm.$v.formData.bscl.$touch()
                              },
                            },
                            model: {
                              value: _vm.avalara_company_bscl_selected,
                              callback: function ($$v) {
                                _vm.avalara_company_bscl_selected = $$v
                              },
                              expression: "avalara_company_bscl_selected",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("customers.service_class"),
                            error: _vm.companySvclError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.avalara_company_svcl_options,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.companySelected($event, "svcl")
                              },
                              input: function ($event) {
                                return _vm.$v.formData.svcl.$touch()
                              },
                            },
                            model: {
                              value: _vm.avalara_company_svcl_selected,
                              callback: function ($$v) {
                                _vm.avalara_company_svcl_selected = $$v
                              },
                              expression: "avalara_company_svcl_selected",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("customers.facilities"),
                            error: _vm.companyFcltError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.avalara_company_fclt_options,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.fclt.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "fclt")
                              },
                            },
                            model: {
                              value: _vm.avalara_company_fclt_selected,
                              callback: function ($$v) {
                                _vm.avalara_company_fclt_selected = $$v
                              },
                              expression: "avalara_company_fclt_selected",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("customers.regulated"),
                            error: _vm.companyRegError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.avalara_company_reg_options,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.reg.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "reg")
                              },
                            },
                            model: {
                              value: _vm.avalara_company_reg_selected,
                              callback: function ($$v) {
                                _vm.avalara_company_reg_selected = $$v
                              },
                              expression: "avalara_company_reg_selected",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2 mb-2",
                          attrs: {
                            label: _vm.$t("customers.franchise"),
                            error: _vm.companyFrchError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.avalara_company_frch_options,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.frch.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "frch")
                              },
                            },
                            model: {
                              value: _vm.avalara_company_frch_selected,
                              callback: function ($$v) {
                                _vm.avalara_company_frch_selected = $$v
                              },
                              expression: "avalara_company_frch_selected",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex md:col-start-1 md:col-span-3 my-5 mb-4",
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black box-title",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "avalara.pbx_services_configuration"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-5",
                          attrs: {
                            label: _vm.$t("customers.did_items"),
                            error: _vm.didItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_did_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "did")
                              },
                            },
                            model: {
                              value: _vm.did_item,
                              callback: function ($$v) {
                                _vm.did_item = $$v
                              },
                              expression: "did_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("customers.extension_items"),
                            error: _vm.extensionItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.cutomAppRateItemsOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_extension_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "ext")
                              },
                            },
                            model: {
                              value: _vm.extension_item,
                              callback: function ($$v) {
                                _vm.extension_item = $$v
                              },
                              expression: "extension_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3",
                          attrs: {
                            label: _vm.$t("customers.cdr_items"),
                            error: _vm.cdrItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_cdr_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "cdr")
                              },
                            },
                            model: {
                              value: _vm.cdr_item,
                              callback: function ($$v) {
                                _vm.cdr_item = $$v
                              },
                              expression: "cdr_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("pbx_services.custom_destinations"),
                            error: _vm.customItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_custom_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "inter")
                              },
                            },
                            model: {
                              value: _vm.custom_item,
                              callback: function ($$v) {
                                _vm.custom_item = $$v
                              },
                              expression: "custom_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t(
                              "pbx_services.custom_destinations_inter"
                            ),
                            error: _vm.internationalItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_international_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "inter")
                              },
                            },
                            model: {
                              value: _vm.international_item,
                              callback: function ($$v) {
                                _vm.international_item = $$v
                              },
                              expression: "international_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t(
                              "pbx_services.custom_destinations_toll"
                            ),
                            error: _vm.totalFreeItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.item_toll_free_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected($event, "toll_free")
                              },
                            },
                            model: {
                              value: _vm.toll_free_item,
                              callback: function ($$v) {
                                _vm.toll_free_item = $$v
                              },
                              expression: "toll_free_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("pbx_services.custom_app_rate_items"),
                            error: _vm.customAppRateItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.custom_app_rate_item_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected(
                                  $event,
                                  "cutom_app_rate_item"
                                )
                              },
                            },
                            model: {
                              value: _vm.cutom_app_rate_item,
                              callback: function ($$v) {
                                _vm.cutom_app_rate_item = $$v
                              },
                              expression: "cutom_app_rate_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t("pbx_services.services_price_item"),
                            error: _vm.servicePriceItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.services_price_item_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected(
                                  $event,
                                  "services_price_item"
                                )
                              },
                            },
                            model: {
                              value: _vm.services_price_item,
                              callback: function ($$v) {
                                _vm.services_price_item = $$v
                              },
                              expression: "services_price_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avalara_bool
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3 mt-2",
                          attrs: {
                            label: _vm.$t(
                              "pbx_services.additional_charges_item"
                            ),
                            error: _vm.additionalChargesItemError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.itemsTypeTaxableAmountOptions,
                              searchable: true,
                              "show-labels": false,
                              label: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.additional_charges_item_id.$touch()
                              },
                              select: function ($event) {
                                return _vm.companySelected(
                                  $event,
                                  "additional_charges_item"
                                )
                              },
                            },
                            model: {
                              value: _vm.additional_charges_item,
                              callback: function ($$v) {
                                _vm.additional_charges_item = $$v
                              },
                              expression: "additional_charges_item",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-6 mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "flex justify-center w-full md:w-auto",
                          attrs: {
                            loading: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("general.update")
                                  : _vm.$t("general.save")
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }