export const BOOTSTRAP_PAYPAL = 'BOOTSTRAP_PAYPAL'
export const ADD_PAYPALS = 'ADD_PAYPALS'
export const UPDATE_PAYPAL = 'UPDATE_PAYPAL'
export const DELETE_PAYPAL = 'DELETE_PAYPAL'
export const SET_TOTAL_PAYPAL = 'SET_TOTAL_PAYPAL'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_PAYPAL = 'SET_SELECTED_VIEW_PAYPAL'
export const RESET_SELECTED_PAYPAL = 'RESET_SELECTED_PAYPAL'
export const SET_SELECTED_PAYPAL = 'SET_SELECTED_PAYPAL'
export const ADD_PAYPAL = 'ADD_PAYPAL'
export const SAVE_PAYPAL = 'SAVE_PAYPAL'
export const VOID_PAYPAL = 'VOID_PAYPAL'
export const REFUNDED_PAYPAL = 'REFUNDED_PAYPAL'
export const UPDATE_STATUS_PAYPAL = 'UPDATE_STATUS_PAYPAL'
export const ADD_PAYPAL_ACH = 'ADD_PAYPAL_ACH'
export const SAVE_PAYPAL_ACH = 'SAVE_PAYPAL_ACH'