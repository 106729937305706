export const SET_PACKAGES = 'SET_PACKAGES'
export const SET_PACKAGES_GROUP = 'SET_PACKAGES_GROUP'
export const ADD_PACKAGE = 'ADD_PACKAGE'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const DELETE_PACKAGE = 'DELETE_PACKAGE'
export const DELETE_MULTIPLE_PACKAGES = 'DELETE_MULTIPLE_PACKAGES'
export const SET_SELECTED_PACKAGES = 'SET_SELECTED_PACKAGES'
export const SET_TOTAL_PACKAGES = 'SET_TOTAL_PACKAGES'

export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const SET_CUSTOMER = 'SET_CUSTOMER'

export const RESET_ITEM = 'RESET_ITEM'
export const SET_ITEM = 'SET_ITEM'

export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'

export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_PACKAGES = 'RESET_SELECTED_PACKAGES'
export const UPDATE_PACKAGE_STATUS = 'UPDATE_PACKAGE_STATUS'

export const RESET_SELECTED_NOTE = 'RESET_SELECTED_NOTE'
export const SET_SELECTED_NOTE = 'SET_SELECTED_NOTE'

export const CREATE_PACKAGE_GROUP = 'CREATE_PACKAGE_GROUP'
export const SET_PACKAGES_BY_GROUP = 'SET_PACKAGES_BY_GROUP'
export const SET_SELECTED_VIEW_PACKAGE = 'SET_SELECTED_VIEW_PACKAGE'
export const PACKAGES_LIST = 'PACKAGES_LIST'