var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "relative" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("expenses.expense", 2),
                    to: "/admin/expenses-template",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "expenses-template.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.edit_expense_template"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.new_expense_template"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 text-sm hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary-outline",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("expenses.update_expense_template")
                            : _vm.$t("expenses.save_expense_template")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _c(
              "sw-card",
              [
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.name"),
                          error: _vm.expenseNameError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: { invalid: _vm.$v.formData.name.$error },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.expense_template_number"),
                          error: _vm.expenseNumError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: {
                            prefix: `${_vm.expensePrefix} - `,
                            invalid: _vm.$v.expenseNumAttribute.$error,
                            disabled: _vm.isEdit,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.expenseNumAttribute.$touch()
                            },
                          },
                          model: {
                            value: _vm.expenseNumAttribute,
                            callback: function ($$v) {
                              _vm.expenseNumAttribute =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "expenseNumAttribute",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("general.status") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.statusOptions,
                            "allow-empty": false,
                            label: "label",
                            "track-by": "value",
                          },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.expense_template_date_begin"),
                          error: _vm.dateError,
                          required: "",
                        },
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.expense_date.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.expense_date.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.expense_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "expense_date", $$v)
                            },
                            expression: "formData.expense_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.term") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.termOptions,
                            "allow-empty": false,
                            label: "label",
                            "track-by": "value",
                          },
                          model: {
                            value: _vm.formData.term,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "term", $$v)
                            },
                            expression: "formData.term",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("general.subject"),
                          error: _vm.expenseSubjectError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: { invalid: _vm.$v.formData.subject.$error },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.subject.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.subject,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "subject",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.subject",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.initial_status") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.initialStatusOptions,
                            "allow-empty": false,
                            label: "label",
                            "track-by": "value",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.onChangeInitialStatus($event)
                            },
                          },
                          model: {
                            value: _vm.formData.initial_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "initial_status", $$v)
                            },
                            expression: "formData.initial_status",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.provider") } },
                      [
                        _vm.provider
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.provider = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.providers,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("expenses.provider_select"),
                            label: "title",
                            "track-by": "id",
                          },
                          model: {
                            value: _vm.provider,
                            callback: function ($$v) {
                              _vm.provider = $$v
                            },
                            expression: "provider",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.category"),
                          error: _vm.categoryError,
                          required: "",
                        },
                      },
                      [
                        _c(
                          "sw-select",
                          {
                            ref: "baseSelect",
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.categories,
                              invalid: _vm.$v.category.$error,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t(
                                "expenses.categories.select_a_category"
                              ),
                              label: "name",
                              "track-by": "id",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.category.$touch()
                              },
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass:
                                  "flex items-center justify-center w-full px-4 py-3 bg-gray-200 border-none outline-none",
                                attrs: {
                                  slot: "afterList",
                                  type: "button",
                                  variant: "gray-light",
                                },
                                on: { click: _vm.openCategoryModal },
                                slot: "afterList",
                              },
                              [
                                _c("shopping-cart-icon", {
                                  staticClass:
                                    "h-5 text-center text-primary-400",
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ml-2 text-xs leading-none text-primary-400",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "settings.expense_category.add_new_category"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.amount"),
                          error: _vm.amountError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-money", {
                          staticClass:
                            "focus:border focus:border-solid focus:border-primary-500",
                          attrs: {
                            currency: _vm.defaultCurrencyForInput,
                            invalid: _vm.$v.formData.amount.$error,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.amount.$touch()
                            },
                          },
                          model: {
                            value: _vm.amount,
                            callback: function ($$v) {
                              _vm.amount = $$v
                            },
                            expression: "amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.customer") } },
                      [
                        _vm.customer
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.customer = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.customers,
                            searchable: true,
                            "show-labels": false,
                            disabled: _vm.isCreate,
                            placeholder: _vm.$t("customers.select_a_customer"),
                            label: "name",
                            "track-by": "id",
                          },
                          on: { select: _vm.CustomerSelected },
                          model: {
                            value: _vm.customer,
                            callback: function ($$v) {
                              _vm.customer = $$v
                            },
                            expression: "customer",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.item") } },
                      [
                        _vm.item
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.item = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.items,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("expenses.item_select"),
                            label: "name",
                            "track-by": "id",
                          },
                          model: {
                            value: _vm.item,
                            callback: function ($$v) {
                              _vm.item = $$v
                            },
                            expression: "item",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("payments.payment_mode") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.paymentModes,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("payments.select_payment_mode"),
                            "max-height": 150,
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.payment_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "payment_method", $$v)
                            },
                            expression: "formData.payment_method",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex mb-4 mt-8" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12 ml-1" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-18px" },
                            model: {
                              value: _vm.formData.notification,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "notification", $$v)
                              },
                              expression: "formData.notification",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-2 mt-1" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("expenses.notification")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.isStatusPending
                      ? _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("expenses.day_after_payment_date"),
                            },
                          },
                          [
                            _c("sw-input", {
                              staticClass: "mt-1",
                              model: {
                                value: _vm.formData.day_after_payment_date,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "day_after_payment_date",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.day_after_payment_date",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 mt-4" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.note"),
                          error: _vm.descriptionError,
                        },
                      },
                      [
                        _c("sw-editor", {
                          attrs: {
                            "set-editor": _vm.formData.description,
                            rows: "2",
                            name: "description",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.description.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary-outline",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass:
                      "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("expenses.update_expense_template")
                            : _vm.$t("expenses.save_expense_template")
                        ) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }