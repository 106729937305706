var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.pbx_service_view") } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _vm.cancel_service && !_vm.isCancelled
                ? _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "danger" },
                      on: { click: _vm.cancelService },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer_profile.cancel_service")) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/customer/tickets/add`,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/customer/dashboard`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _vm.getInfoClient ? _c("customer-info") : _vm._e(),
          _vm._v(" "),
          _vm.bandera
            ? _c("service-detail", { on: { status: function ($event) {} } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }