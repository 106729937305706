var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _vm.isSuperAdmin
        ? _c(
            "base-page",
            {},
            [
              _c(
                "sw-page-header",
                {
                  staticClass: "mb-3",
                  attrs: {
                    title: _vm.$t("settings.customization.modules.manage_host"),
                  },
                },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/dashboard",
                          title: _vm.$t("general.home"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/settings/modules",
                          title: _vm.$t("settings.customization.modules.title"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t(
                            "settings.customization.modules.edit_module"
                          ),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            "tag-name": "router-link",
                            to: "pbx/jobs/logs",
                            size: "lg",
                            variant: "primary",
                          },
                        },
                        [
                          _c("eye-icon", {
                            staticClass: "h-6 mr-1 -ml-2 font-bold",
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.modules.view_log"
                                )
                              ) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            "tag-name": "router-link",
                            to: "pbx/addrow",
                            size: "lg",
                            variant: "primary",
                          },
                        },
                        [
                          _c("plus-sm-icon", {
                            staticClass: "h-6 mr-1 -ml-2 font-bold",
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.modules.add_server"
                                )
                              ) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-dropdown",
                        { staticClass: "ml-4", attrs: { variant: "primary" } },
                        [
                          _c(
                            "sw-button",
                            { attrs: { slot: "activator" }, slot: "activator" },
                            [
                              _c("dots-horizontal-icon", {
                                staticClass: "h-5 -ml-1 -mr-1",
                                staticStyle: { height: "25px" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/settings/add-ons`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("credit-card-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.add_ons"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "serversTable",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      label: _vm.$t(
                        "settings.customization.modules.server_label"
                      ),
                      show: "server_label",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.server_label"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.server_label) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3809863077
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("settings.customization.modules.hostname"),
                      show: "hostname",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.hostname"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              row.hostname
                                ? _c(
                                    "span",
                                    {
                                      domProps: {
                                        innerHTML: _vm._s(row.hostname),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.hostname) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("tax_groups.empty")) +
                                        "\n          "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3518778078
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t(
                        "settings.customization.modules.status_server"
                      ),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.status_server"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              row.status == "A"
                                ? _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.customization.modules.server_online"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "I"
                                ? _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.customization.modules.server_offline"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3441090752
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("tax_groups.action")) +
                                    " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: `pbx/${row.id}/edit`,
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.testConection(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("shield-check-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(" "),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.customization.modules.test_conection"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removePbxServer(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2760279794
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }