var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid gap-8 md:grid-cols-12" }, [
    _c(
      "div",
      { staticClass: "col-span-8 mt-12 md:col-span-4" },
      [
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: {
                  label: _vm.$t("reports.expenses.date_range"),
                  error: _vm.dateRangeError,
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.dateRange,
                    "allow-empty": false,
                    "show-labels": false,
                  },
                  on: { input: _vm.onChangeDateRange },
                  model: {
                    value: _vm.selectedRange,
                    callback: function ($$v) {
                      _vm.selectedRange = $$v
                    },
                    expression: "selectedRange",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2 mb-2",
          },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("reports.expenses.from_date"),
                  error: _vm.fromDateError,
                },
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.from_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.from_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.from_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "from_date", $$v)
                    },
                    expression: "formData.from_date",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5 md:mt-0",
                attrs: {
                  label: _vm.$t("reports.expenses.to_date"),
                  error: _vm.toDateError,
                },
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.to_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.to_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.to_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "to_date", $$v)
                    },
                    expression: "formData.to_date",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2 mt-5" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12",
                attrs: { label: _vm.$t("expenses.provider") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.providers,
                    multiple: true,
                    searchable: true,
                    "show-labels": true,
                    placeholder: _vm.$t("expenses.provider_select"),
                    label: "title",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.selected.providerSelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "providerSelected", $$v)
                    },
                    expression: "selected.providerSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12",
                attrs: { label: _vm.$t("expenses.customer") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.customers,
                    multiple: true,
                    placeholder: _vm.$t("customers.select_a_customer"),
                    searchable: true,
                    "show-labels": true,
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.selected.customerSelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "customerSelected", $$v)
                    },
                    expression: "selected.customerSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12",
                attrs: { label: _vm.$t("payments.payment_mode") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    multiple: true,
                    placeholder: _vm.$t("payments.select_payment_mode"),
                    searchable: true,
                    options: _vm.paymentModes,
                    "show-labels": true,
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.selected.paymentModeSelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "paymentModeSelected", $$v)
                    },
                    expression: "selected.paymentModeSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12",
                attrs: { label: _vm.$t("expenses.category") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.categories,
                    multiple: true,
                    placeholder: _vm.$t(
                      "expenses.categories.select_a_category"
                    ),
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.selected.categorySelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "categorySelected", $$v)
                    },
                    expression: "selected.categorySelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.allow_invoice_form_pos
          ? _c(
              "div",
              { staticClass: "grid grid-cols-12 mb-2" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12",
                    attrs: { label: _vm.$t("core_pos.store") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.stores,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        "track-by": "id",
                        label: "name",
                        tabindex: 1,
                      },
                      model: {
                        value: _vm.stores_selected,
                        callback: function ($$v) {
                          _vm.stores_selected = $$v
                        },
                        expression: "stores_selected",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              { staticClass: "col-span-12", attrs: { label: "Status" } },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.statusOptions,
                    multiple: true,
                    label: "label",
                    "track-by": "value",
                  },
                  model: {
                    value: _vm.selected.statusSelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "statusSelected", $$v)
                    },
                    expression: "selected.statusSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12",
                attrs: { label: _vm.$t("expenses.item") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.items,
                    multiple: true,
                    placeholder: _vm.$t("expenses.item_select"),
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.selected.itemSelected,
                    callback: function ($$v) {
                      _vm.$set(_vm.selected, "itemSelected", $$v)
                    },
                    expression: "selected.itemSelected",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "content-center hidden mt-0 w-md md:flex md:mt-8",
            attrs: { variant: "primary-outline" },
            on: {
              click: function ($event) {
                return _vm.getReports()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("reports.update_report")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-span-8 mt-0 md:mt-12" }, [
      _c("iframe", {
        staticClass:
          "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
        attrs: { src: _vm.getReportUrl },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500",
          on: { click: _vm.viewReportsPDF },
        },
        [
          _c("document-text-icon"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }