var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.did_prefix"),
                error: _vm.ExpensePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.did_pbx_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.did_pbx_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE")
                  },
                },
                model: {
                  value: _vm.expenses.did_pbx_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "did_pbx_prefix", $$v)
                  },
                  expression: "expenses.did_pbx_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.did_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "did_prefix_general", $$v)
                    },
                    expression: "expenses.did_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "\n              "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("corePbx.customization.custom_did_categories"))),
      ]),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end mt-8 lg:flex-nowrap" },
        [
          _vm.permission.create
            ? _c(
                "sw-button",
                {
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.addItemUnit },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.customization.add_category_did")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoadingDeleteCategoria ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: { variant: "gray", data: _vm.fetchData, "show-filter": false },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("corePbx.customization.name"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.items.unit_name"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          staticClass: "h-5 mr-3 text-primary-800",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _vm.permission.update
                          ? _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editCategory(row)
                                  },
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.permission.delete
                          ? _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCategory(row.id)
                                  },
                                },
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }