var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("tickets.departaments.view_departaments") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/tickets/departaments",
                  title: _vm.$t("tickets.menu_title.departaments"),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "button", variant: "primary-outline" },
                  on: { click: _vm.backButton },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.back")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/tickets/departaments/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _c("pencil-icon", { staticClass: "h-5 mr-3 text-gray-600" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeticketdepart(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _c("trash-icon", { staticClass: "h-5 mr-3 text-gray-600" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("item_groups.basic_info")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [_vm._v("\n            Name\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.ticketDepart ? _vm.ticketDepart.name : "") +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("general.description")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.ticketDepart ? _vm.ticketDepart.description : ""
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.ticketDepart ? _vm.ticketDepart.description : ""
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [_vm._v("\n            Email\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.ticketDepart ? _vm.ticketDepart.email : "") +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.contenido
              ? _c("div", [
                  _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n              Default Priority\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.band
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.ticketDepart.default_priority.text
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [_vm._v("\n              Email handling\n            ")]
                      ),
                      _vm._v(" "),
                      _vm.band
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.ticketDepart.email_handling.text) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n              Automatically Close Tickets\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.ticketDepart
                                  ? _vm.ticketDepart.automatically_close +
                                      " Days"
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n              Automatically Delete Tickets\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.ticketDepart
                                  ? _vm.ticketDepart.automatically_delete +
                                      " Days"
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [_vm._v("\n              Status\n            ")]
                      ),
                      _vm._v(" "),
                      _vm.band
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.ticketDepart.status.text) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [_vm._v("USERS")]
            ),
            _vm._v(" "),
            _c("sw-empty-table-placeholder", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyTable,
                  expression: "showEmptyTable",
                },
              ],
              attrs: {
                title: _vm.$t("tickets.departaments.no_users"),
                description: _vm.$t("tickets.departaments.list_of_users"),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showEmptyTable,
                    expression: "!showEmptyTable",
                  },
                ],
                staticClass: "relative table-container",
              },
              [
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    attrs: {
                      data: _vm.fetchUsers,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("users.name"),
                        show: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("users.name"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(row.name))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("users.email"),
                        show: "email",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("users.phone"),
                        show: "phone",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("users.phone"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.phone ? row.phone : "No Contact") +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("users.added_on"),
                        "sort-as": "created_at",
                        show: "formattedCreatedAt",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("users.role"),
                        show: "role2",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("users.role"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.role2 ? row.role2 : "No Role") +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [_vm._v("TICKETS")]
            ),
            _vm._v(" "),
            _c("sw-empty-table-placeholder", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyTableTickets,
                  expression: "showEmptyTableTickets",
                },
              ],
              attrs: {
                title: _vm.$t("customer_ticket.no_customer_ticket"),
                description: _vm.$t("customer_ticket.list_of_customer_ticket"),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showEmptyTableTickets,
                    expression: "!showEmptyTableTickets",
                  },
                ],
                staticClass: "relative table-container",
              },
              [
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    attrs: {
                      data: _vm.fetchTickets,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customer_ticket.summary"),
                        show: "summary",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customer_ticket.summary"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: `main/${row.user_id}/${row.id}/view-ticket`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.summary) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customer_ticket.departament"),
                        show: "departament",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customer_ticket.departament"))
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.departament) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customer_ticket.assignedTo"),
                        show: "assigned",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customer_ticket.assignedTo"))
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.assigned) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customer_ticket.status"),
                        show: "status",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customer_ticket.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              row.status == "S"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStatus(row.status)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "C"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "UNPAID"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "UNPAID"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStatus(row.status)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "I"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStatus(row.status)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "O"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "SENT"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "SENT"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStatus(row.status)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "M"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStatus(row.status)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("payments.date"),
                        "sort-as": "created_at",
                        show: "formattedCustomerNoteDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }