var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("div", [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.tax_agency.title")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
            [
              _c(
                "sw-button",
                {
                  attrs: {
                    size: "lg",
                    variant: "primary-outline",
                    type: "button",
                  },
                  on: { click: _vm.backButton },
                },
                [
                  _c("span", { staticClass: "w-6 h-6" }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.back")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    "tag-name": "router-link",
                    size: "lg",
                    variant: "primary-outline",
                    to: "/admin/settings/tax-types",
                  },
                },
                [
                  _c("currency-dollar-icon", {
                    staticClass: "w-6 h-6 mr-1 -ml-2",
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.tax_categories.taxes")) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  staticStyle: { position: "relative", top: "-12%" },
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      attrs: { size: "lg", variant: "primary-outline" },
                      on: { click: _vm.openTaxModal },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("settings.tax_agency.add_new_tax")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: {
                    label: _vm.$t("settings.tax_agency.name"),
                    color: "black-light",
                  },
                },
                [
                  _c("sw-input", {
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: {
                    label: _vm.$t("settings.tax_agency.number"),
                    color: "black-light",
                  },
                },
                [
                  _c(
                    "sw-input",
                    {
                      model: {
                        value: _vm.filters.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "number", $$v)
                        },
                        expression: "filters.number",
                      },
                    },
                    [
                      _c("hashtag-icon", {
                        staticClass: "h-5 ml-1 text-gray-500",
                        attrs: { slot: "leftIcon" },
                        slot: "leftIcon",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: {
                    label: _vm.$t("customers.country"),
                    color: "black-light",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.countries,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      placeholder: _vm.$t("general.select_country"),
                      label: "name",
                      "track-by": "id",
                    },
                    on: {
                      select: function ($event) {
                        return _vm.countrySelected($event)
                      },
                    },
                    model: {
                      value: _vm.filters.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "country", $$v)
                      },
                      expression: "filters.country",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: {
                    label: _vm.$t("customers.state"),
                    color: "black-light",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.states,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$t("general.select_state"),
                      label: "name",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.filters.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "state", $$v)
                      },
                      expression: "filters.state",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.clear_all")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            variant: "gray",
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.tax_agency.name"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_agency.name"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.tax_agency.number"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_agency.number"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.number)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.tax_agency.email"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_agency.email"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.email)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.tax_agency.phone"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_agency.phone"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_agency.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _vm.permissionModule.update
                          ? _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editTaxAgency(row.id)
                                  },
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.permissionModule.delete
                          ? _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTax(row.id)
                                  },
                                },
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }