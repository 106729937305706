var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "6" } },
        [
          _c("table", { staticClass: "w-full mb-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "pt-6 pr-4 pb-2 pl-4 align-center" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-input", {
                              attrs: {
                                focus: "",
                                type: "text",
                                name: "name",
                                invalid:
                                  !_vm.isValid ||
                                  (_vm.index > 0 &&
                                    _vm.$v.invoice.invoice_number.$invalid),
                              },
                              on: {
                                blur: _vm.validateProviderAndInvoiceNumber,
                              },
                              model: {
                                value: _vm.invoice_number,
                                callback: function ($$v) {
                                  _vm.invoice_number = $$v
                                },
                                expression: "invoice_number",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "pt-6 pr-4 pb-2 pl-4 align-center" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-money", {
                              attrs: {
                                inputmode: "numeric",
                                currency: _vm.customerCurrency,
                                invalid:
                                  _vm.index > 0 &&
                                  _vm.$v.invoice.subtotal.$invalid,
                                onkeydown:
                                  "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                              },
                              model: {
                                value: _vm.subtotal,
                                callback: function ($$v) {
                                  _vm.subtotal = $$v
                                },
                                expression: "subtotal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "pt-6 pr-4 pb-2 pl-4 align-center" }, [
                  _c("div", { staticClass: "flex flex-col mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-select", {
                              ref: "select",
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.taxesOptions,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                multiple: true,
                                "track-by": "name",
                                label: "name",
                                tabindex: 7,
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.updateTotalTaxes()
                                },
                              },
                              model: {
                                value: _vm.invoice.taxes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.invoice, "taxes", $$v)
                                },
                                expression: "invoice.taxes",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "pt-6 pr-4 pb-2 pl-4 align-center" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-money", {
                              attrs: {
                                inputmode: "numeric",
                                currency: _vm.customerCurrency,
                                onkeydown:
                                  "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                              },
                              model: {
                                value: _vm.total_taxes,
                                callback: function ($$v) {
                                  _vm.total_taxes = $$v
                                },
                                expression: "total_taxes",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "pt-6 pr-4 pb-2 pl-4 align-center" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c("div", { staticClass: "relative w-full" }, [
                          _c("div", {
                            staticClass:
                              "min-h-10 block pt-1.5 pr-10 pb-0 pl-2 rounded border border-solid text-sm border-gray-300 bg-white bg-gray-200 text-gray-600 min-h-10 block pt-1.5 pr-10 pb-0 pl-2 rounded border border-solid text-sm",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.formatMoney(_vm.total, _vm.currency)
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "pt-6 pb-2 align-center" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center text-sm" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                        },
                        [
                          _vm.showTrashIcon
                            ? _c("trash-icon", {
                                staticClass: "h-6 text-gray-700",
                                on: { click: _vm.removeInvoice },
                              })
                            : _c("refresh-icon", {
                                staticClass: "h-12 text-gray-700",
                                on: { click: _vm.clearInvoice },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "12.5%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "35%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "12.5%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }