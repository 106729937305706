var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact-modal" }, [
    _c("div", { staticClass: "mt-2 mr-2 flex justify-end" }, [
      _c(
        "div",
        {},
        [
          _c(
            "sw-button",
            {
              attrs: { loading: _vm.isLoading, variant: "primary" },
              on: { click: _vm.copyContactInvoice },
            },
            [
              !_vm.isLoading
                ? _c("clipboard-copy-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("core_pos.hold_get_contact_invoice")) +
                  "\n      "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitContact.apply(null, arguments)
          },
        },
      },
      [
        _c("sw-card", {}, [
          _c(
            "div",
            {},
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("core_pos.contact.principal_phone") },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.second_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "second_phone", $$v)
                      },
                      expression: "formData.second_phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: {
                    label: _vm.$t("core_pos.contact.name"),
                    error: _vm.nameError,
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: { label: _vm.$t("core_pos.contact.last_name") },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "last_name", $$v)
                      },
                      expression: "formData.last_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: {
                    label: _vm.$t("core_pos.contact.optional_phone"),
                    error: _vm.phoneError,
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.phone.$error,
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "phone", $$v)
                      },
                      expression: "formData.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: { label: _vm.$t("core_pos.contact.identification") },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.identification,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "identification", $$v)
                      },
                      expression: "formData.identification",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: {
                    label: _vm.$t("core_pos.contact.email"),
                    error: _vm.emailError,
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.email.$error,
                      type: "text",
                    },
                    model: {
                      value: _vm.formData.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "email", $$v)
                      },
                      expression: "formData.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        variant: "primary",
                        icon: "save",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2" })
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.save")) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }