var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { attrs: { id: "" } }, [
    _c(
      "div",
      { staticClass: "col-span-1" },
      [
        _c("paypal", {
          attrs: {
            formData: _vm.formData,
            codePayment: _vm.codePayment,
            invoice_number: _vm.invoice_number,
            customer: _vm.customer,
          },
          on: { paypalSuccess: _vm.paypalSuccess },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }