var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-destination" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.internacional.prefix_type"),
                  variant: "horizontal",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.typecustomOptions,
                    searchable: true,
                    "show-labels": true,
                    "allow-empty": false,
                    placeholder: _vm.$t("corePbx.internacional.prefix_type"),
                    label: "label",
                    "track-by": "value",
                  },
                  model: {
                    value: _vm.formData.typecustom,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "typecustom", $$v)
                    },
                    expression: "formData.typecustom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.formData.typecustom.value == "P"
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("didFree.item.prefijo"),
                      required: "",
                      variant: "horizontal",
                      error: _vm.prefixError,
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("didFree.item.prefijo"),
                        focus: "",
                        type: "text",
                        name: "prefijo",
                        pattern: "[0-9*|A-Za-z *|#|+]+",
                        title:
                          "Numbers, letters, blank space and  special characters (* # +)",
                        tabindex: "1",
                        placer: "",
                        invalid: _vm.$v.formData.prefijo.$error,
                      },
                      model: {
                        value: _vm.formData.prefijo,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "prefijo", $$v)
                        },
                        expression: "formData.prefijo",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.typecustom.value == "FT"
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("corePbx.internacional.from"),
                      required: "",
                      variant: "horizontal",
                      error: _vm.fromError,
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("corePbx.internacional.from"),
                        focus: "",
                        type: "text",
                        name: "from",
                        pattern: "[0-9*|A-Za-z *|#|+]+",
                        title:
                          "Numbers, letters, blank space and  special characters (* # +)",
                        tabindex: "1",
                        placer: "",
                        invalid: _vm.$v.formData.from.$error,
                      },
                      model: {
                        value: _vm.formData.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "from", $$v)
                        },
                        expression: "formData.from",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.typecustom.value == "FT"
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("corePbx.internacional.to"),
                      variant: "horizontal",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("corePbx.internacional.to"),
                        focus: "",
                        type: "text",
                        name: "to",
                        pattern: "[0-9*|A-Za-z *|#|+]+",
                        title:
                          "Numbers, letters, blank space and  special characters (* # +)",
                        tabindex: "1",
                        placer: "",
                      },
                      model: {
                        value: _vm.formData.to,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "to", $$v)
                        },
                        expression: "formData.to",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$tc("settings.company_info.country"),
                  variant: "horizontal",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.countries,
                    searchable: true,
                    "show-labels": false,
                    "max-height": 200,
                    label: "name",
                    "track-by": "id",
                  },
                  model: {
                    value: _vm.formData.country_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "country_id", $$v)
                    },
                    expression: "formData.country_id",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("didFree.item.name"),
                  error: _vm.nameError,
                  variant: "horizontal",
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  attrs: {
                    invalid: _vm.$v.formData.name.$error,
                    placeholder: _vm.$t("didFree.item.name"),
                    type: "text",
                    name: "name",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "name",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.prefix_groups.status"),
                  variant: "horizontal",
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.statuses,
                    searchable: true,
                    "show-labels": false,
                    label: "name",
                  },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.prefix_groups.category"),
                  variant: "horizontal",
                  error: _vm.categoryError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    invalid: _vm.$v.formData.category.$error,
                    options: _vm.categories,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t(
                      "corePbx.prefix_groups.select_a_category"
                    ),
                    label: "name",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.category.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "category", $$v)
                    },
                    expression: "formData.category",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.internacional.rate_per_minutes"),
                  variant: "horizontal",
                  error: _vm.rateError,
                  required: "",
                },
              },
              [
                _c("sw-money", {
                  attrs: {
                    currency: _vm.defaultCurrency,
                    invalid: _vm.$v.formData.rate_per_minutes.$error,
                    name: "rate_per_minutes_selected",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.rate_per_minutes.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.rate_per_minutes,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "rate_per_minutes", $$v)
                    },
                    expression: "formData.rate_per_minutes",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeItemModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }