var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "relative h-full",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitBandwidth.apply(null, arguments)
        },
      },
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c(
            "sw-page-header",
            { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/settings/modules",
                      title: _vm.$t("settings.customization.modules.title"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/settings/bandwidth",
                      title: _vm.$t("bandwidth.bandwidth"),
                    },
                  }),
                  _vm._v(" "),
                  _vm.$route.name === "bandwidth.edit-config"
                    ? _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("bandwidth.edit_config"),
                          active: "",
                        },
                      })
                    : _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("bandwidth.new_config"),
                          active: "",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "flex justify-center w-full md:w-auto",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        variant: "primary",
                        type: "submit",
                        size: "lg",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("bandwidth.save_config")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c(
              "div",
              { staticClass: "col-span-12" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("bandwidth.account"),
                      error: _vm.accountNameError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.account_name.$error,
                        focus: "",
                        type: "text",
                        tabindex: 1,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.account_name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.account_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "account_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.account_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("bandwidth.url"),
                      error: _vm.urlError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.url.$error,
                        type: "text",
                        tabindex: 2,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.url.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.url,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "url",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.url",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("bandwidth.user"),
                      error: _vm.userError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.user.$error,
                        type: "text",
                        tabindex: 3,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.user.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.user,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "user",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.user",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("bandwidth.password"),
                      error: _vm.passwordError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.password.$error,
                        type: _vm.getInputType,
                        tabindex: 4,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.password.$touch()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "rightIcon",
                          fn: function () {
                            return [
                              _vm.isShowPassword
                                ? _c("eye-off-icon", {
                                    staticClass:
                                      "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        _vm.isShowPassword = !_vm.isShowPassword
                                      },
                                    },
                                  })
                                : _c("eye-icon", {
                                    staticClass:
                                      "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        _vm.isShowPassword = !_vm.isShowPassword
                                      },
                                    },
                                  }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "password",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("bandwidth.account_id"),
                      error: _vm.accountIdError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.account_id.$error,
                        focus: "",
                        type: "text",
                        tabindex: 1,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.account_id.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.account_id,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "account_id",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.account_id",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }