var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "tckets-departaments-view" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("corePbx.tenants.view_tenant") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/corePBX/tenant/tenants-list",
                  title: _vm.$t("general.pbx_tenant"),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/tenant/tenants-list`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c("div", { staticClass: "col-span-12" }, [
          _c(
            "span",
            { staticClass: "text-gray-500 uppercase sw-section-title" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("item_groups.basic_info")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-4 mt-5 text-center" },
            [
              _c(
                "div",
                { staticClass: "col-span-6 md:col-span-3 sm:col-span-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.tenants.name")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.basicInfo?.name) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-span-6 md:col-span-3 sm:col-span-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.tenants.code")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.basicInfo?.tenant_code))])]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-span-6 md:col-span-3 sm:col-span-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.server")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.basicInfo?.metadata?.server_name) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.basicInfo &&
                    _vm.basicInfo.metadata &&
                    _vm.basicInfo.metadata.server_status === "A"
                      ? _c(
                          "span",
                          {
                            staticClass: "text-success fs-6",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.modules.server_online"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "text-danger fs-6",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.modules.server_offline"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-span-6 md:col-span-3 sm:col-span-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.tenants.created_at")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.basicInfo.created_at
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.formatDate(_vm.basicInfo?.created_at) || ""
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.basicInfo.name
          ? _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("corePbx.tenants.pbx_services")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    staticClass: "col-span-12 md:-mt-0 -mt-12",
                    attrs: {
                      data: _vm.pbxServerTable,
                      "show-filter": false,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.service_number"
                        ),
                        show: "pbx_services_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.service_number"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "font-medium text-primary-500",
                                    attrs: { to: _vm.routerPath(row) },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row?.pbx_services_number) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        516765850
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.status"),
                        show: "status",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.status"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row.status == "A"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.active")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "C"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.cancelled")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "S"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.suspended")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "P"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "DRAFT"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "DRAFT"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.pending")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        159454293
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.term"),
                        show: "term",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.term")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.term))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2558541154
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.activation_date"
                        ),
                        show: "date_begin",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.activation_date"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.date_begin))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4008103057
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.date_of_renovation"
                        ),
                        show: "renewal_date",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.date_of_renovation"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row?.renewal_date) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1762762284
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.basicInfo.name
          ? _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("corePbx.tenants.associated_extensions")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "ext_table",
                    staticClass: "col-span-12 md:-mt-0 -mt-12",
                    attrs: {
                      data: _vm.pbxExtensionsTable,
                      "show-filter": false,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.ext"),
                        show: "ext",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.ext")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.ext))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1980481047
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.name"),
                        show: "name",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.name")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.name))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2369288727
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.status"),
                        show: "status",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.status"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row.status == "enabled"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.active")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "disabled"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.inactive")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        415261973
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.email"),
                        show: "email",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.email")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(row?.email))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2325262910
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.service_number"
                        ),
                        show: "pbx_services_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.service_number"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row?.service?.pbx_services_number
                                  ? _c(
                                      "p",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "font-medium text-primary-500",
                                            attrs: {
                                              to: _vm.routerPath(row.service),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  row?.service
                                                    ?.pbx_services_number
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("p", [_vm._v("N/A")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1625360401
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("packages.action")) +
                                      " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _vm.permissionModule.update
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUpdateExtModal(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("pencil-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3317267994
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.basicInfo.name
          ? _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("corePbx.tenants.associated_dids")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "did_table",
                    staticClass: "col-span-12 md:-mt-0 -mt-12",
                    attrs: {
                      data: _vm.pbxDidsTable,
                      "show-filter": false,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.number"),
                        show: "number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.number"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.number))]),
                                _vm._v(" "),
                                !row.number ? _c("p", [_vm._v("-")]) : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3759847076
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.status"),
                        show: "status",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.status"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row.status == "enabled"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.active")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status == "disabled"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.inactive")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1700725152
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.trunk"),
                        show: "trunk",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.trunk")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.trunk))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3800162242
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.type"),
                        show: "type",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.type")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.type))]),
                                _vm._v(" "),
                                !row.type ? _c("p", [_vm._v("-")]) : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        219699786
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.ext"),
                        show: "ext",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("corePbx.tenants.table_view.ext")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row?.ext))]),
                                _vm._v(" "),
                                !row.ext ? _c("p", [_vm._v("-")]) : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4236440443
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.service_number"
                        ),
                        show: "pbx_services_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.service_number"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row?.pbxService?.pbx_services_number
                                  ? _c(
                                      "p",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "font-medium text-primary-500",
                                            attrs: {
                                              to: _vm.routerPath(
                                                row.pbxService
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  row?.pbxService
                                                    ?.pbx_services_number
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("p", [_vm._v("N/A")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3089474715
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("packages.action")) +
                                      " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _vm.permissionModule.update
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUpdateDIDModal(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("pencil-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        910153594
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.basicInfo.name
          ? _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("corePbx.tenants.pbx_services_applications")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    staticClass: "col-span-12 md:-mt-0 -mt-12",
                    attrs: {
                      data: _vm.pbxAppTable,
                      "show-filter": false,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.app_name"),
                        show: "app_name",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.app_name"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row.app_name))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3250189896
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("corePbx.tenants.table_view.amount"),
                        show: "quantity",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", { staticClass: "bg-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.amount"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row.quantity))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        623476543
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.associated_cost"
                        ),
                        show: "costo",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.associated_cost"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(row.costo))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2219627965
                      ),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t(
                          "corePbx.tenants.table_view.service_number"
                        ),
                        show: "pbx_services_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "corePbx.tenants.table_view.service_number"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row?.pbxService?.pbx_services_number
                                  ? _c(
                                      "p",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "font-medium text-primary-500",
                                            attrs: {
                                              to: _vm.routerPath(
                                                row.pbxService
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  row?.pbxService
                                                    ?.pbx_services_number
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("p", [_vm._v("N/A")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3089474715
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }