var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitCustomerContact.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3 text-sm hidden sm:flex",
                        attrs: { variant: "primary-outline", type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelForm()
                          },
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              !_vm.isEdit
                                ? _vm.$t("general.save")
                                : _vm.$t("general.update")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "sw-card",
              { staticClass: "mb-8" },
              [
                _c(
                  "div",
                  {
                    staticClass: "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5",
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.name"),
                          error: _vm.nameError,
                          required: "",
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            invalid: _vm.$v.formData.name.$error,
                            name: "formData.name",
                            type: "text",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "name", $$v)
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.last_name"),
                          variant: "horizontal",
                          error: _vm.lastNameError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            name: "formData.last_name",
                            type: "text",
                            invalid: _vm.$v.formData.last_name.$error,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.last_name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.last_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "last_name", $$v)
                            },
                            expression: "formData.last_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.phone"),
                          error: _vm.phoneError,
                          required: "",
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            invalid: _vm.$v.formData.phone.$error,
                            name: "formData.phone",
                            type: "text",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.phone.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "phone", $$v)
                            },
                            expression: "formData.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.position"),
                          variant: "horizontal",
                          error: _vm.positionError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            name: "formData.position",
                            type: "text",
                            autocomplete: false,
                            invalid: _vm.$v.formData.position.$error,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.position.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "position", $$v)
                            },
                            expression: "formData.position",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.email"),
                          error: _vm.emailError,
                          required: "",
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            invalid: _vm.$v.formData.email.$error,
                            name: "formData.email",
                            type: "text",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.email.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "email", $$v)
                            },
                            expression: "formData.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.allow_receive_emails"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-switch", {
                          attrs: { name: "formData.allow_receive_emails" },
                          model: {
                            value: _vm.formData.allow_receive_emails,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "allow_receive_emails",
                                $$v
                              )
                            },
                            expression: "formData.allow_receive_emails",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("contacts.type"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.types,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": false,
                            placeholder: _vm.$tc("contacts.select_type"),
                            label: "option",
                            "track-by": "id",
                          },
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    false
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              label: _vm.$t("contacts.log_in_credentials"),
                              variant: "horizontal",
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.log_in_credentials" },
                              model: {
                                value: _vm.formData.log_in_credentials,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "log_in_credentials",
                                    $$v
                                  )
                                },
                                expression: "formData.log_in_credentials",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isAllowReceiveEmails
                  ? _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1 mt-3 mt-md-0",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("contacts.allow_receive_emails")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAllowReceiveEmails
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5",
                      },
                      [
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("general.receive_email_estimates"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_estimates" },
                              model: {
                                value: _vm.formData.email_estimates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_estimates", $$v)
                                },
                                expression: "formData.email_estimates",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("general.receive_email_invoices"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_invoices" },
                              model: {
                                value: _vm.formData.email_invoices,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_invoices", $$v)
                                },
                                expression: "formData.email_invoices",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("general.receive_email_payments"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_payments" },
                              model: {
                                value: _vm.formData.email_payments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_payments", $$v)
                                },
                                expression: "formData.email_payments",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("general.receive_email_services"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_services" },
                              model: {
                                value: _vm.formData.email_services,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_services", $$v)
                                },
                                expression: "formData.email_services",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t(
                                "general.receive_email_pbxservices"
                              ),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_pbx_services" },
                              model: {
                                value: _vm.formData.email_pbx_services,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "email_pbx_services",
                                    $$v
                                  )
                                },
                                expression: "formData.email_pbx_services",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("general.receive_email_tickets"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.email_tickets" },
                              model: {
                                value: _vm.formData.email_tickets,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_tickets", $$v)
                                },
                                expression: "formData.email_tickets",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLogInCredentials
                  ? _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1 mt-3 mt-md-0",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("customers.login")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLogInCredentials
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5",
                      },
                      [
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("contacts.password"),
                              required: "",
                            },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid: _vm.$v.formData.password.$error,
                                name: "formData.password",
                                type: _vm.showPassword ? "text" : "password",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.password.$touch()
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "rightIcon",
                                    fn: function () {
                                      return [
                                        _vm.showPassword
                                          ? _c("eye-off-icon", {
                                              staticClass:
                                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            })
                                          : _c("eye-icon", {
                                              staticClass:
                                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1006372100
                              ),
                              model: {
                                value: _vm.formData.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "password", $$v)
                                },
                                expression: "formData.password",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("contacts.repeat_password"),
                              required: "",
                            },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid: _vm.$v.formData.repeat_password.$error,
                                name: "formData.repeat_password",
                                type: _vm.showPassword ? "text" : "password",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.repeat_password.$touch()
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "rightIcon",
                                    fn: function () {
                                      return [
                                        _vm.showPassword
                                          ? _c("eye-off-icon", {
                                              staticClass:
                                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            })
                                          : _c("eye-icon", {
                                              staticClass:
                                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1006372100
                              ),
                              model: {
                                value: _vm.formData.repeat_password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "repeat_password", $$v)
                                },
                                expression: "formData.repeat_password",
                              },
                            }),
                            _vm._v(" "),
                            _vm.errorPasswordsEquals
                              ? _c("small", [
                                  _c("p", { staticStyle: { color: "red" } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "customers.generate_password_must"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("contacts.generate_password"),
                            },
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass: "mr-3 text-sm",
                                attrs: {
                                  variant: "primary-outline",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.generate()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("customers.generate_password")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          { attrs: { label: _vm.$t("contacts.invoices") } },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.invoices" },
                              model: {
                                value: _vm.formData.invoices,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "invoices", $$v)
                                },
                                expression: "formData.invoices",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4",
                            attrs: { label: _vm.$t("contacts.estimates") },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.estimates" },
                              model: {
                                value: _vm.formData.estimates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "estimates", $$v)
                                },
                                expression: "formData.estimates",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4",
                            attrs: { label: _vm.$t("contacts.payments") },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.payments" },
                              model: {
                                value: _vm.formData.payments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "payments", $$v)
                                },
                                expression: "formData.payments",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          { attrs: { label: _vm.$t("contacts.tickets") } },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.tickets" },
                              model: {
                                value: _vm.formData.tickets,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "tickets", $$v)
                                },
                                expression: "formData.tickets",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            attrs: {
                              label: _vm.$t("contacts.payments_accounts"),
                            },
                          },
                          [
                            _c("sw-switch", {
                              attrs: { name: "formData.payments_accounts" },
                              model: {
                                value: _vm.formData.payments_accounts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "payments_accounts",
                                    $$v
                                  )
                                },
                                expression: "formData.payments_accounts",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                    attrs: { variant: "primary-outline", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass:
                      "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          !_vm.isEdit
                            ? _vm.$t("general.save")
                            : _vm.$t("general.update")
                        ) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }