var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/groups",
                  title: _vm.$tc("groups.group", 2),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/groups`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/groups/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeGroup(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
        _c("div", { staticClass: "pt-6 mt-5" }, [
          _c("div", { staticClass: "col-span-12" }, [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("groups.basic_info")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("groups.name")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-sm leading-5 text-black non-italic" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.formData.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("groups.description")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "text-sm leading-5 text-black non-italic",
                  domProps: { innerHTML: _vm._s(_vm.formData.description) },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("groups.allow_upgrades_downgrades")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-sm leading-5 text-black non-italic" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.formData.allow_upgrades
                            ? _vm.$t("groups.allow_upgrades_yes")
                            : _vm.$t("groups.allow_upgrades_no")
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPackages,
                      expression: "showPackages",
                    },
                  ],
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("packages.title")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.packages,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("groups.name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("groups.name"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "font-medium text-primary-500",
                                    attrs: {
                                      to: `/admin/packages/${row.id}/view`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(row.name) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown",
                          label: _vm.$t("groups.action"),
                          show: "action",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("groups.action")) + " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        attrs: {
                                          to: `/admin/packages/${row.id}/view`,
                                          "tag-name": "router-link",
                                        },
                                      },
                                      [
                                        _c("eye-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.$t("general.view")) +
                                            "\n                    "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }