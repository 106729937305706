var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("div", [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.customization.modules.title")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("settings.customization.modules.description")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-shrink-0 ml-4 justify-end" }, [
            _c(
              "div",
              { staticClass: "mb-3 hidden xl:block" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.toggleSideBar },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tickets.departaments.menu")) +
                        "\n          "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$t("settings.customization.modules.title"),
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            variant: "gray",
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.customization.modules.module"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.modules.module"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [
                      _c("img", { attrs: { src: row.image, width: "150px" } }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.customization.modules.module_name"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.customization.modules.module_name")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [_vm._v(_vm._s(row.name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t(
                "settings.customization.modules.module_description"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.customization.modules.module_description"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      },
                      [_vm._v(_vm._s(row.description))]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      },
                      [_vm._v(_vm._s(row.version))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.modules.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _vm.permissionModule.update
                          ? _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.RedirectToModule(row.slug)
                                  },
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.manage")) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }