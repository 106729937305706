var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitPosMoney.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "p-10 sm:p-6" },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mb-8 mt-4",
              attrs: { label: "Name", error: _vm.nameError, required: "" },
            },
            [
              _c("sw-input", {
                attrs: {
                  pattern: "([^\\s][A-z0-9À-ž\\s]+)",
                  title: "The name cannot contain special characters",
                  invalid: _vm.$v.formData.name.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.name.$touch()
                  },
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-8 mt-6",
              attrs: { label: _vm.$t("settings.core_pos.payment_methods") },
            },
            [
              _c("sw-select", {
                attrs: {
                  options: _vm.payment_methods_module,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("payments.select_a_payment_method"),
                  "track-by": "id",
                  label: "name",
                  tabindex: 11,
                  multiple: true,
                },
                model: {
                  value: _vm.payment_methods_ids,
                  callback: function ($$v) {
                    _vm.payment_methods_ids = $$v
                  },
                  expression: "payment_methods_ids",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-8 mt-4",
              attrs: { label: "Amount", error: _vm.amountError, required: "" },
            },
            [
              _c("sw-money", {
                staticClass:
                  "focus:border focus:border-solid focus:border-primary",
                attrs: {
                  currency: _vm.defaultCurrencyForInput,
                  invalid: _vm.$v.formData.amount.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.amount.$touch()
                  },
                },
                model: {
                  value: _vm.formData.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "amount", _vm._n($$v))
                  },
                  expression: "formData.amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-8 mt-6",
              attrs: { label: "Currency", required: "" },
            },
            [
              _c("sw-select", {
                attrs: {
                  invalid: _vm.$v.currency.$error,
                  options: _vm.currencies,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("items.select_a_type"),
                  "track-by": "id",
                  label: "name",
                  tabindex: 11,
                },
                model: {
                  value: _vm.currency,
                  callback: function ($$v) {
                    _vm.currency = $$v
                  },
                  expression: "currency",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            { staticClass: "mb-8 ml-1", attrs: { label: "Is Coin" } },
            [
              _c("div", { staticClass: "flex mt-2" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-switch", {
                      staticClass: "absolute",
                      staticStyle: { top: "-20px" },
                      model: {
                        value: _vm.formData.is_coin,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "is_coin", $$v)
                        },
                        expression: "formData.is_coin",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closeItemCategoryModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                loading: _vm.isLoading,
                variant: "primary",
                icon: "save",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    !_vm.isEdit
                      ? _vm.$t("general.save")
                      : _vm.$t("general.update")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }