<template>
  <!-- Base  -->
  <base-page v-if="isSuperAdmin" class="option-group-create">
    <!--------- Form ---------->
    <form action="" @submit.prevent="submitCustomerAddressData">
      <!-- Header  -->
      <sw-page-header class="mb-3" :title="pageTitle">
        <template slot="actions">
          <sw-button
            class="mr-3 text-sm hidden sm:flex"
            variant="primary-outline"
            type="button"
            @click="closeAddressForm"
            :disabled="isLoading"
          >
            {{ $t('general.cancel') }}
          </sw-button>
          <sw-button
            variant="primary"
            type="submit"
            class="hidden sm:flex"
            :disabled="isLoading"
          >
            <save-icon class="mr-2" v-if="!isLoading" />
            {{ !isEdit ? $t('general.save') : $t('general.update') }}
          </sw-button>
        </template>
      </sw-page-header>
      <sw-card class="mb-8">
        <div class="grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5">
          <!-- <div class="col-span-12"> -->

          <sw-input-group
            :label="$t('customers.address')"
            :error="billAddress1Error"
            class="mt-4"
            required
            variant="horizontal"
          >
            <sw-textarea
              v-model.trim="formData.address_street_1"
              :invalid="$v.formData.address_street_1.$error"
              :placeholder="$t('general.street_1')"
              type="text"
              name="billing_street1"
              rows="1"
              @input="$v.formData.address_street_1.$touch()"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.address_2')"
            :error="billAddress2Error"
            variant="horizontal"
            class="mt-4"
          >
            <sw-textarea
              v-model.trim="formData.address_street_2"
              :placeholder="$t('general.street_2')"
              type="text"
              name="billing_street2"
              rows="1"
              @input="$v.formData.address_street_2.$touch()"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.city')"
            class="mt-4"
            :error="cityError"
            required
            variant="horizontal"
          >
            <sw-input
              v-model="formData.city"
              :invalid="$v.formData.city.$error"
              name="formData.city"
              type="text"
              @input="$v.formData.city.$touch()"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.county')"
            class="mt-4"
            variant="horizontal"
          >
            <sw-input
              v-model="formData.county"
              name="formData.county"
              type="text"
              :autocomplete="false"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.state')"
            :error="stateIdError"
            class="mt-4"
            required
            variant="horizontal"
          >
            <sw-select
              v-if="!isLoadingCountryState"
              v-model="billing_state"
              :invalid="$v.formData.state_id.$error"
              :options="states"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true"
              :placeholder="$t('general.select_state')"
              label="name"
              track-by="id"
              @select="stateSelected($event)"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.zip_code')"
            :error="zipError"
            required
            variant="horizontal"
            class="mt-4"
          >
            <sw-input
              v-model.trim="formData.zip"
              :invalid="$v.formData.zip.$error"
              type="text"
              name="zip"
              @input="$v.formData.zip.$touch()"
              :disabled="isLoading"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('customers.country')"
            :error="countryIdError"
            class="mt-4"
            required
            variant="horizontal"
          >
            <sw-select
              v-if="!isLoadingCountryState"
              v-model="billing_country"
              :invalid="$v.formData.country_id.$error"
              :options="countries"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('general.select_country')"
              label="name"
              track-by="id"
              @select="countrySelected($event)"
              :disabled="isLoading"
            />
            <!-- @input="$v.formData.billing.country_id.$touch()" -->
          </sw-input-group>

          <!-- billing validation button -->
          <div v-if="isAvalaraAvailable">
            <div v-if="isAvalaraValidation" class="w-full mt-4 text-right">
              <sw-button
                variant="primary-outline"
                size="lg"
                type="button"
                @click="checkBilling"
                :loading="isLoading"
              >
                <check-icon v-if="!isLoading" class="mr-2 -ml-1" />
                {{ $t('customers.billing_validation') }}
              </sw-button>
            </div>
          </div>

          <div v-if="isAvalaraValidation">
            <sw-input-group
              :label="$t('avalara.pcode')"
              variant="horizontal"
              class="mt-5"
            >
              <sw-input
                v-model.trim="formData.pcode"
                type="text"
                name="pcode"
                :disabled="true"
              />
            </sw-input-group>
          </div>

          <!-- </div> -->
        </div>

        <sw-button
          class="mr-3 flex w-full mt-4 sm:hidden md:hidden"
          variant="primary-outline"
          type="button"
          @click="closeAddressForm"
          :disabled="isLoading"
        >
          {{ $t('general.cancel') }}
        </sw-button>
        <sw-button
          variant="primary"
          type="submit"
          class="flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden"
          :disabled="isLoading"
        >
          <save-icon class="mr-2" v-if="!isLoading" />
          {{ !isEdit ? $t('general.save') : $t('general.update') }}
        </sw-button>
      </sw-card>
    </form>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { XCircleIcon, CheckIcon } from '@vue-hero-icons/solid'
const { required, maxLength } = require('vuelidate/lib/validators')

export default {
  components: {
    XCircleIcon,
    CheckIcon,
  },
  data() {
    return {
      isAvalaraAvailable: false,
      // isEdit: false,
      isAvalara: false,
      isLoading: true,
      isLoadingCountryState: false,
      isAvalaraLocationValidated: false,
      billing_state: null,
      billing_country: null,
      // billing_delivery_method: { name: 'Email', value: 'Email' },
      formData: {
        id: null,
        name: null,
        country_id: null,
        state_id: null,
        city: null,
        county: null,
        phone: null,
        zip: null,
        pcode: null,
        address_street_1: null,
        address_street_2: null,
        type: '',
      },
      countries: [],
      states: [],
      billing_states: [],
      categories: [],
      user_id: null,
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      /*'refreshData',*/
      'props',
    ]),
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('avalara', ['avalaraLocationToSave']),

    isSuperAdmin() {
      return this.currentUser.role == 'super admin'
    },
    isEdit() {
      if (this.$route.name === 'customers.edit-address') {
        return true
      }
      return false
    },
    pageTitle() {
      if (this.$route.name === 'customers.edit-address') {
        return this.$t('customer_address.edit_address')
      }
      return this.$t('customer_address.new_address')
    },
    isAvalaraValidation() {
      return this.isAvalara > 0 ? true : false
    },
    countryIdError() {
      if (!this.$v.formData.country_id.$error) {
        return ''
      }
      if (!this.$v.formData.country_id.required) {
        return this.$tc('validation.required')
      }
    },
    stateIdError() {
      if (!this.$v.formData.state_id.$error) {
        return ''
      }
      if (!this.$v.formData.state_id.required) {
        return this.$tc('validation.required')
      }
    },
    cityError() {
      if (!this.$v.formData.city.$error) {
        return ''
      }
      if (!this.$v.formData.city.required) {
        return this.$tc('validation.required')
      }
    },
    zipError() {
      if (!this.$v.formData.zip.$error) {
        return ''
      }
      if (!this.$v.formData.zip.required) {
        return this.$tc('validation.required')
      }
    },
    billAddress1Error() {
      if (!this.$v.formData.address_street_1.$error) {
        return ''
      }
      if (!this.$v.formData.address_street_1.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.address_street_1.maxLength) {
        return this.$t('validation.address_maxlength')
      }
    },
    billAddress2Error() {
      if (!this.$v.formData.address_street_2.$error) {
        return ''
      }

      if (!this.$v.formData.address_street_2.maxLength) {
        return this.$t('validation.address_maxlength')
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  /**
   * Método del ciclo de vida de Vue que se ejecuta cuando el componente es creado.
   * Inicia la carga de datos, suscribe la información de facturación de Avalara,
   * obtiene el estado del módulo de Avalara y maneja el estado de carga.
   */
  async created() {
    console.log('Componente creado, iniciando configuración')
    this.isLoading = true
    console.log('Estado de carga inicial:', this.isLoading)

    // Carga los datos necesarios para el componente
    console.log('Llamando a loadData')
    await this.loadData()
    console.log('loadData completado')

    // Suscribe la información de facturación de Avalara
    console.log('Suscribiendo información de facturación de Avalara')
    this.subscribeAvalaraBillingInfo()

    // Obtiene el estado del módulo de Avalara
    console.log('Obteniendo estado del módulo de Avalara')
    this.getStatusModuleAvalara()

    this.isLoading = false
    console.log('Estado de carga final:', this.isLoading)
  },
  validations: {
    formData: {
      country_id: {
        required,
      },
      city: {
        required,
      },
      zip: {
        required,
      },
      state_id: {
        required,
      },
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
    },
  },
  /**
   * Método del ciclo de vida de Vue que se ejecuta cuando el componente se monta.
   * Suscribe el evento 'save-address' para validar la dirección, obtiene el ID del usuario
   * de la ruta actual y verifica si el cliente tiene habilitada la opción de Avalara.
   */
  async mounted() {
    // Suscribe el evento 'save-address' para validar la dirección
    window.hub.$on('save-address', this.addressValidate)

    // Obtiene el ID del usuario de la ruta actual
    this.user_id = this.$route.params.id

    // Obtiene los datos del cliente
    let resCustomer = await this.fetchCustomer(this.$route.params)

    // Verifica si el cliente tiene habilitada la opción de Avalara
    this.isAvalara = resCustomer.data.customer.avalara_bool

    // Enfoca el campo de nombre (comentado)
    // this.$refs.name.focus = true;
  },
  watch: {
    billing_country(newCountry) {
      if (newCountry) {
        this.formData.country_id = newCountry.id
      } else {
        this.formData.country_id = null
      }
    },
    billing_state(newState) {
      if (newState) {
        this.formData.state_id = newState.id
      } else {
        this.formData.state_id = null
      }
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal', 'resetModalData']),
    ...mapActions('customerAddress', [
      'addAddress',
      'updateCustomerAddress',
      'fetchCustomerAddress',
    ]),
    ...mapActions('customer', [
      'fetchCustomer',
      'billingValidation',
      /* 'addCustomer',
      'updateCustomer', */
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('avalara', ['saveAvalaraLocation', 'checkStatusAvalara']),
    ...mapActions('user', ['getUserModules']),
    ...mapActions('user', ['fetchCurrentUser']),

    /**
     * Método para suscribirse a los cambios en la información de facturación de Avalara.
     * Escucha las mutaciones en el store de Vuex relacionadas con la ubicación de Avalara
     * y actualiza los campos del formulario de facturación en consecuencia.
     */
    async subscribeAvalaraBillingInfo() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'avalara/SET_AVALARA_LOCATION_DATA') {
          if (this.avalaraLocationToSave) {
            if (this.avalaraLocationToSave.country) {
              // Aquí puedes agregar la lógica para actualizar el país si es necesario
              /* let country = this.countries.filter(c => c.code === this.avalaraLocationToSave.country)
this.formData.billing.country_id = country.id
this.$v.formData.billing.country_id.$touch() */
            }
            if (this.avalaraLocationToSave.state) {
              // Aquí puedes agregar la lógica para actualizar el estado si es necesario
            }
            if (this.avalaraLocationToSave.locality) {
              this.formData.city = this.avalaraLocationToSave.locality
            }
            if (this.avalaraLocationToSave.county) {
              this.formData.county = this.avalaraLocationToSave.county
            }
            if (this.avalaraLocationToSave.zip) {
              this.formData.zip = this.avalaraLocationToSave.zip
            }
          }
        }
      })
    },

    /**
     * Método para validar la dirección de facturación.
     * Toca los campos del formulario para activar la validación y, si son válidos,
     * envía los datos para la validación de facturación. Si la validación es exitosa,
     * muestra un modal con la información de la dirección validada.
     *
     * @returns {boolean} - Retorna true si hay algún error en la validación.
     */
    async checkBilling() {
      this.isLoading = true

      // Activa la validación de los campos del formulario
      this.$v.formData.country_id.$touch()
      this.$v.formData.state_id.$touch()
      this.$v.formData.city.$touch()
      this.$v.formData.zip.$touch()

      // Verifica si algún campo es inválido
      if (
        this.$v.formData.country_id.$invalid ||
        this.$v.formData.state_id.$invalid ||
        this.$v.formData.city.$invalid ||
        this.$v.formData.zip.$invalid
      ) {
        this.isLoading = false
        return true
      }

      // Prepara los datos para la validación de facturación
      let data = {
        country: this.billing_country.code,
        state: this.billing_state.code,
        city: this.formData.city,
        zip_code: this.formData.zip,
      }

      // Envía los datos para la validación de facturación
      let response = await this.billingValidation(data)

      if (response.data.check.success) {
        let dataModal = [...response.data.check.data]
        if (dataModal.length > 0) {
          dataModal.forEach((element) => {
            element.customerAvalaraLocationId =
              this.customer_avalara_location_id
            for (const key in element) {
              if (data.country == 'US') {
                if (
                  (!element[key] || element[key] === '') &&
                  key != 'customerAvalaraLocationId'
                ) {
                  element.valid = false
                  break
                } else {
                  element.valid = true
                }
              } else {
                if (
                  (!element[key] || element[key] === '') &&
                  key != 'customerAvalaraLocationId' &&
                  key != 'County' &&
                  key != 'Locality' &&
                  key != 'State'
                ) {
                  element.valid = false
                  break
                } else {
                  element.valid = true
                }
              }
            }
          })

          // Información que la empresa tiene actualmente
          dataModal[0].company_geo_info = {
            country: this.billing_country,
            state: this.billing_state,
            city: this.formData.city,
            county: this.formData.county,
            zip: this.formData.zip,
            edit: this.isEdit,
            type: this.formData.type,
            address_street_1: this.formData.address_street_1,
            address_street_2: this.formData.address_street_2,
          }

          // Invoca el modal con las direcciones validadas
          this.openModal({
            title: this.$t('avalara.billing_location_modal.title'),
            componentName: 'AddressBillingValidationModal',
            id: this.$route.params.id,
            data: dataModal,
            variant: 'lg',
            company: 0,
          })
          this.isLoading = false
          this.isAvalaraLocationValidated = true
          return true
        }

        window.toastr['error'](this.$t('avalara.billing_location_error'))
        this.isLoading = false
        return true
      }

      window.toastr['error'](response.data.check.message)
      this.isLoading = false
      return true
    },

    /**
     * Método para validar y actualizar los datos de la dirección.
     * Asigna el código postal, la ciudad y el condado a los campos correspondientes en formData.
     *
     * @param {Object} param0 - Objeto que contiene los datos de la dirección.
     * @param {string} param0.pcode - Código postal de la dirección.
     * @param {string} param0.city - Ciudad de la dirección.
     * @param {string} param0.county - Condado de la dirección.
     */
    addressValidate({ pcode, city, county }) {
      this.formData.pcode = pcode
      this.formData.city = city
      this.formData.county = county
    },
    /**
     * Método para resetear los datos del formulario.
     * Utiliza la validación de Vuelidate para resetear todos los campos del formulario.
     */
    resetFormData() {
      this.$v.formData.$reset()
    },

    /**
     * Método para eliminar el país seleccionado.
     * Establece el país y el estado en null y maneja el estado de carga.
     */
    async deleteCountry() {
      this.isLoading = true
      this.formData.country = null
      this.formData.state = null
      this.isLoading = false
    },
    /**
     * Método para eliminar el estado seleccionado.
     * Establece el estado en null y maneja el estado de carga.
     */
    async deleteState() {
      this.isLoading = true
      this.formData.state = null
      this.isLoading = false
    },
    /**
     * Método para cargar los datos necesarios para la dirección del cliente.
     * Verifica los permisos del usuario para acceder al módulo, obtiene la lista de países,
     * y carga la información del usuario actual. Si se está editando una dirección existente,
     * también carga los datos de la dirección.
     */
    async loadData() {
      // Obtiene la información del usuario actual
      console.log('Iniciando carga de datos del usuario actual')
      let response = await this.fetchCurrentUser()
      console.log('Respuesta de fetchCurrentUser:', response)

      // Obtiene la lista de países
      console.log('Obteniendo lista de países')
      let res = await window.axios.get('/api/v1/countries')
      console.log('Respuesta de la solicitud de países:', res)

      if (res) {
        this.countries = res.data.countries
        console.log('Lista de países:', this.countries)
      }

      if (response.data.user) {
        let userCompany = response.data.user.company
        console.log('Información de la compañía del usuario:', userCompany)

        this.billing_country = userCompany.address
          ? userCompany.address.country
          : ''
        console.log('País de facturación:', this.billing_country)

        if (this.billing_country) {
          console.log('Seleccionando país:', this.billing_country)
          this.countrySelected(this.billing_country)
        }
      }

      // Si se está editando una dirección, carga los datos de la dirección
      if (this.isEdit) {
        console.log('Modo edición activado, cargando datos de la dirección')
        this.setData()
      }

      const data = {
        module: 'cust_address',
      }
      console.log('Datos del módulo:', data)

      // Obtiene los permisos del usuario para el módulo de direcciones de cliente
      const permissions = await this.getUserModules(data)
      console.log('Permisos del usuario:', permissions)

      // Valida que el usuario tenga permiso para ingresar al módulo
      if (!permissions.super_admin) {
        if (!permissions.exist) {
          console.log('Permisos no existen, redirigiendo al dashboard')
          this.$router.push('/admin/dashboard')
        } else {
          const modulePermissions = permissions.permissions[0]
          console.log('Permisos del módulo:', modulePermissions)

          if (modulePermissions.create === 0 && !this.isEdit) {
            console.log(
              'Permiso de creación denegado, redirigiendo al dashboard'
            )
            this.$router.push('/admin/dashboard')
          } else if (modulePermissions.update === 0 && this.isEdit) {
            console.log(
              'Permiso de actualización denegado, redirigiendo al dashboard'
            )
            this.$router.push('/admin/dashboard')
          }
        }
      }

      return; //
    },
    /**
     * Método para enviar los datos de la dirección del cliente.
     * Valida el formulario, verifica la validación de la ubicación de Avalara si es necesario,
     * y luego envía los datos para agregar o actualizar la dirección del cliente.
     * Muestra mensajes de éxito o error según el resultado y redirige a la página de direcciones del cliente.
     */
    async submitCustomerAddressData() {
      // Toca todos los campos del formulario para activar la validación
      this.$v.formData.$touch()

      // Si el formulario es inválido, detiene el proceso
      if (this.$v.$invalid) {
        return true
      }

      // Verifica la validación de la ubicación de Avalara si es necesario
      if (
        this.isAvalara &&
        (!this.isAvalaraLocationValidated || this.formData.pcode == null)
      ) {
        window.toastr['error'](
          this.$t('customer_address.location_not_validated_message')
        )
        return true
      }

      // Indica que la carga de datos ha comenzado
      this.isLoading = true
      let response
      this.formData.user_id = this.user_id
      this.formData.pcode = this.avalaraLocationToSave.pcd || null
      this.formData.type = !this.isEdit
        ? 'services_address'
        : this.formData.type

      // Envía los datos para agregar o actualizar la dirección del cliente
      if (!this.isEdit) {
        response = await this.addAddress(this.formData)
      } else {
        this.formData.id = this.$route.params.idAddress
        response = await this.updateCustomerAddress(this.formData)
      }

      // Maneja la respuesta del servidor
      if (response.data) {
        if (!this.isEdit) {
          if (this.isAvalara) {
            this.avalaraLocationToSave.addresses_id =
              response.data.customerAddress.id
            let response2 = await this.saveAvalaraLocation(
              this.avalaraLocationToSave
            ) // Guarda la ubicación de Avalara

            if (response2.data) {
              window.toastr['success'](
                this.$t('avalara.billing_location_modal.created_message')
              )
            }
          }

          window.toastr['success'](this.$t('customer_address.created_message'))
        } else {
          window.toastr['success'](this.$t('customer_address.updated_message'))
        }

        // Redirige a la página de direcciones del cliente
        this.$router.push(
          '/admin/customers/' + this.$route.params.id + '/address'
        )
        this.isLoading = false
        return true
      }

      // Muestra un mensaje de error si la respuesta no es exitosa
      window.toastr['error'](response.data.error)
    },
    /**
     * Método para obtener y establecer los datos de la dirección del cliente.
     * Utiliza el ID de la dirección proporcionado en la ruta para buscar los datos
     * y actualiza el formulario con la información obtenida. Si no se encuentran
     * datos, resetea el formulario. También maneja la carga de estados según el país seleccionado.
     */

    async setData() {
      // Indica que la carga de datos ha comenzado
      console.log('Inicio de carga de datos')
      this.isLoading = true

      // Obtiene la dirección del cliente usando el ID de la ruta
      console.log(
        'Obteniendo dirección del cliente con ID:',
        this.$route.params.idAddress
      )
      let res = await this.fetchCustomerAddress(this.$route.params.idAddress)
      console.log('Respuesta de fetchCustomerAddress:', res)

      if (res.data.success) {
        // Asigna los datos obtenidos a formData
        const { customerAddress } = res.data
        console.log('Datos de la dirección del cliente:', customerAddress)
        this.formData = {
          country_id: customerAddress.country_id,
          state_id: customerAddress.state_id,
          city: customerAddress.city,
          county: customerAddress.county,
          phone: customerAddress.phone,
          zip: customerAddress.zip,
          pcode: customerAddress.pcode,
          address_street_1: customerAddress.address_street_1,
          address_street_2: customerAddress.address_street_2,
          type: customerAddress.type,
        }
        console.log('Datos asignados a formData:', this.formData)

        // Si hay un country_id, carga los estados correspondientes
        if (customerAddress.country_id) {
          console.log(
            'Cargando estados para el país con ID:',
            customerAddress.country_id
          )
          this.isLoadingCountryState = true

          // Filtra el país seleccionado
          this.billing_country = this.countries.find(
            (c) => c.id == this.formData.country_id
          )
          console.log('País seleccionado:', this.billing_country)

          // Obtiene los estados del país seleccionado
          let res2 = await this.countrySelected(this.billing_country)
          console.log('Respuesta de countrySelected:', res2)

          if (res2 && customerAddress.state_id) {
            // Filtra el estado seleccionado
            this.billing_state = this.states.find(
              (s) => s.id == this.formData.state_id
            )
            console.log('Estado seleccionado:', this.billing_state)
          }

          this.isLoadingCountryState = false
        }
      } else {
        // Si no se obtienen datos, resetea formData
        console.log('No se obtuvieron datos, reseteando formData')
        this.formData = {
          name: null,
          country_id: null,
          state_id: null,
          city: null,
          county: null,
          phone: null,
          zip: null,
          pcode: null,
          address_street_1: null,
          address_street_2: null,
          type: 'services_address',
        }
      }

      // Indica que la carga de datos ha terminado
      console.log('Fin de carga de datos')
      this.isLoading = false
    },
    /**
     * Maneja la selección de un país, actualizando los estados disponibles y reseteando el estado de facturación.
     *
     * @param {Object} country - El país seleccionado, que incluye el código del país.
     * @returns {Promise<Boolean>} - Retorna true si la operación fue exitosa.
     */
    async countrySelected(country) {
      // Resetea el estado de facturación
      this.billing_state = null

      try {
        // Realiza una solicitud para obtener los estados del país seleccionado
        const res = await window.axios.get(`/api/v1/states/${country.code}`)

        // Actualiza la lista de estados con los datos obtenidos
        this.states = res.data.states

        return true
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud
        console.error('Error fetching states:', error)
        return false
      }
    },
    /**
     * Maneja la selección de un estado, actualizando los datos del formulario y el estado de carga.
     *
     * @param {String} state - El estado seleccionado.
     */
    stateSelected(state) {
      // Inicia el estado de carga
      this.isLoadingCountryState = true

      // Actualiza el estado seleccionado en los datos del formulario
      this.formData.state = state

      // Resetea el código postal
      this.formData.pcode = null

      // Finaliza el estado de carga
      this.isLoadingCountryState = false
    },

    /**
     * Cierra el formulario de dirección y redirige a la página de direcciones del cliente.
     */
    closeAddressForm() {
      // Redirige a la página de direcciones del cliente usando el ID del cliente de los parámetros de la ruta
      this.$router.push(`/admin/customers/${this.$route.params.id}/address`)
    },
    /**
     * Verifica el estado del módulo Avalara y actualiza la disponibilidad.
     *
     * @returns {Promise<void>}
     */
    async getStatusModuleAvalara() {
      try {
        // Llama al método para verificar el estado de Avalara
        const response = await this.checkStatusAvalara()

        // Actualiza la disponibilidad de Avalara basado en la respuesta
        this.isAvalaraAvailable = response.data.success
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud
        console.error('Error checking Avalara status:', error)
        this.isAvalaraAvailable = false
      }
    },
  },
}
</script>

<style scoped></style>
