var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pbx-service-details" },
    [
      _c("sw-divider", { staticClass: "my-6" }),
      _vm._v(" "),
      _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("customers.pbxservices_resume")) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.service_number")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.pbx_services_number
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.package_name")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package
                                ? _vm.selectedPbxService.pbx_package
                                    .pbx_package_name
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("packages.status")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    _vm.serviceStatus
                      ? _c("div", [
                          _vm.serviceStatus.value === "A"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.active")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.serviceStatus.value === "P"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "VIEWED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "VIEWED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.pending")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.serviceStatus.value == "S"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor("SENT")
                                            .bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor("SENT")
                                            .color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n       " +
                                          _vm._s(_vm.$t("general.suspended")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.serviceStatus.value == "C"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.cancelled")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.term")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.term
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.discount")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    _vm.allowDiscount === "YES"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("COMPLETED")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("COMPLETED")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.$t("general.yes")) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allowDiscount === "NO"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("OVERDUE")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("OVERDUE")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.$t("general.not")) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.date_begin")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.date_begin
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.type_service")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    _vm.selectedPbxService
                      ? _c("div", [
                          _vm.selectedPbxService.pbx_package
                            ? _c("div", [
                                _vm.selectedPbxService.pbx_package
                                  .status_payment == "postpaid"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n      " +
                                          _vm._s(
                                            _vm.$t("packages.item.postpaid")
                                          ) +
                                          "\n    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectedPbxService.pbx_package
                                  .status_payment == "prepaid"
                                  ? _c("div", [
                                      _vm._v(
                                        " \n      " +
                                          _vm._s(
                                            _vm.$t("packages.item.prepaid")
                                          ) +
                                          "\n    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.server")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package &&
                                _vm.selectedPbxService.pbx_package.server
                                ? _vm.selectedPbxService.pbx_package.server
                                    .server_label
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.tenant")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.tenant
                                ? _vm.selectedPbxService.tenant.name
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.allow_customapprate")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.custom_app_rate_name
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("didFree.item.custom_destination_groups")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.customDestinationsGroupsInbound.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.customDestinationsGroupsInbound,
                            function (customGroupInbound) {
                              return _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(customGroupInbound) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n                   \n                    " +
                                  _vm._s(_vm.$t("providers.not_selected")) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "didFree.item.custom_destination_groups_outbound"
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.customDestinationsGroupsOutbound.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.customDestinationsGroupsOutbound,
                            function (customGroupOutbound) {
                              return _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(customGroupOutbound) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("providers.not_selected")) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.package_price")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.selectedPbxService
                            ? _vm.$utils.formatMoney(
                                _vm.selectedPbxService.pbxpackages_price,
                                _vm.defaultCurrency
                              )
                            : ""
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.package_number")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package
                                ? _vm.selectedPbxService.pbx_package
                                    .packages_number
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.auto_suspension")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    _vm.selectedPbxService
                      ? _c("div", [
                          _vm.selectedPbxService.auto_suspension === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.active")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedPbxService.auto_suspension != 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.inactive")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                  _vm._v(" "),
                  _vm.selectedPbxService.allow_discount == "1"
                    ? _c("div", [
                        _vm.selectedPbxService.discount_term_type == "D"
                          ? _c("div", [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("pbx_services.time_period")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm font-bold leading-5 text-black non-italic",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("general.from")) +
                                        ": " +
                                        _vm._s(
                                          _vm.selectedPbxService.date_from
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", {
                                  staticClass:
                                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm font-bold leading-5 text-black non-italic",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("general.to")) +
                                        ": " +
                                        _vm._s(_vm.selectedPbxService.date_to) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t("pbx_services.time_period")
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.selectedPbxService.time_period +
                                          " " +
                                          _vm.selectedPbxService
                                            .time_period_value
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedPbxService.allow_discount == "1"
                    ? _c("div", [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("pbx_services.discount_type")) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.selectedPbxService.allow_discount_type
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedPbxService.allow_discount == "1"
                    ? _c("div", [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("pbx_services.discount_amount")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.selectedPbxService.allow_discount_type ==
                          "percentage"
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.selectedPbxService
                                          .allow_discount_value
                                      ) +
                                      " %\n                "
                                  ),
                                ]
                              )
                            : _c("p", {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.selectedPbxService
                                      ? _vm.$utils.formatMoney(
                                          _vm.selectedPbxService
                                            .allow_discount_value * 100,
                                          _vm.defaultCurrency
                                        )
                                      : ""
                                  ),
                                },
                              }),
                          _vm._v(" "),
                          _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.selectedPbxService
                                  ? _vm.$utils.formatMoney(
                                      _vm.selectedPbxService.discount_val,
                                      _vm.defaultCurrency
                                    )
                                  : ""
                              ),
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.cap_by_extension")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.cap_extension
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.total_by_extension")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.cap_total
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("pbx_services.inclusive_minutes_consume")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    this.inclusive_minutes_cosumed == null
                      ? _c("div", [
                          _vm._v("\n                0\n              "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    this.inclusive_minutes_cosumed != null
                      ? _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                this.inclusive_minutes_cosumed.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.prorateotal")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.total_prorate / 100
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-gray-500 uppercase sw-section-title mt-6",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("pbx_services.cdr_processes")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 lg:grid-cols-4 mt-5 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.calculated")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.commandosDataCdr.calculated) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.calculated_today")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.commandosDataCdr.calculated_today) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("pbx_services.uncalculated")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.commandosDataCdr.unCalculated) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-1/4" },
                    [
                      false
                        ? _c(
                            "sw-dropdown",
                            { staticClass: "ms-4" },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticClass: "right-5",
                                  attrs: {
                                    slot: "activator",
                                    variant: "primary",
                                  },
                                  slot: "activator",
                                },
                                [_c("p", [_vm._v("Tools Jobs")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.jobInfoService } },
                                [
                                  _c("pencil-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.$t("pbx_services.job_info")) +
                                      "\n                "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.softDeleteService } },
                                [
                                  _c("pencil-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t("pbx_services.soft_delete")
                                      ) +
                                      "\n                "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.hardDeleteService } },
                                [
                                  _c("pencil-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t("pbx_services.hard_delete")
                                      ) +
                                      "\n                "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "text-gray-500 uppercase sw-section-title mt-6",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("pbx_services.jobs_processes")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.calculate")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-badge",
                        {
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.backgroundCalculateStatus
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.backgroundCalculateStatus
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.commandosDataJobs.calculate
                                  ? _vm.$t("general.bg_pro")
                                  : _vm.$t("general.nbg_pro")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.import")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-badge",
                        {
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.backgroundImportStatus
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.backgroundImportStatus
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.commandosDataJobs.import
                                  ? _vm.$t("general.bg_pro")
                                  : _vm.$t("general.nbg_pro")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            variant: "primary",
                            type: "button",
                            size: "lg",
                          },
                          on: { click: _vm.openDateModal },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("general.update_cdrs")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isPrepaid
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-gray-500 uppercase sw-section-title mt-6",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("pbx_services.prepaid_information")) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPrepaid
                ? _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("pbx_services.total_consume")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.defaultCurrency.symbol +
                                    " " +
                                    _vm.total_consume
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("pbx_services.paid_consume")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.defaultCurrency.symbol +
                                    " " +
                                    _vm.paid_consume
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("pbx_services.unpaid_consume")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.unpaid_consume > 0.0
                            ? _c(
                                "sw-badge",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor("I")
                                        .bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor("I").color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.defaultCurrency.symbol +
                                          " " +
                                          _vm.unpaid_consume
                                      ) +
                                      "               \n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.unpaid_consume == 0.0
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.defaultCurrency.symbol +
                                          " " +
                                          _vm.unpaid_consume
                                      ) +
                                      "    \n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("pbx_services.deb_consume")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.defaultCurrency.symbol +
                                    " " +
                                    _vm.total_deb
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "my-8" }),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-8 pb-3 grid col-span-12" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("pbx_services.pbxware_info")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tab-content-slide" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t("pbx_services.act_extensions")
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            }),
                            _vm.package.extensions === 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.yes")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.extensions != 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.not")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("pbx_services.act_did")) +
                                    "\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            }),
                            _vm.package.did === 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.yes")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.did != 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.not")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t("pbx_services.act_call_rating")
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            }),
                            _vm.package.call_ratings === 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.yes")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.call_ratings != 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.not")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t("pbx_services.fixed_server")
                                    ) +
                                    "\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                            }),
                            _vm.package.modify_server === 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "COMPLETED"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.yes")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.modify_server != 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        staticClass: "px-3 py-1",
                                        attrs: {
                                          "bg-color":
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).bgColor,
                                          color:
                                            _vm.$utils.getBadgeStatusColor(
                                              "OVERDUE"
                                            ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n        " +
                                            _vm._s(_vm.$t("general.not")) +
                                            "\n        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.package.extensions || _vm.package.did
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                            },
                            [
                              _vm.package.extensions
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.pro_ext_name"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.package.profile_extensions
                                                .name
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.package.extensions
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.pro_ext_price"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                _vm.package.profile_extensions
                                                  .rate
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.package.did
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.pro_ext_name2"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.package.profile_did.name
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.package.did
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.pro_ext_price2"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                _vm.package.profile_did.did_rate
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.package.call_ratings
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                            },
                            [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "pbx_services.int_dialing_code"
                                          )
                                        ) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm font-bold leading-5 text-black non-italic",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.package.international_dialing_code
                                        ) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "pbx_services.nac_dialing_code"
                                          )
                                        ) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm font-bold leading-5 text-black non-italic",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.package.national_dialing_code
                                        ) +
                                        "\n                      "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.package.call_ratings
                                ? _c("div", [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.rate_per_min"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-sm font-bold leading-5 text-black non-italic",
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.defaultCurrency.symbol +
                                                  " " +
                                                  _vm.package.rate_per_minutes
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.package.call_ratings
                                ? _c("div", [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.min_increments"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-sm font-bold leading-5 text-black non-italic",
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.package.minutes_increments +
                                                  " " +
                                                  _vm.package
                                                    .type_time_increment
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-5 pb-3" }),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid gap-3 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.selectedPbxService
                                    ? _vm.$utils.formatMoney(
                                        _vm.totalPbxService,
                                        _vm.defaultCurrency
                                      )
                                    : ""
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.recurring_charge) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("estimate-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.defaultCurrency.symbol +
                                    " " +
                                    _vm.total_consume
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.additional_charge) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("contact-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.selectedPbxService
                                      ? _vm.selectedPbxService.renewal_date
                                      : ""
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("pbx_services.renewal_date")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("invoice-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.getStatusPayment == "prepaid"
                    ? _c(
                        "router-link",
                        {
                          staticClass:
                            "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                          attrs: { slot: "item-title", to: "" },
                          slot: "item-title",
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-sm font-semibold leading-tight text-black xl:text-2xl",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.selectedViewCustomer &&
                                        _vm.selectedViewCustomer.customer
                                        ? _vm.defaultCurrency.symbol +
                                            " " +
                                            _vm.selectedViewCustomer.customer.balance.toFixed(
                                              2
                                            )
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pbx_services.credit")) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c("dollar-icon", {
                                staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-8" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("corePbx.dashboard.extensions")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-end",
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass: "w-full md:w-auto mb-2 md:mb-0",
                                attrs: { variant: "primary", size: "lg" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openAddExtModal()
                                  },
                                },
                              },
                              [
                                _c("plus-icon", {
                                  staticClass: "w-6 h-6 mr-1 -ml-2",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("corePbx.tenants.add")) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "ext_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchExtensionsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "name",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(row.name) +
                                          "\n\n\n                        "
                                      ),
                                      row.invoice_prorate == 0 &&
                                      row.date_prorate != null
                                        ? _c("div", [
                                            _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.warning_service9"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(row.date_prorate)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.warning_service10"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(row.prorate / 100)
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.number"),
                                show: "ext",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.location"),
                                show: "location",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.status"),
                                show: "status",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                label: _vm.$tc("pbx_services.pro_ext_name"),
                                show: "profile_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                label: _vm.$tc("pbx_services.pro_ext_price"),
                                show: "profile_rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "pbx_services.pro_ext_price"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                row.price.toFixed(2)
                                            ) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                filterable: false,
                                "cell-class": "action-dropdown no-click",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdateExtModal(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("pencil-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("general.edit")
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdatePriceExtModal(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("refresh-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.modify_price"
                                                        )
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.extensionCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.defaultCurrency.symbol +
                                                  " " +
                                                  this.extensionTotal.toFixed(2)
                                              ) +
                                              "\n                          "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("corePbx.dashboard.did")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-end",
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass: "w-full md:w-auto mb-2 md:mb-0",
                                attrs: { variant: "primary", size: "lg" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openAddDidModal()
                                  },
                                },
                              },
                              [
                                _c("plus-icon", {
                                  staticClass: "w-6 h-6 mr-1 -ml-2",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("corePbx.tenants.add")) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchDIDsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.did_channel"),
                                show: "number",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(row.number) +
                                          "\n\n\n                        "
                                      ),
                                      row.invoice_prorate == 0 &&
                                      row.date_prorate != null
                                        ? _c("div", [
                                            _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.warning_service9"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(row.date_prorate)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.warning_service10"
                                                    )
                                                  ) + ": "
                                                ),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(row.prorate / 100)
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.destination"),
                                show: "ext",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                label: _vm.$t("general.did_plantilla"),
                                show: "profile_name",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      row.name_prefix
                                        ? _c("div", [
                                            row.name_prefix != null
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(row.name_prefix) +
                                                      "\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !row.name_prefix
                                        ? _c("div", [
                                            row.name_prefix == null
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(row.profile_name) +
                                                      "\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                label: _vm.$tc("pbx_services.pro_ext_price2"),
                                show: "profile_rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "pbx_services.pro_ext_price2"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.profile_rate
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                filterable: false,
                                "cell-class": "action-dropdown no-click",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdateDIDModal(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("pencil-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("general.edit")
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdatePriceDIDModal(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("refresh-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.modify_price"
                                                        )
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.didCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                this.didTotal.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("corePbx.items")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-end",
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass: "w-full md:w-auto mb-2 md:mb-0",
                                attrs: { variant: "primary", size: "lg" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openAddItemModal()
                                  },
                                },
                              },
                              [
                                _c("plus-icon", {
                                  staticClass: "w-6 h-6 mr-1 -ml-2",
                                }),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("corePbx.tenants.add")) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchItemsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "name",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.name"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c("b", [
                                          _vm._v(" " + _vm._s(row.name) + " "),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      row.end_period_act == 1 ||
                                      row.end_period_act == true
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "packages.end_period_act"
                                                      )
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "packages.discount_enabled"
                                                      )
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "packages.end_period_num"
                                                      )
                                                    ) + ": "
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.end_period_number
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.description"),
                                show: "description",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.quantity"),
                                show: "quantity",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.discount"),
                                show: "discount",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc("pbx_services.discount")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex flex-auto",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          row.discount_type == "fixed"
                                            ? _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm.defaultCurrency
                                                          .symbol
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(row.discount)),
                                          ]),
                                          _vm._v(" "),
                                          row.discount_type == "percentage"
                                            ? _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _vm._v(
                                                    "\n                            %\n                          "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.total"),
                                show: "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.total"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                row.total,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.itemCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                this.itemTotal,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("corePbx.charges")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchAdditionalChargesData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "description",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.price"),
                                show: "amount",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.price"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.amount
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type_from"),
                                show: "type_from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.profile_name"),
                                show: "profile_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.quantity"),
                                show: "quantity",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.total"),
                                show: "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.total"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.total
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.chargesCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                this.chargesTotal.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-8" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("general.warning_service15")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(5),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "ext_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCustomAppRateData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "app_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("general.quantity"),
                                show: "quantity",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("general.price"),
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          "\n\n                          " +
                                            _vm._s(_vm.$t("general.price")) +
                                            "\n                        "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.costo * 100
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          \n\n                          " +
                                            _vm._s(
                                              _vm.$t("general.tquantity")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.totalQuantity)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n\n                          " +
                                            _vm._s(_vm.$t("general.tprice")) +
                                            "\n                         \n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                _vm.totalCosto * 100
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-8" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    \n\n                    " +
                                _vm._s(_vm.$t("general.taxes")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "taxes_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.taxes,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("tax_types.percent"),
                                show: "percent",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("general.type"),
                                show: "type_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("general.amount"),
                                "sort-as": "amount",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.type != "cdr"
                                        ? _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.amount,
                                                  _vm.defaultCurrency
                                                )
                                              ),
                                            },
                                          })
                                        : _c("span", [_vm._v("N/A")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("general.tquantity")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.taxes.length)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.$t("general.tprice")) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                _vm.totalTaxes
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("corePbx.dashboard.call_history")
                                ) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "cdr_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCallHistoryData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("corePbx.dashboard.from"),
                                show: "from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.to"),
                                show: "to",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.date"),
                                show: "formatted_start_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.cdr_type"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.type == 0
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.inbound"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.type == 1
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.outbound"))
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totald"),
                                show: "formatted_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totalr"),
                                show: "formatted_round_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.amount"),
                                show: "cost",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.billed_at == null
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.pending"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.billed_at != null
                                        ? _c("div", [
                                            row.exclusive_seconds == 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            0\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.exclusive_seconds != 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        row.getFormattedExcusiveCost
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-full flex justify-end" },
                          [
                            _c(
                              "sw-button",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: `/admin/customers/${_vm.$route.params.id}/pbx-service/${_vm.$route.params.pbx_service_id}/callHistory`,
                                  variant: "primary-outline",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("general.search")) +
                                    "\n                      "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block mt-5 mb-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.all_cdr")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.callHistoryCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cdr")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(this.billed_cdr.toFixed(2))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_time")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.billed_time)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cost")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " $ " +
                                              _vm._s(
                                                this.callHistoryTotal.toFixed(2)
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("pbx_services.custom_destinations")
                                ) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(8),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCustomDestinationData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("corePbx.dashboard.from"),
                                show: "from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.to"),
                                show: "to",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.date"),
                                show: "formatted_start_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.cdr_type"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.type == 0
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.inbound"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.type == 1
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.outbound"))
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totald"),
                                show: "formatted_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.rate"),
                                show: "rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.custom_rate != null
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  row.custom_rate
                                                    .rate_per_minute
                                                ) +
                                                "\n                        "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totalr"),
                                show: "formatted_round_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.amount"),
                                show: "cost",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.billed_at == null
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.pending"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.billed_at != null
                                        ? _c("div", [
                                            row.exclusive_seconds == 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            0\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.exclusive_seconds != 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        row.getFormattedExcusiveCost
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v("\n>\n\n                  "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.all_cdr")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(this.customDestinationCount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cdr")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.ctmDestBilledCDR.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_time")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(this.ctmDestBilledTime)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cost")
                                            ) +
                                            ":\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " $ " +
                                              _vm._s(
                                                this.customDestinationTotal.toFixed(
                                                  2
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.getStatusPayment == "prepaid"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t("pbx_services.prepaid_charges")
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-slide" },
                            [
                              _vm._m(9),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "table",
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchPrepaidChargesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("pbx_services.name"),
                                      show: "name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.type"),
                                      show: "type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("corePbx.dashboard.date"),
                                      show: "date",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.price"),
                                      show: "amout",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "pbx_services.price"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.defaultCurrency
                                                        .symbol +
                                                        " " +
                                                        row.amout
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3808490648
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("pbx_services.tax_amount"),
                                      show: "taxamount",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "pbx_services.tax_amount"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.defaultCurrency
                                                        .symbol +
                                                        " " +
                                                        row.taxamount
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2903617288
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                                },
                                [
                                  _c("div", { staticClass: "w-full lg:w-1/2" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-between w-full",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "general.currenttotalprepaidcharges"
                                                    )
                                                  ) +
                                                  ":\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.prepaidtotalcurrent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "general.currenttotalprepaidtaxes"
                                                    )
                                                  ) +
                                                  ":\n                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center text-lg uppercase text-primary-400",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " $ " +
                                                    _vm._s(
                                                      this.prepaidtotalcurrenttax.toFixed(
                                                        2
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-5" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                    \n                    " +
                                _vm._s(_vm.$tc("general.invoices")) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(10),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchInvoicesPerServicePbxData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.number"),
                                show: "invoice_number",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("invoices.number"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "font-medium text-primary-500",
                                          attrs: {
                                            to: `/admin/invoices/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(row.invoice_number) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.date"),
                                show: "invoice_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.status"),
                                "sort-as": "status",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("invoices.status"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-badge",
                                        {
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                row.status.replace("_", " ")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.paid_status"),
                                "sort-as": "paid_status",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("invoices.paid_status"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-badge",
                                        {
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                row.paid_status.replace(
                                                  "_",
                                                  " "
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.total"),
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("general.total"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(row.total)
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.amount_due"),
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("invoices.amount_due"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.due_amount
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: { sortable: false, filterable: false },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("invoices.action"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _vm.IsArchivedActived != true
                                            ? _c(
                                                "span",
                                                [
                                                  _vm.isSuperAdmin &&
                                                  row.noeditable == 0
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/invoices/${row.id}/edit`,
                                                          },
                                                        },
                                                        [
                                                          _c("pencil-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.edit"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isSuperAdmin
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/invoices/${row.id}/view`,
                                                          },
                                                        },
                                                        [
                                                          _c("eye-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.view"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/invoices/${row.id}/view`,
                                                          },
                                                        },
                                                        [
                                                          _c("eye-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.view"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                  _vm._v(" "),
                                                  row.status == "DRAFT" &&
                                                  _vm.isSuperAdmin
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sendInvoice(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "paper-airplane-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.send_invoice"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status === "SENT" ||
                                                  (row.status === "VIEWED" &&
                                                    _vm.isSuperAdmin)
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sendInvoice(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "paper-airplane-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.resend_invoice"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status == "DRAFT" &&
                                                  _vm.isSuperAdmin
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.markInvoiceAsSent(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "check-circle-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.mark_as_sent"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status === "SENT" ||
                                                  row.status === "VIEWED" ||
                                                  (row.status === "OVERDUE" &&
                                                    _vm.isSuperAdmin)
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/payments/${row.id}/create`,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "credit-card-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "payments.record_payment"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.isSuperAdmin
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `#`,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "credit-card-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "payments.record_payment"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isSuperAdmin &&
                                                  row.status != "COMPLETED" &&
                                                  row.paid_status === "UNPAID"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeInvoice(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("trash-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.delete"
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                [
                                                  _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.Restore(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("save-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                              " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.restore"
                                                            )
                                                          ) +
                                                          "\n                            "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase mr-12",
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase ml-12",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.invoicesCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }