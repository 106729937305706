var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "item-create" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitExtension.apply(null, arguments)
          },
        },
      },
      [
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "sw-page-header",
          { attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("corePbx.menu_title.extensions", 2),
                    to: "/admin/corePBX/billing-templates/extensions",
                    active: "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-card", [
          _c(
            "div",
            { staticClass: "flex mt-2 col-span-12" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("corePbx.extensions.name"),
                    error: _vm.nameError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      placeholder: _vm.$t("items.name"),
                      focus: "",
                      type: "text",
                      name: "name",
                      tabindex: "1",
                      placer: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  staticStyle: { "padding-left": "1em" },
                  attrs: {
                    label: _vm.$t("packages.status"),
                    error: _vm.statusError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.status.$error,
                      options: _vm.status,
                      searchable: true,
                      "show-labels": false,
                      tabindex: 2,
                      "allow-empty": true,
                      placeholder: _vm.$t("general.select_status"),
                      label: "text",
                      "track-by": "value",
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "status",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex mt-2 col-span-12" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("corePbx.extensions.price"),
                    error: _vm.rateError,
                    required: "",
                  },
                },
                [
                  _c("sw-money", {
                    attrs: {
                      currency: _vm.defaultCurrencyForInput,
                      name: "rate_per_minutes_selected",
                      invalid: _vm.$v.formData.rate.$error,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.rate.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "rate", $$v)
                      },
                      expression: "formData.rate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex mt-2 col-span-12" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: { label: _vm.$t("item_groups.description") },
                },
                [
                  _c("sw-textarea", {
                    staticStyle: { resize: "none" },
                    attrs: { rows: "5", tabindex: "4", name: "description" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.description.$touch()
                      },
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-2 col-span-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-2 col-span-12" }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "h6",
            { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("corePbx.extensions.additional_charges_titile")
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "table-responsive-item2" }, [
            _c("div", { staticClass: "tablemin" }, [
              _c(
                "table",
                { staticClass: "w-full text-center item-table" },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "50%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "20%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "30%" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "thead",
                    {
                      staticClass:
                        "bg-white border border-gray-200 border-solid",
                    },
                    [
                      _c(
                        "th",
                        {
                          staticClass:
                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _c("span", { staticClass: "pl-12" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$tc("corePbx.extensions.description", 2)
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass:
                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("corePbx.extensions.charge")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass:
                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _c("span", { staticClass: "pr-10" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("corePbx.extensions.status")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      staticClass: "item-body",
                      attrs: { tag: "tbody", handle: ".handle" },
                      model: {
                        value: _vm.formData.aditional_charges,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "aditional_charges", $$v)
                        },
                        expression: "formData.aditional_charges",
                      },
                    },
                    _vm._l(
                      _vm.formData.aditional_charges,
                      function (charge, index) {
                        return _c(
                          "tr",
                          {
                            key: charge.id,
                            staticClass:
                              "box-border bg-white border border-gray-200 border-solid rounded-b",
                            attrs: {
                              index: index,
                              "item-data": charge,
                              "group-items": _vm.formData.aditional_charges,
                              currency: _vm.currency,
                            },
                            on: {
                              remove: _vm.removeExt,
                              update: _vm.updateExt,
                              ExtensionValidate: _vm.checkExtsData,
                              checkExists: _vm.checkExistExt,
                            },
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "p-0 text-left align-top",
                                attrs: { colspan: "5" },
                              },
                              [
                                _c("table", { staticClass: "w-full" }, [
                                  _c("colgroup", [
                                    _c("col", {
                                      staticStyle: { width: "50%" },
                                    }),
                                    _vm._v(" "),
                                    _c("col", {
                                      staticStyle: { width: "20%" },
                                    }),
                                    _vm._v(" "),
                                    _c("col", {
                                      staticStyle: { width: "30%" },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-5 py-4 text-left align-top",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex justify-start",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "sw-input-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "corePbx.extensions.description"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("sw-input", {
                                                    attrs: {
                                                      invalid:
                                                        _vm.$v.formData
                                                          .aditional_charges
                                                          .description.$error,
                                                      focus: "",
                                                      type: "text",
                                                      name: "description",
                                                      tabindex: "6",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$v.formData.aditional_charges.description.$touch()
                                                      },
                                                    },
                                                    model: {
                                                      value: charge.description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          charge,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "charge.description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-5 py-4 text-left align-top",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex justify-start",
                                            },
                                            [
                                              _c(
                                                "sw-input-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "corePbx.extensions.amount"
                                                    ),
                                                    error: _vm.ChargeError,
                                                  },
                                                },
                                                [
                                                  _c("sw-money", {
                                                    ref: "amount",
                                                    refInFor: true,
                                                    attrs: {
                                                      currency:
                                                        _vm.defaultCurrencyForInput,
                                                      name: "amount",
                                                      invalid:
                                                        _vm.$v.formData
                                                          .aditional_charges
                                                          .amount.$error,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$v.formData.aditional_charges.amount.$touch()
                                                      },
                                                    },
                                                    model: {
                                                      value: charge.amount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          charge,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "charge.amount",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "px-5 py-4 text-right align-top",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex items-center justify-end text-sm",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "relative w-12",
                                                },
                                                [
                                                  _c("sw-switch", {
                                                    staticClass: "absolute",
                                                    staticStyle: {
                                                      top: "-20px",
                                                    },
                                                    model: {
                                                      value: charge.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          charge,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "charge.status",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                                                },
                                                [
                                                  _vm.showRemoveExtIcon
                                                    ? _c("trash-icon", {
                                                        staticClass:
                                                          "h-5 text-gray-700",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeExt(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
              on: { click: _vm.addExt },
            },
            [
              _c("plus-icon", { staticClass: "h-5 mr-2" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("corePbx.extensions.add_charge")) +
                  "\n      "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-8 py-2 flex flex-col md:flex-row md:space-x-4" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto",
                  attrs: {
                    loading: _vm.isLoading,
                    type: "submit",
                    variant: "primary",
                    size: "lg",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", {
                        staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("corePbx.extensions.update_extension_button")
                          : _vm.$t("corePbx.extensions.save_extension_button")
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto mt-2 md:mt-0",
                  attrs: {
                    variant: "primary-outline",
                    type: "button",
                    size: "lg",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.cancelForm()
                    },
                  },
                },
                [
                  _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.cancel")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }