var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("corePbx.prefix_groups.view_prefixes_group") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.corePbx"),
                  to: "/admin/corePBX",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("corePbx.prefix_groups.prefix_group", 2),
                  to: "/admin/corePBX/billing-templates/prefix-groups",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.prefixGroup ? _vm.prefixGroup.name : "",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/billing-templates/prefix-groups`,
                    variant: "primary-outline",
                  },
                },
                [
                  _c("arrow-left-icon", { staticClass: "h-4 mr-2 -ml-1" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.back")) + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/billing-templates/prefix-groups/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeGroup(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("corePbx.prefix_groups.basic_info")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [_vm._v("\n            Name\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.prefixGroup ? _vm.prefixGroup.name : "") +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [_vm._v("\n            Status\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.prefixGroup ? _vm.prefixGroup.status : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [_vm._v("\n            Type\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.prefixGroup ? _vm.prefixGroup.type : "") +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("general.description")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.prefixGroup ? _vm.prefixGroup.description : ""
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.prefixGroup ? _vm.prefixGroup.description : ""
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("corePbx.prefix_groups.prefixes")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "float-left mt-1" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected",
                    },
                  ],
                  staticClass: "border border-dark",
                  staticStyle: {
                    "text-align": "center",
                    border: "solid 1px black",
                    width: "77px",
                    height: "39px",
                    "border-radius": "10%",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selected = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.refreshTable()
                      },
                    ],
                  },
                },
                _vm._l(_vm.options, function (option) {
                  return _c(
                    "option",
                    {
                      staticStyle: { "text-align": "center" },
                      domProps: { value: option.value },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(option.name) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "sw-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fetchData,
                        expression: "fetchData",
                      },
                    ],
                    staticClass: "ml-4",
                    attrs: { size: "lg", variant: "primary-outline" },
                    on: { click: _vm.toggleFilter },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.filter")) +
                        "\n          "
                    ),
                    _c(_vm.filterIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "slide-y-up-transition",
                  [
                    _c(
                      "sw-filter-wrapper",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showFilters,
                            expression: "showFilters",
                          },
                        ],
                        staticClass: "relative grid grid-flow-col grid-rows",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "w-50",
                            staticStyle: {
                              "margin-left": "1em",
                              "margin-right": "1em",
                            },
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Categories" },
                              },
                              [
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.categories,
                                    "group-select": false,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: "Select a category",
                                    "allow-empty": false,
                                    "group-values": "options",
                                    "group-label": "label",
                                    "track-by": "name",
                                    label: "name",
                                  },
                                  on: {
                                    remove: function ($event) {
                                      return _vm.clearStatusSearch()
                                    },
                                  },
                                  model: {
                                    value: _vm.filters.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filters, "category", $$v)
                                    },
                                    expression: "filters.category",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "min-width": "275px" } },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { label: "Prefix Type" },
                                  },
                                  [
                                    _c("sw-select", {
                                      attrs: {
                                        options: _vm.prefix_type,
                                        "group-select": false,
                                        searchable: true,
                                        "show-labels": false,
                                        placeholder: "Select a prefix type",
                                        "allow-empty": false,
                                        "group-values": "options",
                                        "group-label": "label",
                                        "track-by": "name",
                                        label: "name",
                                      },
                                      on: {
                                        remove: function ($event) {
                                          return _vm.clearStatusSearch()
                                        },
                                      },
                                      model: {
                                        value: _vm.filters.prefix_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "prefix_type",
                                            $$v
                                          )
                                        },
                                        expression: "filters.prefix_type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "w-50",
                            staticStyle: {
                              "margin-left": "1em",
                              "margin-right": "1em",
                            },
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                staticStyle: { "min-width": "275px" },
                                attrs: { label: "Name" },
                              },
                              [
                                _c("sw-input", {
                                  model: {
                                    value: _vm.filters.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filters, "name", $$v)
                                    },
                                    expression: "filters.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.filters.prefix_type.value == "P"
                              ? _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: { "min-width": "275px" },
                                    attrs: { label: "Prefix" },
                                  },
                                  [
                                    _c("sw-input", {
                                      model: {
                                        value: _vm.filters.prefix,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "prefix", $$v)
                                        },
                                        expression: "filters.prefix",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.filters.prefix_type.value == "FT"
                              ? _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: { "min-width": "275px" },
                                    attrs: { label: "From" },
                                  },
                                  [
                                    _c("sw-input", {
                                      model: {
                                        value: _vm.filters.from,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "from", $$v)
                                        },
                                        expression: "filters.from",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "w-50",
                            staticStyle: {
                              "margin-left": "1em",
                              "margin-right": "1em",
                            },
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Countries" },
                              },
                              [
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.countries,
                                    searchable: true,
                                    "show-labels": false,
                                    "allow-empty": false,
                                    placeholder: _vm.$t(
                                      "general.select_country"
                                    ),
                                    label: "name",
                                    "track-by": "id",
                                  },
                                  model: {
                                    value: _vm.filters.country_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filters, "country_id", $$v)
                                    },
                                    expression: "filters.country_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.filters.prefix_type.value == "FT"
                              ? _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: { "min-width": "275px" },
                                    attrs: { label: "To" },
                                  },
                                  [
                                    _c("sw-input", {
                                      model: {
                                        value: _vm.filters.to,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "to", $$v)
                                        },
                                        expression: "filters.to",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass:
                              "absolute text-sm leading-snug text-black cursor-pointer",
                            staticStyle: { top: "20px", right: "30px" },
                            on: { click: _vm.clearFilter },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    attrs: {
                      "show-filter": false,
                      data: _vm.fetchData,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "Name",
                        "sort-as": "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.name) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "Prefix",
                        "sort-as": "prefix",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.prefix) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "From",
                        "sort-as": "from",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.from) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: { sortable: false, label: "To", "sort-as": "to" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.to) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "Category",
                        "sort-as": "category",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              row.category == "C"
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Custom\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.category == "T"
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Toll Free\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.category == "I"
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                International\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "Country",
                        "sort-as": "country_id",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getCountry(
                                          row.country_id ? row.country_id : 0
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        label: "Rate Per Minute",
                        "sort-as": "rate_per_minute",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.defaultCurrency.symbol +
                                          " " +
                                          row.rate_per_minute
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }