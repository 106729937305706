var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hold-invoice-modal w-auto" }, [
    _c("div", { staticClass: "table-container h-96 overflow-auto" }, [
      _c(
        "div",
        {},
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: { sortable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("sw-checkbox", {
                          staticClass: "border-gray-500 rounded-sm",
                          attrs: {
                            id: row.id,
                            value: row.id,
                            variant: "primary",
                            size: "lg",
                          },
                          model: {
                            value: row.selected,
                            callback: function ($$v) {
                              _vm.$set(row, "selected", $$v)
                            },
                            expression: "row.selected",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("core_pos.name"),
                  "sort-as": "name",
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("core_pos.persons"),
                  "sort-as": "persons",
                  show: "Persons",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex flex-row content-center" },
                          [
                            _c(
                              "span",
                              { staticClass: "text-sm" },
                              [
                                _c(
                                  "sw-button",
                                  {
                                    staticClass: "ml-2 w-1/12",
                                    attrs: {
                                      disabled: row.quantity == 0,
                                      variant: "primary-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.decrementQuantity(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  -\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-2 mt-1" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.quantity) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-sm ml-2" },
                              [
                                _c(
                                  "sw-button",
                                  {
                                    staticClass: "ml-2 mt w-1/12",
                                    attrs: { variant: "primary-outline" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.incrementQuantity(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  +\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isEmptyData
        ? _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end px-4 py-4 border-t border-solid border-gray-light",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeTableModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    variant: "primary",
                    icon: "save",
                  },
                  on: { click: _vm.saveTables },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }