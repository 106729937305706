<template>
    <div class="relative">
        <base-loader v-if="isRequestOnGoing" :show-bg-overlay="true" />
        <sw-card>
            <div class="w-full flex justify-end">
                <div class="mb-3 hidden xl:block">
                    <sw-button variant="primary-outline" @click="toggleSideBar">
                    {{ $t('tickets.departaments.menu') }}
                    <component :is="listIcon" class="w-4 h-4 ml-2 -mr-1" />
                    </sw-button>
                </div>
            </div>
            <sw-tabs class="p-2">
                <!-- Logs per Module -->
                <sw-tab-item :title="$t('logs.module_logs.title')">
                    <module-logs />
                </sw-tab-item>

                <!-- Logs per email -->
                <sw-tab-item :title="$t('logs.email_logs.title')">
                    <email-logs />
                </sw-tab-item>

                <!-- Failed payment history -->
                <sw-tab-item v-if="false" :title="$t('logs.failed_payment_history.title')">
                    <failed-payment-history />
                </sw-tab-item>

            </sw-tabs>
        </sw-card>
    </div>
</template>

<script>

import ModuleLogs from "./log-tabs/ModuleLogs.vue";
import EmailLogs from "./log-tabs/EmailLogs.vue";
import FailedPaymentHistory from "./log-tabs/FailedPaymentHistory.vue";
import { XIcon } from '@vue-hero-icons/solid'
import { ClipboardListIcon } from '@vue-hero-icons/outline'

export default {
    components: {
        ModuleLogs,
        EmailLogs,
        FailedPaymentHistory,
        XIcon,
        ClipboardListIcon
    },

    props: {
        showSideBar: {
        type: Boolean,
        required: true
        },
        toggleSideBar: {
        type:Function,
        required: true
        }
    },

    data() {
        return {
            settings: {},
            isRequestOnGoing: false,
        }
    },
    created(){
        this.permissionsUserModule()
    },
    computed: {
        listIcon() {
        return this.showSideBar ? 'x-icon' : 'clipboard-list-icon';
        }
    },
    methods:{
        
        async permissionsUserModule(){
            const data = {
            module: "logs" 
            }
            const permissions = await this.getUserModules(data)
            // valida que el usuario tenga permiso para ingresar al modulo
            if(permissions.super_admin == false){
                if(permissions.exist == false ){
                    this.$router.push('/admin/dashboard')
                }else {
                const modulePermissions = permissions.permissions[0]
                    if(modulePermissions == null){
                    this.$router.push('/admin/dashboard')
                    }else if(modulePermissions.access == 0 ){
                    this.$router.push('/admin/dashboard')
                    }
                }
            }
        }
    },
}
</script>

<style scoped>

</style>