var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "relative" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("expenses.expense", 2),
                    to: "/admin/expenses",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "expenses.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.edit_expense"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.new_expense"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 text-sm hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary-outline",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("expenses.update_expense")
                            : _vm.$t("expenses.save_expense")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _c(
              "sw-card",
              [
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.expense_number"),
                          error: _vm.expenseNumError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: {
                            prefix: `${_vm.expensePrefix} - `,
                            invalid: _vm.$v.expenseNumAttribute.$error,
                            disabled: _vm.isEdit,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.expenseNumAttribute.$touch()
                            },
                          },
                          model: {
                            value: _vm.expenseNumAttribute,
                            callback: function ($$v) {
                              _vm.expenseNumAttribute =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "expenseNumAttribute",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("general.subject"),
                          error: _vm.expenseSubjectError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: { invalid: _vm.$v.formData.subject.$error },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.subject.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.subject,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "subject",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.subject",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("payments.status") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.statusOptions,
                            "allow-empty": false,
                            label: "label",
                            "track-by": "value",
                          },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.category"),
                          error: _vm.categoryError,
                          required: "",
                        },
                      },
                      [
                        _c(
                          "sw-select",
                          {
                            ref: "baseSelect",
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.categories,
                              invalid: _vm.$v.category.$error,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t(
                                "expenses.categories.select_a_category"
                              ),
                              label: "name",
                              "track-by": "id",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.category.$touch()
                              },
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass:
                                  "flex items-center justify-center w-full px-4 py-3 bg-gray-200 border-none outline-none",
                                attrs: {
                                  slot: "afterList",
                                  type: "button",
                                  variant: "gray-light",
                                },
                                on: { click: _vm.openCategoryModal },
                                slot: "afterList",
                              },
                              [
                                _c("shopping-cart-icon", {
                                  staticClass:
                                    "h-5 text-center text-primary-400",
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ml-2 text-xs leading-none text-primary-400",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "settings.expense_category.add_new_category"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.expense_date"),
                          error: _vm.dateError,
                          required: "",
                        },
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.expense_date.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.expense_date.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.expense_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "expense_date", $$v)
                            },
                            expression: "formData.expense_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex mb-4 mt-8" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12 ml-1" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-18px" },
                            model: {
                              value: _vm.formData.notification,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "notification", $$v)
                              },
                              expression: "formData.notification",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-2 mt-1" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("expenses.notification")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.customer") } },
                      [
                        _vm.customer
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.customer = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.customerOptions,
                            searchable: true,
                            "show-labels": false,
                            disabled: _vm.isCreate,
                            placeholder: _vm.$t("customers.select_a_customer"),
                            label: "name",
                            "track-by": "id",
                          },
                          on: { select: _vm.CustomerSelected },
                          model: {
                            value: _vm.customer,
                            callback: function ($$v) {
                              _vm.customer = $$v
                            },
                            expression: "customer",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("corePbx.items") } },
                      [
                        _vm.item
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.item = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.itemOptions,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("expenses.item_select"),
                            label: "name",
                            "track-by": "id",
                          },
                          model: {
                            value: _vm.item,
                            callback: function ($$v) {
                              _vm.item = $$v
                            },
                            expression: "item",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.provider") } },
                      [
                        _vm.provider
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.provider = null
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.providerOptions,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("expenses.provider_select"),
                            label: "title",
                            "track-by": "id",
                            invalid: _vm.isProviderRequired,
                          },
                          model: {
                            value: _vm.provider,
                            callback: function ($$v) {
                              _vm.provider = $$v
                            },
                            expression: "provider",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.allow_invoice_form_pos
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            attrs: { label: _vm.$t("core_pos.store") },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.stores,
                                searchable: true,
                                "show-labels": true,
                                "allow-empty": true,
                                multiple: false,
                                "track-by": "id",
                                label: "name",
                                tabindex: 1,
                              },
                              model: {
                                value: _vm.store_selected,
                                callback: function ($$v) {
                                  _vm.store_selected = $$v
                                },
                                expression: "store_selected",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive-item2" }, [
                  _c(
                    "table",
                    { staticClass: "w-full text-center item-table tablemin" },
                    [
                      _c("colgroup", [
                        _c("col", { staticStyle: { width: "15%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "12.5%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "35%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "12.5%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "15%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "10%" } }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "thead",
                        {
                          staticClass:
                            "bg-white border border-gray-200 border-solid",
                        },
                        [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("invoices.invoice_number")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _c("span", { staticClass: "pr-0" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t("invoices.invoices_subtotal")
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("packages.taxes")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("invoices.total_tax")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("invoices.total")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.formData.invoices, function (invoice, index) {
                        return _c("expense-invoice", {
                          key: index,
                          attrs: {
                            index: index,
                            "invoice-data": invoice,
                            currency: _vm.defaultCurrency,
                            "taxes-options": _vm.taxes,
                            provider: _vm.provider,
                          },
                          on: {
                            update: _vm.updateInvoice,
                            remove: _vm.removeInvoice,
                            invoiceValidate: _vm.checkInvoiceData,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center w-full px-6 py-3 text-base border-r border-b border-l border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                    on: { click: _vm.addItem },
                  },
                  [
                    _c("plus-sm-icon", { staticClass: "h-5 mr-2" }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("invoices.add_invoice")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.amount"),
                          error: _vm.amountError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-money", {
                          staticClass:
                            "focus:border focus:border-solid focus:border-primary-500",
                          attrs: {
                            currency: _vm.defaultCurrencyForInput,
                            invalid: _vm.$v.formData.amount.$error,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.amount.$touch()
                            },
                          },
                          model: {
                            value: _vm.amount,
                            callback: function ($$v) {
                              _vm.amount =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.select_payment") } },
                      [
                        _vm.payment
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "absolute text-gray-400 cursor-pointer",
                                staticStyle: {
                                  top: "36px",
                                  right: "5px",
                                  "z-index": "999999",
                                },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.payment = null),
                                      (_vm.formData.payment_method = null)
                                  },
                                },
                              },
                              [_c("x-circle-icon", { staticClass: "h-5" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.payments,
                            searchable: true,
                            "show-labels": false,
                            disabled: _vm.isCreate,
                            placeholder: _vm.$t(
                              "expenses.select_payment_holder"
                            ),
                            label: "paynumber",
                            "track-by": "id",
                          },
                          on: { select: _vm.PaymentSelected },
                          model: {
                            value: _vm.payment,
                            callback: function ($$v) {
                              _vm.payment = $$v
                            },
                            expression: "payment",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "d-flex justify-content-center",
                        attrs: {
                          label: _vm.$t("expenses.expense_date_pay"),
                          error: _vm.paymentDateError,
                          required: _vm.formData.status.value == "Active",
                        },
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.payment_date.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.payment_date.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.payment_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "payment_date", $$v)
                            },
                            expression: "formData.payment_date",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-button",
                          {
                            staticClass: "mt-2",
                            attrs: { variant: "primary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.clearPaymentDate()
                              },
                            },
                          },
                          [
                            _c("trash-icon", { staticClass: "mr-2 -ml-1" }),
                            _vm._v(
                              "\n            Clear Payment Date\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("payments.payment_mode") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.paymentModes,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("payments.select_payment_mode"),
                            "max-height": 150,
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.payment_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "payment_method", $$v)
                            },
                            expression: "formData.payment_method",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 mt-4" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("expenses.note"),
                          error: _vm.notesError,
                        },
                      },
                      [
                        _c("sw-editor", {
                          attrs: {
                            "set-editor": _vm.formData.notes,
                            rows: "2",
                            name: "notes",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.notes.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.notes,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "notes", $$v)
                            },
                            expression: "formData.notes",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.receipt") } },
                      [
                        _vm.previewReceipt
                          ? _c(
                              "div",
                              { staticClass: "w-full flex justify-end mb-3" },
                              [
                                _vm.isReceiptAvailable
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-gray-500 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                        attrs: {
                                          href: _vm.getReceiptUrl,
                                          "tag-name": "a",
                                        },
                                      },
                                      [
                                        _c("DownloadIcon", {
                                          staticClass: "h-5 w-5",
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-gray-500 hover:text-warning mx-1 rounded-full border border-gray-400 hover:border-warning cursor-pointer p-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.receiptUpload.click()
                                      },
                                    },
                                  },
                                  [
                                    _c("SwitchHorizontalIcon", {
                                      staticClass: "h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-gray-500 hover:text-danger mx-1 rounded-full border border-gray-400 hover:border-danger cursor-pointer p-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePdfReceipt()
                                      },
                                    },
                                  },
                                  [_c("XIcon", { staticClass: "h-5 w-5" })],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { attrs: { id: "receipt-box" } }, [
                          _vm.previewReceipt
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "relative flex items-center justify-center h-24 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box hover:bg-gray-200",
                                  class: {
                                    previewHeight: _vm.previewReceipt !== null,
                                  },
                                },
                                [
                                  _vm.previewReceipt !== null
                                    ? _c("iframe", {
                                        attrs: {
                                          src: _vm.previewReceipt,
                                          width: "100%",
                                          height: "100%",
                                        },
                                      })
                                    : _c("img", {
                                        staticClass:
                                          "absolute opacity-100 preview-logo",
                                        staticStyle: {
                                          "max-height": "80%",
                                          animation: "fadeIn 2s ease",
                                        },
                                        attrs: { src: _vm.previewReceipt },
                                      }),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col items-center border-2 border-gray-200 border-dashed rounded-md py-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.receiptUpload.click()
                                    },
                                  },
                                },
                                [
                                  _c("cloud-upload-icon", {
                                    staticClass:
                                      "h-5 mb-2 text-xl leading-6 text-gray-400",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-xs leading-4 text-center text-gray-400",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Drag a file here or\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer text-primary-500",
                                          attrs: { id: "pick-avatar" },
                                        },
                                        [_vm._v("browse")]
                                      ),
                                      _vm._v(
                                        "\n                to choose a file\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          ref: "receiptUpload",
                          staticClass: "form-control hidden",
                          attrs: { type: "file", multiple: "" },
                          on: { change: _vm.onChange },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "my-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full mt-4" },
                  [
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("expenses.docs") } },
                      [
                        _c("label", { attrs: { for: "uploadDocs" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "hover:bg-gray-200 py-2 cursor-pointer md:w-1/3 mt-5 bg-transparent border-2 border-gray-400 border-dashed rounded-md",
                              attrs: { id: "docs-box " },
                            },
                            [
                              _c("input", {
                                staticClass: "form-control hidden uploadDocs",
                                attrs: {
                                  id: "uploadDocs",
                                  type: "file",
                                  multiple: "",
                                },
                                on: { change: _vm.onChangeDocs },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex flex-col items-center" },
                                [
                                  _c("cloud-upload-icon", {
                                    staticClass:
                                      "h-5 mb-2 text-xl leading-6 text-gray-400",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-xs leading-4 text-center text-gray-400",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-primary-500",
                                          attrs: { id: "pick-avatar" },
                                        },
                                        [_vm._v("browse")]
                                      ),
                                      _vm._v(
                                        "\n                  to choose a file\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap" },
                          _vm._l(_vm.formData.docs, function (file, indexTr) {
                            return _c(
                              "div",
                              {
                                key: indexTr,
                                staticClass: "px-1 py-2 w-full md:w-1/3",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-transparent border-2 border-gray-400 border-dashed rounded-md",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "p-3 flex flex-wrap" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "mb-2 w-full truncate",
                                            class: {
                                              downloadFilename: _vm.isEdit,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFile(file)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(file.file_name) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          { staticClass: "mb-2 w-full" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("expenses.size")
                                                ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.sizeFile(file.size)
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex w-full" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pointer",
                                                class: {
                                                  downloadFilename: _vm.isEdit,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.OpenSeeDocumentExpensesModal(
                                                      file
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("eye-icon", {
                                                  staticClass:
                                                    "h-5 mr-2 text-gray-600",
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteFile(
                                                      indexTr
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("trash-icon", {
                                                  staticClass:
                                                    "h-5 mr-2 text-gray-600",
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("sw-input-group"),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.customFields.length > 0
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid gap-6 mt-6 grid-col-1 md:grid-cols-2",
                        },
                        _vm._l(_vm.customFields, function (field, index) {
                          return _c(
                            "sw-input-group",
                            {
                              key: index,
                              attrs: {
                                label: field.label,
                                required: field.is_required ? true : false,
                              },
                            },
                            [
                              _c(field.type + "Field", {
                                tag: "component",
                                attrs: {
                                  type: field.type.label,
                                  field: field,
                                  "is-edit": _vm.isEdit,
                                  "invalid-fields": _vm.invalidFields,
                                },
                                on: { update: _vm.setCustomFieldValue },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary-outline",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass:
                      "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("expenses.update_expense")
                            : _vm.$t("expenses.save_expense")
                        ) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }