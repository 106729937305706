export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'

export const BOOTSTRAP_CURRENT_USER = 'BOOTSTRAP_CURRENT_USER'

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR'

export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE'

export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO'

export const SET_PRIMARY_COLOR = 'SET_PRIMARY_COLOR'

export const BOOTSTRAP_MODULES_ACTIVE = 'BOOTSTRAP_MODULES_ACTIVE'

export const BOOTSTRAP_SETTINGS_COMPANY = 'BOOTSTRAP_SETTINGS_COMPANY'
