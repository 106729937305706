var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-64": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("reports.reports_PBX") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("reports.reports_PBX"),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/packages`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-3 hidden xl:block" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleListCustomers },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("tickets.departaments.menu")) +
                          "\n          "
                      ),
                      _c(_vm.listIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "relative flex flex-wrap bg-gray-200 mt-4 rouded-lg p-4",
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2 md:w-1/2 md:pr-2",
              attrs: { label: _vm.$t("general.date_range") },
            },
            [
              _c("date-range-picker", {
                staticClass: "w-full rounded date-range-picker",
                attrs: {
                  "time-picker": "",
                  "time-picker-increment": 5,
                  "time-picker24-hour": "",
                  "append-to-body": "",
                  ranges: _vm.rangesPresets,
                },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function (picker) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formatDate(picker)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function (data) {
                      return _c("div", { staticClass: "slot flex mb-1 mr-1" }, [
                        _c(
                          "div",
                          { staticClass: "ml-auto flex items-center" },
                          [
                            !data.in_selection
                              ? _c(
                                  "sw-button",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      variant: "primary-outline",
                                      size: "sm",
                                    },
                                    on: { click: data.clickApply },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.search")) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    },
                  },
                ]),
                model: {
                  value: _vm.filters.dateRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "dateRange", $$v)
                  },
                  expression: "filters.dateRange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2 md:w-1/2 md:pr-2",
              attrs: { label: _vm.$t("general.type") },
            },
            [
              _c("sw-select", {
                attrs: {
                  options: _vm.dcrTypeOptions,
                  "group-select": false,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t("general.type"),
                  "allow-empty": false,
                },
                model: {
                  value: _vm.typeSearch,
                  callback: function ($$v) {
                    _vm.typeSearch = $$v
                  },
                  expression: "typeSearch",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.typeSearch == "Department"
            ? _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 md:w-1/3 md:pr-2",
                  attrs: { label: _vm.$t("general.departments") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.departmentOptions,
                      "group-select": false,
                      searchable: true,
                      multiple: true,
                      "show-labels": false,
                      placeholder: _vm.$t("general.departments"),
                      "allow-empty": true,
                      label: "name",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.filters.departments,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "departments", $$v)
                      },
                      expression: "filters.departments",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.typeSearch == "Tenant" || _vm.typeSearch == "Extension"
            ? _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 md:w-1/3 md:pr-2",
                  attrs: { label: _vm.$t("general.pbx_tenant") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.pbxTenantsOptions,
                      "group-select": false,
                      searchable: true,
                      multiple: false,
                      "show-labels": false,
                      placeholder: _vm.$t("general.pbx_tenant"),
                      "allow-empty": false,
                      label: "name",
                    },
                    on: { select: _vm.getExtensions },
                    model: {
                      value: _vm.filters.tenant,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "tenant", $$v)
                      },
                      expression: "filters.tenant",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.typeSearch == "Extension"
            ? _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 md:w-1/3 md:pr-2",
                  attrs: { label: _vm.$t("general.extension") },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.extensionOptions,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      multiple: true,
                      "track-by": "id",
                      label: "name",
                    },
                    model: {
                      value: _vm.filters.extension,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "extension", $$v)
                      },
                      expression: "filters.extension",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.typeSearch == "Tenant" || _vm.typeSearch == "Extension"
            ? _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 md:w-1/4 md:pr-2",
                  attrs: {
                    label: _vm.$t("general.include_suspended_services"),
                  },
                },
                [
                  _c("sw-checkbox", {
                    model: {
                      value: _vm.includeServicesSuspended,
                      callback: function ($$v) {
                        _vm.includeServicesSuspended = $$v
                      },
                      expression: "includeServicesSuspended",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass:
                "absolute text-sm leading-snug text-black cursor-pointer",
              staticStyle: { top: "10px", right: "15px" },
              on: { click: _vm.resetForm },
            },
            [_vm._v(_vm._s(_vm.$t("general.clear_all")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full flex flex-wrap items-end justify-end mt-3" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "md:mx-1",
                  attrs: {
                    loading: _vm.isRequestOngoing,
                    disabled: _vm.isRequestOngoing,
                    variant: "primary-outline",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.searchReportCdrMetho()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.search")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full flex flex-wrap items-end justify-end mt-3" },
        [
          _c("h4", { staticClass: "text-lg text-primary mt-3" }, [
            _vm._v("Total:"),
          ]),
          _vm._v(" "),
          _c("h4", { staticClass: "text-lg text-primary mt-3 ml-1" }, [
            _vm._v(_vm._s(_vm.quantityExtensions)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overflow-x-auto" }, [
        _c(
          "table",
          {
            staticClass:
              "w-full item-table bg-white border border-gray-200 border-solid",
          },
          [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.reportsCDRDataHeader, function (head, headIndex) {
                  return _c(
                    "th",
                    {
                      key: headIndex,
                      staticClass:
                        "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid bg-gray-50",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " + _vm._s(head) + "\n            "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.reportsCDRData, function (tr, indexTr) {
              return _c(
                "tbody",
                {
                  key: indexTr,
                  class: { "bg-primary-100": indexTr % 2 !== 0 },
                },
                [
                  _c("tr", { staticClass: "py-5" }, [
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                        attrs: { rowspan: "4" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.extensionName) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                        attrs: { rowspan: "4" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.department) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                        attrs: { rowspan: "4" },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(tr.service) + "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.inbound")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.inbound.today) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.inbound.last24Hours) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.inbound.totalCalls) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(tr.items.inbound.totalCalls) /
                                _vm.dayDiff
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.parseNumber(tr.items.inbound.totalTime)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(tr.items.inbound.totalTime) /
                                _vm.parseNumber(
                                  tr.items.inbound.totalCalls > 0
                                    ? tr.items.inbound.totalCalls
                                    : 1
                                )
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.inbound.callAnswered) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.inbound.callUnaswered) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.outbound")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.outbound.today) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.outbound.last24Hours) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.outbound.totalCalls) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(tr.items.outbound.totalCalls) /
                                _vm.dayDiff
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.parseNumber(tr.items.outbound.totalTime)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(
                                  tr.items.outbound.totalTime > 0
                                    ? tr.items.outbound.totalTime
                                    : 0
                                ) /
                                _vm.parseNumber(
                                  tr.items.outbound.totalCalls > 0
                                    ? tr.items.outbound.totalCalls
                                    : 1
                                )
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.outbound.callAnswered) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.outbound.callUnaswered) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [_vm._v("\n            Total\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.global.today) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.global.last24Hours) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.global.totalCalls) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(tr.items.global.totalCalls) /
                                _vm.dayDiff
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.parseNumber(tr.items.global.totalTime)) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (
                                _vm.parseNumber(
                                  tr.items.global.totalTime > 0
                                    ? tr.items.global.totalTime
                                    : 0
                                ) /
                                _vm.parseNumber(
                                  tr.items.global.totalCalls > 0
                                    ? tr.items.global.totalCalls
                                    : 1
                                )
                              ).toFixed(2)
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.global.callAnswered) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "p-5 whitespace-nowrap border-t border-b border-gray-200 border-solid",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(tr.items.global.callUnaswered) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.notData && _vm.reportsCDRData.length == 0
          ? _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap justify-center items-center text-center p-3",
              },
              [
                _c(
                  "h2",
                  { staticClass: "text-xl font-bold text-primary mt-3" },
                  [_vm._v(" " + _vm._s(_vm.$t("general.warning_service20")))]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }