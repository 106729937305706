var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitTicket.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass:
                          "flex justify-center w-full lg:w-auto mr-2",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary-outline",
                          type: "button",
                          size: "lg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelForm()
                          },
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "flex justify-center w-full md:w-auto",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("customer_ticket.update_items_ticket")
                                : _vm.$t("customer_ticket.save_items_ticket")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("customer_ticket.summary"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.summary.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.summary.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.summary,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "summary",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.summary",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("customer_ticket.ticket_number"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              prefix: `${_vm.ticketPrefix} - `,
                              disabled: _vm.isEdit,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.ticketNumAttribute,
                              callback: function ($$v) {
                                _vm.ticketNumAttribute =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "ticketNumAttribute",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticClass:
                            "w-full item-table bg-white border border-gray-200 border-solid mt-4",
                        },
                        [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "26%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "22%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "26%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "26%" } }),
                          ]),
                          _vm._v(" "),
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$tc("customer_ticket.departament")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s("*") +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("customer_ticket.assignedTo")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s("*") +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("customer_ticket.priority")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("customer_ticket.status")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _c("tr", { staticClass: "py-3" }, [
                              _c(
                                "td",
                                { staticClass: "px-5" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        error: _vm.selectDepartamentError,
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        ref: "baseSelect",
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.departaments,
                                          invalid: _vm.$v.dep_id.$error,
                                          searchable: true,
                                          "show-labels": false,
                                          tabindex: 16,
                                          "allow-empty": true,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        on: { select: _vm.getUserDep },
                                        model: {
                                          value: _vm.dep_id,
                                          callback: function ($$v) {
                                            _vm.dep_id = $$v
                                          },
                                          expression: "dep_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mb-4",
                                      attrs: { error: _vm.selectAssignedError },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.assignedTo,
                                          searchable: true,
                                          "show-labels": false,
                                          tabindex: 16,
                                          "allow-empty": true,
                                          label: "name",
                                          "track-by": "id",
                                          invalid: _vm.$v.assigned_id.$error,
                                        },
                                        model: {
                                          value: _vm.assigned_id,
                                          callback: function ($$v) {
                                            _vm.assigned_id = $$v
                                          },
                                          expression: "assigned_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "px-5" },
                                [
                                  _c(
                                    "sw-input-group",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.default_prioritys,
                                          searchable: true,
                                          "show-labels": false,
                                          tabindex: 16,
                                          "allow-empty": true,
                                          label: "text",
                                          "track-by": "value",
                                        },
                                        model: {
                                          value: _vm.priority,
                                          callback: function ($$v) {
                                            _vm.priority = $$v
                                          },
                                          expression: "priority",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "px-5" },
                                [
                                  _c(
                                    "sw-input-group",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.status_options,
                                          searchable: true,
                                          "show-labels": false,
                                          tabindex: 16,
                                          "allow-empty": true,
                                          label: "text",
                                          "track-by": "value",
                                        },
                                        model: {
                                          value: _vm.status,
                                          callback: function ($$v) {
                                            _vm.status = $$v
                                          },
                                          expression: "status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4 mt-4",
                          attrs: {
                            label: _vm.$t("customer_ticket.details"),
                            error: _vm.descriptionError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-textarea", {
                            staticStyle: { resize: "none" },
                            attrs: {
                              rows: "5",
                              name: "note",
                              invalid: _vm.$v.formData.note.$error,
                            },
                            model: {
                              value: _vm.formData.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "note", $$v)
                              },
                              expression: "formData.note",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("customer_ticket.user") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getItemUsers,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "item_user_id",
                              label: "item_user_name",
                            },
                            model: {
                              value: _vm.formData.user_groups,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "user_groups", $$v)
                              },
                              expression: "formData.user_groups",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("customer_ticket.services") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getServices,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "service_id",
                              label: "service_code",
                              tabindex: 7,
                            },
                            model: {
                              value: _vm.formData.services,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "services", $$v)
                              },
                              expression: "formData.services",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("customer_ticket.pbx_services"),
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getPbxServices,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "service_id",
                              label: "service_code",
                              tabindex: 7,
                            },
                            model: {
                              value: _vm.formData.pbxServices,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "pbxServices", $$v)
                              },
                              expression: "formData.pbxServices",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }