var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.pbx_service_view") } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/view`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-select", {
                staticClass: "mr-3",
                attrs: {
                  options: _vm.getStatuses,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t("customers.select_a_status"),
                  label: "status_name",
                  disabled: _vm.isHiddenStatus,
                },
                on: { select: (item) => _vm.changeStatus(item) },
                model: {
                  value: _vm.status_selected,
                  callback: function ($$v) {
                    _vm.status_selected = $$v
                  },
                  expression: "status_selected",
                },
              }),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _c("dots-horizontal-icon", {
                        staticClass: "h-5 -ml-1 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isHiddenStatus
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/customers/${_vm.$route.params.id}/pbx-service/${_vm.$route.params.pbx_service_id}/edit`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("pencil-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("general.edit")) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/tickets/main/add-ticket`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("ticket-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.pbxService
                            ? _c(
                                "sw-dropdown-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "invoices.create",
                                      query: {
                                        from: "pbx_services",
                                        code: _vm.pbxService
                                          .pbx_services_number,
                                        pbx_service_id: _vm.pbxService.id,
                                        customer_id: _vm.pbxService.customer_id,
                                        package_id:
                                          _vm.pbxService.pbx_package_id,
                                      },
                                    },
                                    "tag-name": "router-link",
                                  },
                                },
                                [
                                  _c("calculator-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$t("invoices.new_invoice")) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/customers/${_vm.$route.params.id}/pbx-service/${_vm.$route.params.pbx_service_id}/callHistory`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("SearchIcon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("general.search_calls")) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        to: `/admin/pbx/packages/${this.pbxService.pbx_package_id}/view/detail`,
                        "tag-name": "router-link",
                      },
                    },
                    [
                      _c("ticket-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("general.go_to_packages")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _c("customer-info"),
          _vm._v(" "),
          _c("service-detail", { on: { status: _vm.setStatus } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }