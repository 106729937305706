var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c("base-page", { staticClass: "item-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitPaypal.apply(null, arguments)
              },
            },
          },
          [
            _vm.isRequestOnGoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-page-header",
              { attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$tc("settings.payment_gateways.title", 2),
                        to: "/admin/settings/payment-gateways",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$tc("auxpay.name"),
                        to: "/admin/settings/paypal",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "auxpay.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            title: _vm.$t("auxpay.edit"),
                            to: "#",
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            title: _vm.$t("auxpay.create"),
                            to: "#",
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-4",
                        attrs: {
                          variant: "primary-outline",
                          type: "button",
                          size: "lg",
                        },
                        on: { click: _vm.cancelForm },
                      },
                      [
                        !_vm.isLoading
                          ? _c("x-circle-icon", {
                              staticClass: "w-6 h-6 mr-1 -ml-2",
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("auxpay.update")
                                : _vm.$t("auxpay.save")
                            ) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "col-span-5 sw-section-title lg:col-span-1",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("paypal.gateway_options")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("sw-divider", { staticClass: "col-span-12" }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("authorize.name"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            attrs: { focus: "", type: "text", name: "name" },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("auxpay.endpoint"),
                            error: _vm.endpointError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.endpoint.$error,
                              focus: "",
                              type: "text",
                              name: "endpoint",
                              autocomplete: "off",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.endpoint.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.endpoint,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "endpoint",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.endpoint",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "small",
                            {
                              staticClass: "text-bold mt-1 d-block",
                              staticStyle: { color: "#006400" },
                            },
                            [
                              _vm._v(
                                "\n                The URL to connect to the API should follow this format: \n                "
                              ),
                              _c("strong", [
                                _vm._v(
                                  "https://dev-api.auxvault.net/api/v1/public/"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t(
                              "settings.customization.modules.api_key"
                            ),
                            error: _vm.apikeyError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              focus: "",
                              type: _vm.showApiKey ? "text" : "password",
                              name: "api_key",
                              invalid: _vm.$v.formData.api_key.$error,
                              autocomplete: "off",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.api_key.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "rightIcon",
                                fn: function () {
                                  return [
                                    _vm.showApiKey
                                      ? _c("eye-off-icon", {
                                          staticClass:
                                            "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.showApiKey = !_vm.showApiKey
                                            },
                                          },
                                        })
                                      : _c("eye-icon", {
                                          staticClass:
                                            "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              _vm.showApiKey = !_vm.showApiKey
                                            },
                                          },
                                        }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.formData.api_key,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "api_key",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.api_key",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-4",
                          attrs: { label: _vm.$t("auxpay.merchantid") },
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              focus: "",
                              type: "text",
                              name: "merchant_id",
                            },
                            model: {
                              value: _vm.formData.merchant_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "merchant_id", $$v)
                              },
                              expression: "formData.merchant_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-4",
                          attrs: { label: _vm.$t("paypal.currency") },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.currency,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": true,
                              placeholder: _vm.$t("general.select_currency"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "currency", $$v)
                              },
                              expression: "formData.currency",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-4",
                          attrs: { label: _vm.$t("auxpay.default") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.status,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": true,
                              placeholder: _vm.$t("auxpay.default"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.default,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "default", $$v)
                              },
                              expression: "formData.default",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-4",
                          attrs: { label: _vm.$t("auxpay.production") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.statusp,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": true,
                              placeholder: _vm.$t("auxpay.production"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.production,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "production", $$v)
                              },
                              expression: "formData.production",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "col-span-12 md:col-span-12",
                          attrs: {
                            label: _vm.$t(
                              "authorize.enable_identification_verification"
                            ),
                            variant: "horizontal",
                          },
                        },
                        [
                          _c("sw-switch", {
                            staticClass: "-mt-3",
                            model: {
                              value:
                                _vm.formData.enable_identification_verification,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "enable_identification_verification",
                                  $$v
                                )
                              },
                              expression:
                                "formData.enable_identification_verification",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "col-span-12 md:col-span-12",
                          attrs: {
                            label: _vm.$t("authorize.enable_fee_charges"),
                            variant: "horizontal",
                          },
                        },
                        [
                          _c("sw-switch", {
                            staticClass: "-mt-3",
                            model: {
                              value: _vm.formData.enable_fee_charges,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "enable_fee_charges",
                                  $$v
                                )
                              },
                              expression: "formData.enable_fee_charges",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }