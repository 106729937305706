var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitAvalaraLocation.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "sm:p-6 grid md:grid-cols-2 mb-1" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("settings.company_info.country") },
                },
                [
                  _c(
                    "sw-label",
                    { staticClass: "text-xs text-gray-400 uppercase" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formData.country
                              ? _vm.formData.country.name
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "ml-3 mt-2",
                  attrs: { label: _vm.$tc("settings.company_info.state") },
                },
                [
                  _c(
                    "sw-label",
                    { staticClass: "text-xs text-gray-400 uppercase" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formData.state ? _vm.formData.state.name : ""
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("settings.company_info.city") },
                },
                [
                  _c(
                    "sw-label",
                    { staticClass: "text-xs text-gray-400 uppercase" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formData.city || "") +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "ml-3 mt-2",
                  attrs: { label: _vm.$tc("settings.company_info.zip") },
                },
                [
                  _c(
                    "sw-label",
                    { staticClass: "text-xs text-gray-400 uppercase" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formData.zip || "") +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mb-3 md:mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-6 sm:p-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("avalara.billing_location_modal.locations"),
                variant: "",
              },
            },
            _vm._l(_vm.modalData, function (location) {
              return _c("div", { staticClass: "mt-2" }, [
                location.valid
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.locationSelected,
                            expression: "locationSelected",
                          },
                        ],
                        attrs: { type: "radio", id: location.CountryIso },
                        domProps: {
                          value: location,
                          checked: _vm._q(_vm.locationSelected, location),
                        },
                        on: {
                          change: function ($event) {
                            _vm.locationSelected = location
                          },
                        },
                      }),
                      _vm._v(" "),
                      location.County != "" && location.Locality != ""
                        ? _c(
                            "label",
                            {
                              staticClass: "ml-2",
                              attrs: { for: location.CountryIso },
                            },
                            [
                              _vm._v(
                                "\n            County:   " +
                                  _vm._s(location.County) +
                                  "    \n            City:   " +
                                  _vm._s(location.Locality) +
                                  "    \n            PCode:  \n            " +
                                  _vm._s(location.PCode) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "label",
                            {
                              staticClass: "ml-2",
                              attrs: { for: location.CountryIso },
                            },
                            [
                              _vm._v(
                                "\n            County:   N/A    \n            City:   N/A    \n            PCode:  \n            " +
                                  _vm._s(location.PCode) +
                                  "\n          "
                              ),
                            ]
                          ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3 text-sm",
              attrs: { type: "button", variant: "primary-outline" },
              on: { click: _vm.closeAvalaraLocationsModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                variant: "primary",
                type: "submit",
                loading: _vm.isLoading,
                disabled: _vm.locationSelected.County == null ? true : false,
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    !_vm.isEdit
                      ? _vm.$t("general.save")
                      : _vm.$t("general.update")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }