var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c(
            "sw-page-header",
            {
              staticClass: "mb-3",
              attrs: { title: _vm.$t("bandwidth.title") },
            },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/settings/modules",
                      title: _vm.$t("settings.customization.modules.title"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$t("bandwidth.bandwidth"),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "bandwidth/add-config",
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _c("plus-sm-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("bandwidth.add_config")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative table-container overflow-x-scroll" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.bandwidths.length))]),
                    _vm._v("\n          " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalBandwidths))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("bandwidth.account"),
                      show: "account_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("bandwidth.user"),
                      show: "user",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("bandwidth.url"),
                      show: "url",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("bandwidth.account"),
                      show: "account_id",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("bandwidth.default"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  ref: `switch-${row.id}`,
                                  staticClass: "absolute",
                                  staticStyle: { top: "-30px" },
                                  attrs: { value: row.is_default },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setDefault(row.id, $event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("general.actions")) + " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown",
                              [
                                _c("dot-icon", {
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                }),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `bandwidth/${row.id}/edit-config`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeBandwidth(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }