var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/users",
                      title: _vm.$tc("users.user", 2),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "", title: _vm.name_user, active: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$router.go()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/users`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.go_back")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.update
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/users/${_vm.userData.id}/edit`,
                            variant: "primary-outline",
                          },
                        },
                        [
                          _c("pencil-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.update
                    ? _c(
                        "sw-dropdown",
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                              attrs: { slot: "activator", variant: "primary" },
                              slot: "activator",
                            },
                            [
                              _c("dots-horizontal-icon", {
                                staticClass: "h-5 -ml-1 -mr-1",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.permissionModule.update
                            ? _c(
                                "sw-dropdown-item",
                                {
                                  attrs: {
                                    "tag-name": "router-link",
                                    to: `/admin/users/${_vm.idUser}/permissions`,
                                  },
                                },
                                [
                                  _c("lock-closed-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("roles.permissions")) +
                                      "\n        "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-card",
            [
              _vm.loadding ? _c("base-loader") : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "p",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("corePbx.custom_did_groups.basic_info")
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "grid grid-cols-1 gap-4 mt-5 md:grid-cols-3",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 gap-4 mt-5 md:grid-cols-2 sm:grid-cols-1 col-span-2",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("users.name")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userData.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("users.email")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userData.email) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("users.phone")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.userData.phone
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.userData.phone) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic mt-2",
                                    },
                                    [
                                      _vm._v(
                                        "\n                N/A\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("roles.role")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.userData.role2) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid grid-cols-1 gap-4 mt-5 col-span-1",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("users.departaments")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.userData.item_groups &&
                            _vm.userData.item_groups.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.userData.item_groups,
                                    function (dep, indexDep) {
                                      return _c(
                                        "p",
                                        {
                                          key: indexDep,
                                          staticClass:
                                            "text-sm font-bold leading-5 text-black non-italic mt-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(dep.name) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic mt-2",
                                    },
                                    [
                                      _vm._v(
                                        "\n                N/A\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "my-8" }),
                  _vm._v(" "),
                  _vm.permissionModule.accessEstimates
                    ? _c(
                        "div",
                        [
                          _vm.userData.id
                            ? _c("table-estimates", {
                                attrs: {
                                  userId: _vm.userData.id,
                                  readEstimates:
                                    _vm.permissionModule.readEstimates,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessTickets
                    ? _c(
                        "div",
                        [
                          _vm.userData.id
                            ? _c("table-tickets", {
                                attrs: {
                                  userId: _vm.userData.id,
                                  readTickets: _vm.permissionModule.readTickets,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }