<template>
  <base-page class="payments">
    <div>
      <div class="flex flex-wrap justify-end mt-8 lg:flex-nowrap">
        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/view`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
        >
          {{ $t('customer_notes.clientgoback') }}
        </sw-button>
        <sw-button 
          size="lg" 
          variant="primary-outline" 
          @click="toggleFilter"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
        >
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="w-4 h-4 ml-2 -mr-1" />
        </sw-button>
      </div>

      <!----------------------   Filters    ------------------->
      <slide-y-up-transition>
        <sw-filter-wrapper
          v-show="showFilters"
          class="relative grid grid-rows gap-4 p-4 bg-gray-100 rounded-lg w-full"
        >
          <div class="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
            <sw-input-group :label="$t('general.from')" class="mt-2">
              <base-date-picker
                v-model="filters.from_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
                class="w-full"
                />
            </sw-input-group>

            <sw-input-group :label="$t('general.to')" class="mt-2">
              <base-date-picker
                v-model="filters.to_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
                class="w-full"
                />
            </sw-input-group>

            <sw-input-group :label="$t('logs.email_logs.email')" class="mt-2">
              <sw-input
                v-model="filters.email"
                type="text"
                name="email"
                autocomplete="off"
                class="w-full"
                />
            </sw-input-group>

            <sw-input-group :label="$t('logs.email_logs.subject')" class="mt-2">
              <sw-input
                v-model="filters.subject"
                type="text"
                name="subject"
                autocomplete="off"
                class="w-full"
                />
            </sw-input-group>
          </div>

          <label
            class="absolute text-sm leading-snug text-black cursor-pointer"
            @click="clearFilter"
            style="top: 10px; right: 15px"
            >{{ $t('general.clear_all') }}</label
          >
        </sw-filter-wrapper>
      </slide-y-up-transition>
      <div class="py-4">
        <strong>{{ $t('logs.time_shown') }}</strong>
      </div>

      <!----------------------   Table     ----------------------->
      <div class="table-content">
        <!-- Table -->
        <sw-table-component
          ref="table"
          table-class="table"
          :show-filter="false"
          :data="fetchData"
        >
          <sw-table-column
            :sortable="true"
            :label="$t('logs.email_logs.email')"
            show="to"
          />
          <sw-table-column
            :sortable="true"
            :label="$t('logs.email_logs.subject')"
            show="subject"
          />

          <sw-table-column
            :sortable="true"
            :label="$t('logs.email_logs.body')"
            show="body"
          >
            <template slot-scope="row">
              <span>{{ $t('logs.email_logs.body') }}</span>
              <span v-if="isTruncated(row)">{{ truncatedText(row.body) }}</span>
              <span v-else>{{ row.body }}</span>
              <button @click="toggleText(row)">
                <b> {{ row.showMore ? 'Less' : 'More' }}</b>
              </button>
            </template>
          </sw-table-column>
          <sw-table-column
            :sortable="true"
            :label="$t('logs.email_logs.mailable_type')"
            show="mailable_type"
          >
            <template slot-scope="row">
              <span>{{ $t('logs.email_logs.mailable_type') }}</span>

              <div v-if="row.formattedType">
                {{ $t(typeMapping[row.formattedType]) }}
              </div>
            </template>
          </sw-table-column>
          <sw-table-column
            :sortable="true"
            :label="$t('logs.email_logs.sended')"
            sort-as="created_at"
            show="formattedCreatedAt"
          />

          <sw-table-column
            :sortable="false"
            :filterable="false"
            cell-class="action-dropdown no-click"
          >
            <template slot-scope="row">
              <span>{{ $t('invoices.action') }}</span>
              <sw-dropdown>
              <dot-icon slot="activator" />
              <sw-dropdown-item @click="sendInvoice(row.id)">
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.resend') }}
              </sw-dropdown-item>
               </sw-dropdown>
            </template>
          </sw-table-column>
        </sw-table-component>
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FilterIcon, XIcon, PaperAirplaneIcon } from '@vue-hero-icons/solid'

export default {
  components: {
    FilterIcon,
    XIcon,
    PaperAirplaneIcon,
  },
  data() {
    return {
      typeMapping: {
        Invoice: 'navigation.invoices',
        General: 'general.system',
        Quote: 'navigation.estimates',
        Payment: 'navigation.payments',
      },
      showFilters: false,
      filters: {
        email: '',
        subject: '',
        from_date: '',
        to_date: '',
      },
    }
  },
  computed: {
    ...mapGetters('log', ['totalEmailLogs']),

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  methods: {
    ...mapActions('log', ['fetchEmailLogsCustomer', 'resendEmailCustomer']),

    async fetchData({ page, filter, sort }) {
      console.log(this.$route.params)
      let data = {
        email: this.filters.email,
        subject: this.filters.subject,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        customer_id: this.$route.params.id ? this.$route.params.id : null,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      let response = await this.fetchEmailLogsCustomer(data)

      return {
        data: response.data.emailLogs.data,
        pagination: {
          totalPages: response.data.emailLogs.last_page,
          currentPage: page,
        },
      }
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    clearFilter() {
      this.filters = {
        email: '',
        subject: '',
        from_date: '',
        to_date: '',
      }
    },

    setFilters() {
      this.refreshTable()
    },

    refreshTable() {
      this.$refs.table.refresh()
    },

    /**
     * Envía una factura por email.
     *
     * @param {Number} id - El ID del log de email a reenviar.
     * @returns {Boolean} - Retorna true si el envío fue exitoso, false en caso contrario.
     */
    async sendInvoice(id) {
      console.log(id)

      // Prepara los datos para la solicitud
      const data = {
        logemail_id: id,
        customer_id: this.$route.params.id || null,
      }

      try {
        // Envía la solicitud para reenviar el email
        const response = await this.resendEmailCustomer(data)
        console.log(response)

        // Muestra una notificación basada en el resultado de la solicitud
        if (response.data.success) {
          window.toastr['success'](this.$tc('logs.email_logs.success'))
          return true
        } else {
          window.toastr['error'](this.$tc('logs.email_logs.failed'))
          return false
        }
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud
        console.error(error)
        window.toastr['error'](this.$tc('logs.email_logs.failed'))
        return false
      }
    },
    /**
     * Verifica si el texto debe ser truncado.
     * @param {Object} row - El objeto de la fila que contiene el texto.
     * @returns {boolean} - Retorna true si el texto debe ser truncado, de lo contrario false.
     */
    isTruncated(row) {
      console.log(row)
      if (row.showMore === undefined || row.showMore === false) {
        return row.body.length > 70
      } else {
        return false
      }
    },

    /**
     * Trunca el texto si supera los 70 caracteres.
     * @param {string} text - El texto a truncar.
     * @returns {string} - El texto truncado con "..." añadido si supera los 70 caracteres.
     */
    truncatedText(text) {
      return text.length > 70 ? text.substring(0, 70) + '...' : text
    },

    /**
     * Alterna el estado de mostrar más o menos texto.
     * @param {Object} row - El objeto de la fila que contiene el texto.
     */
    toggleText(row) {
      this.$set(row, 'showMore', !row.showMore)
    },
  },
}
</script>
