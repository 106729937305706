var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hold-invoice-pdf-modal" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-col min-h-0 mt-8 overflow-hidden",
        staticStyle: { height: "75vh" },
      },
      [
        _c("iframe", {
          staticClass:
            "flex-1 border border-gray-400 border-solid rounded-md frame-style",
          attrs: { id: "iframe", name: "iframe", src: _vm.pdfHoldInvoice },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }