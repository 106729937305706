var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "item-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitUser.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/dashboard",
                        title: _vm.$t("general.home"),
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/users",
                        title: _vm.$tc("users.user", 2),
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "users.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("users.edit_user"),
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("users.new_user"),
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3 text-sm hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          variant: "primary-outline",
                          type: "button",
                          size: "lg",
                        },
                        on: { click: _vm.cancelButton },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("users.update_user")
                                : _vm.$t("users.save_user")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12 md:col-span-8" },
                [
                  _c(
                    "sw-card",
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("users.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              autocomplete: "off",
                              focus: "",
                              type: "text",
                              name: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t("users.email"),
                            error: _vm.emailError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              invalid: _vm.$v.formData.email.$error,
                              autocomplete: "off",
                              type: "text",
                              name: "email",
                              "tab-index": "3",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-full flex flex-wrap" },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "w-full md:w-1/2 mt-3",
                              attrs: {
                                label: _vm.$tc("users.password"),
                                error: _vm.passwordError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid: _vm.$v.formData.password.$error,
                                  type: _vm.getInputType,
                                  autocomplete: "new-password",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.password.$touch()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "rightIcon",
                                      fn: function () {
                                        return [
                                          _vm.isShowPassword
                                            ? _c("eye-off-icon", {
                                                staticClass:
                                                  "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isShowPassword =
                                                      !_vm.isShowPassword
                                                  },
                                                },
                                              })
                                            : _c("eye-icon", {
                                                staticClass:
                                                  "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isShowPassword =
                                                      !_vm.isShowPassword
                                                  },
                                                },
                                              }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  841003934
                                ),
                                model: {
                                  value: _vm.formData.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "password", $$v)
                                  },
                                  expression: "formData.password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 flex items-end" },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticClass: "md:ml-2",
                                  attrs: {
                                    variant: "primary-outline",
                                    size: "lg",
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.generate()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("customers.generate_password")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4 mb-6",
                          attrs: { label: _vm.$t("users.phone") },
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              autocomplete: "off",
                              type: "text",
                              name: "phone",
                              "tab-index": "4",
                            },
                            model: {
                              value: _vm.formData.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("users.roles"),
                            error: _vm.roleError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-1",
                            attrs: {
                              required: "",
                              invalid: _vm.$v.formData.role.$error,
                              options: _vm.roles,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": false,
                              placeholder: _vm.$t("users.roles"),
                              label: "name",
                              "track-by": "id",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.role.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.role,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "role", $$v)
                              },
                              expression: "formData.role",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4 mt-4",
                          attrs: { label: _vm.$t("users.departaments") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getDepartamentGroups,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "dep_group_id",
                              label: "dep_group_name",
                            },
                            model: {
                              value: _vm.formData.departament_groups,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "departament_groups",
                                  $$v
                                )
                              },
                              expression: "formData.departament_groups",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.pbx_notification,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "pbx_notification",
                                    $$v
                                  )
                                },
                                expression: "formData.pbx_notification",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.pbx_server_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative w-12 ml-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute ml-8",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_estimates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_estimates", $$v)
                                },
                                expression: "formData.email_estimates",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-12" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_estimates_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_invoices,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_invoices", $$v)
                                },
                                expression: "formData.email_invoices",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_invoices_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative w-12 ml-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute ml-8",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_payments,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_payments", $$v)
                                },
                                expression: "formData.email_payments",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-12" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_payments_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_services,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_services", $$v)
                                },
                                expression: "formData.email_services",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_services_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative w-12 ml-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute ml-8",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_pbx_services,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "email_pbx_services",
                                    $$v
                                  )
                                },
                                expression: "formData.email_pbx_services",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-12" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "users.email_pbxservices_notification"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_tickets,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_tickets", $$v)
                                },
                                expression: "formData.email_tickets",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4 mr-3" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_tickets_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative w-12 ml-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute ml-8",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.email_expenses,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email_expenses", $$v)
                                },
                                expression: "formData.email_expenses",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-12" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("users.email_expenses_notification")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-6 mb-4" },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                              attrs: {
                                loading: _vm.isLoading,
                                variant: "primary-outline",
                                type: "button",
                                size: "lg",
                              },
                              on: { click: _vm.cancelButton },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.cancel")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                              attrs: {
                                loading: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t("users.update_user")
                                      : _vm.$t("users.save_user")
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }