var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-note-view" },
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("customer_notes.view_note") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/customers",
                  title: _vm.$t("customers.title"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: `/admin/customers/${_vm.$route.params.id}/note`,
                  title: _vm.$t("customer_notes.title"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$t("customer_notes.view_note"),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/note`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n       "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/${_vm.$route.params.note_id}/edit-note`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeNote(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c("div", { staticClass: "col-span-12" }, [
          _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("customer_notes.basic_info")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid md:grid-cols-2 grid-cols-1 gap-4 mt-5" },
            [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customer_notes.summary_note")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.note.summary) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customer_notes.pinned")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.isSticky) + "\n          "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.note")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.note.note) + "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }