/*CUSTOMER*/
export const BOOTSTRAP_CUSTOMERS = 'BOOTSTRAP_CUSTOMERS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_MULTIPLE_CUSTOMERS = 'DELETE_MULTIPLE_CUSTOMERS'
export const SET_SELECTED_CUSTOMERS = 'SET_SELECTED_CUSTOMERS'
export const SET_TOTAL_CUSTOMERS = 'SET_TOTAL_CUSTOMERS'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_CUSTOMER = 'SET_SELECTED_VIEW_CUSTOMER'
/*PACKAGES*/
export const PACKAGES_LIST = 'PACKAGES_LIST'
export const SET_PACKAGE_PARAMETERS = 'SET_PACKAGE_PARAMETERS'
/*INVOICES*/
export const INVOICES_LIST = 'INVOICES_LIST'
export const ESTIMATES_LIST = 'ESTIMATES_LIST'
/*PBX SERVICES*/
export const SET_CORE_PBX_SERVICES_PARAMETERS = 'SET_CORE_PBX_SERVICES_PARAMETERS'
export const SET_CORE_PBX_SERVICES_INCLUDED_DATA = 'SET_CORE_PBX_SERVICES_INCLUDED_DATA'
export const PBX_SERVICE_SAVED = 'PBX_SERVICE_SAVED'
export const PBX_DID_SAVED = 'PBX_DID_SAVED'
export const PBX_EXT_SAVED = 'PBX_EXT_SAVED'
export const SET_PBX_SERVICES_DAYS_RENEWAL = 'SET_PBX_SERVICES_DAYS_RENEWAL';
/* PBX TENANT */ 
export const PBX_TENANT_SAVED = 'PBX_TENANT_SAVED'
/*DID*/
export const BOOTSTRAP_DID = 'BOOTSTRAP_DID'
export const BOOTSTRAP_DID_INCLUDE = 'BOOTSTRAP_DID_INCLUDE'
export const SET_SELECTED_DID = 'SET_SELECTED_DID'
export const SET_SELECTED_DID_INCLUDE = 'SET_SELECTED_DID_INCLUDE'
export const SET_TOTAL_DID = 'SET_TOTAL_DID'
export const SET_TOTAL_DID_INCLUDE = 'SET_TOTAL_DID_INCLUDE'
export const SET_SELECT_ALL_STATE_DID = 'SET_SELECT_ALL_STATE_DID'
export const SET_SELECT_ALL_STATE_DID_INCLUDE = 'SET_SELECT_ALL_STATE_DID_INCLUDE'
export const RESET_SELECTED_DID = 'RESET_SELECTED_DID'
/*EXTENSION*/
export const BOOTSTRAP_EXTENSION = 'BOOTSTRAP_EXTENSION'
export const BOOTSTRAP_EXTENSION_INCLUDE = 'BOOTSTRAP_EXTENSION_INCLUDE'
export const SET_SELECTED_EXTENSION = 'SET_SELECTED_EXTENSION'
export const SET_SELECTED_EXTENSION_INCLUDE = 'SET_SELECTED_EXTENSION_INCLUDE'
export const SET_TOTAL_EXTENSION = 'SET_TOTAL_EXTENSION'
export const SET_TOTAL_EXTENSION_INCLUDE = 'SET_TOTAL_EXTENSION_INCLUDE'
export const SET_SELECT_ALL_STATE_EXTENSION = 'SET_SELECT_ALL_STATE_EXTENSION'
export const SET_SELECT_ALL_STATE_EXTENSION_INCLUDE = 'SET_SELECT_ALL_STATE_EXTENSION_INCLUDE'
export const RESET_SELECTED_EXTENSION = 'RESET_SELECTED_EXTENSION'