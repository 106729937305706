var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("core_pos.cash_registers") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "/admin/corePOS" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("core_pos.cash_registers"),
                  to: "cash-register",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n                "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "create-cash-register",
                    size: "lg",
                    variant: "primary",
                  },
                },
                [
                  _c("plus-sm-icon", {
                    staticClass: "h-6 mr-1 -ml-2 font-bold",
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("core_pos.add_cash_register")) +
                      "\n            "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("item_groups.name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("item_groups.description") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "description",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "description", $$v)
                      },
                      expression: "filters.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("item_groups.stores") },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.options_stores,
                      searchable: true,
                      "show-labels": false,
                      "track-by": "id",
                      label: "name",
                      multiple: false,
                      tabindex: 10,
                    },
                    model: {
                      value: _vm.filters.store_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "store_name", $$v)
                      },
                      expression: "filters.store_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative table-container" },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("item_groups.name"),
                  "sort-as": "name",
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("core_pos.cash_register"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: {
                                path: `/admin/corePOS/cash-register/${row.id}/view`,
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(row.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("item_groups.description"),
                  "sort-as": "description",
                  show: "description",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(row.description ? row.description : "None") +
                            "\n                "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("item_groups.store"),
                  "sort-as": "store_name",
                  show: "store_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("item_groups.store"))),
                        ]),
                        _vm._v(
                          "\n                    " +
                            _vm._s(row.store_name) +
                            "\n                "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("item_groups.device"),
                  "sort-as": "device",
                  show: "device",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("core_pos.open_close_cash_modal.status"),
                  "sort-as": "open_cash",
                  show: "open_cash",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                attrs: {
                                  "bg-color": _vm.getStatus(row.open_cash)
                                    .bgColor,
                                  color: _vm.getStatus(row.open_cash).color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(_vm.getStatus(row.open_cash).text)
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, filterable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _vm.permissionModule.access
                                  ? _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.cashRegisterModal(row)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          row.open_cash == 1
                                            ? _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _c("x-circle-icon", {
                                                    staticClass:
                                                      "h-5 mr-1 text-gray-600",
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "core_pos.close_cash_register"
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _c("check-circle-icon", {
                                                    staticClass:
                                                      "h-5 mr-1 text-gray-600",
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "core_pos.open_cash_register"
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirectCashRegisterInformation(
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "core_pos.information_cash_register"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `create-cash-register/${row.id}/edit`,
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteMoney(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }