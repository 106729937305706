var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            {
              staticClass: "mb-3",
              attrs: { title: _vm.$tc("tax_groups.title") },
            },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/settings/tax-groups",
                      title: _vm.$tc("tax_groups.tax_group", 2),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _vm.permissionModule.update
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/settings/tax-groups/${_vm.$route.params.id}/edit`,
                            variant: "primary-outline",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.delete
                    ? _c(
                        "sw-button",
                        {
                          attrs: { slot: "activator", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.removeTaxGroup(_vm.$route.params.id)
                            },
                          },
                          slot: "activator",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
            _c("div", { staticClass: "pt-6 mt-5" }, [
              _c("div", { staticClass: "col-span-12" }, [
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tax_groups.basic_info")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_groups.name")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.formData.name) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_groups.description")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.formData.description) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_groups.country")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.formData.country_name
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.country_name) +
                                "\n            "
                            ),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("tax_groups.not_selected")) +
                                "\n            "
                            ),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_groups.state")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.formData.state_name
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.state_name) +
                                "\n            "
                            ),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("tax_groups.not_selected")) +
                                "\n            "
                            ),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showTaxes,
                          expression: "showTaxes",
                        },
                      ],
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-gray-500 uppercase sw-section-title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_groups.tax_type")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-table-component",
                        {
                          ref: "table",
                          attrs: {
                            "show-filter": false,
                            data: _vm.taxes,
                            "table-class": "table",
                          },
                        },
                        [
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              filterable: true,
                              label: _vm.$t("tax_groups.name"),
                              show: "name",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("tax_groups.name"))),
                                    ]),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(row.name) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }