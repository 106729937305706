var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitData.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("core_pos.tables.new_table") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "/admin/corePOS", title: _vm.$t("core_pos.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/corePOS/tables",
                  title: _vm.$t("general.tables"),
                },
              }),
              _vm._v(" "),
              _vm.$route.name === "corepos.createtable.edit"
                ? _c("sw-breadcrumb-item", {
                    attrs: { to: "#", title: "Edit Table", active: "" },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$t("core_pos.tables.new_table"),
                      active: "",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    variant: "primary-outline",
                    size: "lg",
                    type: "button",
                  },
                  on: { click: _vm.redirectDashboard },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("core_pos.cancel")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "flex justify-center w-full md:w-auto",
                  attrs: { variant: "primary", type: "submit", size: "lg" },
                },
                [
                  _c("save-icon", { staticClass: "mr-2 -ml-1" }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("core_pos.tables.save_table")) +
                      "\n            "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "sw-card",
              { staticClass: "mb-8" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("core_pos.tables.table_name"),
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      staticClass: "mt-2",
                      attrs: { focus: "", type: "text", name: "name" },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("core_pos.cash_registers"),
                      required: "",
                    },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.cash_register_options,
                        searchable: true,
                        "show-labels": false,
                        "track-by": "id",
                        label: "name",
                        multiple: true,
                        tabindex: 10,
                      },
                      model: {
                        value: _vm.formData.cash_registers,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "cash_registers", $$v)
                        },
                        expression: "formData.cash_registers",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }