var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-input-group",
    { attrs: { label: _vm.$t("authorize.date"), required: "" } },
    [
      _c(
        "div",
        { staticClass: "flex w-full" },
        [
          _c("sw-select", {
            staticClass: "mr-1",
            attrs: {
              placeholder: "MM",
              tabindex: 1,
              searchable: true,
              "allow-empty": false,
              options: _vm.monthsOptions,
              invalid: _vm.invalid,
            },
            model: {
              value: _vm.dateExpirationMonth,
              callback: function ($$v) {
                _vm.dateExpirationMonth = $$v
              },
              expression: "dateExpirationMonth",
            },
          }),
          _vm._v(" "),
          _c("sw-select", {
            staticClass: "ml-1",
            attrs: {
              placeholder: "YYYY",
              tabindex: 1,
              searchable: true,
              "allow-empty": false,
              options: _vm.yearsOptions,
              invalid: _vm.invalid,
            },
            model: {
              value: _vm.dateExpirationYear,
              callback: function ($$v) {
                _vm.dateExpirationYear = $$v
              },
              expression: "dateExpirationYear",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }