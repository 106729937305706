var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _vm.isLoading
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _c(
            "div",
            [
              _c(
                "sw-page-header",
                { staticClass: "mb-3", attrs: { title: "Expenses" } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/dashboard",
                          title: _vm.$t("general.home"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/expenses",
                          title: _vm.$t("navigation.expenses"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: `/admin/expenses/${_vm.$route.params.id}/docs`,
                          title: _vm.$t("general.docs"),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/expenses`,
                            variant: "primary-outline",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.go_back")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/expenses/${_vm.$route.params.id}/view`,
                            variant: "primary",
                          },
                        },
                        [
                          _c("eye-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.view")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/expenses/${_vm.$route.params.id}/edit`,
                            variant: "primary",
                          },
                        },
                        [
                          _c("pencil-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-full" }, [
                _c(
                  "div",
                  { staticClass: "col-span-12" },
                  [
                    _c("sw-card", [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("general.general_data")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap mt-3 md:mt-3" },
                          [
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("expenses.expense_number")) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.formData.expense_number) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("expenses.expense_date")) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.formData.expense_date) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("expenses.subject")) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.formData.subject) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("expenses.status_name")) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.formData.status == "Active"
                                  ? _c(
                                      "p",
                                      { staticClass: "text-gray-700 text-sm" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t("expenses.status_pr")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formData.status != "Active"
                                  ? _c(
                                      "p",
                                      { staticClass: "text-gray-700 text-sm" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t("expenses.status_pe")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _vm.count > 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex z-40 justify-center overflow-auto",
                              },
                              [
                                _c("div", { staticClass: "slider" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-gray-600 text-success mx-1 rounded-full border border-gray-400 border-success cursor-pointer p-5",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "25px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.file.position + 1) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "slides" }, [
                                    _c("div", { attrs: { id: "slide-1" } }, [
                                      _vm.isPdf
                                        ? _c("iframe", {
                                            attrs: {
                                              src: _vm.file.base64,
                                              width: "100%",
                                              height: "100%",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isImage
                                        ? _c("img", {
                                            staticClass: "m-10 rounded-md",
                                            staticStyle: {
                                              animation: "fadeIn 2s ease",
                                              position: "relative !important",
                                            },
                                            attrs: { src: _vm.file.base64 },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isImage && !_vm.isPdf
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex flex-wrap justify-center items-center text-center p-3",
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  height: "auto !important",
                                                  width: "auto !important",
                                                  animation: "fadeIn 2s ease",
                                                  position:
                                                    "relative !important",
                                                },
                                                attrs: {
                                                  src: _vm.file.src,
                                                  alt: "ext",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "w-full text-xl font-bold text-primary mt-3",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "expenses.no_preview"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "justify-center mt-5" }, [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "mb-2 w-full truncate text-center",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.file.file_name) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "mb-2 w-full text-center" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("expenses.size")) +
                                      ": " +
                                      _vm._s(_vm.sizeFile(_vm.file.size)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex w-full justify-center" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-gray-600 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                    attrs: { "tag-name": "a" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadFile(_vm.file)
                                      },
                                    },
                                  },
                                  [
                                    _c("DownloadIcon", {
                                      staticClass: "h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex z-40 justify-center mt-8" },
                              [
                                _vm.count > 1
                                  ? _c(
                                      "sw-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          type: "button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.nextFile()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "17px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.next")) +
                                                " »"
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "flex w-full justify-center" },
                            [
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "no_document",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor("I")
                                        .bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor("I").color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("expenses.no_documents")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }