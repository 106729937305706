var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateServicesSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span- mt-2",
              attrs: {
                label: _vm.$t("corePbx.customization.services_bcc_email"),
                error: _vm.emailError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "input-expand",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.email.$error,
                  type: "text",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-4" }),
          _vm._v(" "),
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.packages.email_templates")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 mb-4" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.$t("corePbx.customization.services_creation"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("corePbx.customization.services_subject"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.creation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "creation_services_subject", $$v)
                  },
                  expression: "services.creation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: { label: _vm.$t("corePbx.customization.services_body") },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.creation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "creation_services", $$v)
                  },
                  expression: "services.creation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(_vm._s(_vm.$t("corePbx.customization.services_suspension"))),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("corePbx.customization.services_subject"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.suspension_services_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "suspension_services_subject", $$v)
                  },
                  expression: "services.suspension_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: { label: _vm.$t("corePbx.customization.services_body") },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.suspension_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "suspension_services", $$v)
                  },
                  expression: "services.suspension_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(_vm.$t("corePbx.customization.services_cancellation"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("corePbx.customization.services_subject"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.cancellation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "cancellation_services_subject", $$v)
                  },
                  expression: "services.cancellation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: { label: _vm.$t("corePbx.customization.services_body") },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.cancellation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "cancellation_services", $$v)
                  },
                  expression: "services.cancellation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(_vm.$t("corePbx.customization.services_reactivation"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("corePbx.customization.services_subject"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.reactivation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "reactivation_services_subject", $$v)
                  },
                  expression: "services.reactivation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: { label: _vm.$t("corePbx.customization.services_body") },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.reactivation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "reactivation_services", $$v)
                  },
                  expression: "services.reactivation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateInvoiceSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.packages.package_prefix"),
                error: _vm.packagePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.packages.packages_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.packages.packages_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("PACKAGE")
                  },
                },
                model: {
                  value: _vm.packages.packages_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.packages, "packages_prefix", $$v)
                  },
                  expression: "packages.packages_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateSuspensionScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.packages.package_suspension")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slidePackagesSuspensionDate(option),
                  },
                  model: {
                    value: _vm.allow_suspension_packages_job,
                    callback: function ($$v) {
                      _vm.allow_suspension_packages_job = $$v
                    },
                    expression: "allow_suspension_packages_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.packages.allow_packagesuspension_date_job"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.packages.packagesuspension_date_job_desc"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.packages.time_run_packagesuspension_date_job"
                ),
                error: _vm.timeSuspensionError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.time_run_suspension_packages_job.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.time_run_suspension_packages_job.$touch()
                  },
                },
                model: {
                  value: _vm.time_run_suspension_packages_job,
                  callback: function ($$v) {
                    _vm.time_run_suspension_packages_job = $$v
                  },
                  expression: "time_run_suspension_packages_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingsuspension,
                    disabled: _vm.isLoadingsuspension,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingsuspension
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.save_suspension"
                        )
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateUnsuspendScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.packages.unsuspend_services")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-6" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slideUnsuspendPackages(option),
                  },
                  model: {
                    value: _vm.allow_unsuspend_packages_job,
                    callback: function ($$v) {
                      _vm.allow_unsuspend_packages_job = $$v
                    },
                    expression: "allow_unsuspend_packages_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.allow_unsuspend_date_job")
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.unsuspend_date_job_desc")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.period_time_run_job"),
                error: _vm.timeUnsuspendError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.period_run_unsuspend_packages_job.$error,
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.period_run_unsuspend_packages_job.$touch()
                  },
                },
                model: {
                  value: _vm.period_run_unsuspend_packages_job,
                  callback: function ($$v) {
                    _vm.period_run_unsuspend_packages_job = $$v
                  },
                  expression: "period_run_unsuspend_packages_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4 margin-buttonl",
                  attrs: {
                    loading: _vm.isLoadingunsuspend,
                    disabled: _vm.isLoadingunsuspend,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingunsuspend
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.customization.save_unsuspend")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }