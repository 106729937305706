<template>
  <form action="" @submit.prevent="saveAddress">
    <div class="sm:p-6 grid md:grid-cols-2 gap-6 mb-1" v-if="!isLoading">
      <sw-input-group label="Address Line 1" class="mb-6" required>
        <sw-input
          v-model="editFormData.address"
          required
          class="input-field"
          placeholder="Enter address"
          auto-complete="off"
        />
      </sw-input-group>

      <sw-input-group label="Address Line 2 (Optional)" class="mb-6">
        <sw-input
          v-model="editFormData.address2"
          class="input-field"
          placeholder="Enter address line 2"
          auto-complete="off"
        />
      </sw-input-group>

      <sw-input-group label="City" class="mb-6" required>
        <sw-input
          v-model="editFormData.city"
          required
          class="input-field"
          placeholder="Enter city"
          auto-complete="off"
        />
      </sw-input-group>

      <sw-input-group label="State" class="mb-6" required>
        <sw-select
          v-model="editFormData.state"
          :options="states"
          :searchable="true"
          :show-labels="false"
          :placeholder="'Select a state'"
          label="name"
          track-by="id"
          required
          auto-complete="off"
        />
      </sw-input-group>

      <sw-input-group label="ZIP Code" class="mb-6" required>
        <sw-input
          v-model="editFormData.zip"
          required
          class="input-field"
          placeholder="Enter ZIP code"
          auto-complete="off"
        />
      </sw-input-group>

      <sw-input-group label="Country" class="mb-6" required>
        <sw-select
          v-model="editFormData.country"
          :options="countries"
          :searchable="true"
          :show-labels="false"
          :placeholder="'Select a country'"
          label="name"
          track-by="id"
          required
          auto-complete="off"
          @select="countrySelectedEdit"
        />
      </sw-input-group>
    </div>

    <div class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg">
      <sw-button
        type="button"
        variant="primary-outline"
        class="mr-3 text-sm"
        @click="cancelEdit"
      >
        {{ $t('general.cancel') }}
      </sw-button>
      <sw-button variant="primary" type="submit" :loading="isLoading">
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ !isEdit ? $t('general.save') : $t('general.update') }}
      </sw-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      states: [],
      countries: [],
      editFormData: {
        address: '',
        address2: '',
        city: '',
        state: null,
        zip: '',
        country: null
      }
    };
  },
  computed: {
    ...mapGetters('modal', ['modalDataID', 'modalData', 'refreshData']),
  },
  watch: {
    modalData(val) {
      if (val) {
        this.isEdit = true;
        this.setData();
      } else {
        this.isEdit = false;
      }
    },
  },
  created(){
    this.loadCountries()
    console.log('modal data: '. this.modalData);
  },
  mounted(){
    this.setData()
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('paymentAccountsCustomer', [
      'updatePaymentAccount',
    ]),
    async setData() {
      this.isLoading = true;

      if(this.countries.length === 0){
        await this.loadCountries();
      }
      
      // Lógica para cargar los datos en editFormData
      if (this.modalData) {
        const data = this.modalData;

        const selectedCountry = this.countries.find(country => country.id === data.country_id);

        if(selectedCountry){
          await this.countrySelectedEdit(selectedCountry);
        }

        const selectedState = this.states.find(state => state.id === data.state_id);

        this.editFormData = {
          address: data.address_1 || '',
          address2: data.address_2 || '',
          city: data.city || '',
          state: selectedState || null,
          zip: data.zip || '',
          country: selectedCountry || null
        };
      }
      this.isLoading = false;
    },
    async loadCountries() {
      let res = await window.axios.get('/api/v1/countries')
      if (res && res.data && res.data.countries) {
        this.countries = res.data.countries
      }
    },
    async loadStates(country) {
      if (!country || !country.code) return
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res && res.data && res.data.states) {
        this.states = res.data.states
      }
    },
    async countrySelectedEdit(country) {
      this.editFormData.state = ''
      await this.loadStates(country)
    },
    async saveAddress() {
      this.isLoading = true;

      const data = {
        ...this.modalData,
        address_1: this.editFormData.address,
        address_2: this.editFormData.address2,
        city: this.editFormData.city,
        state_id: this.editFormData.state.id,
        state_name: this.editFormData.state.name,
        zip: this.editFormData.zip,
        country_id: this.editFormData.country.id,
        country_name: this.editFormData.country.name,

        credit_cards: this.modalData.credit_card
        ? { value: this.modalData.credit_card, name: this.modalData.credit_card }
        : null
      };

      try{
        const response = await this.updatePaymentAccount(data)
        if(response){
          window.toastr['success'](this.$t('customers.updated_message'))
          this.refreshData();
          this.closeModal();
        }else {
          window.toastr['error'](this.$t('validation.email_already_taken'))
        }
      }catch(error){
        window.toastr['error']('Error during the request.');
        console.error(error);
      }
      this.isLoading = false;
    },
    cancelEdit() {
      this.closeModal();
    },
  }
};
</script>
