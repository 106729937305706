var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hold-invoice-modal" }, [
    _c(
      "div",
      { staticClass: "relative table-container overflow-auto" },
      [
        _c("sw-card", { staticClass: "text-center" }, [
          _c("h1", { staticClass: "text-xl font-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("general.are_you_sure"))),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "text-lg" }, [
            _vm._v(" " + _vm._s(_vm.$t("core_pos.hold_invoice_message"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: { label: _vm.$t("core_pos.description_input") },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.reference,
                      callback: function ($$v) {
                        _vm.reference = $$v
                      },
                      expression: "reference",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("p", { staticClass: "text-xs text-red-600" }, [
                  _vm._v(
                    _vm._s(_vm.$t("core_pos.hold_invoice_message_warning"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-5 flex justify-center" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "primary", icon: "save" },
                      on: { click: _vm.submitHold },
                    },
                    [
                      _c("save-icon", { staticClass: "mr-2" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core_pos.accept_alert")) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "primary" },
                      on: { click: _vm.printHold },
                    },
                    [
                      _c("printer-icon", { staticClass: "mr-2" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("core_pos.print")) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "primary" },
                      on: { click: _vm.cancelHold },
                    },
                    [
                      _vm._v(
                        "\n\n            " +
                          _vm._s(_vm.$t("core_pos.cancel_alert")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }