var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "package-details",
      class: {
        "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative":
          !_vm.isView,
      },
    },
    [
      !_vm.isView
        ? _c("div", { staticClass: "heading-section" }, [
            _c(
              "p",
              {
                staticClass:
                  "text-2xl not-italic font-semibold leading-7 text-black",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.confirm_package")) +
                    "\n      "
                ),
              ]
            ),
          ])
        : _c(
            "div",
            [
              _c("sw-divider", { staticClass: "my-6" }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text-gray-500 uppercase sw-section-title" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("customers.package_details")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("packages.package_number")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm.isEdit
                          ? _c("span", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    this.parameters.package.package_number !=
                                      undefined
                                      ? this.parameters.package.package_number
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    this.package.package_number != undefined
                                      ? this.package.package_number
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("packages.name")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm.isEdit
                          ? _c("span", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    this.parameters.package.package_name !=
                                      undefined
                                      ? this.parameters.package.package_name
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    this.package.name != undefined
                                      ? this.package.name
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("packages.status")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    }),
                    this.parameters.status.name == "A" ||
                    this.parameters.status.name == "Active"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("COMPLETED")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("COMPLETED")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.active")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.parameters.status.name == "P" ||
                    this.parameters.status.name == "Pending"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("VIEWED")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("VIEWED")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.pending")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.parameters.status.name == "S" ||
                    this.parameters.status.name == "Suspended"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("SENT")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("SENT")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(_vm.$t("general.suspended")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.parameters.status.name == "C" ||
                    this.parameters.status.name == "Cancelled"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-badge",
                              {
                                staticClass: "px-3 py-1",
                                attrs: {
                                  "bg-color":
                                    _vm.$utils.getBadgeStatusColor("OVERDUE")
                                      .bgColor,
                                  color:
                                    _vm.$utils.getBadgeStatusColor("OVERDUE")
                                      .color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.cancelled")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.tax_by")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(this.parameters.tax_type.name) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.discount_by")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(this.parameters.discount_type.name) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.term")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(this.parameters.term.name) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.date_begin")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(this.parameters.date_begin) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  this.parameters.renewal_date
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.date_renow")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(this.parameters.renewal_date) +
                                "\n              "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.parameters.code
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.service_number")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(this.parameters.code) +
                                "\n              "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              this.packageCustomer.FormattedAddress &&
              this.packageCustomer.FormattedAddress !== "N/A" &&
              this.isView
                ? _c(
                    "div",
                    [
                      _c("sw-divider", { staticClass: "my-8" }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-gray-500 uppercase sw-section-title",
                        },
                        [
                          _vm._v(
                            "\n" +
                              _vm._s(_vm.$t("settings.company_info.address")) +
                              "\n"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "address-grid" }, [
                        _c("div", { staticClass: "address-row" }, [
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("customers.country")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress
                                    .CountryName
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("customers.state")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress
                                    .StateName
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("customers.county")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress.county
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("customers.city")) + ":"),
                            ]),
                            _vm._v(
                              _vm._s(this.packageCustomer.FormattedAddress.city)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "address-row" }, [
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.zip_code")) + ":"
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress.zip
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          this.packageCustomer.FormattedAddress.type ===
                          "services_address"
                            ? _c("div", { staticClass: "address-column" }, [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("general.type")) + ":"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("general.for_service")) +
                                    "\n"
                                ),
                              ])
                            : this.packageCustomer.FormattedAddress.type ===
                              "billing"
                            ? _c("div", { staticClass: "address-column" }, [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("general.type")) + ":"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("customers.billing_address")
                                    ) +
                                    "\n"
                                ),
                              ])
                            : this.packageCustomer.FormattedAddress.type ===
                              "shipping"
                            ? _c("div", { staticClass: "address-column" }, [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("general.type")) + ":"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("customers.shipping_address")
                                    ) +
                                    "\n"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.address_1")) + ":"
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress
                                    .address_street_1
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "address-column" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.address_2")) + ":"
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.packageCustomer.FormattedAddress
                                    .address_street_2
                                )
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "my-8" }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text-gray-500 uppercase sw-section-title" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("items.title")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-8" }, [
                _c("table", { staticClass: "w-full text-center item-table" }, [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "40%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c("col", { staticStyle: { width: "15%" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                  ]),
                  _vm._v(" "),
                  _c("thead", { staticClass: "bg-white" }, [
                    _c(
                      "th",
                      {
                        staticClass:
                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                      },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tc("items.item", 1)) +
                              "\n                  "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700",
                      },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("item_groups.item.qty")) +
                              "\n                  "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                      },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("item_groups.item.price")) +
                              "\n                  "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("invoices.item.discount")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700",
                      },
                      [
                        _c("span", {}, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("invoices.item.total")) +
                              "\n                  "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.packageCustomer.items, function (item, index) {
                      return _c(
                        "tr",
                        {
                          key: index,
                          staticClass: "border-t border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "p-0 text-left align-top",
                              attrs: { colspan: "5" },
                            },
                            [
                              _c("table", { staticClass: "w-full" }, [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "40%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "10%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "15%" } }),
                                  _vm._v(" "),
                                  _vm.discountPerItem === "YES"
                                    ? _c("col", {
                                        staticStyle: { width: "15%" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "15%" } }),
                                ]),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "px-5 py-4 text-left align-top",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex justify-start" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "px-5 py-4 text-right align-top",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.quantity)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "px-5 py-4 text-left align-top",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-col" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-auto flex-fill bd-highlight",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "relative w-full",
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            item.price,
                                                            _vm.defaultCurrency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.discountPerItem === "YES"
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "px-5 py-4 text-left align-top",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex flex-col" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex flex-auto",
                                                    attrs: { role: "group" },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.discount)
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "flex" },
                                                      [
                                                        _vm._v(
                                                          "\n                                  " +
                                                            _vm._s(
                                                              item.discount_type ===
                                                                "fixed"
                                                                ? _vm
                                                                    .defaultCurrency
                                                                    .symbol
                                                                : "%"
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "px-5 py-4 text-right align-top",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex items-center justify-end text-sm",
                                          },
                                          [
                                            _c("span", [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      item.total,
                                                      _vm.defaultCurrency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _c("sw-switch", {
                                            staticClass: "relative",
                                            staticStyle: {
                                              top: "-10px",
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { disabled: true },
                                            model: {
                                              value: item.end_period_act,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "end_period_act",
                                                  $$v
                                                )
                                              },
                                              expression: "item.end_period_act",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "flex-grow": "1",
                                            "margin-left": "15px",
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-0 mb-1 text-base leading-snug text-black",
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "packages.end_period_act"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "p-0 m-0 text-xs leading-tight text-gray-500",
                                                staticStyle: {
                                                  "max-width": "480px",
                                                  "margin-left": "10px",
                                                  "margin-bottom": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "packages.end_period_act_desp"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _c("sw-input", {
                                            staticStyle: {
                                              "margin-top": "10px",
                                              "margin-bottom": "10px",
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "packages.end_period_num"
                                              ),
                                              disabled: true,
                                              type: "number",
                                              min: "1",
                                              small: "",
                                              onkeydown:
                                                "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                            },
                                            model: {
                                              value: item.end_period_number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "end_period_number",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.end_period_number",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.taxPerItem === "YES"
                                    ? _c("tr", { staticClass: "tax-tr" }, [
                                        _c("td", {
                                          staticClass:
                                            "px-5 py-4 text-left align-top",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "px-5 py-4 text-left align-top",
                                            attrs: { colspan: "4" },
                                          },
                                          _vm._l(
                                            item.taxes,
                                            function (tax, index) {
                                              return tax.amount
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "flex items-center justify-between mb-3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex items-center",
                                                          staticStyle: {
                                                            flex: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "pr-2 mb-0",
                                                              attrs: {
                                                                align: "right",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "general.tax"
                                                                    )
                                                                  ) +
                                                                  "\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                "(" +
                                                                  tax.name +
                                                                  " - " +
                                                                  tax.percent +
                                                                  "%)"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("div", {
                                                        staticClass:
                                                          "text-sm text-right",
                                                        staticStyle: {
                                                          flex: "3",
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$utils.formatMoney(
                                                              tax.amount,
                                                              _vm.defaultCurrency
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "my-8" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
                },
                [
                  _c("div", { staticClass: "w-full lg:w-1/2" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("invoices.sub_total")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.packageCustomer.sub_total,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.allTaxes, function (tax) {
                        return _c(
                          "div",
                          {
                            key: tax.tax_type_id,
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  _vm._s(tax.name) +
                                    " - " +
                                    _vm._s(tax.percent) +
                                    "%\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        tax.amount,
                                        _vm.defaultCurrency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.discountPerItem === "NO" ||
                      _vm.discountPerItem === null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mt-2",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("invoices.discount")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { width: "105px" },
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.packageCustomer.discount)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {}, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.packageCustomer.discount_type ===
                                            "fixed"
                                            ? _vm.defaultCurrency.symbol
                                            : "%"
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.taxPerItem ? "NO" : null)
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.packageCustomer.taxes,
                              function (tax, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "flex items-center justify-between w-full mt-2 text-sm",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(tax.name) +
                                            " (" +
                                            _vm._s(tax.percent) +
                                            "%)\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg text-black",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                tax.amount,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("invoices.total")) +
                                  " " +
                                  _vm._s(_vm.$t("invoices.amount")) +
                                  ":\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center text-lg uppercase text-primary-400",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.packageCustomer.total,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.isView
                ? _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass:
                            "flex justify-center w-full md:w-auto align-bottom",
                          attrs: {
                            disabled: _vm.isLoading,
                            variant: "primary-outline",
                            size: "lg",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.back()
                            },
                          },
                        },
                        [
                          _c("arrow-left-icon", {
                            staticClass: "h-5 mr-2 -ml-1",
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.back")) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass:
                            "flex justify-center w-full md:w-auto ml-4 align-bottom",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                          on: { click: _vm.submitPackage },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("general.update")
                                  : _vm.$t("services.add_service")
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }