var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-8 py-8 sm:p-6" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("pbx_services.did_channel"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("span", { staticClass: "text-sm text-gray-600" }, [
                    _vm._v(_vm._s(_vm.formData.name)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4",
                  attrs: {
                    label: _vm.$t("pbx_services.did_channel"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("span", { staticClass: "text-sm text-gray-600" }, [
                    _vm._v(_vm._s(_vm.formData.number)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "my-4",
                  attrs: {
                    label: _vm.$t("general.price"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("sw-money", {
                    attrs: { currency: _vm.defaultCurrencyForInput },
                    model: {
                      value: _vm.formData.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "price", $$v)
                      },
                      expression: "formData.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "primary-outline", type: "button" },
                      on: { click: _vm.closeExtensionModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    { attrs: { variant: "primary", type: "submit" } },
                    [
                      _c("save-icon", { staticClass: "mr-2" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.save")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }