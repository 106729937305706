var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAppLoaded
    ? _c(
        "div",
        { staticClass: "h-full" },
        [
          _c("base-modal"),
          _vm._v(" "),
          _vm.isCorePosDashboard ? _c("site-header") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "flex h-screen overflow-hidden pt-16 pb-10":
                  _vm.isCorePosDashboard,
                "flex h-screen overflow-hidden ": !_vm.isCorePosDashboard,
              },
            },
            [
              _vm.isCorePosDashboard ? _c("site-sidebar") : _vm._e(),
              _vm._v(" "),
              _c("router-view"),
            ],
            1
          ),
          _vm._v(" "),
          _vm.intiAutoLogout
            ? _c("auto-logout", {
                attrs: { "idle-time": parseInt(_vm.idleTimeLogout) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("site-footer", { attrs: { footer: _vm.footer } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "h-full" },
        [_c("refresh-icon", { staticClass: "h-6 animate-spin" })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }