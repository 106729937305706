var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex items-center justify-between mb-3" }, [
    _c(
      "div",
      { staticClass: "flex items-center", staticStyle: { flex: "4" } },
      [
        _c("label", { staticClass: "pr-2 mb-0", attrs: { align: "right" } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("general.tax")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("sw-select", {
          attrs: {
            options: _vm.filteredTypes,
            "allow-empty": false,
            "show-labels": false,
            "custom-label": _vm.customLabel,
            placeholder: _vm.$t("general.select_a_tax"),
            "track-by": "name",
            label: "name",
          },
          on: { select: (val) => _vm.onSelectTax(val) },
          model: {
            value: _vm.selectedTax,
            callback: function ($$v) {
              _vm.selectedTax = $$v
            },
            expression: "selectedTax",
          },
        }),
        _vm._v(" "),
        _c("br"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "text-sm text-right",
      staticStyle: { flex: "3" },
      domProps: {
        innerHTML: _vm._s(_vm.$utils.formatMoney(_vm.taxAmount, _vm.currency)),
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
      },
      [
        _vm.taxes.length && _vm.index !== _vm.taxes.length - 1
          ? _c("trash-icon", {
              staticClass: "h-5 text-gray-700",
              on: { click: _vm.removeTax },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }