var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("corePbx.menu_title.tenants_list") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("corePbx.menu_title.tenants_list"),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap items-center justify-end md:-mt-12 mb-5 gap-3",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("general.filter")) + "\n      "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: "tenants-list/create",
                    variant: "primary",
                    size: "lg",
                  },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.tenants.add")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("general.name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setFilters("name")
                          },
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("customers.server") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "server",
                          autocomplete: "off",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setFilters("server")
                          },
                        },
                        model: {
                          value: _vm.filters.server,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "server", $$v)
                          },
                          expression: "filters.server",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("corePbx.tenants.code") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setFilters("code")
                          },
                        },
                        model: {
                          value: _vm.filters.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "code", $$v)
                          },
                          expression: "filters.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative table-container" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.tenantsList.length))]),
                    _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalTenants))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  staticClass: "-mt-10 md:mt-0",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("general.name"),
                      show: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.name"))),
                              ]),
                              _vm._v(" "),
                              _vm.permissionModule.read
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500 hover:text-blue-600",
                                      attrs: {
                                        to: {
                                          path: `/admin/corePBX/tenant/tenants-list/${row.id}/view`,
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.name) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3805952005
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("corePbx.tenants.code"),
                      show: "tenant_code",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("corePbx.tenants.code"))),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "ml-3" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.tenant_code) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2191861213
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("general.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.status"))),
                              ]),
                              _vm._v(" "),
                              row.status === "Active/Completed"
                                ? _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t("corePbx.tenants.completed")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("general.inactive")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3558059436
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("general.date"),
                      show: "created_at",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.date"))),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.formatDate(row.created_at)) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4213148425
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("general.server"),
                      show: "server_name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.server"))),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "ml-5" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.metadata.server_name) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              row &&
                              row.metadata &&
                              row.metadata.server_status === "A"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-success fs-6 text-center",
                                      staticStyle: { "font-size": "14px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.customization.modules.server_online"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger fs-6 text-center",
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.customization.modules.server_offline"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3588030925
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("general.use"),
                      show: "in_use",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.use"))),
                              ]),
                              _vm._v(" "),
                              row.metadata.in_use === true
                                ? _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("general.yes")) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("general.not")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2640799117
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("packages.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _vm.permissionModule.read
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `/admin/corePBX/tenant/tenants-list/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _c("eye-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.update &&
                                  row.status === "Incomplete"
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.completedStatus(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("check-circle-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.tenants.completed"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      159181936
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }