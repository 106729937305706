var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-destination" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              ;("submitData")
            },
          },
        },
        [
          !_vm.showPreview
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "py-4 px-6 form-group-row" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4 md:col-span-2",
                        attrs: {
                          label: _vm.$t("general.file"),
                          variant: "horizontal",
                          error: _vm.fileError,
                          required: "",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              ref: "file",
                              attrs: {
                                invalid:
                                  _vm.$v.formData.file.$error ||
                                  !this.isValidFile,
                                type: "file",
                                name: "file",
                              },
                              on: {
                                change: _vm.onFileChange,
                                input: function ($event) {
                                  return _vm.$v.formData.file.$touch()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "font-medium text-primary-500 ml-6 cursor-pointer",
                                on: { click: _vm.downloadTemplate },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("expenses.download_exmaple")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "font-normal md:col-span-2",
                        attrs: { variant: "horizontal" },
                      },
                      [
                        _c("sw-radio", {
                          attrs: {
                            label: _vm.$t(
                              "corePbx.custom_did_groups.import_to_existing_group"
                            ),
                            size: "sm",
                            name: "filter",
                            value: "existing_group",
                          },
                          on: {
                            change: function ($event) {
                              "onSearch"
                            },
                          },
                          model: {
                            value: _vm.formData.type_group,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type_group", $$v)
                            },
                            expression: "formData.type_group",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formData.type_group === "existing_group"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "my-4",
                                attrs: {
                                  variant: "horizontal",
                                  error: _vm.customError,
                                  required: "",
                                },
                              },
                              [
                                _c("sw-select", {
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.prefixrate_groups_id
                                        .$error,
                                    options: _vm.getCustomDidGroups,
                                    searchable: true,
                                    "show-labels": false,
                                    "allow-empty": true,
                                    "max-height": 200,
                                    placeholder: _vm.$t(
                                      "corePbx.custom_did_groups.select_a_custom_did_group"
                                    ),
                                    label: "name",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.prefixrate_groups_id.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.prefixrate_groups_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "prefixrate_groups_id",
                                        $$v
                                      )
                                    },
                                    expression: "formData.prefixrate_groups_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "font-normal",
                        attrs: { variant: "horizontal" },
                      },
                      [
                        _c("sw-radio", {
                          attrs: {
                            label: _vm.$t(
                              "corePbx.custom_did_groups.import_to_new_group"
                            ),
                            size: "sm",
                            name: "filter",
                            value: "new_group",
                          },
                          on: {
                            change: function ($event) {
                              "onSearch"
                            },
                          },
                          model: {
                            value: _vm.formData.type_group,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type_group", $$v)
                            },
                            expression: "formData.type_group",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formData.type_group === "new_group"
                      ? _c(
                          "div",
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "my-4",
                                attrs: {
                                  error: _vm.nameError,
                                  variant: "horizontal",
                                  required: "",
                                },
                              },
                              [
                                _c("sw-input", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    invalid: _vm.$v.formData.name.$error,
                                    placeholder: _vm.$t(
                                      "corePbx.custom_did_groups.name"
                                    ),
                                    type: "text",
                                    name: "name",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.name.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  error: _vm.typeError,
                                  variant: "horizontal",
                                  required: "",
                                },
                              },
                              [
                                _c("sw-select", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    invalid: _vm.$v.formData.type.$error,
                                    options: _vm.types,
                                    searchable: true,
                                    "show-labels": false,
                                    "allow-empty": true,
                                    placeholder: _vm.$t(
                                      "corePbx.custom_did_groups.select_a_type"
                                    ),
                                    label: "name",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.type.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "type", $$v)
                                    },
                                    expression: "formData.type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                  },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3",
                        attrs: { variant: "primary-outline", type: "button" },
                        on: { click: _vm.closeImportModal },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        attrs: { variant: "primary", type: "button" },
                        on: { click: _vm.uploadFile },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.next")) +
                            "\n          "
                        ),
                        _c("arrow-right-icon", {
                          staticClass: "h-5 ml-2 -mr-1",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showPreview
            ? _c("div", [
                _c("div", { staticClass: "py-4 px-6 form-group-row" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("corePbx.custom_did_groups.column_matching")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    { staticClass: "w-full text-center item-table mb-5 mt-2" },
                    [
                      _c(
                        "thead",
                        {
                          staticClass:
                            "bg-gray-400 border border-gray-200 border-solid",
                        },
                        _vm._l(_vm.table_headers, function (field, key) {
                          return _c(
                            "th",
                            {
                              key: key,
                              staticClass:
                                "px-5 py-3 text-center text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [_c("span", {}, [_vm._v(_vm._s(field))])]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.view_data, function (row, index) {
                            return _c(
                              "tr",
                              _vm._l(row, function (value, i) {
                                return _c(
                                  "td",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-center align-top border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(value) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "tr",
                            _vm._l(_vm.table_headers, function (field, key) {
                              return _c(
                                "td",
                                {
                                  key: key,
                                  staticClass: "px-5 py-4 text-left align-top",
                                },
                                [
                                  _c("sw-select", {
                                    ref: "field_selects",
                                    refInFor: true,
                                    attrs: {
                                      invalid:
                                        _vm.$v.field_selects.$each[key].value
                                          .$error,
                                      options: _vm.db_fields,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      "max-height": 200,
                                      placeholder: _vm.$t(
                                        "general.select_an_option"
                                      ),
                                      label: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.field_selects.$each[
                                          key
                                        ].value.$touch()
                                      },
                                      select: (item) =>
                                        _vm.matchColumn(item, field),
                                    },
                                    model: {
                                      value: _vm.field_selects[key],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.field_selects, key, $$v)
                                      },
                                      expression: "field_selects[key]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                  },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3",
                        attrs: { variant: "primary-outline", type: "button" },
                        on: { click: _vm.back },
                      },
                      [
                        _c("arrow-left-icon", {
                          staticClass: "h-5 mr-2 -ml-1",
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.back")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        attrs: { variant: "primary", type: "button" },
                        on: { click: _vm.submitData },
                      },
                      [
                        _c("save-icon", { staticClass: "mr-2" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.save")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }