var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        [
          _c(
            "sw-page-header",
            {
              staticClass: "mb-3",
              attrs: { title: _vm.$t("customer_ticket.view_ticket") },
            },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/tickets/main",
                      title: _vm.$tc("customer_ticket.title", 2),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("customer_ticket.view_ticket"),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/tickets/main`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.go_back")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.ticket.customer.id}/ticket`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("tickets.go_to_customer")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "primary-outline" },
                      on: {
                        click: function ($event) {
                          return _vm.editTicket(_vm.ticket)
                        },
                      },
                    },
                    [
                      _c("pencil-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteTicket(_vm.ticket.id)
                        },
                      },
                    },
                    [
                      _c("trash-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(" "),
                      !_vm.isLoading
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$tc("general.delete"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c(
              "div",
              { staticClass: "col-span-12" },
              [
                _vm.ticket.customer
                  ? _c("sw-card", [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("customer_ticket.information_ticket")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap mt-5 md:mt-7" },
                          [
                            _c("div", { staticClass: "w-full md:w-1/2" }, [
                              _c("p", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("customer_ticket.customer"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-gray-700 text-sm" },
                                [_vm._v(_vm._s(_vm.ticket.customer.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-gray-700 text-sm" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.ticket.customer.customcode) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mt-3 md:mt-0" },
                              [
                                _c("p", { staticClass: "font-bold" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("customer_ticket.summary"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [_vm._v(_vm._s(_vm.ticket.summary))]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap mt-5 md:mt-7" },
                          [
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc("customer_ticket.departament")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.ticket.ticket_departament.name
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc("customer_ticket.assignedTo")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.ticket.assigned.name) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc("customer_ticket.priority")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", {
                                  staticClass: "text-gray-700 text-sm",
                                }),
                                _vm.ticket.priority == "E" ||
                                _vm.ticket.priority == "C" ||
                                _vm.ticket.priority == "H"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getPriority(
                                                    _vm.ticket.priority
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.priority == "M"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getPriority(
                                                    _vm.ticket.priority
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.priority == "L"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getPriority(
                                                    _vm.ticket.priority
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-full md:w-1/4" }, [
                              _c("div", { staticClass: "font-bold py-2" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("customer_ticket.status")) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", {
                                  staticClass: "text-gray-700 text-sm",
                                }),
                                _vm.ticket.status == "S"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "OVERDUE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    _vm.ticket.status
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.status == "C"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "UNPAID"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "UNPAID"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    _vm.ticket.status
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.status == "I"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "VIEWED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    _vm.ticket.status
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.status == "O"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "SENT"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "SENT"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    _vm.ticket.status
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket.status == "M"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "px-3 py-1",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "COMPLETED"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getStatus(
                                                    _vm.ticket.status
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p"),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("customer_ticket.details"))),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(_vm._s(_vm.ticket.note)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                          _c("p", { staticClass: "font-bold mb-2" }, [
                            _vm._v(_vm._s(_vm.$t("customer_ticket.user"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            _vm._l(_vm.ticket.users, function (tr, indexTr) {
                              return _c(
                                "div",
                                { key: indexTr, staticClass: "flex my-2" },
                                [
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _c(
                                      "p",
                                      { staticClass: "text-gray-700 text" },
                                      [_vm._v(_vm._s(tr.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "text-gray-700 text-sm" },
                                      [_vm._v(_vm._s(tr.email))]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-page-header",
            { staticClass: "mt-5", attrs: { title: " " } },
            [
              _c("template", { slot: "actions" }, [
                _c("div", { staticClass: "w-full" }, [
                  _c(
                    "div",
                    { staticClass: "col-span-12" },
                    [
                      _c(
                        "sw-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.notes_count,
                              expression: "notes_count",
                            },
                          ],
                          attrs: { variant: "primary-outline", size: "lg" },
                          on: { click: _vm.toggleFilter },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.filter")) +
                              "\n              "
                          ),
                          _c(_vm.filterIcon, {
                            tag: "component",
                            staticClass: "w-4 h-4 ml-2 -mr-1",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.createNote(_vm.ticket)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("customer_ticket.create_note")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full mt-5" }, [
            _c(
              "div",
              { staticClass: "col-span-12" },
              [
                _c("sw-card", [
                  _c(
                    "div",
                    [
                      _c(
                        "slide-y-up-transition",
                        [
                          _c(
                            "sw-filter-wrapper",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFilters,
                                  expression: "showFilters",
                                },
                              ],
                              staticClass: "mt-3",
                            },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "flex-1 mt-2 mr-4",
                                  attrs: {
                                    label: _vm.$tc("customer_ticket.reference"),
                                  },
                                },
                                [
                                  _c(
                                    "sw-input",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        type: "text",
                                        name: "reference",
                                        autocomplete: "off",
                                      },
                                      model: {
                                        value: _vm.filters.reference,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "reference",
                                            $$v
                                          )
                                        },
                                        expression: "filters.reference",
                                      },
                                    },
                                    [
                                      _c("hashtag-icon", {
                                        staticClass: "h-5 ml-1 text-gray-500",
                                        attrs: { slot: "leftIcon" },
                                        slot: "leftIcon",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "flex-1 mt-2 mr-4",
                                  attrs: {
                                    label: _vm.$tc("customer_ticket.subject"),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      type: "text",
                                      name: "subject",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.filters.subject,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "subject", $$v)
                                      },
                                      expression: "filters.subject",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "flex-1 mt-2 mr-4",
                                  attrs: {
                                    label: _vm.$tc("customer_ticket.user"),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.users,
                                      searchable: true,
                                      "show-labels": false,
                                      placeholder: _vm.$t(
                                        "customer_ticket.assignedTo"
                                      ),
                                      label: "name",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.filter = !_vm.filter
                                      },
                                    },
                                    model: {
                                      value: _vm.filters.user,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "user", $$v)
                                      },
                                      expression: "filters.user",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                                  attrs: {
                                    label: _vm.$t("expenses.from_date"),
                                  },
                                },
                                [
                                  _c("base-date-picker", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      "calendar-button": true,
                                      "calendar-button-icon": "calendar",
                                    },
                                    model: {
                                      value: _vm.filters.from_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "from_date", $$v)
                                      },
                                      expression: "filters.from_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                                  attrs: { label: _vm.$t("expenses.to_date") },
                                },
                                [
                                  _c("base-date-picker", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      "calendar-button": true,
                                      "calendar-button-icon": "calendar",
                                    },
                                    model: {
                                      value: _vm.filters.to_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "to_date", $$v)
                                      },
                                      expression: "filters.to_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                                  staticStyle: { top: "10px", right: "15px" },
                                  on: { click: _vm.clearFilter },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("general.clear_all"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "relative table-container" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                        },
                        [_c("p", { staticClass: "text-sm" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-table-component",
                        {
                          ref: "notes_table",
                          attrs: {
                            "show-filter": false,
                            "table-class": "table",
                            data: _vm.fetchNotes,
                          },
                        },
                        [
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.reference"),
                              show: "reference",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.reference")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-medium text-primary-500 cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewNote(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n" + _vm._s(row.reference) + "\n"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3101065501
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.subject"),
                              show: "subject",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.subject")
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.subject) +
                                          "\n                "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              427713483
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.message"),
                              show: "message",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.message")
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.message) +
                                          "\n                "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2541352683
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.user"),
                              show: "user_name",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("customer_ticket.user"))
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.user_name) +
                                          "\n                "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3212643859
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: { sortable: true, label: "Datetime" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("general.datetime")) +
                                            " "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.date) +
                                          " " +
                                          _vm._s(row.time) +
                                          "\n                "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2186775147
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: { "cell-class": "action-dropdown no-click" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewNote(row)
                                                },
                                              },
                                            },
                                            [
                                              _c("eye-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.view")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editNote(
                                                    _vm.ticket,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteNote(
                                                    row.note_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("trash-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1204575085
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }