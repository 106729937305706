var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("invoices.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("invoices.invoice", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalInvoices,
                      expression: "totalInvoices",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSuperAdmin
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/customer/invoices/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("invoices.new_invoice")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:mx-8",
                  attrs: { label: _vm.$t("invoices.status") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.status,
                      "group-select": false,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("general.select_a_status"),
                      "allow-empty": false,
                      "group-values": "options",
                      "group-label": "label",
                      "track-by": "name",
                      label: "name",
                    },
                    on: {
                      remove: function ($event) {
                        return _vm.clearStatusSearch()
                      },
                      select: _vm.setActiveTab,
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("general.from") },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block",
                staticStyle: { "margin-top": "3.5rem" },
              }),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { staticClass: "mt-2", attrs: { label: _vm.$t("general.to") } },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: { label: _vm.$t("invoices.invoice_number") },
                },
                [
                  _c(
                    "sw-input",
                    {
                      model: {
                        value: _vm.filters.invoice_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "invoice_number", $$v)
                        },
                        expression: "filters.invoice_number",
                      },
                    },
                    [
                      _c("hashtag-icon", {
                        staticClass: "h-5 ml-1 text-gray-500",
                        attrs: { slot: "leftIcon" },
                        slot: "leftIcon",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("invoices.no_invoices"),
            description: _vm.$t("invoices.list_of_invoices"),
          },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          !_vm.isCustomer
            ? _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/customer/invoices/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      create\n      " +
                      _vm._s(_vm.$t("invoices.new_invoice")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            { staticClass: "relative mt-5" },
            [
              _vm.IsArchivedActived == false
                ? _c("span", [
                    _c(
                      "p",
                      { staticClass: "absolute left-0 md:mt-12 text-sm" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.showing")) +
                            ": "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.invoices.length))]),
                        _vm._v(
                          "\n\n          " + _vm._s(_vm.$t("general.of")) + " "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.totalInvoices))]),
                      ]
                    ),
                  ])
                : _vm.IsArchivedActived == true
                ? _c("span", [
                    _c(
                      "p",
                      {
                        staticClass: "absolute left-0 md:mt-12 text-sm",
                        staticStyle: { top: "50px" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.showing")) +
                            ": "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.archived.length))]),
                        _vm._v(
                          "\n\n          " + _vm._s(_vm.$t("general.of")) + " "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.totalArchived))]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  staticClass: "hidden md:inline",
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter },
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.due"), filter: "DUE" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.overdue"),
                      filter: "OVERDUE",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.completed"),
                      filter: "COMPLETED",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.archived"),
                      filter: "ARCHIVED",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "-mt-3 md:mt-5",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.number")))]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: { to: { path: `invoice/${row.id}/view` } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.invoice_number) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.status"),
                  "sort-as": "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("invoices.status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.status.replace("_", " ")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.paid_status"),
                  "sort-as": "paid_status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.paid_status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.paid_status.replace("_", " ")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.total"),
                  "sort-as": "total",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.amount_due"),
                  "sort-as": "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.amount_due"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, filterable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `invoice/${row.id}/view`,
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("invoices.view")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                row.status === "SENT" ||
                                row.status === "VIEWED" ||
                                row.status === "OVERDUE"
                                  ? _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "paymentsCustomer.create",
                                              params: { id: row.id },
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("credit-card-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("payments.make_payment")
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }