var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "div",
      { class: { "xl:pl-64": _vm.showSideBar } },
      [
        _c("div", { staticClass: "w-full flex justify-end" }, [
          _c(
            "div",
            { staticClass: "mb-3 hidden xl:block" },
            [
              _c(
                "sw-button",
                {
                  attrs: { variant: "primary-outline" },
                  on: { click: _vm.toggleSideBar },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tickets.departaments.menu")) +
                      "\n          "
                  ),
                  _c(_vm.listIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 pb-6 xl:hidden" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("corePbx.pbx")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full mb-6 select-wrapper xl:hidden" },
          [
            _c("sw-select", {
              attrs: {
                options: _vm.menuItemsSelect,
                searchable: true,
                "show-labels": false,
                "allow-empty": false,
                "custom-label": _vm.getCustomLabel,
              },
              on: { input: _vm.navigateToPBX },
              model: {
                value: _vm.currentPbx,
                callback: function ($$v) {
                  _vm.currentPbx = $$v
                },
                expression: "currentPbx",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "slide-x-left-transition",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "fixed hidden h-full top-0 left-0 pt-16 pb-4 bg-white xl:ml-64 w-64 xl:block",
              },
              [
                _c(
                  "div",
                  { staticClass: "p-5" },
                  [
                    _c(
                      "sw-page-header",
                      { attrs: { title: _vm.$tc("corePbx.title", 1) } },
                      [
                        _c(
                          "sw-breadcrumb",
                          {
                            attrs: { slot: "breadcrumbs" },
                            slot: "breadcrumbs",
                          },
                          [
                            _c("sw-breadcrumb-item", {
                              attrs: {
                                title: _vm.$t("general.home"),
                                to: "/admin/dashboard",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-breadcrumb-item", {
                              attrs: {
                                title: _vm.$tc("corePbx.corePbx", 2),
                                to: "#",
                                active: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid md:grid-cols-12" }, [
                      this.$route.path != "/admin/corePBX/PBXwareDashboard"
                        ? _c(
                            "div",
                            { staticClass: "hidden col-span-12 mt-1 xl:block" },
                            _vm._l(_vm.menuItems, function (menuItem, index) {
                              return menuItem.show
                                ? _c(
                                    "sw-list",
                                    {
                                      key: index,
                                      attrs: { title: _vm.$t(menuItem.title) },
                                    },
                                    [
                                      _c(
                                        "sw-list-item",
                                        {
                                          staticClass: "py-3",
                                          attrs: {
                                            title: _vm.$t(menuItem.title),
                                            to: menuItem.link,
                                            active: _vm.hasActiveUrl(
                                              menuItem.link
                                            ),
                                            "tag-name": "router-link",
                                          },
                                        },
                                        [
                                          _c(menuItem.icon, {
                                            tag: "component",
                                            staticClass: "h-5",
                                            attrs: { slot: "icon" },
                                            slot: "icon",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      menuItem.subMenuItems != null
                                        ? _c(
                                            "span",
                                            _vm._l(
                                              menuItem.subMenuItems,
                                              function (subMenuItem, idx) {
                                                return subMenuItem.show
                                                  ? _c(
                                                      "sw-list-item",
                                                      {
                                                        key: idx,
                                                        staticClass: "px-5",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            subMenuItem.title
                                                          ),
                                                          to: subMenuItem.link,
                                                          active:
                                                            _vm.hasActiveUrl(
                                                              subMenuItem.link
                                                            ),
                                                          "tag-name":
                                                            "router-link",
                                                        },
                                                      },
                                                      [
                                                        _c(subMenuItem.icon, {
                                                          tag: "component",
                                                          staticClass: "h-5",
                                                          attrs: {
                                                            slot: "icon",
                                                          },
                                                          slot: "icon",
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }