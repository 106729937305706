var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "pt-6 mt-5 border-t-2 border-solid lg:pt-8 md:pt-4": !_vm.isServiceView,
      },
      style: { "border-top-color: #f9fbff": !_vm.isServiceView },
    },
    [
      _c("div", { staticClass: "col-span-12" }, [
        _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("customers.basic_info")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
          },
          [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.customer_number")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.customcode
                          ? _vm.selectedViewCustomer.customer.customcode
                          : ""
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.display_name")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.name
                          ? _vm.selectedViewCustomer.customer.name
                          : ""
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.primary_contact_name")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.contact_name
                          ? _vm.selectedViewCustomer.customer.contact_name
                          : ""
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }