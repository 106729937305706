var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        [
          _c("div", { staticClass: "w-full flex justify-end" }, [
            _c(
              "div",
              { staticClass: "mb-3 hidden xl:block" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.toggleSideBar },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("tickets.departaments.menu")) +
                        "\n                "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-tabs",
            { staticClass: "p-2" },
            [
              _c(
                "sw-tab-item",
                { attrs: { title: _vm.$t("logs.module_logs.title") } },
                [_c("module-logs")],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-tab-item",
                { attrs: { title: _vm.$t("logs.email_logs.title") } },
                [_c("email-logs")],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "sw-tab-item",
                    {
                      attrs: {
                        title: _vm.$t("logs.failed_payment_history.title"),
                      },
                    },
                    [_c("failed-payment-history")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }