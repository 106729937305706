var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.service_view") } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline" },
                  on: {
                    click: function ($event) {
                      return _vm.gobackForm()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/services`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("navigation.services")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-select", {
                staticClass: "mr-3",
                attrs: {
                  options: _vm.getStatuses,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t("customers.select_a_status"),
                  label: "status_name",
                },
                on: { select: (item) => _vm.changeStatus(item) },
                model: {
                  value: _vm.status_selected,
                  callback: function ($$v) {
                    _vm.status_selected = $$v
                  },
                  expression: "status_selected",
                },
              }),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _c("dots-horizontal-icon", {
                        staticClass: "h-5 -ml-1 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isHiddenStatus
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/customers/${_vm.$route.params.id}/service/${_vm.$route.params.customer_package_id}/edit`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("pencil-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.edit")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "invoices.create",
                                  query: {
                                    from: "customer",
                                    code: _vm.selectedService.code,
                                    customer_packages_id:
                                      _vm.selectedService.id,
                                    customer_id:
                                      _vm.selectedService.customer_id,
                                    package_id: _vm.selectedService.package_id,
                                  },
                                },
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("calculator-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("invoices.new_invoice")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              attrs: {
                                to: `/admin/tickets/main/add-ticket`,
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c("ticket-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        to: `/admin/customers/${_vm.$route.params.id}/view`,
                        "tag-name": "router-link",
                      },
                    },
                    [
                      _c("users-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.go_to_customer")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        to: `/admin/packages/${this.selectedService.package_id}/view`,
                        "tag-name": "router-link",
                      },
                    },
                    [
                      _c("ticket-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.go_to_packages")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _c("customer-info"),
          _vm._v(" "),
          _c("confirm-package", { on: { status: _vm.setStatus } }),
          _vm._v(" "),
          _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-5" }, [
            _c("div", { staticClass: "border-b tab" }, [
              _c("div", { staticClass: "relative" }, [
                _c("input", {
                  staticClass:
                    "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                  attrs: { type: "checkbox" },
                }),
                _vm._v(" "),
                _c(
                  "header",
                  {
                    staticClass:
                      "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-gray-500 uppercase sw-section-title",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.invoices")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              "aria-hidden": "true",
                              "data-reactid": "266",
                              fill: "none",
                              height: "24",
                              stroke: "#606F7B",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              viewbox: "0 0 24 24",
                              width: "24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: { points: "6 9 12 15 18 9" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tab-content-slide" },
                  [
                    _c("div", { staticClass: "text-grey-darkest" }, [
                      _c("div", { staticClass: "flex base-tabs" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-table-component",
                      {
                        ref: "table",
                        attrs: {
                          "show-filter": false,
                          data: _vm.fetchInvoicesPerServiceData,
                          "table-class": "table",
                        },
                      },
                      [
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.number"),
                            show: "invoice_number",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("invoices.number"))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to: `/admin/invoices/${row.id}/view`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(row.invoice_number) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.date"),
                            show: "invoice_date",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("estimates.status"),
                            show: "status",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("estimates.status"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(row.status) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.paid_status"),
                            "sort-as": "paid_status",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("invoices.paid_status"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-badge",
                                    {
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            row.paid_status.replace("_", " ")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.total"),
                            "sort-as": "total",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [_vm._v("Total")]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(row.total)
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.amount_due"),
                            "sort-as": "total",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("invoices.amount_due"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(row.due_amount)
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: { sortable: false, filterable: false },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("invoices.action"))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown",
                                    [
                                      _c("dot-icon", {
                                        attrs: { slot: "activator" },
                                        slot: "activator",
                                      }),
                                      _vm._v(" "),
                                      _vm.IsArchivedActived != true
                                        ? _c(
                                            "span",
                                            [
                                              _vm.isSuperAdmin &&
                                              row.noeditable == 0
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `invoices/${row.id}/edit`,
                                                      },
                                                    },
                                                    [
                                                      _c("pencil-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.edit"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isSuperAdmin
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `/admin/invoices/${row.id}/view`,
                                                      },
                                                    },
                                                    [
                                                      _c("eye-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.view"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `/admin/invoices/${row.id}/view`,
                                                      },
                                                    },
                                                    [
                                                      _c("eye-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.view"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                              _vm._v(" "),
                                              row.status == "DRAFT" &&
                                              _vm.isSuperAdmin
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.sendInvoice(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "paper-airplane-icon",
                                                        {
                                                          staticClass:
                                                            "h-5 mr-3 text-gray-600",
                                                        }
                                                      ),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.send_invoice"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.status === "SENT" ||
                                              (row.status === "VIEWED" &&
                                                _vm.isSuperAdmin)
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.sendInvoice(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "paper-airplane-icon",
                                                        {
                                                          staticClass:
                                                            "h-5 mr-3 text-gray-600",
                                                        }
                                                      ),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.resend_invoice"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.status == "DRAFT" &&
                                              _vm.isSuperAdmin
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.markInvoiceAsSent(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("check-circle-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.mark_as_sent"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.status === "SENT" ||
                                              row.status === "VIEWED" ||
                                              (row.status === "OVERDUE" &&
                                                _vm.isSuperAdmin)
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `/admin/payments/${row.id}/create`,
                                                      },
                                                    },
                                                    [
                                                      _c("credit-card-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "payments.record_payment"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.isSuperAdmin
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        "tag-name":
                                                          "router-link",
                                                        to: `#`,
                                                      },
                                                    },
                                                    [
                                                      _c("credit-card-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "payments.record_payment"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isSuperAdmin &&
                                              row.status != "COMPLETED" &&
                                              row.paid_status === "UNPAID"
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeInvoice(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("trash-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.delete"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            [
                                              _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Restore(row)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("save-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.restore"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                      },
                      [
                        _c("div", { staticClass: "w-full lg:w-1/2" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between w-full",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-sm font-semibold leading-5 text-gray-500 uppercase mr-12",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("pbx_services.count")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex items-center justify-center m-0 text-lg text-black uppercase ml-12",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(this.invoicesCount)),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }