var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "item-create" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitPackage.apply(null, arguments)
          },
        },
      },
      [
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "sw-page-header",
          { attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("corePbx.menu_title.packages", 2),
                    to: "/admin/corePBX/packages",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "corepbx.packages.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("packages.edit_package"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _vm.$route.name === "corepbx.packages.copy"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("packages.copy_package"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("packages.new_package"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-card",
          [
            _c(
              "h6",
              { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("packages.basic")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "col-span-12" }),
            _vm._v(" "),
            _c("h1", {
              staticClass: "col-span-12",
              domProps: { textContent: _vm._s(_vm.$t("packages.lang_name")) },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "container mx-auto" }, [
              _c("div", { staticClass: "flex flex-col md:flex-row md:-mx-2" }, [
                _c(
                  "div",
                  { staticClass: "w-full md:w-1/3 md:px-2 mb-4 md:mb-0" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("packages.name_package"),
                          error: _vm.nameError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            invalid: _vm.$v.formData.pbx_package_name.$error,
                            focus: "",
                            type: "text",
                            name: "pbx_package_name",
                            tabindex: 1,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.pbx_package_name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.pbx_package_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pbx_package_name", $$v)
                            },
                            expression: "formData.pbx_package_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full md:w-1/3 md:px-2 mb-4 md:mb-0" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("corePbx.packages.type"),
                          error: _vm.statusPaymentError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: _vm.status_payment,
                            invalid: _vm.$v.formData.status_payment.$error,
                            searchable: true,
                            "show-labels": false,
                            tabindex: 2,
                            disabled: _vm.bandServices,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_status"),
                            label: "text",
                            "track-by": "value",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.status_payment.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.status_payment,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "status_payment",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.status_payment",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full md:w-1/3 md:px-2" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("packages.status"),
                          error: _vm.statusError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            invalid: _vm.$v.formData.status.$error,
                            options: _vm.status,
                            searchable: true,
                            "show-labels": false,
                            tabindex: 3,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_status"),
                            label: "text",
                            "track-by": "value",
                          },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-1 md:grid-cols-4 gap-4" },
              [
                _c(
                  "div",
                  { staticClass: "col-span-1 md:col-span-1" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t("corePbx.packages.dropdown_server"),
                          error: _vm.dropdownError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: _vm.dropdown_server,
                            invalid: _vm.$v.formData.dropdown_server.$error,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            tabindex: 4,
                            disabled: _vm.bandServices,
                            placeholder: _vm.$t(
                              "corePbx.packages.select_server"
                            ),
                            label: "server_label",
                            "track-by": "id",
                          },
                          on: {
                            select: _vm.DropServerSeleted,
                            input: function ($event) {
                              return _vm.$v.formData.dropdown_server.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.dropdown_server,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "dropdown_server", $$v)
                            },
                            expression: "formData.dropdown_server",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formData.dropdown_server
                      ? _c("div", [
                          _vm.formData.dropdown_server.status == "A"
                            ? _c("span", { staticClass: "text-success" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.server_online"
                                    )
                                  )
                                ),
                              ])
                            : _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.server_offline"
                                    )
                                  )
                                ),
                              ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-span-1 md:col-span-1" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3 mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.packages.status_cdrs"),
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: [],
                            searchable: false,
                            placeholder: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function () {
                                return [
                                  _c("p", [_vm._v(_vm._s(_vm.labelStatusCdr))]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "beforeList",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-wrap" },
                                    _vm._l(
                                      _vm.cdrStatusOptions,
                                      function (item, index) {
                                        return _c("sw-checkbox", {
                                          key: index,
                                          staticClass:
                                            "w-full p-2 px-4 hover:bg-gray-100",
                                          attrs: {
                                            variant: item.color,
                                            label: item.label,
                                            value: item.value,
                                          },
                                          on: { change: _vm.changeStatus },
                                          model: {
                                            value: _vm.formData.cdrStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "cdrStatus",
                                                $$v
                                              )
                                            },
                                            expression: "formData.cdrStatus",
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-span-1 md:col-span-1" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t("corePbx.packages.price"),
                          error: _vm.rateError,
                        },
                      },
                      [
                        _c("sw-money", {
                          attrs: {
                            currency: _vm.customerCurrency,
                            invalid: _vm.$v.formData.rate.$error,
                            name: "rate",
                            tabindex: "5",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.rate.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.rate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "rate", $$v)
                            },
                            expression: "formData.rate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-span-1 md:col-span-1" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t("corePbx.packages.apply_tax_type"),
                        },
                      },
                      [
                        _c("sw-select", {
                          ref: "baseSelect",
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.apply_tax_type_options,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": false,
                            label: "text",
                            "track-by": "value",
                          },
                          model: {
                            value: _vm.formData.apply_tax_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "apply_tax_type", $$v)
                            },
                            expression: "formData.apply_tax_type",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-col md:flex-row" }, [
              _c("div", { staticClass: "md:w-1/2" }, [
                _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "relative",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          label: _vm.$t(
                            "didFree.item.custom_destination_groups"
                          ),
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: _vm.prefixrate_groups,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$t(
                              "didFree.item.custom_destination_groups"
                            ),
                            label: "name",
                            "track-by": "id",
                            tabindex: 10,
                            multiple: true,
                          },
                          model: {
                            value: _vm.prefixrate_groups_id,
                            callback: function ($$v) {
                              _vm.prefixrate_groups_id = $$v
                            },
                            expression: "prefixrate_groups_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "md:w-1/2" }, [
                _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "relative",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          label: _vm.$t(
                            "didFree.item.custom_destination_groups_outbound"
                          ),
                        },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: _vm.prefixrate_groups_outboubd,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$t(
                              "didFree.item.custom_destination_groups_outbound"
                            ),
                            label: "name",
                            "track-by": "id",
                            tabindex: 10,
                            multiple: true,
                          },
                          model: {
                            value: _vm.prefixrate_groups_outbound_id,
                            callback: function ($$v) {
                              _vm.prefixrate_groups_outbound_id = $$v
                            },
                            expression: "prefixrate_groups_outbound_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "relative",
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      label: _vm.$tc("corePbx.submenu_title.custom_app_rate"),
                    },
                  },
                  [
                    _vm.formData.custom_app_rate
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            staticStyle: {
                              top: "55%",
                              right: "5%",
                              "z-index": "999999",
                            },
                            on: {
                              click: function ($event) {
                                _vm.formData.custom_app_rate = null
                              },
                            },
                          },
                          [_c("x-circle-icon", { staticClass: "h-5" })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("sw-select", {
                      attrs: {
                        options: _vm.customAppRateOptions,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        placeholder: _vm.$t(
                          "corePbx.submenu_title.custom_app_rate"
                        ),
                        label: "name",
                        "track-by": "id",
                        tabindex: 7,
                      },
                      model: {
                        value: _vm.formData.custom_app_rate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "custom_app_rate", $$v)
                        },
                        expression: "formData.custom_app_rate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("div", { staticClass: "flex mb-5 col-span-12" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-5 col-span-12" }, [
                    _c("div", { staticClass: "ml-4" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tabs mb-5 grid col-span-12" }, [
                    _c("div", { staticClass: "border-b tab" }, [
                      _c(
                        "div",
                        {
                          staticClass: "border-l-2 border-transparent relative",
                        },
                        [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6",
                            attrs: { type: "checkbox", id: "chck1" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center p-3 pl-0 pr-8 cursor-pointer select-none tab-label",
                              attrs: { for: "chck1" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-grey-darkest font-thin text-xl",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("general.description")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "tab-content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "pl-0 pr-8 pb-5 text-grey-darkest",
                              },
                              [
                                _c("ul", { staticClass: "pl-0" }, [
                                  _c(
                                    "li",
                                    { staticClass: "pb-2" },
                                    [
                                      _c(
                                        "sw-tabs",
                                        {
                                          attrs: {
                                            "active-tab": _vm.activeTab,
                                          },
                                        },
                                        [
                                          _c(
                                            "sw-tab-item",
                                            { attrs: { title: "HTML" } },
                                            [
                                              _c("base-custom-input", {
                                                attrs: { fields: [] },
                                                model: {
                                                  value: _vm.formData.html,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "html",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.html",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-tab-item",
                                            { attrs: { title: "Text" } },
                                            [
                                              _c("base-custom-input", {
                                                attrs: { fields: [] },
                                                model: {
                                                  value: _vm.formData.text,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.text",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-5 col-span-12" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt-2 col-span-12" }, [
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("corePbx.packages.automatic_suspension")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("corePbx.packages.automatic_suspension")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.automatic_suspension,
                            callback: function ($$v) {
                              _vm.automatic_suspension = $$v
                            },
                            expression: "automatic_suspension",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.unmetered")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.unmetered")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.unmetered,
                            callback: function ($$v) {
                              _vm.unmetered = $$v
                            },
                            expression: "unmetered",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isAvalaraAvailable
                      ? _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("corePbx.packages.avalara_options")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 m-0 text-xs leading-4 text-gray-500",
                              staticStyle: { "max-width": "480px" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("corePbx.packages.avalara_options")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAvalaraAvailable
                      ? _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              on: { input: _vm.avalaraOptionsChange },
                              model: {
                                value: _vm.avalara_options,
                                callback: function ($$v) {
                                  _vm.avalara_options = $$v
                                },
                                expression: "avalara_options",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.all_cdrs")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.all_cdrs")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.all_cdrs,
                            callback: function ($$v) {
                              _vm.all_cdrs = $$v
                            },
                            expression: "all_cdrs",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt-2 col-span-12" }, [
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.extensions")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.extensions")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.extensions,
                            callback: function ($$v) {
                              _vm.extensions = $$v
                            },
                            expression: "extensions",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.did")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("corePbx.packages.did")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          model: {
                            value: _vm.did,
                            callback: function ($$v) {
                              _vm.did = $$v
                            },
                            expression: "did",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.unmetered
                      ? _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("corePbx.packages.call_ratings")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 m-0 text-xs leading-4 text-gray-500",
                              staticStyle: { "max-width": "480px" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("corePbx.packages.call_ratings")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.unmetered
                      ? _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              on: { change: _vm.slideChange },
                              model: {
                                value: _vm.call_ratings,
                                callback: function ($$v) {
                                  _vm.call_ratings = $$v
                                },
                                expression: "call_ratings",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt-2 col-span-12" }, [
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("corePbx.packages.update_child_services")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 m-0 text-xs leading-4 text-gray-500",
                          staticStyle: { "max-width": "480px" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("corePbx.packages.update_child_services")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          on: { change: _vm.slideChange },
                          model: {
                            value: _vm.update_child_services,
                            callback: function ($$v) {
                              _vm.update_child_services = $$v
                            },
                            expression: "update_child_services",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.avalara_options
                    ? _c("div", { staticClass: "col-span-12" }, [
                        _c("div", { staticClass: "flex mb-5 col-span-12" }),
                        _vm._v(" "),
                        _c(
                          "h6",
                          { staticClass: "sw-section-title col-span-12" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("corePbx.packages.avalara_options")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex mt-2 col-span-12 grid grid-cols-6 gap-4 items-start",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-span-6 flex grid grid-cols-6 items-start",
                              },
                              [
                                _c("div", { staticClass: "col-span-1" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.packages.avalara_bundle"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 m-0 text-xs leading-4 text-gray-500",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.packages.avalara_bundle"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-span-1 relative" },
                                  [
                                    _c("sw-switch", {
                                      staticClass: "absolute",
                                      staticStyle: { top: "-20px" },
                                      on: {
                                        change: _vm.slideChangeAvalaraBundle,
                                      },
                                      model: {
                                        value: _vm.formData.avalaraBundle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "avalaraBundle",
                                            $$v
                                          )
                                        },
                                        expression: "formData.avalaraBundle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.formData.avalaraBundle
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "md:col-span-2 pr-1",
                                        attrs: {
                                          label: _vm.$t(
                                            "corePbx.packages.avalara_transaction"
                                          ),
                                        },
                                      },
                                      [
                                        _c("sw-input", {
                                          attrs: {
                                            type: "number",
                                            "show-labels": false,
                                          },
                                          model: {
                                            value:
                                              _vm.formData.bundleTransaction,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "bundleTransaction",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.bundleTransaction",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formData.avalaraBundle
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "md:col-span-2 pl-1",
                                        attrs: {
                                          label: _vm.$t(
                                            "corePbx.packages.avalara_service"
                                          ),
                                        },
                                      },
                                      [
                                        _c("sw-input", {
                                          attrs: {
                                            type: "number",
                                            "show-labels": false,
                                          },
                                          model: {
                                            value: _vm.formData.bundleService,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "bundleService",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.bundleService",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("sw-divider", {
                                  staticClass: "col-span-12 mt-2",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("corePbx.packages.items")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("corePbx.packages.items")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value: _vm.avalara_items,
                                            callback: function ($$v) {
                                              _vm.avalara_items = $$v
                                            },
                                            expression: "avalara_items",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle && _vm.extensions
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.packages.extension"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.packages.extension"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value: _vm.avalara_extension,
                                            callback: function ($$v) {
                                              _vm.avalara_extension = $$v
                                            },
                                            expression: "avalara_extension",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_extension
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "customers.extension_items"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeLineOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.avalara_extension_item_id,
                                                callback: function ($$v) {
                                                  _vm.avalara_extension_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "avalara_extension_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle && _vm.did
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("corePbx.packages.did")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("corePbx.packages.did")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value: _vm.avalara_did,
                                            callback: function ($$v) {
                                              _vm.avalara_did = $$v
                                            },
                                            expression: "avalara_did",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_did
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "customers.did_items"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value: _vm.avalara_did_item_id,
                                                callback: function ($$v) {
                                                  _vm.avalara_did_item_id = $$v
                                                },
                                                expression:
                                                  "avalara_did_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle && _vm.call_ratings
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start gap-2",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.packages.callrating"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "corePbx.packages.callrating"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value: _vm.avalara_callrating,
                                            callback: function ($$v) {
                                              _vm.avalara_callrating = $$v
                                            },
                                            expression: "avalara_callrating",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_callrating
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "customers.cdr_items"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value: _vm.cdr_items_id,
                                                callback: function ($$v) {
                                                  _vm.cdr_items_id = $$v
                                                },
                                                expression: "cdr_items_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.avalara_callrating
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.custom_destinations"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.custom_destinations_item_id,
                                                callback: function ($$v) {
                                                  _vm.custom_destinations_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "custom_destinations_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "col-span-2 relative",
                                    }),
                                    _vm._v(" "),
                                    _vm.avalara_callrating
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.custom_destinations_inter"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.inter_destinations_item_id,
                                                callback: function ($$v) {
                                                  _vm.inter_destinations_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "inter_destinations_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.avalara_callrating
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.custom_destinations_toll"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.toll_free_destinations_item_id,
                                                callback: function ($$v) {
                                                  _vm.toll_free_destinations_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "toll_free_destinations_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle &&
                            _vm.call_ratings &&
                            _vm.formData.custom_app_rate !== null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.custom_app_rate_items"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.custom_app_rate_items"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value:
                                              _vm.avalara_custom_app_rate_items,
                                            callback: function ($$v) {
                                              _vm.avalara_custom_app_rate_items =
                                                $$v
                                            },
                                            expression:
                                              "avalara_custom_app_rate_items",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_custom_app_rate_items
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.custom_app_rate_items"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.itemsTypeTaxableAmountOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.avalara_custom_app_rate_item_id,
                                                callback: function ($$v) {
                                                  _vm.avalara_custom_app_rate_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "avalara_custom_app_rate_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.services_price_item"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.services_price_item"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value:
                                              _vm.avalara_services_price_item,
                                            callback: function ($$v) {
                                              _vm.avalara_services_price_item =
                                                $$v
                                            },
                                            expression:
                                              "avalara_services_price_item",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_services_price_item
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.services_price_item"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.avalaraItemsOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.avalara_services_price_item_id,
                                                callback: function ($$v) {
                                                  _vm.avalara_services_price_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "avalara_services_price_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.formData.avalaraBundle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-6 flex grid grid-cols-6 items-start",
                                  },
                                  [
                                    _c("div", { staticClass: "col-span-1" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.additional_charges_item"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pbx_services.additional_charges_item"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-span-1 relative" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value:
                                              _vm.avalara_additional_charges_item,
                                            callback: function ($$v) {
                                              _vm.avalara_additional_charges_item =
                                                $$v
                                            },
                                            expression:
                                              "avalara_additional_charges_item",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.avalara_additional_charges_item
                                      ? _c(
                                          "sw-input-group",
                                          {
                                            staticClass: "md:col-span-2",
                                            attrs: {
                                              label: _vm.$t(
                                                "pbx_services.additional_charges_item"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              attrs: {
                                                options:
                                                  _vm.avalaraItemsOptions,
                                                tabindex: 2,
                                                "allow-empty": false,
                                                label: "name",
                                                "track-by": "id",
                                              },
                                              model: {
                                                value:
                                                  _vm.avalara_additional_charges_item_id,
                                                callback: function ($$v) {
                                                  _vm.avalara_additional_charges_item_id =
                                                    $$v
                                                },
                                                expression:
                                                  "avalara_additional_charges_item_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.formData.avalaraBundle
                    ? _c("sw-divider", { staticClass: "my-4 col-span-12" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.automatic_suspension
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-2 ml-4",
                          attrs: {
                            label: _vm.$t("corePbx.packages.suspension_type"),
                          },
                        },
                        [
                          _c("sw-select", {
                            attrs: {
                              options: _vm.suspensionTypeOptions,
                              tabindex: 2,
                              "allow-empty": false,
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.suspension_type,
                              callback: function ($$v) {
                                _vm.suspension_type = $$v
                              },
                              expression: "suspension_type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-0 col-span-12" }, [
                    _vm.isExtensionSl
                      ? _c(
                          "div",
                          { staticClass: "ml-4" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "relative",
                                staticStyle: { "min-width": "300px" },
                                attrs: {
                                  label: _vm.$t(
                                    "corePbx.menu_title.extensions"
                                  ),
                                },
                              },
                              [
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.extension_ar,
                                    searchable: true,
                                    "show-labels": false,
                                    tabindex: 16,
                                    "allow-empty": true,
                                    disabled: _vm.bandServices,
                                    placeholder: _vm.$t(
                                      "corePbx.menu_title.extensions"
                                    ),
                                    label: "name",
                                    "track-by": "id",
                                  },
                                  model: {
                                    value: _vm.extension_i,
                                    callback: function ($$v) {
                                      _vm.extension_i = $$v
                                    },
                                    expression: "extension_i",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isDidSl
                      ? _c(
                          "div",
                          { staticClass: "ml-4" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "relative",
                                staticStyle: { "min-width": "300px" },
                                attrs: {
                                  label: _vm.$t("corePbx.menu_title.did"),
                                },
                              },
                              [
                                _c("sw-select", {
                                  ref: "baseSelect",
                                  staticClass: "mt-1",
                                  attrs: {
                                    options: _vm.did_ar,
                                    searchable: true,
                                    "show-labels": false,
                                    disabled: _vm.bandServices,
                                    placeholder: _vm.$t(
                                      "corePbx.menu_title.did"
                                    ),
                                    label: "name",
                                    "track-by": "id",
                                  },
                                  model: {
                                    value: _vm.did_i,
                                    callback: function ($$v) {
                                      _vm.did_i = $$v
                                    },
                                    expression: "did_i",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2 col-span-12 mx-6" }, [
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 gap-5" },
                      [
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "corePbx.packages.international_dialing_code"
                                  ),
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    options: _vm.international_dialing_code,
                                    searchable: true,
                                    "show-labels": false,
                                    label: "name",
                                  },
                                  model: {
                                    value:
                                      _vm.international_dialing_code_selected,
                                    callback: function ($$v) {
                                      _vm.international_dialing_code_selected =
                                        $$v
                                    },
                                    expression:
                                      "international_dialing_code_selected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "corePbx.packages.national_dialing_code"
                                  ),
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    options: _vm.national_dialing_code,
                                    searchable: true,
                                    "show-labels": false,
                                    label: "name",
                                  },
                                  model: {
                                    value: _vm.national_dialing_code_selected,
                                    callback: function ($$v) {
                                      _vm.national_dialing_code_selected = $$v
                                    },
                                    expression:
                                      "national_dialing_code_selected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 gap-5" },
                      [
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("packages.inclusive_minutes"),
                                  error: _vm.InMinError,
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.inclusive_minutes.$error,
                                    focus: "",
                                    type: "number",
                                    name: "inclusive_minutes",
                                    tabindex: "1",
                                    numeric: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.inclusive_minutes.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.inclusive_minutes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "inclusive_minutes",
                                        $$v
                                      )
                                    },
                                    expression: "formData.inclusive_minutes",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "corePbx.packages.rate_per_minutes"
                                  ),
                                },
                              },
                              [
                                _c("sw-money", {
                                  attrs: {
                                    currency: _vm.customerCurrencyPerMinute,
                                    name: "rate_per_minutes_selected",
                                    tabindex: "1",
                                  },
                                  model: {
                                    value: _vm.rate_per_minutes_selected,
                                    callback: function ($$v) {
                                      _vm.rate_per_minutes_selected = $$v
                                    },
                                    expression: "rate_per_minutes_selected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 gap-5" },
                      [
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t(
                                    "corePbx.packages.minutes_increments"
                                  ),
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    options: _vm.minutes_increments,
                                    invalid:
                                      _vm.$v.minutes_increments_selected.$error,
                                    searchable: true,
                                    "show-labels": false,
                                    label: "name",
                                    numeric: "",
                                  },
                                  model: {
                                    value: _vm.minutes_increments_selected,
                                    callback: function ($$v) {
                                      _vm.minutes_increments_selected = $$v
                                    },
                                    expression: "minutes_increments_selected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddCallRating
                          ? _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("corePbx.packages.select"),
                                },
                              },
                              [
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.type_time_increment,
                                    searchable: true,
                                    "show-labels": false,
                                    tabindex: 16,
                                    "allow-empty": true,
                                    placeholder: _vm.$t(
                                      "corePbx.extensions.select"
                                    ),
                                    label: "text",
                                    "track-by": "value",
                                  },
                                  model: {
                                    value: _vm.formData.type_time_increment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "type_time_increment",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formData.type_time_increment",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-0 col-span-12 opacity-0" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-1 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-12" }, [
                    _c("h3", { staticClass: "text-lg font-medium" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("packages.general_taxes")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      staticClass: "col-span-12",
                      attrs: {
                        "show-filter": false,
                        data: _vm.paralelo,
                        "table-class": "table",
                        variant: "gray",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("settings.tax_types.tax_name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.tax_name")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "mt-6" }, [
                                  _vm._v(_vm._s(row.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.compound_tax"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.compound_tax")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-badge",
                                  {
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          row.compound_tax ? "YES" : "NO"
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        row.compound_tax ? "YES" : "NO"
                                      ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          row.compound_tax
                                            ? "Yes"
                                            : "No".replace("_", " ")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.percent"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.tax_types.percent"))
                                  ),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.percent) +
                                    " %\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: { sortable: true, filterable: true },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeTax(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-12" }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.taxes") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.taxes,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.add_tax"),
                          label: "name_por",
                          "track-by": "id",
                          tabindex: 9,
                        },
                        on: { select: _vm.taxSeleted },
                        model: {
                          value: _vm.tax,
                          callback: function ($$v) {
                            _vm.tax = $$v
                          },
                          expression: "tax",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.member_tax_groups") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          disabled: _vm.isNoGeneralTaxes,
                          options: _vm.groupTaxes,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.add_group_tax"),
                          multiple: true,
                          label: "name",
                          "track-by": "id",
                          tabindex: 10,
                        },
                        on: {
                          select: _vm.groupTaxSeleted,
                          remove: _vm.removeTaxGroup,
                        },
                        model: {
                          value: _vm.groupTax,
                          callback: function ($$v) {
                            _vm.groupTax = $$v
                          },
                          expression: "groupTax",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-1 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-12" }, [
                    _c("h3", { staticClass: "text-lg font-medium" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("corePbx.packages.cdr_taxes")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      staticClass: "col-span-12",
                      attrs: {
                        "show-filter": false,
                        data: _vm.paraleloTaxesCdr,
                        "table-class": "table",
                        variant: "gray",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("settings.tax_types.tax_name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.tax_name")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "mt-6" }, [
                                  _vm._v(_vm._s(row.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.compound_tax"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.compound_tax")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-badge",
                                  {
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          row.compound_tax ? "YES" : "NO"
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        row.compound_tax ? "YES" : "NO"
                                      ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          row.compound_tax
                                            ? "Yes"
                                            : "No".replace("_", " ")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.percent"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.tax_types.percent"))
                                  ),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.percent) +
                                    " %\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: { sortable: true, filterable: true },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeTaxCdr(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-12" }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.taxes") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.taxesCdr,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.add_tax"),
                          label: "name_por",
                          "track-by": "id",
                          tabindex: 9,
                        },
                        on: { select: _vm.taxCdrSeleted },
                        model: {
                          value: _vm.taxCdr,
                          callback: function ($$v) {
                            _vm.taxCdr = $$v
                          },
                          expression: "taxCdr",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.title_discount")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-3" },
                    [
                      _c("sw-divider", { staticClass: "col-span-12" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex mt-3 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              on: { change: _vm.onDiscounts },
                              model: {
                                value: _vm.formData.package_discount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "package_discount",
                                    $$v
                                  )
                                },
                                expression: "formData.package_discount",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("packages.apply_general_discount")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-3" }, [
                    _c("div", { staticClass: "flex mt-3 mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.title_discounts")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-select", {
                            attrs: {
                              disabled: _vm.showSelectdiscounts,
                              options: _vm.discounts,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": true,
                              placeholder: _vm.$t("packages.title_discounts"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "type", $$v)
                              },
                              expression: "formData.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em", "margin-left": "30px" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.value_discount")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-money", {
                            attrs: {
                              currency: _vm.customPrefixDescount,
                              invalid: _vm.$v.value_discount.$error,
                              name: "value_discount",
                              tabindex: "5",
                            },
                            on: { input: _vm.checkValueDiscount },
                            model: {
                              value: _vm.value_discount,
                              callback: function ($$v) {
                                _vm.value_discount = $$v
                              },
                              expression: "value_discount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.formData.package_discount
              ? _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.discount_term_type"),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.discount_term_type,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "customers.select_a_discount_term"
                            ),
                            label: "name",
                            tabindex: 9,
                          },
                          model: {
                            value: _vm.formData.discount_term_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount_term_type", $$v)
                            },
                            expression: "formData.discount_term_type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.discountBetweenDates
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "relative grid grid-flow-col grid-rows",
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: _vm.$t("general.from") },
                              },
                              [
                                _c("base-date-picker", {
                                  attrs: {
                                    "calendar-button": true,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_start_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_start_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_start_date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "hidden w-8 h-0 ml-8 border border-gray-400 border-solid xl:block",
                              staticStyle: { "margin-top": "3.5rem" },
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: _vm.$t("general.to") },
                              },
                              [
                                _c("base-date-picker", {
                                  attrs: {
                                    "calendar-button": true,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_end_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_end_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_end_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.discountBetweenDates
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              label: _vm.$t("customers.time_unit_number"),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { width: "50%" },
                                attrs: { role: "group" },
                              },
                              [
                                _c("sw-input", {
                                  staticClass:
                                    "border-r-0 rounded-tr-sm rounded-br-sm",
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.discount_time_units
                                        .$error,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.discount_time_units.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.discount_time_units,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_time_units",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_time_units",
                                  },
                                }),
                                _vm._v(" "),
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.discount_term,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "customers.select_a_term"
                                    ),
                                    label: "name",
                                  },
                                  model: {
                                    value: _vm.formData.discount_term,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_term",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_term",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-5 gap-4 mb-8" },
              [
                _c("sw-divider", { staticClass: "col-span-12" }),
                _vm._v(" "),
                _c(
                  "h6",
                  { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("packages.packages_items")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: { label: _vm.$t("packages.item_groups") },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.item_groups,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$tc("packages.item_groups_select"),
                            label: "name",
                            "track-by": "id",
                            tabindex: 11,
                          },
                          on: { select: _vm.itemGroupSelected },
                          model: {
                            value: _vm.item_group,
                            callback: function ($$v) {
                              _vm.item_group = $$v
                            },
                            expression: "item_group",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    undefined !== _vm.formData.item_groups &&
                    _vm.formData.item_groups.length > 0
                      ? _c("div", { staticClass: "col-span-12" }, [
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap justify-start" },
                            _vm._l(
                              _vm.formData.item_groups,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-indigo-100 bg-indigo-700 border border-indigo-700",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "text-xs text-base leading-none max-w-full flex-initial py-2 pl-2",
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-auto flex-row-reverse",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-6 h-4 ml-2 pr-1",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "100%",
                                                height: "100%",
                                                fill: "none",
                                                viewBox: "0 0 24 24",
                                                stroke: "currentColor",
                                                "stroke-width": "4",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeItemGroup(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  x1: "18",
                                                  y1: "6",
                                                  x2: "6",
                                                  y2: "18",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "6",
                                                  y1: "6",
                                                  x2: "18",
                                                  y2: "18",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid col-span-12" }, [
                      _c("div", { staticClass: "table-responsive-item2" }, [
                        _c("div", { staticClass: "tablemin" }, [
                          _c(
                            "table",
                            { staticClass: "w-full text-center item-table" },
                            [
                              _c("colgroup", [
                                _c("col", { staticStyle: { width: "40%" } }),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "10%" } }),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "15%" } }),
                                _vm._v(" "),
                                _vm.discountPerItem === "YES"
                                  ? _c("col", { staticStyle: { width: "15%" } })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "15%" } }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "thead",
                                {
                                  staticClass:
                                    "bg-white border border-gray-200 border-solid",
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", { staticClass: "pl-12" }, [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(_vm.$tc("items.item", 1)) +
                                              "\n                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.item.quantity"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t("estimates.item.price")
                                              ) +
                                              "\n                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.discountPerItem === "YES"
                                      ? _c(
                                          "th",
                                          {
                                            staticClass:
                                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "estimates.item.discount"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pr-10 column-heading",
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.item.amount"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  staticClass: "item-body",
                                  attrs: { tag: "tbody", handle: ".handle" },
                                  model: {
                                    value: _vm.formData.items,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "items", $$v)
                                    },
                                    expression: "formData.items",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.items,
                                  function (item, index) {
                                    return _c("package-item", {
                                      key: item.id,
                                      attrs: {
                                        index: index,
                                        "item-data": item,
                                        currency: _vm.currency,
                                        isView: false,
                                        isNoGeneralTaxes: _vm.isNoGeneralTaxes,
                                        "package-items": _vm.formData.items,
                                        "tax-per-item": _vm.taxPerItem,
                                        "discount-per-item":
                                          _vm.discountPerItem,
                                      },
                                      on: {
                                        remove: _vm.removeItem,
                                        update: _vm.updateItem,
                                        itemValidate: _vm.checkItemsData,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                              on: { click: _vm.addItem },
                            },
                            [
                              _c("shopping-cart-icon", {
                                staticClass: "h-5 mr-2",
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("estimates.add_item")) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      type: "submit",
                      variant: "primary",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", {
                          staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("packages.update_package")
                            : _vm.$t("packages.save_package")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      variant: "primary-outline",
                      type: "button",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }