var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-card",
          { staticClass: "customer-card" },
          [
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 col-span-12 gap-3 mt-8 lg:gap-3 lg:mt-0 lg:grid-cols-2",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        label: _vm.$t(
                          "core_pos.open_close_cash_modal.reference"
                        ),
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: { type: "text", disabled: "" },
                        model: {
                          value: _vm.formData.ref,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ref", $$v)
                          },
                          expression: "formData.ref",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        label: _vm.$t(
                          "core_pos.open_close_cash_modal.open_date"
                        ),
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: { type: "text", disabled: "" },
                        model: {
                          value: _vm.formData.open_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "open_date", $$v)
                          },
                          expression: "formData.open_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            !_vm.isConfirmed
              ? _c(
                  "div",
                  [
                    _vm.permissionModule.access
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mb-4",
                            attrs: { label: _vm.$t("core_pos.users") },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.users_options,
                                searchable: true,
                                "show-labels": false,
                                "track-by": "id",
                                label: "name",
                                placeholder: "Select an User",
                                multiple: true,
                                tabindex: 10,
                                disabled: _vm.disabledOpen,
                              },
                              model: {
                                value: _vm.formData.users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "users", $$v)
                                },
                                expression: "formData.users",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isOpenCashRegister
              ? _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 col-span-12 gap-3 mt-8 lg:gap-3 lg:mt-0 lg:grid-cols-2",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t(
                              "core_pos.open_close_cash_modal.initial_amount"
                            ),
                          },
                        },
                        [
                          _c("sw-money", {
                            staticClass:
                              "focus:border focus:border-solid focus:border-primary",
                            attrs: {
                              currency: _vm.defaultCurrencyForInput,
                              disabled: "",
                              value: _vm.calculateInitialAmount,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isConfirmed
                        ? _c(
                            "div",
                            [
                              _vm.isOpenCashRegister
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        label: _vm.$t(
                                          "core_pos.open_close_cash_modal.close_date"
                                        ),
                                      },
                                    },
                                    [
                                      _c("sw-input", {
                                        attrs: { type: "text", disabled: "" },
                                        model: {
                                          value: _vm.formData.close_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "close_date",
                                              $$v
                                            )
                                          },
                                          expression: "formData.close_date",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isOpenCashRegister
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t(
                        "core_pos.open_close_cash_modal.last_amount"
                      ),
                    },
                  },
                  [
                    _c("sw-money", {
                      staticClass:
                        "focus:border focus:border-solid focus:border-primary",
                      attrs: { currency: _vm.defaultCurrencyForInput },
                      model: {
                        value: _vm.last_amount,
                        callback: function ($$v) {
                          _vm.last_amount = _vm._n($$v)
                        },
                        expression: "last_amount",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs text-red-600" }, [
                      _vm._v(_vm._s(_vm.$t("core_pos.last_amount_message"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isOpenCashRegister
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t(
                        "core_pos.open_close_cash_modal.cash_received"
                      ),
                    },
                  },
                  [
                    _c("sw-money", {
                      staticClass:
                        "focus:border focus:border-solid focus:border-primary",
                      attrs: {
                        currency: _vm.defaultCurrencyForInput,
                        disabled: _vm.disabledOpen,
                      },
                      model: {
                        value: _vm.formData.cash_received,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "cash_received", _vm._n($$v))
                        },
                        expression: "formData.cash_received",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs text-red-600" }, [
                      _vm._v(_vm._s(_vm.$t("core_pos.cash_received_message"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isOpenCashRegister
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t(
                        "core_pos.open_close_cash_modal.initial_amount"
                      ),
                    },
                  },
                  [
                    _c("sw-money", {
                      staticClass:
                        "focus:border focus:border-solid focus:border-primary",
                      attrs: {
                        currency: _vm.defaultCurrencyForInput,
                        disabled: "",
                        value: _vm.calculateInitialAmount,
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs text-red-600" }, [
                      _vm._v(_vm._s(_vm.$t("core_pos.initial_amount_message"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isOpenCashRegister
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t("core_pos.open_close_cash_modal.open_note"),
                    },
                  },
                  [
                    _c("sw-textarea", {
                      attrs: {
                        rows: "4",
                        cols: "50",
                        disabled: _vm.disabledOpen,
                      },
                      model: {
                        value: _vm.formData.open_note,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "open_note", $$v)
                        },
                        expression: "formData.open_note",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            !_vm.isConfirmed
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 col-span-12 gap-3 mt-8 lg:gap-3 lg:mt-0 lg:grid-cols-2",
                        },
                        [
                          _vm.isOpenCashRegister
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-4",
                                  attrs: {
                                    label: _vm.$t("core_pos.cash_received"),
                                  },
                                },
                                [
                                  _c("sw-money", {
                                    staticClass:
                                      "focus:border focus:border-solid focus:border-primary",
                                    attrs: {
                                      currency: _vm.defaultCurrencyForInput,
                                      disabled: _vm.disabledOpen,
                                    },
                                    model: {
                                      value: _vm.received,
                                      callback: function ($$v) {
                                        _vm.received = _vm._n($$v)
                                      },
                                      expression: "received",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isOpenCashRegister
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-4",
                                  attrs: {
                                    label: _vm.$t("core_pos.cash_returned"),
                                  },
                                },
                                [
                                  _c("sw-money", {
                                    staticClass:
                                      "focus:border focus:border-solid focus:border-primary",
                                    attrs: {
                                      currency: _vm.defaultCurrencyForInput,
                                      disabled: _vm.disabledOpen,
                                    },
                                    model: {
                                      value: _vm.returned,
                                      callback: function ($$v) {
                                        _vm.returned = _vm._n($$v)
                                      },
                                      expression: "returned",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isOpenCashRegister
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-4",
                                  attrs: { label: _vm.$t("core_pos.income") },
                                },
                                [
                                  _c("sw-money", {
                                    staticClass:
                                      "focus:border focus:border-solid focus:border-primary",
                                    attrs: {
                                      currency: _vm.defaultCurrencyForInput,
                                      disabled: _vm.disabledOpen,
                                    },
                                    model: {
                                      value: _vm.income,
                                      callback: function ($$v) {
                                        _vm.income = _vm._n($$v)
                                      },
                                      expression: "income",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isOpenCashRegister
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-4",
                                  attrs: {
                                    label: _vm.$t("core_pos.withdrawal"),
                                  },
                                },
                                [
                                  _c("sw-money", {
                                    staticClass:
                                      "focus:border focus:border-solid focus:border-primary",
                                    attrs: {
                                      currency: _vm.defaultCurrencyForInput,
                                      disabled: _vm.disabledOpen,
                                    },
                                    model: {
                                      value: _vm.withdrawal,
                                      callback: function ($$v) {
                                        _vm.withdrawal = _vm._n($$v)
                                      },
                                      expression: "withdrawal",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.isOpenCashRegister
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4 w-1/2",
                            attrs: {
                              label: _vm.$t(
                                "core_pos.open_close_cash_modal.final_amount"
                              ),
                            },
                          },
                          [
                            _c("sw-money", {
                              staticClass:
                                "focus:border focus:border-solid focus:border-primary",
                              attrs: {
                                currency: _vm.defaultCurrencyForInput,
                                value: _vm.calculateFinalAmount,
                                disabled: _vm.isConfirmed,
                              },
                              model: {
                                value: _vm.formData.final_amount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "final_amount", $$v)
                                },
                                expression: "formData.final_amount",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isOpenCashRegister
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              label: _vm.$t(
                                "core_pos.open_close_cash_modal.close_note"
                              ),
                            },
                          },
                          [
                            _c("sw-textarea", {
                              attrs: { rows: "4", cols: "50" },
                              model: {
                                value: _vm.formData.close_note,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "close_note", $$v)
                                },
                                expression: "formData.close_note",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isConfirmed
              ? _c("div", { staticClass: "my-2" }, [
                  _c("span", { staticClass: "text-xs text-red-700" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "core_pos.open_close_cash_modal.message_confirm_open_cash_register"
                          )
                        )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-5 flex" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      icon: "save",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isConfirmed
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.confirm")) +
                              "\n          "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.save")) +
                              "\n          "
                          ),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      type: "button",
                      loading: _vm.isLoading,
                      variant: "primary-outline",
                    },
                    on: { click: _vm.closeModalCashRegister },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }