var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.$t("packages.title") } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$tc("corePbx.menu_title.packages", 2),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalPackages,
                          expression: "totalPackages",
                        },
                      ],
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                          attrs: {
                            "tag-name": "router-link",
                            to: "packages/create",
                            variant: "primary",
                            size: "lg",
                          },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("packages.add_new_package")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("packages.filter.package_id") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            type: "text",
                            name: "packages_number",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.filters.packages_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "packages_number", $$v)
                            },
                            expression: "filters.packages_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("packages.filter.name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "pbx_package_name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.pbx_package_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "pbx_package_name", $$v)
                          },
                          expression: "filters.pbx_package_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("packages.item.type_service") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.categories,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "expenses.categories.select_a_category"
                          ),
                          label: "name",
                        },
                        on: {
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.status_payment,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status_payment", $$v)
                          },
                          expression: "filters.status_payment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("packages.no_packages"),
                description: _vm.$t("packages.list_of_packages"),
              },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "packages/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("packages.add_new_package")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.packages.length))]),
                    _vm._v(
                      "\n\n        " +
                        _vm._s(_vm.$t("general.of")) +
                        "\n\n        "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.totalPackages))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-transition",
                    { attrs: { type: "fade" } },
                    [
                      _vm.selectedPackages.length
                        ? _c(
                            "sw-dropdown",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n            "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.removeMultiplePackages } },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  staticClass: "-mt-14 md:mt-0",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("packages.item.package_id"),
                      show: "packages_number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("packages.item.package_id"))
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.permissionModule.read
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to: { path: `packages/${row.id}/view` },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.packages_number) +
                                          "\n            "
                                      ),
                                      row.server
                                        ? _c("div", [
                                            row.server.status == "A"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-success",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "settings.customization.modules.server_online"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "settings.customization.modules.server_offline"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(row.packages_number) +
                                        "\n            "
                                    ),
                                    row.server
                                      ? _c("div", [
                                          row.server.status == "A"
                                            ? _c(
                                                "span",
                                                { staticClass: "text-success" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "settings.customization.modules.server_online"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "settings.customization.modules.server_offline"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      105309969
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("packages.item.name"),
                      show: "pbx_package_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("packages.item.type_service"),
                      show: "status_payment",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              row.status_payment == "postpaid"
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("packages.item.postpaid")
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.status_payment == "prepaid"
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("packages.item.prepaid")
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3485339536
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("packages.item.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              row.status == "A"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.active")) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "I"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("general.inactive")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "R"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "VIEWED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("general.restrictive")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1803775902
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("packages.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _vm.permissionModule.read
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `/admin/corePBX/packages/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _c("eye-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.update
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `packages/${row.id}/edit`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.create
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `packages/${row.id}/copy`,
                                          },
                                        },
                                        [
                                          _c("plus-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("packages.copy")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.delete
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removePackage(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("trash-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3431272038
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }