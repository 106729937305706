var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "payments" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c("sw-page-header", {
            attrs: { title: _vm.$t("failed_payment_history.title") },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/payments`,
                    variant: "primary-outline",
                    size: "lg",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.go_to_payments")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.isCustomer
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.customerId}/view`,
                        variant: "primary-outline",
                        size: "lg",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.go_to_customer")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total_items,
                      expression: "total_items",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n          "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 lg:mr-6",
                      attrs: {
                        label: _vm.$t("failed_payment_history.payment_gateway"),
                        color: "black-light",
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.payment_gateways,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "failed_payment_history.payment_gateway"
                          ),
                          label: "name",
                        },
                        model: {
                          value: _vm.filters.payment_gateways,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "payment_gateways", $$v)
                          },
                          expression: "filters.payment_gateways",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2",
                      attrs: {
                        label: _vm.$t("failed_payment_history.payment_number"),
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.payment_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "payment_number", $$v)
                            },
                            expression: "filters.payment_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-3",
                      attrs: {
                        label: _vm.$t("failed_payment_history.invoice"),
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.invoice_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "invoice_number", $$v)
                            },
                            expression: "filters.invoice_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isCustomer
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "flex-1 mt-2 ml-3",
                          attrs: {
                            label: _vm.$t("failed_payment_history.customer"),
                          },
                        },
                        [
                          _c("sw-input", {
                            model: {
                              value: _vm.filters.customer,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "customer", $$v)
                              },
                              expression: "filters.customer",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 lg:ml-6",
                      attrs: { label: _vm.$t("general.from") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 lg:ml-6",
                      attrs: { label: _vm.$t("general.to") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "relative table-container" }, [
        _c(
          "div",
          {
            staticClass:
              "relative flex items-center justify-between h-10 mt-5 list-none",
          },
          [
            _c("p", { staticClass: "text-sm" }, [
              _vm._v("\n          " + _vm._s(_vm.$t("general.showing")) + ": "),
              _c("b", [_vm._v(_vm._s(_vm.items_per_page))]),
              _vm._v("\n          " + _vm._s(_vm.$t("general.of")) + " "),
              _c("b", [_vm._v(_vm._s(_vm.total_items))]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          staticClass: "flex",
          attrs: {
            data: _vm.fetchData,
            headerClass: "bg-danger",
            tableClass: "w-full",
            tbodyClass: "border-b-2 border-gray-200 border-solid",
            "show-filter": false,
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.date"),
              "sort-as": "date",
              show: "date",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.transaction_number"),
              "sort-as": "transaction_number",
              show: "transaction_number",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("failed_payment_history.transaction_number")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            row.transaction_number
                              ? row.transaction_number
                              : "Transaction number not generated by payment gateway"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.customer"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    row.customer_id
                      ? _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: { path: `customers/${row.customer_id}/view` },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.name) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            "\n            " + _vm._s(row.name) + "\n          "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.payment_gateway"),
              show: "payment_gateway",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.amount"),
              "sort-as": "amount",
              show: "amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("failed_payment_history.amount"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(row.amount, row)
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.payment_number"),
              show: "payment_number",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("failed_payment_history.transaction_number")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            row.payment_number
                              ? row.payment_number
                              : `For customer's credit`
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              "sort-as": "invoice_number",
              label: _vm.$t("failed_payment_history.invoice"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    row.invoice == null
                      ? _c("div", [_c("span", [_vm._v("N/A")])])
                      : _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: {
                                    path: `invoices/${row.invoice.id}/view`,
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.invoice.invoice_number) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("failed_payment_history.description"),
              show: "description",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("failed_payment_history.description"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            row.description
                              ? row.description
                              : "Connection failure with Payment Gateway"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }