var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overflow-auto", staticStyle: { height: "85vh" } },
    [
      _c("div", [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.sendInvoiceData.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "px-8 py-8 sm:p-6" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("general.from"),
                      error: _vm.fromError,
                      variant: "vertical",
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.from.$error,
                        type: "text",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.from.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "from", $$v)
                        },
                        expression: "formData.from",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("general.to"),
                      error: _vm.toError,
                      variant: "vertical",
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.to.$error,
                        type: "text",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.to.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.to,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "to", $$v)
                        },
                        expression: "formData.to",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2",
                    attrs: { label: _vm.$t("general.subject") },
                  },
                  [
                    _c("base-custom-input", {
                      attrs: { fields: _vm.InvoiceMailFields },
                      model: {
                        value: _vm.formData.subject,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "subject", $$v)
                        },
                        expression: "formData.subject",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("general.body"),
                      error: _vm.bodyError,
                      variant: "vertical",
                      required: "",
                    },
                  },
                  [
                    _c("base-custom-input", {
                      staticClass: "mt-2",
                      attrs: {
                        fields: _vm.InvoiceMailFields,
                        invalid: _vm.$v.formData.body.$error,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.body.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.body,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "body", $$v)
                        },
                        expression: "formData.body",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
              },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3",
                    attrs: { variant: "primary-outline", type: "button" },
                    on: { click: _vm.closeSendInvoiceModal },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("paper-airplane-icon", { staticClass: "h-5 mr-2" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.send")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }