var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-did" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("didFree.item.prefijo"),
                  error: _vm.prefixError,
                  variant: "horizontal",
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  attrs: {
                    placeholder: _vm.$t("didFree.item.prefijo"),
                    invalid: _vm.$v.formData.prefijo.$error,
                    type: "text",
                    name: "prefijo",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.prefijo.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.prefijo,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "prefijo", $$v)
                    },
                    expression: "formData.prefijo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.didFree.status"),
                  variant: "horizontal",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.statuses,
                    searchable: true,
                    "show-labels": false,
                    label: "name",
                  },
                  model: {
                    value: _vm.formData.statu,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "statu", $$v)
                    },
                    expression: "formData.statu",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.didFree.category"),
                  variant: "horizontal",
                  error: _vm.categoryError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    invalid: _vm.$v.formData.category.$error,
                    options: _vm.categoriesTollFree,
                    searchable: true,
                    "show-labels": false,
                    "max-height": 200,
                    placeholder: _vm.$t("corePbx.didFree.select_a_category"),
                    label: "name",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.category.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "category", $$v)
                    },
                    expression: "formData.category",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("corePbx.custom_did_groups.price"),
                  variant: "horizontal",
                  error: _vm.rateError,
                  required: "",
                },
              },
              [
                _c("sw-money", {
                  staticClass:
                    "relative w-full focus:border focus:border-solid focus:border-primary",
                  attrs: {
                    currency: _vm.defaultCurrencyForInput,
                    invalid: _vm.$v.formData.rate_per_minutes.$error,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.rate_per_minutes.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.rate_per_minutes,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "rate_per_minutes", $$v)
                    },
                    expression: "formData.rate_per_minutes",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeCustomModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }