var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitAvalaraCategoryExemption.apply(null, arguments)
        },
      },
    },
    [
      _vm.isLoading ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "p-6 grid md:grid-cols-2 gap-4 mb-1" }, [
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.country")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.dataModal.country ? _vm.dataModal.country.name : ""
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.state")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.dataModal.state ? _vm.dataModal.state.name : "") +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.city")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.dataModal.city || "") + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("avalara.pcode")) + "\n      "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.dataModal.pcode || "") + "\n      "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sm:p-6 grid md:grid-cols-2 gap-4 mb-1" },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: { label: _vm.$tc("avalara.exemption_type"), required: "" },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.exemptionTypeOptions,
                  "show-labels": false,
                  "allow-empty": false,
                  placeholder: _vm.$t("avalara.exemption_type"),
                  label: "text",
                  "track-by": "value",
                },
                model: {
                  value: _vm.exemptionTypeSelect,
                  callback: function ($$v) {
                    _vm.exemptionTypeSelect = $$v
                  },
                  expression: "exemptionTypeSelect",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider"),
      _vm._v(" "),
      _vm.exemptionTypeSelect.value == 1
        ? _c(
            "div",
            { staticClass: "sm:p-6 grid md:grid-cols-2 gap-4 mb-1" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$tc("avalara.category_exemptions"),
                    required: "",
                    error: _vm.categoryExceptionError,
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.categoryExemptionsOptions,
                      "show-labels": false,
                      "allow-empty": true,
                      placeholder: _vm.$t("avalara.category_exemptions"),
                      label: "name",
                      "track-by": "id",
                      invalid: _vm.$v.formData.categoryException.$error,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.categoryException.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.categoryException,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "categoryException", $$v)
                      },
                      expression: "formData.categoryException",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.exemptionTypeSelect.value == 2
        ? _c(
            "div",
            { staticClass: "sm:p-6 grid md:grid-cols-2 gap-4 mb-1" },
            [
              _c("h6", { staticClass: "sw-section-title md:col-span-2" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("avalara.tax_type")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$tc("avalara.category"),
                    required: "",
                    error: _vm.categoryDefinitionsError,
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.categoryDefinitionsOptions,
                      "show-labels": false,
                      "allow-empty": true,
                      placeholder: _vm.$t("avalara.category"),
                      label: "name",
                      "track-by": "id",
                      invalid: _vm.$v.formData.categoryDefinitions.$error,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.selectCategoryDefinition()
                      },
                      input: function ($event) {
                        return _vm.$v.formData.categoryDefinitions.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.categoryDefinitions,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "categoryDefinitions", $$v)
                      },
                      expression: "formData.categoryDefinitions",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$tc("avalara.tax_type"),
                    required: "",
                    error: _vm.taxTypeError,
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.taxTypeOptionsFilter,
                      "show-labels": false,
                      "allow-empty": true,
                      placeholder: _vm.$t("avalara.tax_type"),
                      label: "TaxDescription",
                      "track-by": "TaxType",
                      invalid: _vm.$v.formData.taxType.$error,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.taxType.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.taxType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "taxType", $$v)
                      },
                      expression: "formData.taxType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("sw-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sm:p-6 grid md:grid-cols-3 gap-4 mb-1" },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$tc("avalara.force"),
                required: "",
                error: _vm.frcError,
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.frcOptions,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("avalara.force"),
                  label: "text",
                  "track-by": "value",
                  invalid: _vm.$v.formData.frc.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.frc.$touch()
                  },
                },
                model: {
                  value: _vm.formData.frc,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "frc", $$v)
                  },
                  expression: "formData.frc",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$tc("avalara.exemption_domain"),
                required: "",
                error: _vm.domError,
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.domOptions,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("avalara.exemption_domain"),
                  label: "text",
                  "track-by": "value",
                  invalid: _vm.$v.formData.dom.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.dom.$touch()
                  },
                },
                model: {
                  value: _vm.formData.dom,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "dom", $$v)
                  },
                  expression: "formData.dom",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$tc("avalara.exemption_scope"),
                required: "",
                error: _vm.scpError,
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.scpOptions,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("avalara.exemption_scope"),
                  label: "text",
                  "track-by": "value",
                  invalid: _vm.$v.formData.scp.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.scp.$touch()
                  },
                },
                model: {
                  value: _vm.formData.scp,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "scp", $$v)
                  },
                  expression: "formData.scp",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$tc("avalara.exempt_non-billable"),
                required: "",
                error: _vm.exnbError,
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.exnbOptions,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("avalara.exempt_non-billable"),
                  label: "text",
                  "track-by": "value",
                  invalid: _vm.$v.formData.exnb.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.exnb.$touch()
                  },
                },
                model: {
                  value: _vm.formData.exnb,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "exnb", $$v)
                  },
                  expression: "formData.exnb",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3 text-sm",
              attrs: { type: "button", variant: "primary-outline" },
              on: { click: _vm.closeAvalaraLocationsModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                variant: "primary",
                type: "submit",
                loading: _vm.isLoading,
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.$t("general.save")) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }