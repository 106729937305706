export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'

export const BOOTSTRAP_CURRENT_USER = 'BOOTSTRAP_CURRENT_USER'

export const BOOTSTRAP_CUSTOMERS_MESSAGING = 'BOOTSTRAP_CUSTOMERS_MESSAGING'

export const SET_TOTAL_CUSTOMERS_MESSAGING = 'SET_TOTAL_CUSTOMERS_MESSAGING'

export const SET_SELECTED_CUSTOMERS_MESSAGING = 'SET_SELECTED_CUSTOMERS_MESSAGING'

export const SET_SELECT_ALL_STATE_MESSAGING = 'SET_SELECT_ALL_STATE_MESSAGING'

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR'

export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE'

export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO'
