var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("core_pos.money") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("core_pos.money", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        size: "lg",
                        type: "button",
                        variant: "primary-outline",
                      },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "h-4 ml-1 -mr-1 font-bold",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-2",
                      attrs: { size: "lg", variant: "primary" },
                      on: { click: _vm.addMoney },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("core_pos.add_money")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("core_pos.name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", autocomplete: "off" },
                    model: {
                      value: _vm.filters.money_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "money_name", $$v)
                      },
                      expression: "filters.money_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("core_pos.is_coin") },
                },
                [
                  _c("sw-select", {
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      options: _vm.is_coin_options,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("core_pos.is_coin"),
                      "allow-empty": false,
                      "track-by": "value",
                      label: "text",
                    },
                    model: {
                      value: _vm.filters.is_coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "is_coin", $$v)
                      },
                      expression: "filters.is_coin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("core_pos.currency") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.currencies_option,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "track-by": "id",
                      label: "name",
                      tabindex: 11,
                    },
                    model: {
                      value: _vm.filters.currency_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "currency_name", $$v)
                      },
                      expression: "filters.currency_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("core_pos.name"),
                  "sort-as": "name",
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("core_pos.amount"),
                  "sort-as": "amount",
                  show: "amount",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("core_pos.is_coin"),
                  "sort-as": "is_coin",
                  show: "is_coin",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        row.is_coin
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("core_pos.is_coin_yes")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("core_pos.is_coin_no")) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("core_pos.currency"),
                  "sort-as": "currency_id",
                  show: "currency_id",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getCurrency(
                                    row.currency_id ? row.currency_id : 0
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, filterable: false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateMoney(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteMoney(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n                Delete\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }