var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "div",
        { staticClass: "items" },
        [
          _vm.isRequestOnGoing
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "sw-card",
            [
              _c(
                "sw-tabs",
                { staticClass: "p-2" },
                [
                  _c(
                    "sw-tab-item",
                    { attrs: { title: _vm.$t("corePbx.menu_title.packages") } },
                    [
                      _c("packages-tab", {
                        attrs: {
                          settings: _vm.settings,
                          permission: _vm.permissionModule,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-tab-item",
                    {
                      attrs: { title: _vm.$t("corePbx.menu_title.extensions") },
                    },
                    [
                      _c("extensions-tab", {
                        attrs: {
                          settings: _vm.settings,
                          permission: _vm.permissionModule,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-tab-item",
                    { attrs: { title: _vm.$t("corePbx.menu_title.did") } },
                    [
                      _c("did-tab", {
                        attrs: {
                          settings: _vm.settings,
                          permission: _vm.permissionModule,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-tab-item",
                    { attrs: { title: _vm.$t("corePbx.menu_title.services") } },
                    [
                      _c("services-tab", {
                        attrs: {
                          settings: _vm.settings,
                          permission: _vm.permissionModule,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }