var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80",
    },
    [
      !_vm.imageData
        ? _c(
            "div",
            [
              _c(
                "sw-button",
                {
                  staticClass: "absolute top-0 right-0 m-4",
                  attrs: { variant: "primary", type: "button" },
                  on: { click: _vm.switchCamera },
                },
                [_c("SwitchHorizontalIcon", { staticClass: "w-6 h-6" })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "camera-container" }, [
                _c("video", {
                  ref: "video",
                  staticClass: "video-element",
                  attrs: { autoplay: "", playsinline: "" },
                  on: { loadedmetadata: _vm.onVideoMetadataLoaded },
                }),
                _vm._v(" "),
                _c("canvas", { ref: "canvas", staticClass: "hidden" }),
                _vm._v(" "),
                _vm.typePhoto === "selfie"
                  ? _c("div", { staticClass: "overlayTakePhotoFace" }, [
                      _c("div", {
                        staticClass: "circleFace",
                        class: { "no-face": !_vm.faceDetected },
                      }),
                      _vm._v(" "),
                      !_vm.faceDetected
                        ? _c("div", { staticClass: "message" }, [
                            _vm._v("No Face Detected"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.typePhoto === "ic_front" || _vm.typePhoto === "ic_back"
                  ? _c("div", { staticClass: "overlayTakePhotoRect" }, [
                      _c("div", { staticClass: "messageDocument" }, [
                        _vm._v("Center document on the screen"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "camera-buttons" },
                  [
                    _c(
                      "sw-button",
                      {
                        attrs: { variant: "primary", type: "button" },
                        on: { click: _vm.closeTakeModal },
                      },
                      [
                        _c("XIcon", { staticClass: "w-6 h-6 mr-2" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "mx-4",
                        attrs: {
                          type: "button",
                          disabled:
                            _vm.typePhoto === "selfie" && !_vm.faceDetected,
                        },
                        on: { click: _vm.onCapture },
                      },
                      [
                        _c("CameraIcon", { staticClass: "w-6 h-6 mr-2" }),
                        _vm._v("\n          Take\n        "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imageData
        ? _c("div", [
            _c("img", { attrs: { src: _vm.imageData, alt: "Foto tomada" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-center mt-5" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary", type: "button" },
                    on: { click: _vm.reTakePhoto },
                  },
                  [_c("CameraIcon", { staticClass: "w-6 h-6" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "mx-4",
                    attrs: { type: "button" },
                    on: { click: _vm.saveImage },
                  },
                  [_c("CheckIcon", { staticClass: "w-6 h-6" })],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }