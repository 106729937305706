var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pbx-service-details" },
    [
      _c("sw-divider", { staticClass: "my-6" }),
      _vm._v(" "),
      _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("customers.pbxservices_resume")) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.service_number")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.pbx_services_number
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.package_name")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package
                                ? _vm.selectedPbxService.pbx_package
                                    .pbx_package_name
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("packages.status")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.serviceStatus ? _vm.serviceStatus.name : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.term")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.term
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.discount")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.allowDiscount) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.date_begin")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService
                                ? _vm.selectedPbxService.date_begin
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("pbx_services.type_service")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package
                                ? _vm.selectedPbxService.pbx_package
                                    .status_payment
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("pbx_services.tenant")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.tenant
                                ? _vm.selectedPbxService.tenant.name
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("pbx_services.package_price")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.selectedPbxService
                            ? _vm.$utils.formatMoney(
                                _vm.selectedPbxService.pbxpackages_price,
                                _vm.defaultCurrency
                              )
                            : ""
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("pbx_services.package_number")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.selectedPbxService &&
                                _vm.selectedPbxService.pbx_package
                                ? _vm.selectedPbxService.pbx_package
                                    .packages_number
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "my-8" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid gap-3 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.selectedPbxService
                                    ? _vm.$utils.formatMoney(
                                        _vm.selectedPbxService.total,
                                        _vm.defaultCurrency
                                      )
                                    : ""
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("customers.recurring_charge")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("estimate-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.defaultCurrency.symbol +
                                    " " +
                                    this.selectedPbxService.total_consume
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("pbx_services.additional_month")
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("contact-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                      attrs: { slot: "item-title", to: "" },
                      slot: "item-title",
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-lg font-semibold leading-tight text-black",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.selectedPbxService
                                      ? _vm.selectedPbxService.renewal_date
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("pbx_services.renewal_date")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("invoice-icon", {
                            staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.getStatusPayment == "prepaid"
                    ? _c(
                        "router-link",
                        {
                          staticClass:
                            "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                          attrs: { slot: "item-title", to: "" },
                          slot: "item-title",
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-sm font-semibold leading-tight text-black xl:text-2xl",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.selectedViewCustomer &&
                                        _vm.selectedViewCustomer.customer
                                        ? _vm.defaultCurrency.symbol +
                                            " " +
                                            _vm.selectedViewCustomer.customer.balance.toFixed(
                                              2
                                            )
                                        : ""
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("pbx_services.credit")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c("dollar-icon", {
                                staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-8" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("corePbx.dashboard.extensions")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "ext_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchExtensionsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.number"),
                                show: "ext",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.location"),
                                show: "location",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.status"),
                                show: "status",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.pro_ext_name"),
                                show: "profile_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.pro_ext_price"),
                                show: "profile_rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "pbx_services.pro_ext_price"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.price.toFixed(2)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                filterable: false,
                                "cell-class": "action-dropdown no-click",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdateExtModal2(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("pencil-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.$t("general.edit")
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.extensionCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                this.extensionTotal.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("corePbx.dashboard.did")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchDIDsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.did_channel"),
                                show: "number",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.destination"),
                                show: "ext",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.pro_ext_name2"),
                                show: "profile_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.pro_ext_price2"),
                                show: "profile_rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "pbx_services.pro_ext_price2"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.profile_rate
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: false,
                                filterable: false,
                                "cell-class": "action-dropdown no-click",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _vm.editextension == true
                                            ? _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openUpdateDIDModal(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("pencil-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.$t("general.edit")
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.didCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                this.didTotal.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("corePbx.items")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchItemsData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.description"),
                                show: "description",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.quantity"),
                                show: "quantity",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.discount"),
                                show: "discount",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc("pbx_services.discount")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex flex-auto",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          row.discount_type == "fixed"
                                            ? _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.defaultCurrency
                                                          .symbol
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(row.discount)),
                                          ]),
                                          _vm._v(" "),
                                          row.discount_type == "percentage"
                                            ? _c(
                                                "span",
                                                { staticClass: "flex" },
                                                [
                                                  _vm._v(
                                                    "\n                          %\n                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.total"),
                                show: "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.total"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                row.total,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.itemCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                this.itemTotal,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("corePbx.charges")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchAdditionalChargesData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "description",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.price"),
                                show: "amount",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.price"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              row.amount
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type_from"),
                                show: "type_from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.profile_name"),
                                show: "profile_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.chargesCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.total")
                                            ) +
                                            "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.amount")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                this.chargesTotal.toFixed(2)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-8" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  Custom App Rate\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "ext_table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCustomAppRateData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("pbx_services.name"),
                                show: "app_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: "Quantity",
                                show: "quantity",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: "Price",
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [_vm._v("Price")]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.costo * 100
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Total Quantity\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.totalQuantity)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Total Price\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                _vm.totalCosto * 100
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("corePbx.dashboard.call_history")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(5),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCallHistoryData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("corePbx.dashboard.from"),
                                show: "from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.to"),
                                show: "to",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.date"),
                                show: "formatted_start_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [_vm._v("Type")]),
                                      _vm._v(" "),
                                      row.type == 0
                                        ? _c("div", [_vm._v("Inbound")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.type == 1
                                        ? _c("div", [_vm._v("Outbound")])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totalr"),
                                show: "formatted_round_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.amount"),
                                show: "cost",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.billed_at == null
                                        ? _c("div", [_vm._v("Pending")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.billed_at != null
                                        ? _c("div", [
                                            row.exclusive_seconds == 0
                                              ? _c("div", [_vm._v("0")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.exclusive_seconds != 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        row.getFormattedExcusiveCost
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.all_cdr")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.callHistoryCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cdr")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.billed_cdr)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_time")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.billed_time)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cost")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " $ " +
                                              _vm._s(
                                                this.callHistoryTotal.toFixed(2)
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("pbx_services.custom_destinations")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchCustomDestinationData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("corePbx.dashboard.from"),
                                show: "from",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.to"),
                                show: "to",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.date"),
                                show: "formatted_start_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.type"),
                                show: "type",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [_vm._v("Type")]),
                                      _vm._v(" "),
                                      row.type == 0
                                        ? _c("div", [_vm._v("Inbound")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.type == 1
                                        ? _c("div", [_vm._v("Outbound")])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.rate"),
                                show: "rate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.custom_rate != null
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  row.custom_rate
                                                    .rate_per_minute
                                                ) +
                                                "\n                      "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("corePbx.dashboard.totalr"),
                                show: "formatted_round_duration",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$tc("pbx_services.amount"),
                                show: "cost",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("pbx_services.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      row.billed_at == null
                                        ? _c("div", [_vm._v("Pending")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.billed_at != null
                                        ? _c("div", [
                                            row.exclusive_seconds == 0
                                              ? _c("div", [_vm._v("0")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.exclusive_seconds != 0
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        row.getFormattedExcusiveCost
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.all_cdr")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(this.customDestinationCount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cdr")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.ctmDestBilledCDR)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_time")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(this.ctmDestBilledTime)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.billed_cost")
                                            ) +
                                            ":\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center text-lg uppercase text-primary-400",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                          $ " +
                                              _vm._s(
                                                this.customDestinationTotal.toFixed(
                                                  2
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-5" }, [
                _c("div", { staticClass: "border-b tab" }, [
                  _c("div", { staticClass: "relative" }, [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                      attrs: { type: "checkbox" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-gray-500 uppercase sw-section-title",
                          },
                          [
                            _vm._v(
                              "\n                  INVOICES\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tab-content-slide" },
                      [
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "sw-table-component",
                          {
                            ref: "table",
                            attrs: {
                              "show-filter": false,
                              data: _vm.fetchInvoicesPerServicePbxData,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.number"),
                                show: "invoice_number",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("invoices.number"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "font-medium text-primary-500",
                                          attrs: {
                                            to: `/customer/invoice/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(row.invoice_number) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: "DATE",
                                show: "invoice_date",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: "STATUS",
                                show: "status",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: "PAID STATUS",
                                show: "paid_status",
                              },
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.total"),
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [_vm._v("Total")]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(row.total)
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("sw-table-column", {
                              attrs: {
                                sortable: true,
                                label: _vm.$t("invoices.amount_due"),
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [_vm._v("AMOUNT DUE")]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.due_amount
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                          },
                          [
                            _c("div", { staticClass: "w-full lg:w-1/2" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between w-full",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-sm font-semibold leading-5 text-gray-500 uppercase mr-12",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("pbx_services.count")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "flex items-center justify-center m-0 text-lg text-black uppercase ml-12",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.invoicesCount)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }