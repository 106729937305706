var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.warningZone
    ? _c("idle-modal", { attrs: { "idle-time": _vm.idleTime } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }