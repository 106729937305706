export const BOOTSTRAP_ITEMS = 'BOOTSTRAP_ITEMS'
export const BOOTSTRAP_ITEMS_POS = 'BOOTSTRAP_ITEMS_POS'
export const ADD_ITEM = 'ADD_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const DELETE_MULTIPLE_ITEMS = 'DELETE_MULTIPLE_ITEMS'
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS'
export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS'
export const SET_TOTAL_ITEMS_POS = 'SET_TOTAL_ITEMS_POS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const ADD_ITEM_UNIT = 'ADD_ITEM_UNIT'
export const SET_ITEM_UNITS = 'SET_ITEM_UNITS'
export const UPDATE_ITEM_UNIT = 'UPDATE_ITEM_UNIT'
export const DELETE_ITEM_UNIT = 'DELETE_ITEM_UNIT'
export const SET_ITEMS_CATEGORIES = 'SET_ITEMS_CATEGORIES'