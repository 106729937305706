var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-2" },
    [
      !_vm.isView || _vm.selectedTax != null
        ? _c("div", { staticClass: "flex items-center justify-between" }, [
            _c(
              "div",
              {
                staticClass: "flex items-center text-base",
                staticStyle: { flex: "9", "margin-left": "332px" },
              },
              [
                _c(
                  "label",
                  { staticClass: "pr-2 mb-0", attrs: { align: "right" } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("packages.tax")) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isView
                  ? _c("div", [
                      _vm.selectedTax != null && _vm.selectedTax.name != ""
                        ? _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                            domProps: {
                              textContent: _vm._s(_vm.selectedTax.name),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _c(
                      "sw-select",
                      {
                        attrs: {
                          disabled: _vm.disabled == 1 ? true : false,
                          options: _vm.filteredTypes,
                          "allow-empty": false,
                          "show-labels": false,
                          "custom-label": _vm.customLabel,
                          placeholder: _vm.$t("general.select_a_tax"),
                          "track-by": "name",
                          label: "name",
                        },
                        on: { select: (val) => _vm.onSelectTax(val) },
                        model: {
                          value: _vm.selectedTax,
                          callback: function ($$v) {
                            _vm.selectedTax = $$v
                          },
                          expression: "selectedTax",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "afterList" }, slot: "afterList" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "flex items-center justify-center w-full px-2 py-2 bg-gray-200 border-none outline-none",
                                attrs: { type: "button" },
                                on: { click: _vm.openTaxModal },
                              },
                              [
                                _c("check-circle-icon", {
                                  staticClass: "h-5 text-primary-400",
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ml-2 text-sm leading-none text-primary-400",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("packages.add_new_tax"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c("br"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-sm text-right", staticStyle: { flex: "3" } },
              [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$utils.formatMoney(_vm.taxAmount, _vm.currency)
                    ),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            !_vm.isView
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                  },
                  [
                    _vm.taxes.length && _vm.index !== _vm.taxes.length - 1
                      ? _c("trash-icon", {
                          staticClass: "h-5 text-gray-700",
                          attrs: { icon: "trash-alt" },
                          on: { click: _vm.removeTax },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "col-span-12" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }