var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "session_expired_overlay" }, [
    _c("div", { staticClass: "session_expired_modal" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "p-3" }, [
        _c("p", [
          _vm._v(
            "You have left this browser idle for " +
              _vm._s(_vm.idleTime) +
              " minutes."
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.second) + " seconds left")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "session_expired_modal__title" }, [
      _c("span", [_vm._v("Session Expired")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }