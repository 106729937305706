export const BOOTSTRAP_DID = 'BOOTSTRAP_DID'
export const ADD_DID = 'ADD_DID'
export const UPDATE_DID = 'UPDATE_DID'
export const DELETE_DID = 'DELETE_DID'
export const RESET_DID = 'RESET_DID'
export const SET_SELECTED_DID = 'SET_SELECTED_DID'
export const SET_TOTAL_DID = 'SET_TOTAL_DID'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_DID = 'RESET_SELECTED_DID'
export const DELETE_MULTIPLE_DID = 'DELETE_MULTIPLE_DID'
