export const payments = (state) => state.payments
export const selectedPayments = (state) => state.selectedPayments
export const selectAllField = (state) => state.selectAllField
export const totalPayments = (state) => state.totalPayments
export const paymentModes = (state) => state.paymentModes
export const paymentModesWithSettings = (state) => state.paymentModesWithSettings
export const existAuthorizeSetting = (state) => state.existAuthorizeSetting
export const existPaypalSetting = (state) => state.existPaypalSetting
export const existAuxVaultSetting = (state) => state.existAuxVaultSetting
export const selectedNote = (state) => state.selectedNote
