<template>
  <div>
    <!-- Recent Credit History -->
    <!--   <sw-card class="mb-6">
      <h2 class="text-xl font-semibold mb-6">Recent Credit History</h2>
      <div class="space-y-2">
        <div
          v-for="history in creditHistory"
          :key="history.date"
          class="flex justify-between items-center bg-gray-100 p-2 rounded-md"
        >
          <span>{{ formatDate(history.date) }}</span>
          <span class="font-medium">${{ formatCurrency(history.amount) }}</span>
        </div>
      </div>
    </sw-card>
  -->
    <!-- Quick Add -->
    <sw-card class="mb-6">
      <h2 class="text-xl font-semibold mb-6">Quick Add</h2>
      <div class="flex space-x-2">
        <button
          v-for="amount in [10, 25, 50, 100]"
          :key="amount"
          class="quick-add-btn w-20"
          @click="quickAddCredit(amount)"
        >
          ${{ amount }}
        </button>
      </div>
    </sw-card>

    <!-- Credit Summary -->
    <sw-card class="mb-6">
      <h2 class="text-xl font-semibold mb-6">Credit Summary</h2>
      <div class="space-y-4">
        <div class="flex items-center justify-between">
          <label for="amountToAdd" class="text-lg font-medium text-gray-700"
            >Amount to Add</label
          >
          <div class="relative rounded-md shadow-sm w-1/2">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <span class="text-gray-500 sm:text-lg">$</span>
            </div>
            <input
              type="text"
              name="amountToAdd"
              id="amountToAdd"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-lg border-gray-300 rounded-md text-right"
              placeholder="0.00"
              v-model="formattedAmount"
              @input="handleInput"
              @keydown="handleKeydown"
              ref="amountInput"
            />
          </div>
        </div>

        <!-- Fees -->
        <div v-if="fees.length > 0" class="space-y-2">
          <div
            v-for="fee in fees"
            :key="fee.id"
            class="flex justify-between items-center text-sm w-full"
          >
            <div
              v-if="fee.type == 'fixed'"
              class="flex justify-between items-center w-full"
            >
              <span class="text-gray-600 text-left">{{ fee.name }}</span>
              <div
                v-if="fee.type == 'fixed'"
                v-html="$utils.formatMoney(fee.amount, defaultCurrency)"
                class="text-right"
              ></div>
            </div>
            <div
              v-if="fee.type == 'percentage'"
              class="flex justify-between items-center w-full"
            >
              <span class="text-gray-600 text-left">{{ fee.name }}</span>
              <span class="font-medium text-right"
                >{{ fee.amount / 100 }} %</span
              >
            </div>
          </div>
        </div>

        <div
          class="flex justify-between items-center font-semibold text-lg pt-2 border-t"
        >
          <span>Total Charge:</span>
          <span>${{ formatCurrency(summaryAmount) }}</span>
        </div>

        <!-- Mostrar la información de la tarjeta guardada -->
        <div
          v-if="cardSaveText && Last4digittext"
          class="card-info border border-gray-300 rounded-lg p-2 flex items-center"
        >
          <img
            v-if="creditCardImages[cardSaveText]"
            :src="creditCardImages[cardSaveText].src"
            :width="creditCardImages[cardSaveText].width"
            class="h-5 mr-2"
            alt="Credit Card Logo"
          />
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="24px"
            height="24px"
            class="h-5 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
            />
          </svg>
          <span>{{ cardSaveText }} ending in {{ Last4digittext }}</span>
        </div>

        <sw-button
          :disabled="isPayDisabled"
          variant="primary-outline"
          class="w-full"
          @click="handleButtonClick()"
        >
          {{ buttonText }}
        </sw-button>
      </div>
    </sw-card>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  props: {
    fees: Array,
    disablePay: Boolean,
    isidentificationverification: Boolean,
    formData: {
      type: Object,
      required: true,
    },
    defaultPaymentdisplay: {
      type: Object,
      required: true,
    },
    defaultPaymentGateway: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
    cardSaveText: {
      type: String,
      required: true,
    },
    Last4digittext: {
      type: String,
      required: true,
    },
    creditCardId: {
      type:  Number,
      default: null,
      required: true,
    },
    achAccountId: {
      type:  Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      creditHistory: [
        { date: new Date(2023, 7, 1), amount: 50 },
        { date: new Date(2023, 6, 15), amount: 100 },
        { date: new Date(2023, 6, 1), amount: 25 },
      ],
      totalAmount: 0,
      totalDue: 0, // Asegúrate de definir totalDue aquí
      fees: [],
      amountError: null,
      requireIdVerification: false,
      showModal: false,
      customer: {},
      creditCardImages: {
        VISA: { src: '/images/visa.png', width: '70px' },
        MASTERCARD: { src: '/images/mastercard.png', width: '40px' },
        'AMERICAN EXPRESS': {
          src: '/images/american_express.png',
          width: '45px',
        },
        DISCOVER: { src: '/images/discover.png', width: '50px' },
      },
    }
  },
  mounted() {
    // console.log('mounted hook iniciado')
    this.$nextTick(() => {
      // console.log('$nextTick ejecutado')
      this.loadData()
      // console.log('loadData llamado')
    })
  },

  methods: {
    ...mapActions('paymentAccounts', [
      'fetchPaymentAccounts',
      'fetchPaymentAccount',
      'addPaymentAccount',
    ]),

    ...mapActions('payment', [
      'addPayment',
      'updatePayment',
      'fetchPayment',
      'fetchPaymentModes',
      'resetSelectedNote',
      'processPayment',
      'paymentsMethodActiveCustomerCredit',
      'paymentAsociated',
    ]),

    async loadData() {
      if (this.currentUser) {
        this.customer = this.currentUser
      }

      return true
    },
    showIdVerificationModal() {
      this.showModal = true
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
        date
      )
    },
    formatCurrency(amount) {
      if (amount) {
        return (Math.round(amount * 100) / 100).toFixed(2)
      }
      return '0.00'
    },
    updateAmount() {
      // console.log('Actualizando monto:', this.totalAmount)

      if (this.totalAmount <= 0) {
        this.amountError = 'Amount must be greater than zero.'
        // console.log('Error:', this.amountError)
      } else {
        this.amountError = null
        //console.log('Monto actualizado correctamente.')
      }
    },
    calculateFee(fee) {
      //  console.log('Calculando tarifa para:', fee)

      if (fee.type === 'percentage') {
        const calculatedFee = (this.totalAmount * (fee.amount / 100)) / 100
        //  console.log('Tipo de tarifa: porcentaje')
        //console.log('Valor de tarifa:', fee.amount)
        //console.log('Monto total:', this.totalAmount)
        //console.log('Tarifa calculada:', calculatedFee)
        return calculatedFee
      }

      if (fee.type === 'fixed') {
        const calculatedFee = fee.amount / 100
        console.log('Tipo de tarifa: fixe')
        console.log('Valor de tarifa:', fee.amount)
        console.log('Tarifa calculada:', calculatedFee)
        return calculatedFee
      }

      console.log('Tipo de tarifa: monto fijo')
      console.log('Monto de tarifa:', fee.amount)
      return fee.amount
    },
    quickAddCredit(amount) {
      if (!Number.isInteger(amount)) {
        console.log('Error: El monto debe ser un número entero.');
        return;
      }
      this.totalAmount = amount;
      this.formattedAmount = this.formatValue(amount.toString());
      this.updateAmount();
    },
    formatValue(value) {
      const cleanedValue = value.replace(/[^0-9.]/g, '');
      
      let [integer, decimal] = cleanedValue.split('.');
      
      if (!decimal) decimal = '00';
      if (decimal.length > 2) decimal = decimal.slice(0, 2);
      
      return `${integer || '0'}.${decimal.padEnd(2, '0')}`;
    },

    handleInput(event) {
      const input = event.target;
      const cursorPosition = input.selectionStart; 

      const rawValue = input.value;
      const formattedValue = this.formatValue(rawValue);

      this.$refs.amountInput.value = formattedValue;

      this.totalAmount = parseFloat(formattedValue);

      this.$nextTick(() => {
        input.selectionStart = cursorPosition;
        input.selectionEnd = cursorPosition;
      });

      this.updateAmount();
    },

    handleKeydown(event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        this.handleInput(event);
      }
    },

    /**
     * Handles the button click event. Validates the form data and either shows a modal for ID verification
     * or submits the payment data based on the validation results.
     */
    async handleButtonClick() {
      const isValid = await this.Validation()
      if (isValid) {
        if (this.requireIdVerification) {
          this.showModal = true // Mostrar el modal
        } else {
          this.submitpaymentdata()
        }
      }
    },
    /**
     * Submits the payment data after confirming with the user.
     * Displays a confirmation dialog and, if confirmed, validates and assigns necessary fields in formData,
     * then calls handlePaymentcuadro2 to process the payment.
     * @returns {Promise<void>}
     */
    async submitpaymentdata() {
      // Mostrar un cuadro de diálogo de confirmación
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('payments.payment_invoice_message'),
        icon: 'warning',
        buttons: true,
      }).then((value) => {
        if (value) {
          // El usuario confirmó, proceder con el procesamiento del pago
          console.log('formdata')
          console.log(this.formData)

          console.log('defaultPaymentdisplay')
          console.log(this.defaultPaymentdisplay)
          console.log('defaultPaymentGateway')
          console.log(this.defaultPaymentGateway)
          console.log('paymentType')
          console.log(this.paymentType)
          console.log('totalamount')
          console.log(this.totalAmount)
          console.log('Fees')
          console.log(this.fees)

          // Validar y asignar paymentType si no está presente en formData
          if (!this.formData.paymentType) {
            this.$set(this.formData, 'paymentType', this.paymentType)
          }

          // Validar y asignar defaultPaymentdisplay si no está presente en formData
          if (!this.formData.defaultPaymentdisplay) {
            this.$set(
              this.formData,
              'defaultPaymentdisplay',
              this.defaultPaymentdisplay
            )
          }

          // Validar y asignar paymentGateway si no está presente en formData
          if (!this.formData.paymentGateway) {
            this.$set(
              this.formData,
              'paymentGateway',
              this.defaultPaymentGateway
            )
          }

          console.log('formdata procesado')
          console.log(this.formData)

          // Llamar al método para manejar el cuadro de diálogo de pago
          this.handlePaymentcuadro2()
        } else {
          // El usuario canceló, no hacer nada
          return false
        }
      })
    },

    /**
     * Handles the payment process by displaying a warning dialog while the payment is being processed.
     * Calls the processPaymentvue method to process the payment and closes the dialog based on the result.
     * @returns {Promise<void>}
     */
    async handlePaymentcuadro2() {
      // Mostrar un cuadro de diálogo de advertencia mientras se procesa el pago
      Swal.fire({
        title: 'Payment Processing',
        text: 'Payment is Processing, wait a few seconds...',
        icon: 'warning',
        buttons: false,
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })

      try {
        // Llamar al método para procesar el pago
        const response = await this.processPaymentdisparador()
        // Cerrar el cuadro de diálogo al completar el proceso
        Swal.close()
        // Manejar la respuesta exitosa aquí
      } catch (error) {
        // Cerrar el cuadro de diálogo si hay un error
        Swal.close()
        // Manejar el error aquí
      }
    },
    /**
     * Processes a payment by determining the type of payment (saved or one-time) and calling the appropriate method.
     * Logs various data to the console for debugging purposes.
     * @returns {Promise<boolean>} - Returns true if the payment process completes successfully.
     */
    async processPaymentdisparador() {
      console.log('----process payment----')
      console.log(this.formData)

      console.log('fees ' + this.fees)
      console.log('customer ' + this.customer)
      console.log(this.customer)

      // Verificar si el tipo de pago es 'saved'
      if (this.formData.paymentType == 'saved') {
        if (this.formData.defaultPaymentdisplay) {
          // Llamar al método para procesar el pago guardado
          const response = await this.processPaymentvueSave()
          return true
        }
      } else {
        console.log('----process payment onetime ----')
        console.log(this.formData)
        // Llamar al método para procesar el pago único
        const response = await this.processPaymentvueOneTime()
        console.log('----process payment onetime ----')
        return true
      }
      console.log('----process payment----')
      return true
    },
    /**
     * Processes a saved payment by gathering necessary data from the form and customer,
     * organizing it into a DataPayment object, and then attempting to process the payment.
     * Displays success or error messages based on the server response.
     * @returns {Promise<boolean>} - Returns true if the payment is processed successfully, otherwise false.
     */
    async processPaymentvueSave() {
      console.log('----process payment saved----')
      console.log('defaultPaymentdisplay:', this.formData.defaultPaymentdisplay)
      console.log('customer:', this.customer)
      console.log('fees:', this.fees)

      // Crear el objeto DataPayment con los datos necesarios
      let DataPayment = {
        payer_email: this.customer.email,
        payment_account_type:
          this.formData.defaultPaymentdisplay.payment_account_type,
        user_id: this.customer.id,
        client_id: this.customer.id,
        payer_id: this.customer.id,
        payment_number: 'pay-0009',
        company_id: this.customer.company_id,
        customcode: this.customer.customcode,
        name: this.customer.last_name || this.customer.name,
        status: 'A',
        payment_date: moment().format('YYYY-MM-DD'),
        date: moment().format('YYYY-MM-DD'),
        company_name: this.customer.first_name || this.customer.contact_name,
        name: this.customer.last_name || this.customer.name,
        payment_gateway_id: this.formData.paymentGateway.id,
        country_id: this.formData.defaultPaymentdisplay.country_id,
        state_id: this.formData.defaultPaymentdisplay.state_id,
        city: this.formData.defaultPaymentdisplay.city,
        address_1: this.formData.defaultPaymentdisplay.address_1,
        address_2: this.formData.defaultPaymentdisplay.address_2,
        zip: this.formData.defaultPaymentdisplay.zip,
        nameOnAccount: this.customer.last_name || this.customer.name,
        admin: 'cust',
        has_fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' ? 1 : 0,
        fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' &&
          this.fees.length > 0
            ? this.fees.map((fee) => fee.id)
            : [],
        invoices: [],
        amount: Math.trunc(this.totalAmount * 100),
        phone: null,
        country: this.formData.defaultPaymentdisplay.country_name,
        state: this.formData.defaultPaymentdisplay.state_name,
        type: 'billing',
        first_name: this.customer.name,
        last_name: this.customer.name,
        email: this.customer.email,
        invoice_id: null,
        nameOnAccount: this.customer.last_name || this.customer.name,
      }

      // Limitar la longitud de los nombres si es necesario

      // Limitar la longitud de ciertos campos
      ;['name', 'nameOnAccount', 'company_name'].forEach((field) => {
        if (DataPayment[field]) {
          DataPayment[field] = DataPayment[field].substring(0, 21)
        }
      })

      DataPayment.invoice_list = []
      DataPayment.invoices = []

      // Agregar detalles específicos según el tipo de cuenta de pago
      if (DataPayment.payment_account_type === 'CC') {
        DataPayment.credit_card =
          this.formData.defaultPaymentdisplay.credit_card
        DataPayment.cvv = this.formData.defaultPaymentdisplay.cvv
        DataPayment.card_number =
          this.formData.defaultPaymentdisplay.card_number
        DataPayment.expiration_date =
          this.formData.defaultPaymentdisplay.expiration_date
        DataPayment.payment_method_id = this.creditCardId
      } else {
        DataPayment.ACH_type =
          this.formData.defaultPaymentdisplay.ACH_type.toString().toLowerCase()
        DataPayment.account_number =
          this.formData.defaultPaymentdisplay.account_number
        DataPayment.routing_number =
          this.formData.defaultPaymentdisplay.routing_number
        DataPayment.bank_name = this.formData.defaultPaymentdisplay.bank_name
        DataPayment.payment_method_id = this.achAccountId
      }

      console.log('DataPayment:', DataPayment)

      try {
        // Intentar procesar el pago
        const response = await this.processPayment(DataPayment)
        // Mostrar mensaje de éxito o error según la respuesta del servidor
        if (response.data.success) {
          window.toastr['success'](this.$t('payments.created_message'))
          this.$router.push(
            `/customer/payments/${response.data.payment_id}/view`
          )
          this.isLoading = false
          console.log('----process payment saved exito----')
          return true
        } else {
          window.toastr['error'](response.data.message)
          this.isLoading = false
          console.log('----process payment saved exito fracaso----')
          return false
        }
      } catch (error) {
        // Manejar errores
        this.isLoading = false

        if (error.response && error.response.status === 422) {
          // Manejar errores de validación
          if (error.response.data.hasOwnProperty('errors')) {
            for (let key in error.response.data.errors) {
              let message = key + ': ' + error.response.data.errors[key][0]
              window.toastr['error'](message)
            }
          } else if (error.response.data.hasOwnProperty('data')) {
            if (typeof error.response.data.data === 'string') {
              let message = error.response.data.data
              window.toastr['error'](message)
            } else if (Array.isArray(error.response.data.data)) {
              for (let key in error.response.data.data) {
                let message = key + ': ' + error.response.data.data[key][0]
                window.toastr['error'](message)
              }
            }
          }
        } else {
          // Manejar otros tipos de errores
          window.toastr['error']('Unknown error, contact administration')
          return false
        }

        return false
      }
    },
    /**
     * Processes a one-time payment by gathering necessary data from the form and customer,
     * organizing it into a DataPayment object, and then attempting to process the payment.
     * Displays success or error messages based on the server response.
     * @returns {Promise<boolean>} - Returns true if the payment is processed successfully, otherwise false.
     */
    async processPaymentvueOneTime() {
      console.log('----process payment onetime metodo----')
      console.log('formdat:', this.formData.defaultPaymentdisplay)
      console.log('formdata:', this.formData)
      console.log('customer:', this.customer)
      console.log('fees:', this.fees)

      // Crear el objeto DataPayment con los datos necesarios
      const DataPayment = {
        payment_account_type: this.formData.payment_account_type,
        payer_email: this.customer.email,
        user_id: this.customer.id,
        client_id: this.customer.id,
        payer_id: this.customer.id,
        payment_number: 'pay-0009',
        company_id: this.customer.company_id,
        customcode: this.customer.customcode,
        status: 'A',
        payment_date: moment().format('YYYY-MM-DD'),
        date: moment().format('YYYY-MM-DD'),
        company_name: this.customer.first_name || this.customer.contact_name,
        name: this.customer.last_name || this.customer.name,
        payment_gateway_id: this.formData.paymentGateway.id,
        country_id: this.formData.country.id,
        state_id: this.formData.state.id,
        city: this.formData.city,
        address_1: this.formData.address_1,
        address_2: this.formData.address_2,
        zip: this.formData.zip,
        nameOnAccount: this.customer.last_name || this.customer.name,
        admin: 'cust',
        has_fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' ? 1 : 0,
        fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' &&
          this.fees.length > 0
            ? this.fees.map((fee) => fee.id)
            : [],
        invoices: [],
        amount: this.totalAmount * 100,
        phone: null,
        country: this.formData.country.name,
        state: this.formData.state.name,
        type: 'billing',
        first_name: this.customer.name,
        last_name: this.customer.name,
        email: this.customer.email,
        invoice_id: null,
        payment_method_id: this.formData.payment_method.id,
      }

      // Limitar la longitud de los nombres si es necesario
      DataPayment.name = DataPayment.name?.substring(0, 21)
      DataPayment.company_name = DataPayment.company_name?.substring(0, 21)

      // Agregar detalles específicos según el tipo de cuenta de pago
      if (DataPayment.payment_account_type === 'CC') {
        Object.assign(DataPayment, {
          credit_card: this.formData.credit_card.value,
          cvv: this.formData.cvv,
          card_number: this.formData.card_number,
          expiration_date: `${this.formData.expiration_year}-${this.formData.expiration_month}`,
          nameOnAccount: this.formData.card_holder?.substring(0, 21),
        })
      } else {
        Object.assign(DataPayment, {
          ACH_type: this.formData.ACH_type.name.toString().toLowerCase(),
          account_number: this.formData.account_number,
          routing_number: this.formData.routing_number,
          bank_name: this.formData.bank_name,
          nameOnAccount: this.formData.account_holder?.substring(0, 21),
        })
      }

      console.log('DataPayment:', DataPayment)

      try {
        // Intentar procesar el pago
        const response = await this.processPayment(DataPayment)
        // Mostrar mensaje de éxito o error según la respuesta del servidor
        if (response.data.success) {
          window.toastr['success'](this.$t('payments.created_message'))

          if (this.formData.saveForFutureUse) {
            const DataPayamentAccount = {
              first_name: DataPayment.nameOnAccount,
              country_id: DataPayment.country_id,
              state_id: DataPayment.state_id,
              city: DataPayment.city,
              address_1: DataPayment.address_1,
              address_2: DataPayment.address_2,
              zip: DataPayment.zip,
              payment_account_type: DataPayment.payment_account_type,
              client_id: DataPayment.client_id,
              status: 'A',
              main_account: this.formData.saved_pay
                ? this.formData.saved_pay === true
                  ? 1
                  : 0
                : 0,
              nameOnAccount: DataPayment.nameOnAccount,
              ...(DataPayment.payment_account_type === 'CC'
                ? {
                    credit_cards: this.formData.credit_card,
                    cvv: this.formData.cvv,
                    card_number: this.formData.card_number,
                    expiration_date: DataPayment.expiration_date,
                  }
                : {
                    ACH_type: this.formData.ACH_type.name,
                    account_number: DataPayment.account_number,
                    routing_number: DataPayment.routing_number,
                    bank_name: DataPayment.bank_name,
                  }),
            }

            try {
              const responsePaymentaccount = await this.addPaymentAccount(
                DataPayamentAccount
              )
              if (responsePaymentaccount.status === 200) {
                window.toastr['success']('Payment Account Added')
              } else {
                window.toastr['error']('Failed to add payment account')
              }
            } catch (error) {
              window.toastr['error']('Failed to add payment account')
            }
          }
          this.$router.push(
            `/customer/payments/${response.data.payment_id}/view`
          )
          this.isLoading = false
          console.log('----process payment saved exito----')
          return true
        } else {
          window.toastr['error'](response.data.message)
          this.isLoading = false
          console.log('----process payment saved exito fracaso----')
          return false
        }
      } catch (error) {
        // Manejar errores
        this.isLoading = false

        if (error.response && error.response.status === 422) {
          // Manejar errores de validación
          const errors = error.response.data.errors || error.response.data.data
          if (errors) {
            for (let key in errors) {
              let message = `${key}: ${errors[key][0]}`
              window.toastr['error'](message)
            }
          }
        } else {
          // Manejar otros tipos de errores
          window.toastr['error']('Unknown error, contact administration')
        }

        return false
      }
    },

    /**
     * Validates various properties of the component to ensure they have valid values.
     * If any validation fails, an error message is displayed and the execution is stopped.
     * @returns {boolean} - Returns true if all validations pass, otherwise false.
     */
    async Validation() {
      // Función auxiliar para mostrar mensajes de error y retornar false
      const showError = (message, errorType) => {
        window.toastr['error'](`${message} (Error: ${errorType})`)
        return false
      }

      // Validar que totalAmount sea un número mayor que cero
      if (
        this.totalAmount < 1 ||
        this.totalAmount === null ||
        isNaN(this.totalAmount)
      ) {
        return showError(
          'The total amount must be a number greater than zero.',
          'totalAmount'
        )
      }

      // Validar que defaultPaymentGateway sea un objeto válido
      if (
        this.defaultPaymentGateway === null ||
        this.defaultPaymentGateway === undefined ||
        typeof this.defaultPaymentGateway !== 'object'
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'defaultPaymentGateway'
        )
      }

      // Validar que paymentType sea 'saved' o 'oneTime'
      if (
        this.paymentType === null ||
        this.paymentType === undefined ||
        (this.paymentType !== 'saved' && this.paymentType !== 'oneTime')
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'paymentType'
        )
      }

      // Validar que customer no sea null ni undefined
      if (this.customer === null || this.customer === undefined) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'customer'
        )
      }

      // Validar que formData no sea null ni undefined
      if (this.formData === null || this.formData === undefined) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'formData'
        )
      }

      // Validar que payment_account_type en formData sea 'CC' o 'ACH' y que paymentType sea 'oneTime'
      if (
        this.paymentType === 'oneTime' &&
        (this.formData.payment_account_type === undefined ||
          this.formData.payment_account_type === null ||
          (this.formData.payment_account_type !== 'CC' &&
            this.formData.payment_account_type !== 'ACH'))
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'payment_account_type'
        )
      }

      // Validar que defaultPaymentdisplay no sea null ni undefined si paymentType es 'saved'
      if (
        this.paymentType === 'saved' &&
        (this.defaultPaymentdisplay === null ||
          this.defaultPaymentdisplay === undefined)
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'defaultPaymentdisplay'
        )
      }

      // Validar campos específicos si paymentType es 'oneTime' y payment_account_type existe
      if (
        this.paymentType === 'oneTime' &&
        this.formData.payment_account_type
      ) {
        if (this.formData.payment_account_type === 'CC') {
          // Validar campos de tarjeta de crédito
          const ccFields = [
            'credit_card.value',
            'cvv',
            'card_number',
            'expiration_year',
            'expiration_month',
            'card_holder',
          ]
          for (const field of ccFields) {
            if (!this.getNestedValue(this.formData, field)) {
              return showError(
                'Please ensure all credit card details are filled. If the problem persists, refresh the page or contact the system administrator.',
                'credit card details'
              )
            }
          }
        } else if (this.formData.payment_account_type === 'ACH') {
          // Validar campos de ACH
          const achFields = [
            'ACH_type',
            'account_number',
            'routing_number',
            'account_holder',
          ]
          for (const field of achFields) {
            if (!this.formData[field]) {
              return showError(
                'Please ensure all ACH details are filled. If the problem persists, refresh the page or contact the system administrator.',
                'ACH details'
              )
            }
          }
        }

        // Validar campos adicionales si paymentType es 'oneTime'
        const requiredFields = ['address_1', 'zip', 'state', 'country', 'city']
        for (const field of requiredFields) {
          if (
            this.formData[field] === null ||
            this.formData[field] === undefined
          ) {
            return showError(
              `Please ensure the ${field} is filled. If the problem persists, refresh the page or contact the system administrator.`,
              field
            )
          }
        }

        // Validar que state y country sean objetos si paymentType es 'oneTime'
        if (
          typeof this.formData.state !== 'object' ||
          typeof this.formData.country !== 'object'
        ) {
          return showError(
            'Please ensure the state and country fields are valid objects. If the problem persists, refresh the page or contact the system administrator.',
            'state or country'
          )
        }
      }

      // Si todas las validaciones pasan, retornar true
      return true
    },

    /**
     * Retrieves the value of a nested property within an object.
     * @param {Object} obj - The object to retrieve the value from.
     * @param {string} path - The path to the nested property (e.g., 'credit_card.value').
     * @returns {*} - The value of the nested property, or undefined if it doesn't exist.
     */
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    },
  },
  computed: {
    ...mapState('user', ['currentUser', 'settingsCompany']),
    ...mapGetters('company', ['defaultCurrencyForInput', 'defaultCurrency']),
    ...mapGetters('customer', ['customers']),
    formattedAmount: {
      get() {
        return this.totalAmount.toFixed(2)
      },
      set(value) {
        this.totalAmount = parseFloat(value)
      },
    },
    buttonText() {
      console.log(this.isidentificationverification)
      return this.requireIdVerification ? 'ID Verification' : 'Add Credit'
    },
    summaryAmount() {
      console.log('Total Amount:', this.totalAmount)

      const totalFees = this.fees.reduce((total, fee) => {
        const feeValue = this.calculateFee(fee)
        console.log('Fee:', fee, 'Calculated Fee:', feeValue)
        return total + feeValue
      }, 0)

      console.log('Total Amount 2:', this.totalAmount)
      const summary = Number(this.totalAmount) + Number(totalFees)
      console.log('Total Fees:', totalFees)
      console.log('Summary Amount:', summary)

      return summary
    },
    isPayDisabled() {
      return this.totalAmount <= 1
    },
  },
}
</script>

<style scoped>
.quick-add-btn {
  background-color: #f0f0f0;
  color: #000;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quick-add-btn:hover {
  background-color: #e0e0e0;
  color: #333;
}

.quick-add-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
</style>
