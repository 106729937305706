var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "px-8 py-10 sm:p-6 relative overflow-auto sw-scroll h-80 grid grid-cols-12 gap-4",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-6",
                  attrs: {
                    label: _vm.$t("pbx_services.did_number"),
                    error: _vm.numberError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.number.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.number.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "number", $$v)
                      },
                      expression: "formData.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-6",
                  attrs: {
                    label: _vm.$t("pbx_services.did_status"),
                    error: _vm.statusError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.status.$error,
                      options: _vm.statuses,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      placeholder: _vm.$t("pbx_services.ext_select_status"),
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.status.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12 md:-mt-10",
                  attrs: {
                    label: _vm.$t("pbx_services.did_dest_type"),
                    error: _vm.destTypeError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.dest_type.$error,
                      options: _vm.typeDestOptions,
                      searchable: true,
                      "show-labels": false,
                      tabindex: 2,
                      "allow-empty": true,
                      label: "name",
                      "track-by": "value",
                    },
                    on: {
                      select: _vm.optionsSelectNumber,
                      input: function ($event) {
                        return _vm.$v.formData.dest_type.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.dest_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "dest_type", $$v)
                      },
                      expression: "formData.dest_type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData?.dest_type?.value !== 5
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12 md:-mt-10",
                      attrs: {
                        label: _vm.$t("pbx_services.did_value"),
                        error: _vm.valueError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          invalid: _vm.$v.formData.destination.$error,
                          options: _vm.optionsValue,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          "max-height": 200,
                          label: "label",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.destination.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.destination,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "destination", $$v)
                          },
                          expression: "formData.destination",
                        },
                      }),
                      _vm._v(" "),
                      _vm.messageError
                        ? _c("span", { staticClass: "text-red-600 text-xs" }, [
                            _vm._v(_vm._s(_vm.messageError)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData?.dest_type?.value == 5
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12 md:-mt-10",
                      attrs: {
                        label: _vm.$t("pbx_services.did_external_number"),
                        error: _vm.externalNumberError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.external_number.$error,
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.external_number.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.external_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "external_number", $$v)
                          },
                          expression: "formData.external_number",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "col-span-12 md:col-span-12 md:-mt-10",
                  attrs: { label: _vm.$t("pbx_services.did_trunk") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.trunkOptions,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      label: "name",
                    },
                    model: {
                      value: _vm.formData.trunk,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "trunk", $$v)
                      },
                      expression: "formData.trunk",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeExtensionModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                { attrs: { variant: "primary", type: "submit" } },
                [
                  _c("save-icon", { staticClass: "mr-2" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }