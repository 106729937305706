export const BOOTSTRAP_CUSTOM_DID_GROUPS = 'BOOTSTRAP_CUSTOM_DID_GROUPS'
export const SET_TOTAL_CUSTOM_DID_GROUPS = 'SET_TOTAL_CUSTOM_DID_GROUPS'
export const ADD_CUSTOM_DID_GROUP = 'ADD_CUSTOM_DID_GROUP'
export const UPDATE_CUSTOM_DID_GROUP = 'UPDATE_CUSTOM_DID_GROUP'
export const DELETE_CUSTOM_DID_GROUP = 'DELETE_CUSTOM_DID_GROUP'
export const DELETE_MULTIPLE_CUSTOM_DID_GROUPS = 'DELETE_MULTIPLE_CUSTOM_DID_GROUPS'
export const SET_SELECTED_CUSTOM_DID_GROUPS = 'SET_SELECTED_CUSTOM_DID_GROUPS'
export const RESET_SELECTED_CUSTOM_DID_GROUP = 'RESET_SELECTED_CUSTOM_DID_GROUP'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_CUSTOM_DID_GROUP = 'SET_SELECTED_VIEW_CUSTOM_DID_GROUP'
export const SET_CLONED_DID_GROUP = 'SET_CLONED_DID_GROUP'
export const RESET_CLONED_DID_GROUP = 'RESET_CLONED_DID_GROUP'