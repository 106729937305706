var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: "" } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "#" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("core_pos.title"), to: "#", active: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "sw-card",
              { attrs: { variant: "setting-card h-full" } },
              [
                _c("div", [
                  _c(
                    "form",
                    {
                      staticClass: "relative h-full",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveData.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("h6", { staticClass: "sw-section-title" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("settings.core_pos.core_pos")) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid gap-12 grid-col-1 md:grid-cols-3 mt-4",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "settings.core_pos.core_pos_prefix"
                                ),
                              },
                            },
                            [
                              _c("sw-input", {
                                staticStyle: { "max-width": "30%" },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.changeToUppercase("PREFIX")
                                  },
                                },
                                model: {
                                  value: _vm.formData.core_pos_prefix,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "core_pos_prefix",
                                      $$v
                                    )
                                  },
                                  expression: "formData.core_pos_prefix",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "mt-10",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$tc("settings.account_settings.save")
                              ) +
                              "\n              "
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "mt-6 mb-8" }),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "form",
                    {
                      staticClass: "relative mt-6",
                      staticStyle: { "z-index": "10" },
                      attrs: { action: "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveItemsCategory.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "mb-5 text-lg font-medium text-black" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("settings.core_pos.items_category")
                              ) +
                              "\n                  "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "flex mb-3" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    label: _vm.$t(
                                      "settings.core_pos.items_category"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.item_categories_options,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      multiple: true,
                                      "track-by": "id",
                                      label: "name",
                                      tabindex: 4,
                                    },
                                    model: {
                                      value: _vm.item_categories,
                                      callback: function ($$v) {
                                        _vm.item_categories = $$v
                                      },
                                      expression: "item_categories",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2" })
                                : _vm._e(),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "form",
                    {
                      staticClass: "relative mt-6",
                      staticStyle: { "z-index": "1" },
                      attrs: { action: "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.savePaymentMethods.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "mb-5 text-lg font-medium text-black" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("settings.core_pos.payment_methods")
                              ) +
                              "\n                  "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "flex mb-3" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    label: _vm.$t(
                                      "settings.core_pos.payment_methods"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.payment_methods_options,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      multiple: true,
                                      "track-by": "id",
                                      label: "name",
                                      tabindex: 4,
                                    },
                                    model: {
                                      value: _vm.payment_methods,
                                      callback: function ($$v) {
                                        _vm.payment_methods = $$v
                                      },
                                      expression: "payment_methods",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2" })
                                : _vm._e(),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "form",
                    {
                      staticClass: "relative h-full mt-6",
                      attrs: { action: "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveConfig.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "h3",
                          {
                            staticClass: "mb-5 text-lg font-medium text-black",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.invoices.pos_invoice"
                                  )
                                ) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex mb-6" }, [
                          _c(
                            "div",
                            { staticClass: "relative w-12" },
                            [
                              _c("sw-switch", {
                                staticClass: "absolute",
                                staticStyle: { top: "-20px" },
                                model: {
                                  value: _vm.allow_invoice_form_pos,
                                  callback: function ($$v) {
                                    _vm.allow_invoice_form_pos = $$v
                                  },
                                  expression: "allow_invoice_form_pos",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black",
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.invoices.pos_invoice"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 m-0 text-xs leading-tight text-gray-500",
                                staticStyle: { "max-width": "480px" },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.invoices.allow_invoice_form_pos"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "h3",
                          {
                            staticClass: "mb-5 text-lg font-medium text-black",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.invoices.pdf_pos_format"
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex mb-6" }, [
                          _c(
                            "div",
                            { staticClass: "relative w-12" },
                            [
                              _c("sw-switch", {
                                staticClass: "absolute",
                                staticStyle: { top: "-20px" },
                                model: {
                                  value: _vm.pdf_format_pos,
                                  callback: function ($$v) {
                                    _vm.pdf_format_pos = $$v
                                  },
                                  expression: "pdf_format_pos",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black",
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.invoices.pdf_pos_format"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 m-0 text-xs leading-tight text-gray-500",
                                staticStyle: { "max-width": "480px" },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.invoices.change_pdf_pos"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "mb-5 text-lg font-medium text-black",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.core_pos.activate_pay_button"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex mb-6" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.activate_pay_button,
                                    callback: function ($$v) {
                                      _vm.activate_pay_button = $$v
                                    },
                                    expression: "activate_pay_button",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.activate_pay_button"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 m-0 text-xs leading-tight text-gray-500",
                                  staticStyle: { "max-width": "480px" },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.activate_pay_button"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "mb-5 text-lg font-medium text-black",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.core_pos.allow_partial_pay"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex mb-6" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.allow_partial_pay,
                                    callback: function ($$v) {
                                      _vm.allow_partial_pay = $$v
                                    },
                                    expression: "allow_partial_pay",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.allow_partial_pay"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 m-0 text-xs leading-tight text-gray-500",
                                  staticStyle: { "max-width": "480px" },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.allow_partial_pay"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "mb-5 text-lg font-medium text-black",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.core_pos.autoprint_pdf_pos"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex mb-6" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.autoprint_pdf_pos,
                                    callback: function ($$v) {
                                      _vm.autoprint_pdf_pos = $$v
                                    },
                                    expression: "autoprint_pdf_pos",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.autoprint_pdf_pos"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 m-0 text-xs leading-tight text-gray-500",
                                  staticStyle: { "max-width": "480px" },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.core_pos.autoprint_pdf_pos"
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2" })
                                : _vm._e(),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("settings.customization.save")
                                  ) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("sw-divider", { staticClass: "mt-6 mb-8" }),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("settings.core_pos.core_pos_section"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-end mt-8 lg:flex-nowrap",
                  },
                  [
                    _c(
                      "sw-button",
                      {
                        attrs: { size: "lg", variant: "primary-outline" },
                        on: { click: _vm.addSection },
                      },
                      [
                        _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "settings.core_pos.core_pos_create_section"
                              )
                            ) +
                            "\n      "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "tableSection",
                    attrs: {
                      variant: "gray",
                      data: _vm.fetchDataSections,
                      "show-filter": false,
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t(
                          "settings.core_pos.core_pos_section_name"
                        ),
                        show: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.core_pos.core_pos_section_name"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "mt-6" }, [
                                _vm._v(_vm._s(row.name)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.action"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    staticClass: "h-5 mr-3 text-primary-800",
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.editSection(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }