var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c("div", [
                  _c("h6", { staticClass: "sw-section-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("settings.mail.mail_config")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      staticStyle: { "max-width": "680px" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("settings.mail.mail_config_desc")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-shrink-0 ml-4 justify-end" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 hidden xl:block" },
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline" },
                          on: { click: _vm.toggleSideBar },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tickets.departaments.menu")) +
                              "\n              "
                          ),
                          _c(_vm.listIcon, {
                            tag: "component",
                            staticClass: "w-4 h-4 ml-2 -mr-1",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.mailConfigData
            ? _c(
                "div",
                [
                  _c(
                    _vm.mail_driver,
                    {
                      tag: "component",
                      attrs: {
                        "config-data": _vm.mailConfigData,
                        loading: _vm.isLoading,
                        "mail-drivers": _vm.mail_drivers,
                      },
                      on: {
                        "on-change-driver": (val) =>
                          (_vm.mail_driver = _vm.mailConfigData.mail_driver =
                            val),
                        "submit-data": _vm.saveEmailConfig,
                      },
                    },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "primary-outline", type: "button" },
                          on: { click: _vm.openMailTestModal },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.test_mail_conf")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissionModule.update
            ? _c("div", { staticClass: "flex w-full" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-switch", {
                      staticClass: "absolute",
                      staticStyle: { top: "-18px" },
                      on: { change: _vm.setChangeModeTestSettings },
                      model: {
                        value: _vm.send_email_deactive,
                        callback: function ($$v) {
                          _vm.send_email_deactive = $$v
                        },
                        expression: "send_email_deactive",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass: "p-0 mb-1 text-base leading-snug text-black",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("settings.mail.send_email_deactive")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }