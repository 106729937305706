var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sw-card", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitPrefix.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 border border-grey-700 rounded-lg mb-5 p-3",
          },
          [
            _c(
              "sw-input-group",
              { attrs: { label: _vm.$tc("settings.company_info.country") } },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.countries,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_country"),
                    label: "name",
                    "track-by": "id",
                    disabled: _vm.isCountryActive,
                  },
                  model: {
                    value: _vm.formData.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "country", $$v)
                    },
                    expression: "formData.country",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("packages.status"),
                  error: _vm.statusError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.status,
                    searchable: true,
                    "show-labels": false,
                    tabindex: 16,
                    "allow-empty": false,
                    placeholder: _vm.$t("general.select_status"),
                    label: "text",
                    "track-by": "value",
                  },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("expenses.category"),
                  error: _vm.categoryError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.category,
                    invalid: _vm.$v.formData.category.$error,
                    searchable: true,
                    "show-labels": false,
                    tabindex: 16,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_category"),
                    label: "text",
                    "track-by": "value",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.category.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "category", $$v)
                    },
                    expression: "formData.category",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("didFree.item.custom_destination_group"),
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  attrs: { disabled: _vm.isEditPrefixGroup },
                  model: {
                    value: _vm.getDestinationGroups,
                    callback: function ($$v) {
                      _vm.getDestinationGroups = $$v
                    },
                    expression: "getDestinationGroups",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.formData.multiple, function (prefix, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 border border-grey-700 rounded-lg mt-5 p-3",
            },
            [
              _c(
                "sw-input-group",
                {
                  attrs: { label: _vm.$t("corePbx.internacional.prefix_type") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.typecustomOptions,
                      searchable: true,
                      "show-labels": true,
                      "allow-empty": false,
                      placeholder: _vm.$t("corePbx.internacional.prefix_type"),
                      label: "label",
                      "track-by": "value",
                    },
                    model: {
                      value: prefix.typecustom,
                      callback: function ($$v) {
                        _vm.$set(prefix, "typecustom", $$v)
                      },
                      expression: "prefix.typecustom",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              prefix.typecustom.value == "P"
                ? _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("didFree.item.prefijo"),
                        required: "",
                        error: _vm.prefixValidate(
                          _vm.$v.formData.multiple.$each[index].prefijo
                        ),
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          placeholder: _vm.$t("didFree.item.prefijo"),
                          focus: "",
                          type: "text",
                          name: "prefijo",
                          pattern: "[0-9*|A-Za-z *|#|+]+",
                          title:
                            "Numbers, letters, blank space and  special characters (* # +)",
                          tabindex: "1",
                          placer: "",
                          invalid:
                            _vm.$v.formData.multiple.$each[index].prefijo
                              .$error,
                        },
                        model: {
                          value: prefix.prefijo,
                          callback: function ($$v) {
                            _vm.$set(prefix, "prefijo", $$v)
                          },
                          expression: "prefix.prefijo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              prefix.typecustom.value == "FT"
                ? _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("corePbx.internacional.from"),
                        required: "",
                        error: _vm.fromValidate(
                          _vm.$v.formData.multiple.$each[index].from
                        ),
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          placeholder: _vm.$t("corePbx.internacional.from"),
                          focus: "",
                          type: "text",
                          name: "from",
                          pattern: "[0-9*|A-Za-z *|#|+]+",
                          title:
                            "Numbers, letters, blank space and  special characters (* # +)",
                          tabindex: "1",
                          placer: "",
                          invalid:
                            _vm.$v.formData.multiple.$each[index].from.$error,
                        },
                        model: {
                          value: prefix.from,
                          callback: function ($$v) {
                            _vm.$set(prefix, "from", $$v)
                          },
                          expression: "prefix.from",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              prefix.typecustom.value == "FT"
                ? _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("corePbx.internacional.to") } },
                    [
                      _c("sw-input", {
                        attrs: {
                          placeholder: _vm.$t("corePbx.internacional.to"),
                          focus: "",
                          type: "text",
                          name: "to",
                          pattern: "[0-9*|A-Za-z *|#|+]+",
                          title:
                            "Numbers, letters, blank space and  special characters (* # +)",
                          tabindex: "1",
                          placer: "",
                        },
                        model: {
                          value: prefix.to,
                          callback: function ($$v) {
                            _vm.$set(prefix, "to", $$v)
                          },
                          expression: "prefix.to",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("didFree.item.name"),
                    required: "",
                    error: _vm.nameValidate(
                      _vm.$v.formData.multiple.$each[index].name
                    ),
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      placeholder: _vm.$t("didFree.item.name"),
                      focus: "",
                      type: "text",
                      name: "name",
                      tabindex: "1",
                      placer: "",
                      invalid:
                        _vm.$v.formData.multiple.$each[index].name.$error,
                    },
                    model: {
                      value: prefix.name,
                      callback: function ($$v) {
                        _vm.$set(prefix, "name", $$v)
                      },
                      expression: "prefix.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("corePbx.packages.rate_per_minutes"),
                    required: "",
                  },
                },
                [
                  _c("sw-money", {
                    attrs: {
                      currency: _vm.defaultCurrency,
                      name: "rate_per_minutes_selected",
                      invalid:
                        _vm.$v.formData.multiple.$each[index].rate_per_minutes
                          .$error,
                    },
                    model: {
                      value: prefix.rate_per_minutes,
                      callback: function ($$v) {
                        _vm.$set(prefix, "rate_per_minutes", $$v)
                      },
                      expression: "prefix.rate_per_minutes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "md:col-span-2 xl:col-span-3 flex justify-end" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        variant: "primary",
                        type: "submit",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2" })
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            !_vm.isEditPrefix
                              ? _vm.$t("general.save")
                              : _vm.$t("general.update")
                          ) +
                          "\n    "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }