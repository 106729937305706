var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoice
    ? _c("base-page", { staticClass: "xl:pl-96" }, [
        _c(
          "div",
          { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
          [
            _c("sw-page-header", { attrs: { title: _vm.pageTitle } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex flex-wrap items-center justify-end" },
              [
                _c("div", { staticClass: "mr-3 text-sm" }),
                _vm._v(" "),
                _vm.invoice.status != "COMPLETED" &&
                _vm.invoice.status != "SAVE_DRAFT"
                  ? _c(
                      "sw-button",
                      {
                        staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                        attrs: {
                          to: `/customer/payments/${_vm.$route.params.id}/create`,
                          "tag-name": "router-link",
                          variant: "primary",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("payments.make_payment")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "sw-dropdown",
                  { staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass:
                          "h-10 w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                        attrs: { slot: "activator", variant: "primary" },
                        slot: "activator",
                      },
                      [_c("dots-horizontal-icon", { staticClass: "h-5" })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown-item",
                      { on: { click: _vm.copyPdfUrl } },
                      [
                        _c("link-icon", {
                          staticClass: "h-5 mr-3 text-gray-600",
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.copy_pdf_url")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _vm._l(this.payments, function (item, index) {
                      return _c(
                        "sw-dropdown-item",
                        {
                          key: index,
                          attrs: {
                            to: `/customer/payments/${item.id}/view`,
                            "tag-name": "router-link",
                          },
                        },
                        [
                          _c("credit-card-icon", {
                            staticClass: "h-5 mr-3 text-primary-800",
                          }),
                          _vm._v(
                            "\n          Go to: " +
                              _vm._s(item.payment_number) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full",
              },
              [
                _c(
                  "sw-input",
                  {
                    staticClass: "mb-6",
                    attrs: {
                      placeholder: _vm.$t("general.search"),
                      type: "text",
                      variant: "gray",
                    },
                    on: { input: _vm.onSearch },
                    model: {
                      value: _vm.searchData.searchText,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "searchText", $$v)
                      },
                      expression: "searchData.searchText",
                    },
                  },
                  [
                    _c("search-icon", {
                      staticClass: "h-5",
                      attrs: { slot: "rightIcon" },
                      slot: "rightIcon",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isSearching
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll",
              },
              [
                _vm._l(_vm.invoices, function (invoice, index) {
                  return _c(
                    "router-link",
                    {
                      key: index,
                      class: [
                        "flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                        {
                          "bg-gray-100 border-l-4 border-primary-500 border-solid":
                            _vm.hasActiveUrl(invoice.id),
                        },
                      ],
                      staticStyle: {
                        "border-bottom": "1px solid rgba(185, 193, 209, 0.41)",
                      },
                      attrs: {
                        to: `/customer/invoice/${invoice.id}/view`,
                        id: "invoice-" + invoice.id,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.formatName(invoice.user.name)) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(invoice.invoice_number) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-1 text-xs",
                              attrs: {
                                "bg-color": _vm.$utils.getBadgeStatusColor(
                                  invoice.status
                                ).bgColor,
                                color: _vm.$utils.getBadgeStatusColor(
                                  invoice.status
                                ).color,
                                "font-size": _vm.$utils.getBadgeStatusColor(
                                  invoice.status
                                ).fontSize,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(invoice.status) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("invoices.total"),
                          "sort-as": "total",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("invoices.total"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-1 whitespace-nowrap right" },
                        [
                          _c("div", {
                            staticClass:
                              "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.formatMoney(
                                  invoice.due_amount,
                                  invoice.user.currency
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(invoice.formattedInvoiceDate) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                !_vm.invoices.length
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "flex justify-center px-4 mt-5 text-sm text-gray-600",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("invoices.no_matching_invoices")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex flex-col min-h-0 mt-8 overflow-hidden",
            staticStyle: { height: "75vh" },
          },
          [
            _c("iframe", {
              staticClass:
                "flex-1 border border-gray-400 border-solid rounded-md frame-style",
              attrs: { src: `${_vm.shareableLink}` },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }