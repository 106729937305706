var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitData.apply(null, arguments)
        },
      },
    },
    [
      _c("sw-page-header", {
        staticClass: "mb-3",
        attrs: { title: _vm.title },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "sw-card",
              { staticClass: "mb-8" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("core_pos.name"),
                      error: _vm.nameError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("core_pos.description"),
                      error: _vm.descriptionError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.description.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "description", $$v)
                        },
                        expression: "formData.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                  },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3",
                        attrs: { variant: "primary-outline", type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.cancel()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      { attrs: { variant: "primary", type: "submit" } },
                      [
                        _c("save-icon", { staticClass: "mr-2" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.save")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "tab-content" }, [
          _c("div", { staticClass: "text-grey-darkest" }, [
            _c("ul", { staticClass: "pl-0" }, [
              _c(
                "li",
                { staticClass: "pb-2" },
                [
                  _c(
                    "sw-tabs",
                    { attrs: { "active-tab": _vm.activeTab } },
                    [
                      _c("sw-tab-item", { attrs: { title: "ITEMS" } }, [
                        _c(
                          "table",
                          { staticClass: "w-full text-center item-table" },
                          [
                            _c("colgroup", [
                              _c("col", { staticStyle: { width: "50%" } }),
                              _vm._v(" "),
                              _c("col", { staticStyle: { width: "20%" } }),
                              _vm._v(" "),
                              _c("col", { staticStyle: { width: "30%" } }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "thead",
                              {
                                staticClass:
                                  "bg-white border border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c("span", { staticClass: "pl-12" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.$tc("items.item", 2)) +
                                          "\n                      "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$t("item_groups.item.unit")
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c("span", { staticClass: "pr-10" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("item_groups.item.price")
                                          ) +
                                          "\n                      "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              {
                                staticClass: "item-body",
                                attrs: { tag: "tbody", handle: ".handle" },
                                model: {
                                  value: _vm.formData.items,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "items", $$v)
                                  },
                                  expression: "formData.items",
                                },
                              },
                              _vm._l(
                                _vm.formData.items,
                                function (items, index) {
                                  return _c("items-group-item", {
                                    key: items.id,
                                    attrs: {
                                      index: index,
                                      "item-data": items,
                                      "group-items": _vm.formData.items,
                                      currency: _vm.currency,
                                    },
                                    on: {
                                      remove: _vm.removeItem,
                                      update: _vm.updateItem,
                                      itemValidate: _vm.checkItemsData,
                                      checkExists: _vm.checkExistItem,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                            on: { click: _vm.addItem },
                          },
                          [
                            _c("shopping-cart-icon", {
                              staticClass: "h-5 mr-2",
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("item_groups.add_item")) +
                                "\n                "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("sw-tab-item", { attrs: { title: "ITEMS GROUP" } }, [
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                          [
                            _c("sw-divider", { staticClass: "col-span-12" }),
                            _vm._v(" "),
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "col-span-5 sw-section-title lg:col-span-1",
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("packages.packages_items")) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                              },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:col-span-3",
                                    attrs: {
                                      label: _vm.$t("packages.item_groups"),
                                    },
                                  },
                                  [
                                    _c("sw-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        options: _vm.item_groups,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        placeholder: _vm.$tc(
                                          "packages.item_groups_select"
                                        ),
                                        label: "name",
                                        "track-by": "id",
                                        tabindex: 11,
                                      },
                                      on: { select: _vm.itemGroupSelected },
                                      model: {
                                        value: _vm.item_group,
                                        callback: function ($$v) {
                                          _vm.item_group = $$v
                                        },
                                        expression: "item_group",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                undefined !== _vm.formData.item_groups &&
                                _vm.formData.item_groups.length > 0
                                  ? _c("div", { staticClass: "col-span-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-wrap justify-start",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "relative table-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("p", {
                                                    staticClass: "text-sm",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "sw-table-component",
                                                {
                                                  ref: "table",
                                                  attrs: {
                                                    data: _vm.formData
                                                      .item_groups,
                                                    "show-filter": false,
                                                    "table-class": "table",
                                                  },
                                                },
                                                [
                                                  _c("sw-table-column", {
                                                    staticStyle: {
                                                      width: "50%",
                                                    },
                                                    attrs: {
                                                      sortable: true,
                                                      label:
                                                        _vm.$t("core_pos.name"),
                                                      show: "name",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (row) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "core_pos.name"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n                                " +
                                                                  _vm._s(
                                                                    row.name
                                                                  ) +
                                                                  "\n                              "
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3576645596
                                                    ),
                                                  }),
                                                  _vm._v(" "),
                                                  _c("sw-table-column", {
                                                    staticStyle: {
                                                      width: "50%",
                                                    },
                                                    attrs: {
                                                      sortable: true,
                                                      label:
                                                        _vm.$t(
                                                          "core_pos.action"
                                                        ),
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (row) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "core_pos.action"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-6 h-4 ml-2 pr-1",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                    fill: "none",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    stroke:
                                                                      "currentColor",
                                                                    "stroke-width":
                                                                      "4",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeItemGroup(
                                                                          row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("line", {
                                                                    attrs: {
                                                                      x1: "18",
                                                                      y1: "6",
                                                                      x2: "6",
                                                                      y2: "18",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("line", {
                                                                    attrs: {
                                                                      x1: "6",
                                                                      y1: "6",
                                                                      x2: "18",
                                                                      y2: "18",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3348796125
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }