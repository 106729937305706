var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-64": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("tickets.menu_title.departaments") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("tickets.menu_title.departaments", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/packages`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-3 hidden xl:block" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleListCustomers },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("tickets.departaments.menu")) +
                          "\n          "
                      ),
                      _c(_vm.listIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "/admin/corePBX/reports/departaments/create",
                    variant: "primary",
                    size: "lg",
                  },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("tickets.departaments.add_new_departament")
                      ) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 mr-4",
                  attrs: { label: _vm.$tc("packages.filter.name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("tickets.departaments.no_packages"),
            description: _vm.$t("tickets.departaments.list_of_departaments"),
          },
        },
        [
          _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "departaments/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("tickets.departaments.add_new_departament")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.departaments.length))]),
                _vm._v(
                  "\n\n        " + _vm._s(_vm.$t("general.of")) + "\n\n        "
                ),
                _c("b", [_vm._v(_vm._s(_vm.totalDepartaments))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("tickets.departaments.name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tickets.departaments.name"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: `/admin/corePBX/reports/departaments/${row.id}/edit`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("tickets.departaments.creator"),
                  show: "creator",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("tickets.departaments.creator"))
                          ),
                        ]),
                        _vm._v(" "),
                        row.create_user.role == "customer"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: `/admin/customers/${row.create_user.id}/view`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("general.customer")) +
                                    ": " +
                                    _vm._s(row.create_user.customcode) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: `/admin/users/${row.create_user.id}/view`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("general.user")) +
                                    ": " +
                                    _vm._s(row.create_user.name) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("tickets.departaments.description"),
                  show: "description",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return row.description !== null
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.description.replace(/(<([^>]+)>)/gi, "")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("packages.action")) + " "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: `/admin/corePBX/reports/departaments/${row.id}/edit`,
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeDepartament(row.id)
                                  },
                                },
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }