var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "payments" },
    [
      _c("div", { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" }, [
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c("sw-page-header", { attrs: { title: "Document" } }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [_vm._v("\n        Click on the photos for more options\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center justify-end" },
          [
            _c(
              "sw-button",
              {
                staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                attrs: {
                  "tag-name": "router-link",
                  to: `/admin/customers/${_vm.$route.params.id}/view`,
                  variant: "primary-outline",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customer_address.clientgoback")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      false
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("customer_address.no_customer_address"),
                description: _vm.$t(
                  "customer_address.list_of_customer_address"
                ),
              },
            },
            [
              _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/add-address`,
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("customer_address.new_address")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: "Front Document",
                  show: "frontImage",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", { staticClass: "relative inline-block" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center md:flex-row md:items-start",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "cursor-pointer object-cover img-border",
                                attrs: {
                                  src: row.frontImage,
                                  alt: "Back Document",
                                  width: "100",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdown(row.id, "front")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2 md:mt-0" }, [
                                _vm.isDropdownOpen(row.id, "front")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: {
                                              href: row.frontImage,
                                              download: "back_document.png",
                                            },
                                          },
                                          [
                                            _c("DownloadIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" Download"),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.viewImage(
                                                  row.frontImage
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("EyeIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" View Large"),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: "Back Document",
                  show: "backImage",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", { staticClass: "relative inline-block" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center md:flex-row md:items-start",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "cursor-pointer object-cover img-border",
                                attrs: {
                                  src: row.backImage,
                                  alt: "Back Document",
                                  width: "100",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdown(row.id, "back")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2 md:mt-0" }, [
                                _vm.isDropdownOpen(row.id, "back")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: {
                                              href: row.backImage,
                                              download: "back_document.png",
                                            },
                                          },
                                          [
                                            _c("DownloadIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" Download"),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.viewImage(
                                                  row.backImage
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("EyeIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" View Large"),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: "Selfie",
                  show: "selfieImage",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", { staticClass: "relative inline-block" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center md:flex-row md:items-start",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "cursor-pointer object-cover img-border",
                                attrs: {
                                  src: row.selfieImage,
                                  alt: "Selfie Document",
                                  width: "100",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdown(row.id, "selfie")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2 md:mt-0" }, [
                                _vm.isDropdownOpen(row.id, "selfie")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: {
                                              href: row.selfieImage,
                                              download: "back_document.png",
                                            },
                                          },
                                          [
                                            _c("DownloadIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" Download"),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.viewImage(
                                                  row.selfieImage
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("EyeIcon", {
                                              staticClass: "w-5 h-5 mr-2",
                                            }),
                                            _vm._v(" View Large"),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: "Added on",
                  show: "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.formattedCreatedAt
                                ? row.formattedCreatedAt
                                : row.created_at
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "div",
            {
              staticClass:
                "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "bg-white p-4 rounded shadow-lg max-w-lg w-full relative",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "absolute top-2 right-2 text-gray-800 hover:text-gray-900 bg-gray-200 p-2 rounded-full",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("\n        ×\n      ")]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "w-full h-auto",
                    attrs: { src: _vm.modalImage, alt: "Large View" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }