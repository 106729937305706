var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c("base-page", { staticClass: "item-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitPaypal.apply(null, arguments)
              },
            },
          },
          [
            _vm.isRequestOnGoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-page-header",
              { attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$tc("settings.payment_gateways.title", 2),
                        to: "/admin/settings/payment-gateways",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$tc("paypal.title"),
                        to: "/admin/settings/paypal",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "paypal.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            title: _vm.$t("paypal.edit_paypal"),
                            to: "#",
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            title: _vm.$t("paypal.new_paypal"),
                            to: "#",
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("paypal.gateway_options")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("sw-divider", { staticClass: "col-span-12" }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-6",
                      attrs: { label: _vm.$t("authorize.name"), required: "" },
                    },
                    [
                      _c("sw-input", {
                        attrs: { focus: "", type: "text", name: "name" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: {
                        label: _vm.$t("paypal.merchant_id"),
                        error: _vm.merchantIdError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.merchant_id.$error,
                          focus: "",
                          type: "text",
                          name: "merchant_id",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.merchant_id.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.merchant_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "merchant_id", $$v)
                          },
                          expression: "formData.merchant_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: {
                        label: _vm.$t("paypal.public_key"),
                        error: _vm.publicKeyError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.public_key.$error,
                          focus: "",
                          type: "text",
                          name: "public_key",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.public_key.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.public_key,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "public_key", $$v)
                          },
                          expression: "formData.public_key",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: {
                        label: _vm.$t("paypal.private_key"),
                        error: _vm.privateKeyError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.private_key.$error,
                          focus: "",
                          type: "password",
                          name: "private_key",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.private_key.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.private_key,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "private_key", $$v)
                          },
                          expression: "formData.private_key",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: {
                        label: _vm.$t("paypal.email"),
                        error: _vm.emailError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.email.$error,
                          focus: "",
                          type: "email",
                          name: "email",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.email.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "email", $$v)
                          },
                          expression: "formData.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: {
                        label: _vm.$t("paypal.enviroment"),
                        error: _vm.enviromentError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.enviromentOptions,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 16,
                          "allow-empty": true,
                          label: "text",
                          "track-by": "value",
                          invalid: _vm.$v.formData.enviroment.$error,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.enviroment.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.enviroment,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "enviroment", $$v)
                          },
                          expression: "formData.enviroment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("sw-divider", {
                    staticClass: "my-0 col-span-12 opacity-0",
                  }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: { label: _vm.$t("paypal.currency") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.currency,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 16,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_currency"),
                          label: "text",
                          "track-by": "value",
                        },
                        model: {
                          value: _vm.formData.currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "currency", $$v)
                          },
                          expression: "formData.currency",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-4",
                      attrs: { label: _vm.$t("paypal.status") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.status,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 16,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_status"),
                          label: "text",
                          "track-by": "value",
                        },
                        model: {
                          value: _vm.formData.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t(
                          "authorize.enable_identification_verification"
                        ),
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("sw-switch", {
                        staticClass: "-mt-3",
                        model: {
                          value:
                            _vm.formData.enable_identification_verification,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "enable_identification_verification",
                              $$v
                            )
                          },
                          expression:
                            "formData.enable_identification_verification",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-12",
                      attrs: {
                        label: _vm.$t("authorize.enable_fee_charges"),
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("sw-switch", {
                        staticClass: "-mt-3",
                        model: {
                          value: _vm.formData.enable_fee_charges,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "enable_fee_charges", $$v)
                          },
                          expression: "formData.enable_fee_charges",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-6 mb-4" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "flex justify-center w-full md:w-auto",
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("paypal.update_paypal")
                            : _vm.$t("paypal.save_paypal")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }