<template>
  <div class="fixed top-0 left-0 hidden h-full pt-16 pb-4 ml-56 bg-white xl:ml-64 w-64 xl:block">
    <div class="p-5">
      <sw-page-header :title="$tc('general.core_pos', 1)">
        <sw-breadcrumb slot="breadcrumbs">
          <sw-breadcrumb-item :title="$t('general.home')" to="/admin/dashboard" />
          <sw-breadcrumb-item :title="$tc('general.core_pos', 2)" to="#" active />
        </sw-breadcrumb>
      </sw-page-header>
      <!-- Lista de elementos del menú -->
      <sw-list v-for="(menuItem, index) in menuItems" :title="$t(menuItem.title)" :key="index">
        <!-- MENU PRINCIPAL -->
        <sw-list-item :title="$t(menuItem.title)" :to="menuItem.link" :active="hasActiveUrl(menuItem.link)"
          tag-name="router-link" class="py-3">
          <component slot="icon" :is="menuItem.icon" class="h-5" />
        </sw-list-item>
        <span v-if="menuItem.subMenuItems != null">
          <!-- SUB MENÚ -->
          <sw-list-item v-for="(subMenuItem, idx) in menuItem.subMenuItems" :title="$t(subMenuItem.title)" :key="idx"
            :to="subMenuItem.link" :active="hasActiveUrl(subMenuItem.link)" tag-name="router-link" class="px-5">
            <component slot="icon" :is="subMenuItem.icon" class="h-5" />
          </sw-list-item>
        </span>
      </sw-list>

    </div>
    <!-- -------------------------------- -->
    <!-- Contenido adicional en modo móvil -->
    <div class="pb-6 xl:hidden">
      <sw-page-header :title="$tc('tickets.title', 1)">
        <sw-breadcrumb slot="breadcrumbs">
          <sw-breadcrumb-item :title="$t('general.home')" to="/admin/dashboard" />
          <sw-breadcrumb-item :title="$tc('tickets.tickets', 2)" to="#" active />
        </sw-breadcrumb>
      </sw-page-header>
    </div>
    <!--  <div class="pb-6">
        {{ $t('corePbx.pbx') }}
        </div> -->
    <!-- Selector en modo móvil para cambiar de PBX -->
    <div class="w-full mb-6 select-wrapper xl:hidden">
      <sw-select :options="menuItems" v-model="currentPbx" :searchable="true" :show-labels="false" :allow-empty="false"
        :custom-label="getCustomLabel" @input="navigateToPBX" />
    </div>
    <!-- Lista de elementos del menú en modo móvil -->
    <!-- <div class="hidden mt-1 xl:block"> -->
    <div class="hidden mt-1 xl:hidden">
      <sw-list v-for="(menuItem, index) in menuItems" :title="$t(menuItem.title)" :key="index">
        <!-- MENU PRINCIPAL -->
        <sw-list-item :title="$t(menuItem.title)" :to="menuItem.link" :active="hasActiveUrl(menuItem.link)"
          tag-name="router-link" class="py-3">
          <component slot="icon" :is="menuItem.icon" class="h-5" />
        </sw-list-item>
        <span v-if="menuItem.subMenuItems != null">
          <!-- SUB MENÚ -->
          <sw-list-item v-for="(subMenuItem, idx) in menuItem.subMenuItems" :title="$t(subMenuItem.title)" :key="idx"
            :to="subMenuItem.link" :active="hasActiveUrl(subMenuItem.link)" tag-name="router-link" class="px-5">
            <component slot="icon" :is="subMenuItem.icon" class="h-5" />
          </sw-list-item>
        </span>
      </sw-list>
    </div>
  </div>
</template>

<script>
import {
  UserIcon,
  OfficeBuildingIcon,
  BellIcon,
  CheckCircleIcon,
  ClipboardListIcon,
  CubeIcon,
  ClipboardCheckIcon,
  EyeIcon,
  CollectionIcon,
  ViewGridIcon,
} from '@vue-hero-icons/outline'

import {
  RefreshIcon,
  CogIcon,
  MailIcon,
  PencilAltIcon,
  CloudUploadIcon,
  FolderIcon,
  DatabaseIcon,
  CreditCardIcon,
  BadgeCheckIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    UserIcon,
    OfficeBuildingIcon,
    PencilAltIcon,
    CogIcon,
    CheckCircleIcon,
    ClipboardListIcon,
    MailIcon,
    BellIcon,
    FolderIcon,
    RefreshIcon,
    CubeIcon,
    CloudUploadIcon,
    DatabaseIcon,
    CreditCardIcon,
    ClipboardCheckIcon,
    BadgeCheckIcon,
    EyeIcon,
    CollectionIcon,
    ViewGridIcon,
  },

  data() {
    return {
      currentPbx: {
        link: '/admin/corePOS',
        title: 'general.core_pos_main',
        icon: 'collection-icon',
      },
      // Elementos del menú
      menuItems: [
        {
          link: '/admin/corePOS/main',
          title: 'general.core_pos_main',
          icon: 'collection-icon',
        },
        {
          link: '/admin/corePOS/stores',
          title: 'general.stores',
          icon: 'collection-icon',
        },
        {
          link: '/admin/corePOS/cash-register',
          title: 'general.cash_register',
          icon: 'collection-icon',
        },
        {
          link: '/admin/corePOS/tables',
          title: 'general.tables',
          icon: 'collection-icon',
        },
        {
          link: '/admin/corePOS/money',
          title: 'general.money',
          icon: 'collection-icon',
        },
        {
          link: '/admin/corePOS/corepos-history',
          title: 'general.corepos_history',
          icon: 'collection-icon',
        }
      ],
    }
  },
  mounted() {
    this.currentPbx = this.menuItems.find(
      (item) => item.link == this.$route.path
    )
  },

  created() {
    if (this.$route.path === '/admin/corePOS') {
      this.$router.push('/admin/corePOS/cash-register')
    }
  },

  methods: {
    getCustomLabel({ title }) {
      return this.$t(title)
    },
    hasActiveUrl(url) {
      return this.$route.path.indexOf(url) > -1
    },
    navigateToPBX(pbx) {
      this.$router.push(pbx.link)
    },
  },
}
</script>
