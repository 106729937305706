var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-8 py-8 sm:p-6" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_name"),
                    error: _vm.nameError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("login.email"),
                    error: _vm.emailError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-1 md:mt-0",
                    attrs: {
                      invalid: _vm.$v.formData.email.$error,
                      type: "text",
                      name: "email",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.email.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_extension"),
                    error: _vm.extError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.ext.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.ext.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.ext,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "ext", $$v)
                      },
                      expression: "formData.ext",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_status"),
                    variant: "horizontal",
                    error: _vm.statusError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      invalid: _vm.$v.formData.status.$error,
                      options: _vm.statuses,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      "max-height": 200,
                      placeholder: _vm.$t("pbx_services.ext_select_status"),
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.status.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("pbx_services.ext_pin"),
                    error: _vm.pinError,
                    variant: "horizontal",
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.pin.$error,
                      type: "text",
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      pattern: "[0-9]{4}",
                      maxlength: "4",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.pin.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.pin,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "pin", $$v)
                      },
                      expression: "formData.pin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "primary-outline", type: "button" },
                      on: { click: _vm.closeExtensionModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    { attrs: { variant: "primary", type: "submit" } },
                    [
                      _c("save-icon", { staticClass: "mr-2" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.save")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }