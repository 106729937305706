var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "pt-6 mt-5 border-t-2 border-solid lg:pt-8 md:pt-4": !_vm.isServiceView,
      },
      style: { "border-top-color: #f9fbff": !_vm.isServiceView },
    },
    [
      _c("div", { staticClass: "col-span-12" }, [
        _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("customers.basic_info")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
          },
          [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.customer_number")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.customcode
                          ? _vm.selectedViewCustomer.customer.customcode
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.selectedViewCustomer.customer &&
              _vm.selectedViewCustomer.customer.customer_type == "B"
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("customers.display_name")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.selectedViewCustomer.customer &&
                                _vm.selectedViewCustomer.customer.name
                                ? _vm.selectedViewCustomer.customer.name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedViewCustomer.customer &&
              _vm.selectedViewCustomer.customer.customer_type == "R"
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("customers.first_name")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.selectedViewCustomer.customer &&
                                _vm.selectedViewCustomer.customer.first_name
                                ? _vm.selectedViewCustomer.customer.first_name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.selectedViewCustomer.customer &&
              _vm.selectedViewCustomer.customer.customer_type == "B"
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("customers.primary_contact_name")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.selectedViewCustomer.customer &&
                                _vm.selectedViewCustomer.customer.contact_name
                                ? _vm.selectedViewCustomer.customer.contact_name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedViewCustomer.customer &&
              _vm.selectedViewCustomer.customer.customer_type == "R"
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("customers.last_name")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.selectedViewCustomer.customer &&
                                _vm.selectedViewCustomer.customer.last_name
                                ? _vm.selectedViewCustomer.customer.last_name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.$route.name === "customers.view"
          ? _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.email")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.email
                              ? _vm.selectedViewCustomer.customer.email
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("wizard.currency")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.currency
                              ? `${_vm.selectedViewCustomer.customer.currency.code} (${_vm.selectedViewCustomer.customer.currency.symbol})`
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.phone_number")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.phone
                              ? _vm.selectedViewCustomer.customer.phone
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.type_customer")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                      staticStyle: { "text-transform": "capitalize" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.status_payment
                              ? _vm.selectedViewCustomer.customer.status_payment
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.website")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.website
                              ? _vm.selectedViewCustomer.customer.website
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.security_pin")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectedViewCustomer.customer &&
                              _vm.selectedViewCustomer.customer.security_pin
                              ? _vm.selectedViewCustomer.customer.security_pin
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        (_vm.getFormattedShippingAddress.length ||
          _vm.getFormattedBillingAddress.length) &&
        _vm.$route.name === "customers.view"
          ? _c(
              "p",
              { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("customers.address")) + "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.name === "customers.view" ||
        _vm.$route.name === "customers.add-corepbx-services"
          ? _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2",
              },
              [
                _vm.getFormattedBillingAddress.length
                  ? _c("div", { staticClass: "mt-5" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.billing_address")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedBillingAddress),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getFormattedShippingAddress.length
                  ? _c("div", { staticClass: "mt-5" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.shipping_address")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedShippingAddress),
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.getCustomField.length > 0 && _vm.$route.name === "customers.view"
          ? _c(
              "p",
              { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.custom_fields.title")) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.name === "customers.view"
          ? _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              _vm._l(_vm.getCustomField, function (field, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    attrs: { required: field.is_required ? true : false },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(field.custom_field.label) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    field.type === "Switch"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            field.defaultAnswer === 1
                              ? _c("span", [_vm._v(" Yes ")])
                              : _c("span", [_vm._v(" No ")]),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(field.defaultAnswer) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.getStickyNotes.length > 0 && _vm.$route.name === "customers.view"
          ? _c(
              "p",
              { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("customers.sticky_notes")) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$route.name === "customers.view"
          ? _c(
              "div",
              {
                staticClass: "grid grid-cols-4 gap-3 mt-5",
                staticStyle: { "grid-template-columns": "repeat(4, 1fr)" },
              },
              _vm._l(_vm.getStickyNotes, function (note, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "flex",
                    staticStyle: {
                      border: "1px solid #ccc",
                      "border-radius": "10px",
                      padding: "10px",
                      "background-color": "rgba(255, 255, 255, 0.9)",
                      "box-shadow": "0 0 10px rgba(255, 255, 255, 0.5)",
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            staticStyle: {
                              border: "1px solid var(--v-primary-base)",
                            },
                            attrs: {
                              to: {
                                path: `/admin/customers/${note.user_id}/${note.id}/edit-note`,
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(note.summary) +
                                " by " +
                                _vm._s(note.creator) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center w-6 h-5 cursor-pointer",
                      },
                      [
                        _c("x-icon", {
                          staticClass: "h-5 text-gray-900",
                          on: {
                            click: function ($event) {
                              return _vm.removeNote(note.id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessNormalServices
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
                      staticStyle: { "border-top-color": "#f9fbff" },
                    },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck1" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck1" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("customers.services")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _c(
                                "div",
                                { staticClass: "text-grey-darkest" },
                                [
                                  _c(
                                    "sw-tabs",
                                    {
                                      attrs: { "active-tab": _vm.activeTab },
                                      on: { update: _vm.setStatusFilter },
                                    },
                                    [
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.active"),
                                          filter: "A",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.pending"),
                                          filter: "P",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.suspend"),
                                          filter: "S",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.cancelled"),
                                          filter: "C",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "services_table",
                                  staticStyle: { "min-height": "300px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchServicesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "code",
                                      label: _vm.$t("services.service_number"),
                                      show: "code",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "services.service_number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.permissionModule
                                                .readNormalServices
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "font-medium text-primary-500",
                                                      attrs: {
                                                        to: `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(row.code) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(row.code) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1352191779
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "id",
                                      label: _vm.$t(
                                        "customers.applicable_service"
                                      ),
                                      show: "package.name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "total",
                                      label: _vm.$t("customers.amount"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("customers.amount")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.total,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      494947999
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("customers.term"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("customers.term")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.capitalizeFirstLetter(
                                                      row.term
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3884038541
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "activation_date",
                                      label: _vm.$t(
                                        "customers.activation_date"
                                      ),
                                      show: "formattedActivationDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "renewal_date",
                                      label: _vm.$t("customers.renewal_date"),
                                      show: "formattedRenewalDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown no-click",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("general.actions")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .createInvoices
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "invoices.create",
                                                              query: {
                                                                from: "customer",
                                                                code: row.code,
                                                                customer_packages_id:
                                                                  row.id,
                                                                customer_id:
                                                                  row.customer_id,
                                                                package_id:
                                                                  row.package_id,
                                                              },
                                                            },
                                                            "tag-name":
                                                              "router-link",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "calculator-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.new_invoice"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readNormalServices
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            to: `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                                            "tag-name":
                                                              "router-link",
                                                          },
                                                        },
                                                        [
                                                          _c("cog-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.manage"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status != "C"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm.permissionModule
                                                            .updateNormalServices
                                                            ? _c(
                                                                "sw-dropdown-item",
                                                                {
                                                                  attrs: {
                                                                    to: `/admin/customers/${row.customer_id}/service/${row.id}/edit`,
                                                                    "tag-name":
                                                                      "router-link",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "pencil-icon",
                                                                    {
                                                                      staticClass:
                                                                        "h-5 mr-3 text-gray-600",
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "general.edit"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      985436243
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessPBXServices && this.pbxserviceactive
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck4" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck4" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("customers.services_pbx")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _c(
                                "div",
                                { staticClass: "text-grey-darkest" },
                                [
                                  _c(
                                    "sw-tabs",
                                    {
                                      attrs: {
                                        "active-tab": _vm.activeServicesPbxTab,
                                      },
                                      on: {
                                        update: _vm.setServicesPbxStatusFilter,
                                      },
                                    },
                                    [
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.active"),
                                          filter: "A",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.pending"),
                                          filter: "P",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.suspend"),
                                          filter: "S",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("customers.cancelled"),
                                          filter: "C",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "pbx_services_table",
                                  staticStyle: { "min-height": "300px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchPbxServicesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("services.service_number"),
                                      show: "pbx_services_number",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "services.service_number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.permissionModule
                                                .readPBXServices
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "font-medium text-primary-500",
                                                      attrs: {
                                                        to: `/admin/customers/${row.customer_id}/pbx-service/${row.id}/view`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            row.pbx_services_number
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.pbx_services_number
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      226748125
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      label: _vm.$tc("packages.package", 1),
                                      show: "pbx_package.pbx_package_name",
                                      sortable: true,
                                      "sort-as": "id",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("customers.amount"),
                                      "sort-as": "total",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("customers.amount")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.total,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      494947999
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("customers.term"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("customers.term")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.capitalizeFirstLetter(
                                                      row.term
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3884038541
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      label: _vm.$t(
                                        "customers.activation_date"
                                      ),
                                      show: "formattedActivationDate",
                                      sortable: true,
                                      "sort-as": "date_begin",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      label: _vm.$t("customers.renewal_date"),
                                      show: "formattedRenewalDate",
                                      sortable: true,
                                      "sort-as": "renewal_date",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown no-click",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("general.actions")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .createInvoices
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "invoices.create",
                                                              query: {
                                                                from: "pbx_services",
                                                                code: row.pbx_services_number,
                                                                pbx_service_id:
                                                                  row.id,
                                                                customer_id:
                                                                  row.customer_id,
                                                                package_id:
                                                                  row.pbx_package_id,
                                                              },
                                                            },
                                                            "tag-name":
                                                              "router-link",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "calculator-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.new_invoice"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readPBXServices
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            to: `/admin/customers/${row.customer_id}/pbx-service/${row.id}/view`,
                                                            "tag-name":
                                                              "router-link",
                                                          },
                                                        },
                                                        [
                                                          _c("cog-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.manage"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status != "C"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm.permissionModule
                                                            .updatePBXServices
                                                            ? _c(
                                                                "sw-dropdown-item",
                                                                {
                                                                  attrs: {
                                                                    to: `/admin/customers/${row.customer_id}/pbx-service/${row.id}/edit`,
                                                                    "tag-name":
                                                                      "router-link",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "pencil-icon",
                                                                    {
                                                                      staticClass:
                                                                        "h-5 mr-3 text-gray-600",
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "general.edit"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      882968585
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessInvoices
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-8" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck2" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck2" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tc("invoices.invoice", 2)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _c(
                                "div",
                                { staticClass: "text-grey-darkest" },
                                [
                                  _c(
                                    "sw-tabs",
                                    {
                                      attrs: {
                                        "active-tab": _vm.activeInvoiceTab,
                                      },
                                      on: {
                                        update: _vm.setInvoiceStatusFilter,
                                      },
                                    },
                                    [
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.all"),
                                          filter: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.due"),
                                          filter: "DUE",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.draft"),
                                          filter: "DRAFT",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t(
                                            "general.save_as_draft"
                                          ),
                                          filter: "SAVE_DRAFT",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.overdue"),
                                          filter: "OVERDUE",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.completed"),
                                          filter: "COMPLETED",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.archived"),
                                          filter: "ARCHIVED",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "invoices_table",
                                  staticStyle: { "min-height": "300px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchInvoicesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("invoices.date"),
                                      "sort-as": "invoice_date",
                                      show: "formattedInvoiceDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "invoice_number",
                                      label: _vm.$t("invoices.number"),
                                      show: "invoice_number",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("invoices.number")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.permissionModule.readInvoices
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "font-medium text-primary-500",
                                                      attrs: {
                                                        to: {
                                                          path: `/admin/invoices/${row.id}/view`,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            row.invoice_number
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.invoice_number
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1224254587
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      "sort-as": "status",
                                      label: _vm.$t("invoices.status"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("invoices.status")
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-badge",
                                                {
                                                  attrs: {
                                                    "bg-color":
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).bgColor,
                                                    color:
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        row.status.replace(
                                                          "_",
                                                          " "
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1386508005
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("invoices.paid_status"),
                                      "sort-as": "paid_status",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoices.paid_status"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-badge",
                                                {
                                                  attrs: {
                                                    "bg-color":
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).bgColor,
                                                    color:
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        row.paid_status.replace(
                                                          "_",
                                                          " "
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      294840750
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("invoices.total"),
                                      "sort-as": "total",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("invoices.total")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.total,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      695320068
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("invoices.amount_due"),
                                      "sort-as": "due_amount",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoices.amount_due"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.due_amount,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1387549284
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown no-click",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("invoices.action")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.invoice_status.value !==
                                              "ARCHIVED"
                                                ? _c(
                                                    "sw-dropdown",
                                                    [
                                                      _c("dot-icon", {
                                                        attrs: {
                                                          slot: "activator",
                                                        },
                                                        slot: "activator",
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.permissionModule
                                                        .updateInvoices
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              attrs: {
                                                                "tag-name":
                                                                  "router-link",
                                                                to: {
                                                                  path: `/admin/invoices/${row.id}/edit`,
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "pencil-icon",
                                                                {
                                                                  staticClass:
                                                                    "h-5 mr-3 text-gray-600",
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "general.edit"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.permissionModule
                                                        .readInvoices
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              attrs: {
                                                                "tag-name":
                                                                  "router-link",
                                                                to: {
                                                                  path: `/admin/invoices/${row.id}/view`,
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c("eye-icon", {
                                                                staticClass:
                                                                  "h-5 mr-3 text-gray-600",
                                                              }),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoices.view"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      row.status == "DRAFT" ||
                                                      row.status == "SAVE_DRAFT"
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sendInvoice(
                                                                      row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "paper-airplane-icon",
                                                                {
                                                                  staticClass:
                                                                    "h-5 mr-3 text-gray-600",
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoices.send_invoice"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      row.status != "DRAFT" &&
                                                      row.status != "SAVE_DRAFT"
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sendInvoice(
                                                                      row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "paper-airplane-icon",
                                                                {
                                                                  staticClass:
                                                                    "h-5 mr-3 text-gray-600",
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoices.resend_invoice"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      row.status == "DRAFT" ||
                                                      row.status == "SAVE_DRAFT"
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.markInvoiceAsSent(
                                                                      row.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "check-circle-icon",
                                                                {
                                                                  staticClass:
                                                                    "h-5 mr-3 text-gray-600",
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoices.mark_as_sent"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.permissionModule
                                                        .createPayments
                                                        ? _c(
                                                            "div",
                                                            [
                                                              row.status ===
                                                                "SENT" ||
                                                              row.status ===
                                                                "VIEWED" ||
                                                              row.status ===
                                                                "OVERDUE"
                                                                ? _c(
                                                                    "sw-dropdown-item",
                                                                    {
                                                                      attrs: {
                                                                        "tag-name":
                                                                          "router-link",
                                                                        to: `/admin/payments/${row.id}/invoices`,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "credit-card-icon",
                                                                        {
                                                                          staticClass:
                                                                            "h-5 mr-3 text-gray-600",
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "payments.record_payment"
                                                                            )
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.permissionModule
                                                        .createInvoices
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onCloneInvoice(
                                                                      row.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "document-duplicate-icon",
                                                                {
                                                                  staticClass:
                                                                    "h-5 mr-3 text-gray-600",
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoices.clone_invoice"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.permissionModule
                                                        .deleteInvoices
                                                        ? _c(
                                                            "sw-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeInvoice(
                                                                      row.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("trash-icon", {
                                                                staticClass:
                                                                  "h-5 mr-3 text-gray-600",
                                                              }),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "general.delete"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "sw-dropdown",
                                                    [
                                                      _c("dot-icon", {
                                                        attrs: {
                                                          slot: "activator",
                                                        },
                                                        slot: "activator",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.restoreInvoiced(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("save-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.restore"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      986468810
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessEstimates
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck3" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck3" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tc("estimates.estimate", 2)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _c(
                                "div",
                                { staticClass: "text-grey-darkest" },
                                [
                                  _c(
                                    "sw-tabs",
                                    {
                                      attrs: {
                                        "active-tab": _vm.activeEstimateTab,
                                      },
                                      on: {
                                        update: _vm.setEstimateStatusFilter,
                                      },
                                    },
                                    [
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.all"),
                                          filter: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.draft"),
                                          filter: "DRAFT",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.sent"),
                                          filter: "SENT",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "estimates_table",
                                  staticStyle: { "min-height": "350px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchEstimatesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("estimates.date"),
                                      "sort-as": "estimate_date",
                                      show: "formattedEstimateDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc("estimates.estimate", 1),
                                      show: "estimate_number",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "estimates.estimate",
                                                      1
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-medium text-primary-500",
                                                  attrs: {
                                                    to: {
                                                      path: `/admin/estimates/${row.id}/view`,
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        row.estimate_number
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1200054891
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("estimates.status"),
                                      show: "status",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("estimates.status")
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-badge",
                                                {
                                                  staticClass: "px-3 py-1",
                                                  attrs: {
                                                    "bg-color":
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).bgColor,
                                                    color:
                                                      _vm.$utils.getBadgeStatusColor(
                                                        row.status
                                                      ).color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(row.status) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      457991249
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("estimates.total"),
                                      "sort-as": "total",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("estimates.total")
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.total,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1330658472
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("payments.action")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .updateEstimates
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: {
                                                              path: `/admin/estimates/${row.id}/edit`,
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("pencil-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.edit"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .deleteEstimates
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeEstimate(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("trash-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.delete"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readEstimates
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: {
                                                              path: `/admin/estimates/${row.id}/view`,
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("eye-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.view"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .createInvoices
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.convertInToinvoice(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "document-text-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.convert_to_invoice"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status !== "SENT"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onMarkAsSent(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "check-circle-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_sent"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status !== "SENT"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sendEstimate(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "paper-airplane-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.send_estimate"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status == "SENT" ||
                                                  row.status == "VIEWED"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sendEstimate(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "paper-airplane-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.resend_estimate"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status !== "ACCEPTED"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onMarkAsAccepted(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "check-circle-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_accepted"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  row.status !== "REJECTED"
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onMarkAsRejected(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("x-circle-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_rejected"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3502881887
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessExpenses
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck5" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck5" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tc("expenses.expense", 2)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "expenses_table",
                                  staticStyle: { "min-height": "300px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchExpensesData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("expenses.date"),
                                      "sort-as": "expense_date",
                                      show: "formattedExpenseDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("expenses.expense_number"),
                                      show: "expense_number",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "expenses.expense_number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.permissionModule
                                                .updateExpenses
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "font-medium text-primary-500",
                                                      attrs: {
                                                        to: {
                                                          path: `/admin/expenses/${row.id}/edit`,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            row.expense_number
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1053939722
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$tc(
                                        "expenses.categories.category",
                                        1
                                      ),
                                      "sort-as": "name",
                                      show: "category.name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "expenses.categories.category",
                                                      1
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row.category.name) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      743959943
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("expenses.provider"),
                                      "sort-as": "provider_title",
                                      show: "provider_title",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("expenses.provider")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      row.provider_title
                                                        ? row.provider_title
                                                        : "Not selected"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3367508756
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("expenses.amount"),
                                      "sort-as": "amount",
                                      show: "category.amount",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("expenses.amount")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.amount,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3646256165
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("general.status"),
                                      show: "status",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("general.status")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-badge",
                                                {
                                                  attrs: {
                                                    "bg-color":
                                                      _vm.$utils.getBadgeStatusExpenseColor(
                                                        row.status
                                                      ).bgColor,
                                                    color:
                                                      _vm.$utils.getBadgeStatusExpenseColor(
                                                        row.status
                                                      ).color,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        row.status == "Active"
                                                          ? _vm.$t(
                                                              "general.processed"
                                                            )
                                                          : row.status
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1991221744
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("expenses.subject"),
                                      "sort-as": "subject",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("expenses.subject")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "subject" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "truncate" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.subject)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      210767681
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown no-click",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("expenses.action")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .updateExpenses
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: {
                                                              path: `/admin/expenses/${row.id}/edit`,
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("pencil-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.edit"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readExpenses
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/expenses/${row.id}/view`,
                                                          },
                                                        },
                                                        [
                                                          _c("eye-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoices.view"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readExpenses
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: `/admin/expenses/${row.id}/docs`,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "document-text-icon",
                                                            {
                                                              staticClass:
                                                                "h-5 mr-3 text-gray-600",
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.docs"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .deleteExpenses
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeExpense(
                                                                row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("trash-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.delete"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      759821723
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.permissionModule.accessPayments
          ? _c("div", [
              _vm.$route.name === "customers.view"
                ? _c(
                    "div",
                    { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                    [
                      _c("div", { staticClass: "border-b tab" }, [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck6" },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                              attrs: { for: "chck6" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-500 uppercase sw-section-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tc("payments.payment", 2)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-reactid": "266",
                                        fill: "none",
                                        height: "24",
                                        stroke: "#606F7B",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "2",
                                        viewbox: "0 0 24 24",
                                        width: "24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("polyline", {
                                        attrs: { points: "6 9 12 15 18 9" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tab-content-customer" },
                            [
                              _c(
                                "div",
                                { staticClass: "text-grey-darkest" },
                                [
                                  _c(
                                    "sw-tabs",
                                    {
                                      ref: "tabsStatusPayments",
                                      on: {
                                        update: _vm.setPaymentsStatusFilter,
                                      },
                                    },
                                    [
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.all"),
                                          filter: "ALL",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.void"),
                                          filter: "Void",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.unapply"),
                                          filter: "Unapply",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.refunded"),
                                          filter: "Refunded",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.approved"),
                                          filter: "Approved",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t(
                                            "general.balance_to_debit"
                                          ),
                                          filter: "balance_to_debit",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.pending"),
                                          filter: "Pending",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.returned"),
                                          filter: "Returned",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.declined"),
                                          filter: "Declined",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-tab-item", {
                                        attrs: {
                                          title: _vm.$t("general.error"),
                                          filter: "Error",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "sw-table-component",
                                {
                                  ref: "payments_table",
                                  staticStyle: { "min-height": "300px" },
                                  attrs: {
                                    "show-filter": false,
                                    data: _vm.fetchPaymentsData,
                                    "table-class": "table",
                                  },
                                },
                                [
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.date"),
                                      "sort-as": "payment_date",
                                      show: "formattedPaymentDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.payment_number"),
                                      show: "payment_number",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "payments.payment_number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.permissionModule.readPayments
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "font-medium text-primary-500",
                                                      attrs: {
                                                        to: {
                                                          path: `/admin/payments/${row.id}/view`,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            row.payment_number
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2667637042
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.payment_mode"),
                                      show: "payment_mode",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "payments.payment_mode"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                row.payment_method_id == null &&
                                                row.invoice_id != null
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.balance_to_debit"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                row.payment_method_id != null
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            row.payment_mode
                                                              ? row.payment_mode
                                                              : "Not selected"
                                                          ) +
                                                          "\n\n                        "
                                                      ),
                                                      row.payment_method &&
                                                      (row.payment_method
                                                        .show_notes_table ===
                                                        1 ||
                                                        row.payment_method
                                                          .show_notes_table ===
                                                          true)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-sm",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    row.notes
                                                                      ? row.notes
                                                                      : "Not notes"
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1311121795
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.invoice"),
                                      "sort-as": "invoice_id",
                                      show: "invoice_id",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              true
                                                ? _c("div", [
                                                    row.invoice != null
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "router-link",
                                                              {
                                                                staticClass:
                                                                  "font-medium text-primary-500",
                                                                attrs: {
                                                                  to: {
                                                                    path: `invoices/${row.invoice_id}/view`,
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      row.invoice_number
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c("div", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "payments.no_invoice"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]),
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          row.invoice_number !=
                                                            null
                                                            ? row.invoice_number
                                                            : _vm.$t(
                                                                "payments.no_invoice"
                                                              )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      877730901
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.status"),
                                      show: "transaction_status",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              row.transaction_status ==
                                              "Approved"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "COMPLETED"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "COMPLETED"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.approved"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status == "Void"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.void"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status ==
                                              "Unapply"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.unapply"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status ==
                                              "Refunded"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.refunded"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status ==
                                              "Pending"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "VIEWED"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "VIEWED"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.pending"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status ==
                                              "Returned"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "payments.returned"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status ==
                                              "Declined"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.declined"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.transaction_status == "Error"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "sw-badge",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1",
                                                          attrs: {
                                                            "bg-color":
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).bgColor,
                                                            color:
                                                              _vm.$utils.getBadgeStatusColor(
                                                                "OVERDUE"
                                                              ).color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.error"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2076840124
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: true,
                                      label: _vm.$t("payments.amount"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("payments.amount")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      row.amount,
                                                      row.user.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1477429167
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("sw-table-column", {
                                    attrs: {
                                      sortable: false,
                                      filterable: false,
                                      "cell-class": "action-dropdown",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (row) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("payments.action")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "sw-dropdown",
                                                [
                                                  _c("dot-icon", {
                                                    attrs: {
                                                      slot: "activator",
                                                    },
                                                    slot: "activator",
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .updatePayments
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: {
                                                              path: `/admin/payments/${row.id}/edit`,
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("pencil-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.edit"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.permissionModule
                                                    .readPayments
                                                    ? _c(
                                                        "sw-dropdown-item",
                                                        {
                                                          attrs: {
                                                            "tag-name":
                                                              "router-link",
                                                            to: {
                                                              path: `/admin/payments/${row.id}/view`,
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("eye-icon", {
                                                            staticClass:
                                                              "h-5 mr-3 text-gray-600",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.view"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2630345031
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }