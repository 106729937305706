var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mail-config-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.savePackagesGroup.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "p-4 md:p-8" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("packages.modal.name"),
                  error: _vm.name,
                  variant: "horizontal",
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { type: "button", variant: "primary-outline" },
                on: { click: _vm.closeTaxModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  variant: "primary",
                  type: "submit",
                  loading: _vm.isLoading,
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }