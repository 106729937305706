export const ADD_AVALARA_CONFIG = 'ADD_AVALARA_CONFIG'
export const AVALARA_LOCATION_SAVED = 'AVALARA_LOCATION_SAVED'
export const SET_AVALARA_CONFIGS = 'SET_AVALARA_CONFIGS'
export const SET_AVALARA_CONFIGS_GROUP = 'SET_AVALARA_CONFIGS_GROUP'
export const UPDATE_AVALARA_CONFIG = 'UPDATE_AVALARA_CONFIG'
export const DELETE_AVALARA_CONFIG = 'DELETE_AVALARA_CONFIG'
export const DELETE_MULTIPLE_AVALARA_CONFIGS = 'DELETE_MULTIPLE_AVALARA_CONFIGS'
export const SET_SELECTED_AVALARA_CONFIGS = 'SET_SELECTED_AVALARA_CONFIGS'
export const SET_TOTAL_AVALARA_CONFIGS = 'SET_TOTAL_AVALARA_CONFIGS'

export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const SET_CUSTOMER = 'SET_CUSTOMER'

export const RESET_ITEM = 'RESET_ITEM'
export const SET_ITEM = 'SET_ITEM'

export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'

export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_AVALARA_CONFIGS = 'RESET_SELECTED_AVALARA_CONFIGS'
export const UPDATE_AVALARA_CONFIG_STATUS = 'UPDATE_AVALARA_CONFIG_STATUS'

export const RESET_SELECTED_NOTE = 'RESET_SELECTED_NOTE'
export const SET_SELECTED_NOTE = 'SET_SELECTED_NOTE'

export const CREATE_AVALARA_CONFIG_GROUP = 'CREATE_AVALARA_CONFIG_GROUP'
export const SET_AVALARA_ITEMS = 'SET_AVALARA_ITEMS'
export const SET_AVALARA_LOCATION_DATA = 'SET_AVALARA_LOCATION_DATA'
