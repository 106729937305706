var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-wizard-step",
    { attrs: { title: _vm.$t("customers.corepbx_parameters") } },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12 mt-5" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$tc("packages.package", 1),
                  error: _vm.packageError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.package.$error,
                    options: _vm.groups,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_pbx_package"),
                    label: "pbx_package_name",
                    disabled: _vm.isEdit,
                  },
                  on: {
                    select: _vm.setTenant,
                    input: function ($event) {
                      return _vm.$v.formData.package.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.package,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "package", $$v)
                    },
                    expression: "formData.package",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _vm.packageInfo.serverName
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.server")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.packageInfo.serverName) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.packageInfo.extName
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.extesion_profile")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.packageInfo.extName) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.packageInfo.didName
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.did_package")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.packageInfo.didName) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.packageInfo.call_ratings
              ? _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.call_ratings")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.active")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$tc("customers.status", 2) },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.statusOptions,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_status"),
                    label: "name",
                  },
                  on: { select: _vm.setBeginDate },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("customers.term") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.term,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_term"),
                    label: "name",
                    disabled: _vm.isEdit,
                  },
                  model: {
                    value: _vm.formData.term,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "term", $$v)
                    },
                    expression: "formData.term",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showDates
              ? _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.date_act"),
                          error: _vm.dateBeginError,
                          required: "",
                        },
                      },
                      [
                        _c("base-date-picker", {
                          ref: "dateBegin",
                          attrs: {
                            invalid: _vm.$v.formData.date_begin.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                            disabled: _vm.isEdit,
                          },
                          model: {
                            value: _vm.formData.date_begin,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "date_begin", $$v)
                            },
                            expression: "formData.date_begin",
                          },
                        }),
                        _vm._v(" "),
                        _vm.error_activation_date
                          ? _c("p", [
                              _c("small", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("general.warning_service2"))
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mb-4",
                            attrs: { label: _vm.$t("customers.renewal_date") },
                          },
                          [
                            _c("base-date-picker", {
                              ref: "renewalDate",
                              attrs: {
                                "calendar-button": true,
                                "calendar-button-icon": "calendar",
                              },
                              on: { input: _vm.validateRenewalDate },
                              model: {
                                value: _vm.formData.renewal_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "renewal_date", $$v)
                                },
                                expression: "formData.renewal_date",
                              },
                            }),
                            _vm._v(" "),
                            _vm.error_renewal_date
                              ? _c("p", [
                                  _c(
                                    "small",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("general.warning_service4")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isTenant
              ? _c(
                  "span",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.tenant"),
                          error: _vm.tenatError,
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.tenant.$error,
                            options: _vm.tenant,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("customers.select_a_tenant"),
                            label: "name",
                            disabled: _vm.isEdit,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.tenant.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.tenant,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "tenant", $$v)
                            },
                            expression: "formData.tenant",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isEdit &&
                    _vm.formData.package.call_ratings &&
                    !_vm.formData.package.template_did_id &&
                    !_vm.formData.package.template_extension_id
                      ? _c("p", [
                          _c("small", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.warning_service5")) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formData.package.all_cdrs
                      ? _c("small", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.warning_service6")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.tenant.length <= 0 && _vm.showMessageTenants
                      ? _c("p", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.warning_service7")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.prefixrateGroups.length
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4 relative",
                    attrs: { label: _vm.$t("customers.prefix_rate_group") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.prefixrateGroupsInbound,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        placeholder: _vm.$t(
                          "customers.select_a_prefix_rate_group"
                        ),
                        label: "name",
                        "track-by": "id",
                        tabindex: 10,
                        multiple: true,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.prefix_rate_groups.$touch()
                        },
                      },
                      model: {
                        value: _vm.prefixrate_groups_id,
                        callback: function ($$v) {
                          _vm.prefixrate_groups_id = $$v
                        },
                        expression: "prefixrate_groups_id",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.prefixrateGroups.length
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4 relative",
                    attrs: {
                      label: _vm.$t("customers.prefix_rate_group_outbound"),
                    },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.prefixrateGroupsOutbound,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        placeholder: _vm.$t(
                          "customers.select_a_prefix_rate_group"
                        ),
                        label: "name",
                        "track-by": "id",
                        tabindex: 10,
                        multiple: true,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.prefix_rate_groups_outbound.$touch()
                        },
                      },
                      model: {
                        value: _vm.prefixrate_groups_outbound_id,
                        callback: function ($$v) {
                          _vm.prefixrate_groups_outbound_id = $$v
                        },
                        expression: "prefixrate_groups_outbound_id",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4 relative",
                attrs: { label: _vm.$t("customers.service_custom_app_rate") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.customAppRate,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$t("customers.service_custom_app_rate"),
                    label: "name",
                  },
                  on: { input: _vm.selectCustomAppRateMetho },
                  model: {
                    value: _vm.selectCustom_app_rate,
                    callback: function ($$v) {
                      _vm.selectCustom_app_rate = $$v
                    },
                    expression: "selectCustom_app_rate",
                  },
                }),
                _vm._v(" "),
                _vm.selectCustom_app_rate &&
                _vm.selectCustom_app_rate.name !== "None"
                  ? _c("x-circle-icon", {
                      staticClass: "h-5 absolute text-gray-400 cursor-pointer",
                      staticStyle: { top: "42px", right: "28px" },
                      on: {
                        click: function ($event) {
                          _vm.selectCustom_app_rate = { name: "None" }
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("customers.tax_type") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.tax_type,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$t("customers.select_a_tax_type"),
                    label: "name",
                    disabled: _vm.taxPerItem == "NO" || _vm.isEdit,
                  },
                  model: {
                    value: _vm.formData.tax_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "tax_type", $$v)
                    },
                    expression: "formData.tax_type",
                  },
                }),
                _vm._v(" "),
                _c("small", { staticClass: "text-secondary" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.warning_service8")) +
                      "\n        "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4 relative",
                attrs: { label: _vm.$t("customer_address.title_service") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.addressOptions,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customer_address.title_service"),
                    label: "name",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "flex items-center" }, [
                            option.option.state
                              ? _c("div", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    _vm._s(
                                      option.option.state.name +
                                        " " +
                                        option.option.address_street_1
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "flex items-center" }, [
                            option.option.state
                              ? _c("div", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    _vm._s(
                                      option.option.state.name +
                                        " " +
                                        option.option.address_street_1
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.formData.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "address", $$v)
                    },
                    expression: "formData.address",
                  },
                }),
                _vm._v(" "),
                _vm.formData.address && _vm.formData.address.state
                  ? _c("x-circle-icon", {
                      staticClass: "h-5 absolute text-gray-400 cursor-pointer",
                      staticStyle: { top: "42px", right: "28px" },
                      on: {
                        click: function ($event) {
                          _vm.formData.address = {}
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    on: { change: _vm.setDiscountParams },
                    model: {
                      value: _vm.formData.auto_suspension,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "auto_suspension", $$v)
                      },
                      expression: "formData.auto_suspension",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.auto_suspension")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            this.formData.auto_suspension == true
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$tc("customers.suspension_type") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.SuspensionTypeOptions,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("customers.suspension_type"),
                        label: "name",
                      },
                      model: {
                        value: _vm.formData.suspension_type_op,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "suspension_type_op", $$v)
                        },
                        expression: "formData.suspension_type_op",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "flex mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.main_update,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "main_update", $$v)
                      },
                      expression: "formData.main_update",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("customers.main_update_dids_and_extensions")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_pbx_packages_update,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_pbx_packages_update", $$v)
                      },
                      expression: "formData.allow_pbx_packages_update",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_pbx_packages_updates")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "h6",
              { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.invoice_pdf_format")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_pbxpackages,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_pbxpackages", $$v)
                      },
                      expression: "formData.allow_pbxpackages",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_pbxpackage")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_items,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_items", $$v)
                      },
                      expression: "formData.allow_items",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_items")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_extensions,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_extensions", $$v)
                      },
                      expression: "formData.allow_extensions",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_extensions")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_did,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_did", $$v)
                      },
                      expression: "formData.allow_did",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_did")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_aditionalcharges,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_aditionalcharges", $$v)
                      },
                      expression: "formData.allow_aditionalcharges",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_aditionalcharges")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_usagesummary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_usagesummary", $$v)
                      },
                      expression: "formData.allow_usagesummary",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_usagesummary")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.allow_customapp,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_customapp", $$v)
                      },
                      expression: "formData.allow_customapp",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_customapprate")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    on: { change: _vm.setDiscountParams },
                    model: {
                      value: _vm.formData.allow_discount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_discount", $$v)
                      },
                      expression: "formData.allow_discount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_discount")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.formData.allow_discount
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$tc("customers.select_time_period", 2),
                    },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.time_period,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("customers.select_time_period"),
                        label: "name",
                      },
                      model: {
                        value: _vm.formData.time_period,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "time_period", $$v)
                        },
                        expression: "formData.time_period",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.allow_discount
              ? _c("span", [
                  _vm.formData.time_period &&
                  _vm.formData.time_period.value == "D"
                    ? _c(
                        "div",
                        {
                          staticClass: "relative grid grid-flow-col grid-rows",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-2",
                              attrs: { label: _vm.$t("general.from") },
                            },
                            [
                              _c("base-date-picker", {
                                attrs: {
                                  "calendar-button": true,
                                  "calendar-button-icon": "calendar",
                                },
                                model: {
                                  value: _vm.formData.discount_start_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "discount_start_date",
                                      $$v
                                    )
                                  },
                                  expression: "formData.discount_start_date",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "hidden w-8 h-0 ml-8 border border-gray-400 border-solid xl:block",
                            staticStyle: { "margin-top": "3.5rem" },
                          }),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-2",
                              attrs: { label: _vm.$t("general.to") },
                            },
                            [
                              _c("base-date-picker", {
                                attrs: {
                                  "calendar-button": true,
                                  "calendar-button-icon": "calendar",
                                },
                                model: {
                                  value: _vm.formData.discount_end_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "discount_end_date",
                                      $$v
                                    )
                                  },
                                  expression: "formData.discount_end_date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.time_period &&
                  _vm.formData.time_period.value == "T"
                    ? _c(
                        "div",
                        {
                          staticClass: "relative grid grid-flow-col grid-rows",
                        },
                        [
                          _c(
                            "sw-input-group",
                            [
                              _c("sw-input", {
                                staticClass: "rounded-tr-sm rounded-br-sm",
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.time_period_value.$error,
                                  placeholder: _vm.$t(
                                    "customers.time_period_value"
                                  ),
                                  label: "name",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.time_period_value.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.time_period_value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "time_period_value",
                                      $$v
                                    )
                                  },
                                  expression: "formData.time_period_value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "hidden w-8 h-0 ml-8 border border-gray-400 border-solid xl:block",
                            staticStyle: { "margin-top": "1.5rem" },
                          }),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            [
                              _c("sw-select", {
                                staticClass:
                                  "mt-2 border-r-0 rounded-tr-sm rounded-br-sm",
                                attrs: {
                                  options: _vm.time_period_type,
                                  searchable: true,
                                  "show-labels": false,
                                  placeholder: _vm.$t(
                                    "customers.time_period_type"
                                  ),
                                  label: "name",
                                },
                                model: {
                                  value: _vm.formData.time_period_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "time_period_type",
                                      $$v
                                    )
                                  },
                                  expression: "formData.time_period_type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mt-4 pull-right",
                attrs: { loading: _vm.isLoading, variant: "primary" },
                on: { click: _vm.nextPage },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("general.continue")) +
                    "\n        "
                ),
                _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }