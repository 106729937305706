var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "payments" }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end mt-8 lg:flex-nowrap" },
          [
            _c(
              "sw-button",
              {
                staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                attrs: {
                  "tag-name": "router-link",
                  to: `/admin/customers/${_vm.$route.params.id}/view`,
                  variant: "primary-outline",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customer_notes.clientgoback")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                attrs: { size: "lg", variant: "primary-outline" },
                on: { click: _vm.toggleFilter },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.filter")) + "\n        "
                ),
                _c(_vm.filterIcon, {
                  tag: "component",
                  staticClass: "w-4 h-4 ml-2 -mr-1",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "slide-y-up-transition",
          [
            _c(
              "sw-filter-wrapper",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilters,
                    expression: "showFilters",
                  },
                ],
                staticClass:
                  "relative grid grid-rows gap-4 p-4 bg-gray-100 rounded-lg w-full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "w-full grid grid-cols-1 md:grid-cols-4 gap-4",
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-2",
                        attrs: { label: _vm.$t("general.from") },
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "w-full",
                          attrs: {
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          model: {
                            value: _vm.filters.from_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "from_date", $$v)
                            },
                            expression: "filters.from_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-2",
                        attrs: { label: _vm.$t("general.to") },
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "w-full",
                          attrs: {
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          model: {
                            value: _vm.filters.to_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "to_date", $$v)
                            },
                            expression: "filters.to_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-2",
                        attrs: { label: _vm.$t("logs.email_logs.email") },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "w-full",
                          attrs: {
                            type: "text",
                            name: "email",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.filters.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "email", $$v)
                            },
                            expression: "filters.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-2",
                        attrs: { label: _vm.$t("logs.email_logs.subject") },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "w-full",
                          attrs: {
                            type: "text",
                            name: "subject",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.filters.subject,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "subject", $$v)
                            },
                            expression: "filters.subject",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "absolute text-sm leading-snug text-black cursor-pointer",
                    staticStyle: { top: "10px", right: "15px" },
                    on: { click: _vm.clearFilter },
                  },
                  [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "py-4" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("logs.time_shown")))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-content" },
          [
            _c(
              "sw-table-component",
              {
                ref: "table",
                attrs: {
                  "table-class": "table",
                  "show-filter": false,
                  data: _vm.fetchData,
                },
              },
              [
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("logs.email_logs.email"),
                    show: "to",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("logs.email_logs.subject"),
                    show: "subject",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("logs.email_logs.body"),
                    show: "body",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("logs.email_logs.body"))),
                          ]),
                          _vm._v(" "),
                          _vm.isTruncated(row)
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.truncatedText(row.body))),
                              ])
                            : _c("span", [_vm._v(_vm._s(row.body))]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggleText(row)
                                },
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  " " + _vm._s(row.showMore ? "Less" : "More")
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("logs.email_logs.mailable_type"),
                    show: "mailable_type",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("logs.email_logs.mailable_type"))
                            ),
                          ]),
                          _vm._v(" "),
                          row.formattedType
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(_vm.typeMapping[row.formattedType])
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("logs.email_logs.sended"),
                    "sort-as": "created_at",
                    show: "formattedCreatedAt",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: false,
                    filterable: false,
                    "cell-class": "action-dropdown no-click",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("invoices.action"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendInvoice(row.id)
                                    },
                                  },
                                },
                                [
                                  _c("paper-airplane-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.resend")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }