var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative customer-modal" },
    [
      _vm.isRequestOngoing
        ? _c("base-loader", {
            staticClass: "h-130",
            attrs: { "show-bg-overlay": true },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.createNewBackup.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "p-6" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "py-2",
                  attrs: {
                    label: _vm.$t("settings.backup.select_backup_type"),
                    error: _vm.optionError,
                    horizontal: "",
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.options,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("settings.backup.select_backup_type"),
                      "allow-empty": false,
                      maxHeight: 100,
                    },
                    model: {
                      value: _vm.formData.option,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "option", $$v)
                      },
                      expression: "formData.option",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "py-2",
                  attrs: {
                    label: _vm.$t("settings.disk.select_disk"),
                    error: _vm.selectDiskError,
                    horizontal: "",
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.getDisks,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("settings.disk.select_disk"),
                      "allow-empty": false,
                      "track-by": "id",
                      "preselect-first": true,
                      "custom-label": _vm.getCustomLabel,
                      maxHeight: 100,
                      loading: _vm.isLoading,
                    },
                    model: {
                      value: _vm.formData.selected_disk,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "selected_disk", $$v)
                      },
                      expression: "formData.selected_disk",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.cancelBackup },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    loading: _vm.isCreateLoading,
                    variant: "primary",
                    type: "submit",
                    disabled: _vm.isCreateLoading,
                  },
                },
                [
                  !_vm.isCreateLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.create")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }