var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("avalara.avalara_logs") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/avalara/configs",
                      title: _vm.$tc("avalara.title", 2),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$t("avalara.logs"),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: {
                        click: function ($event) {
                          _vm.showFilters = !_vm.showFilters
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "relative grid grid-flow-col grid-rows",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$tc("customers.customer", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: { label: _vm.$t("general.from") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.searchFilter()
                          },
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block",
                    staticStyle: { "margin-top": "3.5rem" },
                  }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: { label: _vm.$t("general.to") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.searchFilter()
                          },
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: { label: _vm.$t("invoices.invoice_number") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          on: {
                            input: function ($event) {
                              return _vm.searchFilter()
                            },
                          },
                          model: {
                            value: _vm.filters.invoice_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "invoice_number", $$v)
                            },
                            expression: "filters.invoice_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: { label: _vm.$t("avalara.pbx_services") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          on: {
                            input: function ($event) {
                              return _vm.searchFilter()
                            },
                          },
                          model: {
                            value: _vm.filters.pbx_services_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "pbx_services_number", $$v)
                            },
                            expression: "filters.pbx_services_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-black cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showEmptyScreen && _vm.lenght === 0
            ? _c(
                "sw-empty-table-placeholder",
                {
                  attrs: {
                    title: _vm.$t("avalara.no_avalara_logs"),
                    description: _vm.$t("avalara.list_of_avalara_logs"),
                  },
                },
                [_c("astronaut-icon", { staticClass: "mt-5 mb-4" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative table-container" },
            [
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("avalara.payload"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("avalara.payload"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-button",
                                {
                                  attrs: { variant: "primary-outline" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showModalJson(row.response)
                                    },
                                  },
                                },
                                [
                                  _c("eye-icon", {
                                    staticClass: "w-4 h-4 mr-1 -ml-2",
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("avalara.json")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      105435713
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.invoice"),
                      show: "invoice_id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("avalara.invoice"))),
                              ]),
                              _vm._v(" "),
                              row.invoice
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to:
                                          row.invoice.deleted_at == null
                                            ? {
                                                path: `/admin/invoices/${row.invoice.id}/view`,
                                              }
                                            : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.invoice.invoice_number) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3931925895
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.pbx_services"),
                      show: "pbx_service_id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("avalara.pbx_services"))),
                              ]),
                              _vm._v(" "),
                              row.pbx_service_id !== null
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to:
                                          row.pbx_service.deleted_at == null
                                            ? {
                                                path: `/admin/customers/${row.customer.id}/pbx-service/${row.pbx_service.id}/view`,
                                              }
                                            : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            row.pbx_service.pbx_services_number
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c("p", [
                                    _vm._v("\n            N/A\n          "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3542390069
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.customer"),
                      show: "user_id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("avalara.customer"))),
                              ]),
                              _vm._v(" "),
                              row.customer
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to:
                                          row.customer.deleted_at == null
                                            ? {
                                                path: `admin/customers/${row.customer.id}/view`,
                                              }
                                            : "#",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.customer.customcode) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2998340504
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.operation_type"),
                      show: "operation_type",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("avalara.operation_type"))
                                ),
                              ]),
                              _vm._v(" "),
                              row.operation_type == 0
                                ? _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("avalara.calculated")) +
                                          "\n              "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.operation_type == 1
                                ? _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("avalara.deleted")) +
                                          "\n              "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.operation_type == 2
                                ? _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("avalara.uncommited")) +
                                          "\n              "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.operation_type == 3
                                ? _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("avalara.adjusted")) +
                                          "\n              "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      554495241
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.date"),
                      show: "formattedCreatedAt",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-modal",
                {
                  ref: "seenJsonModal",
                  attrs: { variant: "primary" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute flex content-center justify-center w-5 cursor-pointer",
                                staticStyle: { top: "20px", right: "15px" },
                                on: { click: _vm.closeModal },
                              },
                              [_c("x-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("avalara.seen_json"))),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    786567863
                  ),
                },
                [
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "pre",
                      {
                        staticClass:
                          "text-sm overflow-x-auto px-5 h-full w-full",
                        staticStyle: { height: "450px" },
                      },
                      [
                        _vm._v("          "),
                        _c("code", [_vm._v(_vm._s(_vm.jsonPayload))]),
                        _vm._v("\n        "),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }