var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.EventSubir.apply(null, arguments)
        },
      },
    },
    [
      !_vm.banCsv
        ? _c("div", [
            _c("div", { staticClass: "py-4 px-6 form-group-row" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("corePbx.internacional.text_csv"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "py-4 px-6 form-group-row" }, [
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("input", {
                    ref: "file",
                    staticClass: "form-control mb-2",
                    attrs: { type: "file", id: "file", accept: ".XLSX, .CSV" },
                    on: {
                      change: function ($event) {
                        return _vm.handleFileUpload()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "download-csv",
                    {
                      staticClass:
                        "relative my-0 ml-0 text-sm font-medium cursor-pointer text-primary-500 inline",
                      attrs: { data: _vm.json_data },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("expenses.download_exmaple")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banCargArch
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "flex mr-2 w-50",
                          attrs: { variant: "primary", type: "button" },
                          on: { click: _vm.cancelLoadArch },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.remove")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pt-4 pb-40 px-6" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  { staticClass: "font-normal" },
                  [
                    _c("sw-radio", {
                      attrs: {
                        label: _vm.$t(
                          "corePbx.custom_did_groups.import_to_existing_group"
                        ),
                        size: "sm",
                        name: "filter",
                        value: "existing_group",
                      },
                      on: {
                        change: function ($event) {
                          "onSearch"
                        },
                      },
                      model: {
                        value: _vm.formData.type_group,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type_group", $$v)
                        },
                        expression: "formData.type_group",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.formData.type_group === "existing_group"
                  ? _c(
                      "sw-input-group",
                      { staticClass: "my-4", attrs: { error: _vm.idError } },
                      [
                        _c("sw-select", {
                          attrs: {
                            disabled: _vm.import_new,
                            invalid:
                              _vm.$v.formData.prefixrate_groups_id.$error,
                            options: _vm.group,
                            searchable: true,
                            "show-labels": false,
                            tabindex: 16,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_category"),
                            label: "name",
                            "track-by": "id",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.prefixrate_groups_id.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.prefixrate_groups_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "prefixrate_groups_id",
                                $$v
                              )
                            },
                            expression: "formData.prefixrate_groups_id",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  { staticClass: "font-normal" },
                  [
                    _c("sw-radio", {
                      attrs: {
                        label: _vm.$t(
                          "corePbx.custom_did_groups.import_to_new_group"
                        ),
                        size: "sm",
                        name: "filter",
                        value: "new_group",
                      },
                      on: {
                        change: function ($event) {
                          "onSearch"
                        },
                      },
                      model: {
                        value: _vm.formData.type_group,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type_group", $$v)
                        },
                        expression: "formData.type_group",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.formData.type_group === "new_group"
                  ? _c(
                      "div",
                      [
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "my-4",
                            attrs: { error: _vm.groupNameError, required: "" },
                          },
                          [
                            _c("sw-input", {
                              staticClass: "mt-2",
                              attrs: {
                                invalid:
                                  _vm.$v.formData.prefix_group_name.$error,
                                placeholder: "Destination group name",
                                type: "text",
                                name: "name",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.prefix_group_name.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.prefix_group_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "prefix_group_name",
                                    $$v
                                  )
                                },
                                expression: "formData.prefix_group_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "mb-4",
                            attrs: { error: _vm.groupTypeError, required: "" },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                invalid:
                                  _vm.$v.formData.prefix_group_type.$error,
                                options: _vm.types,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                placeholder: _vm.$t(
                                  "corePbx.custom_did_groups.select_a_type"
                                ),
                                label: "name",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.prefix_group_type.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.prefix_group_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "prefix_group_type",
                                    $$v
                                  )
                                },
                                expression: "formData.prefix_group_type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.banCsv
        ? _c("div", [
            _vm.banCsv
              ? _c(
                  "div",
                  { staticStyle: { "overflow-y": "scroll", height: "500px" } },
                  [
                    _c(
                      "table",
                      {
                        staticClass:
                          "w-full item-table bg-white border border-gray-200 border-solid",
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("thead", [
                          _c("tr", [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][0]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][1]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s("from / to") +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][4]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][5]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][6]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(this.formData.listCsv[0][7]) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(this.formData.listCsv, function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "py-3" },
                              [
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(index) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              item[0] == "P"
                                                ? "Prefix"
                                                : "From / To"
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item[1]) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "whitespace-nowrap" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${item[2] || ""}${
                                                  item[3] ? " / " : ""
                                                }${item[3] || ""}`
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item[4]) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item[5]) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.showCategory(item[6])) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "td",
                                      { staticClass: "px-5 py-4 text-center" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item[7]) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "z-0 flex justify-end p-4" },
        [
          _c(
            "sw-button",
            {
              staticClass: "flex my-4 ml-6 mr-2 w-50",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closePaymentModeModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "flex my-4 my-4 mr-6 ml-2 w-50",
              attrs: { variant: "primary", type: "submit", size: "lg" },
            },
            [
              this.banCsv
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(this.banCsv ? "Save" : "Next") +
                  "\n\n      "
              ),
              !this.banCsv
                ? _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", {
        staticClass: "p-0 mb-1 text-base leading-snug text-black box-title",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "3%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "3%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "3%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "25%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "22%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "22%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "22%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "th",
      {
        staticClass:
          "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
      },
      [_c("span", [_vm._v(" # ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }