var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$t("customer_ticket.view_ticket") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/customer/tickets",
                  title: _vm.$tc("customer_ticket.title", 2),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("customer_ticket.view_ticket"),
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _vm.ticket.customer
              ? _c("sw-card", [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "text-gray-500 uppercase sw-section-title",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("customer_ticket.information_ticket")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                      _c("div", { staticClass: "w-full md:w-1/2" }, [
                        _c("p", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.customer"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-gray-700 text-sm" }, [
                          _vm._v(_vm._s(_vm.ticket.customer.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-1/2 mt-3 md:mt-0" },
                        [
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("customer_ticket.ticket_number")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(_vm._s(_vm.ticket.ticket_number)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-full md:w-1/2 mt-3 md:mt-0" },
                        [
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("customer_ticket.summary"))),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(_vm._s(_vm.ticket.summary)),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                      _c("div", { staticClass: "w-full md:w-1/4" }, [
                        _c("div", { staticClass: "font-bold py-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tc("customer_ticket.departament")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.ticket.ticket_departament.name) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-full md:w-1/4" }, [
                        _c("div", { staticClass: "font-bold py-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tc("customer_ticket.assignedTo")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.ticket.assigned.name) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-full md:w-1/4" }, [
                        _c("div", { staticClass: "font-bold py-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tc("customer_ticket.priority")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.prioritysOptions[_vm.ticket.priority]
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-full md:w-1/4" }, [
                        _c("div", { staticClass: "font-bold py-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tc("customer_ticket.status")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.statusOptions[_vm.ticket.status]) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                      _c("p", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("customer_ticket.details"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-gray-700 text-sm" }, [
                        _vm._v(_vm._s(_vm.ticket.note)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                      _c("p", { staticClass: "font-bold mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("customer_ticket.user"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        _vm._l(_vm.ticket.users, function (tr, indexTr) {
                          return _c(
                            "div",
                            { key: indexTr, staticClass: "flex my-2" },
                            [
                              _c("div"),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-2" }, [
                                _c("p", { staticClass: "text-gray-700 text" }, [
                                  _vm._v(_vm._s(tr.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "text-gray-700 text-sm" },
                                  [_vm._v(_vm._s(tr.email))]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "relative table-container" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                        },
                        [_c("p", { staticClass: "text-sm" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-table-component",
                        {
                          ref: "notes_table",
                          attrs: {
                            "show-filter": false,
                            "table-class": "table",
                            data: _vm.fetchNotes,
                          },
                        },
                        [
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.reference"),
                              show: "reference",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.reference")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-medium text-primary-500 cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewNote(
                                                _vm.ticket,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.reference) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3689827349
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.subject"),
                              show: "subject",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.subject")
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.subject) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2996260299
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.message"),
                              show: "message",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customer_ticket.message")
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.message) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2652819691
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("customer_ticket.user"),
                              show: "user_name",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("customer_ticket.user"))
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.user_name) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              535961747
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: { sortable: true, label: "Datetime" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("general.datetime")) +
                                            " "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.date) +
                                          " " +
                                          _vm._s(row.time) +
                                          "\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3432544619
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: { "cell-class": "action-dropdown no-click" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.actions"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewNote(
                                                    _vm.ticket,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("eye-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("general.view")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              352473824
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }