var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitNote.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3 text-sm hidden sm:flex",
                        attrs: {
                          "tag-name": "router-link",
                          to: `/admin/leads/${this.$route.params.idlead}/view`,
                          variant: "primary-outline",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("customer_notes.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("customer_notes.update_items_note")
                                : _vm.$t("customer_notes.save_items_note")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("customer_notes.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.summary.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.summary.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.summary,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "summary",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.summary",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("leads.message"),
                            error: _vm.descriptionError,
                          },
                        },
                        [
                          _c("sw-textarea", {
                            attrs: { rows: "2", name: "note" },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.note.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "note", $$v)
                              },
                              expression: "formData.note",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$t("leads.followupdate") } },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            model: {
                              value: _vm.formData.followdate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "followdate", $$v)
                              },
                              expression: "formData.followdate",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("leads.followupdatedesc")) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                attrs: {
                  "tag-name": "router-link",
                  to: `/admin/leads/${this.$route.params.idlead}/view`,
                  variant: "primary-outline",
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("customer_notes.cancel")) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass:
                  "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                  size: "lg",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("customer_notes.update_items_note")
                        : _vm.$t("customer_notes.save_items_note")
                    ) +
                    "\n    "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }