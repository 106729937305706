<template>
  <div>
    <base-loader v-if="isRequestOnGoing" :show-bg-overlay="true"/>
    <sw-card variant="setting-card">
      <template slot="header">
        <div class="flex flex-wrap justify-between items-center">
          <div>
            <h6 class="sw-section-title">
              {{ $t('settings.menu_title.preferences') }}
            </h6>

            <p
              class="mt-2 text-sm leading-snug text-gray-500"
              style="max-width: 680px"
            >
              {{ $t('settings.preferences.general_settings') }}
            </p>
          </div>
          <div class="flex-shrink-0 ml-4 justify-end">
            <div class="mb-3 hidden xl:block">
              <sw-button variant="primary-outline" @click="toggleSideBar">
                {{ $t('tickets.departaments.menu') }}
                <component :is="listIcon" class="w-4 h-4 ml-2 -mr-1" />
              </sw-button>
            </div>
          </div>
        </div>
      </template>

      <form action="" @submit.prevent="updatePreferencesData" class="relative">
        <div class="grid gap-6 sm:grid-col-1 md:grid-cols-2">
          <sw-input-group
            :label="$tc('settings.preferences.currency')"
            :error="currencyError"
            required
          >
            <sw-select
              v-model="formData.currency"
              :options="currencies"
              :custom-label="currencyNameWithCode"
              :class="{ error: $v.formData.currency.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.currencies.select_currency')"
              class="mt-2"
              label="name"
              track-by="id"
            />
          </sw-input-group>

          <sw-input-group
            :label="$tc('settings.preferences.default_language')"
            :error="languageError"
            required
          >
            <sw-select
              v-model="formData.language"
              :options="languages"
              :class="{ error: $v.formData.language.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.preferences.select_language')"
              class="mt-2"
              label="name"
              track-by="code"
            />
          </sw-input-group>

          <sw-input-group
            :label="$tc('settings.preferences.time_zone')"
            :error="timeZoneError"
            required
          >
            <sw-select
              v-model="formData.timeZone"
              :options="timeZones"
              :class="{ error: $v.formData.timeZone.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.preferences.select_time_zone')"
              class="mt-2"
              label="key"
              track-by="key"
            />
          </sw-input-group>

          <sw-input-group
            :label="$tc('settings.preferences.date_format')"
            :error="dateFormatError"
            required
          >
            <sw-select
              v-model="formData.dateFormat"
              :options="dateFormats"
              :class="{ error: $v.formData.dateFormat.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.preferences.select_date_format')"
              class="mt-2"
              label="display_date"
            />
          </sw-input-group>

          <sw-input-group
            :label="$tc('settings.preferences.fiscal_year')"
            :error="fiscalYearError"
            class="mb-2"
            required
          >
            <sw-select
              v-model="formData.fiscalYear"
              :options="fiscalYears"
              :class="{ error: $v.formData.fiscalYear.$error }"
              :show-labels="false"
              :allow-empty="false"
              :searchable="true"
              :placeholder="$tc('settings.preferences.select_financial_year')"
              label="key"
              track-by="value"
            />
          </sw-input-group>
        </div>

        <sw-button
          class="mt-6"
          variant="primary"
          type="submit"
          :disabled="isLoading"
          :loading="isLoading"
          v-if="permissionModule.update"
        >
          <save-icon v-if="!isLoading" class="mr-2 -ml-1"/>
          {{ $tc('settings.company_info.save') }}
        </sw-button>

        <sw-divider class="mt-6 mb-8"/>

        <div class="flex">
          <div class="relative w-12">
            <sw-switch
              v-model="discount_per_item"
              class="absolute"
              style="top: -18px"
              @change="setDiscount"
            />
          </div>

          <div class="ml-15">
            <p class="p-0 mb-1 text-base leading-snug text-black">
              {{ $t('settings.preferences.discount_per_item') }}
            </p>
            <p
              class="p-0 m-0 text-xs leading-tight text-gray-500"
              style="max-width: 480px"
            >
              {{ $t('settings.preferences.discount_setting_description') }}
            </p>
          </div>
        </div>
      </form>

      <sw-divider class="mt-6 mb-8"/>

      <form action="" class="mt-6" @submit.prevent="updateIdleTimeLogout">
        <sw-input-group
          :label="$t('settings.preferences.idle_time_to_logout')"
          :error="idleTimeError"
          required
        >
          <sw-input
            v-model="idle_time_to_logout"
            :invalid="$v.idle_time_to_logout.$error"
            style="max-width: 30%"
            @input="$v.idle_time_to_logout.$touch()"
            class="mt-2"
            type="number"
            min="1"
            max="60"
          />
        </sw-input-group>
        <sw-button
          :loading="isLoading"
          :disabled="isLoading"
          variant="primary"
          type="submit"
          class="mt-6"
          v-if="permissionModule.update"
        >
          <save-icon v-if="!isLoading" class="mr-2"/>
          {{ $t('general.save') }}
        </sw-button>
      </form>

    </sw-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const { required, between } = require('vuelidate/lib/validators')
import { ClipboardListIcon } from '@vue-hero-icons/outline'
import { XIcon } from '@vue-hero-icons/solid'

export default {
  components: {
    XIcon,
    ClipboardListIcon
  },

  data() {
    return {
      isLoading: false,
      formData: {
        language: null,
        currency: null,
        timeZone: null,
        dateFormat: null,
        fiscalYear: null,
      },
      isRequestOnGoing: false,
      discount_per_item: null,
      idle_time_to_logout: null,
      permissionModule: {
        update: false,
        create: false,
        read: false,
        delete: false,
      }
    }
  },

  props: {
    showSideBar: {
      type: Boolean,
      required: true
    },
    toggleSideBar: {
      type:Function,
      required: true
    }
  },

  validations: {
    formData: {
      currency: {
        required,
      },
      language: {
        required,
      },
      dateFormat: {
        required,
      },
      timeZone: {
        required,
      },
      fiscalYear: {
        required,
      },
    },
    idle_time_to_logout: {
      required,
      between: between(1, 60)
    },
    
  },

  computed: {
    ...mapGetters([
      'currencies',
      'timeZones',
      'dateFormats',
      'fiscalYears',
      'languages',
    ]),

    ...mapGetters('company', ['defaultFiscalYear', 'defaultTimeZone']),

    currencyError() {
      if (!this.$v.formData.currency.$error) {
        return ''
      }
      if (!this.$v.formData.currency.required) {
        return this.$tc('validation.required')
      }
    },

    listIcon() {
      return this.showSideBar ? 'x-icon' : 'clipboard-list-icon';
    },

    languageError() {
      if (!this.$v.formData.language.$error) {
        return ''
      }
      if (!this.$v.formData.language.required) {
        return this.$tc('validation.required')
      }
    },

    timeZoneError() {
      if (!this.$v.formData.timeZone.$error) {
        return ''
      }
      if (!this.$v.formData.timeZone.required) {
        return this.$tc('validation.required')
      }
    },

    fiscalYearError() {
      if (!this.$v.formData.fiscalYear.$error) {
        return ''
      }
      if (!this.$v.formData.fiscalYear.required) {
        return this.$tc('settings.company_info.errors.required')
      }
    },

    dateFormatError() {
      if (!this.$v.formData.dateFormat.$error) {
        return ''
      }

      if (!this.$v.formData.dateFormat.required) {
        return this.$tc('validation.required')
      }
    },

    idleTimeError() {
      if (!this.$v.idle_time_to_logout.$error) {
        return ''
      }
      if (!this.$v.idle_time_to_logout.required) {
        return this.$t('validation.required')
      }
    },
  },

  async mounted() {
    this.setInitialData()
  },

  created(){
    this.permissionsUserModule()
  },

  methods: {
    ...mapActions('company', [
      'setDefaultCurrency',
      'fetchCompanySettings',
      'updateCompanySettings',
    ]),

    ...mapActions([
      'fetchLanguages',
      'fetchFiscalYears',
      'fetchDateFormats',
      'fetchTimeZones',
    ]),

    ...mapActions('user', ['getUserModules']),

    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`
    },

    async setInitialData() {
      this.isRequestOnGoing = true

      await this.fetchDateFormats()
      await this.fetchLanguages()
      await this.fetchFiscalYears()
      await this.fetchTimeZones()

      let response = await this.fetchCompanySettings([
        'currency',
        'time_zone',
        'language',
        'fiscal_year',
        'carbon_date_format',
        'moment_date_format',
        'discount_per_item',
        'idle_time_logout',
      ])

      if (response.data) {
        response.data.discount_per_item === 'YES'
          ? (this.discount_per_item = true)
          : (this.discount_per_item = false)

        this.formData.currency = this.currencies.find(
          (currency) => currency.id == response.data.currency
        )

        this.formData.language = this.languages.find(
          (language) => language.code == response.data.language
        )

        this.formData.timeZone = this.timeZones.find(
          (timeZone) => timeZone.value == this.defaultTimeZone
        )

        this.formData.fiscalYear = this.fiscalYears.find(
          (fiscalYear) => fiscalYear.value == this.defaultFiscalYear
        )

        this.formData.dateFormat = this.dateFormats.find(
          (dateFormat) =>
            dateFormat.carbon_format_value == response.data.carbon_date_format
        )

        this.idle_time_to_logout = response.data.idle_time_logout ?? ''
      }

      this.isRequestOnGoing = false
    },

    async updatePreferencesData() {
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      let data = {
        settings: {
          currency: this.formData.currency.id,
          time_zone: this.formData.timeZone.value,
          fiscal_year: this.formData.fiscalYear.value,
          language: this.formData.language.code,
          carbon_date_format: this.formData.dateFormat.carbon_format_value,
          moment_date_format: this.formData.dateFormat.moment_format_value,
        },
      }
      let response = await this.updateCompanySettings(data)
      if (response.data.success) {
        this.isLoading = false
        // window.i18n.locale = this.formData.language.code
        this.setDefaultCurrency(this.formData.currency)
        window.toastr['success'](
          this.$t('settings.preferences.updated_message')
        )
        return true
      }
      window.toastr['error'](response.data.error)
      return true
    },

    async setDiscount() {
      let data = {
        settings: {
          discount_per_item: this.discount_per_item ? 'YES' : 'NO',
        },
      }
      let response = await this.updateCompanySettings(data)
      if (response.data.success) {
        window.toastr['success'](this.$t('general.setting_updated'))
      }
    },

    async updateIdleTimeLogout() {
      this.$v.idle_time_to_logout.$touch()
      if (this.$v.idle_time_to_logout.$error) {
        return true
      }
      let data = {
        settings: {
          idle_time_logout: this.idle_time_to_logout ?? 0,
        },
      }
      let response = await this.updateCompanySettings(data)
      if (response.data.success) {
        window.toastr['success'](this.$t('general.setting_updated'))
      }
    },

    async permissionsUserModule(){
      const data = {
         module: "preferences" 
      }
      const permissions = await this.getUserModules(data)
      // valida que el usuario tenga permiso para ingresar al modulo
      if(permissions.super_admin == false){
        if(permissions.exist == false ){
          this.$router.push('/admin/dashboard')
        }else {
         const modulePermissions = permissions.permissions[0]
          if(modulePermissions == null){
            this.$router.push('/admin/dashboard')
          }else if(modulePermissions.access == 0 || modulePermissions.read == 0){
            this.$router.push('/admin/dashboard')
          }
        }
      }

      // valida que el usuario tenga el permiso create, read, delete, update
      if(permissions.super_admin == true){
        this.permissionModule.create = true
        this.permissionModule.update = true
        this.permissionModule.delete = true
        this.permissionModule.read = true
      }else if(permissions.exist == true ){
        const modulePermissions = permissions.permissions[0]
        if(modulePermissions.create == 1){
            this.permissionModule.create = true
        }
        if(modulePermissions.update == 1){
            this.permissionModule.update = true
        }
        if(modulePermissions.delete == 1){
            this.permissionModule.delete = true
        }
        if(modulePermissions.read == 1){
            this.permissionModule.read = true
        }
      }

    }
  },
}
</script>

<style scoped>

p{
  margin-left: 50px;
}

</style>