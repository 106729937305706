var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c(
            "div",
            [
              _c("h6", { staticClass: "sw-section-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.add_ons.title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "mt-2 text-sm leading-snug text-gray-500",
                  staticStyle: { "max-width": "680px" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("settings.add_ons.description")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/settings/modules",
                      title: _vm.$t("settings.customization.modules.title"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/settings/pbx`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            variant: "gray",
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.add_ons.add_on"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(_vm.$t("settings.add_ons.add_on"))),
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [
                      _c("img", { attrs: { src: row.image, width: "150px" } }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.add_ons.addon_name"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.add_ons.addon_name"))),
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [_vm._v(_vm._s(row.name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.add_ons.addon_description"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.add_ons.addon_description"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      },
                      [_vm._v(_vm._s(row.description))]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      },
                      [_vm._v(_vm._s(row.version))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("paypal.status"),
              show: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("paypal.status")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-33px" },
                          attrs: {
                            "v-model": row.status == "A" ? true : false,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeStatus(row.id, row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }