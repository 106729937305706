var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("navigation.services") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { title: _vm.$t("general.home"), to: "dashboard" },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("navigation.services"),
                      to: "#",
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalServices > 0,
                      expression: "totalServices > 0",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0",
                      attrs: { label: _vm.$tc("customers.service_number") },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          staticClass: "mt-2",
                          attrs: { type: "text", name: "name" },
                          model: {
                            value: _vm.filters.service_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "service_number", $$v)
                            },
                            expression: "filters.service_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0",
                      attrs: { label: _vm.$tc("customers.title") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-3",
                        attrs: {
                          type: "text",
                          name: "name",
                          placeholder: _vm.$t("customers.title"),
                        },
                        model: {
                          value: _vm.filters.name_customer,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name_customer", $$v)
                          },
                          expression: "filters.name_customer",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.type_service") },
                    },
                    [
                      _vm.filters.type
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-gray-400 cursor-pointer",
                              staticStyle: {
                                top: "55%",
                                right: "1%",
                                "z-index": "999999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.filters.type = null
                                },
                              },
                            },
                            [_c("x-circle-icon", { staticClass: "h-5" })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-select",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.typeServicesOptions,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("customers.type_service"),
                          },
                          model: {
                            value: _vm.filters.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "type", $$v)
                            },
                            expression: "filters.type",
                          },
                        },
                        [_c("div", [_c("span", [_vm._v("icon")])])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.status") },
                    },
                    [
                      _vm.filters.status
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-gray-400 cursor-pointer",
                              staticStyle: {
                                top: "55%",
                                right: "1%",
                                "z-index": "999999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.filters.status = null
                                },
                              },
                            },
                            [_c("x-circle-icon", { staticClass: "h-5" })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.statusServicesOptions,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customers.status"),
                          label: "text",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "2em", "margin-right": "2em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.tenants") },
                    },
                    [
                      _vm.filters.tenant
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-gray-400 cursor-pointer",
                              staticStyle: {
                                top: "55%",
                                right: "5%",
                                "z-index": "999999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.filters.tenant = null
                                },
                              },
                            },
                            [_c("x-circle-icon", { staticClass: "h-5" })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.pbxTenantsOptions,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customers.tenants"),
                          label: "name",
                        },
                        model: {
                          value: _vm.filters.tenant,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "tenant", $$v)
                          },
                          expression: "filters.tenant",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-25" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("customers.date_act") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.activation_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "activation_date", $$v)
                          },
                          expression: "filters.activation_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("customers.renewal_date") },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.renewal_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "renewal_date", $$v)
                          },
                          expression: "filters.renewal_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          staticClass: "-mt-2 md:mt-0",
          attrs: {
            data: _vm.fetchData,
            "show-filter": false,
            "table-class": "table",
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("customers.service_number"),
              show: "service_number",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("customers.service_number"))),
                    ]),
                    _vm._v(" "),
                    row.type == "PBX" &&
                    _vm.permissionModule.readServicesPBX == true
                      ? _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: { to: _vm.routerPath(row) },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.service_number) +
                                "\n        "
                            ),
                          ]
                        )
                      : row.type == "NORMAL" &&
                        _vm.permissionModule.readServicesNormal == true
                      ? _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: { to: _vm.routerPath(row) },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.service_number) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.service_number) +
                              "\n        "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              "sort-as": "customer_id",
              label: _vm.$t("customers.title"),
              show: "user.name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: { path: `customers/${row.customer_id}/view` },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.user.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("customers.type_service"),
              show: "type",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("customers.type_service"))),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(row.type))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("customers.status"),
              show: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("customers.status")))]),
                    _vm._v(" "),
                    _c("span", [
                      row.status == "A" || row.status == "Active"
                        ? _c(
                            "div",
                            [
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor(
                                        "COMPLETED"
                                      ).bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor(
                                        "COMPLETED"
                                      ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("general.active")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      row.status == "P" || row.status == "Pending"
                        ? _c(
                            "div",
                            [
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor("VIEWED")
                                        .bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor("VIEWED")
                                        .color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("general.pending")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      row.status == "S" || row.status == "Suspended"
                        ? _c(
                            "div",
                            [
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor("SENT")
                                        .bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor("SENT")
                                        .color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n         " +
                                      _vm._s(_vm.$t("general.suspended")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      row.status == "C" || row.status == "Cancelled"
                        ? _c(
                            "div",
                            [
                              _c(
                                "sw-badge",
                                {
                                  staticClass: "px-3 py-1",
                                  attrs: {
                                    "bg-color":
                                      _vm.$utils.getBadgeStatusColor("OVERDUE")
                                        .bgColor,
                                    color:
                                      _vm.$utils.getBadgeStatusColor("OVERDUE")
                                        .color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("general.cancelled")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("customers.date_act"),
              show: "activation_date",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("customers.date_act")))]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(row.activation_date))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("customers.renewal_date"),
              show: "renewal_date",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("customers.renewal_date"))),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(row.renewal_date))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown no-click",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (row) {
                    return row.status == "A"
                      ? [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("general.actions"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              _vm.permissionModule.createInvoices
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "invoices.create",
                                          query:
                                            row.type == "PBX"
                                              ? {
                                                  from: "pbx_services",
                                                  code: row.service_number,
                                                  pbx_service_id: row.id,
                                                  customer_id: row.customer_id,
                                                  package_id: row.package_id,
                                                }
                                              : {
                                                  from: "customer",
                                                  code: row.service_number,
                                                  customer_packages_id: row.id,
                                                  customer_id: row.customer_id,
                                                  package_id: row.package_id,
                                                },
                                        },
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("calculator-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t("invoices.new_invoice")
                                          ) +
                                          "\n          "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  row.type == "PBX" &&
                                  _vm.permissionModule.readServicesPBX == true
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            to:
                                              row.type == "PBX"
                                                ? `/admin/customers/${row.customer_id}/pbx-service/${row.id}/view`
                                                : `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                            "tag-name": "router-link",
                                          },
                                        },
                                        [
                                          _c("cog-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.manage")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : row.type == "NORMAL" &&
                                      _vm.permissionModule.readServicesNormal ==
                                        true
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            to:
                                              row.type == "PBX"
                                                ? `/admin/customers/${row.customer_id}/pbx-service/${row.id}/view`
                                                : `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                            "tag-name": "router-link",
                                          },
                                        },
                                        [
                                          _c("cog-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.manage")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status != "C"
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            to:
                                              row.type == "PBX"
                                                ? `/admin/customers/${row.customer_id}/pbx-service/${row.id}/edit`
                                                : `/admin/customers/${row.customer_id}/service/${row.id}/edit`,
                                            "tag-name": "router-link",
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }