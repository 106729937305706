var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-8 py-8 sm:p-6" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("customer_ticket.reference"),
                    variant: "horizontal",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text", readonly: "" },
                    model: {
                      value: _vm.formData.reference,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "reference", $$v)
                      },
                      expression: "formData.reference",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("customer_ticket.subject"),
                    variant: "horizontal",
                    error: _vm.subjectError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: { type: "text" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.subject.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "subject", $$v)
                      },
                      expression: "formData.subject",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("customer_ticket.message"),
                    variant: "horizontal",
                    error: _vm.messageError,
                    required: "",
                  },
                },
                [
                  _c("sw-textarea", {
                    attrs: { type: "text" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.message.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "message", $$v)
                      },
                      expression: "formData.message",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: "Date",
                    error: _vm.dateError,
                    required: "",
                    variant: "horizontal",
                  },
                },
                [
                  _c("base-date-picker", {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                      disabled: _vm.isEdit,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.date.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "date", $$v)
                      },
                      expression: "formData.date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: "Time",
                    error: _vm.timeError,
                    required: "",
                    variant: "horizontal",
                  },
                },
                [
                  _c("base-time-picker", {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      invalid: _vm.$v.formData.time.$error,
                      "calendar-button": true,
                      placeholder: "HH:mm",
                      "calendar-button-icon": "calendar",
                      disabled: _vm.isEdit,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.time.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "time", $$v)
                      },
                      expression: "formData.time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "primary-outline", type: "button" },
                      on: { click: _vm.closeExtensionModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    { attrs: { variant: "primary", type: "submit" } },
                    [
                      _c("save-icon", { staticClass: "mr-2" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.save")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }