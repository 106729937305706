<template>
  <base-page>
    <sw-page-header :title="pageTitle" class="mb-5">
      <template slot="actions"> </template>
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item :title="$t('general.home')" to="/admin/dashboard" />
        <sw-breadcrumb-item
          :title="$tc('payments.payment', 2)"
          to="/admin/payments"
        />
        <sw-breadcrumb-item
          :title="paymentRoute === 'Invoices' ? 'Invoices' : 'Credit'"
          to="#"
          active
        />
      </sw-breadcrumb>
    </sw-page-header>

    <div class="grid lg:grid-cols-12 sm:grid-cols-1 gap-8 mt-1 mb-2">
      <div class="lg:col-span-8 md:col-span-8 sm:col-span-12">
        <sw-card class="bg-white">
          <sw-input-group class="col-span-1" label="Customers">
            <sw-select
              v-model="customer"
              :options="customers_options"
              :searchable="true"
              :show-labels="false"
              placeholder="Customer select"
              :max-height="150"
              label="name"
              class="mt-1"
              required
              @select="updateCustomerCustomerData"
            >
            </sw-select>
          </sw-input-group>
          <br />
          <h1 class="font-semibold mb-4 text-lg">Payment Method</h1>
          <div class="flex gap-4 mb-4">
            <custom-sw-button
              :variant="
                paymentType === 'oneTime' ? 'primary' : 'primary-outline'
              "
              size="lg"
              @click="changePaymentType('oneTime')"
              :selected="paymentType === 'oneTime'"
              class="w-full"
            >
              <credit-card-icon class="mr-2" /> One-time Payment
            </custom-sw-button>
            <custom-sw-button
              :variant="paymentType === 'saved' ? 'primary' : 'primary-outline'"
              size="lg"
              @click="changePaymentType('saved')"
              :selected="paymentType === 'saved'"
              class="w-full"
            >
              <save-icon class="mr-2" /> Use Saved Payment Method
            </custom-sw-button>
          </div>

          <!-- Formulario de One-time Payment -->
          <div v-if="paymentType === 'oneTime'" class="col-span-12 mt-4">
            <PaymentForm
              @paymentInformation="handlePaymentInformation"
              :paymentType="paymentType"
              :accounts="accounts"
              :isNewMethod="false"
              :customer="customer"
            />
          </div>
          <div
            v-if="
              this.accounts != null &&
              this.accounts.length > 0 &&
              paymentType === 'saved'
            "
          >
            <div
              v-for="(account, index) in accounts"
              :key="account.id"
              :class="[
                'flex items-center p-4 mb-4 rounded border cursor-pointer',
                {
                  'saved-method': selectedAccountId === account.id,
                },
              ]"
              @click="selectAccount(account)"
            >
              <div
                v-if="account.payment_account_type === 'CC'"
                class="flex items-center"
              >
                <input
                  type="checkbox"
                  class="mr-2"
                  :checked="selectedAccountId === account.id"
                />
                <component
                  :is="getCreditCardComponent(account.credit_card)"
                  class="ml-2 mr-2"
                />
                <div class="flex flex-col">
                  <span class="font-semibold">
                    {{ account.credit_card }} ending in
                    {{ getLastFourDigits(account.card_number) }}
                  </span>
                  <span class="text-gray-600">{{
                    getSavedAddress(account)
                  }}</span>
                </div>
              </div>
              <div
                v-if="account.payment_account_type === 'ACH'"
                class="flex items-center"
              >
                <input
                  type="checkbox"
                  class="mr-2"
                  :checked="selectedAccountId === account.id"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="50px"
                  height="50px"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                  />
                </svg>

                <div class="flex flex-col">
                  <span class="font-semibold">
                    {{ account.ACH_type }} ending in
                    {{ getLastFourDigits(account.account_number) }}
                  </span>
                  <span class="text-gray-600">{{
                    getSavedAddress(account)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Botón para agregar nuevo método de pago -->
          <div v-if="paymentType === 'saved' && !showPaymentForm" class="mt-4">
            <button class="btn w-full" @click="togglePaymentForm">
              + Add New Payment Account
            </button>
          </div>

          <!-- Formulario para agregar nuevo método de pago -->
          <div v-if="showPaymentForm" class="col-span-12 mt-4">
            <PaymentForm
              @saveNewMethod="saveNewPaymentMethod"
              @closeForm="handleCloseForm"
              :isNewMethod="true"
              :paymentType="paymentType"
              :formData="formData"
              :accounts="accounts"
              :customer="customer"
            />
          </div>

          <!-- Mostrar dirección guardada y opciones de edición -->
          <div
            v-if="this.defaultPaymentdisplay != null && paymentType === 'saved'"
            class="mt-4 saved-address"
          >
            <h3 class="font-semibold mb-2 text-lg">Billing Address</h3>
            <p><strong>Default Billing Address:</strong></p>
            <p class="text-gray-700">{{ savedAddress }}</p>
            <a
              href="#"
              @click.prevent="toggleEdit(defaultPaymentdisplay)"
              class="edit-link text-blue-600"
              >Edit</a
            >
          </div>
        </sw-card>
      </div>

      <div class="md:col-span-4 lg:col-span-4 sm:col-span-12">
        <Invoices
          :customer="customer"
          v-if="paymentRoute == 'Invoices'"
          @payInvoice="payInvoice"
          :fees="fees"
          :isidentificationverification="isidentificationverification"
          :disablePay="disablePay"
          :formData="formData"
          :defaultPaymentdisplay="defaultPaymentdisplay"
          :defaultPaymentGateway="defaultPaymentGateway"
          :paymentType="paymentType"
          :cardSaveText="cardSaveText"
          :Last4digittext="Last4digittext"
          :creditCardId="creditCardId"
          :achAccountId="achAccountId"
          :invoiceObject="invoiceObject"
          :idCurrenUserGeneral="idCurrenUserGeneral"
        />
        <Credit
          :customer="customer"
          v-if="paymentRoute == 'Credit'"
          :fees="fees"
          :disablePay="disablePay"
          :isidentificationverification="isidentificationverification"
          :formData="formData"
          :defaultPaymentdisplay="defaultPaymentdisplay"
          :defaultPaymentGateway="defaultPaymentGateway"
          :paymentType="paymentType"
          :cardSaveText="cardSaveText"
          :Last4digittext="Last4digittext"
          :creditCardId="creditCardId"
          :achAccountId="achAccountId"
     
          :idCurrenUserGeneral="idCurrenUserGeneral"
        />
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { CreditCardIcon, SaveIcon } from '@vue-hero-icons/outline'
import PaymentForm from './PaymentForm.vue'
import Invoices from './Invoices.vue'
import Credit from './Credit.vue'
import CustomSwButton from './components/CustomSwButton.vue'

export default {
  components: {
    CustomSwButton,
    CreditCardIcon,
    SaveIcon,
    PaymentForm,
    Invoices,
    Credit,
  },
  data() {
    return {
      customers_options: [],
      customer: null,
      id: null,
      idCurrenUserGeneral: 0,
      paymentType: 'oneTime',
      accounts: [],
      payment_accounts: [],
      fees: [],
      disablePay: true,
      showPaymentForm: false,
      isStorageDataAvailable: false,
      isidentificationverification: false,
      lastFourDigits: '',
      selectedAccountId: null,
      savedAddress: '',
      savedCard: {},
      formData: {},
      paymentType: 'oneTime',
      isEditing: false,
      editContext: '',
      editFormData: {
        address: '',
        address2: '',
        city: '',
        state: null,
        zip: '',
        country: null,
      },
      defaultPayment: [],
      defaultPaymentdisplay: null,
      defaultPaymentGateway: null,
      countries: [],
      states: [],
      cardSaveText: '',
      Last4digittext: '',
      creditCardId: null,
      achAccountId: null,
      invoiceObject: null,
    }
  },
  computed: {
    // ...mapGetters('user', ['currentUser']),

    ...mapState('user', ['currentUser', 'settingsCompany']),

    pageTitle() {
      if (this.$route.name === 'paymentsAdmin.credit') {
        return 'Add Credit'
      }
      return this.$t('payments.new_payment')
    },
    paymentRoute() {
      if (
        this.$route.name === 'paymentsAdmin.invoices' ||
        this.$route.name === 'paymentsAdmin.invoicesById'
      ) {
        return 'Invoices'
      }
      return 'Credit'
    },
  },
  watch: {
    formData: {
      handler(newVal) {
        console.log('formData changed in index:', newVal)

        if (this.paymentType == 'oneTime') {
          this.changeDataCardformData()
        }
      },
      deep: true,
    },
    paymentType(newVal, oldVal) {
      console.log(`paymentType cambió de ${oldVal} a ${newVal}`)
      // Aquí puedes añadir la lógica que necesites cuando paymentType cambie
      this.cardSaveText = ''
      this.Last4digittext = ''
      if (newVal == 'saved') {
        this.changeDataCard()
      }
    },
    // Watcher para defaultPaymentdisplay
    defaultPaymentdisplay: {
      handler(newVal, oldVal) {
        console.log('defaultPaymentdisplay cambió:', newVal)
        // Aquí puedes añadir la lógica que necesites cuando defaultPaymentdisplay cambie

        if (this.paymentType == 'saved') {
          this.changeDataCard()
        }
      },
      deep: true, // Esto permite observar cambios en propiedades anidadas
    },
  },
  created() {
    //this.loadCountries()
    this.loadData()
    // this.loadStoredAddress()
    this.setDefaultPaymentGateway()
    console.log(this.currentUser)

    if (this.currentUser) {
      this.idCurrenUserGeneral = this.currentUser.id
    }
  },
  methods: {
    ...mapActions('paymentAccounts', ['fetchPaymentAccounts']),
    ...mapActions('modal', ['openModal']),
    ...mapActions('paymentGateways', ['fetchPaymentGateways']),
    ...mapActions('payment', ['paymentsMethodActiveCustomerCredit','fetchPaymentModes']),
    ...mapActions('customer', ['fetchCustomers', 'fetchCustomer']),
    ...mapActions('invoice', ['fetchInvoice']),

    /**
     * Cambia el tipo de pago y actualiza el estado del formulario de pago.
     *
     * @param {string} value - El nuevo tipo de pago seleccionado.
     */
    changePaymentType(value) {
      // Actualiza el tipo de pago seleccionado
      this.paymentType = value

      // Deshabilita el botón de pago
      this.disablePay = true

      // Oculta el formulario de pago
      this.showPaymentForm = false

      // Actualiza el tipo de pago en los datos del formulario
      this.formData.paymentType = this.paymentType
    },

    /**
     * Actualiza los textos de la tarjeta guardada y los últimos 4 dígitos
     * según la información de la cuenta de pago predeterminada.
     */
    changeDataCard() {
      // Inicializar los textos
      this.cardSaveText = ''
      this.Last4digittext = ''

      // Verificar si defaultPaymentdisplay está definido
      if (this.defaultPaymentdisplay) {
        console.log(this.defaultPaymentdisplay)

        // Verificar el tipo de cuenta de pago
        const paymentType = this.defaultPaymentdisplay.payment_account_type
        const cardNumber = this.defaultPaymentdisplay.card_number
        const accountNumber = this.defaultPaymentdisplay.account_number

        if (paymentType === 'CC') {
          // Asignar el texto de la tarjeta de crédito
          this.cardSaveText = this.defaultPaymentdisplay.credit_card

          // Validar y asignar los últimos 4 dígitos del número de tarjeta
          this.Last4digittext =
            cardNumber && cardNumber.length >= 4 ? cardNumber.slice(-4) : '' // Valor por defecto si no cumple la condición
        } else {
          // Asignar el texto del tipo de cuenta ACH
          this.cardSaveText = this.defaultPaymentdisplay.ACH_type

          // Validar y asignar los últimos 4 dígitos del número de cuenta
          this.Last4digittext =
            accountNumber && accountNumber.length >= 4
              ? accountNumber.slice(-4)
              : '' // Valor por defecto si no cumple la condición
        }
      }
    },

    /**
     * Actualiza los textos de la tarjeta o cuenta ACH en formData.
     *
     * Este método verifica el tipo de cuenta de pago y actualiza los textos correspondientes para la tarjeta de crédito o la cuenta ACH.
     */
    changeDataCardformData() {
      // Inicializar los textos
      this.cardSaveText = ''
      this.Last4digittext = ''

      // Verificar si formData está definido
      if (this.formData && this.formData.payment_account_type) {
        // Verificar el tipo de cuenta de pago
        if (this.formData.payment_account_type === 'CC') {
          // Verificar si los datos de la tarjeta de crédito están presentes
          if (
            this.formData.card_number &&
            this.formData.credit_card &&
            this.formData.credit_card.value
          ) {
            // Asignar el texto de la tarjeta de crédito
            this.cardSaveText = this.formData.credit_card.value

            // Validar y asignar los últimos 4 dígitos del número de tarjeta
            this.Last4digittext =
              this.formData.card_number.length >= 4
                ? this.formData.card_number.slice(-4)
                : '' // Valor por defecto si no cumple la condición
          }
        } else {
          // Verificar si los datos de la cuenta ACH están presentes
          if (
            this.formData.account_number &&
            this.formData.ACH_type &&
            this.formData.ACH_type.value
          ) {
            // Asignar el texto del tipo de cuenta ACH
            this.cardSaveText = this.formData.ACH_type.value

            // Validar y asignar los últimos 4 dígitos del número de cuenta
            this.Last4digittext =
              this.formData.account_number.length >= 4
                ? this.formData.account_number.slice(-4)
                : '' // Valor por defecto si no cumple la condición
          }
        }
      }
    },

    handlePaymentInformation(formData) {
      this.formData = formData
    },
    /**
     * Actualiza los datos del cliente y sus cuentas de pago.
     *
     * Este método obtiene las cuentas de pago del cliente, las ordena colocando la cuenta principal primero,
     * y formatea los datos para su uso en un selector de método de pago.
     *
     * @param {Object} val - Objeto que contiene la información del cliente.
     */
    async updateCustomerCustomerData(val) {
      console.log('Inicio de updateCustomerCustomerData')
      console.log('Cliente actual:', this.customer)
      console.log('Valor recibido:', val)
      console.log('Cuentas actuales:', this.accounts)
      console.log('Pago por defecto actual:', this.defaultPayment)

      // Inicializa las cuentas y el pago por defecto
      this.accounts = []
      this.defaultPayment = []
      this.invoiceObject = null

      // Verifica si el cliente es válido
      if (val && val.id) {
        console.log('Cliente encontrado:', val)

        // Prepara los datos para la solicitud
        let data = {
          customer_id: val.id,
          limit: 1000,
        }
        console.log('Datos para fetchPaymentAccounts:', data)

        // Realiza la solicitud para obtener las cuentas de pago
        let response = await this.fetchPaymentAccounts(data)
        console.log('Respuesta de fetchPaymentAccounts:', response)

        // Asigna las cuentas obtenidas
        this.accounts = response.data.payment_accounts.data
        console.log('Cuentas obtenidas:', this.accounts)

        // Ordena las cuentas, colocando la cuenta principal primero
        if (this.accounts && this.accounts.length > 0) {
          this.accounts.sort((a, b) => b.main_account - a.main_account)
          console.log('Cuentas ordenadas:', this.accounts)
        }

        // Filtra y mapea las cuentas para el selector de método de pago
        this.defaultPayment = this.accounts.map((account) => {
          let displayNumber = ''

          // Formatea el número de cuenta según el tipo de cuenta
          if (account.payment_account_type === 'CC') {
            // Formateo para tarjetas de crédito
            const auxCardNumber = account.card_number.toString().split('')
            let showCardNumber = ''
            const limit = auxCardNumber.length - 4
            auxCardNumber.forEach((el, i) => {
              showCardNumber += i < limit ? '*' : el
            })
            displayNumber = `${account.first_name} - ${showCardNumber} - (CC)`
          } else if (account.payment_account_type === 'ACH') {
            // Formateo para cuentas ACH
            const auxAccountNumber = account.account_number.toString().split('')
            let showAccountNumber = ''
            const limit = auxAccountNumber.length - 4
            auxAccountNumber.forEach((el, i) => {
              showAccountNumber += i < limit ? '*' : el
            })
            displayNumber = `${account.bank_name} - ${showAccountNumber} - (ACH)`
          }

          console.log('Número de cuenta formateado:', displayNumber)

          // Buscar el registro que tenga main_account igual a 1
          const defaultAccount = this.accounts.find(
            (account) => account.main_account === 1
          )
          console.log('Cuenta principal encontrada:', defaultAccount)

          // Asignar el registro encontrado a this.defaultPaymentdisplay o null si no se encuentra
          this.defaultPaymentdisplay = defaultAccount || null

          if (this.defaultPaymentdisplay) {
            console.log(
              'Asignando cuenta principal a defaultPaymentdisplay:',
              this.defaultPaymentdisplay
            )
            this.setLastFourDigits()
            this.setSavedAddress()
            this.formData.defaultPaymentdisplay = this.defaultPaymentdisplay
            this.selectedAccountId = this.defaultPaymentdisplay.id
          }

          return {
            name: displayNumber,
            value: account.id,
          }
        })

        console.log('defaultPayment:', this.defaultPayment)
      } else {
        console.log('No se encontró cliente o cliente.id')
      }

      console.log('Fin de updateCustomerCustomerData')
    },

    /**
     * Establece el cliente seleccionado para el pago.
     * Busca en la lista de clientes por ID y asigna el cliente encontrado a 'this.customer'.
     * @param {number} id - El ID del cliente a establecer.
     */
    setPaymentCustomer(id) {
      console.log('Prueba entrro ')
      console.log(id)

      this.customer = this.customers_options.find((c) => {
        return c.id === id
      })
      if (this.customer) {
        this.idCustomerGeneral = this.customer.id
        this.updateCustomerCustomerData(this.customer)
      }
    },

    /**
     * Carga los datos de las cuentas de pago del usuario.
     *
     * Este método asíncrono obtiene las cuentas de pago del usuario actual, las ordena y formatea para su uso en el formulario de selección de método de pago.
     */
    async loadData() {
      try {
        const customer = await this.fetchCustomers({ limit: 'all' })

        if (
          customer &&
          customer.data &&
          customer.data.customers &&
          Array.isArray(customer.data.customers.data)
        ) {
          this.customers_options = customer.data.customers.data

          if (this.customers_options.length === 0) {
            console.error('No se encontraron clientes.')
          } else {
            console.log('Clientes cargados exitosamente.')
          }
        } else {
          console.error(
            'La estructura de la respuesta no es válida o no se encontraron clientes.'
          )
          this.customers_options = []
        }
      } catch (error) {
        console.error('Error al cargar los clientes: ', error)
        this.customers_options = []
      }

      this.formData.paymentType = this.paymentType

      /// query custromer
      console.log(this.$route.query)
      console.log(this.$route.query.customer)
      if (this.$route.query.customer) {
        console.log(this.$route.query.customer)
        this.setPaymentCustomer(parseInt(this.$route.query.customer))
      }

      console.log(this.$route.params)
      if (this.$route.params.idInvoice) {
        const invoicesId = parseInt(this.$route.params.idInvoice)
        if (!isNaN(invoicesId)) {
          try {
            let invoice = await this.fetchInvoice(invoicesId)
            this.invoiceObject = invoice.data.invoice

            this.setPaymentCustomer(parseInt(this.invoiceObject.user_id))
          } catch (error) {
            console.error('Error fetching invoice:', error)
          }
        } else {
          console.log('No se proporcionó un idInvoice válido.')
        }
      }

      // Asigna información del usuario a formData
      // this.formData.user_id = this.currentUser.id
      // this.formData.email = this.currentUser.email

      try {
      

        let res = await this.fetchPaymentModes({ limit: 'all' })
        const methodData = res.data.paymentMethodsWithSettings
        console.log('methods: ', methodData)

     
        const firstCreditCardAccount = methodData.find(
          (method) => method.account_accepted === 'C'
        )
        const creditCardId = firstCreditCardAccount
          ? firstCreditCardAccount.id
          : null

        const firstAchAccount = methodData.find(
          (method) => method.account_accepted === 'A'
        )
        const achAccountId = firstAchAccount ? firstAchAccount.id : null

        this.creditCardId = creditCardId
        this.achAccountId = achAccountId

        console.log(
          'ids payments method accounts: ',
          this.creditCardId,
          this.achAccountId
        )

      
      } catch (error) {
        console.error('Error fetching payment accounts:', error)
      }
    },

    /**
     * Cierra el formulario de pago y actualiza los datos.
     *
     * Este método asíncrono oculta el formulario de pago, recarga los datos y selecciona la cuenta correspondiente si se ha guardado un método de pago.
     */
    async handleCloseForm() {
      // Oculta el formulario de pago
      this.showPaymentForm = false

      // Recarga los datos
      await this.loadData()

      // Verifica si se ha guardado un método de pago
      if (this.formData.saved_pay) {
        // Determina el número de cuenta a buscar basado en el tipo de cuenta de pago
        const accountNumberToFind =
          this.formData.payment_account_type === 'CC'
            ? this.formData.card_number
            : this.formData.account_number

        // Busca la cuenta correspondiente en la lista de cuentas
        const accountToSelect = this.accounts.find((account) => {
          return (
            (account.payment_account_type === 'CC' &&
              account.card_number === accountNumberToFind) ||
            (account.payment_account_type === 'ACH' &&
              account.account_number === accountNumberToFind)
          )
        })

        // Si se encuentra la cuenta, la selecciona
        if (accountToSelect) {
          this.selectAccount(accountToSelect)
        }
      }
    },

    /**
     * Establece la dirección guardada concatenando las partes de la dirección
     * de `defaultPaymentdisplay`, filtrando los valores nulos, indefinidos o vacíos.
     */
    setSavedAddress() {
      if (this.defaultPaymentdisplay) {
        // Extraer las partes de la dirección de defaultPaymentdisplay
        const { address_1, address_2, city, state_name, zip, country_name } =
          this.defaultPaymentdisplay

        // Crear un array con las partes de la dirección
        const addressParts = [
          address_1,
          address_2,
          city,
          state_name,
          zip,
          country_name,
        ]

        // Filtrar los valores que no sean null, undefined o vacíos y unirlos con comas
        this.savedAddress = addressParts.filter(Boolean).join(', ')
      } else {
        // Si defaultPaymentdisplay no está definido, establecer savedAddress como una cadena vacía
        this.savedAddress = ''
      }
    },

    /**
     * Establece la pasarela de pago predeterminada y configura las tarifas de pago si están activas.
     * @param {Object} paymentgateway - El objeto de la pasarela de pago.
     * @param {string} type - El tipo de pasarela de pago.
     */
    async setDefaultPaymentGateway() {
      // Log the input parameters

      let res = await this.fetchPaymentGateways()
      let paymentGateways = res.data.payment_gateways

      // Encontrar el payment gateway con default = 1
      let defaultGateway = paymentGateways.find(
        (gateway) => gateway.default === 1
      )

      if (defaultGateway) {
        this.formData.paymentGateway = defaultGateway
        this.defaultPaymentGateway = defaultGateway
        //  console.log('Payment Gateway por defecto encontrado:', defaultGateway)

        // Verificar si IsPaymentFeeActive existe y es YES
        if (
          defaultGateway.IsPaymentFeeActive &&
          defaultGateway.IsPaymentFeeActive === 'YES'
        ) {
          // console.log('IsPaymentFeeActive es YES')

          // Habilita las tarifas de pago
          this.paymentfeesenabledinvoices = true
          // Establece las tarifas de pago desde los datos de registro de la pasarela de pago
          this.fees = defaultGateway.registrationdatafees

          // Ejecutar las líneas necesarias
          // Aquí puedes agregar el código que necesitas ejecutar
        } else {
          // console.log('IsPaymentFeeActive no es YES o no existe')
        }

        if (
          defaultGateway.IsPaymentFeeActive &&
          defaultGateway.isidentificationverification === 'YES'
        ) {
          this.isidentificationverification = true
        } else {
          //console.log('IsPaymentFeeActive no es YES o no existe')
        }
      } else {
        //console.log('No se encontró ningún Payment Gateway con default = 1')
      }
    },

    /**
     * Alterna la visibilidad del formulario de pago.
     *
     * Este método cambia el estado de `showPaymentForm` a su valor opuesto.
     */
    togglePaymentForm() {
      // Cambia el estado de showPaymentForm a su valor opuesto
      this.showPaymentForm = !this.showPaymentForm
    },

    /**
     * Activa el modo de edición para una dirección específica.
     *
     * Este método establece el contexto de edición con la dirección proporcionada y abre un modal para editar la dirección de facturación.
     *
     * @param {Object} address - La dirección que se va a editar.
     */
    toggleEdit(address) {
      // Descomentar la siguiente línea para ver la dirección en la consola
      // console.log('address: ', address);

      // Activa el modo de edición
      this.isEditing = true

      // Establece el contexto de edición con la dirección proporcionada
      this.editContext = address

      // Abre un modal para editar la dirección de facturación
      this.openModal({
        title: 'Edit Billing Address',
        componentName: 'EditAddressModal',
        id: address.id,
        data: this.editContext,
        refreshData: this.loadData,
      })
    },

    /**
     * Guarda un nuevo método de pago.
     *
     * Este método asíncrono toma los datos del formulario y los guarda en la propiedad `formData`.
     *
     * @param {Object} formData - Los datos del formulario que contienen la información del nuevo método de pago.
     */
    async saveNewPaymentMethod(formData) {
      // Asigna los datos del formulario a la propiedad formData
      this.formData = formData

      // Descomentar la siguiente línea para ver los datos del formulario en la consola
      // console.log('guardar formData: ', this.formData);
    },

    /**
     * Updates the `lastFourDigits` property with the last four digits of the credit card number from the selected account (if available).
     */
    setLastFourDigits() {
      // Check if a default payment is displayed and has a card number
      if (
        this.defaultPaymentdisplay &&
        this.defaultPaymentdisplay.card_number
      ) {
        this.lastFourDigits = this.defaultPaymentdisplay.card_number.slice(-4) // Extract last four digits
      } else {
        // Set lastFourDigits to an empty string if no card number
        this.lastFourDigits = ''
      }
    },
    /**
     * Utility function to extract the last four digits of a number (if provided).
     *
     * @param {string|number} number (Optional) - The number to extract digits from.
     * @returns {string} - The last four digits of the number, or an empty string if no number is provided.
     */
    getLastFourDigits(number) {
      return number ? number.slice(-4) : '' // Extract last four digits or return empty string
    },
    /**
     * Constructs and returns a formatted address string from the account details.
     *
     * @param {Object} account - The account object containing address information.
     * @returns {string} - The formatted address string, combining non-empty address parts.
     */
    getSavedAddress(account) {
      const addressParts = [
        // Address components
        account.address_1,
        account.address_2,
        account.city,
        account.state_name,
        account.zip,
        account.country_name,
      ]

      // Filter out empty address parts before joining
      const filteredParts = addressParts.filter((part) => part)

      // Join filtered address parts with commas
      return filteredParts.join(', ')
    },
    /**
     * Selecciona un account y actualiza el estado de la aplicación.
     *
     * @param {Object} account - El objeto que representa el account seleccionado.
     */
    selectAccount(account) {
      // Asigna el ID del account seleccionado al estado
      this.selectedAccountId = account.id

      // Actualiza la información del formulario y establece la dirección guardada
      this.handleAccountSelection(account)
    },

    /**
     * Realiza las acciones necesarias después de que se selecciona un account.
     * Actualiza el formulario con los datos del account y establece la dirección guardada.
     *
     * @param {Object} account - El objeto que representa el account seleccionado.
     */
    handleAccountSelection(account) {
      // Actualiza el formulario con los datos del account seleccionado
      // (Asumiendo que formData.defaultPaymentdisplay es una referencia al mismo objeto que defaultPaymentdisplay)
      this.formData.defaultPaymentdisplay = this.defaultPaymentdisplay = account

      // Establece la dirección guardada basada en los datos del account
      this.setSavedAddress(account)
    },

    /**
     * Retrieves the Vue component representing a credit card image based on the provided credit card type.
     *
     * @param {string} creditCardType - The credit card type (e.g., "VISA", "MASTERCARD", "AMERICAN EXPRESS", "DISCOVER").
     * @returns {Object|string} - The Vue component or a default class name if the credit card type is invalid.
     */
    getCreditCardComponent(creditCardType) {
      // Define a map of credit card types to their corresponding image information.
      const creditCardImages = {
        VISA: { src: '/images/visa.png', width: '65px' },
        MASTERCARD: { src: '/images/mastercard.png', width: '80px' },
        'AMERICAN EXPRESS': {
          src: '/images/american_express.png',
          width: '90px',
        },
        DISCOVER: { src: '/images/discover.png', width: '100px' },
      }

      // If the credit card type is not provided or is invalid, return a default class name.
      if (!creditCardType || !creditCardImages[creditCardType]) {
        return 'credit-card-icon'
      }

      // Return a Vue component that renders the appropriate credit card image.
      return {
        template: `<img :src="image.src" :width="image.width" />`,
        data() {
          return {
            image: creditCardImages[creditCardType],
          }
        },
      }
    },
  },
}
</script>

<style scoped>
.w-full {
  width: 100%;
}

.saved-method {
  background-color: #f0f4ff;
  border-color: #d1e0ff;
}

.saved-method input[type='checkbox'] {
  appearance: none;
  border: 2px solid #4a90e2;
  border-radius: 4px; /* Cambia a 4px para que sea un cuadrado */
  width: 18px;
  height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saved-method input[type='checkbox']:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #4a90e2;
  border-radius: 2px; /* Cambia a 2px para que sea un cuadrado */
}

.saved-address {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.saved-address h3 {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.saved-address p {
  margin: 0.25rem 0;
}

.edit-link {
  text-decoration: underline;
  cursor: pointer;
}

.remove-link {
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f9f9f9;
  text-align: center;
}

.btn:hover {
  background-color: #007bff;
  color: white;
}
</style>
