var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.service_view") } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _vm.cancel_service && !_vm.isCancelled
                ? _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "danger" },
                      on: { click: _vm.cancelService },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("customer_profile.cancel_service")) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/customer/tickets/add`,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/customer/dashboard`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _vm.getInfoClient ? _c("customer-info") : _vm._e(),
          _vm._v(" "),
          _c("confirm-package", { on: { status: function ($event) {} } }),
          _vm._v(" "),
          _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-5" }, [
            _c("div", { staticClass: "border-b tab" }, [
              _c("div", { staticClass: "relative" }, [
                _c("input", {
                  staticClass:
                    "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                  attrs: { type: "checkbox" },
                }),
                _vm._v(" "),
                _c(
                  "header",
                  {
                    staticClass:
                      "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-gray-500 uppercase sw-section-title",
                      },
                      [_vm._v("\n                INVOICES\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              "aria-hidden": "true",
                              "data-reactid": "266",
                              fill: "none",
                              height: "24",
                              stroke: "#606F7B",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              viewbox: "0 0 24 24",
                              width: "24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: { points: "6 9 12 15 18 9" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tab-content-slide" },
                  [
                    _c("div", { staticClass: "text-grey-darkest" }, [
                      _c("div", { staticClass: "flex base-tabs" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-table-component",
                      {
                        ref: "table",
                        attrs: {
                          "show-filter": false,
                          data: _vm.fetchInvoicesPerServiceData,
                          "table-class": "table",
                        },
                      },
                      [
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.number"),
                            show: "invoice_number",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("invoices.number"))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to: `/customer/invoice/${row.id}/view`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(row.invoice_number) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: "DATE",
                            show: "invoice_date",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: "STATUS",
                            show: "status",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: "PAID STATUS",
                            show: "paid_status",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.total"),
                            "sort-as": "total",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [_vm._v("Total")]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(row.total)
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("invoices.amount_due"),
                            "sort-as": "total",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [_vm._v("AMOUNT DUE")]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(row.due_amount)
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "block my-10 table-foot lg:justify-between lg:flex lg:items-start",
                      },
                      [
                        _c("div", { staticClass: "w-full lg:w-1/2" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between w-full",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-sm font-semibold leading-5 text-gray-500 uppercase mr-12",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("pbx_services.count")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex items-center justify-center m-0 text-lg text-black uppercase ml-12",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(this.invoicesCount)),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }