var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-card", [
        _c("div", { staticClass: "col-span-12" }, [
          _c("div", { staticClass: "space-y-2 max-h-40 overflow-y-auto" }, [
            _c("h2", { staticClass: "text-xl font-semibold mb-6" }, [
              _vm._v("Invoice Detail"),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "text-l font-semibold mb-6" }, [
                _vm._v("Customer: "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-m mb-6" }, [
                _vm._v(" " + _vm._s(this.customer.name)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex items-center p-2 text-sm hover:bg-gray-100 bg-gray-50",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "flex-grow",
                    attrs: { for: _vm.showInvoiceList.id },
                  },
                  [_vm._v(_vm._s(_vm.showInvoiceList.invoice_number))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "font-semibold mr-2" }, [
                  _vm._v(
                    _vm._s(
                      (_vm.showInvoiceList.due_amount / 100).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { class: _vm.getStatusClass(_vm.showInvoiceList.status) },
                  [_vm._v(_vm._s(_vm.showInvoiceList.status))]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("sw-card", [
        _c("h2", { staticClass: "text-xl font-semibold mb-6" }, [
          _vm._v("Invoice Payment Summary"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "space-y-4" },
          [
            _c("div", { staticClass: "flex justify-between items-center" }, [
              _c("span", { staticClass: "text-gray-600" }, [
                _vm._v("Subtotal"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-medium" }, [
                _vm._v("$" + _vm._s(_vm.subTotal.toFixed(2))),
              ]),
            ]),
            _vm._v(" "),
            _vm.fees.length > 0
              ? _c(
                  "div",
                  { staticClass: "space-y-2" },
                  _vm._l(_vm.fees, function (fee) {
                    return _c(
                      "div",
                      {
                        key: fee.id,
                        staticClass:
                          "flex justify-between items-center text-sm w-full",
                      },
                      [
                        fee.type == "fixed"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center w-full",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-gray-600 text-left" },
                                  [_vm._v(_vm._s(fee.name))]
                                ),
                                _vm._v(" "),
                                fee.type == "fixed"
                                  ? _c("div", {
                                      staticClass: "text-right",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            fee.amount,
                                            _vm.defaultCurrency
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        fee.type == "percentage"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center w-full",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-gray-600 text-left" },
                                  [_vm._v(_vm._s(fee.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "font-medium text-right" },
                                  [_vm._v(_vm._s(fee.amount / 100) + " %")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.taxes > 0
              ? _c(
                  "div",
                  { staticClass: "flex justify-between items-center" },
                  [
                    _c("span", { staticClass: "text-gray-600" }, [
                      _vm._v("Taxes"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-medium" }, [
                      _vm._v("$" + _vm._s(_vm.formatCurrency(_vm.taxes))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-between items-center text-lg font-semibold pt-2 border-t",
              },
              [
                _c("span", [_vm._v("Total Fees")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.totalDue))]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-between items-center text-lg font-semibold pt-2 border-t",
              },
              [
                _c("span", [_vm._v("Amount to Pay")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.amountToPay))]),
              ]
            ),
            _vm._v(" "),
            _vm.cardSaveText && _vm.Last4digittext
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-info border border-gray-300 rounded-lg p-2 flex items-center",
                  },
                  [
                    _vm.creditCardImages[_vm.cardSaveText]
                      ? _c("img", {
                          staticClass: "h-5 mr-2",
                          style: {
                            width: _vm.creditCardImages[_vm.cardSaveText].width,
                            height:
                              _vm.creditCardImages[_vm.cardSaveText].height,
                          },
                          attrs: {
                            src: _vm.creditCardImages[_vm.cardSaveText].src,
                            alt: "Credit Card Logo",
                          },
                        })
                      : _c(
                          "svg",
                          {
                            staticClass: "h-5 mr-2",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              width: "24px",
                              height: "24px",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z",
                              },
                            }),
                          ]
                        ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.cardSaveText) +
                          " ending in " +
                          _vm._s(_vm.Last4digittext)
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "w-full",
                attrs: {
                  disabled: _vm.isPayDisabled,
                  variant: "primary-outline",
                },
                on: { click: _vm.handleButtonClick },
              },
              [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.showModal
              ? _c("Modal", { on: { close: _vm.closeModal } }, [
                  _c("h1", [_vm._v("Hello World")]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }