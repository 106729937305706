var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "item-create" },
        [
          _c(
            "sw-page-header",
            { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/leads",
                      title: _vm.$tc("leads.title", 2),
                    },
                  }),
                  _vm._v(" "),
                  _vm.$route.name === "leads.edit"
                    ? _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("leads.title"),
                          active: "",
                        },
                      })
                    : _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("leads.title"),
                          active: "",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3 text-sm hidden sm:flex",
                      attrs: {
                        "tag-name": "router-link",
                        variant: "primary-outline",
                        type: "button",
                        size: "lg",
                        to: `/admin/leads`,
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("x-circle-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("contacts.clientgoback")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule2.update
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "hidden sm:flex",
                          attrs: {
                            "tag-name": "router-link",
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                            to: `/admin/leads/${_vm.$route.params.id}/edit`,
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "sw-card",
            { attrs: { variant: "customer-card" } },
            [
              _c("div", { staticClass: "pt-6 mt-5" }, [
                _c("div", { staticClass: "col-span-12" }, [
                  _c(
                    "p",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("providers.basic_info")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.customer_type")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.customer_type.text) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isCustomerBusiness
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("leads.company_name")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formData.company_name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCustomerBusiness
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("leads.primary_contact_name")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formData.primary_contact_name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isCustomerBusiness
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("leads.first_name")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formData.first_name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isCustomerBusiness
                        ? _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("leads.last_name")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.formData.last_name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.type")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.type.text) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.email")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.email) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.phone")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.phone) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.status")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.status.text) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.last_contacted_date")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.formattedLastDate) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("leads.followupdate")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 text-black non-italic",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formData.formattedFollowDate) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "sw-page-header",
                { staticClass: "mt-5", attrs: { title: " " } },
                [
                  _c("template", { slot: "actions" }, [
                    _c("div", { staticClass: "w-full" }, [
                      _c(
                        "div",
                        { staticClass: "col-span-12" },
                        [
                          _vm.permissionModule.create
                            ? _c(
                                "sw-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "tag-name": "router-link",
                                    to: `/admin/leadnotes/${this.$route.params.id}/create`,
                                    variant: "primary",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("customer_ticket.create_note")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "relative table-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                    },
                    [_c("p", { staticClass: "text-sm" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "notes_table",
                      attrs: {
                        "show-filter": false,
                        "table-class": "table",
                        data: _vm.fetchLeadNote,
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("customer_ticket.reference"),
                          show: "leadnote_number",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customer_ticket.reference")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500 cursor-pointer",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.leadnote_number) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          795924226
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("customer_ticket.subject"),
                          show: "subject",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("customer_ticket.subject"))
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.subject) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2492277195
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("customer_ticket.message"),
                          show: "body",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("customer_ticket.message"))
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.body) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          778773712
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("customer_ticket.user"),
                          show: "creator_id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("customer_ticket.user"))
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.formattedUserName) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          163026138
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: "Datetime",
                          show: "created_at",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("general.datetime")) +
                                        " "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.formattedAddeDate) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2624890277
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: { "cell-class": "action-dropdown no-click" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("general.actions"))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown",
                                    [
                                      _c("dot-icon", {
                                        attrs: { slot: "activator" },
                                        slot: "activator",
                                      }),
                                      _vm._v(" "),
                                      _vm.permissionModule.read
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                to: `/admin/leadnotes/${row.lead_id}/${row.id}/view`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("eye-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.view")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.permissionModule.update
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                to: `/admin/leadnotes/${row.lead_id}/${row.id}/edit`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.permissionModule.delete
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteNote(row.id)
                                                },
                                              },
                                            },
                                            [
                                              _c("trash-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3054159920
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }