var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateInvoiceSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.invoices.invoice_prefix"),
                error: _vm.invoicePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "input-expand",
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.invoices.invoice_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.invoices.invoice_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("INVOICES")
                  },
                },
                model: {
                  value: _vm.invoices.invoice_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_prefix", $$v)
                  },
                  expression: "invoices.invoice_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span- mt-2 col-12 col-md-6 col-lg-4 mb-3",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.invoice_bcc_email"
                ),
                error: _vm.emailError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "input-expand",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.email.$error,
                  type: "text",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2 col-12 col-md-6 col-lg-4 mb-3",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.default_invoice_email_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.invoices.invoice_mail_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_mail_subject", $$v)
                  },
                  expression: "invoices.invoice_mail_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4 col-12 col-md-6 col-lg-4 mb-3",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.default_invoice_email_body"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.invoices.invoice_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_mail_body", $$v)
                  },
                  expression: "invoices.invoice_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4 col-12 col-md-6 col-lg-4 mb-3",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.default_invoice_footer"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.invoices.invoice_footer,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_footer", $$v)
                  },
                  expression: "invoices.invoice_footer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4 col-12 col-md-6 col-lg-4 mb-3",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.company_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.invoices.company_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "company_address_format", $$v)
                  },
                  expression: "invoices.company_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 col-12 col-md-6 col-lg-4 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.shipping_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.shippingFields },
                model: {
                  value: _vm.invoices.shipping_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "shipping_address_format", $$v)
                  },
                  expression: "invoices.shipping_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 col-12 col-md-6 col-lg-4 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.billing_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.billingFields },
                model: {
                  value: _vm.invoices.billing_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "billing_address_format", $$v)
                  },
                  expression: "invoices.billing_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tabs mb-5 grid col-span-12 col-12 col-md-6 col-lg-4",
            },
            [
              _c("div", { staticClass: "border-b tab" }, [
                _c(
                  "div",
                  { staticClass: "border-l-2 border-transparent relative" },
                  [
                    _c("input", {
                      staticClass:
                        "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6",
                      attrs: { type: "checkbox", id: "chck1" },
                    }),
                    _vm._v(" "),
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center p-3 pl-0 pr-8 cursor-pointer select-none tab-label",
                        attrs: { for: "chck1" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-grey-darkest font-thin text-xl",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.email_template"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  "aria-hidden": "true",
                                  "data-reactid": "266",
                                  fill: "none",
                                  height: "24",
                                  stroke: "#606F7B",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  viewbox: "0 0 24 24",
                                  width: "24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("polyline", {
                                  attrs: { points: "6 9 12 15 18 9" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tab-content col-12 col-md-6 col-lg-4 mb-3",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pl-0 pr-8 pb-5 text-grey-darkest" },
                          [
                            _c("ul", { staticClass: "pl-0" }, [
                              _c(
                                "li",
                                { staticClass: "pb-2" },
                                [
                                  _c(
                                    "sw-tabs",
                                    { attrs: { "active-tab": _vm.activeTab } },
                                    [
                                      _c(
                                        "sw-tab-item",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "settings.customization.invoices.notice_one"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_subject"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_one_subject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_one_subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_one_subject",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_body"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_one,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_one",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_one",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-tab-item",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "settings.customization.invoices.notice_two"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_subject"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_two_subject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_two_subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_two_subject",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_body"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_two,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_two",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_two",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-tab-item",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "settings.customization.invoices.notice_three"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_subject"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_three_subject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_three_subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_three_subject",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.invoice_body"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_three,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_three",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_three",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-tab-item",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "settings.customization.invoices.notice_auto_debit"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.auto_debit_subject"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_unpaid_subject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_unpaid_subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_unpaid_subject",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-input-group",
                                            {
                                              staticClass:
                                                "mt-2 col-12 col-md-6 col-lg-4 mb-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "settings.customization.invoices.auto_debit_body"
                                                ),
                                              },
                                            },
                                            [
                                              _c("base-custom-input", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  fields: _vm.InvoiceMailFields,
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoices
                                                      .invoice_notice_unpaid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.invoices,
                                                      "invoice_notice_unpaid",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "invoices.invoice_notice_unpaid",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 col-12 col-md-6 col-lg-4 mb-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setInvoiceSetting },
              model: {
                value: _vm.invoiceAutogenerate,
                callback: function ($$v) {
                  _vm.invoiceAutogenerate = $$v
                },
                expression: "invoiceAutogenerate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4 col-12 col-md-6 col-lg-4 mb-3" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.autogenerate_invoice_number"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "p-0 m-0 text-xs leading-tight text-gray-500 col-12 col-md-6 col-lg-4 mb-3",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.invoice_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("sw-divider", {
        staticClass: "mt-6 mb-8 col-12 col-md-6 col-lg-4 mb-3",
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6 col-12 col-md-6 col-lg-4 mb-3",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateDueDate.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("corePbx.customization.invoice_due_date")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "margin-grid",
              attrs: {
                label: _vm.$t("corePbx.customization.invoice_due_date"),
                error: _vm.InvoiceDuedateError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.invoice_issuance_period.$error,
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.invoice_issuance_period.$touch()
                  },
                },
                model: {
                  value: _vm.invoice_issuance_period,
                  callback: function ($$v) {
                    _vm.invoice_issuance_period = $$v
                  },
                  expression: "invoice_issuance_period",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-8",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.customization.save_duedate")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6 col-12 col-md-6 col-lg-4 mb-3",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.invoices.task_scheduling")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-6" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: { change: (option) => _vm.slideSendInvoice(option) },
                  model: {
                    value: _vm.allow_send_invoice_job,
                    callback: function ($$v) {
                      _vm.allow_send_invoice_job = $$v
                    },
                    expression: "allow_send_invoice_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.invoices.allow_send_invoice_job"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.invoices.send_invoice_job_desc"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "margin-grid",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.period_time_run_job"
                ),
                error: _vm.timeJobError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.period_time_run_send_invoice_job.$error,
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.period_time_run_send_invoice_job.$touch()
                  },
                },
                model: {
                  value: _vm.period_time_run_send_invoice_job,
                  callback: function ($$v) {
                    _vm.period_time_run_send_invoice_job = $$v
                  },
                  expression: "period_time_run_send_invoice_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-8 mb-8" }),
          _vm._v(" "),
          _c("h3", { staticClass: "my-6 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("settings.customization.invoices.late_fees")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "margin-grid",
              attrs: {
                label: _vm.$t("settings.customization.invoices.late_fees"),
                error: _vm.lateFeesError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticClass: "margin-calendar",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.late_fees.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.late_fees.$touch()
                  },
                },
                model: {
                  value: _vm.late_fees,
                  callback: function ($$v) {
                    _vm.late_fees = $$v
                  },
                  expression: "late_fees",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("settings.customization.invoices.late_fee_one")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-3 display-grid" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t("settings.customization.invoices.activate"),
                    required: "",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "absolute margin-tl",
                    staticStyle: { top: "-16px", left: "28%" },
                    on: { change: function ($event) {} },
                    model: {
                      value: _vm.late_fee_one,
                      callback: function ($$v) {
                        _vm.late_fee_one = $$v
                      },
                      expression: "late_fee_one",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_days"
                    ),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "margin-tl-wh",
                    staticStyle: {
                      top: "5%",
                      left: "14%",
                      "max-width": "70%",
                      position: "absolute",
                    },
                    attrs: { type: "number", max: "90" },
                    model: {
                      value: _vm.late_fee_one_days,
                      callback: function ($$v) {
                        _vm.late_fee_one_days = $$v
                      },
                      expression: "late_fee_one_days",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mr-6 my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_value"
                    ),
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ml-12", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        staticClass:
                          "ml-3 border-r-0 rounded-tr-sm rounded-br-sm margin-tl-wh",
                        staticStyle: {
                          top: "5%",
                          position: "absolute",
                          "max-width": "70%",
                        },
                        model: {
                          value: _vm.late_fee_one_value,
                          callback: function ($$v) {
                            _vm.late_fee_one_value = $$v
                          },
                          expression: "late_fee_one_value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown",
                        { attrs: { position: "bottom-end" } },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass: "margin-tl2",
                              staticStyle: {
                                height: "41px",
                                position: "absolute",
                                left: "74%",
                                top: "-26px",
                              },
                              attrs: {
                                slot: "activator",
                                type: "button",
                                "data-toggle": "dropdown",
                                size: "discount",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                                variant: "white",
                              },
                              slot: "activator",
                              model: {
                                value: _vm.late_fee_one_type,
                                callback: function ($$v) {
                                  _vm.late_fee_one_type = $$v
                                },
                                expression: "late_fee_one_type",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.late_fee_one_type == "fixed"
                                          ? "$"
                                          : "%"
                                      ) +
                                      "\n                "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectFixed_1 } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.fixed")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectPercentage_1 } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.percentage")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "mt-6 mb-3 text-lg font-medium text-black" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("settings.customization.invoices.late_fee_two")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-3 display-grid" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t("settings.customization.invoices.activate"),
                    required: "",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "absolute margin-tl",
                    staticStyle: { top: "-16px", left: "28%" },
                    on: { change: function ($event) {} },
                    model: {
                      value: _vm.late_fee_two,
                      callback: function ($$v) {
                        _vm.late_fee_two = $$v
                      },
                      expression: "late_fee_two",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_days"
                    ),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "margin-tl-wh",
                    staticStyle: {
                      top: "5%",
                      left: "14%",
                      "max-width": "70%",
                      position: "absolute",
                    },
                    attrs: { type: "number", max: "90" },
                    model: {
                      value: _vm.late_fee_two_days,
                      callback: function ($$v) {
                        _vm.late_fee_two_days = $$v
                      },
                      expression: "late_fee_two_days",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mr-6 my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_value"
                    ),
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ml-12", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        staticClass:
                          "ml-3 border-r-0 rounded-tr-sm rounded-br-sm margin-tl-wh",
                        staticStyle: {
                          top: "5%",
                          position: "absolute",
                          "max-width": "70%",
                        },
                        model: {
                          value: _vm.late_fee_two_value,
                          callback: function ($$v) {
                            _vm.late_fee_two_value = $$v
                          },
                          expression: "late_fee_two_value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown",
                        { attrs: { position: "bottom-end" } },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass: "margin-tl2",
                              staticStyle: {
                                height: "41px",
                                position: "absolute",
                                left: "74%",
                                top: "-26px",
                              },
                              attrs: {
                                slot: "activator",
                                type: "button",
                                "data-toggle": "dropdown",
                                size: "discount",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                                variant: "white",
                              },
                              slot: "activator",
                              model: {
                                value: _vm.late_fee_two_type,
                                callback: function ($$v) {
                                  _vm.late_fee_two_type = $$v
                                },
                                expression: "late_fee_two_type",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.late_fee_two_type == "fixed"
                                          ? "$"
                                          : "%"
                                      ) +
                                      "\n                "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectFixed_2 } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.fixed")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectPercentage_2 } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.percentage")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "mt-6 mb-3 text-lg font-medium text-black" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("settings.customization.invoices.late_fee_three")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-3 display-grid" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t("settings.customization.invoices.activate"),
                    required: "",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "absolute margin-tl",
                    staticStyle: { top: "-16px", left: "28%" },
                    on: { change: function ($event) {} },
                    model: {
                      value: _vm.late_fee_three,
                      callback: function ($$v) {
                        _vm.late_fee_three = $$v
                      },
                      expression: "late_fee_three",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_days"
                    ),
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "margin-tl-wh",
                    staticStyle: {
                      top: "5%",
                      left: "14%",
                      "max-width": "70%",
                      position: "absolute",
                    },
                    attrs: { type: "number", max: "90" },
                    model: {
                      value: _vm.late_fee_three_days,
                      callback: function ($$v) {
                        _vm.late_fee_three_days = $$v
                      },
                      expression: "late_fee_three_days",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mr-6 my-6",
                  attrs: {
                    label: _vm.$t(
                      "settings.customization.invoices.late_fee_value"
                    ),
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ml-12", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        staticClass:
                          "ml-3 border-r-0 rounded-tr-sm rounded-br-sm margin-tl-wh",
                        staticStyle: {
                          top: "5%",
                          position: "absolute",
                          "max-width": "70%",
                        },
                        model: {
                          value: _vm.late_fee_three_value,
                          callback: function ($$v) {
                            _vm.late_fee_three_value = $$v
                          },
                          expression: "late_fee_three_value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "sw-dropdown",
                        { attrs: { position: "bottom-end" } },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass: "margin-tl2",
                              staticStyle: {
                                height: "41px",
                                position: "absolute",
                                left: "74%",
                                top: "-26px",
                              },
                              attrs: {
                                slot: "activator",
                                type: "button",
                                "data-toggle": "dropdown",
                                size: "discount",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                                variant: "white",
                              },
                              slot: "activator",
                              model: {
                                value: _vm.late_fee_three_type,
                                callback: function ($$v) {
                                  _vm.late_fee_three_type = $$v
                                },
                                expression: "late_fee_three_type",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.late_fee_three_type == "fixed"
                                          ? "$"
                                          : "%"
                                      ) +
                                      "\n                "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectFixed_3 } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.fixed")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.selectPercentage_3 } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.percentage")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h3",
            { staticClass: "mt-6 mb-3 text-lg font-medium text-black" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.invoice_late_fee_retroactive"
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-3 display-grid" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("settings.customization.invoices.activate"),
                    required: "",
                  },
                },
                [
                  _c("sw-switch", {
                    staticClass: "absolute margin-tl",
                    staticStyle: { top: "-16px", left: "28%" },
                    on: { change: function ($event) {} },
                    model: {
                      value: _vm.invoice_late_fee_retroactive,
                      callback: function ($$v) {
                        _vm.invoice_late_fee_retroactive = $$v
                      },
                      expression: "invoice_late_fee_retroactive",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-8 margin-buttonl",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.invoices.save_scheduling"
                        )
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }