var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updatePaymentEmailSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span- mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_bbc_email"
                ),
                error: _vm.emailError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "input-expand",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.email.$error,
                  type: "text",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "settings.customization.payments.payment_with_approved_ach"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_approved_ach_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_approved_ach_subject",
                      $$v
                    )
                  },
                  expression: "emailPayment.payment_approved_ach_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.mailFieldsACH },
                model: {
                  value: _vm.emailPayment.payment_approved_ach,
                  callback: function ($$v) {
                    _vm.$set(_vm.emailPayment, "payment_approved_ach", $$v)
                  },
                  expression: "emailPayment.payment_approved_ach",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.$t("settings.customization.payments.ach_payment_declined")
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_ach_declined_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_ach_declined_subject",
                      $$v
                    )
                  },
                  expression: "emailPayment.payment_ach_declined_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.mailFieldsACH },
                model: {
                  value: _vm.emailPayment.payment_ach_declined,
                  callback: function ($$v) {
                    _vm.$set(_vm.emailPayment, "payment_ach_declined", $$v)
                  },
                  expression: "emailPayment.payment_ach_declined",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "settings.customization.payments.payment_approved_credit_card"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_approved_credit_card_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_approved_credit_card_subject",
                      $$v
                    )
                  },
                  expression:
                    "emailPayment.payment_approved_credit_card_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_approved_credit_card,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_approved_credit_card",
                      $$v
                    )
                  },
                  expression: "emailPayment.payment_approved_credit_card",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "settings.customization.payments.payment_rejected_credit_card"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_credit_card_rejected_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_credit_card_rejected_subject",
                      $$v
                    )
                  },
                  expression:
                    "emailPayment.payment_credit_card_rejected_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.emailPayment.payment_credit_card_rejected,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_credit_card_rejected",
                      $$v
                    )
                  },
                  expression: "emailPayment.payment_credit_card_rejected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "settings.customization.payments.payment_card_expiration"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value:
                    _vm.emailPayment.payment_card_expiration_reminders_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_card_expiration_reminders_subject",
                      $$v
                    )
                  },
                  expression:
                    "emailPayment.payment_card_expiration_reminders_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.mailRemindersFields },
                model: {
                  value: _vm.emailPayment.payment_card_expiration_reminders,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailPayment,
                      "payment_card_expiration_reminders",
                      $$v
                    )
                  },
                  expression: "emailPayment.payment_card_expiration_reminders",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "my-4",
                  attrs: {
                    loading: _vm.isLoadingPayment,
                    disabled: _vm.isLoadingPayment,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingPayment
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-4 mb-6" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-4",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updatePaymentSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_prefix"),
                error: _vm.paymentPrefixError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.payments.payment_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.payments.payment_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("PAYMENTS")
                  },
                },
                model: {
                  value: _vm.payments.payment_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.payments, "payment_prefix", $$v)
                  },
                  expression: "payments.payment_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 mb-4" }, [
            _c("h1", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.default_payment_email_subject"
                    )
                  ) +
                  "Main Payment Email"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.default_payment_email_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.payments.payment_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.payments, "payment_subject", $$v)
                  },
                  expression: "payments.payment_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.default_payment_email_body"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.payments.payment_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.payments, "payment_mail_body", $$v)
                  },
                  expression: "payments.payment_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.company_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.payments.company_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.payments, "company_address_format", $$v)
                  },
                  expression: "payments.company_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.from_customer_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.customerAddressFields },
                model: {
                  value: _vm.payments.from_customer_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.payments, "from_customer_address_format", $$v)
                  },
                  expression: "payments.from_customer_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "my-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setPaymentSetting },
              model: {
                value: _vm.paymentAutogenerate,
                callback: function ($$v) {
                  _vm.paymentAutogenerate = $$v
                },
                expression: "paymentAutogenerate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.autogenerate_payment_number"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.payment_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateReminderScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.payments.payment_reminder")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slidePaymentReminderDate(option),
                  },
                  model: {
                    value: _vm.allow_reminder_payment_job,
                    callback: function ($$v) {
                      _vm.allow_reminder_payment_job = $$v
                    },
                    expression: "allow_reminder_payment_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.allow_paymentreminder_date_job"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.paymentreminder_date_job_desc"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.time_run_paymentreminder_date_job"
                ),
                error: _vm.timeReminderError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.time_run_reminder_payment_job.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.time_run_reminder_payment_job.$touch()
                  },
                },
                model: {
                  value: _vm.time_run_reminder_payment_job,
                  callback: function ($$v) {
                    _vm.time_run_reminder_payment_job = $$v
                  },
                  expression: "time_run_reminder_payment_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingReminder,
                    disabled: _vm.isLoadingReminder,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingReminder
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("settings.customization.payments.save_renewal")
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateCardExpirationReminderScheduling.apply(
                null,
                arguments
              )
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "settings.customization.payments.payment_card_expiration"
                  )
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slideCardExpirationDate(option),
                  },
                  model: {
                    value: _vm.allow_cardexpiration_payment_job,
                    callback: function ($$v) {
                      _vm.allow_cardexpiration_payment_job = $$v
                    },
                    expression: "allow_cardexpiration_payment_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.allow_cardexpiration_job"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.cardexpiration_date_job_desc"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.time_run_cardexpiration_date_job"
                ),
                error: _vm.timeCardExpirationError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.time_run_cardexpiration_payment_job.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.time_run_cardexpiration_payment_job.$touch()
                  },
                },
                model: {
                  value: _vm.time_run_cardexpiration_payment_job,
                  callback: function ($$v) {
                    _vm.time_run_cardexpiration_payment_job = $$v
                  },
                  expression: "time_run_cardexpiration_payment_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4 margin-buttonl",
                  attrs: {
                    loading: _vm.isLoadingCardExpiration,
                    disabled: _vm.isLoadingCardExpiration,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingCardExpiration
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.payments.save_cardexpiration"
                        )
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }