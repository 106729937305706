var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateExpensesSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "settings.customization.expenses.expense_template_prefix"
                ),
                error: _vm.ExpenseTemplatePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "input-expand",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.expenses.expense_template_prefix.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.expense_template_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE_TEMPLATE")
                  },
                },
                model: {
                  value: _vm.expenses.expense_template_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "expense_template_prefix", $$v)
                  },
                  expression: "expenses.expense_template_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.expenses_template_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.expenses,
                        "expenses_template_prefix_general",
                        $$v
                      )
                    },
                    expression: "expenses.expenses_template_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.expenses.expense_prefix"),
                error: _vm.ExpensePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.expense_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.expense_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE")
                  },
                },
                model: {
                  value: _vm.expenses.expense_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "expense_prefix", $$v)
                  },
                  expression: "expenses.expense_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.expenses_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "expenses_prefix_general", $$v)
                    },
                    expression: "expenses.expenses_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "settings.customization.expenses.provider_prefix"
                ),
                error: _vm.ProviderPrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.prov_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.prov_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("PROVIDER")
                  },
                },
                model: {
                  value: _vm.expenses.prov_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "prov_prefix", $$v)
                  },
                  expression: "expenses.prov_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.provider_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "provider_prefix_general", $$v)
                    },
                    expression: "expenses.provider_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-4" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid gap-6 mt-5 mb-4 sm:grid-col-1 md:grid-cols-2",
            },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc(
                      "settings.customization.expenses.warning_days_before_due_date"
                    ),
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.days_before_due_date,
                      label: "name",
                      "track-by": "id",
                      searchable: true,
                    },
                    model: {
                      value: _vm.expenses.select_days_before_due_date,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.expenses,
                          "select_days_before_due_date",
                          $$v
                        )
                      },
                      expression: "expenses.select_days_before_due_date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-4",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateExpenseNotifications.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span- mt-2",
              attrs: {
                label: _vm.$tc(
                  "settings.customization.expenses.expense_bcc_email"
                ),
                error: _vm.emailError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.email.$error,
                  type: "text",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-4",
              attrs: {
                label: _vm.$tc(
                  "settings.customization.expenses.expense_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.expense.expense_mail_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.expense, "expense_mail_subject", $$v)
                  },
                  expression: "expense.expense_mail_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$tc("settings.customization.expenses.expense_body"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.expense.expense_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.expense, "expense_mail_body", $$v)
                  },
                  expression: "expense.expense_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              _vm._s(
                _vm.$t("settings.customization.expenses.expense_notification")
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slideCardExpirationDate(option),
                  },
                  model: {
                    value: _vm.allow_expense_notifications,
                    callback: function ($$v) {
                      _vm.allow_expense_notifications = $$v
                    },
                    expression: "allow_expense_notifications",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.expenses.allow_expense_notification"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.expenses.enable_expense_notification"
                        )
                      ) +
                      "\n          \n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$tc(
                  "settings.customization.expenses.time_expense_job"
                ),
                error: _vm.expenseNotificationsRun,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.expense_notifications_run.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.expense_notifications_run.$touch()
                  },
                },
                model: {
                  value: _vm.expense_notifications_run,
                  callback: function ($$v) {
                    _vm.expense_notifications_run = $$v
                  },
                  expression: "expense_notifications_run",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setExpenseSetting },
              model: {
                value: _vm.expenseAutogenerate,
                callback: function ($$v) {
                  _vm.expenseAutogenerate = $$v
                },
                expression: "expenseAutogenerate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.expenses.autogenerate_expense_number"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.expenses.expense_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setExpenseTemplateSetting },
              model: {
                value: _vm.expenseTemplateAutogenerate,
                callback: function ($$v) {
                  _vm.expenseTemplateAutogenerate = $$v
                },
                expression: "expenseTemplateAutogenerate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.expenses.autogenerate_expense_template_number"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.expenses.expense_template_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("settings.customization.expenses.recurring_expenses")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slideRecurringExpenseDate(option),
                  },
                  model: {
                    value: _vm.job_expense_template_enable,
                    callback: function ($$v) {
                      _vm.job_expense_template_enable = $$v
                    },
                    expression: "job_expense_template_enable",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.expenses.allow_recurring_date_job"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.expenses.recurring_date_job_desc"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.expenses.time_run_recurring_expenses"
                ),
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                model: {
                  value: _vm.job_expense_template_time_run,
                  callback: function ($$v) {
                    _vm.job_expense_template_time_run = $$v
                  },
                  expression: "job_expense_template_time_run",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4 margin-buttonl",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("settings.customization.expenses.save_recurring")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-4" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }