<template>
  <form @submit.prevent="submitForm">
    <!-- <sw-input-group
      v-if="paymentType === 'saved'"
      class="col-span-1 mb-4"
      :label="$t('settings.payment_gateways.saved_credit_cards')"
    >
      <sw-input-group class="mb-2 mt-2">
        <sw-select
          v-model="selectedAccount"
          :options="formatted_payment_accounts"
          :searchable="true"
          :show-labels="false"
          :allow-empty="false"
          :placeholder="$t('payment_accounts.select_a_payment_account')"
          @input="fillFormData"
          track-by="id"
          label="name_account_number"
          :tabindex="1"
        />
      </sw-input-group>
    </sw-input-group> -->

    <sw-input-group
      class="col-span-1 mb-4"
      :label="$t('payments.account_holder_name')"
      required
    >
      <sw-input
        v-model="localFormData.account_holder"
        focus
        type="text"
        name="name"
        tabindex="1"
        @input="
          validateAccountHolderName
          updateLocalFormData()
        "
        autoComplete="off"
      />
      <p v-if="accountHolderError" class="error-text">
        {{ accountHolderError }}
      </p>
    </sw-input-group>

    <sw-input-group
      :label="$t('payment_accounts.routing_number')"
      class="mb-4"
      required
    >
      <sw-input
        v-model="localFormData.routing_number"
        focus
        :type="getInputType2"
        name="routing_number"
        tabindex="1"
        @input="
          validateRoutingNumber
          updateLocalFormData()
        "
        autoComplete="off"
      >
        <template v-slot:rightIcon>
          <eye-off-icon
            v-if="isShowRouting"
            class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
            @click="isShowRouting = !isShowRouting"
          />
          <eye-icon
            v-else
            class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
            @click="isShowRouting = !isShowRouting"
          />
        </template>
      </sw-input>
      <p v-if="routingNumberError" class="error-text">
        {{ routingNumberError }}
      </p>
    </sw-input-group>

    <sw-input-group
      :label="$t('payment_accounts.account_number')"
      class="mb-4"
      required
    >
      <sw-input
        v-model="localFormData.account_number"
        focus
        :type="getInputType"
        name="account_number"
        tabindex="1"
        @input="
          validateAccountNumber
          updateLocalFormData()
        "
        autoComplete="off"
      >
        <template v-slot:rightIcon>
          <eye-off-icon
            v-if="isShowNumber"
            class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
            @click="isShowNumber = !isShowNumber"
          />
          <eye-icon
            v-else
            class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
            @click="isShowNumber = !isShowNumber"
          />
        </template>
      </sw-input>
      <p v-if="accountNumberError" class="error-text">
        {{ accountNumberError }}
      </p>
    </sw-input-group>

    <div class="grid grid-cols-12 gap-8 mt-1 mb-4">
      <sw-input-group
        class="col-span-12"
        :label="$t('payments.account_type')"
        required
      >
        <sw-select
          v-model="localFormData.ACH_type"
          :options="account_type"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('payments.account_type')"
          :max-height="150"
          label="name"
          class="mt-1"
          @input="updateLocalFormData()"
        />
      </sw-input-group>
    </div>

    <sw-input-group
      class="col-span-1 mb-4"
      :label="$t('payments.account_bank_name')"
    >
      <sw-input
        v-model="localFormData.bank_name"
        focus
        type="text"
        name="name"
        tabindex="1"
        @input="updateLocalFormData()"
        autoComplete="off"
      />
      <p v-if="accountHolderError" class="error-text">
        {{ accountHolderError }}
      </p>
    </sw-input-group>

    <h3>{{ $t('payments.billing_address') }}</h3>
    <sw-input-group
      class="col-span-1 mb-4"
      :label="$t('payments.addres_line_one')"
      required
    >
      <sw-input
        v-model="localFormData.address_1"
        focus
        type="text"
        name="address_1"
        tabindex="1"
        @input="
          validateAddress1
          updateLocalFormData()
        "
        autoComplete="off"
      />
      <p v-if="address1Error" class="error-text">{{ address1Error }}</p>
    </sw-input-group>

    <sw-input-group
      class="col-span-1 mb-4"
      :label="$t('payments.addres_line_two')"
    >
      <sw-input
        v-model="localFormData.address_2"
        focus
        type="text"
        name="address_2"
        tabindex="1"
        autoComplete="off"
        @input="updateLocalFormData()"
      />
    </sw-input-group>

    <div class="grid lg:grid-cols-12 sm:grid-cols-1 gap-8 mt-1 mb-2">
      <sw-input-group class="col-span-6" :label="$t('customers.city')" required>
        <sw-input
          v-model="localFormData.city"
          name="city"
          type="text"
          tabindex="10"
          autocomplete="off"
          @input="
            validateCity
            updateLocalFormData()
          "
        />
        <p v-if="cityError" class="error-text">{{ cityError }}</p>
      </sw-input-group>

      <sw-input-group
        class="col-span-6"
        :label="$t('customers.zip_code')"
        required
      >
        <sw-input
          tabindex="14"
          v-model.trim="localFormData.zip"
          type="text"
          name="zip"
          autocomplete="off"
          @input="
            validateZip
            updateLocalFormData()
          "
        />
        <p v-if="zipError" class="error-text">{{ zipError }}</p>
      </sw-input-group>

      <sw-input-group
        class="col-span-6"
        :label="$t('customers.country')"
        required
      >
        <sw-select
          v-model="localFormData.country"
          :options="countries"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('general.select_country')"
          label="name"
          track-by="id"
          @select="countrySelected($event)"
          @input="updateLocalFormData()"
        />
      </sw-input-group>

      <sw-input-group
        class="col-span-6"
        :label="$t('customers.state')"
        required
      >
        <sw-select
          v-model="localFormData.state"
          :options="states"
          :searchable="true"
          :show-labels="false"
          :allow-empty="true"
          :tabindex="8"
          :placeholder="$t('general.select_state')"
          label="name"
          track-by="id"
          @input="updateLocalFormData()"
        />
      </sw-input-group>
    </div>

    <!-- Checkboxes y botones -->
    <div
      v-if="!showPaymentForm"
      class="mt-4 p-4 border-gray-300 rounded-lg space-y-4"
    >
      <!-- <label v-if="paymentType === 'oneTime'" class="flex items-center">
        <input
          type="checkbox"
          @change="updateLocalFormData"
          v-model="localFormData.saveForFutureUse"
          class="form-checkbox h-4 w-4 rounded-full border-gray-300 focus:ring-0 text-blue-600"
          style="border-radius: 50%; border-width: 1px; border-color: #d1d5db"
        />
        <span class="ml-2 text-gray-700 text-sm"
          >Save this payment method for future use</span
        >
      </label> -->

      <!-- SWITCH -->
      <div
        class="flex md:col-span-3"
        v-if="
          (paymentType === 'oneTime' && localFormData.saveForFutureUse) ||
          paymentType === 'saved'
        "
      >
        <div class="relative w-12">
          <sw-switch
            v-model="localFormData.saved_pay"
            class="absolute"
            style="top: -20px"
            @change="updateLocalFormData"
          />
        </div>

        <div class="ml-4">
          <p class="p-0 mb-1 text-base leading-snug text-black box-title">
            Make Payment Account as Default
          </p>
        </div>
      </div>

      <div
        v-if="paymentType === 'saved'"
        class="mt-4 flex justify-end space-x-2"
      >
        <sw-button :variant="'primary'" @click.prevent="submitForm">
          <template v-if="isLoading">
            <div class="loader"></div>
          </template>
          <template v-else> Save Account </template>
        </sw-button>
      </div>
    </div>

    <!-- Mostrar la dirección guardada -->
    <div v-if="isSaved && !showPaymentForm" class="mt-4 saved-address">
      <h3>Billing Address</h3>
      <p><strong>Default Billing Address:</strong></p>
      <p>{{ savedAddress }}</p>
      <a href="#" @click.prevent="toggleEdit" class="edit-link">Edit</a>
      <a href="#" @click.prevent="removeAddress" class="remove-link">Remove</a>

      <!-- Mostrar formulario de edición de dirección -->
      <div v-if="isEditing" class="mt-4">
        <sw-input-group label="Address Line 1" class="mb-4">
          <sw-input
            v-model="editFormData.address_1"
            required
            class="input-field"
            placeholder="Enter address"
            autoComplete="off"
          />
        </sw-input-group>
        <sw-input-group label="Address Line 2 (Optional)" class="mb-4">
          <sw-input
            v-model="editFormData.address_2"
            class="input-field"
            placeholder="Enter address line 2"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="City" class="mb-4">
          <sw-input
            v-model="editFormData.city"
            required
            class="input-field"
            placeholder="Enter city"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="State" class="mb-4">
          <sw-select
            v-model="editFormData.state"
            :options="states"
            :searchable="true"
            :show-labels="false"
            :placeholder="'Select a state'"
            label="name"
            track-by="id"
            required
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="ZIP Code" class="mb-4">
          <sw-input
            v-model="editFormData.zip"
            required
            class="input-field"
            placeholder="Enter ZIP code"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="Country" class="mb-4">
          <sw-select
            v-model="editFormData.country_id"
            :options="countries"
            :searchable="true"
            :show-labels="false"
            :placeholder="'Select a country'"
            label="name"
            track-by="id"
            @select="countrySelectedEdit"
            required
            autoComplete="off"
          />
        </sw-input-group>

        <div class="mt-4 flex justify-end space-x-2">
          <sw-button :variant="'secondary'" @click.prevent="cancelEdit"
            >Cancel</sw-button
          >
          <sw-button :variant="'primary'" @click.prevent="saveAddress"
            >Save Address</sw-button
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  props: {
    formData: Object,
    updateFormData: Function,
    paymentType: String,
    isNewMethod: Boolean,
    disablePay: Boolean,
    paymentSucess: Function,
  },

  data() {
    return {
      customer: {},
      account_type: [
        { name: 'Checking', value: 'checking' },
        { name: 'Savings', value: 'savings' },
      ],
      formatted_payment_accounts: [],
      savePaymentMethod: false,
      isStorageDataAvailable: false,
      showPaymentForm: false,
      localFormData: { ...this.formData },
      countries: [],
      states: [],
      selectedAccount: null,
      isShowNumber: false,
      isShowRouting: false,
      accountHolderError: '',
      routingNumberError: '',
      accountNumberError: '',
      address1Error: '',
      cityError: '',
      zipError: '',
      isStorageDataAvailable: false,
      isLoading: false,
      isSaved: false,
      isEditing: false,
      savedAddress: '',
      editFormData: {
        address_1: '',
        address_2: '',
        city: '',
        state: null,
        zip: '',
        country_id: null,
      },
    }
  },
  computed: {
    ...mapGetters('company', ['defaultCurrencyForInput']),
    ...mapGetters('customer', ['customers']),
    ...mapGetters('payment', ['paymentModes', 'selectedNote']),

    getInputType() {
      return this.isShowNumber ? 'text' : 'password'
    },
    getInputType2() {
      return this.isShowRouting ? 'text' : 'password'
    },
    buttonLabel() {
      if (this.showPaymentForm) {
        return 'Save and Pay'
      } else if (this.saveForFutureUse) {
        return 'Save and Pay'
      } else if (this.isNewMethod) {
        return 'Save New Method'
      } else {
        return 'Use This Method'
      }
    },
  },
  async created() {
    await this.setInvoicePaymentData(this.$route.params.unique_hash);

    let res = await window.axios.get('/api/v1/countries')
    if (res) {
      this.countries = res.data.countries
    }
    if (this.paymentType === 'saved') {
      this.setPaymentAccounts()
    }
    this.loadStoredACH()
    if (this.currentUser) {
      this.customer = this.currentUser
    }
    let params = {
      id: this.customer.id
    }
    await this.loadCustomerData(params);
  },
  mounted(){
    this.setData();
  },
  methods: {
    ...mapActions('paymentAccounts', ['addPaymentAccount']),
    ...mapActions('invoice', ['fetchInvoice', 'fetchInvoices', 'fetchInvoiceForUniqueHash']),
    ...mapActions('customer', ['fetchCustomers', 'fetchCustomer', 'fetchCustomerWithoutLogin']),

    async loadCustomerData(params) {
     
     let response = await this.fetchCustomerWithoutLogin(params)
     console.log('data del customer en load customer data: ', response.data.customer);
     const customerData = response.data.customer;

     if(customerData){
        const selectedCountry = this.countries.find(country => country.id === customerData.billing_address.country.id);

        if(selectedCountry){
          await this.countrySelectedEdit(selectedCountry);
        }

        const selectedState = this.states.find(state => state.id === customerData.billing_address.state.id);

        this.localFormData = {
          state: selectedState || null ,
          country: selectedCountry || null,
          card_holder: customerData.name
        }
     }
     console.log('formdata in set data: ', this.localFormData)
     this.isLoading = false;
   },
   async setInvoicePaymentData(unique_hash) {
      
      let data = await this.fetchInvoiceForUniqueHash(unique_hash)
      
      if(data.data.invoice == null)
      {
        this.invoiceIsCanceled = true
        return
      }     

      this.invoiceNumber = data.data.invoice.invoice_number

      if(data.data.invoice.status === "COMPLETED" && data.data.invoice.paid_status === "PAID")
      {    
        this.invoiceIsCompleted = true
      }    

      this.customer = data.data.invoice.user
      this.invoice = data.data.invoice
      this.formData.amount = data.data.invoice.due_amount
      this.maxPayableAmount = data.data.invoice.due_amount
      this.company_id = data.data.invoice.company_id
    },

    async setPaymentAccounts() {
      this.formatted_payment_accounts = this.filteredAccounts
        .filter((el) => el.payment_account_type === 'ACH')
        .map((el) => {
          const auxAccountNumber = el.account_number.toString().split('')
          let showAccountNumber = ''
          const limit = auxAccountNumber.length - 4
          auxAccountNumber.forEach((el, i) => {
            if (i < limit) showAccountNumber += '*'
            else showAccountNumber += el
          })
          return {
            ...el,
            name_account_number: el.first_name + ' ' + showAccountNumber,
          }
        })
    },
    async loadCountries() {
      let res = await window.axios.get('/api/v1/countries')
      if (res && res.data && res.data.countries) {
        this.countries = res.data.countries
      }
    },
    async loadStates(country) {
      if (!country || !country.code) return
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res && res.data && res.data.states) {
        this.states = res.data.states
      }
    },
    async countrySelectedEdit(country) {
      this.editFormData.state = ''
      await this.loadStates(country)
    },

    async setData(){
      if(this.countries.length === 0){
        await this.loadCountries();
      }

      if (this.currentUser && this.currentUser.addresses) {
        const billingAddress = this.currentUser.addresses.find(address => address.type === 'billing');

        if (billingAddress) {
          const selectedCountry = this.countries.find(country => country.id === billingAddress.country_id);
          
          if(selectedCountry){
            await this.countrySelectedEdit(selectedCountry);
          }

          const selectedState = this.states.find(state => state.id === billingAddress.state_id);

          this.localFormData = {
            state: selectedState || null,
            country: selectedCountry || null,
            account_holder: this.customer.name
          };
        }
      }

      this.isLoading = false;
    },
    async fillFormData() {
      for (let key in this.localFormData) {
        if (this.selectedAccount.hasOwnProperty(key)) {
          this.localFormData[key] = this.selectedAccount[key]
        }
        this.localFormData.name =
          this.selectedAccount.first_name + ' ' + this.selectedAccount.last_name
        if (this.selectedAccount.ACH_type === 'Checking') {
          this.localFormData.ACH_type = { name: 'Checking', id: 1 }
        } else {
          this.localFormData.ACH_type = { name: 'Savings', id: 2 }
        }
      }
      for (let country of this.countries) {
        if (country.id === this.selectedAccount.country_id) {
          this.localFormData.country_id = country
          this.countrySelected(country)
        }
      }
      for (let state of this.states) {
        if (state.id === this.localFormData.state_id) {
          this.localFormData.state_id = state
          this.localFormData.state = state
        }
      }
    },
    async countrySelected(country) {
      let res = await window.axios.get('/api/v1/states/' + country.code)
      if (res) {
        this.states = res.data.states
        if (this.paymentType === 'saved') {
          for (let state of this.states) {
            if (state.id === this.selectedAccount.state_id) {
              this.localFormData.state_id = state
              this.localFormData.state = state
            }
          }
        }
      }
    },
    validateAccountHolderName() {
      const maxLength = 21
      if (this.localFormData.account_holder.length > maxLength) {
        this.accountHolderError = `Accountholder name cannot exceed ${maxLength} characters`
      } else {
        this.accountHolderError = ''
      }
    },
    validateRoutingNumber() {
      const regex = /^[0-9]{9}$/
      this.routingNumberError = regex.test(this.localFormData.routing_number)
        ? ''
        : 'Invalid routing number'
    },
    validateAccountNumber() {
      const regex = /^[0-9]{8,12}$/
      this.accountNumberError = regex.test(this.localFormData.account_number)
        ? ''
        : 'Invalid account number'
    },
    validateAddress1() {
      this.address1Error = this.localFormData.address_1
        ? ''
        : 'Address Line 1 is required'
    },
    validateCity() {
      this.cityError = this.localFormData.city ? '' : 'City is required'
    },
    validateZip() {
      const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      this.zipError = regex.test(this.localFormData.zip)
        ? ''
        : 'Invalid ZIP code'
    },
    async submitForm() {
      this.validateAccountHolderName()
      this.validateRoutingNumber()
      this.validateAccountNumber()
      this.validateAddress1()
      this.validateCity()
      this.validateZip()

      if (
        this.accountHolderError ||
        this.routingNumberError ||
        this.accountNumberError ||
        this.address1Error ||
        this.cityError ||
        this.zipError
      ) {
        return
      }

      this.isLoading = true

      const dataPaymentAccount = {
        first_name: this.localFormData.account_holder?.substring(0, 21),
        country_id: this.localFormData.country.id,
        state_id: this.localFormData.state.id,
        city: this.localFormData.city,
        address_1: this.localFormData.address_1,
        address_2: this.localFormData.address_2,
        zip: this.localFormData.zip,
        payment_account_type: this.localFormData.payment_account_type,
        client_id: this.customer.id,
        status: 'A',
        main_account: 0,
        nameOnAccount: this.localFormData.account_holder?.substring(0, 21),
        ACH_type: this.localFormData.ACH_type.name,
        account_number: this.localFormData.account_number,
        routing_number: this.localFormData.routing_number,
        bank_name: this.localFormData.bank_name,
      }
      try {
        const responsePaymentaccount = await this.addPaymentAccount(
          dataPaymentAccount
        )
        if (responsePaymentaccount.status === 200) {
          window.toastr['success']('Payment Account Added')
          this.$emit('paymentSucess')
        } else {
          window.toastr['error']('Failed to add payment account')
        }
      } catch (error) {
        window.toastr['error']('Failed to add payment account')
      }
      this.isLoading = false
    },
    updateLocalFormData() {
      if (this.updateFormData) {
        this.updateFormData({ ...this.localFormData })
        this.localFormData.payment_account_type = 'ACH'
      }
    },
    loadStoredACH() {
      const savedACH = localStorage.getItem('ACHDetails')
      if (savedACH) {
        const parsedData = JSON.parse(savedACH)
        this.localFormData = { ...this.localFormData, ...parsedData }
        this.isStorageDataAvailable = true
        this.savedAddress = `${parsedData.address_1}, ${parsedData.address_2}, ${parsedData.city}, ${parsedData.state}, ${parsedData.zip}, ${parsedData.country}`
        this.isSaved = true
      }
    },
    removeAddress() {
      localStorage.removeItem('ACHDetails')
      this.isSaved = false
      this.savedAddress = ''
      this.isStorageDataAvailable = false
    },
    toggleEdit() {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.editFormData = { ...this.localFormData }
      }
    },
    cancelEdit() {
      this.isEditing = false
    },
    saveAddress() {
      this.savedAddress = `${this.editFormData.address_1}, ${this.editFormData.address_2}, ${this.editFormData.city}, ${this.editFormData.state.name}, ${this.editFormData.zip}, ${this.editFormData.country_id.name}`
      this.isEditing = false
      this.isSaved = true

      localStorage.setItem('ACHDetails', JSON.stringify(this.editFormData))
      this.isStorageDataAvailable = true
    },
    async countrySelectedEdit(country) {
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res) {
        this.states = res.data.states
        this.editFormData.state = null
      }
    },
  },
}
</script>

<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-text {
  color: red;
  font-size: 0.875rem;
}
</style>