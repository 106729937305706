export const BOOTSTRAP_STRIPE = 'BOOTSTRAP_STRIPE'
export const ADD_STRIPES = 'ADD_STRIPES'
export const UPDATE_STRIPE = 'UPDATE_STRIPE'
export const DELETE_STRIPE = 'DELETE_STRIPE'
export const SET_TOTAL_STRIPE = 'SET_TOTAL_STRIPE'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_STRIPE = 'SET_SELECTED_VIEW_STRIPE'
export const RESET_SELECTED_STRIPE = 'RESET_SELECTED_STRIPE'
export const SET_SELECTED_STRIPE = 'SET_SELECTED_STRIPE'
export const ADD_STRIPE = 'ADD_STRIPE'
export const SAVE_STRIPE = 'SAVE_STRIPE'
export const VOID_STRIPE = 'VOID_STRIPE'
export const REFUNDED_STRIPE = 'REFUNDED_STRIPE'
export const UPDATE_STATUS_STRIPE = 'UPDATE_STATUS_STRIPE'
export const ADD_STRIPE_ACH = 'ADD_STRIPE_ACH'
export const SAVE_STRIPE_ACH = 'SAVE_STRIPE_ACH'