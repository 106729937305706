var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _vm.isSuperAdmin
        ? _c("base-page", { staticClass: "tax-group-create" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitRetention.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "sw-page-header",
                  { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
                  [
                    _c(
                      "sw-breadcrumb",
                      { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                      [
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/settings/retentions",
                            title: _vm.$t("settings.retentions.retention"),
                          },
                        }),
                        _vm._v(" "),
                        _vm.$route.name === "retentions.edit"
                          ? _c("sw-breadcrumb-item", {
                              attrs: {
                                to: "#",
                                title: _vm.$t(
                                  "settings.retentions.edit_retention"
                                ),
                                active: "",
                              },
                            })
                          : _c("sw-breadcrumb-item", {
                              attrs: {
                                to: "#",
                                title: _vm.$t(
                                  "settings.retentions.new_retention"
                                ),
                                active: "",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "flex justify-center w-full md:w-auto",
                            attrs: {
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                              variant: "primary",
                              type: "submit",
                              size: "lg",
                            },
                          },
                          [
                            !_vm.isLoading
                              ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t(
                                        "settings.retentions.update_retention"
                                      )
                                    : _vm.$t(
                                        "settings.retentions.save_retention"
                                      )
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "grid grid-cols-12" }, [
                  _c(
                    "div",
                    { staticClass: "col-span-12" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("settings.retentions.concept"),
                            error: _vm.conceptError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-textarea", {
                            attrs: {
                              invalid: _vm.$v.formData.concept.$error,
                              placeholder: _vm.$t(
                                "settings.retentions.concept"
                              ),
                              type: "text",
                              name: "concept",
                              rows: "3",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.concept.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.concept,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "concept",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.concept",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid gap-6 sm:grid-cols-1 md:grid-cols-3",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.retentions.minimium_base_per_unit"
                                ),
                                error: _vm.minimiunBasePerUnitError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.minimium_base_per_unit
                                      .$error,
                                  placeholder: _vm.$t(
                                    "settings.retentions.minimium_base_per_unit"
                                  ),
                                  type: "number",
                                  name: "minimium_base_per_unit",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.minimium_base_per_unit.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.minimium_base_per_unit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "minimium_base_per_unit",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.minimium_base_per_unit",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.retentions.minimium_base_in_currency"
                                ),
                                error: _vm.minimiunBaseInCurrencyError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.minimium_base_in_currency
                                      .$error,
                                  placeholder: _vm.$t(
                                    "settings.retentions.minimium_base_in_currency"
                                  ),
                                  type: "number",
                                  name: "minimium_base_in_currency",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.minimium_base_in_currency.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.minimium_base_in_currency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "minimium_base_in_currency",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "formData.minimium_base_in_currency",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t("settings.retentions.type"),
                                error: _vm.typeError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  invalid: _vm.$v.formData.type.$error,
                                  options: _vm.type,
                                  searchable: true,
                                  "show-labels": false,
                                  tabindex: 3,
                                  "allow-empty": false,
                                  placeholder: _vm.$t(
                                    "settings.retentions.type"
                                  ),
                                  label: "text",
                                  "track-by": "value",
                                },
                                model: {
                                  value: _vm.formData.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "type", $$v)
                                  },
                                  expression: "formData.type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("settings.retentions.percentage"),
                            error: _vm.percentageError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-money", {
                            staticClass:
                              "relative w-full focus:border focus:border-solid focus:border-primary",
                            attrs: {
                              currency: _vm.defaultInput,
                              invalid: _vm.$v.formData.percentage.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.percentage.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.percentage,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "percentage", $$v)
                              },
                              expression: "formData.percentage",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid gap-6 sm:grid-cols-1 md:grid-cols-2",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t("settings.retentions.foreing"),
                                error: _vm.foreingError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  invalid: _vm.$v.formData.foreing.$error,
                                  options: _vm.foreing,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  placeholder: _vm.$t(
                                    "settings.retentions.foreing"
                                  ),
                                  label: "text",
                                },
                                model: {
                                  value: _vm.formData.foreing,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "foreing", $$v)
                                  },
                                  expression: "formData.foreing",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t("settings.retentions.country"),
                                error: _vm.countryError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  invalid: _vm.$v.formData.country_id.$error,
                                  options: _vm.countries,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  tabindex: 5,
                                  placeholder: _vm.$t("general.select_country"),
                                  label: "name",
                                  "track-by": "id",
                                },
                                on: { select: _vm.countrySeleted },
                                model: {
                                  value: _vm.formData.countries,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "countries", $$v)
                                  },
                                  expression: "formData.countries",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$tc(
                                  "customers.responsabilidad_fiscal"
                                ),
                                required: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex grid md:grid-cols-2" },
                                [
                                  _c("sw-checkbox", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "customers.great_contributor"
                                      ),
                                      disabled:
                                        _vm.formData.not_applicable_others ||
                                        this.type_vat_regime.value == "0",
                                    },
                                    on: {
                                      change: _vm.changeFiscalResponsibility,
                                    },
                                    model: {
                                      value: _vm.formData.great_contributor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "great_contributor",
                                          $$v
                                        )
                                      },
                                      expression: "formData.great_contributor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { staticClass: "mt-3 sm:ml-4" }, [
                                    _vm._v(" 0 - 13 "),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-checkbox", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      label: _vm.$t("customers.self_retaining"),
                                      disabled:
                                        _vm.formData.not_applicable_others ||
                                        this.type_vat_regime.value == "0",
                                    },
                                    on: {
                                      change: _vm.changeFiscalResponsibility,
                                    },
                                    model: {
                                      value: _vm.formData.self_retaining,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "self_retaining",
                                          $$v
                                        )
                                      },
                                      expression: "formData.self_retaining",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { staticClass: "mt-3 sm:ml-4" }, [
                                    _vm._v(" 0 - 15 "),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-checkbox", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "customers.vat_withholding_agent"
                                      ),
                                      disabled:
                                        _vm.formData.not_applicable_others ||
                                        this.type_vat_regime.value == "0",
                                    },
                                    on: {
                                      change: _vm.changeFiscalResponsibility,
                                    },
                                    model: {
                                      value: _vm.formData.vat_withholding_agent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "vat_withholding_agent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.vat_withholding_agent",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { staticClass: "mt-3 sm:ml-4" }, [
                                    _vm._v(" 0 - 23 "),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-checkbox", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "customers.simple_tax_regime"
                                      ),
                                      disabled:
                                        _vm.formData.not_applicable_others ||
                                        this.type_vat_regime.value == "0",
                                    },
                                    on: {
                                      change: _vm.changeFiscalResponsibility,
                                    },
                                    model: {
                                      value: _vm.formData.simple_tax_regime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "simple_tax_regime",
                                          $$v
                                        )
                                      },
                                      expression: "formData.simple_tax_regime",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { staticClass: "mt-3 sm:ml-4" }, [
                                    _vm._v(" 0 - 47 "),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-5" }, [
                                _c("label", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$tc(
                                          "customers.responsabilidad_fiscal_desc"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }