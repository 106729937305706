var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("roles.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("roles.title", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        variant: "primary-outline",
                        type: "button",
                        size: "lg",
                      },
                      on: { click: _vm.backButton },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.back")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            "tag-name": "router-link",
                            to: "roles/create",
                            variant: "primary",
                            size: "lg",
                          },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("roles.add_new_role")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("roles.filter.name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("roles.no_roles"),
                description: _vm.$t("roles.list_of_roles"),
              },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/roles/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("roles.add_new_role")) + "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.lengthRolesMin))]),
                    _vm._v(
                      "\n\n        " +
                        _vm._s(_vm.$t("general.of")) +
                        "\n\n        "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.totalRoles))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("roles.name"),
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("roles.description"),
                      show: "description",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("customers.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _vm.permissionModule.update
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `roles/${row.id}/edit`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.delete
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removePackage(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("trash-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      982102477
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }