var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hold-invoice-modal" }, [
    _c(
      "div",
      { staticClass: "relative table-container h-96 overflow-auto" },
      [
        _c(
          "sw-table-component",
          {
            ref: "table",
            attrs: {
              "show-filter": false,
              data: _vm.fetchData,
              "table-class": "table",
            },
          },
          [
            _c("sw-table-column", {
              attrs: {
                sortable: true,
                label: _vm.$t("core_pos.date"),
                "sort-as": "invoice_date",
                show: "invoice_date",
              },
            }),
            _vm._v(" "),
            _c("sw-table-column", {
              attrs: {
                sortable: true,
                label: _vm.$t("core_pos.reference"),
                "sort-as": "description",
                show: "description",
              },
            }),
            _vm._v(" "),
            _c("sw-table-column", {
              attrs: { sortable: true, label: "", "sort-as": "", show: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.getHoldInvoice(row)
                              },
                            },
                          },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-10 text-gray-600",
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("sw-table-column", {
              attrs: { sortable: true, label: "", "sort-as": "", show: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openHoldInvoicesPdfModal(row)
                              },
                            },
                          },
                          [
                            _c("printer-icon", {
                              staticClass: "h-10 text-gray-600",
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("sw-table-column", {
              attrs: {
                sortable: true,
                label: "",
                "sort-as": "total",
                show: "total",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.deleteHoldInvoiceSelected(row)
                              },
                            },
                          },
                          [
                            _c("trash-icon", {
                              staticClass: "h-10 ml-4 text-gray-600",
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }