<template>
  <div>
    <!-- Tarjeta de crédito -->
    <sw-input-group label="Cardholder Name" class="mb-4" required>
      <sw-input
        v-model="localFormData.card_holder"
        required
        class="input-field"
        placeholder="Enter cardholder name"
        autoComplete="off"
        @input="
          validateCardholderName
          updateLocalFormData()
        "
      />
      <p v-if="cardHolderError" class="error-text">{{ cardHolderError }}</p>
    </sw-input-group>

    <sw-input-group label="Card Number" class="mb-4" required>
      <div class="input-with-icon">
        <img
          :src="paymentAccountPreview.src"
          :width="paymentAccountPreview.width"
          class="card-icon"
          alt="Card Image"
        />
        <sw-input
          v-model="localFormData.card_number"
          required
          class="input-field"
          placeholder="Enter card number"
          autocomplete="off"
          @input="changeCreditCard"
        />
      </div>
      <p v-if="cardNumberError" class="error-text">{{ cardNumberError }}</p>
    </sw-input-group>
    
    <div class="grid grid-cols-2 gap-4 mb-4">
      <!-- Contenedor para la fecha de expiración -->
      <sw-input-group label="Expiration Date" required>
        <div class="expiration-container">
          <sw-select
            v-model="selectedMonth"
            :options="months"
            class="input-field w-20"
            placeholder="MM"
            label="month"
            track-by="value"
            @input="
              validateExpirationDate
              updateLocalFormData('expiration_month', selectedMonth)
            "
          />

          <sw-select
            v-model="selectedYear"
            :options="years"
            class="input-field w-20"
            placeholder="YY"
            label="year"
            track-by="value"
            @input="
              validateExpirationDate
              updateLocalFormData('expiration_year', selectedYear)
            "
          />
        </div>
        <p v-if="expirationDateError" class="error-text">
          {{ expirationDateError }}
        </p>
      </sw-input-group>

      <sw-input-group label="CVV" required>
        <sw-input
          v-model="localFormData.cvv"
          required
          class="input-field"
          placeholder="Enter CVV"
          autoComplete="off"
          @input="
            validateCVV
            updateLocalFormData()
          "
        />
        <p v-if="errors.cvv" class="error-text">{{ errors.cvv }}</p>
      </sw-input-group>
    </div>

    <!-- Dirección de facturación -->
    <h3 class="mb-4">Billing Address</h3>
    <sw-input-group label="Address Line 1" class="mb-4" required>
      <sw-input
        v-model="localFormData.address_1"
        required
        class="input-field"
        placeholder="Enter address"
        autoComplete="off"
        @input="updateLocalFormData()"
      />
    </sw-input-group>
    <sw-input-group label="Address Line 2 (Optional)" class="mb-4">
      <sw-input
        v-model="localFormData.address_2"
        class="input-field"
        placeholder="Enter address line 2"
        autoComplete="off"
        @input="updateLocalFormData()"
      />
    </sw-input-group>

    <div class="grid grid-cols-2 gap-4 mb-4">
      <sw-input-group :label="$t('customers.city')" required>
        <sw-input
          v-model="localFormData.city"
          required
          class="input-field"
          placeholder="Enter city"
          autocomplete="off"
          @input="updateLocalFormData()"
        />
      </sw-input-group>

      <sw-input-group :label="$t('customers.zip_code')" required>
        <sw-input
          v-model="localFormData.zip"
          required
          class="input-field"
          placeholder="Enter ZIP code"
          autocomplete="off"
          @input="updateLocalFormData()"
        />
      </sw-input-group>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-4">
      <sw-input-group :label="$t('customers.country')" required>
        <sw-select
          v-model="localFormData.country"
          :options="countries"
          :searchable="true"
          :show-labels="false"
          :placeholder="'Select a country'"
          label="name"
          track-by="id"
          @select="countrySelected"
          required
          autoComplete="off"
          @input="updateLocalFormData()"
        />
      </sw-input-group>

      <sw-input-group :label="$t('customers.state')" required>
        <sw-select
          v-model="localFormData.state"
          :options="states"
          :searchable="true"
          :show-labels="false"
          :placeholder="'Select a state'"
          label="name"
          track-by="id"
          required
          autoComplete="off"
          @input="updateLocalFormData()"
        />
      </sw-input-group>
    </div>

    <!-- Checkboxes y botones -->
    <div
      v-if="!showPaymentForm"
      class="mt-4 p-4 border-gray-300 rounded-lg space-y-4"
    >
      <!-- <label v-if="paymentType === 'oneTime'" class="flex items-center">
        <input
          type="checkbox"
          @change="updateLocalFormData"
          v-model="localFormData.saveForFutureUse"
          class="form-checkbox h-4 w-4 rounded-full border-gray-300 focus:ring-0 text-blue-600"
          style="border-radius: 50%; border-width: 1px; border-color: #d1d5db"
        />
        <span class="ml-2 text-gray-700 text-sm"
          >Save this payment method for future use</span
        >
      </label> -->

      <!-- SWITCH -->
      <div
        class="flex md:col-span-3"
        v-if="
          (paymentType === 'oneTime' && localFormData.saveForFutureUse) ||
          paymentType === 'saved'
        "
      >
        <div class="relative w-12">
          <sw-switch
            v-model="localFormData.saved_pay"
            class="absolute"
            style="top: -20px"
            @change="updateLocalFormData"
          />
        </div>

        <div class="ml-4">
          <p class="p-0 mb-1 text-base leading-snug text-black box-title">
            Make Payment Account as Default
          </p>
        </div>
      </div>

      <div
        class="mt-4 flex justify-end space-x-2"
        v-if="paymentType === 'saved'"
      >
        <sw-button :variant="'primary'" @click.prevent="submitForm">
          <template v-if="isLoading">
            <div class="loader"></div>
          </template>
          <template v-else> Save Account </template>
        </sw-button>
      </div>
    </div>

    <p v-if="submissionError" class="error-text">
      Invalid information provided. Please check the highlighted fields.
    </p>

    <!-- Mostrar dirección guardada -->
    <div v-if="isSaved && !showPaymentForm" class="mt-4 saved-address">
      <h3>Billing Address</h3>
      <p><strong>Default Billing Address:</strong></p>
      <p>{{ savedAddress }}</p>
      <a href="#" @click.prevent="toggleEdit" class="edit-link">Edit</a>
      <a href="#" @click.prevent="removeAddress" class="remove-link">Remove</a>

      <!-- Mostrar formulario de edición de dirección -->
      <div v-if="isEditing" class="mt-4">
        <sw-input-group label="Address Line 1" class="mb-4">
          <sw-input
            v-model="editFormData.address"
            required
            class="input-field"
            placeholder="Enter address"
            autoComplete="off"
          />
        </sw-input-group>
        <sw-input-group label="Address Line 2 (Optional)" class="mb-4">
          <sw-input
            v-model="editFormData.address2"
            class="input-field"
            placeholder="Enter address line 2"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="City" class="mb-4">
          <sw-input
            v-model="editFormData.city"
            required
            class="input-field"
            placeholder="Enter city"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="State" class="mb-4">
          <sw-select
            v-model="editFormData.state"
            :options="states"
            :searchable="true"
            :show-labels="false"
            :placeholder="'Select a state'"
            label="name"
            track-by="id"
            required
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="ZIP Code" class="mb-4">
          <sw-input
            v-model="editFormData.zip"
            required
            class="input-field"
            placeholder="Enter ZIP code"
            autoComplete="off"
          />
        </sw-input-group>

        <sw-input-group label="Country" class="mb-4">
          <sw-select
            v-model="editFormData.country"
            :options="countries"
            :searchable="true"
            :show-labels="false"
            :placeholder="'Select a country'"
            label="name"
            track-by="id"
            @select="countrySelectedEdit"
            required
            autoComplete="off"
          />
        </sw-input-group>

        <div class="mt-4 flex justify-end space-x-2">
          <sw-button :variant="'secondary'" @click.prevent="cancelEdit"
            >Cancel</sw-button
          >
          <sw-button :variant="'primary'" @click.prevent="saveAddress"
            >Save Address</sw-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../event-bus.js'
import { maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    formData: Object,
    paymentType: String,
    isNewMethod: Boolean,
    disablePay: Boolean,
    updateFormData: Function,
  },
  data() {
    return {
      isSaved: false,
      paymentAccountPreview: {
        src: '/images/bank.png',
        width: '100px',
      },
      customer: {},
      isEditing: false,
      isStorageDataAvailable: false,
      showPaymentForm: false,
      isLoading: false, // Nuevo estado para el preloader
      countries: [],
      states: [],
      savedAddress: '',
      cardHolderError: '',
      cardNumberError: '',
      expirationDateError: '',
      selectedCard: '',
      selectedMonth: '',
      selectedYear: '',
      errors: {
        cvv: '',
      },
      submissionError: false, // Nueva propiedad para manejar la advertencia de error
      editFormData: {
        address: '',
        address2: '',
        city: '',
        state: null,
        zip: '',
        country: null,
      },
      localFormData: { ...this.formData },
      months: [
        { month: '01', value: '01' },
        { month: '02', value: '02' },
        { month: '03', value: '03' },
        { month: '04', value: '04' },
        { month: '05', value: '05' },
        { month: '06', value: '06' },
        { month: '07', value: '07' },
        { month: '08', value: '08' },
        { month: '09', value: '09' },
        { month: '10', value: '10' },
        { month: '11', value: '11' },
        { month: '12', value: '12' },
      ],
      credit_cards: [
        { name: 'VISA', value: 'VISA' },
        { name: 'MASTERCARD', value: 'MASTERCARD' },
        { name: 'AMERICAN EXPRESS', value: 'AMERICAN EXPRESS' },
        { name: 'DISCOVER', value: 'DISCOVER' },
      ],
      years: Array.from({ length: 15 }, (_, i) => ({
        value: (new Date().getFullYear() + i).toString(),
        year: (new Date().getFullYear() + i).toString(),
      })),
    }
  },
  computed: {
    ...mapGetters('company', ['defaultCurrencyForInput']),
    ...mapGetters('customer', ['customers']),
    ...mapGetters('payment', ['paymentModes', 'selectedNote']),

    buttonLabel() {
      if (this.showPaymentForm) {
        return 'Save and Pay'
      } else if (this.saveForFutureUse) {
        return 'Save and Pay'
      } else if (this.isNewMethod) {
        return 'Save New Method'
      } else {
        return 'Use This Method'
      }
    },
  },

  methods: {
    ...mapActions('paymentAccounts', ['addPaymentAccount']),
    ...mapActions('invoice', ['fetchInvoice', 'fetchInvoices', 'fetchInvoiceForUniqueHash']),
    ...mapActions('customer', ['fetchCustomers', 'fetchCustomer', 'fetchCustomerWithoutLogin']),

    changeCreditCard() {
      this.validateCardNumber()
      this.updateLocalFormData()
    },
    async loadCustomerData(params) {
     
     let response = await this.fetchCustomerWithoutLogin(params)
     console.log('data del customer en load customer data: ', response.data.customer);
     const customerData = response.data.customer;

     if(customerData){
        const selectedCountry = this.countries.find(country => country.id === customerData.billing_address.country.id);

        if(selectedCountry){
          await this.countrySelectedEdit(selectedCountry);
        }

        const selectedState = this.states.find(state => state.id === customerData.billing_address.state.id);

        this.localFormData = {
          state: selectedState || null ,
          country: selectedCountry || null,
          card_holder: customerData.name
        }
     }
     console.log('formdata in set data: ', this.localFormData)
     this.isLoading = false;
   },

    async loadCountries() {
      let res = await window.axios.get('/api/v1/countries')
      if (res && res.data && res.data.countries) {
        this.countries = res.data.countries
      }
    },
    async loadStates(country) {
      if (!country || !country.code) return
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res && res.data && res.data.states) {
        this.states = res.data.states
      }
    },
    async countrySelectedEdit(country) {
      this.editFormData.state = ''
      await this.loadStates(country)
    },

    validateCardholderName() {
      const maxLength = 21
      if (this.localFormData.card_holder.length > maxLength) {
        this.cardHolderError = `Cardholder name cannot exceed ${maxLength} characters`
      } else {
        this.cardHolderError = ''
      }
    },
    updateCardImage(payment_account) {
      const creditCardImages = {
        VISA: { src: '/images/visa.png', width: '65px' },
        MASTERCARD: { src: '/images/mastercard.png', width: '80px' },
        'AMERICAN EXPRESS': {
          src: '/images/american_express.png',
          width: '90px',
        },
        DISCOVER: { src: '/images/discover.png', width: '100px' },
      }

      if (payment_account && creditCardImages[payment_account.value]) {
        const cardDetails = creditCardImages[payment_account.value]
        this.paymentAccountPreview.src = cardDetails.src
        this.paymentAccountPreview.width = cardDetails.width
      }
    },
    validateCardNumber() {
      const regex = /^[0-9]{13,19}$/
      if (!regex.test(this.localFormData.card_number)) {
        this.cardNumberError = 'Invalid card number'
        return
      }

      let cardType = ''
      const cardNumber = this.localFormData.card_number
      if (cardNumber.startsWith('4')) cardType = 'VISA'
      else if (cardNumber.startsWith('5')) cardType = 'MASTERCARD'
      else if (cardNumber.startsWith('3')) cardType = 'AMERICAN EXPRESS'
      else if (cardNumber.startsWith('6')) cardType = 'DISCOVER'

      const selectedCard = this.credit_cards.find(
        (card) => card.value === cardType
      )
      if (selectedCard) {
        this.localFormData.credit_card = selectedCard
        this.updateCardImage(selectedCard)
      } else {
        this.localFormData.credit_card = null
        this.paymentAccountPreview.src = '/images/bank.png'
        this.paymentAccountPreview.width = '100px'
      }

      this.cardNumberError = ''
    },

    validateExpirationDate() {
      const month = this.localFormData.expiration_month
      const year = this.localFormData.expiration_year
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()

      if (
        isNaN(month) ||
        isNaN(year) ||
        month < 1 ||
        month > 12 ||
        year < currentYear ||
        (year == currentYear && month < currentMonth)
      ) {
        this.expirationDateError = 'Card expired'
      } else {
        this.expirationDateError = ''
      }
    },
    validateCVV() {
      if (!this.localFormData.card_number) {
        this.errors.cvv = 'Invalid CVV'
        return
      }

      const firstDigit = this.localFormData.card_number.charAt(0)
      const cvv = this.localFormData.cvv

      if (!/^\d+$/.test(cvv)) {
        this.errors.cvv = 'Invalid CVV: must be numeric'
        return
      }

      const cvvLength = cvv.length

      // Validar CVV de acuerdo al número de la tarjeta
      if (firstDigit === '3' && cvvLength !== 4) {
        this.errors.cvv =
          'Invalid CVV: must be 4 digits for card types starting with 3 or 6'
      } else if (
        (firstDigit === '4' ||
          firstDigit === '5' ||
          firstDigit === '6' ||
          firstDigit === '2') &&
        cvvLength !== 3
      ) {
        this.errors.cvv =
          'Invalid CVV: must be 3 digits for card types starting with 4 or 5'
      } else {
        this.errors.cvv = ''
      }
    },
    updateLocalFormData(key, value) {
      const processedValue =
        typeof value === 'object' && value !== null && value.value
          ? value.value
          : value
      this.localFormData[key] = processedValue
      if (this.updateFormData) {
        this.updateFormData({ ...this.localFormData })
        this.localFormData.payment_account_type = 'CC'
      }
    },
    async submitForm() {
      this.submissionError = false // Resetear la advertencia de error

      this.validateCardholderName()
      this.validateCardNumber()
      this.validateExpirationDate()
      this.validateCVV()

      if (
        this.cardHolderError ||
        this.cardNumberError ||
        this.expirationDateError ||
        this.errors.cvv
      ) {
        this.submissionError = true
        return
      }

      this.isLoading = true

      const dataPaymentAccount = {
        first_name: this.localFormData.card_holder?.substring(0, 21),
        country_id: this.localFormData.country.id,
        state_id: this.localFormData.state.id,
        city: this.localFormData.city,
        address_1: this.localFormData.address_1,
        address_2: this.localFormData.address_2,
        zip: this.localFormData.zip,
        payment_account_type: this.localFormData.payment_account_type,
        client_id: this.customer.id,
        status: 'A',
        main_account: 0,
        nameOnAccount: this.localFormData.card_holder?.substring(0, 21),
        credit_cards: this.localFormData.credit_card,
        cvv: this.localFormData.cvv,
        card_number: this.localFormData.card_number,
        expiration_date: `${this.localFormData.expiration_year}-${this.localFormData.expiration_month}`,
      }
      console.log('data payment: ', dataPaymentAccount)
      try {
        const responsePaymentaccount = await this.addPaymentAccount(
          dataPaymentAccount
        )
        if (responsePaymentaccount.status === 200) {
          window.toastr['success']('Payment Account Added')
          this.$emit('paymentSucess')
        } else {
          window.toastr['error']('Failed to add payment account')
        }
      } catch (error) {
        window.toastr['error']('Failed to add payment account')
      }
      this.isLoading = false
    },
    resetCreditCardForm() {
      this.formData.card_holder = ''
      this.formData.card_number = ''
      this.formData.expiration_month = ''
      this.formData.expiration_year = ''
      this.formData.cvv = ''
    },
    resetAddressForm() {
      this.formData.address_1 = ''
      this.formData.address_2 = ''
      this.formData.city = ''
      this.formData.state = null
      this.formData.zip = ''
      this.formData.country_array = null
    },
    cancelAction() {
      this.isSaved = false
      this.savedAddress = ''
    },
    async countrySelected(country) {
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res) {
        this.states = res.data.states
        this.formData.state = null
      }
    },
    async countrySelectedEdit(country) {
      let res = await window.axios.get(`/api/v1/states/${country.code}`)
      if (res) {
        this.states = res.data.states
        this.editFormData.state = null
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.editFormData = { ...this.formData }
      }
    },
    cancelEdit() {
      this.isEditing = false
    },
    saveAddress() {
      this.savedAddress = `${this.editFormData.address_1}, ${this.editFormData.address_2}, ${this.editFormData.city}, ${this.editFormData.state.name}, ${this.editFormData.zip}, ${this.editFormData.country.name}`
      this.isEditing = false
      this.isSaved = true

      localStorage.setItem('billingAddress', JSON.stringify(this.editFormData))
      this.isStorageDataAvailable = true
    },
    removeAddress() {
      localStorage.removeItem('billingAddress')
      localStorage.removeItem('saveType')
      localStorage.removeItem('savedCard')
      this.isSaved = false
      this.savedAddress = ''
      this.isStorageDataAvailable = false

      this.resetAddressForm()
      EventBus.$emit('cardSaved', false)
    },
    showFormAndHideAddress() {
      this.showPaymentForm = true
      this.isStorageDataAvailable = false
    },
    async setInvoicePaymentData(unique_hash) {
      
      let data = await this.fetchInvoiceForUniqueHash(unique_hash)
      
      if(data.data.invoice == null)
      {
        this.invoiceIsCanceled = true
        return
      }     

      this.invoiceNumber = data.data.invoice.invoice_number

      if(data.data.invoice.status === "COMPLETED" && data.data.invoice.paid_status === "PAID")
      {    
        this.invoiceIsCompleted = true
      }    

      this.customer = data.data.invoice.user
      this.invoice = data.data.invoice
      this.formData.amount = data.data.invoice.due_amount
      this.maxPayableAmount = data.data.invoice.due_amount
      this.company_id = data.data.invoice.company_id
    },
  },
  watch: {
    'formData.card_number': 'validateCVV',
    'formData.cvv': 'validateCVV',
  },
  async created() {

    await this.setInvoicePaymentData(this.$route.params.unique_hash);

    console.log('customer: ', this.customer)
    let res = await window.axios.get('/api/v1/countries')
    if (res) {
      this.countries = res.data.countries
    }

    EventBus.$on('addNewPaymentMethod', this.showFormAndHideAddress)

    const savedAddress = localStorage.getItem('billingAddress')
    const saveType = localStorage.getItem('saveType')

    if (savedAddress && saveType === 'Use This Method') {
      this.editFormData = JSON.parse(savedAddress)
      this.savedAddress = `${this.editFormData.address_1}, ${this.editFormData.address_2}, ${this.editFormData.city}, ${this.editFormData.state.name}, ${this.editFormData.zip}, ${this.editFormData.country.name}`
      this.isSaved = true
      this.isStorageDataAvailable = true

      if (this.editFormData.country && this.editFormData.country.code) {
        await this.countrySelectedEdit(this.editFormData.country)
      }
    }
    let params = {
      id: this.customer.id
    }
    await this.loadCustomerData(params);

  },
  beforeDestroy() {
    EventBus.$off('addNewPaymentMethod', this.showFormAndHideAddress)
  },
}
</script>

<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.saved-address {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.saved-address h3 {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.saved-address p {
  margin: 0.25rem 0;
}

.saved-address strong {
  font-weight: bold;
}

.edit-link {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.edit-link:hover {
  text-decoration: underline;
}

.remove-link {
  color: #ff0000;
  text-decoration: none;
  font-size: 14px;
  margin-left: 10px;
}

.remove-link:hover {
  text-decoration: underline;
}

.mb-4 {
  margin-bottom: 1rem;
}

.expiration-container {
  display: flex;
  gap: 0.5rem;
}

.w-20 {
  width: 5rem;
}

.sw-input-group .sw-input.input-field:focus,
.sw-select.input-field:focus {
  border: 1px solid blue !important;
}

.error-text {
  color: red;
  font-size: 0.875rem;
}
.input-with-icon {
  display: flex;
  align-items: center;
}
.card-icon {
  margin-right: 10px;
}
</style>