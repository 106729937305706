export function validateCreditCard(number) {

    const digitos = number.split('').reverse().map(Number);

    // algoritmo Luhn

    let sum = 0;

    for (let i = 0; i < digitos.length; i++) {
        let digito = digitos[i];
        if (i % 2 === 1) {
            digito *= 2;
            if (digito > 9) {
            digito -= 9;
            }
        }
        sum += digito;
    }
    return sum % 10 === 0;
}

export function getTypeCard(number) {
    const bin = number.substring(0, 2);
    if (/^3[47]/.test(bin)) return "AMERICAN EXPRESS";
    if (/^4/.test(bin)) return "VISA";
    if (/^5[1-5]/.test(bin)) return "MASTERCARD";
    if (/^6(011|5)/.test(bin)) return "DISCOVER";
    return "Desconocido";
}

export function validateCardTypeAndNumber(number, cardType) {
    const tipoTarjeta = getTypeCard(number);
    const isValid = validateCreditCard(number);

    if (!isValid) {
        return { isValid: false, message: 1 };
    }

    if (tipoTarjeta !== cardType.value) {
        return { isValid: false, message: 2 };
    }

    return { isValid: true, message: '' };
}