var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 512.171 512.171" },
      attrs: {
        width: "50",
        height: "50",
        viewBox: "0 0 512.171 512.171",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M509.035,248.212l-213.504-212.8c-3.051-3.029-7.595-3.904-11.627-2.304c-3.989,1.664-6.571,5.547-6.571,9.856v117.333\n\t\t\tH10.667C4.779,160.298,0,165.076,0,170.964v170.667c0,5.888,4.779,10.667,10.667,10.667h266.667v116.885\n\t\t\tc0,4.309,2.603,8.192,6.592,9.856c1.323,0.555,2.709,0.811,4.075,0.811c2.773,0,5.504-1.088,7.552-3.115l213.504-213.419\n\t\t\tc2.005-2.005,3.115-4.715,3.115-7.552C512.171,252.927,511.04,250.218,509.035,248.212z",
          fill: "#000",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }