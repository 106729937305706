var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "5" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "40%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "10%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
              _vm._v(" "),
              _vm.discountPerItem === "YES"
                ? _c("col", { staticStyle: { width: "15%" } })
                : _vm._e(),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", { staticStyle: { "background-color": "#f5f3f3" } }, [
                _c("td", { staticClass: "px-5 py-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      !_vm.isView
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                            },
                            [_c("drag-icon")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isView
                        ? _c("div", [
                            _c("p", {
                              staticClass:
                                "text-sm font-bold leading-5 text-black non-italic",
                              domProps: { textContent: _vm._s(_vm.item.name) },
                            }),
                          ])
                        : _c("item-select", {
                            ref: "itemSelect",
                            attrs: {
                              invalid: _vm.$v.item.name.$error,
                              "invalid-description":
                                _vm.$v.item.description.$error,
                              item: _vm.item,
                              "tax-per-item": _vm.taxPerItem,
                              taxes: _vm.item.taxes,
                            },
                            on: {
                              search: _vm.searchVal,
                              select: _vm.onSelectItem,
                              deselect: _vm.deselectItem,
                              onDesriptionInput: function ($event) {
                                return _vm.$v.item.description.$touch()
                              },
                              onSelectItem: function ($event) {
                                _vm.isSelected = true
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-5 py-4 text-right align-top" },
                  [
                    _vm.isView
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-end text-sm",
                          },
                          [
                            _c("span", [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.item.quantity),
                                },
                              }),
                            ]),
                          ]
                        )
                      : _c("sw-input", {
                          attrs: {
                            invalid: _vm.$v.item.quantity.$error,
                            type: "number",
                            small: "",
                          },
                          on: {
                            keyup: _vm.updateItem,
                            input: function ($event) {
                              return _vm.$v.item.quantity.$touch()
                            },
                          },
                          model: {
                            value: _vm.item.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "quantity", $$v)
                            },
                            expression: "item.quantity",
                          },
                        }),
                    _vm._v(" "),
                    _vm.$v.item.quantity.$error
                      ? _c("div", [
                          !_vm.$v.item.quantity.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("validation.quantity_maxlength")
                                    ) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _vm.isView
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-start text-sm",
                              },
                              [
                                _c("span", [
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(_vm.price) },
                                  }),
                                ]),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "relative w-full" },
                              [
                                _c("sw-money", {
                                  attrs: {
                                    currency: _vm.customerCurrency,
                                    invalid: _vm.$v.item.price.$error,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.item.price.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.price,
                                    callback: function ($$v) {
                                      _vm.price = $$v
                                    },
                                    expression: "price",
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _vm.$v.item.price.$error
                          ? _c("div", [
                              !_vm.$v.item.price.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("validation.price_maxlength")
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.discountPerItem === "YES"
                  ? _c("td", { staticClass: "px-5 py-4 text-left align-top" }, [
                      _c("div", { staticClass: "flex flex-col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex flex-auto",
                            attrs: { role: "group" },
                          },
                          [
                            _c("sw-input", {
                              staticClass:
                                "border-r-0 rounded-tr-none rounded-br-none",
                              attrs: {
                                type: "number",
                                invalid: _vm.$v.item.discount_val.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.item.discount_val.$touch()
                                },
                              },
                              model: {
                                value: _vm.discount,
                                callback: function ($$v) {
                                  _vm.discount = $$v
                                },
                                expression: "discount",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown",
                              [
                                _c(
                                  "sw-button",
                                  {
                                    staticStyle: { height: "43px" },
                                    attrs: {
                                      slot: "activator",
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      size: "discount",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                      variant: "white",
                                    },
                                    slot: "activator",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex" },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.item.discount_type == "fixed"
                                                ? _vm.currency.symbol
                                                : "%"
                                            ) +
                                            "\n                      "
                                        ),
                                        _c("chevron-down-icon", {
                                          staticClass: "h-5",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  { on: { click: _vm.selectFixed } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("general.fixed")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  { on: { click: _vm.selectPercentage } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("general.percentage")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center text-sm justify-end" },
                    [
                      _c("span", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.total, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      !_vm.isView
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                            },
                            [
                              _vm.isShowRemoveItemIcon
                                ? _c("trash-icon", {
                                    staticClass: "h-5 text-gray-700",
                                    on: { click: _vm.removeItem },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tr",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "td",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-left": "10px",
                      },
                    },
                    [
                      _c("sw-switch", {
                        staticClass: "relative",
                        staticStyle: { top: "-10px", "margin-bottom": "10px" },
                        attrs: { disabled: _vm.isView },
                        model: {
                          value: _vm.item.end_period_act,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "end_period_act", $$v)
                          },
                          expression: "item.end_period_act",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: {
                        "flex-grow": "1",
                        "margin-left": "15px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("packages.end_period_act")) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 m-0 text-xs leading-tight text-gray-500",
                            staticStyle: {
                              "max-width": "480px",
                              "margin-left": "10px",
                              "margin-bottom": "10px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("packages.end_period_act_desp")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { "margin-left": "10px" } },
                    [
                      _c("sw-input", {
                        staticStyle: {
                          "margin-top": "10px",
                          "margin-bottom": "10px",
                        },
                        attrs: {
                          label: _vm.$t("packages.end_period_num"),
                          disabled: _vm.isView,
                          type: "number",
                          min: "1",
                          small: "",
                          onkeydown:
                            "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                        },
                        model: {
                          value: _vm.item.end_period_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "end_period_number", $$v)
                          },
                          expression: "item.end_period_number",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.taxPerItem === "YES"
                ? _c("tr", { staticClass: "tax-tr" }, [
                    _c(
                      "td",
                      { staticClass: "align-top", attrs: { colspan: "12" } },
                      _vm._l(_vm.item.taxes, function (tax, index) {
                        return _c("tax", {
                          key: tax.id,
                          attrs: {
                            disabled: _vm.item.no_taxable == 1 ? true : false,
                            index: index,
                            "tax-data": tax,
                            taxes: _vm.item.taxes,
                            taxPerItem: _vm.taxPerItem,
                            isView: _vm.isView,
                            "discounted-total": _vm.total,
                            "total-tax": _vm.totalSimpleTax,
                            total: _vm.total,
                            currency: _vm.currency,
                          },
                          on: { update: _vm.updateTax, remove: _vm.removeTax },
                        })
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }