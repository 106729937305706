var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.$tc("auxpay.name") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/settings/payment-gateways",
                  title: _vm.$tc("settings.payment_gateways.title"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/settings/AuxVault",
                  title: _vm.$tc("auxpay.name"),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/settings/AuxVault/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeAuthorize(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c("sw-card", [
              _c("div", [
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("authorize.basic_info")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                  _c("div", { staticClass: "w-full md:w-1/3" }, [
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("auxpay.endpoint"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(_vm.formData.endpoint)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full md:w-1/3 mt-3 md:mt-0" }, [
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("authorize.name"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(_vm.formData.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full md:w-1/3 mt-3 md:mt-0" }, [
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("auxpay.merchantid"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(_vm.formData.merchant_id)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { staticClass: "mt-5", attrs: { title: " " } },
        [
          _c("template", { slot: "actions" }, [
            _c("div", { staticClass: "w-full" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { slot: "activator", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openPaymentFee()
                        },
                      },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.add")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full mt-5" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c("sw-card", [
              _c(
                "div",
                { staticClass: "relative table-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-between h-10 list-none border-b-2 border-gray-200 border-solid",
                    },
                    [_c("p", { staticClass: "text-sm" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "notes_table",
                      attrs: {
                        "show-filter": false,
                        "table-class": "table",
                        data: _vm.fetchPaymentFeesData,
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("payment_fees.name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("payment_fees.name"))),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.name) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("payment_fees.type"),
                          show: "type",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("payment_fees.type"))),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.type) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("authorize.number"),
                          show: "amount",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("authorize.number"))),
                                ]),
                                _vm._v(" "),
                                row.type === "fixed"
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            row.amount,
                                            _vm.defaultCurrency
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                row.type === "percentage"
                                  ? _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row.amount / 100) + "  % "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: { "cell-class": "action-dropdown no-click" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPaymentFeeEdit(row)
                                          },
                                        },
                                      },
                                      [
                                        _c("pencil-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("general.edit")) +
                                            "\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteNote(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("trash-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            "\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }