export const SET_ESTIMATES = 'SET_ESTIMATES'
export const ADD_ESTIMATE = 'ADD_ESTIMATE'
export const UPDATE_ESTIMATE = 'UPDATE_ESTIMATE'
export const DELETE_ESTIMATE = 'DELETE_ESTIMATE'
export const DELETE_MULTIPLE_ESTIMATES = 'DELETE_MULTIPLE_ESTIMATES'
export const SET_SELECTED_ESTIMATES = 'SET_SELECTED_ESTIMATES'
export const SET_TOTAL_ESTIMATES = 'SET_TOTAL_ESTIMATES'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const RESET_ITEM = 'RESET_ITEM'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_ITEM = 'SET_ITEM'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_ESTIMATES = 'RESET_SELECTED_ESTIMATES'
export const UPDATE_ESTIMATE_STATUS = 'UPDATE_ESTIMATE_STATUS'
export const VIEW_ESTIMATE = 'VIEW_ESTIMATE'

export const SET_SELECTED_NOTE = 'SET_SELECTED_NOTE'
export const RESET_SELECTED_NOTE = 'RESET_SELECTED_NOTE'
export const SET_USERS_OPTIONS = 'SET_USERS_OPTIONS'
