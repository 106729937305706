var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("leads.customers_leads") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("leads.customers_leads", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showEmptyScreen,
                          expression: "!showEmptyScreen",
                        },
                      ],
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n         " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n         "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        variant: "primary",
                        size: "lg",
                        to: "leads",
                        type: "button",
                        "tag-name": "router-link",
                      },
                    },
                    [
                      _vm._v(
                        "\n         " +
                          _vm._s(_vm.$t("leads.title")) +
                          "\n       "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        variant: "primary",
                        size: "lg",
                        to: "customers",
                        type: "button",
                        "tag-name": "router-link",
                      },
                    },
                    [
                      _vm._v(
                        "\n       " +
                          _vm._s(_vm.$t("leads.customer")) +
                          "\n       "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "relative grid grid-flow-col grid-rows",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        "margin-left": "1em",
                        "margin-right": "1em",
                      },
                    },
                    [
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$tc("leads.search") } },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              type: "text",
                              name: "name",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.filters.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "search", $$v)
                              },
                              expression: "filters.search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        "margin-left": "1em",
                        "margin-right": "1em",
                      },
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("leads.type") },
                        },
                        [
                          _c("sw-select", {
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              options: _vm.type_options,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t("leads.type"),
                              "allow-empty": false,
                              "track-by": "value",
                              label: "name",
                            },
                            model: {
                              value: _vm.filters.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "type", $$v)
                              },
                              expression: "filters.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [
                      _vm._v(
                        "\n         " + _vm._s(_vm.$t("general.clear_all"))
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("leads.name"),
                  show: "company_name",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("leads.name")))]),
                          _vm._v(" "),
                          row.company_name != null
                            ? _c("span", [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(row.company_name) +
                                    "\n         "
                                ),
                              ])
                            : row.first_name != null
                            ? _c("span", [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(
                                      row.first_name + " " + row.last_name
                                    ) +
                                    "\n         "
                                ),
                              ])
                            : _c("span", [_vm._v(" N/A ")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1751094860
                ),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("leads.email"),
                  show: "email",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: true, label: _vm.$t("leads.phone") },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("leads.phone")))]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(row.phone ? row.phone : "No Contact") + " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1542198147
                ),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: true, label: _vm.$t("leads.type") },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("leads.type")))]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.type_from) + " ")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  910266870
                ),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("leads.customer_type"),
                  "sort-as": "customer_type",
                  show: "customer_type",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [_vm._v(_vm._s(row.customer_type))]),
                          _vm._v(" "),
                          row.customer_type === "N"
                            ? _c("span", [_vm._v("None")])
                            : row.customer_type === "B"
                            ? _c("span", [_vm._v("Business")])
                            : _c("span", [_vm._v("Residential")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3879073544
                ),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("leads.action")) + " "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            [
                              _c("dot-icon", {
                                attrs: { slot: "activator" },
                                slot: "activator",
                              }),
                              _vm._v(" "),
                              row.type_from == "lead"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `leads/${row.id}/edit`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n                 " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n             "
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      row.status === "A"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "sw-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.convertCustomer(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("pencil-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                  }),
                                                  _vm._v(
                                                    "\n                   " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "leads.convert_customer"
                                                        )
                                                      ) +
                                                      "\n               "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `customers/${row.id}/edit`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n                 " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n             "
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `customers/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _c("eye-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n                 " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n             "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2939616087
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }