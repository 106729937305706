<template>
  <base-page class="relative">
    <form action="">
      <div class="mt-4">
        <sw-input-group :label="$t('expenses.docs')">
          <div
            id="docs-box "
            class="hover:bg-gray-200 py-2 cursor-pointer md:w-1/3 mt-5 bg-transparent border-2 border-gray-400 border-dashed rounded-md"
          >
            <label for="uploadDocs">
              <input
                class="form-control hidden uploadDocs"
                id="uploadDocs"
                type="file"
                multiple
                @change="onChangeDocs"
              />
              <div class="flex flex-col items-center">
                <cloud-upload-icon
                  class="h-5 mb-2 text-xl leading-6 text-gray-400"
                />

                <p class="text-xs leading-4 text-center text-gray-400">
                  <span id="pick-avatar" class="text-primary-500">browse</span>
                  to choose a file
                </p>
              </div>
            </label>
          </div>
          <sw-divider class="my-8" />
          <div class="flex flex-wrap">
            <div
              class="px-1 py-2 w-full md:w-1/2 lg:w-1/3"
              v-for="(file, indexTr) in formData.docs"
              :key="indexTr"
            >
              <div
                class="bg-transparent border-2 border-gray-400 border-dashed rounded-md"
              >
                <div class="p-3 flex flex-wrap">
                  <!-- <sw-input-group :label="$t('login.email')" class="mb-4"> -->
                  <sw-input-group label="Name" class="mb-4">
                    <sw-input
                      :placeholder="file.file_name"
                      v-model="file.file_name"
                      type="text"
                      @blur="updateFile(file)"
                    />
                  </sw-input-group>

                  <sw-input-group label="Description" class="mb-4">
                    <sw-input
                      :placeholder="file.file_description"
                      v-model="file.file_description"
                      type="text"
                      @blur="updateFile(file)"
                    />
                  </sw-input-group>

                  <p class="mb-2 w-full">
                    {{ $t('expenses.size') }}: {{ sizeFile(file.size) }}
                  </p>

                  <!-- type -->
                  <p class="mb-2 w-full">Type: {{ file.typeFile }}</p>

                  <!-- actions buttons -->
                  <div class="flex w-full">
                    <span
                      v-bind:class="{ downloadFilename: isEdit }"
                      v-on:click="OpenSeeDocumentExpensesModal(file)"
                      class="pointer"
                    >
                      <eye-icon class="h-6 mr-3 text-gray-600" />
                    </span>
                    <span v-on:click="downloadFile(file)" class="pointer">
                      <download-icon class="h-6 mr-3 text-gray-600" />
                    </span>
                    <span v-on:click="deleteFile(file)" class="pointer">
                      <trash-icon class="h-6 mr-3 text-gray-600" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </sw-input-group>

        <sw-input-group>
          <!-- <ul class="list-group mt-10">
              <li class="list-group-item" v-for="file in formData.docs">
                <span v-on:click="deleteFile" class="pointer"><trash-icon class="h-5 mr-3 text-gray-600" style="position: absolute"/></span>
                <span style="margin-left: 31px;"> {{ file.name }} </span>
              </li>
            </ul> -->
        </sw-input-group>
      </div>
      <sw-divider class="my-8" />
    </form>
  </base-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const {
  required,
  requiredIf,
  minValue,
  numeric,
  maxLength,
} = require('vuelidate/lib/validators')
import {
  DownloadIcon,
  SwitchHorizontalIcon,
  PencilIcon,
  XIcon,
} from '@vue-hero-icons/outline'
import { PlusSmIcon } from '@vue-hero-icons/solid'
import {
  CloudUploadIcon,
  ShoppingCartIcon,
  TrashIcon,
  EyeIcon,
  XCircleIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    CloudUploadIcon,
    ShoppingCartIcon,
    DownloadIcon,
    TrashIcon,
    EyeIcon,
    SwitchHorizontalIcon,
    XIcon,
    XCircleIcon,
    PlusSmIcon,
    PencilIcon,
  },
  props: {
    note: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      isProviderRequired: false,
      formData: {
        docs: [],
        docsSend: [],
        existantDocs: [],
      },
      isLoading: false,
      isCreate: false,
      isEdit: false,
      category: null,
      previewReceipt: null,
      isPDF: false,
      loadingFiles: false,
      uploadedFileType: '',
    }
  },

  validations: {
    category: {
      required,
    },
    formData: {
      notes: {
        maxLength: maxLength(65000),
      },
    },
  },

  computed: {
    ...mapGetters('company', ['defaultCurrency', 'defaultCurrencyForInput']),
    ...mapGetters('payment', ['paymentModes', 'selectedNote']),

    ...mapGetters('category', ['categories']),

    ...mapGetters('company', ['getSelectedCompany']),

    ...mapGetters('providers', ['providers']),

    ...mapGetters('payment', ['payments']),
  },

  watch: {
    note: {
      handler: function (val) {
        if (val) {
          this.getDocs()
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('expense', [
      'getExpenseReceipt',
      'getExpenseDocs',
      'addExpense',
      'updateExpense',
      'fetchExpense',
      'downloadExpenseDoc',
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['getUserModules']),
    ...mapActions('category', ['fetchCategories']),
    ...mapActions('customer', ['fetchCustomers', 'fetchOnlyCustomer']),
    ...mapActions('company', ['fetchCompanySettings']),
    ...mapActions('providers', ['fetchProviders', 'fetchOnlyProvider']),
    ...mapActions('item', ['fetchItems', 'fetchOnlyitem']),
    ...mapActions('taxType', ['fetchTaxTypes']),
    ...mapActions('payment', ['fetchPayments', 'fetchPaymentModes']),
    ...mapActions('corePos', ['fetchStores']),
    ...mapActions('modules', ['getModules']),
    ...mapActions('customerTicket', [
      'saveNoteDocuments',
      'updateNoteDocument',
      'deleteDocument',
      'downloadNoteDocument',
      ,
    ]),
    sizeFile(size) {
      if (size < 1024) {
        return size + ' B'
      }
      if (size < 1048576) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      if (size < 1073741824) {
        return (size / 1048576).toFixed(2) + ' MB'
      }
      return (size / 1073741824).toFixed(2) + ' GB'
    },
    OpenSeeDocumentExpensesModal(file) {
      // let media = this.formData.docs.find(file => file.id === file.id);
      this.openModal({
        title: this.$t('expenses.see_document_expenses'),
        componentName: 'SeeDocumentExpensesModal',
        props: {
          media: file,
        },
      })
    },
    onChange(e) {
      if (e == null) return
      if (e.target.files[0].type == 'application/pdf') {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = (event) => {
          this.previewReceipt = event.target.result
        }

        this.fileObject = e.target.files[0]
        this.isReceiptAvailable = false
      } else {
        window.toastr['error'](this.$t('expenses.error_not_pdf'))
      }
    },

    async onChangeDocs(data) {
      try {
        this.loadingFiles = true
        if (data == null) return

        let sendingData = new FormData()
        let filePromises = []

        Array.from(data.target.files).forEach((file, index) => {
          const filePromise = new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = (event) => {
              this.formData.docs.push({
                file_name: file.name,
                size: file.size,
                description: '',
                type: file.type,
                base64: event.target.result,
                typeFile: file.name.split('.').pop(),
              })

              let uploadFileSize = (file.size / 1048576).toFixed(2) * 1
              this.uploadedFileType = file.name.split('.').pop()

              if (uploadFileSize > 2) {
                window.toastr['error'](this.$t('tickets.notes.error_file_size'))
                this.formData.docs.pop()
                return
              }

              if (
                [
                  'pdf',
                  'doc',
                  'docx',
                  'xls',
                  'xlsx',
                  'ppt',
                  'pptx',
                  'txt',
                  'jpg',
                  'jpeg',
                  'png',
                  'gif',
                  'bmp',
                  'tiff',
                  'svg',
                  'zip',
                  'rar',
                  '7z',
                  'tar',
                  'gz',
                  'bz2',
                  'tgz',
                ].indexOf(this.uploadedFileType) === -1
              ) {
                window.toastr['error'](this.$t('tickets.notes.error_file_type'))
                this.formData.docs.pop()
                return
              }

              this.formData.docsSend.push(file)

              sendingData.append(`files[${index}][file]`, file)
              sendingData.append(`files[${index}][name]`, file.name)
              sendingData.append(
                `files[${index}][description]`,
                file.description || ''
              )
              resolve()
            }
            fileReader.onerror = (error) => {
              reject(error)
            }
          })

          filePromises.push(filePromise)
        })

        sendingData.append('note_ticket_id', this.$route.params.id)

        // Esperar a que todas las promesas de lectura de archivos se resuelvan
        await Promise.all(filePromises)
        console.log(sendingData)
        await this.saveNoteDocuments(sendingData)
        this.reloadNoteDocuments()
      } catch (error) {
        window.toastr['error'](error)
      } finally {
        this.loadingFiles = false
      }
    },

    /*     deleteFile(index) {
      this.formData.docs.splice(index, 1);
      this.formData.docsSend.splice(index, 1);
    }, */

    async updateFile(file) {
      const sendData = {
        id: file.id,
        name: file.file_name,
        description: file.file_description || '',
      }
      if (file.file_name === '') {
        window.toastr['error'](this.$t('tickets.notes.error_file_name'))
        return
      }
      await this.updateNoteDocument(sendData)
      window.toastr['success'](this.$tc('tickets.notes.document_updated'))
    },

    async deleteFile(file) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('tickets.notes.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteDocument(file.id)

          if (res.data.success) {
            window.toastr['success'](this.$tc('tickets.notes.document_deleted'))
            this.reloadNoteDocuments()
            return true
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async reloadNoteDocuments() {
      // this.formData.docs = [];
      await this.$emit('reloadDocuments')
    },

    async downloadFile(file) {
      const byteString = atob(file.base64.split(',')[1])
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const uint8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([uint8Array], { type: file.typeFile })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', file.file_name + '.' + file.typeFile)
      link.click()
    },

    async getDocs() {
      let filesData = this.note.files
      const filesDataArr = []
      filesData.forEach((file) => {
        var oFile = this.dataURLtoFile(file.base64, file.name)
        oFile.id = file.id
        oFile.model_id = file.model_id
        oFile.isEdit = false
        oFile.base64 = file.base64
        oFile.file_name = file.name
        oFile.file_description = file.description
        oFile.typeFile = file.disk_url.split('.').pop()
        filesDataArr.push(oFile)
      })
      this.formData.docs = filesDataArr
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    removePdfReceipt() {
      this.previewReceipt = null
      this.fileObject = null
    },
  },
}
</script>

<style scoped>
.previewHeight {
  height: 600px;
}
.pointer {
  cursor: pointer;
}

.marginFilename {
  margin-left: 31px;
}

.description {
  height: 100px;
}

.downloadFilename {
  cursor: pointer;
  color: rgba(88, 81, 216, var(1));
}

.noborders {
  border: none;
}
</style>
