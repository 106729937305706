var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateServicesSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span- mt-2 mb-2",
              attrs: {
                label: _vm.$t("corePbx.customization.pbx_service_bbc_email"),
                error: _vm.emailError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.email.$error,
                  type: "text",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.services_creation_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_creation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "pbx_creation_services_subject", $$v)
                  },
                  expression: "services.pbx_creation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.services_creation"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_creation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "pbx_creation_services", $$v)
                  },
                  expression: "services.pbx_creation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.services_suspension_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_suspension_services_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.services,
                      "pbx_suspension_services_subject",
                      $$v
                    )
                  },
                  expression: "services.pbx_suspension_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.services_suspension"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_suspension_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "pbx_suspension_services", $$v)
                  },
                  expression: "services.pbx_suspension_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.services_cancellation_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_cancellation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.services,
                      "pbx_cancellation_services_subject",
                      $$v
                    )
                  },
                  expression: "services.pbx_cancellation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.services_cancellation"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_cancellation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "pbx_cancellation_services", $$v)
                  },
                  expression: "services.pbx_cancellation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.services_reactivation_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_reactivation_services_subject,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.services,
                      "pbx_reactivation_services_subject",
                      $$v
                    )
                  },
                  expression: "services.pbx_reactivation_services_subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.services_reactivation"),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.services.pbx_reactivation_services,
                  callback: function ($$v) {
                    _vm.$set(_vm.services, "pbx_reactivation_services", $$v)
                  },
                  expression: "services.pbx_reactivation_services",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingTemplateEmail,
                    disabled: _vm.isLoadingTemplateEmail,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingTemplateEmail
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updatePrefixSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.services_prefix"),
                error: _vm.ExpensePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.pbx_services_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.pbx_services_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE")
                  },
                },
                model: {
                  value: _vm.expenses.pbx_services_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "pbx_services_prefix", $$v)
                  },
                  expression: "expenses.pbx_services_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.services_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "services_prefix_general", $$v)
                    },
                    expression: "expenses.services_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n                   " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "              "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingPrefix,
                    disabled: _vm.isLoadingPrefix,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingPrefix
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("corePbx.customization.task_scheduling")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: { change: (option) => _vm.slideRenewalDate(option) },
                  model: {
                    value: _vm.allow_renewal_date_job,
                    callback: function ($$v) {
                      _vm.allow_renewal_date_job = $$v
                    },
                    expression: "allow_renewal_date_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.allow_renewal_date_job")
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.renewal_date_job_desc")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.time_run_renewal_date_job"
                ),
                error: _vm.timeJobError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.time_run_renewal_date_job.$touch()
                  },
                },
                model: {
                  value: _vm.time_run_renewal_date_job,
                  callback: function ($$v) {
                    _vm.time_run_renewal_date_job = $$v
                  },
                  expression: "time_run_renewal_date_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingRenewal,
                    disabled: _vm.isLoadingRenewal,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingRenewal
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.customization.save_scheduling")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateSuspensionScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("corePbx.customization.package_suspension")) +
                "\n          "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: {
                    change: (option) => _vm.slidePackagesSuspensionDate(option),
                  },
                  model: {
                    value: _vm.allow_suspension_pbx_job,
                    callback: function ($$v) {
                      _vm.allow_suspension_pbx_job = $$v
                    },
                    expression: "allow_suspension_pbx_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.$t(
                          "corePbx.customization.allow_packagesuspension_date_job"
                        )
                      ) +
                      "\n                  "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.$t(
                          "corePbx.customization.packagesuspension_date_job_desc"
                        )
                      ) +
                      "\n                  "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mb-4",
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.time_run_packagesuspension_date_job"
                ),
                error: _vm.timeSuspensionError,
                required: "",
              },
            },
            [
              _c("base-time-picker", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.time_run_suspension_pbx_job.$error,
                  "calendar-button": true,
                  placeholder: "HH:mm",
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.time_run_suspension_pbx_job.$touch()
                  },
                },
                model: {
                  value: _vm.time_run_suspension_pbx_job,
                  callback: function ($$v) {
                    _vm.time_run_suspension_pbx_job = $$v
                  },
                  expression: "time_run_suspension_pbx_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingSuspension,
                    disabled: _vm.isLoadingSuspension,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingSuspension
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("corePbx.customization.save_suspension")) +
                      "\n          "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateUnsuspendScheduling.apply(null, arguments)
            },
          },
        },
        [
          _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("corePbx.customization.unsuspend_services")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-6" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: { change: (option) => _vm.slideUnsuspendPbx(option) },
                  model: {
                    value: _vm.allow_unsuspend_pbx_job,
                    callback: function ($$v) {
                      _vm.allow_unsuspend_pbx_job = $$v
                    },
                    expression: "allow_unsuspend_pbx_job",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.allow_unsuspend_date_job")
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.unsuspend_date_job_desc")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.period_time_run_job"),
                error: _vm.timeUnsuspendError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid: _vm.$v.period_run_unsuspend_job.$error,
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.period_run_unsuspend_job.$touch()
                  },
                },
                model: {
                  value: _vm.period_run_unsuspend_job,
                  callback: function ($$v) {
                    _vm.period_run_unsuspend_job = $$v
                  },
                  expression: "period_run_unsuspend_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingUnsuspend,
                    disabled: _vm.isLoadingUnsuspend,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingUnsuspend
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.customization.save_suspension")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }