var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("customers.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { title: _vm.$t("general.home"), to: "dashboard" },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("customers.customer", 2),
                      to: "#",
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalCustomers,
                      expression: "totalCustomers",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalCustomers,
                      expression: "totalCustomers",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    size: "lg",
                    "tag-name": "router-link",
                    to: "customers/disable-list",
                    variant: "primary",
                  },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.disable_list")) +
                      "\n        "
                  ),
                  _c("eye-off-icon", {
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "customers/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-sm-icon", {
                        staticClass: "h-6 mr-1 -ml-2 font-bold",
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("customers.add_new_customer")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("customers.display_nametable") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.display_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "display_name", $$v)
                      },
                      expression: "filters.display_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.contact_name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "address_name",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "contact_name", $$v)
                      },
                      expression: "filters.contact_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.phone") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "phone", autocomplete: "off" },
                    model: {
                      value: _vm.filters.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "phone", $$v)
                      },
                      expression: "filters.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: {
                    label: _vm.$t("customers.status"),
                    color: "black-light",
                    hidden: "",
                  },
                },
                [
                  _c("base-status-select", {
                    ref: "statusSelect",
                    on: {
                      select: _vm.onSelectStatus,
                      deselect: _vm.clearStatusSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("customers.no_customers"),
            description: _vm.$t("customers.list_of_customers"),
          },
        },
        [
          _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/customers/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("customers.add_new_customer")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "sw-tabs",
            {
              ref: "tabsStatusPayments",
              staticClass: "hidden md:inline",
              attrs: { "active-tab": _vm.activeTab },
              on: { update: _vm.setStatusFilter },
            },
            [
              _c("sw-tab-item", {
                attrs: { title: _vm.$t("general.all"), filter: "ALL" },
              }),
              _vm._v(" "),
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("customers.customer_prepaid"),
                  filter: "prepaid",
                },
              }),
              _vm._v(" "),
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("customers.customer_postpaid"),
                  filter: "postpaid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "-mt-10 md:mt-0",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("customers.customer_number"),
                  show: "customcode",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.customer_number"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `customers/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.customcode) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "font-medium text-black-500" },
                              [_vm._v(_vm._s(row.customcode))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("customers.display_nametable"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.display_nametable"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `customers/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "font-medium text-black-500" },
                              [_vm._v(_vm._s(row.name))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.contact_name"),
                  show: "contact_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.contact_name"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.contact_name
                                  ? row.contact_name
                                  : _vm.$t("customers.no_contact_name")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.phone"),
                  show: "phone",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("customers.phone")))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.phone
                                  ? row.phone
                                  : _vm.$t("customers.no_contact")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.amount_due"),
                  show: "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("customers.amount_due")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.added_on"),
                  "sort-as": "created_at",
                  show: "formattedCreatedAt",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.type"),
                  show: "status_payment",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("customers.type")))]),
                        _vm._v(" "),
                        row.status_payment == "prepaid"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.customer_prepaid"))
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status_payment == "postpaid"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.customer_postpaid"))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("customers.action")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `customers/${row.id}/edit`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `customers/${row.id}/view`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCustomer(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("customers.deletecustomer")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }