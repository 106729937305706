var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "item-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitGroup.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/dashboard",
                        title: _vm.$t("general.home"),
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/groups",
                        title: _vm.$tc("groups.group", 2),
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "groups.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("groups.edit_group"),
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("groups.new_group"),
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3 text-sm hidden sm:flex",
                        attrs: {
                          variant: "primary-outline",
                          type: "button",
                          size: "lg",
                        },
                        on: { click: _vm.cancelForm },
                      },
                      [
                        !_vm.isLoading
                          ? _c("x-circle-icon", {
                              staticClass: "w-6 h-6 mr-1 -ml-2",
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                          tabindex: "3",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("groups.update_group")
                                : _vm.$t("groups.save_group")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12 md:col-span-12" },
                [
                  _c(
                    "sw-card",
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("groups.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-6 mb-4",
                          attrs: { label: _vm.$t("groups.description") },
                        },
                        [
                          _c("base-custom-input", {
                            staticClass: "mb-4",
                            attrs: { tabindex: 1 },
                            model: {
                              value: _vm.formData.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "description",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-checkbox", {
                        attrs: {
                          label: _vm.$t("groups.allow_upgrades_downgrades"),
                          variant: "primary",
                          size: "sm",
                          tabindex: 2,
                        },
                        model: {
                          value: _vm.formData.allow_upgrades,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "allow_upgrades",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.allow_upgrades",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-input-group", {
                        staticClass: "mt-6 mb-4",
                        attrs: { label: _vm.$t("packages.title") },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            [
                              _c("sw-divider", { staticClass: "col-span-12" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-span-3" }, [
                                _c("h6", [
                                  _vm._v(
                                    _vm._s(_vm.$t("groups.member_packages"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid gap-6 grid-col-1 md:grid-cols-2",
                                    staticStyle: {
                                      height: "100px",
                                      "overflow-y": "scroll",
                                      "border-color": "#cbd5e0",
                                      "border-width": "1px",
                                      "border-style": "solid",
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.packageLeft,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveToRight(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cursor-pointer",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.name) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticStyle: {
                                  "text-align": "center",
                                  padding: "20px",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-span-7" }, [
                                _c("h6", [
                                  _vm._v(
                                    _vm._s(_vm.$t("groups.available_packages"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid gap-6 grid-col-1 md:grid-cols-2",
                                    staticStyle: {
                                      height: "100px",
                                      "overflow-y": "scroll",
                                      "border-color": "#cbd5e0",
                                      "border-width": "1px",
                                      "border-style": "solid",
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.packageRight,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveToLeft(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cursor-pointer",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.name) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-6 mb-4" }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                      attrs: {
                        variant: "primary-outline",
                        type: "button",
                        size: "lg",
                      },
                      on: { click: _vm.cancelForm },
                    },
                    [
                      !_vm.isLoading
                        ? _c("x-circle-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass:
                        "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                      attrs: {
                        loading: _vm.isLoading,
                        variant: "primary",
                        type: "submit",
                        size: "lg",
                        tabindex: "3",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("groups.update_group")
                              : _vm.$t("groups.save_group")
                          ) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }