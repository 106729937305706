var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-fluid p-0" },
        [
          _c(
            "sw-card",
            { staticClass: "pl-2" },
            [
              _c("div", { staticClass: "w-full flex justify-end" }, [
                _c(
                  "div",
                  { staticClass: "mb-3 hidden xl:block" },
                  [
                    _c(
                      "sw-button",
                      {
                        attrs: { variant: "primary-outline" },
                        on: { click: _vm.toggleSideBar },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("tickets.departaments.menu")) +
                            "\n            "
                        ),
                        _c(_vm.listIcon, {
                          tag: "component",
                          staticClass: "w-4 h-4 ml-2 -mr-1",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("sw-tabs", { staticClass: "p-2 max-w-300" }, [
                _c("div", { staticClass: "row no-gutters max-w-300" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3 max-w-300" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.invoices.title"
                            ),
                          },
                        },
                        [
                          _c("invoices-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.estimates.title"
                            ),
                          },
                        },
                        [
                          _c("estimates-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.payments.title"
                            ),
                          },
                        },
                        [
                          _c("payments-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t("settings.customization.items.title"),
                          },
                        },
                        [
                          _c("items-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.packages.title"
                            ),
                          },
                        },
                        [
                          _c("packages-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.customer.title"
                            ),
                          },
                        },
                        [
                          _c("preflix-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t(
                              "settings.customization.expenses.title"
                            ),
                          },
                        },
                        [
                          _c("expenses-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-4 mb-3" },
                    [
                      _c(
                        "sw-tab-item",
                        {
                          attrs: {
                            title: _vm.$t("settings.customization.sms_config"),
                          },
                        },
                        [
                          _c("sms-tab", {
                            attrs: {
                              settings: _vm.settings,
                              permission: _vm.permissionModule,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }