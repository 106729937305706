var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "profit-loss-reports reports" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$tc("reports.report", 2) } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.home"),
                      to: `/admin/dashboard`,
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("reports.report", 2),
                      to: `/admin/reports`,
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    loading: _vm.isDownloadOnGoing,
                    disabled: _vm.isDownloadOnGoing,
                    size: "lg",
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onDownload()
                    },
                  },
                },
                [
                  !_vm.isDownloadOnGoing
                    ? _c("download-icon", { staticClass: "h-5 mr-1 -ml-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("reports.download_pdf")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "sw-tabs",
            [
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("reports.sales.sales"),
                  route: "/admin/reports/sales",
                },
              }),
              _vm._v(" "),
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("reports.profit_loss.profit_loss"),
                  route: "/admin/reports/profit-loss",
                },
              }),
              _vm._v(" "),
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("reports.expenses.expenses"),
                  route: "/admin/reports/expenses",
                },
              }),
              _vm._v(" "),
              _c("sw-tab-item", {
                attrs: {
                  title: _vm.$t("reports.taxes.taxes"),
                  route: "/admin/reports/taxes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.activeTab === "SALES" || "PROFIT_LOSS" || "EXPENSES" || "TAXES"
          ? _c("div", [_c("router-view", { ref: "report" })], 1)
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }