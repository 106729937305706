var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("corePbx.prefix_groups.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.corePbx"),
                      to: "/admin/corePBX",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("corePbx.prefix_groups.prefix_group", 2),
                      to: "#",
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalPrefixGroups,
                      expression: "totalPrefixGroups",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePBX/billing-templates/international-rate`,
                        variant: "primary-outline",
                        size: "lg",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "corePbx.prefix_groups.custom_destination_button"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "prefix-groups/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-sm-icon", {
                        staticClass: "h-6 mr-1 -ml-2 font-bold",
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("corePbx.prefix_groups.new_prefix_group")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("corePbx.prefix_groups.title") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("general.type") },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.type,
                      "group-select": false,
                      searchable: true,
                      "show-labels": false,
                      placeholder: "Select a prefix type",
                      "allow-empty": false,
                      "group-values": "options",
                      "group-label": "label",
                      "track-by": "name",
                      label: "name",
                    },
                    model: {
                      value: _vm.filters.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "type", $$v)
                      },
                      expression: "filters.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.clear_all")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("corePbx.prefix_groups.no_prefix_groups"),
            description: _vm.$t("corePbx.prefix_groups.list_of_prefix_groups"),
          },
        },
        [
          _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _vm.permissionModule.create
            ? _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/corePBX/billing-templates/prefix-groups/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("corePbx.prefix_groups.add_new_prefixes_group")
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.prefixGroups.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalPrefixGroups))]),
              ]),
              _vm._v(" "),
              _c(
                "sw-transition",
                { attrs: { type: "fade" } },
                [
                  _vm.selectedPrefixGroups.length
                    ? _c(
                        "sw-dropdown",
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                              _c("chevron-down-icon", { staticClass: "h-5" }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  "removeMultipleItemGroups"
                                },
                              },
                            },
                            [
                              _c("trash-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoadingDelete ? _c("base-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("corePbx.prefix_groups.name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("corePbx.prefix_groups.name"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `prefix-groups/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.name) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v("´\n\n      "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("corePbx.prefix_groups.prefix_groups_type"),
                  show: "type",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(" Type")]),
                        _vm._v(
                          "\n          " + _vm._s(row.type) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("corePbx.prefix_groups.description"),
                  show: "description",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("corePbx.prefix_groups.description"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.description
                                ? row.description
                                : _vm.$t("item_groups.empty")
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("customers.action")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `prefix-groups/${row.id}/edit`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.create
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `prefix-groups/${row.id}/copy`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("plus-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("packages.copy")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `prefix-groups/${row.id}/view`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePrefixGroup(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }