export const BOOTSTRAP_AUTHORIZATIONS = 'BOOTSTRAP_AUTHORIZATIONS'
export const ADD_AUTHORIZATION = 'ADD_AUTHORIZATION'
export const UPDATE_AUTHORIZATION = 'UPDATE_AUTHORIZATION'
export const DELETE_AUTHORIZATION = 'DELETE_AUTHORIZATION'
export const SET_TOTAL_AUTHORIZATIONS = 'SET_TOTAL_AUTHORIZATIONS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_AUTHORIZATION = 'SET_SELECTED_VIEW_AUTHORIZATION'
export const RESET_SELECTED_AUTHORIZATION = 'RESET_SELECTED_AUTHORIZATION'
export const SET_SELECTED_AUTHORIZATIONS = 'SET_SELECTED_AUTHORIZATIONS'
export const ADD_AUTHORIZE = 'ADD_AUTHORIZE'
export const SAVE_AUTHORIZE = 'SAVE_AUTHORIZE'
export const VOID_AUTHORIZE = 'VOID_AUTHORIZE'
export const REFUNDED_AUTHORIZE = 'REFUNDED_AUTHORIZE'
export const UPDATE_STATUS_AUTHORIZE = 'UPDATE_STATUS_AUTHORIZE'
export const ADD_AUTHORIZE_ACH = 'ADD_AUTHORIZE_ACH'
export const SAVE_AUTHORIZE_ACH = 'SAVE_AUTHORIZE_ACH'
export const ADD_AUTHORIZE_PAYPAL = 'ADD_AUTHORIZE_PAYPAL'
export const SAVE_PAYPAL = 'SAVE_PAYPAL'