var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitItemCategory.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "p-8 sm:p-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.items.name"),
                error: _vm.nameError,
                variant: "horizontal",
                required: "",
              },
            },
            [
              _c("sw-input", {
                ref: "name",
                attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.name.$touch()
                  },
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-3 mb-4 p-8 sm:p-6" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              model: {
                value: _vm.formData.is_group,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "is_group", $$v)
                },
                expression: "formData.is_group",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            {
              staticClass:
                "p-0 mb-1 text-base leading-snug text-black box-title",
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("settings.customization.items.group")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-3 mb-4 p-8 sm:p-6" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              model: {
                value: _vm.formData.is_item,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "is_item", $$v)
                },
                expression: "formData.is_item",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            {
              staticClass:
                "p-0 mb-1 text-base leading-snug text-black box-title",
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("settings.customization.items.item")) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closeItemCategoryModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                loading: _vm.isLoading,
                variant: "primary",
                icon: "save",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    !_vm.isEdit
                      ? _vm.$t("general.save")
                      : _vm.$t("general.update")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }