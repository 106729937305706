export default {
    package_id: null,
    item_id: null,
    item_group_id: null,
    name: '',
    title: '',
    description: null,
    quantity: 1,
    price: 0,
    discount_type: 'fixed',
    discount_val: 0,
    discount: 0,
    total: 0,
    totalTax: 0,
    totalSimpleTax: 0,
    totalCompoundTax: 0,
    tax: 0,
    taxes: [],
    no_taxable: 0,
}