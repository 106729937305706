var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("estimates.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("estimates.estimate", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalCustomerEstimates,
                      expression: "totalCustomerEstimates",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:mx-8",
                  attrs: { label: _vm.$t("estimates.status") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.status,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$t("general.select_a_status"),
                    },
                    on: {
                      select: _vm.setActiveTab,
                      remove: function ($event) {
                        return _vm.clearStatusSearch()
                      },
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: _vm.$t("general.from"),
                    color: "black-light",
                  },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "hidden w-8 h-0 mx-4 border border-gray-400 border-solid xl:block",
                staticStyle: { "margin-top": "3.5rem" },
              }),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("general.to"), color: "black-light" },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2 xl:ml-8",
                  attrs: {
                    label: _vm.$t("estimates.estimate_number"),
                    color: "black-light",
                  },
                },
                [
                  _c(
                    "sw-input",
                    {
                      model: {
                        value: _vm.filters.estimate_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "estimate_number", $$v)
                        },
                        expression: "filters.estimate_number",
                      },
                    },
                    [
                      _c("hashtag-icon", {
                        staticClass: "h-5 ml-1 text-gray-500",
                        attrs: { slot: "leftIcon" },
                        slot: "leftIcon",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("estimates.no_estimates"),
            description: _vm.$t("estimates.list_of_estimates"),
          },
        },
        [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            { staticClass: "relative mt-5" },
            [
              _c("p", { staticClass: "absolute left-0 md:mt-12 text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.contShowing))]),
              ]),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  staticClass: "mb-10 hidden md:inline",
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter },
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: "Pending", filter: "SENT" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-transition",
                { attrs: { type: "fade" } },
                [
                  _vm.selectedCustomerEstimates.length
                    ? _c(
                        "sw-dropdown",
                        {
                          staticClass: "absolute float-right",
                          staticStyle: { "margin-top": "-70px" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                              _c("chevron-down-icon", { staticClass: "h-5" }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.removeMultipleEstimates } },
                            [
                              _c("trash-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.estimatesCustomer && _vm.estimatesCustomer.length,
                expression: "estimatesCustomer && estimatesCustomer.length",
              },
            ],
            staticClass:
              "absolute z-10 items-center pl-4 mt-2 select-none md:mt-6",
          }),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.date"),
                  "sort-as": "estimate_date",
                  show: "formattedEstimateDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$tc("estimates.estimate_number", 1),
                  show: "estimate_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("estimates.estimate", 1))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: { to: { path: `estimates/${row.id}/view` } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.estimate_number) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.customer"),
                  "sort-as": "name",
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.customer_num"),
                  "sort-as": "customcode",
                  show: "customcode",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("estimates.status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            staticClass: "px-3 py-1",
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.status) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.total"),
                  "sort-as": "total",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("estimates.total"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("estimates.action")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          { attrs: { containerClass: "w-56" } },
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: `estimates/${row.id}/view`,
                                },
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.view")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            row.status !== "ACCEPTED"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onMarkAsAccepted(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("estimates.mark_as_accepted")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status !== "REJECTED"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onMarkAsRejected(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("x-circle-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("estimates.mark_as_rejected")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }