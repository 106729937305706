<template>
  <base-page class="payments">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex-grow">
        <sw-page-header :title="'Document'"> </sw-page-header>
        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
          Click on the photos for more options
        </p>
      </div>
      <div class="flex flex-wrap items-center justify-end">
        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/view`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
        >
          {{ $t('customer_address.clientgoback') }}
        </sw-button>
      </div>
    </div>

    <sw-empty-table-placeholder
      v-if="false"
      :title="$t('customer_address.no_customer_address')"
      :description="$t('customer_address.list_of_customer_address')"
    >
      <capsule-icon class="mt-5 mb-4" />
      <sw-button
        slot="actions"
        tag-name="router-link"
        :to="`/admin/customers/${$route.params.id}/add-address`"
        size="lg"
        variant="primary-outline"
      >
        <plus-icon class="w-6 h-6 mr-1 -ml-2" />
        {{ $t('customer_address.new_address') }}
      </sw-button>
    </sw-empty-table-placeholder>

    <!-- Tabla -->
    <div v-show="true" class="relative table-container">
      <sw-table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="'Front Document'"
          show="frontImage"
        >
          <template slot-scope="row">
            <div class="relative inline-block">
              <div class="flex flex-col items-center md:flex-row md:items-start">
                <img 
                  :src="row.frontImage" 
                  alt="Back Document" 
                  width="100"
                  @click="toggleDropdown(row.id, 'front')" 
                  class="cursor-pointer object-cover img-border"
                />
                <div class="mt-2 md:mt-0">
                  <div v-if="isDropdownOpen(row.id, 'front')" class="absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48">
                    <a :href="row.frontImage" download="back_document.png" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><DownloadIcon class="w-5 h-5 mr-2"/> Download</a>
                    <a href="#" @click.prevent="viewImage(row.frontImage)" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><EyeIcon class="w-5 h-5 mr-2"/> View Large</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </sw-table-column>

        <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="'Back Document'"
        show="backImage"
        >
          <template slot-scope="row">
            <div class="relative inline-block">
              <div class="flex flex-col items-center md:flex-row md:items-start">
                <img 
                  :src="row.backImage" 
                  alt="Back Document" 
                  width="100"
                  @click="toggleDropdown(row.id, 'back')" 
                  class="cursor-pointer object-cover img-border"
                />
                  <div class="mt-2 md:mt-0">
                    <div v-if="isDropdownOpen(row.id, 'back')" class="absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48">
                      <a :href="row.backImage" download="back_document.png" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><DownloadIcon class="w-5 h-5 mr-2"/> Download</a>
                      <a href="#" @click.prevent="viewImage(row.backImage)" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><EyeIcon class="w-5 h-5 mr-2"/> View Large</a>
                    </div>
                  </div>
              </div>
            </div>
          </template>
        </sw-table-column>

        <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="'Selfie'"
        show="selfieImage"
        >
          <template slot-scope="row">
            <div class="relative inline-block">
              <div class="flex flex-col items-center md:flex-row md:items-start">
                <img 
                  :src="row.selfieImage" 
                  alt="Selfie Document" 
                  width="100"
                  @click="toggleDropdown(row.id, 'selfie')" 
                  class="cursor-pointer object-cover img-border"
                />
                  <div class="mt-2 md:mt-0">
                    <div v-if="isDropdownOpen(row.id, 'selfie')" class="absolute z-10 bg-white border border-gray-200 rounded shadow-lg w-40 sm:w-48">
                      <a :href="row.selfieImage" download="back_document.png" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><DownloadIcon class="w-5 h-5 mr-2"/> Download</a>
                      <a href="#" @click.prevent="viewImage(row.selfieImage)" class="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100"><EyeIcon class="w-5 h-5 mr-2"/> View Large</a>
                    </div>
                  </div>
              </div>
            </div>
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="'Added on'"
          show="created_at"
        >
          <template slot-scope="row">
            {{ row.formattedCreatedAt ? row.formattedCreatedAt : row.created_at }}
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>

    <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-4 rounded shadow-lg max-w-lg w-full relative">
        <button @click="closeModal" class="absolute top-2 right-2 text-gray-800 hover:text-gray-900 bg-gray-200 p-2 rounded-full">
          &times;
        </button>
        <img :src="modalImage" alt="Large View" class="w-full h-auto" />
      </div>
    </div>

  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CapsuleIcon from '@/components/icon/CapsuleIcon'
import {
  PlusIcon,
  DownloadIcon,
  EyeIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    CapsuleIcon,
    PlusIcon,
    EyeIcon,
    DownloadIcon
  },

  data() {
    return {
      dropdowns: {},
      showModal: false,
      modalImage: '',
    }
  },

  computed: {
    showEmptyScreen() {
      return false && !this.isRequestOngoing
    },

    ...mapGetters('customer', ['customers']),

    ...mapGetters('customerAddress', [
      'totalCustomerAddress',

      /* 'CustomerNote',
      'selectAllField', */
    ]),
  },
  methods: {
    ...mapActions('customerAddress', [
      'fetchCustomerAddresses',
      'deleteCustomerAddress',
      /* 'setSelectAllState', */
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['getUserModules']),
    ...mapActions('customer', [
      'fetchCustomer',
      'billingValidation',
      'fetchCustomerDocuments',
      /* 'addCustomer',
      'updateCustomer', */
    ]),

    async fetchData({ page, sort }) {
      this.isRequestOngoing = true

      let response = await this.fetchCustomerDocuments({
        customer_id: this.$route.params.id,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
        limit: 10,
      })

      this.isRequestOngoing = false
      return {
        data: response.data.customerDocuments.data,
        pagination: {
          totalPages: response.data.customerDocuments.last_page,
          currentPage: page,
          count: response.data.customerDocuments.total,
          orderBy: sort.order || 'desc',
        },
      }
    },

    toggleDropdown(id, imageType) {
      const dropdownKey = `${id}-${imageType}`
      this.$set(this.dropdowns, dropdownKey, !this.dropdowns[dropdownKey])
    },

    isDropdownOpen(id, imageType){
      const dropdownKey = `${id}-${imageType}`
      return this.dropdowns[dropdownKey]
    },
    viewImage(image){
      this.modalImage = image
      this.showModal = true
    },
    closeModal(){
      this.showModal = false
      this.modalImage = ""
    }
  },
}
</script>

<style scoped>
  .img-border{
    border: 8px solid #d1d5db;
    border-radius: 4px;
  }
</style>