var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "tckets-departaments-view" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            {
              staticClass: "mb-3",
              attrs: {
                title: _vm.$t("tickets.departaments.view_pbx_packages"),
              },
            },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/corePBX/packages",
                      title: _vm.$t("corePbx.menu_title.packages"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/packages`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePBX/packages/${_vm.$route.params.id}/edit`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permissionModule.delete
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { slot: "activator", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.removePackage(_vm.$route.params.id)
                        },
                      },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("item_groups.basic_info")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("packages.name_package")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.formData.pbx_package_name) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.type")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c("p", {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      })
                    : _vm._e(),
                  _vm.formData.status_payment.text == "Postpaid"
                    ? _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("packages.item.postpaid")) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.status_payment.text == "Prepaid"
                    ? _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("packages.item.prepaid")) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("packages.status")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c("div", [
                        _vm.formData.status.text == "Active"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.active")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.status.text == "Inactive"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.inactive")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.dropdown_server")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.formData.dropdown_server.server_label
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.dropdown_server
                    ? _c("div", [
                        _vm.formData.dropdown_server.status == "A"
                          ? _c("span", { staticClass: "text-success" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.server_online"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.modules.server_offline"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.status_cdrs")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.labelStatusCdr) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.price")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("decimalFormat")(_vm.formData.rate, 2)
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("corePbx.packages.automatic_suspension")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _c(
                        "sw-badge",
                        {
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.automatic_suspension ? "YES" : "NO"
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.automatic_suspension ? "YES" : "NO"
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.automatic_suspension
                                  ? _vm.$t("general.active")
                                  : _vm.$t("general.inactive")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.automatic_suspension
                  ? _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("corePbx.packages.suspension_type")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.suspension_type == "T"
                                  ? "Tenant"
                                  : "Extension"
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.unmetered")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _c(
                            "sw-badge",
                            {
                              attrs: {
                                "bg-color": _vm.$utils.getBadgeStatusColor(
                                  _vm.unmetered ? "YES" : "NO"
                                ).bgColor,
                                color: _vm.$utils.getBadgeStatusColor(
                                  _vm.unmetered ? "YES" : "NO"
                                ).color,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.unmetered
                                      ? _vm.$t("general.active")
                                      : _vm.$t("general.inactive")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.extensions")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _c(
                            "sw-badge",
                            {
                              attrs: {
                                "bg-color": _vm.$utils.getBadgeStatusColor(
                                  _vm.extensions ? "YES" : "NO"
                                ).bgColor,
                                color: _vm.$utils.getBadgeStatusColor(
                                  _vm.extensions ? "YES" : "NO"
                                ).color,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.extensions
                                      ? _vm.$t("general.active")
                                      : _vm.$t("general.inactive")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.did")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.banBasic
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _c(
                            "sw-badge",
                            {
                              attrs: {
                                "bg-color": _vm.$utils.getBadgeStatusColor(
                                  _vm.did ? "YES" : "NO"
                                ).bgColor,
                                color: _vm.$utils.getBadgeStatusColor(
                                  _vm.did ? "YES" : "NO"
                                ).color,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.did
                                      ? _vm.$t("general.active")
                                      : _vm.$t("general.inactive")
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.call_ratings")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _c(
                        "sw-badge",
                        {
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.call_ratings ? "YES" : "NO"
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.call_ratings ? "YES" : "NO"
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.call_ratings
                                  ? _vm.$t("general.active")
                                  : _vm.$t("general.inactive")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.menu_title.extensions")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.extension_i ? _vm.extension_i.name : "None"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.menu_title.did")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.did_i ? _vm.did_i.name : "None") +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("corePbx.submenu_title.custom_app_rate")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formData.custom_app_rate
                              ? _vm.formData.custom_app_rate
                              : "None"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "corePbx.packages.international_dialing_code"
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.international_dialing_code_selected
                              ? _vm.international_dialing_code_selected
                              : "None"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("packages.inclusive_minutes")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formData.inclusive_minutes
                              ? _vm.formData.inclusive_minutes
                              : 0
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("corePbx.packages.national_dialing_code")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.national_dialing_code_selected
                              ? _vm.national_dialing_code_selected
                              : 0
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.rate_per_minutes")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("decimalFormat")(
                              _vm.rate_per_minutes_selected,
                              5
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("corePbx.packages.minutes_increments")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.minutes_increments_selected
                              ? _vm.minutes_increments_selected
                              : 0
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.packages.typo_incre")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formData.type_time_increment
                              ? _vm.formData.type_time_increment.text
                              : "None"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("packages.general_taxes")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    staticClass: "col-span-12",
                    attrs: {
                      "show-filter": false,
                      data: _vm.paralelo,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("settings.tax_types.tax_name"),
                        show: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", { staticClass: "bg-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.tax_name"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "mt-6" }, [
                                _vm._v(_vm._s(row.name)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.compound_tax"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.tax_types.compound_tax")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        row.compound_tax
                                          ? "Yes"
                                          : "No".replace("_", " ")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.percent"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.percent"))
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.percent) +
                                  " %\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid col-span-12 gap-1 md:grid-cols-6" },
              [
                _c("sw-divider", { staticClass: "col-span-12 my-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }, [
                  _c("h3", { staticClass: "text-lg font-medium" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("corePbx.packages.cdr_taxes")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    staticClass: "col-span-12",
                    attrs: {
                      "show-filter": false,
                      data: _vm.paraleloTaxesCdr,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("settings.tax_types.tax_name"),
                        show: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", { staticClass: "bg-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.tax_name"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "mt-6" }, [
                                _vm._v(_vm._s(row.name)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.compound_tax"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.tax_types.compound_tax")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        row.compound_tax
                                          ? "Yes"
                                          : "No".replace("_", " ")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.percent"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.percent"))
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.percent) +
                                  " %\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.title_discount")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-3" },
                    [
                      _c("sw-divider", { staticClass: "col-span-12 mt-5" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex mt-3 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              on: { change: _vm.onDiscounts },
                              model: {
                                value: _vm.formData.package_discount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "package_discount",
                                    $$v
                                  )
                                },
                                expression: "formData.package_discount",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("packages.apply_general_discount")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-3" }, [
                    _c("div", { staticClass: "flex mt-3 mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.title_discounts")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-select", {
                            attrs: {
                              disabled: _vm.showSelectdiscounts,
                              options: _vm.discounts,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": true,
                              placeholder: _vm.$t("packages.title_discounts"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "type", $$v)
                              },
                              expression: "formData.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em", "margin-left": "30px" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.value_discount")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-input", {
                            attrs: {
                              focus: "",
                              type: "text",
                              name: "value_discount",
                              placeholder: _vm.$t("packages.value_discount"),
                              tabindex: "1",
                              numeric: "",
                              disabled: _vm.bandView,
                            },
                            model: {
                              value: _vm.value_discount,
                              callback: function ($$v) {
                                _vm.value_discount = $$v
                              },
                              expression: "value_discount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.formData.package_discount
              ? _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.discount_term_type"),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.discount_term_type,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "customers.select_a_discount_term"
                            ),
                            label: "name",
                            disabled: _vm.bandView,
                          },
                          model: {
                            value: _vm.formData.discount_term_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount_term_type", $$v)
                            },
                            expression: "formData.discount_term_type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.discountBetweenDates
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "relative grid grid-flow-col grid-rows",
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: _vm.$t("general.from") },
                              },
                              [
                                _c("base-date-picker", {
                                  attrs: {
                                    "calendar-button": true,
                                    disabled: _vm.bandView,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_start_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_start_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_start_date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "hidden w-8 h-0 ml-8 border border-gray-400 border-solid xl:block",
                              staticStyle: { "margin-top": "3.5rem" },
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: _vm.$t("general.to") },
                              },
                              [
                                _c("base-date-picker", {
                                  attrs: {
                                    "calendar-button": true,
                                    disabled: _vm.bandView,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_end_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_end_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_end_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.discountBetweenDates
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              label: _vm.$t("customers.time_unit_number"),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { width: "50%" },
                                attrs: { role: "group" },
                              },
                              [
                                _c("sw-input", {
                                  staticClass:
                                    "border-r-0 rounded-tr-sm rounded-br-sm",
                                  attrs: { disabled: _vm.bandView },
                                  model: {
                                    value: _vm.formData.discount_time_units,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_time_units",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_time_units",
                                  },
                                }),
                                _vm._v(" "),
                                _c("sw-select", {
                                  attrs: {
                                    options: _vm.discount_term,
                                    disabled: _vm.bandView,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "customers.select_a_term"
                                    ),
                                    label: "name",
                                  },
                                  model: {
                                    value: _vm.formData.discount_term,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_term",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_term",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-5 gap-4 mb-8" },
              [
                _c("sw-divider", { staticClass: "col-span-12 mt-5" }),
                _vm._v(" "),
                _c(
                  "h6",
                  { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("packages.packages_items")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                  },
                  [
                    _c("sw-input-group", {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.item_groups") },
                    }),
                    _vm._v(" "),
                    undefined !== _vm.formData.item_groups &&
                    _vm.formData.item_groups.length > 0
                      ? _c("div", { staticClass: "col-span-12" }, [
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap justify-start" },
                            _vm._l(
                              _vm.formData.item_groups,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-indigo-100 bg-indigo-700 border border-indigo-700",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "text-xs text-base leading-none max-w-full flex-initial py-2 pl-2",
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-auto flex-row-reverse",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-6 h-4 ml-2 pr-1",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "100%",
                                                height: "100%",
                                                fill: "none",
                                                viewBox: "0 0 24 24",
                                                stroke: "currentColor",
                                                "stroke-width": "4",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  x1: "18",
                                                  y1: "6",
                                                  x2: "6",
                                                  y2: "18",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "6",
                                                  y1: "6",
                                                  x2: "18",
                                                  y2: "18",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid col-span-12" }, [
                      _c("div", { staticClass: "table-responsive-item2" }, [
                        _c("div", { staticClass: "tablemin" }, [
                          _c(
                            "table",
                            { staticClass: "w-full text-center item-table" },
                            [
                              _c("colgroup", [
                                _c("col", { staticStyle: { width: "40%" } }),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "10%" } }),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "15%" } }),
                                _vm._v(" "),
                                _vm.discountPerItem === "YES"
                                  ? _c("col", { staticStyle: { width: "15%" } })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("col", { staticStyle: { width: "15%" } }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "thead",
                                {
                                  staticClass:
                                    "bg-white border border-gray-200 border-solid",
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", { staticClass: "pl-12" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.$tc("items.item", 1)) +
                                              "\n                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.item.quantity"
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("estimates.item.price")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.discountPerItem === "YES"
                                      ? _c(
                                          "th",
                                          {
                                            staticClass:
                                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "estimates.item.discount"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pr-10 column-heading",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("estimates.item.total")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.formData.items,
                                function (item, index) {
                                  return _c("package-item", {
                                    key: item.id,
                                    attrs: {
                                      index: index,
                                      "item-data": item,
                                      currency: _vm.currency,
                                      isView: true,
                                      isNoGeneralTaxes: _vm.isNoGeneralTaxes,
                                      "package-items": _vm.formData.items,
                                      "tax-per-item": _vm.taxPerItem,
                                      "discount-per-item": _vm.discountPerItem,
                                    },
                                    on: {
                                      update: _vm.updateItem,
                                      itemValidate: _vm.checkItemsData,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _vm.permissionModule.accessPBXServices
              ? _c("div", [_c("tablePbxServicesPackages")], 1)
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }