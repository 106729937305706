var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 512.171 512.171" },
      attrs: {
        width: "50",
        height: "50",
        viewBox: "0 0 512.171 512.171",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M501.504,160.047H234.837V42.714c0-4.309-2.603-8.192-6.592-9.856c-3.989-1.664-8.576-0.747-11.627,2.304L3.115,248.495\n\t\t\tC1.109,250.501,0,253.21,0,256.047c0,2.837,1.131,5.547,3.115,7.552l213.504,213.419c2.048,2.048,4.779,3.115,7.552,3.115\n\t\t\tc1.365,0,2.752-0.256,4.075-0.811c3.989-1.664,6.592-5.547,6.592-9.856V352.047h266.667c5.888,0,10.667-4.779,10.667-10.667\n\t\t\tV170.714C512.171,164.826,507.413,160.047,501.504,160.047z",
          fill: "#000",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }