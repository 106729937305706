var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "div",
      { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-3", attrs: { title: "Expenses" } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    to: "/admin/dashboard",
                    title: _vm.$t("general.home"),
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: { to: "/admin/expenses", title: "Expenses" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center justify-end" },
          [
            _c(
              "sw-button",
              {
                staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                attrs: {
                  "tag-name": "router-link",
                  to: `/admin/expenses`,
                  variant: "primary-outline",
                  size: "lg",
                },
              },
              [
                _c("pencil-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.go_back")) + "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.permissionModule.update
              ? _c(
                  "sw-button",
                  {
                    staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                    attrs: {
                      "tag-name": "router-link",
                      to: `/admin/expenses/${_vm.$route.params.id}/edit`,
                      variant: "primary",
                    },
                  },
                  [
                    _c("pencil-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                attrs: {
                  "tag-name": "router-link",
                  to: `/admin/expenses/${_vm.$route.params.id}/docs`,
                  variant: "primary",
                },
              },
              [
                _c("document-text-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.docs")) + "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.permissionModule.delete
              ? _c(
                  "sw-button",
                  {
                    staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                    attrs: { slot: "activator", variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.removeExpense(_vm.$route.params.id)
                      },
                    },
                    slot: "activator",
                  },
                  [
                    _c("trash-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.delete")) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "w-full" }, [
      _c(
        "div",
        { staticClass: "col-span-12" },
        [
          _c("sw-card", [
            _c("div", [
              _c(
                "p",
                { staticClass: "text-gray-500 uppercase sw-section-title" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.general_data")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                _c("div", { staticClass: "w-full md:w-1/2" }, [
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("expenses.expense_number"))),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-gray-700 text-sm" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formData.expense_number) +
                        "\n              "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.status_name")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.formData.status == "Active"
                      ? _c("p", { staticClass: "text-gray-700 text-sm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("expenses.status_pr")) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formData.status != "Active"
                      ? _c("p", { staticClass: "text-gray-700 text-sm" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("expenses.status_pe")) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.expense_date")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.formData.expense_date) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.expense_category")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.formData.category) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.subject")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.formData.subject) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.provider")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm.formData.provider != null && _vm.isLoading == false
                        ? _c(
                            "span",
                            { staticClass: "text-gray-700 text-sm" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: `/admin/providers/${_vm.formData.provider.id}/view`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.formData.provider.title) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticStyle: { "font-size": "15px" } },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.formData.provider.providers_number
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formData.provider == null && _vm.isLoading == false
                        ? _c("span", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("expenses.empty")) +
                                "\n                  "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.customer")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm.formData.customer != null && _vm.isLoading == false
                        ? _c(
                            "span",
                            { staticClass: "text-gray-700 text-sm" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: `/admin/customers/${_vm.formData.customer.id}/view`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.formData.customer.name) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticStyle: { "font-size": "15px" } },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.formData.customer.customcode) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formData.customer == null && _vm.isLoading == false
                        ? _c("span", { staticClass: "text-gray-700 text-sm" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("expenses.empty")) +
                                "\n                  "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("expenses.item")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.formData.item) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("expenses.note"))),
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "text-gray-700 text-sm",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.formData.note != null ? _vm.formData.note : ""
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("expenses.invoice")) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.formData.invoices.length > 0
                ? _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                    _c("div", { staticStyle: { "min-width": "50rem" } }, [
                      _c(
                        "table",
                        { staticClass: "w-full text-center item-table" },
                        [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "15%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "15%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "40%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "15%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "15%" } }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "thead",
                            {
                              staticClass:
                                "bg-white border border-gray-200 border-solid",
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$t("invoices.invoice_number")
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                                  },
                                  [
                                    _c("span", { staticClass: "pr-0" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("invoices.invoices_subtotal")
                                          ) +
                                          "\n                      "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("packages.taxes")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("invoices.total_tax")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid text-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("invoices.total")) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.formData.invoices,
                              function (invoice, index) {
                                return _c(
                                  "tr",
                                  {
                                    staticClass:
                                      "box-border bg-white border border-gray-200 border-solid rounded-b",
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pt-4 pr-4 pb-4 pl-4 align-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(invoice.invoice_number) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pt-4 pr-4 pb-4 pl-4 align-center",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                invoice.subtotal,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    invoice.taxes.length > 0
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "pt-4 pr-4 pb-4 pl-4 align-center",
                                          },
                                          _vm._l(
                                            invoice.taxes,
                                            function (tax, i) {
                                              return _c(
                                                "p",
                                                { staticClass: "pt-1 pb-1" },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(tax.name) +
                                                      " - " +
                                                      _vm._s(tax.percent) +
                                                      "%\n                      "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "td",
                                          {
                                            staticClass:
                                              "pt-4 pr-4 pb-4 pl-4 align-center",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "expenses.expense_no_taxes"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pt-4 pr-4 pb-4 pl-4 align-center",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                invoice.total_tax,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "pt-4 pr-4 pb-4 pl-4 align-center",
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                invoice.total,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("expenses.asodocuments")) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("expenses.receipt"))),
                ]),
                _vm._v(" "),
                _vm.formData.receipt.length > 0
                  ? _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      _vm._l(_vm.formData.receipt, function (file, indexTr) {
                        return _c(
                          "div",
                          {
                            key: indexTr,
                            staticClass: "px-1 py-2 w-full md:w-1/3",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-transparent border-2 border-gray-400 border-dashed rounded-md div-zoom-effect",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-3 flex flex-wrap hover:text-success",
                                  },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "mb-2 w-full truncate text-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(file.file_name) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mb-2 w-full text-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.$t("expenses.size")) +
                                            ": " +
                                            _vm._s(_vm.sizeFile(file.size)) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex w-full justify-center",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-gray-600 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                            attrs: { "tag-name": "a" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.OpenSeeDocumentExpensesModal(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("eye-icon", {
                                              staticClass: "h-5 w-5",
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-gray-500 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                            attrs: {
                                              href: `/expenses/${_vm.$route.params.id}/receipt`,
                                              "tag-name": "a",
                                            },
                                          },
                                          [
                                            _c("DownloadIcon", {
                                              staticClass: "h-5 w-5",
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [
                      _c("p", { staticClass: "text-gray-700 text-sm mt-1" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("expenses.empty")) +
                            "\n              "
                        ),
                      ]),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mt-5 md:mt-7" }, [
                _c("p", { staticClass: "font-bold" }, [_vm._v("Docs")]),
                _vm._v(" "),
                _vm.formData.docs.length > 0
                  ? _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      _vm._l(_vm.formData.docs, function (file, indexTr) {
                        return _c(
                          "div",
                          {
                            key: indexTr,
                            staticClass: "px-1 py-2 w-full md:w-1/3",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-transparent border-2 border-gray-400 border-dashed rounded-md div-zoom-effect",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-3 flex flex-wrap hover:text-success",
                                  },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "mb-2 w-full truncate text-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(file.file_name) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mb-2 w-full text-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.$t("expenses.size")) +
                                            ": " +
                                            _vm._s(_vm.sizeFile(file.size)) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex w-full justify-center",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-gray-600 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                            attrs: { "tag-name": "a" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.OpenSeeDocumentExpensesModal(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("eye-icon", {
                                              staticClass: "h-5 w-5",
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-gray-600 hover:text-success mx-1 rounded-full border border-gray-400 hover:border-success cursor-pointer p-2",
                                            attrs: { "tag-name": "a" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFile(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("DownloadIcon", {
                                              staticClass: "h-5 w-5",
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [
                      _c("p", { staticClass: "text-gray-700 text-sm mt-1" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("expenses.empty")) +
                            "\n              "
                        ),
                      ]),
                    ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }