<template>
  <base-page class="payments">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <sw-page-header :title="$t('contacts.title')"> </sw-page-header>

      <div class="flex flex-wrap items-center justify-end">
        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/view`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
        >
          {{ $t('contacts.clientgoback') }}
        </sw-button>

        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/add-contact`"
          variant="primary"
          size="lg"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          v-if="permissionModule.create"
        >
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('contacts.create_contact') }}
        </sw-button>
      </div>
    </div>

    <sw-empty-table-placeholder
      v-if="showEmptyScreen"
      :title="$t('contacts.no_contacts')"
      :description="$t('contacts.list_of_contacts')"
    >
      <capsule-icon class="mt-5 mb-4" />
      <sw-button
        slot="actions"
        tag-name="router-link"
        :to="`/admin/customers/${$route.params.id}/add-contact`"
        size="lg"
        variant="primary-outline"
      >
        <plus-icon class="w-6 h-6 mr-1 -ml-2" />
        {{ $t('contacts.new_contact') }}
      </sw-button>
    </sw-empty-table-placeholder>

    <div v-show="!showEmptyScreen" class="relative table-container">
      <div
        class="relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid"
      >
        <p class="text-sm"></p>
      </div>

      <sw-table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('contacts.name')"
          show="name"
        >
          <template slot-scope="row">
            <span>{{ $t('contacts.name') }}</span>
            <router-link
              :to="{ path: `edit-contact/${row.id}` }"
              class="font-medium text-primary-500"
            >
              {{ row.name }}
            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('contacts.last_name')"
          show="last_name"
        />

        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('contacts.phone')"
          show="phone"
        />

        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('contacts.position')"
          show="position"
        />
        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.action') }}</span>
            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item
                tag-name="router-link"
                :to="`edit-contact/${row.id}`"
                v-if="permissionModule.update"
              >
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                @click="confirmDeleteContact(row.id)"
                v-if="permissionModule.delete"
              >
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CapsuleIcon from '@/components/icon/CapsuleIcon'
import {
  PlusIcon,
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
  BookOpenIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    CapsuleIcon,
    PlusIcon,
    FilterIcon,
    XIcon,
    ChevronDownIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
    BookOpenIcon,
  },

  data() {
    return {
      showFilters: false,
      sortedBy: 'created_at',
      isRequestOngoing: true,

      filters: {
        customer: '',
        payment_mode: '',
        payment_number: '',
      },

      permissionModule: {
        create: false,
        read: false,
        delete: false,
        update: false,
      },
    }
  },

  computed: {
    showEmptyScreen() {
      return !this.totalCustomerContacts && !this.isRequestOngoing
    },

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },

    ...mapGetters('customer', ['customers']),

    ...mapGetters('customerContacts', [
      'totalCustomerContacts'
    ]),
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  created() {
    this.permissionsUserModule()
  },
  methods: {
    ...mapActions('customerContacts', [
      'fetchCustomerContacts',
      'deleteCustomerContact',
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['getUserModules']),

    /**
     * Fetches customer contacts data with pagination, sorting, and filtering.
     * Updates the request status and returns the fetched data along with pagination details.
     * @param {Object} params - Parameters for fetching data.
     * @param {number} params.page - Current page number.
     * @param {Object} params.filter - Filter criteria.
     * @param {Object} params.sort - Sorting criteria.
     * @param {string} params.sort.fieldName - Field name to sort by.
     * @param {string} params.sort.order - Order of sorting ('asc' or 'desc').
     * @returns {Object} - Fetched data and pagination details.
     */
    async fetchData({ page, filter, sort }) {
      // Indica que la solicitud está en curso
      this.isRequestOngoing = true

      try {
        // Realiza la solicitud para obtener los contactos del cliente
        let response = await this.fetchCustomerContacts({
          customer_id: this.$route.params.id,
          orderByField: sort.fieldName || 'created_at',
          orderBy: sort.order || 'desc',
          page,
          limit: 10,
        })

        // Indica que la solicitud ha finalizado
        this.isRequestOngoing = false

        // Retorna los datos obtenidos y los detalles de paginación
        return {
          data: response.data.contacts.data,
          pagination: {
            totalPages: response.data.contacts.last_page,
            currentPage: page,
            count: response.data.contacts.total,
            orderBy: sort.order || 'desc',
          },
        }
      } catch (error) {
        // Maneja cualquier error durante la solicitud
        this.isRequestOngoing = false
        window.toastr['error']('Unspecified error, contact administrator')
        throw error
      }
    },

    refreshTable() {
      this.$refs.table.refresh()
    },

    /**
     * Muestra un cuadro de diálogo de confirmación para eliminar un contacto.
     * Si el usuario confirma, intenta eliminar el contacto y muestra un mensaje de éxito o error.
     * En caso de error no especificado, muestra un mensaje genérico.
     * @param {number} id - ID del contacto a eliminar.
     */
    async confirmDeleteContact(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('contacts.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            let res = await this.deleteCustomerContact({ id: id })

            // Verifica si la respuesta es exitosa
            if (res && res.data && res.data.success) {
              window.toastr['success'](this.$tc('contacts.deleted_message', 1))
              this.$refs.table.refresh()
              return true
            }

            // Maneja errores específicos
            if (res && res.data && res.data.message) {
              window.toastr['error'](res.data.message)
            } else {
              // Maneja errores no especificados
              window.toastr['error']('Unspecified error, contact administrator')
            }
          } catch (error) {
            // Maneja cualquier error durante la solicitud
            window.toastr['error']('Unspecified error, contact administrator')
            console.error('Error al eliminar el contacto:', error)
          }
        }
      })
    },

    /**
     * Verifica los permisos del usuario para el módulo 'cust_contacts'.
     * Redirige al usuario al dashboard si no tiene acceso.
     * Establece los permisos de creación, lectura, actualización y eliminación según corresponda.
     */
    async permissionsUserModule() {
      const data = {
        module: 'cust_contacts',
      }

      try {
        const permissions = await this.getUserModules(data)

        // Verifica si el usuario no es super administrador
        if (!permissions.super_admin) {
          // Si el módulo no existe o no tiene acceso, redirige al dashboard
          if (
            !permissions.exist ||
            !permissions.permissions[0] ||
            permissions.permissions[0].access === 0
          ) {
            this.$router.push('/admin/dashboard')
            return
          }
        }

        // Establece los permisos según el rol del usuario
        if (permissions.super_admin) {
          this.setAllPermissions(true)
        } else if (permissions.exist) {
          this.setModulePermissions(permissions.permissions[0])
        }
      } catch (error) {
        console.error('Error al obtener los permisos del módulo:', error)
      }
    },

    /**
     * Establece todos los permisos (crear, leer, actualizar, eliminar) a un valor dado.
     * @param {boolean} value - Valor para establecer todos los permisos.
     */
    setAllPermissions(value) {
      this.permissionModule.create = value
      this.permissionModule.update = value
      this.permissionModule.delete = value
      this.permissionModule.read = value
    },

    /**
     * Establece los permisos del módulo según los datos proporcionados.
     * @param {Object} modulePermissions - Objeto que contiene los permisos del módulo.
     */
    setModulePermissions(modulePermissions) {
      this.permissionModule.create = modulePermissions.create === 1
      this.permissionModule.update = modulePermissions.update === 1
      this.permissionModule.delete = modulePermissions.delete === 1
      this.permissionModule.read = modulePermissions.read === 1
    },
  },
}
</script>