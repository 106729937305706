var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { attrs: { action: "" } },
    [
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            data: _vm.fetchData,
            "show-filter": true,
            "table-class": "table",
          },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("items.name"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("items.name")))]),
                    _vm._v("\n        " + _vm._s(row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("corePbx.extensions.prepaidpostpaid"),
              show: "status_payment",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("corePbx.extensions.prepaidpostpaid"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            row.status_payment
                              ? row.status_payment
                              : "Not selected"
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("items.action")) + " "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.ConfirmExtension(row.id)
                              },
                            },
                          },
                          [
                            _c("save-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.save")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3",
              attrs: { variant: "primary-outline", type: "button" },
              on: { click: _vm.closeExtensionModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }