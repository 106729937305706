var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    !_vm.paymentSuccess && !_vm.invoiceIsCompleted && !_vm.invoiceIsCanceled
      ? _c("div", [
          _c(
            "div",
            {
              staticClass:
                "grid lg:grid-cols-12 sm:grid-cols-1 gap-8 mt-1 mb-2 mx-8",
            },
            [
              _c(
                "div",
                { staticClass: "lg:col-span-8 md:col-span-8 sm:col-span-12" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap justify-center w-full" },
                    [
                      _c("img", {
                        staticClass:
                          "block w-48 h-auto max-w-full mb-5 text-primary-400",
                        attrs: { src: _vm.urlLogo },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-card", { staticClass: "bg-white" }, [
                    _c("h1", { staticClass: "font-semibold mb-4 text-lg" }, [
                      _vm._v("Payment Method"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex gap-4 mb-4" },
                      [
                        _c(
                          "custom-sw-button",
                          {
                            staticClass: "w-full",
                            attrs: {
                              variant:
                                _vm.paymentType === "oneTime"
                                  ? "primary"
                                  : "primary-outline",
                              size: "lg",
                              selected: _vm.paymentType === "oneTime",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changePaymentType("oneTime")
                              },
                            },
                          },
                          [
                            _c("credit-card-icon", { staticClass: "mr-2" }),
                            _vm._v(" One-time Payment\n          "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "custom-sw-button",
                          {
                            staticClass: "w-full",
                            attrs: {
                              variant:
                                _vm.paymentType === "saved"
                                  ? "primary"
                                  : "primary-outline",
                              size: "lg",
                              selected: _vm.paymentType === "saved",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changePaymentType("saved")
                              },
                            },
                          },
                          [
                            _c("save-icon", { staticClass: "mr-2" }),
                            _vm._v(" Use Saved Payment Method\n          "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.paymentType === "oneTime"
                      ? _c(
                          "div",
                          { staticClass: "col-span-12 mt-4" },
                          [
                            _c("PaymentForm", {
                              attrs: {
                                paymentType: _vm.paymentType,
                                accounts: _vm.accounts,
                                isNewMethod: false,
                              },
                              on: {
                                paymentInformation:
                                  _vm.handlePaymentInformation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.accounts != null &&
                    this.accounts.length > 0 &&
                    _vm.paymentType === "saved"
                      ? _c(
                          "div",
                          _vm._l(_vm.accounts, function (account, index) {
                            return _c(
                              "div",
                              {
                                key: account.id,
                                class: [
                                  "flex items-center p-4 mb-4 rounded border cursor-pointer",
                                  {
                                    "saved-method":
                                      _vm.selectedAccountId === account.id,
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.selectAccount(account)
                                  },
                                },
                              },
                              [
                                account.payment_account_type === "CC"
                                  ? _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("input", {
                                          staticClass: "mr-2",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked:
                                              _vm.selectedAccountId ===
                                              account.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          _vm.getCreditCardComponent(
                                            account.credit_card
                                          ),
                                          {
                                            tag: "component",
                                            staticClass: "ml-2 mr-2",
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-col" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "font-semibold" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      account.credit_card
                                                    ) +
                                                    " ending in\n                  " +
                                                    _vm._s(
                                                      _vm.getLastFourDigits(
                                                        account.card_number
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-600" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getSavedAddress(account)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                account.payment_account_type === "ACH"
                                  ? _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("input", {
                                          staticClass: "mr-2",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked:
                                              _vm.selectedAccountId ===
                                              account.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              stroke: "currentColor",
                                              width: "50px",
                                              height: "50px",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                d: "M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-col" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "font-semibold" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(account.ACH_type) +
                                                    " ending in\n                  " +
                                                    _vm._s(
                                                      _vm.getLastFourDigits(
                                                        account.account_number
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-600" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getSavedAddress(account)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "md:col-span-4 lg:col-span-4 sm:col-span-12" },
                [
                  _c("Invoices", {
                    attrs: {
                      fees: _vm.fees,
                      isidentificationverification:
                        _vm.isidentificationverification,
                      disablePay: _vm.disablePay,
                      formData: _vm.formData,
                      defaultPaymentdisplay: _vm.defaultPaymentdisplay,
                      defaultPaymentGateway: _vm.defaultPaymentGateway,
                      paymentType: _vm.paymentType,
                      cardSaveText: _vm.cardSaveText,
                      Last4digittext: _vm.Last4digittext,
                      creditCardId: _vm.creditCardId,
                      achAccountId: _vm.achAccountId,
                    },
                    on: {
                      "payment-success": _vm.updatePaymentStatus,
                      payInvoice: _vm.payInvoice,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.paymentSuccess
      ? _c(
          "div",
          { staticClass: "flex flex-wrap justify-center w-full" },
          [
            _c("sw-card", { staticClass: "md:w-2/3" }, [
              _c(
                "div",
                { staticClass: "flex flex-col items-center justify-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-24 h-24 mb-4 bg-green-100 rounded-full",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-12 h-12 text-green-500",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M5 13l4 4L19 7",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass:
                        "mb-4 text-2xl font-bold leading-normal text-gray-800",
                    },
                    [
                      _vm._v(
                        "\n          Payment created successfully\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-4 text-sm font-normal leading-normal text-gray-600",
                    },
                    [
                      _vm._v(
                        "\n          Please check your email to view the payment receipt or log in to see the recorded payment\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceIsCompleted
      ? _c(
          "div",
          { staticClass: "flex flex-wrap justify-center w-full" },
          [
            _c("sw-card", { staticClass: "md:w-2/3" }, [
              _c(
                "div",
                { staticClass: "flex flex-col items-center justify-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-24 h-24 mb-4 bg-green-100 rounded-full",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-12 h-12 text-green-500",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M5 13l4 4L19 7",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass:
                        "mb-4 text-2xl font-bold leading-normal text-gray-800",
                    },
                    [
                      _vm._v(
                        "\n          The invoice with the number " +
                          _vm._s(_vm.invoiceNumber) +
                          " has already been paid.\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-4 text-sm font-normal leading-normal text-gray-600",
                    },
                    [_vm._v("\n          Greetings\n        ")]
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.invoiceIsCanceled
      ? _c(
          "div",
          { staticClass: "flex flex-wrap justify-center w-full" },
          [
            _c("sw-card", { staticClass: "md:w-2/3" }, [
              _c(
                "div",
                { staticClass: "flex flex-col items-center justify-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-24 h-24 mb-4 bg-red-100 rounded-full",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-12 h-12 text-green-500",
                          attrs: {
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M5 13l4 4L19 7",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass:
                        "mb-4 text-2xl font-bold leading-normal text-gray-800",
                    },
                    [
                      _vm._v(
                        "\n          The invoice has not been found, please contact the administration\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-4 text-sm font-normal leading-normal text-gray-600",
                    },
                    [_vm._v("\n          Greetings\n        ")]
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }