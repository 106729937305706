var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative setting-main-container backup" },
    [
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", [
                _c("h6", { staticClass: "sw-section-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("settings.backup.title", 1)) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "mt-2 text-sm leading-snug text-gray-500",
                    staticStyle: { "max-width": "680px" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.backup.description")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
                [
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline", size: "lg" },
                          on: { click: _vm.onCreateNewBackup },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("settings.backup.new_backup")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid mb-8 md:grid-cols-3" },
            [
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$t("settings.disk.select_disk") } },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.getDisks,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("settings.disk.select_disk"),
                      "allow-empty": false,
                      "track-by": "id",
                      label: "name",
                      "custom-label": _vm.getCustomLabel,
                    },
                    on: { select: _vm.refreshTable },
                    model: {
                      value: _vm.filters.selected_disk,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "selected_disk", $$v)
                      },
                      expression: "filters.selected_disk",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                variant: "gray",
                "show-filter": false,
                data: _vm.fetchBackupsData,
              },
            },
            [
              _c("sw-table-column", {
                attrs: { label: _vm.$t("settings.backup.path"), show: "path" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.backup.path"))),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mt-6" }, [
                          _vm._v(_vm._s(row.path)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  label: _vm.$t("settings.backup.created_at"),
                  show: "created_at",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { label: _vm.$t("settings.backup.size"), show: "size" },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  data: _vm.fetchBackupsData,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.backup.action"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDownloadBckup(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("cloud-download-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.download")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRemoveBackup(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }