var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal-overlay",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "modal-container" }, [
        _c("button", { staticClass: "modal-close", on: { click: _vm.close } }, [
          _vm._v("×"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }