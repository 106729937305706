var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
    },
    [
      _c("div", { staticClass: "heading-section" }, [
        _c(
          "p",
          {
            staticClass:
              "text-2xl not-italic font-semibold leading-7 text-black",
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("customers.pbxservices_details")) +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
        },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("packages.name")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.parameters
                        ? this.parameters.package.pbx_package_name
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("packages.status")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text-sm font-bold leading-5 text-black non-italic",
            }),
            this.parameters
              ? _c("div", [
                  this.parameters.status === "A"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("COMPLETED")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("COMPLETED")
                                    .color,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.active")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.parameters.status === "P"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("VIEWED")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("VIEWED")
                                    .color,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.pending")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.parameters.status == "S"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("SENT")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("SENT").color,
                              },
                            },
                            [
                              _vm._v(
                                "\n           " +
                                  _vm._s(_vm.$t("general.suspended")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.parameters.status == "C"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("OVERDUE")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("OVERDUE")
                                    .color,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.cancelled")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p"),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.term")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(this.parameters ? this.parameters.term : "") +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
        },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("invoices.discount")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.parameters.allow_discount
                        ? _vm.$t("general.yes")
                        : _vm.$t("general.not")
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.date_act")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("formatDate")(this.parameters.date_begin)) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.isEdit
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.date_renow")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          this.parameters.renewal_date.length > 0
                            ? this.parameters.renewal_date
                            : "None"
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.server")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.parameters.package.server
                        ? this.parameters.package.server.server_label
                        : "None"
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.tenant")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.parameters.tenant.name.length > 0
                        ? this.parameters.tenant.name
                        : "None"
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.type_service")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text-sm font-bold leading-5 text-black non-italic",
            }),
            this.parameters
              ? _c("div", [
                  this.parameters.package.status_payment.length > 0
                    ? _c("div", [
                        this.parameters.package.status_payment == "postpaid"
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("packages.item.postpaid")) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        this.parameters.package.status_payment == "prepaid"
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("packages.item.prepaid")) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      this.parameters.package.extensions
        ? _c(
            "div",
            {
              staticClass:
                "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
            },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("p", { staticClass: "text-lg" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.pbxservices_extensions")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.messageExtensionServer.show
                    ? _c(
                        "sw-badge",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.messageExtensionServer.status
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.messageExtensionServer.status
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.messageExtensionServer.message) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.listed")) +
                    ":\n        " +
                    _vm._s(_vm.dataExtensionsIncluded.length) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-4 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-transition",
                    [
                      _vm.selectFieldExtIncluded.length > 0
                        ? _c(
                            "sw-dropdown",
                            { staticStyle: { "z-index": "99" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400 left: 4em;",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n              "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.excludeExtensions()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.exclude")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      variant: "primary",
                      label: _vm.$t("general.select_all"),
                    },
                    model: {
                      value: _vm.SelectAllFieldExtIncluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldExtIncluded = $$v
                      },
                      expression: "SelectAllFieldExtIncluded",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary" },
                    model: {
                      value: _vm.SelectAllFieldExtIncluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldExtIncluded = $$v
                      },
                      expression: "SelectAllFieldExtIncluded",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "tablesExt",
                  attrs: {
                    data: _vm.dataExtensionsIncludedMetho,
                    "show-filter": false,
                    "table-class": "tablesDidsExt",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "custom-control custom-checkbox flex",
                              },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.ext,
                                    value: row.ext,
                                    variant: "primary",
                                  },
                                  model: {
                                    value: _vm.selectFieldExtIncluded,
                                    callback: function ($$v) {
                                      _vm.selectFieldExtIncluded = $$v
                                    },
                                    expression: "selectFieldExtIncluded",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "ml-1 text-sm text-gray-600 bg-transparent border border-danger-200 rounded p-1 leading-none font-medium hover:text-gray-900 focus:outline-none focus:border-danger-300 focus:shadow-outline-danger active:bg-gray-50 transition duration-150",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.excludeExtensions(row.ext)
                                      },
                                    },
                                  },
                                  [
                                    _c("minus-icon", {
                                      staticClass: "text-danger h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      918444993
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.extension"),
                      show: "ext",
                      sortBy: "sortBy",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.extension"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.ext ? row.ext : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              row.date_prorate !== null
                                ? _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "whitespace-nowrap" },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "general.warning_service9"
                                                )
                                              ) +
                                              ": "
                                          ),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.date_prorate
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "whitespace-nowrap" },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "general.warning_service10"
                                                )
                                              ) +
                                              ": "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                row.prorate,
                                                _vm.currency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              row.only_api
                                ? _c("small", [
                                    _vm._v(
                                      "   " +
                                        _vm._s(
                                          _vm.$t("general.warning_service11")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : row.db_available
                                ? _c("small", [
                                    _vm._v(
                                      "   " +
                                        _vm._s(
                                          _vm.$t("general.warning_service12")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1965086818
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.email"),
                      show: "email",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.email"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.email ? row.email : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1530401641
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.location"),
                      show: "location",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.location"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.location
                                          ? row.location
                                          : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1272541592
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.ua_fullname"),
                      show: "ua_fullname",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("corePbx.extensions.ua_fullname")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.ua_fullname
                                          ? row.ua_fullname
                                          : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2195559002
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.macaddress"),
                      show: "macaddress",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("corePbx.extensions.macaddress")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.macaddress
                                          ? row.macaddress
                                          : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1711872444
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.status ? row.status : "Not selected"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3319248049
                    ),
                  }),
                  _vm._v(" "),
                  _vm.isEdit
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.extensions.prorate"),
                          show: "id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("corePbx.extensions.prorate")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-switch", {
                                    staticStyle: { top: "2px" },
                                    attrs: {
                                      disabled:
                                        row.invoice_prorate == 1 ||
                                        _vm.formData.term == "daily",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.switchExtProrate(row)
                                      },
                                    },
                                    model: {
                                      value: row.statusProrate,
                                      callback: function ($$v) {
                                        _vm.$set(row, "statusProrate", $$v)
                                      },
                                      expression: "row.statusProrate",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2821408828
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isEdit && _vm.formData.term !== "daily"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.extensions.prorate_date"),
                          show: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return row.statusProrate
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.extensions.prorate_date"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("sw-date-picker", {
                                        ref: "ProrateDatepicker",
                                        attrs: {
                                          config: {
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            enableTime: false,
                                            time_24hr: false,
                                          },
                                          disabled: row.invoice_prorate == 1,
                                          invalid: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.switchExtProrate(row)
                                          },
                                        },
                                        model: {
                                          value: row.date_prorate,
                                          callback: function ($$v) {
                                            _vm.$set(row, "date_prorate", $$v)
                                          },
                                          expression: "row.date_prorate",
                                        },
                                      }),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c("p", { staticClass: "text-lg" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("customers.pbxservices_extensions_excluded")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.listed")) +
                    ":\n        " +
                    _vm._s(_vm.dataExtensionsExcluded.length) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-transition",
                    [
                      _vm.selectFieldExtExcluded.length > 0
                        ? _c(
                            "sw-dropdown",
                            { staticStyle: { "z-index": "99" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400 left: 4em;",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n              "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.includeExtensions()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.include")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      variant: "primary",
                      size: "sm",
                      label: _vm.$t("general.select_all"),
                    },
                    model: {
                      value: _vm.SelectAllFieldExtExcluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldExtExcluded = $$v
                      },
                      expression: "SelectAllFieldExtExcluded",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary" },
                    model: {
                      value: _vm.SelectAllFieldExtExcluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldExtExcluded = $$v
                      },
                      expression: "SelectAllFieldExtExcluded",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.dataExtensionsExcluded,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "custom-control custom-checkbox flex",
                              },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.ext,
                                    value: row.ext,
                                    variant: "primary",
                                  },
                                  model: {
                                    value: _vm.selectFieldExtExcluded,
                                    callback: function ($$v) {
                                      _vm.selectFieldExtExcluded = $$v
                                    },
                                    expression: "selectFieldExtExcluded",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "ml-1 text-sm text-gray-600 bg-transparent border border-success-200 rounded p-1 leading-none font-medium hover:text-gray-900 focus:outline-none focus:border-success-300 focus:shadow-outline-success active:bg-gray-50 transition duration-150",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.includeExtensions(row.ext)
                                      },
                                    },
                                  },
                                  [
                                    _c("plus-icon", {
                                      staticClass: "text-success h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3664972183
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.extension"),
                      show: "ext",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.extension"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.ext
                                        ? row.ext
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1337666021
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.email"),
                      show: "email",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.email"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.email
                                        ? row.email
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2749948432
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.location"),
                      show: "location",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.location"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.location
                                        ? row.location
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4176305985
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.ua_fullname"),
                      show: "ua_fullname",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("corePbx.extensions.ua_fullname")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.ua_fullname
                                        ? row.ua_fullname
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3824144451
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.macaddress"),
                      show: "macaddress",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("corePbx.extensions.macaddress")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.macaddress
                                        ? row.macaddress
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3153449669
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.extensions.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.extensions.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.status
                                        ? row.status
                                        : _vm.$t("general.warning_service13")
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2975933960
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.parameters.package.did
        ? _c(
            "div",
            {
              staticClass:
                "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
            },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("p", { staticClass: "text-lg" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.pbxservices_did")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.messageDidServer.show
                    ? _c(
                        "sw-badge",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            "bg-color": _vm.$utils.getBadgeStatusColor(
                              _vm.messageDidServer.status
                            ).bgColor,
                            color: _vm.$utils.getBadgeStatusColor(
                              _vm.messageDidServer.status
                            ).color,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.messageDidServer.message) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.listed")) +
                    ":\n        " +
                    _vm._s(_vm.dataDidIncluded.length) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-transition",
                    [
                      _vm.selectFieldDidIncluded.length > 0 &&
                      _vm.dataDidIncluded.length > 0
                        ? _c(
                            "sw-dropdown",
                            { staticStyle: { "z-index": "99" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n              "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.excludeDid()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.exclude")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary" },
                    on: { change: _vm.selectAllDID },
                    model: {
                      value: _vm.SelectAllFieldDidIncluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldDidIncluded = $$v
                      },
                      expression: "SelectAllFieldDidIncluded",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      label: _vm.$t("general.select_all"),
                      variant: "primary",
                    },
                    on: { change: _vm.selectAllDID },
                    model: {
                      value: _vm.SelectAllFieldDidIncluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldDidIncluded = $$v
                      },
                      expression: "SelectAllFieldDidIncluded",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "tablesDids",
                  attrs: {
                    data: _vm.dataDidIncludedMetho,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "custom-control custom-checkbox flex",
                              },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.number,
                                    value: row.number,
                                    variant: "primary",
                                  },
                                  model: {
                                    value: _vm.selectFieldDidIncluded,
                                    callback: function ($$v) {
                                      _vm.selectFieldDidIncluded = $$v
                                    },
                                    expression: "selectFieldDidIncluded",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "ml-1 text-sm text-gray-600 bg-transparent border border-danger-200 rounded p-1 leading-none font-medium hover:text-gray-900 focus:outline-none focus:border-danger-300 focus:shadow-outline-danger active:bg-gray-50 transition duration-150",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.excludeDid(row.number)
                                      },
                                    },
                                  },
                                  [
                                    _c("minus-icon", {
                                      staticClass: "text-danger h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1035648488
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.did_channel"),
                      show: "number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.did.did_channel"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.number
                                          ? row.number
                                          : _vm.$t("general.warning_service13")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              row.date_prorate !== null
                                ? _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "whitespace-nowrap" },
                                      [
                                        _c("b", [_vm._v("Prorate Date: ")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.date_prorate
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "whitespace-nowrap" },
                                      [
                                        _c("b", [_vm._v("Prorate Price: ")]),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                row.prorate,
                                                _vm.currency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              row.only_api
                                ? _c("small", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("general.warning_service11")
                                        )
                                    ),
                                  ])
                                : row.db_available
                                ? _c("small", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("general.warning_service12")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1639001971
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.destination"),
                      show: "ext",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.did.destination"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.ext == null
                                          ? _vm.$t("general.warning_service13")
                                          : row.ext
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3492595038
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.type"),
                      show: "type",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("corePbx.did.type"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.type == null
                                          ? _vm.$t("general.warning_service13")
                                          : row.type
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      252769594
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("corePbx.did.status"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: { only_api: row.only_api } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.status == null
                                          ? _vm.$t("general.warning_service13")
                                          : row.status
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1278334550
                    ),
                  }),
                  _vm._v(" "),
                  _vm.isEdit && _vm.formData.term !== "daily"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.extensions.prorate"),
                          show: "id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("corePbx.extensions.prorate")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-switch", {
                                    staticStyle: { top: "2px" },
                                    attrs: {
                                      disabled:
                                        row.invoice_prorate == 1 ||
                                        _vm.formData.term == "daily",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.prorateDidSwitchChange(row)
                                      },
                                    },
                                    model: {
                                      value: row.statusProrate,
                                      callback: function ($$v) {
                                        _vm.$set(row, "statusProrate", $$v)
                                      },
                                      expression: "row.statusProrate",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2196130970
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isEdit && _vm.formData.term !== "daily"
                    ? _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.extensions.prorate_date"),
                          show: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return row.statusProrate
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.extensions.prorate_date"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("sw-date-picker", {
                                        ref: "ProrateDatepicker",
                                        attrs: {
                                          config: {
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            enableTime: false,
                                            time_24hr: false,
                                          },
                                          disabled: row.invoice_prorate == 1,
                                          invalid: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.prorateDidSwitchChange(
                                              row
                                            )
                                          },
                                        },
                                        model: {
                                          value: row.date_prorate,
                                          callback: function ($$v) {
                                            _vm.$set(row, "date_prorate", $$v)
                                          },
                                          expression: "row.date_prorate",
                                        },
                                      }),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(_vm.$t("customers.pbxservices_did_excluded"))),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.listed")) +
                    ":\n        " +
                    _vm._s(_vm.dataDidExcluded.length) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c(
                    "sw-transition",
                    [
                      _vm.selectFieldDidExcluded.length > 0 &&
                      _vm.dataDidExcluded.length > 0
                        ? _c(
                            "sw-dropdown",
                            { staticStyle: { "z-index": "99" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n              "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.includeDid()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.include")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary" },
                    model: {
                      value: _vm.SelectAllFieldDidExcluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldDidExcluded = $$v
                      },
                      expression: "SelectAllFieldDidExcluded",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      label: _vm.$t("general.select_all"),
                      variant: "primary",
                    },
                    model: {
                      value: _vm.SelectAllFieldDidExcluded,
                      callback: function ($$v) {
                        _vm.SelectAllFieldDidExcluded = $$v
                      },
                      expression: "SelectAllFieldDidExcluded",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.dataDidExcluded,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "custom-control custom-checkbox flex",
                              },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.number,
                                    value: row.number,
                                    variant: "primary",
                                  },
                                  model: {
                                    value: _vm.selectFieldDidExcluded,
                                    callback: function ($$v) {
                                      _vm.selectFieldDidExcluded = $$v
                                    },
                                    expression: "selectFieldDidExcluded",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "ml-1 text-sm text-gray-600 bg-transparent border border-success-200 rounded p-1 leading-none font-medium hover:text-gray-900 focus:outline-none focus:border-success-300 focus:shadow-outline-success active:bg-gray-50 transition duration-150",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.includeDid(row.number)
                                      },
                                    },
                                  },
                                  [
                                    _c("plus-icon", {
                                      staticClass: "text-success h-5 w-5",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      771391262
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.did_channel"),
                      show: "number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.did.did_channel"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.number ? row.number : "Not selected"
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      356889237
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.destination"),
                      show: "ext",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.ext == null
                                      ? _vm.$t("general.warning_service13")
                                      : row.ext
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3048989824
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.type"),
                      show: "type",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.type == null
                                      ? _vm.$t("general.warning_service13")
                                      : row.type
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1267724128
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.did.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.status == null
                                      ? _vm.$t("general.warning_service13")
                                      : row.status
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1137225568
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.parameters.custom_app_rate_id != null
        ? _c(
            "div",
            {
              staticClass:
                "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("general.warning_service15")))]),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.pbx_services_app_rate,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: { sortable: true, label: "APPS", show: "app_name" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [_vm._v(_vm._s("APPS"))]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.app_name) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      931687263
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: { sortable: true, label: "TOTAL", show: "total" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [_vm._v(_vm._s("TOTAL"))]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.total) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2961521492
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: { sortable: true, label: "IN USE", show: "in_use" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("general.warning_service16"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.in_use) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2272142345
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: "AVAILABLE",
                      show: "available",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("general.warning_service17"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-success": row.available > 0,
                                    "text-danger": row.available <= 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.available) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1861622111
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: { sortable: true, label: "PRICE", show: "price" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("general.warning_service18"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.price * 100,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      248408279
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: "QUANTITY",
                      show: "quantity",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("general.warning_service19"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("sw-input", {
                                staticClass: "rounded-tr-sm rounded-br-sm",
                                staticStyle: { width: "100px" },
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  max: row.total,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.updateQuantityCustomApp(row)
                                  },
                                },
                                model: {
                                  value: row.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(row, "quantity", $$v)
                                  },
                                  expression: "row.quantity",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1896190567
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: { sortable: true, label: "Total", show: "costo" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [_vm._v(_vm._s("Total"))]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.price * 100 * row.quantity,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      168935458
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
        [
          _c("sw-divider", { staticClass: "col-span-12" }),
          _vm._v(" "),
          _c(
            "h6",
            { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("packages.packages_items")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: { label: _vm.$t("packages.item_groups") },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.item_groups,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      placeholder: _vm.$tc("packages.item_groups_select"),
                      label: "name",
                      "track-by": "id",
                    },
                    on: { select: _vm.itemGroupSelected },
                    model: {
                      value: _vm.item_group,
                      callback: function ($$v) {
                        _vm.item_group = $$v
                      },
                      expression: "item_group",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              undefined !== _vm.formData.item_groups &&
              _vm.formData.item_groups.length > 0
                ? _c("div", { staticClass: "col-span-12" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap justify-start" },
                      _vm._l(_vm.formData.item_groups, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-indigo-100 bg-indigo-700 border border-indigo-700",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "text-xs text-base leading-none max-w-full flex-initial py-2 pl-2",
                              domProps: { textContent: _vm._s(item.name) },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-auto flex-row-reverse",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-6 h-4 m-2 pr-1",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "100%",
                                        height: "100%",
                                        fill: "none",
                                        viewBox: "0 0 24 24",
                                        stroke: "currentColor",
                                        "stroke-width": "4",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItemGroup(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("line", {
                                        attrs: {
                                          x1: "18",
                                          y1: "6",
                                          x2: "6",
                                          y2: "18",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        attrs: {
                                          x1: "6",
                                          y1: "6",
                                          x2: "18",
                                          y2: "18",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "grid col-span-12" }, [
                _c(
                  "table",
                  { staticClass: "w-full text-center item-table" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "40%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _vm.discountPerItem === "YES"
                        ? _c("col", { staticStyle: { width: "15%" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "thead",
                      {
                        staticClass:
                          "bg-white border border-gray-200 border-solid",
                      },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pl-12" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$tc("items.item", 1)) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("estimates.item.quantity")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("estimates.item.price")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.discountPerItem === "YES"
                            ? _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("estimates.item.discount")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "pr-10 column-heading" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("estimates.item.total")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "item-body",
                        attrs: { tag: "tbody", handle: ".handle" },
                        model: {
                          value: _vm.formData.items,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "items", $$v)
                          },
                          expression: "formData.items",
                        },
                      },
                      _vm._l(_vm.formData.items, function (item, index) {
                        return _c("package-item", {
                          key: item.id,
                          attrs: {
                            index: index,
                            "item-data": item,
                            currency: _vm.currency,
                            isView: false,
                            isNoGeneralTaxes: _vm.isNoGeneralTaxes,
                            "package-items": _vm.formData.items,
                            "tax-per-item": _vm.taxPerItem,
                            "discount-per-item": _vm.discountPerItem,
                          },
                          on: {
                            remove: _vm.removeItem,
                            update: _vm.updateItem,
                            itemValidate: _vm.checkItemsData,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                    on: { click: _vm.addItem },
                  },
                  [
                    _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("estimates.add_item")) +
                        "\n          "
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid col-span-12 md:col-span-6 gap-y-1 gap-x-4 my-10" },
        [
          _c("sw-divider", { staticClass: "col-span-12 my-8" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-12" }, [
            _c("h3", { staticClass: "text-lg font-medium" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("corePbx.packages.cdr_taxes")) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "col-span-12",
              attrs: {
                "show-filter": false,
                data: _vm.packageCustomer.taxesCdr,
                "table-class": "table",
                variant: "gray",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("settings.tax_types.tax_name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.tax_types.tax_name"))),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mt-6" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("settings.tax_types.compound_tax"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("settings.tax_types.compound_tax"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.compound_tax ? "YES" : "NO"
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(
                                row.compound_tax ? "YES" : "NO"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  row.compound_tax
                                    ? "Yes"
                                    : "No".replace("_", " ")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("settings.tax_types.percent"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.tax_types.percent"))),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(row.percent) +
                            " %\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: true, filterable: true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("trash-icon", {
                          staticClass: "h-5 mr-3 text-gray-600",
                          on: {
                            click: function ($event) {
                              return _vm.removeTaxCdr(row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-12" }),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span-6",
              attrs: { label: _vm.$t("packages.taxes") },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.taxesOptions,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$tc("packages.add_tax"),
                  label: "name",
                  "track-by": "id",
                  tabindex: 9,
                },
                on: { select: _vm.taxCdrSeletedMetho },
                model: {
                  value: _vm.taxCdrSelect,
                  callback: function ($$v) {
                    _vm.taxCdrSelect = $$v
                  },
                  expression: "taxCdrSelect",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span-6",
              attrs: { label: _vm.$t("packages.member_tax_groups") },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.groupTaxesOptions,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$tc("packages.add_group_tax"),
                  label: "name",
                  "track-by": "id",
                  tabindex: 10,
                },
                on: { select: _vm.groupTaxCdrSeleted },
                model: {
                  value: _vm.groupTaxSelect,
                  callback: function ($$v) {
                    _vm.groupTaxSelect = $$v
                  },
                  expression: "groupTaxSelect",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
        },
        [
          _c("div", { staticClass: "w-full lg:w-1/2" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
            },
            [
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-between w-full mt-2",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-sm font-semibold leading-5 text-gray-500 uppercase",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("customers.generate_prorate_invoice"))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center m-0 text-lg text-black",
                          attrs: { role: "group" },
                        },
                        [
                          _c("sw-switch", {
                            staticStyle: { position: "relative", top: "-12px" },
                            model: {
                              value: _vm.formData.invoice_prorate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "invoice_prorate", $$v)
                              },
                              expression: "formData.invoice_prorate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex items-center justify-between w-full mt-2",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                    },
                    [_vm._v(_vm._s(_vm.$t("customers.price")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center m-0 text-lg text-black",
                      attrs: { role: "group" },
                    },
                    [
                      _c("sw-money", {
                        staticClass: "rounded-tr-sm rounded-br-sm",
                        attrs: { currency: _vm.defaultCurrencyForInput },
                        model: {
                          value: _vm.price,
                          callback: function ($$v) {
                            _vm.price = $$v
                          },
                          expression: "price",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex items-center justify-between w-full mt-2",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                    },
                    [_vm._v(_vm._s(_vm.$t("customers.cap_by_extension")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center m-0 text-lg text-black",
                      attrs: { role: "group" },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "rounded-tr-sm rounded-br-sm",
                        attrs: { type: "number" },
                        model: {
                          value: _vm.capByExtension,
                          callback: function ($$v) {
                            _vm.capByExtension = $$v
                          },
                          expression: "capByExtension",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              (_vm.discountPerItem === "NO" || _vm.discountPerItem === null) &&
              _vm.parameters.allow_discount
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-between w-full mt-2",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-sm font-semibold leading-5 text-gray-500 uppercase",
                        },
                        [_vm._v(_vm._s(_vm.$t("invoices.discount")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center m-0 text-lg text-black",
                          attrs: { role: "group" },
                        },
                        [
                          _c("sw-input", {
                            staticClass:
                              "border-r-0 rounded-tr-sm rounded-br-sm",
                            attrs: {
                              invalid:
                                _vm.$v.packageCustomer.discount_val.$error,
                              type: "number",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.packageCustomer.discount_val.$touch()
                              },
                            },
                            model: {
                              value: _vm.discountService,
                              callback: function ($$v) {
                                _vm.discountService = $$v
                              },
                              expression: "discountService",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            {
                              staticStyle: { "z-index": "99" },
                              attrs: { position: "bottom-end" },
                            },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticStyle: { height: "43px" },
                                  attrs: {
                                    slot: "activator",
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    size: "discount",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                    variant: "white",
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "flex" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.packageCustomer
                                              .discount_type === "fixed"
                                              ? _vm.currency.symbol
                                              : "%"
                                          ) +
                                          "\n                  "
                                      ),
                                      _c("chevron-down-icon", {
                                        staticClass: "h-5",
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectFixed } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.fixed")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectPercentage } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.percentage")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.packageCustomer.taxes, function (tax, index) {
                return _c(
                  "div",
                  {
                    key: tax.tax_type_id,
                    staticClass: "flex items-center justify-between w-full",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                      },
                      [
                        _vm._v(
                          _vm._s(tax.name) +
                            " - " +
                            _vm._s(tax.percent) +
                            "%\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                        staticStyle: { "font-size": "18px" },
                      },
                      [
                        _c("div", {
                          attrs: { hidden: "" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(tax.amount, _vm.currency)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("trash-icon", {
                          staticClass: "h-5 ml-2",
                          on: {
                            click: function ($event) {
                              return _vm.removeInvoiceTax(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "sw-popup",
                {
                  ref: "taxModal",
                  staticClass:
                    "my-3 text-sm font-semibold leading-5 text-primary-400",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "float-right pt-2 pb-5",
                      attrs: { slot: "activator" },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n            + " +
                          _vm._s(_vm.$t("invoices.add_tax")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("tax-select-popup", {
                    attrs: { taxes: _vm.taxesNormalList },
                    on: { select: _vm.onSelectTax },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.taxPerItem == "YES"
                ? _c(
                    "p",
                    { staticClass: "text-primary-400 w-full mt-5 pt-6" },
                    [
                      _vm._v(
                        "\n          *  " +
                          _vm._s(_vm.$t("general.warning_service14")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          staticClass: "flex justify-center w-full md:w-auto align-bottom",
          attrs: {
            disabled: _vm.isLoading,
            variant: "primary-outline",
            size: "lg",
          },
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [
          _c("arrow-left-icon", { staticClass: "h-5 mr-2 -ml-1" }),
          _vm._v("\n      " + _vm._s(_vm.$t("general.back")) + "\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          staticClass: "mt-4 pull-right",
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            variant: "primary",
          },
          on: { click: _vm.next },
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t("general.continue")) + "\n      "),
          _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }