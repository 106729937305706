var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 left-0 hidden h-full pt-16 pb-4 ml-56 bg-white xl:ml-64 w-88 xl:block",
    },
    [
      _c(
        "div",
        { staticClass: "p-8" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$tc("bandwidth.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.home"),
                      to: "/admin/dashboard",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("bandwidth.title"),
                      to: "#",
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.menuItems, function (menuItem, index) {
            return _c(
              "sw-list",
              { key: index, attrs: { title: _vm.$t(menuItem.title) } },
              [
                _c(
                  "sw-list-item",
                  {
                    staticClass: "py-3",
                    attrs: {
                      title: _vm.$t(menuItem.title),
                      to: menuItem.link,
                      active: _vm.hasActiveUrl(menuItem.link),
                      "tag-name": "router-link",
                    },
                  },
                  [
                    _c(menuItem.icon, {
                      tag: "component",
                      staticClass: "h-5",
                      attrs: { slot: "icon" },
                      slot: "icon",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                menuItem.subMenuItems != null
                  ? _c(
                      "span",
                      _vm._l(
                        menuItem.subMenuItems,
                        function (subMenuItem, idx) {
                          return _c(
                            "sw-list-item",
                            {
                              key: idx,
                              staticClass: "px-5",
                              attrs: {
                                title: _vm.$t(subMenuItem.title),
                                to: subMenuItem.link,
                                active: _vm.hasActiveUrl(subMenuItem.link),
                                "tag-name": "router-link",
                              },
                            },
                            [
                              _c(subMenuItem.icon, {
                                tag: "component",
                                staticClass: "h-5",
                                attrs: { slot: "icon" },
                                slot: "icon",
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-6 xl:hidden" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$tc("bandwidth.title", 1) } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.home"),
                      to: "/admin/dashboard",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("bandwidth.title"),
                      to: "#",
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full mb-6 select-wrapper xl:hidden" },
        [
          _c("sw-select", {
            attrs: {
              options: _vm.menuItems,
              searchable: true,
              "show-labels": false,
              "allow-empty": false,
              "custom-label": _vm.getCustomLabel,
            },
            on: { input: _vm.navigateToPBX },
            model: {
              value: _vm.currentPbx,
              callback: function ($$v) {
                _vm.currentPbx = $$v
              },
              expression: "currentPbx",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hidden mt-1 xl:hidden" },
        _vm._l(_vm.menuItems, function (menuItem, index) {
          return _c(
            "sw-list",
            { key: index, attrs: { title: _vm.$t(menuItem.title) } },
            [
              _c(
                "sw-list-item",
                {
                  staticClass: "py-3",
                  attrs: {
                    title: _vm.$t(menuItem.title),
                    to: menuItem.link,
                    active: _vm.hasActiveUrl(menuItem.link),
                    "tag-name": "router-link",
                  },
                },
                [
                  _c(menuItem.icon, {
                    tag: "component",
                    staticClass: "h-5",
                    attrs: { slot: "icon" },
                    slot: "icon",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              menuItem.subMenuItems != null
                ? _c(
                    "span",
                    _vm._l(menuItem.subMenuItems, function (subMenuItem, idx) {
                      return _c(
                        "sw-list-item",
                        {
                          key: idx,
                          staticClass: "px-5",
                          attrs: {
                            title: _vm.$t(subMenuItem.title),
                            to: subMenuItem.link,
                            active: _vm.hasActiveUrl(subMenuItem.link),
                            "tag-name": "router-link",
                          },
                        },
                        [
                          _c(subMenuItem.icon, {
                            tag: "component",
                            staticClass: "h-5",
                            attrs: { slot: "icon" },
                            slot: "icon",
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }