import { render, staticRenderFns } from "./headerPbxServices.vue?vue&type=template&id=6080d56a&"
import script from "./headerPbxServices.vue?vue&type=script&lang=js&"
export * from "./headerPbxServices.vue?vue&type=script&lang=js&"
import style0 from "./headerPbxServices.vue?vue&type=style&index=0&id=6080d56a&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/cbdev/crater/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6080d56a')) {
      api.createRecord('6080d56a', component.options)
    } else {
      api.reload('6080d56a', component.options)
    }
    module.hot.accept("./headerPbxServices.vue?vue&type=template&id=6080d56a&", function () {
      api.rerender('6080d56a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/customers/SearchCallHistoryPbxServices/headerPbxServices.vue"
export default component.exports