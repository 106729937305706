var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-card",
          {},
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-4",
                attrs: {
                  error: _vm.amountError,
                  label: _vm.$t("core_pos.amount"),
                },
              },
              [
                _c("sw-money", {
                  staticClass:
                    "focus:border focus:border-solid focus:border-primary",
                  attrs: { currency: _vm.defaultCurrencyForInput },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.amount.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "amount", _vm._n($$v))
                    },
                    expression: "formData.amount",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-4",
                attrs: {
                  error: _vm.descriptionError,
                  label: _vm.$t("core_pos.description"),
                },
              },
              [
                _c("sw-textarea", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.description.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-5 flex" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      icon: "save",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.save")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      type: "button",
                      loading: _vm.isLoading,
                      variant: "primary-outline",
                    },
                    on: { click: _vm.closeIncomeWithdrawalModal },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }