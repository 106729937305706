<template>
  <form action="" class="mh-300">
    <div
      class="pt-2 pb-4 grid grid-cols-1 col-span-1 mt-8 lg:mt-0 lg:grid"
    >
      <sw-input-group v-if="!isNewMethod" class="col-span-1" :label="$t('payments.payment_mode')">
        <sw-select
          v-model="formData.payment_method"
          :options="options_payment_methods"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('payments.payment_method_type')"
          :max-height="150"
          label="formattedNameLabel"
          class="mt-1"
          @input="PaymentModeSelected"
          required
        >
        </sw-select>
      </sw-input-group>
      <br />
      <sw-input-group 
        v-if="isNewMethod" class="pt-2 pb-4 grid grid-cols-1 col-span-1 mt-8 lg:mt-0 lg:grid"  label="Select account type" required>
        <sw-select
          v-model="selectedOption"
          :options="accountTypeOptions"
          :searchable="true"
          :show-labels="false"
          placeholder="Select account type"
          :max-height="150"
          label="text"
          class="mt-1"
          @input="PaymentModeSelected"
          required
        >
        </sw-select>
      </sw-input-group>
      <br>  
      <!-- Paypal -->
     <!--   <Paypal
        v-if="formData.payment_method.name === 'Paypal'"
        :filteredAccounts="filteredAccounts"
        :paymentType="paymentType"
        :formData="formData"
      />-->
      <!-- Credit Card -->
      <CreditCard
        @paymentInformation="paymentInformation"
        @paymentSucess="handlePaymentSucces"
        :updateFormData="updateFormData"
        v-if="(formData.payment_method && formData.payment_method.account_accepted === 'C') || (isNewMethod && formData.payment_account_type === 'CC')"
        :filteredAccounts="filteredAccounts"
        :paymentType="paymentType"
        :formData="formData"
      />
      <!-- ACH -->
      <ACH
        @paymentInformation="paymentInformation"
        @paymentSucess="handlePaymentSucces"
        :updateFormData="updateFormData"
        v-if="(formData.payment_method && formData.payment_method.account_accepted === 'A') || (isNewMethod && formData.payment_account_type === 'ACH')"
        :filteredAccounts="filteredAccounts"
        :paymentType="paymentType"
        :formData="formData" 
      />
    </div>

    <!-- Checkboxes -->
    <div v-if="paymentType === 'oneTime' || isNewMethod" class="mt-4 p-4 border-gray-300 rounded-lg space-y-4">
      <!-- Checkbox for Require ID Verification (Visible in both modes) -->
      <label class="flex items-center">
        <!-- <input 
          type="checkbox" 
          v-model="requireIdVerification" 
          @change="notifyChange"
          class="form-checkbox h-4 w-4 rounded-full border-gray-300 focus:ring-0 text-blue-600"
          style="border-radius: 50%; border-width: 1px; border-color: #d1d5db;"> -->
        <!-- <span class="ml-2 text-gray-700 text-sm">Require ID Verification</span> -->
      </label>
    </div>

    <!-- Mostrar la dirección de facturación guardada -->
    <!-- <div v-if="savedAddress" class="mt-4">
      <h3>Billing Address</h3>
      <p><strong>Default Billing Address:</strong> {{ savedAddress }}</p>
      <a @click="editAddress">Edit</a>
    </div> -->



  </form>
</template>

<script>
import { EventBus } from './event-bus.js';
import { mapActions, mapGetters } from "vuex";
import CreditCard from "./payment-methods/CreditCard.vue";
import Paypal from "./payment-methods/Paypal.vue";
import ACH from "./payment-methods/ACH.vue";

export default {
  components: {
    CreditCard,
    Paypal,
    ACH,
  },
  props: {
    paymentType: {
      type: String,
      default: "oneTime",
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    isNewMethod: {
      type: Boolean,
      default: false,
    },
    disablePay: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      saveForFutureUse: false,
      requireIdVerification: false,
      filteredAccounts: [],
      options_payment_methods: [],
      id: null,
      isLoadingThePaymentAccount: false,
      customerHasPaymentMethods: false,
      savedAddress: null, // Guardar la dirección aquí
      accountTypeOptions: [
        { value: 'CC', text: 'Credit Card' },
        { value: 'ACH', text: 'ACH' }
      ],
      selectedOption: null,
      formData: {
        user_id: null,
        payment_number: null,
        account_number: null,
        ACH_type: {
          value: "checking",
          text: "Checking",
        },
        name: null,
        account_holder: null,
        routing_number: null,
        bank_name: null,
        num_check: null,
        card_holder: null,
        invoice_number: null,
        customer: null,
        payment_date: new Date(),
        amount: 0,
        customer_credit: false,
        payment_method: { name: "Credit Card", id: 1 },
        address_1: null,
        address_2: null,
        country: [],
        state: [],
        state_id: null,
        city: null,
        zip: null,
        country_id: null,
        invoice_id: null,
        notes: null,
        payment_method_id: null,
        payment_gateways: [],
        payment_account_type: null,
        authorize: null,
        authorize_id: null,
        credit_cards: [],
        invoice_list: [],
        credit_card: null,
        updatebillinginformation: false,
        createaccount: false,
        transaction_status: null,
        isTransactionStatus: false,
        status_with_authorize: true,
        add_payment_gateway: 0,
        saved_pay: false,
        payment_method_nonce: null,
        nonce: null,
        invoices: [],
        status: {
          value: "Approved",
          text: "Approved",
        },
        saveForFutureUse: false,
        expiration_month: null,
        expiration_year: null,
        cvv: null,
        account_type: '',
        card_number: null,
      },
      showModal: false,
    };
  },
  watch: {
    accounts: {
      handler: function (val) {
        this.accounts = val;
      },
      deep: true,
    },
    filteredAccounts: {
      handler: function (val) {
        this.filteredAccounts = val;
      },
      deep: true,
    },
    paymentType: {
      handler: function (val) {
        this.updateButtonLabel();
        this.paymentType = val;
        if (val === "saved") {
          this.PaymentModeSelected(this.formData.payment_method);
        }
      },
      deep: true,
    },
    saveForFutureUse: {
      handler: function() {
        this.updateButtonLabel();
      }
    },
    isNewMethod: {
      handler: function() {
        this.updateButtonLabel();
      }
    },
  },
  computed: {
    pageTitle() {
      if (this.$route.name === "payments.edit") {
        return this.$t("payments.edit_payment");
      }
      return this.$t("payments.new_payment");
    },
  },
  async mounted() {
    if (this.$route.params.unique_hash) {      
      const paymentCreditcard = await this.paymentModesWithoutLogin({ 
        limit: 'all',
        orderBy: 'asc'
        })        
        await this.PaymentModeSelected(paymentCreditcard.data.paymentMethods)
    }
  },
  async created() {
    const paymentCreditcard = await this.paymentModesWithoutLogin({ 
      limit: 'all',
      orderBy: 'asc'
      }) 

      this.options_payment_methods = paymentCreditcard.data.paymentMethods

    // await this.setInvoicePaymentData(this.$route.params.unique_hash);

    console.log('estamos en  payment form: ', this.options_payment_methods);
  }, 
  methods: {
    ...mapActions('invoice', ['fetchInvoice', 'fetchInvoices', 'fetchInvoiceForUniqueHash']),

    paymentInformation(formData) {
      this.savedAddress = `${formData.address_1}, ${formData.city}, ${formData.state}, ${formData.zip}, ${formData.country}`;
      this.formData = formData;
    },
    handlePaymentSucces(){
      this.$emit('closeForm');
    },
    async checkAutoGenerate() {
      let response1 = await axios.get('/api/v1/next-number?key=payment', {
        headers: {
          company: this.company_id,
        },
      })

      this.paymentPrefix = response1.data.prefix 
      this.paymentNumAttribute = response1.data.nextNumber

    },
    resetFormData(){
      // ACH //
      this.formData.account_holder = null;
      this.formData.routing_number = null;
      this.formData.bank_name = null;
      this.formData.account_number = null;
      this.formData.ACH_type = null;
     
      // CC //
      this.formData.card_holder = null;
      this.formData.card_number = null;
      this.formData.credit_card = null;
      this.formData.expiration_month = null;
      this.formData.expiration_year = null;
      this.formData.cvv = null;
    },
    submitForm() {
      if (this.isNewMethod) {
        this.$emit('saveNewMethod', this.formData);
      } else if (this.saveForFutureUse) {
        this.$emit('saveAndPay', this.formData);
      } else {
        this.$emit("paymentInformation", this.formData, this.formData.payment_method);
      }
    },
    cancelAction() {
      // Lógica para cancelar la acción actual y regresar al estado anterior
      this.$emit('cancel');
    },
    notifyChange() {
      console.log('Checkbox changed, emitting event:', this.requireIdVerification);
      EventBus.$emit('verification-changed', this.requireIdVerification);
    },
    ...mapActions("payment", [
      "addPaymentWithoutLogin",
      "updatePayment",
      "fetchPayment",
      "paymentModesWithoutLogin",
      "resetSelectedNote",
      "processPayment",
      "paymentModesWithoutLogin",
      "fetchPaymentAccounts"
    ]),
    ...mapActions('paymentGateways', ['fetchPaymentGateways', 'fetchPaymentGatewaysWithoutLogin']),
    ...mapActions('customer', ['fetchCustomers', 'fetchCustomer', 'fetchCustomerWithoutLogin']),

    async fetchCustomerAccounts(userId) {
      let data = {
        customer_id: userId,
        status: 'UNPAID',
      }
      let response = await this.fetchPaymentAccounts(data)
      this.accountList = response.data.payment_accounts.data
        .filter((el) => {
          return el.payment_account_type == 'ACH'
        })
        .map((el) => {
          const auxAccountNumber = el.account_number.toString().split('')
          let showAccountNumber = ''
          const limit = auxAccountNumber.length - 4
          auxAccountNumber.forEach((el, i) => {
            if (i < limit) showAccountNumber = showAccountNumber + '*'
            else showAccountNumber = showAccountNumber + el
          })
          return {
            ...el,
            name_account_number: el.bank_name + ' - ' + showAccountNumber,
          }
        })
      this.cardList = response.data.payment_accounts.data
        .filter((el) => {
          return el.payment_account_type == 'CC'
        })
        .map((el) => {
          const auxCardNumber = el.card_number.toString().split('')
          let showCardNumber = ''
          const limit = auxCardNumber.length - 4
          auxCardNumber.forEach((el, i) => {
            if (i < limit) showCardNumber = showCardNumber + '*'
            else showCardNumber = showCardNumber + el
          })
          return {
            ...el,
            card_number_cvv: el.first_name + ' - ' + showCardNumber,
          }
        })
      this.isLoadingPayments = false
    },

    async PaymentModeSelected(val) {
      this.resetFormData();
      if(!this.isNewMethod){
        this.filteredAccounts = this.accounts.filter(
          (account) =>
            (account.payment_account_type === "CC" && val.account_accepted === "C") ||
            (account.payment_account_type === "ACH" && val.account_accepted === "A")
        );
        this.formData.payment_account_type = val.account_accepted === 'C' ? 'CC' : 'ACH';
      }else if(this.isNewMethod){
        this.formData.payment_method = null;
        this.formData.payment_account_type = val.value === 'CC' ? 'CC' : 'ACH';
      }
      console.log('formData after update: ', this.formData);
    },
    async loadData() {
      let data = {
        limit: 'all',
        orderBy: 'asc',
      }
      this.customerHasPaymentMethods = true;
      let res = await this.paymentModesWithoutLogin(data);
      this.options_payment_methods = res.data.paymentMethods;
      console.log('options: ', this.options_payment_methods)
      await this.buildMethods();
    },
    buildMethods() {
      for (let items of this.options_payment_methods) {
        if (items.account_accepted === 'C') {
          this.formData.payment_method = items;
          break;
        } else if(!this.formData.payment_method && items.account_accepted === 'A') {
          this.formData.payment_method = items;
        }
      }
      this.formData.payment_account_type = this.formData.payment_method.account_accepted === 'C' ? 'CC' : 'ACH';
    },

    editAddress() {
      // Aquí puedes permitir que el usuario edite la dirección
      this.savedAddress = null;
    },
    updateFormData(newFormData){
      this.formData = { ...this.formData, ...newFormData };
      if(!this.isNewMethod){
        this.$emit('paymentInformation', this.formData);
      }else{
        this.$emit('saveNewMethod', this.formData);
      }
    }
  },
};
</script>

<style scoped>
.w100 {
  width: 100%;
}
.select-button {
  justify-content: center;
  align-items: center;
  display: grid;
  height: 90px;
}
#main {
  border: none;
}
.mh-300 {
  min-height: 300px;
}
</style>