var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c("stats-info"),
      _vm._v(" "),
      _c("dashboard-info"),
      _vm._v(" "),
      _c("table-info"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }