var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitItemGroup.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/dashboard",
                        title: _vm.$t("general.home"),
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/tickets/departaments",
                        title: _vm.$t("tickets.title"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "tickets.departaments.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t(
                              "tickets.departaments.edit_departament"
                            ),
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t(
                              "tickets.departaments.new_departament"
                            ),
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("template", { slot: "actions" }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("tickets.departaments.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("tickets.departaments.description"),
                            error: _vm.descriptionError,
                          },
                        },
                        [
                          _c("sw-editor", {
                            attrs: {
                              "set-editor": _vm.formData.description,
                              rows: "2",
                              name: "description",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-checkbox", {
                              staticClass: "absolute",
                              model: {
                                value: _vm.formData.client_permission,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "client_permission",
                                    $$v
                                  )
                                },
                                expression: "formData.client_permission",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "tickets.departaments.client_permission_checkbox"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("tickets.departaments.email"),
                            error: _vm.emailError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.email.$error,
                              type: "text",
                              name: "email",
                              tabindex: "3",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("customer_ticket.user") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getListUsers,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "user_id",
                              label: "user_name",
                            },
                            model: {
                              value: _vm.formData.users,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "users", $$v)
                              },
                              expression: "formData.users",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.contenido
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "flex my-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "relative w-12" },
                                  [
                                    _c("sw-checkbox", {
                                      staticClass: "absolute",
                                      model: {
                                        value: _vm.formData.sender_override,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "sender_override",
                                            $$v
                                          )
                                        },
                                        expression: "formData.sender_override",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "tickets.departaments.sender_override_checkbox"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex my-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "relative w-12" },
                                  [
                                    _c("sw-checkbox", {
                                      staticClass: "absolute",
                                      model: {
                                        value: _vm.formData.send_emails,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "send_emails",
                                            $$v
                                          )
                                        },
                                        expression: "formData.send_emails",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formData.send_emails) +
                                          "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "tickets.departaments.send_emails_checkbox"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex my-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "relative w-12" },
                                  [
                                    _c("sw-checkbox", {
                                      staticClass: "absolute",
                                      model: {
                                        value:
                                          _vm.formData
                                            .automatically_transition_admin,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "automatically_transition_admin",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.automatically_transition_admin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "tickets.departaments.transition_ticket_checkbox"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.$t(
                                      "tickets.departaments.default_priority"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.default_prioritys,
                                      searchable: true,
                                      "show-labels": false,
                                      tabindex: 16,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "tickets.departaments.default_priority"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.default_priorit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "default_priorit",
                                          $$v
                                        )
                                      },
                                      expression: "formData.default_priorit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.$t(
                                      "tickets.departaments.email_handling"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.email_handlings,
                                      searchable: true,
                                      "show-labels": false,
                                      tabindex: 16,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "tickets.departaments.email_handling"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.email_handlin,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "email_handlin",
                                          $$v
                                        )
                                      },
                                      expression: "formData.email_handlin",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.$t(
                                      "tickets.departaments.automatically_close_tickets"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.days,
                                      searchable: true,
                                      "show-labels": false,
                                      tabindex: 16,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "tickets.departaments.automatically_close_tickets"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.automatically_clos,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "automatically_clos",
                                          $$v
                                        )
                                      },
                                      expression: "formData.automatically_clos",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.$t(
                                      "tickets.departaments.automatically_delete_tickets"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.days,
                                      searchable: true,
                                      "show-labels": false,
                                      tabindex: 16,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "tickets.departaments.automatically_delete_tickets"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.automatically_delet,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "automatically_delet",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.automatically_delet",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: { label: _vm.$t("providers.status") },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.status,
                                      searchable: true,
                                      "show-labels": false,
                                      tabindex: 16,
                                      "allow-empty": true,
                                      placeholder: _vm.$t("providers.status"),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.statu,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "statu", $$v)
                                      },
                                      expression: "formData.statu",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("sw-input-group", {
                                staticClass: "mb-4",
                                attrs: {
                                  label: _vm.$t(
                                    "tickets.departaments.schedule"
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "w-full item-table bg-white border border-gray-200 border-solid",
                                },
                                [
                                  _c("colgroup", [
                                    _c("col", {
                                      staticStyle: { width: "26%" },
                                    }),
                                    _vm._v(" "),
                                    _c("col", {
                                      staticStyle: { width: "22%" },
                                    }),
                                    _vm._v(" "),
                                    _c("col", {
                                      staticStyle: { width: "26%" },
                                    }),
                                    _vm._v(" "),
                                    _c("col", {
                                      staticStyle: { width: "26%" },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "tickets.departaments.day"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "tickets.departaments.all_day"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "tickets.departaments.start_time"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "tickets.departaments.end_time"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.days_week,
                                      function (item, index) {
                                        return _c(
                                          "tr",
                                          { key: index, staticClass: "py-3" },
                                          [
                                            _c("td", { staticClass: "px-5" }, [
                                              _vm._v(_vm._s(item)),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("sw-checkbox", {
                                                  attrs: { value: item },
                                                  model: {
                                                    value:
                                                      _vm.formData.day_check,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "day_check",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.day_check",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5" },
                                              [
                                                _c("sw-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    options: _vm.schedule,
                                                    searchable: true,
                                                    "show-labels": false,
                                                    tabindex: 16,
                                                    "allow-empty": true,
                                                    label: "text",
                                                    "track-by": "value",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.star_tim[
                                                        index
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData.star_tim,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.star_tim[index]",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5" },
                                              [
                                                _c("sw-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    options: _vm.schedule,
                                                    searchable: true,
                                                    "show-labels": false,
                                                    tabindex: 16,
                                                    "allow-empty": true,
                                                    label: "text",
                                                    "track-by": "value",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.end_tim[
                                                        index
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData.end_tim,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.end_tim[index]",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("sw-input-group", {
                                staticClass: "mb-4",
                                attrs: {
                                  label: _vm.$t(
                                    "tickets.departaments.receive_tickets_emails"
                                  ),
                                  error: _vm.emailError,
                                  required: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.options_settings,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "flex my-8" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "relative w-8" },
                                          [
                                            _c("sw-checkbox", {
                                              staticClass: "absolute",
                                              attrs: { value: item },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .receive_tickets_emailss,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "receive_tickets_emailss",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.receive_tickets_emailss",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "p-0 mb-1 mr-4 text-base leading-snug text-black box-title",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c("sw-input-group", {
                                staticClass: "mb-4",
                                attrs: {
                                  label: _vm.$t(
                                    "tickets.departaments.receive_mobile_tickets_emails"
                                  ),
                                  error: _vm.emailError,
                                  required: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.options_settings,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "flex my-8" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "relative w-8" },
                                          [
                                            _c("sw-checkbox", {
                                              staticClass: "absolute",
                                              attrs: { value: item },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .receive_mobile_tickets_emailss,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "receive_mobile_tickets_emailss",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.receive_mobile_tickets_emailss",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "p-0 mb-1 mr-4 text-base leading-snug text-black box-title",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c("sw-input-group", {
                                staticClass: "mb-4",
                                attrs: {
                                  label: _vm.$t(
                                    "tickets.departaments.receive_tickets_messenger_notifications"
                                  ),
                                  error: _vm.emailError,
                                  required: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.options_settings,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "flex my-8" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "relative w-8" },
                                          [
                                            _c("sw-checkbox", {
                                              attrs: { value: item },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .receive_tickets_messenger_notificationss,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "receive_tickets_messenger_notificationss",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.receive_tickets_messenger_notificationss",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "p-0 mb-1 mr-4 text-base leading-snug text-black box-title",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-6 mb-4" },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full md:w-auto",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t(
                                          "tickets.departaments.update_departament"
                                        )
                                      : _vm.$t(
                                          "tickets.departaments.save_departament"
                                        )
                                  ) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full md:w-auto",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                type: "button",
                                variant: "primary-outline",
                                size: "lg",
                              },
                              on: { click: _vm.buttonCancel },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("general.cancel")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "flex justify-center w-full md:w-auto",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("tickets.departaments.update_departament")
                            : _vm.$t("tickets.departaments.save_departament")
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }