var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getStickyNotes.length > 0
    ? _c("sw-card", { staticClass: "flex flex-col mt-8" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("customers.sticky_notes")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-3 mt-5" },
            _vm._l(_vm.getStickyNotes, function (note, index) {
              return _c("div", { key: index, staticClass: "flex" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "font-medium text-primary-500",
                        attrs: { to: "" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(note.summary) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }