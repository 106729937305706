var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("users.user_permisions") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: { to: "/admin/users", title: _vm.$tc("users.user", 2) },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/users/" + _vm.idUser + "/view",
                  title: _vm.userData.name,
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$t("roles.permissions"),
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid gap-4 grid-cols-12" }, [
        _c(
          "div",
          { staticClass: "col-span-12 xl:col-span-4" },
          [
            _c("sw-card", [
              _c(
                "div",
                { staticClass: "grid grid-cols-2 gap-4 xl:grid-cols-1" },
                [
                  _c("div", { staticClass: "mb-5" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("users.name")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.userData.name) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("users.role")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.userData.role2) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-span-12 xl:col-span-8" },
          [
            _c(
              "sw-card",
              [
                _vm.isRequestOnGoing ? _c("base-loader") : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col md:flex-row md:justify-between items-center mb-5 md:mb-0",
                  },
                  [
                    _c("h6", { staticClass: "sw-section-title mb-5 md:mb-0" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("roles.permissions")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-center md:justify-end w-full md:w-auto",
                      },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "mr-2 mb-2 md:mb-0 w-full md:w-auto",
                            attrs: { type: "button", variant: "primary" },
                            on: { click: _vm.cancelButton },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("roles.modules.cancel")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.moduleOptionsFilter.length !== 0
                          ? _c(
                              "sw-button",
                              {
                                staticClass:
                                  "mr-2 mb-2 md:mb-0 w-full md:w-auto",
                                attrs: { variant: "primary-outline" },
                                on: { click: _vm.addAllPermissions },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("roles.modules.add_all")) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "sw-button",
                          {
                            staticClass: "mr-2 mb-2 md:mb-0 w-full md:w-auto",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.updateUser()
                              },
                            },
                          },
                          [
                            _c("save-icon", { staticClass: "mr-2 -ml-1" }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("general.update")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "scrollable-div3" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "w-full text-center item-table table-permission table-wrapperestper",
                    },
                    [
                      _c(
                        "thead",
                        {
                          staticClass:
                            "border border-gray-200 border-solid theadClass",
                        },
                        [
                          _c(
                            "th",
                            {
                              staticClass: "py-3 px-2 text-sm text-gray-700",
                              staticStyle: { width: "70%" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.module")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "py-3 px-2 text-sm text-gray-700" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.modules.access")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "py-3 px-2 text-sm text-gray-700" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.modules.create")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "py-3 px-2 text-sm text-gray-700" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.modules.read")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "py-3 px-2 text-sm text-gray-700" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.modules.update")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "py-3 px-2 text-sm text-gray-700" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("roles.modules.delete")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("th", {
                            staticClass: "py-3 px-2 text-sm text-gray-700",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.userData.permissions,
                          function (permission, indexTr) {
                            return _c(
                              "tr",
                              {
                                key: indexTr,
                                staticClass:
                                  "border border-gray-200 border-solid",
                              },
                              [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-center items-center p-3",
                                    },
                                    [
                                      _c(
                                        "sw-input-group",
                                        [
                                          _c("sw-select", {
                                            attrs: {
                                              options: _vm.moduleOptionsFilter,
                                              searchable: true,
                                              "show-labels": false,
                                              placeholder:
                                                _vm.$t("roles.module"),
                                              label: "label",
                                            },
                                            on: { input: _vm.addPermission },
                                            model: {
                                              value: permission.module,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  permission,
                                                  "module",
                                                  $$v
                                                )
                                              },
                                              expression: "permission.module",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center pb-6" },
                                    [
                                      _c("sw-switch", {
                                        model: {
                                          value: permission.access,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "access", $$v)
                                          },
                                          expression: "permission.access",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center pb-6" },
                                    [
                                      _c("sw-switch", {
                                        model: {
                                          value: permission.create,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "create", $$v)
                                          },
                                          expression: "permission.create",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center pb-6" },
                                    [
                                      _c("sw-switch", {
                                        model: {
                                          value: permission.read,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "read", $$v)
                                          },
                                          expression: "permission.read",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center pb-6" },
                                    [
                                      _c("sw-switch", {
                                        model: {
                                          value: permission.update,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "update", $$v)
                                          },
                                          expression: "permission.update",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center pb-6" },
                                    [
                                      _c("sw-switch", {
                                        model: {
                                          value: permission.delete,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "delete", $$v)
                                          },
                                          expression: "permission.delete",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  permission.module.value !== ""
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center p-3 cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeModule(indexTr)
                                            },
                                          },
                                        },
                                        [
                                          _c("trash-icon", {
                                            staticClass: "h-5 text-gray-700",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }