var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
    _c("div", { staticClass: "border-b tab" }, [
      _c("div", { staticClass: "relative" }, [
        _c("input", {
          staticClass:
            "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
          attrs: { type: "checkbox", id: "chck3" },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass:
              "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
            attrs: { for: "chck3" },
          },
          [
            _c(
              "span",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$tc("estimates.estimate_assigned")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      "aria-hidden": "true",
                      "data-reactid": "266",
                      fill: "none",
                      height: "24",
                      stroke: "#606F7B",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      viewbox: "0 0 24 24",
                      width: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [_c("polyline", { attrs: { points: "6 9 12 15 18 9" } })]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-content" },
          [
            _c(
              "div",
              { staticClass: "text-grey-darkest" },
              [
                _c(
                  "sw-tabs",
                  {
                    attrs: { "active-tab": _vm.activeEstimateTab },
                    on: { update: _vm.setEstimateStatusFilter },
                  },
                  [
                    _c("sw-tab-item", {
                      attrs: { title: _vm.$t("general.all"), filter: "" },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("general.draft"),
                        filter: "DRAFT",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: { title: _vm.$t("general.sent"), filter: "SENT" },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("general.viewed"),
                        filter: "VIEWED",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("general.expired"),
                        filter: "EXPIRED",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("general.accepted"),
                        filter: "ACCEPTED",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("general.rejected"),
                        filter: "REJECTED",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-table-component",
              {
                ref: "estimates_table",
                attrs: {
                  "show-filter": false,
                  data: _vm.fetchEstimatesData,
                  "table-class": "table",
                },
              },
              [
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("estimates.date"),
                    "sort-as": "estimate_date",
                    show: "formattedEstimateDate",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$tc("estimates.estimate", 1),
                    show: "estimate_number",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$tc("estimates.estimate", 1))),
                          ]),
                          _vm._v(" "),
                          _vm.readEstimates
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: `/admin/estimates/${row.id}/view`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.estimate_number) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.estimate_number) +
                                    "\n              "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("estimates.status"),
                    show: "status",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("estimates.status"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color": _vm.$utils.getBadgeStatusColor(
                                  row.status
                                ).bgColor,
                                color: _vm.$utils.getBadgeStatusColor(
                                  row.status
                                ).color,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.status) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("estimates.total"),
                    "sort-as": "total",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("estimates.total"))),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.formatMoney(
                                  row.total,
                                  row.user.currency
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }