var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitCashRegister.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/corePOS",
                        title: _vm.$t("core_pos.home"),
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/corePOS/cash-register",
                        title: _vm.$t("core_pos.cash_registers"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "corepos.cashregister.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: "Edit Cash Register",
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("core_pos.new_cash_register"),
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          variant: "primary-outline",
                          size: "lg",
                          type: "button",
                        },
                        on: { click: _vm.redirectDashboard },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("core_pos.cancel")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "flex justify-center w-full md:w-auto",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.isEdit
                                ? "Update Cash Register"
                                : _vm.$t("core_pos.save_cash_register")
                            ) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("core_pos.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("core_pos.device"),
                            error: _vm.deviceError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.device.$error,
                              focus: "",
                              type: "text",
                              name: "device",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.device.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.device,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "device",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.device",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4 mt-4",
                          attrs: {
                            label: _vm.$t("core_pos.description"),
                            error: _vm.descriptionError,
                          },
                        },
                        [
                          _c("sw-textarea", {
                            staticStyle: { resize: "none" },
                            attrs: {
                              invalid: _vm.$v.formData.description.$error,
                              rows: "5",
                              name: "note",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            error: _vm.userError,
                            label: _vm.$t("core_pos.users"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.users,
                              searchable: true,
                              "show-labels": false,
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select an User",
                              multiple: true,
                              tabindex: 10,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.users_id.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.users_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "users_id", $$v)
                              },
                              expression: "formData.users_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            error: _vm.customerError,
                            label: _vm.$t("core_pos.customer"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.customers,
                              searchable: true,
                              "show-labels": false,
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select an Customer",
                              multiple: false,
                              tabindex: 10,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.customer_id.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.customer_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "customer_id", $$v)
                              },
                              expression: "formData.customer_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            error: _vm.storeError,
                            label: _vm.$t("core_pos.store"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.stores,
                              searchable: true,
                              "show-labels": false,
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select an Store",
                              multiple: false,
                              tabindex: 10,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.store_id.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.store_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "store_id", $$v)
                              },
                              expression: "formData.store_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }