<template>
    <div>
    <!-- Textarea para las notas -->
    <sw-input-group label="Notes" class="mb-4">
      <textarea
        v-model="localFormData.notes"
        class="input-field w-full h-32"
        placeholder="Enter your notes here"
        rows="5"
        @input="updateLocalFormData()"
      ></textarea>
    </sw-input-group>

    <div class="mt-4 flex justify-end space-x-2">
      <sw-button :variant="'primary'" @click.prevent="submitNotes">
        Save Notes
      </sw-button>
    </div>
  </div>
</template>
  
  <script>
  import { EventBus } from '../event-bus.js'
  import { mapActions, mapState } from 'vuex'
  
  export default {
    props: {
      formData: Object,
      paymentType: String,
      isNewMethod: Boolean,
      disablePay: Boolean,
      updateFormData: Function,
      customer: Object,
    },
    data() {
      return {
        isSaved: false,
        paymentAccountPreview: {
          src: '/images/bank.png',
          width: '100px',
        },
        isEditing: false,
        isStorageDataAvailable: false,
        showPaymentForm: false,
        isLoading: false,
        submissionError: false, // Nueva propiedad para manejar la advertencia de error
        localFormData: { ...this.formData },
      }
    },
    computed: {
  
      buttonLabel() {
        if (this.showPaymentForm) {
          return 'Save and Pay'
        } else if (this.saveForFutureUse) {
          return 'Save and Pay'
        } else if (this.isNewMethod) {
          return 'Save New Method'
        } else {
          return 'Use This Method'
        }
      },
    },
    watch: {
      customer(newVal) {
        if (newVal) {
          this.setData();
        }
      }
    },
    methods: {
      ...mapActions('paymentAccounts', ['addPaymentAccount']),
      ...mapActions('customerAddress', ['fetchCustomerAddresses']),
  
      changeCreditCard() {
        this.validateCardNumber()
        this.updateLocalFormData()
      },
  
      async loadCountries() {
        let res = await window.axios.get('/api/v1/countries')
        if (res && res.data && res.data.countries) {
          this.countries = res.data.countries
        }
      },
      async loadStates(country) {
        if (!country || !country.code) return
        let res = await window.axios.get(`/api/v1/states/${country.code}`)
        if (res && res.data && res.data.states) {
          this.states = res.data.states
        }
      },
      async countrySelectedEdit(country) {
        this.editFormData.state = ''
        await this.loadStates(country)
      },
  
      async setData(){
  
        if(this.countries.length === 0){
          await this.loadCountries();
        }
  
        let resAddress = await this.fetchCustomerAddresses({
          customer_id: this.customer.id,
        });
  
        const address = resAddress.data.customerAddress.data
  
        if (this.customer && address) {
          const billingAddress = address.find(address => address.type === 'billing');
  
          if (billingAddress) {
            const selectedCountry = this.countries.find(country => country.id === billingAddress.country_id);
            
            if(selectedCountry){
              await this.countrySelectedEdit(selectedCountry);
            }
  
            const selectedState = this.states.find(state => state.id === billingAddress.state_id);
  
            this.localFormData = {
              state: selectedState || null,
              country: selectedCountry || null,
              card_holder: this.customer.name,
            };
          }
        }
        console.log('formdata in set data: ', this.localFormData)
  
        this.isLoading = false;
      },
      updateLocalFormData() {
        this.updateFormData({ ...this.localFormData })
        this.localFormData.payment_account_type = 'NG'
        console.log('formData: ', this.localFormData);
      },
      async submitForm() {
        this.submissionError = false // Resetear la advertencia de error
  
        this.validateCardholderName()
        this.validateCardNumber()
        this.validateExpirationDate()
        this.validateCVV()
  
        if (
          this.cardHolderError ||
          this.cardNumberError ||
          this.expirationDateError ||
          this.errors.cvv
        ) {
          this.submissionError = true
          return
        }
  
        this.isLoading = true
  
        const dataPaymentAccount = {
          first_name: this.localFormData.card_holder?.substring(0, 21),
          country_id: this.localFormData.country.id,
          state_id: this.localFormData.state.id,
          city: this.localFormData.city,
          address_1: this.localFormData.address_1,
          address_2: this.localFormData.address_2,
          zip: this.localFormData.zip,
          payment_account_type: this.localFormData.payment_account_type,
          client_id: this.customer.id,
          status: 'A',
          main_account: 0,
          nameOnAccount: this.localFormData.card_holder?.substring(0, 21),
          credit_cards: this.localFormData.credit_card,
          cvv: this.localFormData.cvv,
          card_number: this.localFormData.card_number,
          expiration_date: `${this.localFormData.expiration_year}-${this.localFormData.expiration_month}`,
        }
        console.log('data payment: ', dataPaymentAccount)
        try {
          const responsePaymentaccount = await this.addPaymentAccount(
            dataPaymentAccount
          )
          if (responsePaymentaccount.status === 200) {
            window.toastr['success']('Payment Account Added')
            this.$emit('paymentSucess')
          } else {
            window.toastr['error']('Failed to add payment account')
          }
        } catch (error) {
          window.toastr['error']('Failed to add payment account')
        }
        this.isLoading = false
      },
    },
    async created() {
      console.log('customer: ', this.localFormData)
    },
  }
  </script>
  
  <style scoped>
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .saved-address {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .saved-address h3 {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .saved-address p {
    margin: 0.25rem 0;
  }
  
  .saved-address strong {
    font-weight: bold;
  }
  
  .edit-link {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .edit-link:hover {
    text-decoration: underline;
  }
  
  .remove-link {
    color: #ff0000;
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .remove-link:hover {
    text-decoration: underline;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .expiration-container {
    display: flex;
    gap: 0.5rem;
  }
  
  .w-20 {
    width: 5rem;
  }
  
  .sw-input-group .sw-input.input-field:focus,
  .sw-select.input-field:focus {
    border: 1px solid blue !important;
  }
  
  .error-text {
    color: red;
    font-size: 0.875rem;
  }
  .input-with-icon {
    display: flex;
    align-items: center;
  }
  .card-icon {
    margin-right: 10px;
  }
  </style>