var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid gap-3 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8" },
    [
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/invoices" },
          slot: "item-title",
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-sm font-semibold leading-tight text-black xl:text-2xl",
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalDueAmount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.due_amount")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("dollar-icon", { staticClass: "w-9 h-9 xl:w-12 xl:h-12" })],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/customers" },
          slot: "item-title",
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-sm font-semibold leading-tight text-black xl:text-2xl",
                  },
                  [_vm._v("\n        " + _vm._s(_vm.getContacts) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.customers")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("contact-icon", { staticClass: "w-9 h-9 xl:w-12 xl:h-12" })],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "" },
          slot: "item-title",
        },
        [
          _c(
            "sw-dropdown",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                {
                  staticClass: "relative flex justify-between lg:col-span-2",
                  staticStyle: { width: "100%" },
                  attrs: { slot: "activator" },
                  slot: "activator",
                },
                [
                  _c("div", [
                    _vm.getDashboardDataLoaded
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getInvoices) +
                                "\n\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("dashboard.cards.invoices")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("invoice-icon", {
                        staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                { attrs: { to: `/admin/invoices`, "tag-name": "router-link" } },
                [
                  _c("paper-airplane-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v("\n        Go to invoices\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=DRAFT`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Draft:")]),
                  _vm._v("  " + _vm._s(_vm.getinvoicesCountDraft) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=DUE`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Sent:")]),
                  _vm._v("  " + _vm._s(_vm.getinvoicesCountSend) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=DUE`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Unpaid:")]),
                  _vm._v("  " + _vm._s(_vm.getinvoicesunpaid) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=DUE`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Partially Paid:")]),
                  _vm._v("  " + _vm._s(_vm.getinvoicesCountppaid) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=DUE`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Viewed:")]),
                  _vm._v("  " + _vm._s(_vm.getinvoicesCountView) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=COMPLETED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Completed:")]),
                  _vm._v(
                    "  " + _vm._s(_vm.getinvoicesCountCompleted) + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=COMPLETED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Paid:")]),
                  _vm._v("  " + _vm._s(_vm.getInvoicespaid) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=OVERDUE`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("x-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Overdue:")]),
                  _vm._v(
                    "   " + _vm._s(_vm.getinvoicesCountOverdue) + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/invoices?status=ARCHIVED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("x-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Archived:")]),
                  _vm._v(
                    "   " + _vm._s(_vm.getinvoicesCountDeleted) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "" },
          slot: "item-title",
        },
        [
          _c(
            "sw-dropdown",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                {
                  staticClass: "relative flex justify-between lg:col-span-2",
                  staticStyle: { width: "100%" },
                  attrs: { slot: "activator" },
                  slot: "activator",
                },
                [
                  _c("div", [
                    _vm.getDashboardDataLoaded
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-semibold leading-tight text-black xl:text-2xl",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getEstimates) +
                                "\n\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("dashboard.cards.estimates")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("invoice-icon", {
                        staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: { to: `/admin/estimates`, "tag-name": "router-link" },
                },
                [
                  _c("paper-airplane-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v("\n        Go to estimates\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=DRAFT`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Draft:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesDraft) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=SENT`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Sent:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesSent) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=VIEWED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Viewed:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesViewed) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=EXPIRED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Expired:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesExpired) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=ACCEPTED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Accepted:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesAccepted) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown-item",
                {
                  attrs: {
                    to: `/admin/estimates?status=REJECTED`,
                    "tag-name": "router-link",
                  },
                },
                [
                  _c("check-circle-icon", {
                    staticClass: "h-5 mr-3 text-gray-600",
                  }),
                  _vm._v(" "),
                  _c("b", [_vm._v("\n          Rejected:")]),
                  _vm._v("  " + _vm._s(_vm.getEstimatesRejected) + "\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }