var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
        [
          _c("template", { slot: "actions" }),
          _vm._v(" "),
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("general.home"),
                  to: "/customer/dashboard",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("payments.payment", 2),
                  to: "/customer/payments",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title:
                    _vm.paymentRoute === "Invoices" ? "Invoices" : "Credit",
                  to: "#",
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid lg:grid-cols-12 sm:grid-cols-1 gap-8 mt-1 mb-2" },
        [
          _c(
            "div",
            { staticClass: "lg:col-span-8 md:col-span-8 sm:col-span-12" },
            [
              _c("sw-card", { staticClass: "bg-white" }, [
                _c("h1", { staticClass: "font-semibold mb-4 text-lg" }, [
                  _vm._v("Payment Method"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex gap-4 mb-4" },
                  [
                    _c(
                      "custom-sw-button",
                      {
                        staticClass: "w-full",
                        attrs: {
                          variant:
                            _vm.paymentType === "oneTime"
                              ? "primary"
                              : "primary-outline",
                          size: "lg",
                          selected: _vm.paymentType === "oneTime",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePaymentType("oneTime")
                          },
                        },
                      },
                      [
                        _c("credit-card-icon", { staticClass: "mr-2" }),
                        _vm._v(" One-time Payment\n          "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "custom-sw-button",
                      {
                        staticClass: "w-full",
                        attrs: {
                          variant:
                            _vm.paymentType === "saved"
                              ? "primary"
                              : "primary-outline",
                          size: "lg",
                          selected: _vm.paymentType === "saved",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePaymentType("saved")
                          },
                        },
                      },
                      [
                        _c("save-icon", { staticClass: "mr-2" }),
                        _vm._v(" Use Saved Payment Method\n          "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.paymentType === "oneTime"
                  ? _c(
                      "div",
                      { staticClass: "col-span-12 mt-4" },
                      [
                        _c("PaymentForm", {
                          attrs: {
                            paymentType: _vm.paymentType,
                            accounts: _vm.accounts,
                            isNewMethod: false,
                          },
                          on: {
                            paymentInformation: _vm.handlePaymentInformation,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.accounts != null &&
                this.accounts.length > 0 &&
                _vm.paymentType === "saved"
                  ? _c(
                      "div",
                      _vm._l(_vm.accounts, function (account, index) {
                        return _c(
                          "div",
                          {
                            key: account.id,
                            class: [
                              "flex items-center p-4 mb-4 rounded border cursor-pointer",
                              {
                                "saved-method":
                                  _vm.selectedAccountId === account.id,
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.selectAccount(account)
                              },
                            },
                          },
                          [
                            account.payment_account_type === "CC"
                              ? _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("input", {
                                      staticClass: "mr-2",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked:
                                          _vm.selectedAccountId === account.id,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      _vm.getCreditCardComponent(
                                        account.credit_card
                                      ),
                                      {
                                        tag: "component",
                                        staticClass: "ml-2 mr-2",
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-col" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-semibold" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(account.credit_card) +
                                                " ending in\n                  " +
                                                _vm._s(
                                                  _vm.getLastFourDigits(
                                                    account.card_number
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-gray-600" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getSavedAddress(account)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            account.payment_account_type === "ACH"
                              ? _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("input", {
                                      staticClass: "mr-2",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked:
                                          _vm.selectedAccountId === account.id,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          stroke: "currentColor",
                                          width: "50px",
                                          height: "50px",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            d: "M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-col" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-semibold" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(account.ACH_type) +
                                                " ending in\n                  " +
                                                _vm._s(
                                                  _vm.getLastFourDigits(
                                                    account.account_number
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-gray-600" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getSavedAddress(account)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.paymentType === "saved" && !_vm.showPaymentForm
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn w-full",
                          on: { click: _vm.togglePaymentForm },
                        },
                        [
                          _vm._v(
                            "\n            + Add New Payment Account\n          "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showPaymentForm
                  ? _c(
                      "div",
                      { staticClass: "col-span-12 mt-4" },
                      [
                        _c("PaymentForm", {
                          attrs: {
                            isNewMethod: true,
                            paymentType: _vm.paymentType,
                            formData: _vm.formData,
                            accounts: _vm.accounts,
                          },
                          on: {
                            saveNewMethod: _vm.saveNewPaymentMethod,
                            closeForm: _vm.handleCloseForm,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.defaultPaymentdisplay != null &&
                _vm.paymentType === "saved"
                  ? _c("div", { staticClass: "mt-4 saved-address" }, [
                      _c("h3", { staticClass: "font-semibold mb-2 text-lg" }, [
                        _vm._v("Billing Address"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", [_vm._v("Default Billing Address:")]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-gray-700" }, [
                        _vm._v(_vm._s(_vm.savedAddress)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "edit-link text-blue-600",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleEdit(_vm.defaultPaymentdisplay)
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md:col-span-4 lg:col-span-4 sm:col-span-12" },
            [
              _vm.creditCardId &&
              _vm.achAccountId &&
              _vm.paymentRoute == "Invoices"
                ? _c("Invoices", {
                    attrs: {
                      fees: _vm.fees,
                      isidentificationverification:
                        _vm.isidentificationverification,
                      disablePay: _vm.disablePay,
                      formData: _vm.formData,
                      defaultPaymentdisplay: _vm.defaultPaymentdisplay,
                      defaultPaymentGateway: _vm.defaultPaymentGateway,
                      paymentType: _vm.paymentType,
                      cardSaveText: _vm.cardSaveText,
                      Last4digittext: _vm.Last4digittext,
                      creditCardId: _vm.creditCardId,
                      achAccountId: _vm.achAccountId,
                    },
                    on: { payInvoice: _vm.payInvoice },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.creditCardId &&
              _vm.achAccountId &&
              _vm.paymentRoute == "Credit"
                ? _c("Credit", {
                    attrs: {
                      fees: _vm.fees,
                      disablePay: _vm.disablePay,
                      isidentificationverification:
                        _vm.isidentificationverification,
                      formData: _vm.formData,
                      defaultPaymentdisplay: _vm.defaultPaymentdisplay,
                      defaultPaymentGateway: _vm.defaultPaymentGateway,
                      paymentType: _vm.paymentType,
                      cardSaveText: _vm.cardSaveText,
                      Last4digittext: _vm.Last4digittext,
                      creditCardId: _vm.creditCardId,
                      achAccountId: _vm.achAccountId,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }