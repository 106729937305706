var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex items-center justify-between mb-0" }, [
    _c(
      "div",
      { staticClass: "flex items-center ml-4", staticStyle: { flex: "6" } },
      [
        _c("h6", {
          staticClass: "pr-2 mb-0 text-",
          attrs: { align: "left" },
          domProps: { textContent: _vm._s(_vm.taxData.name) },
        }),
        _vm._v(" "),
        _c("br"),
      ]
    ),
    _vm._v(" "),
    _vm.taxData.tax * 100 > 0.0
      ? _c("div", {
          staticClass: "text-sm text-right mr-3",
          staticStyle: { flex: "3" },
          domProps: {
            innerHTML: _vm._s(
              _vm.$utils.formatMoney(_vm.taxData.tax * 100, _vm.currency)
            ),
          },
        })
      : _c("div", {
          staticClass: "text-sm text-right mr-3",
          staticStyle: { flex: "3" },
        }),
    _vm._v(" "),
    _c("div", {
      staticClass:
        "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }