var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pbx-service-details" },
    [
      _c("sw-divider", { staticClass: "my-6" }),
      _vm._v(" "),
      _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("customers.pbxservices_resume")) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customers.service_number")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.pbx_services_number
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customers.package_name")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService &&
                              _vm.selectedPbxService.pbx_package
                              ? _vm.selectedPbxService.pbx_package
                                  .pbx_package_name
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("packages.status")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  _vm.serviceStatus
                    ? _c("div", [
                        _vm.serviceStatus.value === "A"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n" +
                                        _vm._s(_vm.$t("general.active")) +
                                        "\n"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.serviceStatus.value === "P"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n" +
                                        _vm._s(_vm.$t("general.pending")) +
                                        "\n"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.serviceStatus.value == "S"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n" +
                                        _vm._s(_vm.$t("general.suspended")) +
                                        "\n"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.serviceStatus.value == "C"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n" +
                                        _vm._s(_vm.$t("general.cancelled")) +
                                        "\n"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customers.term")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.term
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customers.discount")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  _vm.allowDiscount === "YES"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("COMPLETED")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("COMPLETED")
                                    .color,
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("general.yes")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.allowDiscount === "NO"
                    ? _c(
                        "div",
                        [
                          _c(
                            "sw-badge",
                            {
                              staticClass: "px-3 py-1",
                              attrs: {
                                "bg-color":
                                  _vm.$utils.getBadgeStatusColor("OVERDUE")
                                    .bgColor,
                                color:
                                  _vm.$utils.getBadgeStatusColor("OVERDUE")
                                    .color,
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("general.not")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customers.date_begin")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.date_begin
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.type_service")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  _vm.selectedPbxService
                    ? _c("div", [
                        _vm.selectedPbxService.pbx_package
                          ? _c("div", [
                              _vm.selectedPbxService.pbx_package
                                .status_payment == "postpaid"
                                ? _c("div", [
                                    _vm._v(
                                      "\n      " +
                                        _vm._s(
                                          _vm.$t("packages.item.postpaid")
                                        ) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedPbxService.pbx_package
                                .status_payment == "prepaid"
                                ? _c("div", [
                                    _vm._v(
                                      "\n      " +
                                        _vm._s(
                                          _vm.$t("packages.item.prepaid")
                                        ) +
                                        "\n    "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.server")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService &&
                              _vm.selectedPbxService.pbx_package &&
                              _vm.selectedPbxService.pbx_package.server
                              ? _vm.selectedPbxService.pbx_package.server
                                  .server_label
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.tenant")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService &&
                              _vm.selectedPbxService.tenant
                              ? _vm.selectedPbxService.tenant.name
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.package_price")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.selectedPbxService
                          ? _vm.$utils.formatMoney(
                              _vm.selectedPbxService.pbxpackages_price * 100,
                              _vm.defaultCurrency
                            )
                          : ""
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.package_number")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.selectedPbxService &&
                              _vm.selectedPbxService.pbx_package
                              ? _vm.selectedPbxService.pbx_package
                                  .packages_number
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.auto_suspension")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  _vm.selectedPbxService
                    ? _c("div", [
                        _vm.selectedPbxService.auto_suspension === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.$t("general.active")) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedPbxService.auto_suspension != 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.$t("general.inactive")) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.cap_by_extension")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                 " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.cap_extension
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.total_by_extension")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                 " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.cap_total
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("pbx_services.inclusive_minutes_consume")
                          ) +
                          "\n                      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  this.inclusive_minutes_cosumed == null
                    ? _c("div", [
                        _vm._v(
                          "\n                                    0\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.inclusive_minutes_cosumed != null
                    ? _c("div", [
                        _vm._v(
                          "\n                                  " +
                            _vm._s(this.inclusive_minutes_cosumed.toFixed(2)) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.prorateotal")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                 " +
                          _vm._s(
                            _vm.selectedPbxService
                              ? _vm.selectedPbxService.total_prorate / 100
                              : ""
                          ) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title mt-6" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("pbx_services.cdr_processes")) +
                    "\n          "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.calculated")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.commandosDataCdr.calculated) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.calculated_today")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.commandosDataCdr.calculated_today) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("pbx_services.uncalculated")) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.commandosDataCdr.unCalculated) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.isPrepaid
              ? _c(
                  "p",
                  {
                    staticClass:
                      "text-gray-500 uppercase sw-section-title mt-6",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("pbx_services.prepaid_information")) +
                        "\n          "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isPrepaid
              ? _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
                  },
                  [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.total_consume")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.defaultCurrency.symbol +
                                  " " +
                                  _vm.total_consume.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.paid_consume")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.defaultCurrency.symbol +
                                  " " +
                                  _vm.paid_consume.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.unpaid_consume")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.defaultCurrency.symbol +
                                  " " +
                                  _vm.unpaid_consume.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.deb_consume")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.defaultCurrency.symbol +
                                  " " +
                                  _vm.total_deb.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c("div", { staticClass: "tabs mb-8 pb-3 grid col-span-12" }, [
              _c("div", { staticClass: "border-b tab" }, [
                _c("div", { staticClass: "relative" }, [
                  _c("input", {
                    staticClass:
                      "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                    attrs: { type: "checkbox" },
                  }),
                  _vm._v(" "),
                  _c(
                    "header",
                    {
                      staticClass:
                        "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-gray-500 uppercase sw-section-title",
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("pbx_services.pbxware_info")) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                "aria-hidden": "true",
                                "data-reactid": "266",
                                fill: "none",
                                height: "24",
                                stroke: "#606F7B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                viewbox: "0 0 24 24",
                                width: "24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("polyline", {
                                attrs: { points: "6 9 12 15 18 9" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "tab-content-slide" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                      },
                      [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.$t("pbx_services.act_extensions")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          }),
                          _vm.package.extensions === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.yes")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.package.extensions != 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.not")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p"),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.$t("pbx_services.act_did")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          }),
                          _vm.package.did === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.yes")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.package.did != 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.not")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p"),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.$t("pbx_services.act_call_rating")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          }),
                          _vm.package.call_ratings === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.yes")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.package.call_ratings != 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.not")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p"),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.$t("pbx_services.fixed_server")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass:
                              "text-sm font-bold leading-5 text-black non-italic",
                          }),
                          _vm.package.modify_server === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "COMPLETED"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.yes")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.package.modify_server != 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).bgColor,
                                        color:
                                          _vm.$utils.getBadgeStatusColor(
                                            "OVERDUE"
                                          ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("general.not")) +
                                          "\n        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.package.extensions || _vm.package.did
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                          },
                          [
                            _vm.package.extensions
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("pbx_services.pro_ext_name")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.package.profile_extensions.name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.extensions
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("pbx_services.pro_ext_price")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              _vm.package.profile_extensions
                                                .rate
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.did
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("pbx_services.pro_ext_name2")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.package.profile_did.name) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.did
                              ? _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t(
                                              "pbx_services.pro_ext_price2"
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm font-bold leading-5 text-black non-italic",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              _vm.package.profile_did.did_rate
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.package.call_ratings
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1",
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t("pbx_services.int_dialing_code")
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.package.international_dialing_code
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$t("pbx_services.nac_dialing_code")
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-bold leading-5 text-black non-italic",
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.package.national_dialing_code
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.package.call_ratings
                              ? _c("div", [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.rate_per_min"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                _vm.package.rate_per_minutes
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.package.call_ratings
                              ? _c("div", [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "pbx_services.min_increments"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm font-bold leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.package.minutes_increments +
                                                " " +
                                                _vm.package.type_time_increment
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-5 pb-3" }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid gap-3 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                    attrs: { slot: "item-title", to: "" },
                    slot: "item-title",
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.selectedPbxService
                                  ? _vm.$utils.formatMoney(
                                      _vm.selectedPbxService.total,
                                      _vm.defaultCurrency
                                    )
                                  : ""
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("customers.recurring_charge")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("estimate-icon", {
                          staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
                    attrs: { slot: "item-title", to: "" },
                    slot: "item-title",
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.defaultCurrency.symbol +
                                  " " +
                                  _vm.total_consume.toFixed(2)
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.additional_month")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("contact-icon", {
                          staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                    attrs: { slot: "item-title", to: "" },
                    slot: "item-title",
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-lg font-semibold leading-tight text-black",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.selectedPbxService
                                    ? _vm.selectedPbxService.renewal_date
                                    : ""
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pbx_services.renewal_date")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("invoice-icon", {
                          staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.getStatusPayment == "prepaid"
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                        attrs: { slot: "item-title", to: "" },
                        slot: "item-title",
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-sm font-semibold leading-tight text-black xl:text-2xl",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.selectedViewCustomer &&
                                      _vm.selectedViewCustomer.customer
                                      ? _vm.defaultCurrency.symbol +
                                          " " +
                                          _vm.selectedViewCustomer.customer.balance.toFixed(
                                            2
                                          )
                                      : ""
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("pbx_services.credit")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("dollar-icon", {
                              staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "relative flex flex-wrap bg-gray-200 mt-4 rouded-lg p-4",
              },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/2 md:pr-2",
                    attrs: { label: _vm.$t("general.date_range") },
                  },
                  [
                    _c("date-range-picker", {
                      staticClass: "w-full rounded date-range-picker",
                      attrs: {
                        "time-picker": "",
                        "time-picker-increment": 5,
                        "time-picker24-hour": "",
                        "append-to-body": "",
                        ranges: _vm.rangesPresets,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "input",
                          fn: function (picker) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.formatDate(picker)) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                        {
                          key: "footer",
                          fn: function (data) {
                            return _c(
                              "div",
                              { staticClass: "slot flex mb-1 mr-1" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "ml-auto flex items-center" },
                                  [
                                    !data.in_selection
                                      ? _c(
                                          "sw-button",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              variant: "primary-outline",
                                              size: "sm",
                                            },
                                            on: { click: data.clickApply },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("general.apply")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          },
                        },
                      ]),
                      model: {
                        value: _vm.dateRange,
                        callback: function ($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.from") },
                  },
                  [
                    _c("sw-input", {
                      model: {
                        value: _vm.filters.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "from", $$v)
                        },
                        expression: "filters.from",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.to") },
                  },
                  [
                    _c("sw-input", {
                      model: {
                        value: _vm.filters.to,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "to", $$v)
                        },
                        expression: "filters.to",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.id") },
                  },
                  [
                    _c(
                      "sw-input",
                      {
                        model: {
                          value: _vm.filters.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "id", $$v)
                          },
                          expression: "filters.id",
                        },
                      },
                      [
                        _vm._v("ay\n                "),
                        _c("hashtag-icon", {
                          staticClass: "h-5 ml-1 text-gray-500",
                          attrs: { slot: "leftIcon" },
                          slot: "leftIcon",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.status") },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: [],
                        searchable: false,
                        placeholder: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function () {
                            return [
                              _c("p", [_vm._v(_vm._s(_vm.labelStatusCrp))]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "beforeList",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex flex-wrap" },
                                _vm._l(
                                  _vm.cdrStatusOptions,
                                  function (item, index) {
                                    return _c("sw-checkbox", {
                                      key: index,
                                      staticClass:
                                        "w-full p-1 px-4 hover:bg-gray-100",
                                      attrs: {
                                        variant: item.color,
                                        label: item.label,
                                        value: item.value,
                                      },
                                      on: { change: _vm.changeStatus },
                                      model: {
                                        value: _vm.filters.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "status", $$v)
                                        },
                                        expression: "filters.status",
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.cdr_type") },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: _vm.dcrTypeOptions,
                        "group-select": false,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("general.cdr_type"),
                        "allow-empty": false,
                        "track-by": "value",
                        label: "name",
                      },
                      model: {
                        value: _vm.filters.cdrType,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "cdrType", $$v)
                        },
                        expression: "filters.cdrType",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.cdr_category") },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: _vm.cdrCategoryOptions,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("general.cdr_category"),
                        "allow-empty": false,
                        "track-by": "value",
                        label: "name",
                      },
                      model: {
                        value: _vm.filters.type_custom,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "type_custom", $$v)
                        },
                        expression: "filters.type_custom",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-2 md:w-1/4 md:pr-2",
                    attrs: { label: _vm.$t("general.paid") },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: _vm.paidOptions,
                        "group-select": false,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t("general.paid"),
                        "allow-empty": false,
                        "track-by": "value",
                        label: "name",
                      },
                      model: {
                        value: _vm.filters.paid,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "paid", $$v)
                        },
                        expression: "filters.paid",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "absolute text-sm leading-snug text-black cursor-pointer",
                    staticStyle: { top: "10px", right: "15px" },
                    on: { click: _vm.clearFilter },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("general.clear_all")) + "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-wrap items-end justify-end mt-3",
                  },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "md:mx-1",
                        attrs: {
                          loading: _vm.searchLoading,
                          disabled: _vm.searchLoading,
                          variant: "primary-outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.searchMetho()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.search")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full flex justify-between mt-3 items-end" },
              [
                _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-2 md:pr-2",
                        attrs: { label: _vm.$t("general.per_page") },
                      },
                      [
                        _c("sw-select", {
                          attrs: {
                            options: _vm.pagesOptions,
                            "group-select": false,
                            "allow-empty": false,
                          },
                          model: {
                            value: _vm.limit,
                            callback: function ($$v) {
                              _vm.limit = $$v
                            },
                            expression: "limit",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "download-csv",
                      {
                        ref: "downloadCsv",
                        attrs: {
                          data: _vm.cdrListExportCsv,
                          fields: _vm.csvFields,
                          name: `Service_${this.selectedPbxService.pbx_services_number}-${this.dateNow}.csv`,
                        },
                      },
                      [_c("p")]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        attrs: {
                          loading: _vm.exportexportAllFiltersCsvLoading,
                          disabled: _vm.exportexportAllFiltersCsvLoading,
                          variant: _vm.exportexportAllFiltersCsvLoading
                            ? "success"
                            : "primary",
                        },
                        on: { click: _vm.exportAllFiltersCsv },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.export")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "relative" },
              [
                _vm.showTableCallHistory
                  ? _c(
                      "sw-table-component",
                      {
                        ref: "cdr_table",
                        staticClass: "w-full",
                        attrs: {
                          "show-filter": false,
                          data: _vm.fetchCallHistoryData,
                          "table-class": "table",
                        },
                      },
                      [
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$t("corePbx.dashboard.from"),
                            show: "from",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.to"),
                            show: "to",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.date"),
                            show: "formatted_start_date",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("pbx_services.type"),
                            show: "type",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c("span", [_vm._v("Type")]),
                                    _vm._v(" "),
                                    row.type == 0
                                      ? _c("div", [_vm._v("Inbound")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.type == 1
                                      ? _c("div", [_vm._v("Outbound")])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2024251483
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.status"),
                            show: "status",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.unique_id"),
                            show: "unique_id",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.totald"),
                            show: "formatted_duration",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("corePbx.dashboard.totalr"),
                            show: "formatted_round_duration",
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: true,
                            label: _vm.$tc("pbx_services.amount"),
                            show: "cost",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$tc("pbx_services.amount"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    row.billed_at == null
                                      ? _c("div", [_vm._v("Pending")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.billed_at != null
                                      ? _c("div", [
                                          row.exclusive_seconds == 0
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                    0\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.exclusive_seconds != 0
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.getFormattedExcusiveCost
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3777239490
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "block mt-5 mb-10 table-foot lg:justify-between lg:flex lg:items-start",
                  },
                  [
                    _c("div", { staticClass: "w-full lg:w-1/2" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded table-total lg:mt-0",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pbx_services.total_cdr")) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(this.callHistoryCount)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pbx_services.total_time")) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                              },
                              [_c("span", [_vm._v(_vm._s(this.billed_time))])]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t("pbx_services.total_cost")) +
                                    ":\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center text-lg uppercase text-primary-400",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " $ " +
                                      _vm._s(this.callHistoryTotal.toFixed(2))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-grey-darkest" }, [
      _c("div", { staticClass: "flex base-tabs" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }