<template>
  <base-page v-if="isSuperAdmin" class="items">
    <!-- Page Header -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="/admin/dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="/admin/users" :title="$tc('users.user', 2)" />
        <sw-breadcrumb-item
          to=""
          :title="name_user"
          active
          @click.native="$router.go()"
        />
      </sw-breadcrumb>

      <div class="flex flex-wrap items-center justify-end">
        <sw-button
          tag-name="router-link"
          :to="`/admin/users`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
        >
          {{ $t('general.go_back') }}
        </sw-button>
        <sw-button
          tag-name="router-link"
          :to="`/admin/users/${userData.id}/edit`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
          v-if="permissionModule.update"
        >
          <pencil-icon class="h-5 mr-3 text-gray-600" />
          {{ $t('general.edit') }}
        </sw-button>
        <sw-dropdown v-if="permissionModule.update">
          <sw-button
            slot="activator"
            variant="primary"
            class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          >
            <dots-horizontal-icon class="h-5 -ml-1 -mr-1" />
          </sw-button>

          <sw-dropdown-item
            tag-name="router-link"
            :to="`/admin/users/${idUser}/permissions`"
            v-if="permissionModule.update"
          >
            <lock-closed-icon class="h-5 mr-3 text-gray-600" />
            {{ $t('roles.permissions') }}
          </sw-dropdown-item>
        </sw-dropdown>
      </div>
    </div>

    <sw-card>
      <base-loader v-if="loadding" />
      <div class="col-span-12">
        <p class="text-gray-500 uppercase sw-section-title">
          {{ $t('corePbx.custom_did_groups.basic_info') }}
        </p>

        <div class="grid grid-cols-1 gap-4 mt-5 md:grid-cols-3">
          <div
            class="grid grid-cols-1 gap-4 mt-5 md:grid-cols-2 sm:grid-cols-1 col-span-2"
          >
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.name') }}
              </p>
              <p class="text-sm font-bold leading-5 text-black non-italic">
                {{ userData.name }}
              </p>
            </div>
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.email') }}
              </p>
              <p class="text-sm font-bold leading-5 text-black non-italic">
                {{ userData.email }}
              </p>
            </div>
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.phone') }}
              </p>
              <div v-if="userData.phone">
                <p class="text-sm font-bold leading-5 text-black non-italic">
                  {{ userData.phone }}
                </p>
              </div>
              <div v-else>
                <p
                  class="text-sm font-bold leading-5 text-black non-italic mt-2"
                >
                  N/A
                </p>
              </div>
            </div>
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('roles.role') }}
              </p>
              <p class="text-sm font-bold leading-5 text-black non-italic">
                {{ userData.role2 }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 mt-5 col-span-1">
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.departaments') }}
              </p>
              <div
                v-if="userData.item_groups && userData.item_groups.length > 0"
              >
                <p
                  v-for="(dep, indexDep) in userData.item_groups"
                  :key="indexDep"
                  class="text-sm font-bold leading-5 text-black non-italic mt-2"
                >
                  {{ dep.name }}
                </p>
              </div>
              <div v-else>
                <p
                  class="text-sm font-bold leading-5 text-black non-italic mt-2"
                >
                  N/A
                </p>
              </div>
            </div>
          </div>
        </div>

        <sw-divider class="my-8" />

        <div v-if="permissionModule.accessEstimates">
          <table-estimates
            v-if="userData.id"
            :userId="userData.id"
            :readEstimates="permissionModule.readEstimates"
          />
        </div>
        <div v-if="permissionModule.accessTickets">
          <table-tickets
            v-if="userData.id"
            :userId="userData.id"
            :readTickets="permissionModule.readTickets"
          />
        </div>
      </div>
    </sw-card>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  PencilIcon,
  ArrowLeftIcon,
  LockClosedIcon,
} from '@vue-hero-icons/solid'
import { DotsHorizontalIcon } from '@vue-hero-icons/outline'
import tableEstimates from './TableEstimates.vue'
import tableTickets from './TableTickets.vue'

export default {
  components: {
    PencilIcon,
    ArrowLeftIcon,
    LockClosedIcon,
    DotsHorizontalIcon,
    tableEstimates,
    tableTickets,
  },
  data() {
    return {
      loadding: false,

      userData: {},
      permissionModule: {
        create: false,
        read: false,
        delete: false,
        update: false,
        readTickets: false,
        accessTickets: false,
        readEstimates: false,
        accessEstimates: false,
      },
      name_user: '',
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),

    isSuperAdmin() {
      return this.currentUser.role == 'super admin'
    },

    idUser() {
      return this.$route.params.id
    },
  },
  beforeCreate() {
 
  },
  async created() {
   await this.permissionsUserModule()
    this.loadUserData()

    console.log(this.permissionModule)
  },
  methods: {
    ...mapActions('users', ['showUserId']),
    ...mapActions('user', ['getUserModules']),

    /**
     * Load user data based on the provided user ID.
     * Fetches user data, updates the component state, and handles loading state.
     *
     * @return {Promise<void>}
     */
    async loadUserData() {
      try {
        // Set loading state to true
        this.loading = true

        // Fetch user data based on the user ID
        const res = await this.showUserId(this.idUser)

        // Update component state with fetched user data
        this.name_user = res.data.data.name
        this.userData = res.data?.data
        console.log(this.userData)
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error('Error loading user data:', error)
      } finally {
        // Set loading state to false
        this.loading = false
      }
    },
    /**
     * Check and set user permissions for various modules.
     * Redirects the user if they lack access permissions and sets module-specific permissions.
     *
     * @return {Promise<void>}
     */
    async permissionsUserModule() {
      // Define the data object for the 'users' module
      const data = { module: 'users' }

      // Fetch user permissions for the 'users' module
      const permissions = await this.getUserModules(data)

      // Validate if the user has permission to access the module
      if (!permissions.super_admin) {
        if (!permissions.exist) {
          this.$router.push('/admin/dashboard')
          return
        } else {
          const modulePermissions = permissions.permissions[0]
          if (!modulePermissions || modulePermissions.access === 0) {
            this.$router.push('/admin/dashboard')
            return
          }
        }
      }

      // Set permissions for create, read, update, delete based on user role
      if (permissions.super_admin) {
        this.permissionModule = {
          create: true,
          update: true,
          delete: true,
          read: true,
        }
      } else if (permissions.exist) {
        const modulePermissions = permissions.permissions[0]
        this.permissionModule.create = modulePermissions.create === 1
        this.permissionModule.update = modulePermissions.update === 1
        this.permissionModule.delete = modulePermissions.delete === 1
        this.permissionModule.read = modulePermissions.read === 1
      }

      // Define the data object for the 'estimates' module
      const dataEstimates = { module: 'estimates' }

      // Fetch user permissions for the 'estimates' module
      const permissionsEstimates = await this.getUserModules(dataEstimates)

      // Set permissions for read and access based on user role for 'estimates'
      if (permissionsEstimates.super_admin) {
        this.permissionModule.readEstimates = true
        this.permissionModule.accessEstimates = true
      } else if (permissionsEstimates.exist) {
        const modulePermissions = permissionsEstimates.permissions[0]
        if (!modulePermissions) {
          this.permissionModule.readEstimates = false
          this.permissionModule.accessEstimates = false
        } else {
          this.permissionModule.accessEstimates = modulePermissions.access === 1
          this.permissionModule.readEstimates = modulePermissions.read === 1
        }
      }

      // Define the data object for the 'tickets' module
      const dataTickets = { module: 'tickets' }

      // Fetch user permissions for the 'tickets' module
      const permissionsTickets = await this.getUserModules(dataTickets)

      console.log(permissionsTickets)
      // Set permissions for read and access based on user role for 'tickets'
      if (permissionsTickets.super_admin) {
        this.permissionModule.readTickets = true
        this.permissionModule.accessTickets = true
      } else if (permissionsTickets.exist) {
        const modulePermissions = permissionsTickets.permissions[0]
        if (!modulePermissions) {
          this.permissionModule.readTickets = false
          this.permissionModule.accessTickets = false
        } else {
          this.permissionModule.accessTickets = modulePermissions.access === 1
          this.permissionModule.readTickets = modulePermissions.read === 1
        }
      }
      console.log("permission moduele")
      console.log(this.permissionModule)
    },
  },
}
</script>

<style scoped>
</style>