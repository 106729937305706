var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c("div", { staticClass: "flex flex-col h-full" }, [
      _vm.props.media.typeFile == "pdf" ||
      _vm.props.media.typeFile == "application/pdf"
        ? _c("iframe", {
            staticStyle: { height: "70vh" },
            attrs: {
              src: _vm.props.media.base64,
              width: "100%",
              height: "100%",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ifImage
        ? _c("img", {
            staticClass: "m-2 rounded-md",
            staticStyle: {
              "max-height": "80% !important",
              height: "90vh",
              animation: "fadeIn 2s ease",
            },
            attrs: { src: _vm.props.media.base64 },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.ifImage && _vm.props.media.typeFile != "pdf"
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-wrap justify-center items-center text-center p-3",
              staticStyle: { "max-height": "90vh" },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.imageExt(_vm.props.media.typeFile, _vm.props.media),
                  alt: "ext",
                },
              }),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "w-full text-xl font-bold text-primary mt-3" },
                [_vm._v(_vm._s(_vm.$t("expenses.no_preview")))]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }