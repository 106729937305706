var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.extension_prefix"),
                error: _vm.ExpensePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.extension_pbx_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.extension_pbx_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE")
                  },
                },
                model: {
                  value: _vm.expenses.extension_pbx_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "extension_pbx_prefix", $$v)
                  },
                  expression: "expenses.extension_pbx_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.extension_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "extension_prefix_general", $$v)
                    },
                    expression: "expenses.extension_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n                   " +
                      _vm._s(
                        _vm.$t(
                          "settings.customization.items.apply_general_prefix"
                        )
                      ) +
                      "                "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }