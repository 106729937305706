var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid gap-8 md:grid-cols-12" }, [
    _c("div", { staticClass: "col-span-8 mt-12 md:col-span-4" }, [
      _c(
        "div",
        { staticClass: "grid grid-cols-12" },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "col-span-12 md:col-span-8",
              attrs: {
                label: _vm.$t("reports.profit_loss.date_range"),
                error: _vm.dateRangeError,
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.dateRange,
                  "allow-empty": false,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeDateRange },
                model: {
                  value: _vm.selectedRange,
                  callback: function ($$v) {
                    _vm.selectedRange = $$v
                  },
                  expression: "selectedRange",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("reports.profit_loss.from_date"),
                error: _vm.fromDateError,
              },
            },
            [
              _c("base-date-picker", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.formData.from_date.$error,
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.from_date.$touch()
                  },
                },
                model: {
                  value: _vm.formData.from_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "from_date", $$v)
                  },
                  expression: "formData.from_date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-5 md:mt-0",
              attrs: {
                label: _vm.$t("reports.profit_loss.to_date"),
                error: _vm.toDateError,
              },
            },
            [
              _c("base-date-picker", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.formData.to_date.$error,
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.to_date.$touch()
                  },
                },
                model: {
                  value: _vm.formData.to_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "to_date", $$v)
                  },
                  expression: "formData.to_date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
        [
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("expenses.customer") } },
            [
              _c("sw-select", {
                ref: "baseSelect",
                staticClass: "mt-1",
                attrs: {
                  options: _vm.customers,
                  searchable: true,
                  "show-labels": true,
                  multiple: true,
                  placeholder: _vm.$t("customers.select_a_customer"),
                  label: "name",
                  "track-by": "id",
                },
                model: {
                  value: _vm.customersSelected,
                  callback: function ($$v) {
                    _vm.customersSelected = $$v
                  },
                  expression: "customersSelected",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.allow_invoice_form_pos
            ? _c(
                "div",
                { staticClass: "grid grid-cols-1 mt-2 md:gap-10" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-1",
                      attrs: { label: _vm.$t("core_pos.store") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.stores,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          multiple: true,
                          "track-by": "id",
                          label: "name",
                          tabindex: 1,
                        },
                        model: {
                          value: _vm.stores_selected,
                          callback: function ($$v) {
                            _vm.stores_selected = $$v
                          },
                          expression: "stores_selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-6 md:gap-10" },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span-3",
              attrs: { label: _vm.$t("customers.country") },
            },
            [
              _vm.country
                ? _c(
                    "span",
                    {
                      staticClass: "absolute text-gray-400 cursor-pointer",
                      staticStyle: {
                        top: "38px",
                        right: "5px",
                        "z-index": "999999",
                      },
                      on: {
                        click: function ($event) {
                          _vm.country = null
                          _vm.states = []
                          _vm.state = null
                        },
                      },
                    },
                    [_c("x-circle-icon", { staticClass: "h-5" })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("sw-select", {
                attrs: {
                  options: _vm.countries,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("general.select_country"),
                  label: "name",
                  "track-by": "id",
                },
                on: {
                  select: function ($event) {
                    return _vm.countrySelected($event)
                  },
                },
                model: {
                  value: _vm.country,
                  callback: function ($$v) {
                    _vm.country = $$v
                  },
                  expression: "country",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span-3",
              attrs: { label: _vm.$t("customers.state") },
            },
            [
              _vm.state
                ? _c(
                    "span",
                    {
                      staticClass: "absolute text-gray-400 cursor-pointer",
                      staticStyle: {
                        top: "38px",
                        right: "5px",
                        "z-index": "999999",
                      },
                      on: {
                        click: function ($event) {
                          _vm.state = null
                        },
                      },
                    },
                    [_c("x-circle-icon", { staticClass: "h-5" })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("sw-select", {
                attrs: {
                  options: _vm.states,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: _vm.$t("general.select_state"),
                  label: "name",
                  "track-by": "id",
                },
                on: {
                  select: function ($event) {
                    return _vm.stateSelected($event)
                  },
                },
                model: {
                  value: _vm.state,
                  callback: function ($$v) {
                    _vm.state = $$v
                  },
                  expression: "state",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-1 mt-6 md:gap-10" }, [
        _c("div", { staticClass: "flex my-8" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-checkbox", {
                staticClass: "absolute",
                attrs: { tabindex: "5" },
                model: {
                  value: _vm.includecredit,
                  callback: function ($$v) {
                    _vm.includecredit = $$v
                  },
                  expression: "includecredit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-0 md:mt-8" },
        [
          _c(
            "sw-button",
            {
              staticClass: "content-center hidden text-sm w-md md:flex",
              attrs: { variant: "primary-outline" },
              on: {
                click: function ($event) {
                  return _vm.getReports()
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("reports.update_report")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-span-8 mt-0 md:mt-12" }, [
      _c("iframe", {
        staticClass:
          "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
        attrs: { src: _vm.getReportUrl },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500",
          on: { click: _vm.viewReportsPDF },
        },
        [
          _c("document-text-icon"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-4" }, [
      _c(
        "p",
        { staticClass: "p-0 mb-1 text-base leading-snug text-black box-title" },
        [_vm._v("\n                Show Balance to debit\n              ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }