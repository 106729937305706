var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-64": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("tickets.email.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("tickets.email.title", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "actions" }, [
            _c(
              "div",
              { staticClass: "mr-3 hidden xl:block" },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.toggleListCustomers },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tickets.departaments.menu")) +
                        "\n          "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "relative table-container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.updateEmail.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span- mt-2 mb-2",
                attrs: { label: "Ticket BCC Email", error: _vm.emailError },
              },
              [
                _c("sw-input", {
                  staticStyle: { "max-width": "30%" },
                  attrs: {
                    invalid: _vm.$v.formData.email.$error,
                    type: "text",
                    name: "email",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.email.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "email", $$v)
                    },
                    expression: "formData.email",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span- mt-2 mb-2",
                attrs: { label: "Ticket Prefix", error: _vm.ticketError },
              },
              [
                _c("sw-input", {
                  staticStyle: { "max-width": "30%" },
                  attrs: {
                    invalid: _vm.$v.formData.ticket_prefix.$error,
                    type: "text",
                    name: "prefix",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.ticket_prefix.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.ticket_prefix,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "ticket_prefix", $$v)
                    },
                    expression: "formData.ticket_prefix",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tabs mb-5 grid col-span-12" }, [
              _c("div", { staticClass: "border-b tab" }, [
                _c(
                  "div",
                  { staticClass: "border-l-2 border-transparent relative" },
                  [
                    _c(
                      "header",
                      {
                        staticClass:
                          "col-span-5 flex justify-between items-center p-3 pl-0 pr-8 select-none tab-label",
                        attrs: { for: "chck1" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-grey-darkest font-thin text-xl",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("tickets.template")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mb-3" }),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "pl-0 pr-8 pb-5 text-grey-darkest" },
                        [
                          _c("ul", { staticClass: "pl-0" }, [
                            _c(
                              "li",
                              { staticClass: "pb-2" },
                              [
                                _c(
                                  "sw-tabs",
                                  { attrs: { "active-tab": _vm.activeTab } },
                                  [
                                    _c(
                                      "sw-tab-item",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "tickets.creation_client"
                                          ),
                                        },
                                      },
                                      [
                                        _c("base-custom-input", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            fields: _vm.InvoiceMailFields,
                                          },
                                          model: {
                                            value:
                                              _vm.emails
                                                .ticket_creatio_customer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.emails,
                                                "ticket_creatio_customer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "emails.ticket_creatio_customer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-tab-item",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "tickets.creation_user"
                                          ),
                                        },
                                      },
                                      [
                                        _c("base-custom-input", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            fields: _vm.InvoiceMailFields,
                                          },
                                          model: {
                                            value:
                                              _vm.emails.ticket_creatio_user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.emails,
                                                "ticket_creatio_user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "emails.ticket_creatio_user",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-tab-item",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "tickets.update_customer"
                                          ),
                                        },
                                      },
                                      [
                                        _c("base-custom-input", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            fields: _vm.InvoiceMailFields,
                                          },
                                          model: {
                                            value:
                                              _vm.emails.ticket_update_customer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.emails,
                                                "ticket_update_customer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "emails.ticket_update_customer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-tab-item",
                                      {
                                        attrs: {
                                          title: _vm.$t("tickets.update_user"),
                                        },
                                      },
                                      [
                                        _c("base-custom-input", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            fields: _vm.InvoiceMailFields,
                                          },
                                          model: {
                                            value:
                                              _vm.emails.ticket_update_user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.emails,
                                                "ticket_update_user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "emails.ticket_update_user",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "relative flex items-center justify-between h-10 list-none border-b-2 border-gray-200 border-solid",
      },
      [_c("p", { staticClass: "text-sm" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }