var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitItem.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("items.item", 2),
                    to: "/admin/items",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "items.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("items.edit_item"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("items.new_item"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 text-sm hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary-outline",
                      type: "button",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      size: "lg",
                      tabindex: 10,
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("items.update_item")
                            : _vm.$t("items.save_item")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "sw-card",
          [
            _c(
              "div",
              { staticClass: "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: {
                      label: _vm.$t("items.name"),
                      error: _vm.nameError,
                      variant: "vertical",
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      staticClass: "mt-2",
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        tabindex: 1,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: {
                      label: _vm.$t("items.price"),
                      error: _vm.priceError,
                      variant: "vertical",
                      required: "",
                    },
                  },
                  [
                    _c("sw-money", {
                      staticClass:
                        "relative w-full focus:border focus:border-solid focus:border-primary-500",
                      attrs: {
                        invalid: _vm.$v.formData.price.$error,
                        currency: _vm.defaultCurrencyForInput,
                        tabindex: 2,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.price.$touch()
                        },
                      },
                      model: {
                        value: _vm.price,
                        callback: function ($$v) {
                          _vm.price = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: { label: _vm.$t("items.unit") },
                  },
                  [
                    _c(
                      "sw-select",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.itemUnits,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("items.select_a_unit"),
                          label: "name",
                          tabindex: 3,
                        },
                        model: {
                          value: _vm.formData.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "unit", $$v)
                          },
                          expression: "formData.unit",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-center w-full px-6 py-3 text-base bg-gray-200 cursor-pointer text-primary-400",
                            attrs: { slot: "afterList" },
                            on: { click: _vm.addItemUnit },
                            slot: "afterList",
                          },
                          [
                            _c("shopping-cart-icon", {
                              staticClass:
                                "h-5 mr-2 -ml-2 text-center text-primary-400",
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "ml-2 text-sm leading-none text-primary-400",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.items.add_item_unit"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: { label: _vm.$t("items.items_groups") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.getItemGroups,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        "track-by": "item_group_id",
                        label: "item_group_name",
                        tabindex: 4,
                      },
                      model: {
                        value: _vm.formData.item_groups,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "item_groups", $$v)
                        },
                        expression: "formData.item_groups",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: { label: _vm.$t("items.items_category_main") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.getItemCategories,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: false,
                        "track-by": "id",
                        label: "name",
                        tabindex: 4,
                      },
                      model: {
                        value: _vm.formData.item_category_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "item_category_id", $$v)
                        },
                        expression: "formData.item_category_id",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4 pr-3",
                    attrs: { label: _vm.$t("items.items_category") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.getItemCategories,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        "track-by": "id",
                        label: "name",
                        tabindex: 4,
                      },
                      model: {
                        value: _vm.formData.item_categories,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "item_categories", $$v)
                        },
                        expression: "formData.item_categories",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.noTaxable
                  ? _c("div", { staticClass: "flex my-8" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            attrs: { tabindex: "5" },
                            on: { change: _vm.setTaxable },
                            model: {
                              value: _vm.formData.no_taxable,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "no_taxable", $$v)
                              },
                              expression: "formData.no_taxable",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("items.no_taxable")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 m-0 text-xs leading-4 text-gray-500",
                            staticStyle: { "max-width": "480px" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("items.no_tax_description")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTaxable
                  ? _c("div", { staticClass: "flex my-8 mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-20px" },
                            attrs: { tabindex: 6 },
                            on: { change: _vm.setTax },
                            model: {
                              value: _vm.formData.allow_taxes,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "allow_taxes", $$v)
                              },
                              expression: "formData.allow_taxes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("items.allow_taxes")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTaxPerItem
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4 pr-3",
                        attrs: { label: _vm.$t("items.taxes") },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.getTaxTypes,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            multiple: true,
                            "track-by": "tax_type_id",
                            label: "tax_name",
                            tabindex: 7,
                          },
                          model: {
                            value: _vm.formData.taxes,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "taxes", $$v)
                            },
                            expression: "formData.taxes",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.retention_platform_active
                  ? _c(
                      "div",
                      { staticClass: "flex my-8 mb-4" },
                      [
                        _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              on: { change: _vm.getRetention },
                              model: {
                                value: _vm.formData.retentions_bool,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "retentions_bool", $$v)
                                },
                                expression: "formData.retentions_bool",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("items.allow_retentions")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.retentions_bool
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4 pr-3",
                        attrs: { label: _vm.$t("items.retentions") },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.retentionsOptions,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            "track-by": "id",
                            label: "label",
                            tabindex: 7,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (option) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        option.option
                                          ? _c(
                                              "div",
                                              { staticClass: "text-sm" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      option.option.concept +
                                                        " " +
                                                        option.option
                                                          .percentage +
                                                        "%"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        option.option
                                          ? _c(
                                              "div",
                                              { staticClass: "text-sm" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      option.option.concept +
                                                        " " +
                                                        option.option
                                                          .percentage +
                                                        "%"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3742224630
                          ),
                          model: {
                            value: _vm.formData.retentions,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "retentions", $$v)
                            },
                            expression: "formData.retentions",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPosAvailable
              ? _c(
                  "div",
                  [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("items.section_pos")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex my-8 mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-20px" },
                            attrs: { tabindex: 6 },
                            model: {
                              value: _vm.formData.allow_pos,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "allow_pos", $$v)
                              },
                              expression: "formData.allow_pos",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("items.allow_pos")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("sw-divider"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5",
                      },
                      [
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4 pr-3",
                            attrs: { label: _vm.$t("core_pos.store") },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.store_options,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                multiple: true,
                                "track-by": "id",
                                label: "name",
                                tabindex: 1,
                              },
                              model: {
                                value: _vm.formData.item_store,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "item_store", $$v)
                                },
                                expression: "formData.item_store",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-4 pr-3",
                            attrs: {
                              label: _vm.$t("core_pos.core_pos_section"),
                            },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.section_options,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                multiple: true,
                                "track-by": "id",
                                label: "name",
                                tabindex: 1,
                              },
                              model: {
                                value: _vm.formData.item_section,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "item_section", $$v)
                                },
                                expression: "formData.item_section",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAvalaraAvailable
              ? _c(
                  "div",
                  [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1 mt-sm-4",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("items.section_avalar")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("sw-divider"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5",
                      },
                      [
                        _c("div", { staticClass: "flex mt-4 mb-4" }, [
                          _c(
                            "div",
                            { staticClass: "relative w-12" },
                            [
                              _c("sw-switch", {
                                staticClass: "absolute",
                                staticStyle: { top: "-20px" },
                                model: {
                                  value: _vm.formData.avalara_bool,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "avalara_bool", $$v)
                                  },
                                  expression: "formData.avalara_bool",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-4" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "p-0 mb-1 text-base leading-snug text-black box-title",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("items.add_avalara")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.isAddAvalara
                          ? _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-4 pr-3",
                                attrs: {
                                  label: _vm.$t("items.item_avalara_type"),
                                },
                              },
                              [
                                _c("sw-select", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    options: _vm.avalara_types,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t("items.select_a_type"),
                                    label: "name",
                                    tabindex: 9,
                                    disabled: _vm.isEdit && _vm.isItemUsed,
                                  },
                                  on: { select: _vm.transactionSeleted },
                                  model: {
                                    value: _vm.avalara_type_selected,
                                    callback: function ($$v) {
                                      _vm.avalara_type_selected = $$v
                                    },
                                    expression: "avalara_type_selected",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddAvalara
                          ? _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-4 pr-3",
                                attrs: {
                                  label: _vm.$t("items.avalara_service_type"),
                                },
                              },
                              [
                                _c("sw-select", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    options: _vm.avalara_service_types,
                                    searchable: true,
                                    "show-labels": false,
                                    "allow-empty": true,
                                    placeholder: _vm.$t("items.select_a_type"),
                                    "track-by": "id",
                                    label: "service_type_name_label",
                                    tabindex: 11,
                                    disabled: _vm.isEdit && _vm.isItemUsed,
                                  },
                                  on: { select: _vm.serviceSeleted },
                                  model: {
                                    value: _vm.formData.avalara_service_types,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "avalara_service_types",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.avalara_service_types",
                                  },
                                }),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _vm.formData.avalara_payment_type != null
                                  ? _c("div", [
                                      _vm.formData.avalara_payment_type.value ==
                                      "TAXABLE_AMOUNT"
                                        ? _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "items.this_services_es_calculated_as_taxable_amount_and_if_apply_to_cdrs_app_rates_services_price_and_additional_charges"
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formData.avalara_payment_type.value ==
                                      "LINES"
                                        ? _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "items.this_services_is_calculated_as_line_and_is_apply_to_Did_extension"
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddAvalara
                          ? _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-4 pr-3",
                                attrs: {
                                  label: _vm.$t("items.avalara_sale_type"),
                                  required: "",
                                },
                              },
                              [
                                _c("sw-select", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    options: _vm.avalara_sale_types,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "items.avalara_sale_type"
                                    ),
                                    label: "name",
                                    tabindex: 9,
                                    disabled: _vm.isEdit && _vm.isItemUsed,
                                  },
                                  model: {
                                    value: _vm.formData.avalara_sale_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "avalara_sale_type",
                                        $$v
                                      )
                                    },
                                    expression: "formData.avalara_sale_type",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddAvalara
                          ? _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-4 pr-3",
                                attrs: {
                                  label: _vm.$t("items.avalara_discount_type"),
                                  required: "",
                                },
                              },
                              [
                                _c("sw-select", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    options: _vm.avalara_discount_types,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "items.avalara_discount_type"
                                    ),
                                    label: "name",
                                    tabindex: 9,
                                    disabled: _vm.isEdit && _vm.isItemUsed,
                                  },
                                  model: {
                                    value: _vm.formData.avalara_discount_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "avalara_discount_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.avalara_discount_type",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAddAvalara
                          ? _c("div", { staticClass: "flex my-8 mb-4" }, [
                              _c(
                                "div",
                                { staticClass: "relative w-12" },
                                [
                                  _c("sw-switch", {
                                    staticClass: "absolute",
                                    staticStyle: { top: "-20px" },
                                    model: {
                                      value: _vm.formData.tax_inclusion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "tax_inclusion",
                                          $$v
                                        )
                                      },
                                      expression: "formData.tax_inclusion",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 mb-1 text-base leading-snug text-black box-title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "items.avalara_is_tax_inclusion"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 m-0 text-xs leading-4 text-red-500",
                                    staticStyle: { "max-width": "480px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "items.message_tax_description"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t("items.description"),
                      error: _vm.descriptionError,
                    },
                  },
                  [
                    _c("sw-textarea", {
                      attrs: { rows: "2", name: "description", tabindex: 8 },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.description.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "description", $$v)
                        },
                        expression: "formData.description",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-6 mb-4",
                    attrs: { label: _vm.$tc("items.image") },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                        attrs: { id: "logo-box" },
                      },
                      [
                        _vm.previewPicture != "0"
                          ? _c("img", {
                              staticClass: "absolute opacity-100 preview-logo",
                              staticStyle: {
                                "max-height": "80%",
                                animation: "fadeIn 2s ease",
                              },
                              attrs: { src: _vm.previewPicture },
                            })
                          : _c(
                              "div",
                              { staticClass: "flex flex-col items-center" },
                              [
                                _c("cloud-upload-icon", {
                                  staticClass:
                                    "h-5 mb-2 text-xl leading-6 text-gray-400",
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-xs leading-4 text-center text-gray-400",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Drag a file here or\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "cursor-pointer text-primary-500",
                                        attrs: { id: "pick-avatar" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  browse\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                to choose a file\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("sw-avatar", {
                      attrs: {
                        trigger: "#logo-box",
                        "preview-avatar": _vm.previewPicture,
                      },
                      on: {
                        changed: _vm.onChange,
                        uploadHandler: _vm.onUploadHandler,
                        handleUploadError: _vm.onHandleUploadError,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("cloud-upload-icon", {
                                staticClass:
                                  "h-5 mb-2 text-xl leading-6 text-gray-400",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary-outline",
                  type: "button",
                  size: "lg",
                },
                on: {
                  click: function ($event) {
                    return _vm.cancelForm()
                  },
                },
              },
              [
                !_vm.isLoading
                  ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass:
                  "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  size: "lg",
                  tabindex: 10,
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("items.update_item")
                        : _vm.$t("items.save_item")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }