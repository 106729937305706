var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "col-span-12" }, [
      _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("customers.basic_info")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
        },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.customer_number")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.customcode
                        ? _vm.loggedInCustomer.customer.customcode
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.display_name")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.name
                        ? _vm.loggedInCustomer.customer.name
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.primary_contact_name")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.contact_name
                        ? _vm.loggedInCustomer.customer.contact_name
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
        },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.email")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.email
                        ? _vm.loggedInCustomer.customer.email
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("wizard.currency")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.currency
                        ? `${_vm.loggedInCustomer.customer.currency.code} (${_vm.loggedInCustomer.customer.currency.symbol})`
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.phone_number")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.phone
                        ? _vm.loggedInCustomer.customer.phone
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.type_customer")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
                staticStyle: { "text-transform": "capitalize" },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.status_payment
                        ? _vm.loggedInCustomer.customer.status_payment
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.website")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.website
                        ? _vm.loggedInCustomer.customer.website
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.security_pin")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-bold leading-5 text-black non-italic",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.loggedInCustomer.customer &&
                        _vm.loggedInCustomer.customer.security_pin
                        ? _vm.loggedInCustomer.customer.security_pin
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.getFormattedShippingAddress.length ||
      _vm.getFormattedBillingAddress.length
        ? _c(
            "p",
            { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("customers.address")) + "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2",
        },
        [
          _vm.getFormattedBillingAddress.length
            ? _c("div", { staticClass: "mt-5" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.billing_address")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                  domProps: {
                    innerHTML: _vm._s(_vm.getFormattedBillingAddress),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.getFormattedShippingAddress.length
            ? _c("div", { staticClass: "mt-5" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.shipping_address")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                  domProps: {
                    innerHTML: _vm._s(_vm.getFormattedShippingAddress),
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.getCustomField.length > 0
        ? _c(
            "p",
            { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.custom_fields.title")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
        },
        _vm._l(_vm.getCustomField, function (field, index) {
          return _c(
            "div",
            {
              key: index,
              attrs: { required: field.is_required ? true : false },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(field.custom_field.label) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              field.type === "Switch"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      field.defaultAnswer === 1
                        ? _c("span", [_vm._v(" Yes ")])
                        : _c("span", [_vm._v(" No ")]),
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(field.defaultAnswer) +
                          "\n        "
                      ),
                    ]
                  ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }