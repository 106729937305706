var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: {
            title: _vm.$t("corePbx.custom_did_groups.view_custom_did_groups"),
          },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.corePbx"),
                  to: "/admin/corePBX",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.custom_did_groups.title"),
                  to: "/admin/corePBX/billing-templates/custom-did-groups",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.customDidGroup ? _vm.customDidGroup.name : "",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/billing-templates/custom-did-groups`,
                    variant: "primary-outline",
                  },
                },
                [
                  _c("arrow-left-icon", { staticClass: "h-4 mr-2 -ml-1" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.back")) + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/billing-templates/custom-did-groups/${_vm.$route.params.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: { slot: "activator", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeGroup(_vm.$route.params.id)
                    },
                  },
                  slot: "activator",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.delete")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("corePbx.custom_did_groups.basic_info")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.custom_did_groups.name")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.customDidGroup ? _vm.customDidGroup.name : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.custom_did_groups.status")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                  }),
                  _vm.customDidGroup
                    ? _c("div", [
                        _vm.customDidGroup.status == "Active"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.active")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.customDidGroup.status != "Active"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.inactive")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("corePbx.custom_did_groups.type")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm font-bold leading-5 text-black non-italic",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.customDidGroup ? _vm.customDidGroup.type : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mt-5" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("general.description")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.customDidGroup ? _vm.customDidGroup.description : ""
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.customDidGroup
                            ? _vm.customDidGroup.description
                            : ""
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "my-8" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("corePbx.custom_did_groups.custom_dids")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("sw-empty-table-placeholder", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyTable,
                  expression: "showEmptyTable",
                },
              ],
              attrs: {
                title: _vm.$t("corePbx.custom_did_groups.no_custom_dids"),
                description: _vm.$t(
                  "corePbx.custom_did_groups.list_of_custom_dids"
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showEmptyTable,
                    expression: "!showEmptyTable",
                  },
                ],
                staticClass: "mt-5",
              },
              _vm._l(_vm.getSortedObject, function (category, key) {
                return _c("div", { key: key }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                    },
                    [_vm._v("\n            " + _vm._s(key) + "\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    { staticClass: "w-full text-center item-table mb-5 mt-2" },
                    [
                      _c("colgroup", [
                        _c("col", {}),
                        _vm._v(" "),
                        _c("col", {}),
                        _vm._v(" "),
                        _c("col", {}),
                      ]),
                      _vm._v(" "),
                      _c(
                        "thead",
                        {
                          staticClass:
                            "bg-white border border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", {}, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("corePbx.custom_did_groups.prefix")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("corePbx.custom_did_groups.category")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("corePbx.custom_did_groups.price")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(category, function (did, index) {
                          return _c("tr", { staticClass: "border" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "px-5 py-4 text-left align-top border-b border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "items-center text-sm" },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(did.prefijo) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "px-5 py-4 text-center border-b border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "items-center text-sm" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(did.category_name) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "px-5 py-4 text-right align-top border-b border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "items-center text-sm" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.defaultCurrency.symbol +
                                              " " +
                                              did.rate_per_minute
                                          ) +
                                          "                \n                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }