var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "paypalButton" } },
    [
      _vm.loadindPayment ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "paypal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }