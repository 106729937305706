var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "relative h-full",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveData.apply(null, arguments)
        },
      },
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.mobile.mobile")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2 mt-4" },
            [
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$t("settings.mobile.logo") } },
                [
                  _vm.logoexist == true
                    ? _c("img", {
                        staticClass: "mt-2 logo",
                        attrs: { src: _vm.file_url, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-9", attrs: { id: "docs-box" } },
                    [
                      _c("input", {
                        staticClass: "form-control inputFile",
                        attrs: {
                          id: "uploadLogo",
                          type: "file",
                          accept: "image/png",
                        },
                        on: { change: _vm.onChangeLogo },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex flex-col items-center" },
                        [
                          _c("cloud-upload-icon", {
                            staticClass:
                              "h-5 mb-2 text-xl leading-6 text-gray-400",
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "uploadLogo" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-xs leading-4 text-center text-gray-400",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cursor-pointer text-primary-500",
                                    attrs: { id: "pick-avatar" },
                                  },
                                  [_vm._v("browse")]
                                ),
                                _vm._v(
                                  "\n                  to choose a file\n                "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$t("settings.mobile.color_palette") } },
                [
                  !_vm.isRequestOnGoing
                    ? _c("vue-tailwind-color-picker", {
                        ref: "colorPalette",
                        staticClass: "mt-3",
                        attrs: {
                          swatches: _vm.swatches,
                          "hide-swatches": false,
                        },
                        on: {
                          "update:swatches": function ($event) {
                            _vm.swatches = $event
                          },
                          change: _vm.changedColor,
                          addSwatch: _vm.swatchAdded,
                          deleteSwatch: _vm.swatchDeleted,
                        },
                        model: {
                          value: _vm.formData.color_palette,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "color_palette", $$v)
                          },
                          expression: "formData.color_palette",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-3 mt-8" },
            [
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.mobile.menu_vertical") } },
                [
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.services,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.vertical_menu, "services", $$v)
                      },
                      expression: "formData.vertical_menu.services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.pbx_services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.pbx_services,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.vertical_menu,
                          "pbx_services",
                          $$v
                        )
                      },
                      expression: "formData.vertical_menu.pbx_services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.invoices"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.invoices,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.vertical_menu, "invoices", $$v)
                      },
                      expression: "formData.vertical_menu.invoices",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.estimates"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.estimates,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.vertical_menu, "estimates", $$v)
                      },
                      expression: "formData.vertical_menu.estimates",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.payments"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.payments,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.vertical_menu, "payments", $$v)
                      },
                      expression: "formData.vertical_menu.payments",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.payment_accounts"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.payment_accounts,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.vertical_menu,
                          "payment_accounts",
                          $$v
                        )
                      },
                      expression: "formData.vertical_menu.payment_accounts",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.tickets"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.vertical_menu.tickets,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.vertical_menu, "tickets", $$v)
                      },
                      expression: "formData.vertical_menu.tickets",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: { label: _vm.$tc("settings.mobile.menu_horizontal") },
                },
                [
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.services,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.horizontal_menu, "services", $$v)
                      },
                      expression: "formData.horizontal_menu.services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.pbx_services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.pbx_services,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.horizontal_menu,
                          "pbx_services",
                          $$v
                        )
                      },
                      expression: "formData.horizontal_menu.pbx_services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.invoices"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.invoices,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.horizontal_menu, "invoices", $$v)
                      },
                      expression: "formData.horizontal_menu.invoices",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.estimates"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.estimates,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.horizontal_menu, "estimates", $$v)
                      },
                      expression: "formData.horizontal_menu.estimates",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.payments"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.payments,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.horizontal_menu, "payments", $$v)
                      },
                      expression: "formData.horizontal_menu.payments",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.payment_accounts"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.payment_accounts,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.horizontal_menu,
                          "payment_accounts",
                          $$v
                        )
                      },
                      expression: "formData.horizontal_menu.payment_accounts",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.tickets"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.horizontal_menu.tickets,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.horizontal_menu, "tickets", $$v)
                      },
                      expression: "formData.horizontal_menu.tickets",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.mobile.dashboard") } },
                [
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.services,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "services", $$v)
                      },
                      expression: "formData.dashboard.services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.pbx_services"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.pbx_services,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "pbx_services", $$v)
                      },
                      expression: "formData.dashboard.pbx_services",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.invoices"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.invoices,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "invoices", $$v)
                      },
                      expression: "formData.dashboard.invoices",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.estimates"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.estimates,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "estimates", $$v)
                      },
                      expression: "formData.dashboard.estimates",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.payments"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.payments,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "payments", $$v)
                      },
                      expression: "formData.dashboard.payments",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    attrs: {
                      label: _vm.$t("settings.mobile.tickets"),
                      variant: "primary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.formData.dashboard.tickets,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.dashboard, "tickets", $$v)
                      },
                      expression: "formData.dashboard.tickets",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-10",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tc("settings.account_settings.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }