var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "bg-white" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.pageTitle } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: _vm.routeRedirect,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("customer-info"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-center justify-between w-full h-32 step-indicator",
        },
        [
          _c(
            "sw-wizard",
            {
              attrs: {
                steps: 3,
                currentStep: _vm.step,
                "allow-navigation-redirect": false,
              },
              on: {
                "update:currentStep": function ($event) {
                  _vm.step = $event
                },
                "update:current-step": function ($event) {
                  _vm.step = $event
                },
              },
            },
            [
              _c(_vm.tab, {
                tag: "component",
                on: { next: _vm.setTab, back: _vm.back },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }