var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("estimates.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("estimates.estimate", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalEstimates,
                      expression: "totalEstimates",
                    },
                  ],
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "estimates/create",
                        size: "lg",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("estimates.new_estimate")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-50",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: { label: _vm.$tc("customers.customer", 1) },
                    },
                    [
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "min-width": "300px" },
                      attrs: {
                        label: _vm.$t("customers.customcode"),
                        color: "black-light",
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.customcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "customcode", $$v)
                            },
                            expression: "filters.customcode",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "2em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:ml-8",
                      attrs: {
                        label: _vm.$t("estimates.estimate_number"),
                        color: "black-light",
                      },
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          model: {
                            value: _vm.filters.estimate_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "estimate_number", $$v)
                            },
                            expression: "filters.estimate_number",
                          },
                        },
                        [
                          _c("hashtag-icon", {
                            staticClass: "h-5 ml-1 text-gray-500",
                            attrs: { slot: "leftIcon" },
                            slot: "leftIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2 xl:mx-8",
                      attrs: { label: _vm.$t("estimates.status") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.status,
                          "group-select": false,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("general.select_a_status"),
                          "allow-empty": false,
                          "group-values": "options",
                          "group-label": "label",
                          "track-by": "name",
                          label: "name",
                        },
                        on: {
                          remove: function ($event) {
                            return _vm.clearStatusSearch()
                          },
                          select: _vm.setActiveTab,
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$t("general.from"),
                        color: "black-light",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$t("general.to"),
                        color: "black-light",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("estimates.no_estimates"),
            description: _vm.$t("estimates.list_of_estimates"),
          },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/estimates/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("estimates.add_new_estimate")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            { staticClass: "relative mt-5" },
            [
              _c("p", { staticClass: "absolute m-0 text-sm md:mt-12" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.estimates.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalEstimates))]),
              ]),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  staticClass: "mb-10 hidden md:inline",
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter },
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.draft"), filter: "DRAFT" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.sent"), filter: "SENT" },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.viewed"),
                      filter: "VIEWED",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.expired"),
                      filter: "EXPIRED",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.accepted"),
                      filter: "ACCEPTED",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-tab-item", {
                    attrs: {
                      title: _vm.$t("general.rejected"),
                      filter: "REJECTED",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "-mt-6 md:mt-5",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.date"),
                  "sort-as": "estimate_date",
                  show: "formattedEstimateDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$tc("estimates.estimate_number", 1),
                  show: "estimate_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("estimates.estimate", 1))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `estimates/${row.id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.estimate_number) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.estimate_number) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$tc("estimates.customer"),
                  "sort-as": "name",
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("estimates.customer"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: `customers/${row.user_id}/view` },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.name) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.customer_num"),
                  "sort-as": "customcode",
                  show: "customcode",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("estimates.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("estimates.status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            staticClass: "px-3 py-1",
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.status
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(row.status)
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.status) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("invoices.total"),
                  "sort-as": "total",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("estimates.total"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("estimates.action")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          { attrs: { containerClass: "w-56" } },
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `estimates/${row.id}/edit`,
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeEstimate(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `estimates/${row.id}/view`,
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: {
                                    path: `/admin/customers/${row.user_id}/view`,
                                  },
                                },
                              },
                              [
                                _c("users-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.go_to_customer")) +
                                    "\n           "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: {
                                    path: `/admin/users/${row.assigne_user_id}/view`,
                                  },
                                },
                              },
                              [
                                _c("users-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.go_to_asiggned")) +
                                    "\n           "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.permissionModule.createInvoice
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.convertInToinvoice(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("document-text-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("estimates.convert_to_invoice")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "div",
                                  [
                                    row.status == "DRAFT"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMarkAsSent(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("check-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_sent"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status !== "SENT"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.sendEstimate(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("paper-airplane-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("estimates.send_estimate")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.status == "SENT" || row.status == "VIEWED"
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.sendEstimate(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("paper-airplane-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("estimates.resend_estimate")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.sendSMSEstimate(row)
                                  },
                                },
                              },
                              [
                                _c("paper-airplane-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("estimates.sendsms_estimate")
                                    ) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "div",
                                  [
                                    row.status !== "ACCEPTED" ||
                                    row.status !== "DRAFT"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMarkAsAccepted(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("check-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_accepted"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "div",
                                  [
                                    row.status == "DRAFT" ||
                                    row.status == "SENT" ||
                                    row.status == "VIEWED"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMarkAsRejected(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("x-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_rejected"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }