<template>
    <sw-button
      v-bind="$attrs"
      v-on="$listeners"
      :class="computedClass"
    >
      <slot />
    </sw-button>
  </template>
  
  <script>
  export default {
    name: 'CustomSwButton',
    props: {
      selected: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      computedClass() {
        return this.selected ? 'border-transparent' : 'border-gray-400';
      },
    },
  };
  </script>
  
  <style scoped>
  .border-gray-400 {
    border-color: #d1d5db !important; /* Gris claro */
  }
  </style>
  