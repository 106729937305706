var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { class: { "xl:pl-96": _vm.showSideBar } },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c("sw-page-header", { attrs: { title: _vm.pageTitle } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "div",
                { staticClass: "mr-3 hidden xl:block" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleListCustomers },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.contacts_list")) +
                          "\n          "
                      ),
                      _c(_vm.listIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-1 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.$route.params.id}/edit`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                {
                  staticClass: "w-full md:w-auto md:ml-1 mb-2 md:mb-0",
                  attrs: { position: "bottom-end" },
                },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-1 mb-2 md:mb-0",
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.new_transaction")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.allowInvoiceFormPos
                    ? _c(
                        "sw-dropdown-item",
                        { on: { click: _vm.redirectDashboardPos } },
                        [
                          _c("document-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("core_pos.new_invoice_pos")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessEstimates &&
                  _vm.permissionModule.createEstimates
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/estimates/create?customer=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("document-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("estimates.new_estimate")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessInvoices &&
                  _vm.permissionModule.createInvoices
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/invoices/create?customer=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("document-text-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("invoices.new_invoice")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessPayments &&
                  _vm.permissionModule.createPayments
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/payments/invoices?customer=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("credit-card-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("payments.new_payment")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessExpenses &&
                  _vm.permissionModule.createExpenses
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/expenses/create?customer=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("calculator-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("expenses.new_expense")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessNormalServices &&
                  _vm.permissionModule.createNormalServices
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/add-package`,
                          },
                        },
                        [
                          _c("document-duplicate-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("services.add_service")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessPBXServices &&
                  _vm.permissionModule.createPBXServices
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/add-corepbx-services`,
                          },
                        },
                        [
                          _c("document-duplicate-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.add_corepbx_services")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessPayments &&
                  _vm.permissionModule.createPayments
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/payments/credit?customer=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("document-duplicate-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.add_credit")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                { staticClass: "w-full md:w-auto md:ml-1 mb-2 md:mb-0" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-1 mb-2 md:mb-0",
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _c("dots-horizontal-icon", {
                        staticClass: "h-5 -ml-1 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.accessCustomerAddress
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/address`,
                          },
                        },
                        [
                          _c("map-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customer_address.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessCustomerContacts
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/contacts`,
                          },
                        },
                        [
                          _c("user-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("contacts.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.delete
                    ? _c(
                        "sw-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeCustomer(_vm.$route.params.id)
                            },
                          },
                        },
                        [
                          _c("trash-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.deletecustomer")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessCustomerPaymentAccounts
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            to: `/admin/customers/${_vm.$route.params.id}/payment-accounts`,
                            "tag-name": "router-link",
                          },
                        },
                        [
                          _c("credit-card-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("payment_accounts.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessCustomerPaymentAccounts
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            to: `/admin/payments-failed?customer_id=${_vm.$route.params.id}`,
                            "tag-name": "router-link",
                          },
                        },
                        [
                          _c("credit-card-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("failed_payment_history.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessCustomerMemoNotes
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/note`,
                          },
                        },
                        [
                          _c("document-duplicate-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customer_notes.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.update
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/options`,
                          },
                        },
                        [
                          _c("adjustments-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.options")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.permissionModule.accessTickets &&
                  _vm.permissionModule.createEstimates
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/customers/${_vm.$route.params.id}/ticket`,
                          },
                        },
                        [
                          _c("document-duplicate-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customer_ticket.title")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.$route.params.id}/email-logs`,
                      },
                    },
                    [
                      _c("document-duplicate-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("logs.email_logs.title")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.$route.params.id}/personal-documents`,
                      },
                    },
                    [
                      _c("document-duplicate-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v("\n          Personal Documents \n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("customer-view-sidebar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("customer-chart", { attrs: { refresh: _vm.isRefresh } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }