var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.$t("leads.title") } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "dashboard",
                          title: _vm.$t("general.home"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$tc("leads.title", 2),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showEmptyScreen,
                          expression: "!showEmptyScreen",
                        },
                      ],
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                          attrs: {
                            "tag-name": "router-link",
                            to: "leads/create",
                            variant: "primary",
                            size: "lg",
                          },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("leads.add_lead")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "search",
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("leads.search_lead_customer")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "relative grid grid-flow-col grid-rows",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        "margin-left": "1em",
                        "margin-right": "1em",
                      },
                    },
                    [
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$tc("leads.name") } },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              type: "text",
                              name: "name",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.filters.company_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "company_name", $$v)
                              },
                              expression: "filters.company_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$tc("leads.email") } },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              type: "text",
                              name: "name",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.filters.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "email", $$v)
                              },
                              expression: "filters.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$tc("leads.phone") } },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              type: "text",
                              name: "name",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.filters.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "phone", $$v)
                              },
                              expression: "filters.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        "margin-left": "1em",
                        "margin-right": "1em",
                      },
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("leads.status") },
                        },
                        [
                          _c("sw-select", {
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              options: _vm.status_options,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t("leads.status"),
                              "allow-empty": false,
                              "track-by": "value",
                              label: "text",
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("leads.last_contacted_date") },
                        },
                        [
                          _c("base-date-picker", {
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            model: {
                              value: _vm.filters.last_contacted_date,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filters,
                                  "last_contacted_date",
                                  $$v
                                )
                              },
                              expression: "filters.last_contacted_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: {
                        "margin-left": "1em",
                        "margin-right": "1em",
                      },
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("leads.customer_type") },
                        },
                        [
                          _c("sw-select", {
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              options: _vm.customer_types_options,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t("leads.customer_type"),
                              "allow-empty": false,
                              "track-by": "value",
                              label: "text",
                            },
                            model: {
                              value: _vm.filters.customer_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "customer_type", $$v)
                              },
                              expression: "filters.customer_type",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-2",
                          attrs: { label: _vm.$t("leads.followupdate") },
                        },
                        [
                          _c("base-date-picker", {
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            model: {
                              value: _vm.filters.followup_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "followup_date", $$v)
                              },
                              expression: "filters.followup_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("leads.no_leads"),
                description: _vm.$t("leads.list_of_leads"),
              },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      attrs: {
                        slot: "actions",
                        "tag-name": "router-link",
                        to: "leads/create",
                        size: "lg",
                        variant: "primary-outline",
                      },
                      slot: "actions",
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("leads.add_lead")) + "\n    "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.name"),
                      show: "company_name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("leads.name"))),
                              ]),
                              _vm._v(" "),
                              _vm.permissionModule.read
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to: { path: `leads/${row.id}/view` },
                                      },
                                    },
                                    [
                                      row.customer_type === "B"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(row.company_name) +
                                                "\n            "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  row.first_name !== null
                                                    ? row.first_name
                                                    : "N/A" +
                                                        " " +
                                                        row.last_name !==
                                                      null
                                                    ? row.last_name
                                                    : "N/A"
                                                ) +
                                                "\n            "
                                            ),
                                          ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.read == false
                                ? _c("p", [
                                    row.customer_type === "B"
                                      ? _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.company_name) +
                                              "\n            "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                row.first_name !== null
                                                  ? row.first_name
                                                  : "N/A" +
                                                      " " +
                                                      row.last_name !==
                                                    null
                                                  ? row.last_name
                                                  : "N/A"
                                              ) +
                                              "\n            "
                                          ),
                                        ]),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1154621426
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.email"),
                      show: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.phone"),
                      show: "phone",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("leads.phone"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.phone ? row.phone : "No Contact") +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1123065763
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.customer_type"),
                      "sort-as": "customer_type",
                      show: "customer_type",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.customer_type))]),
                              _vm._v(" "),
                              row.customer_type === "N"
                                ? _c("span", [_vm._v("None")])
                                : row.customer_type === "B"
                                ? _c("span", [_vm._v("Business")])
                                : _c("span", [_vm._v("Residential")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3879073544
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.last_contacted_date"),
                      show: "last_contacted_date",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("leads.last_contacted_date"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.formattedLastDate
                                      ? row.formattedLastDate
                                      : "N/A"
                                  ) + "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3376946899
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("leads.followupdate"),
                      show: "followup_date",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("leads.followupdate"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.formattedFollowDate
                                      ? row.formattedFollowDate
                                      : "N/A"
                                  ) + "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      314573740
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("leads.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _vm.permissionModule.read
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `leads/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.update
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `leads/${row.id}/edit`,
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendSMSLead(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("paper-airplane-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("leads.send_lead_sms")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendEmailLead(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("paper-airplane-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("leads.send_email_sms")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  row.status === "A" &&
                                  _vm.permissionModule.update
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.convertCustomer(
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "leads.convert_customer"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3254134654
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }