<template>
  <base-page class="payments">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <sw-page-header :title="$t('customer_address.title')"> </sw-page-header>
      <div class="flex flex-wrap items-center justify-end">
        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/view`"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          variant="primary-outline"
        >
          {{ $t('customer_address.clientgoback') }}
        </sw-button>

        <sw-button
          tag-name="router-link"
          :to="`/admin/customers/${$route.params.id}/add-address`"
          variant="primary"
          size="lg"
          class="w-full md:w-auto md:ml-4 mb-2 md:mb-0"
          v-if="permissionModule.create"
        >
          <!-- @click="openCustomerAddressModal()" -->
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('customer_address.create_address') }}
        </sw-button>
      </div>
    </div>

    <sw-empty-table-placeholder
      v-if="showEmptyScreen"
      :title="$t('customer_address.no_customer_address')"
      :description="$t('customer_address.list_of_customer_address')"
    >
      <capsule-icon class="mt-5 mb-4" />
      <sw-button
        slot="actions"
        tag-name="router-link"
        :to="`/admin/customers/${$route.params.id}/add-address`"
        size="lg"
        variant="primary-outline"
      >
        <plus-icon class="w-6 h-6 mr-1 -ml-2" />
        {{ $t('customer_address.new_address') }}
      </sw-button>
    </sw-empty-table-placeholder>

    <div v-show="!showEmptyScreen" class="relative table-container">

      <sw-table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('customer_address.country')"
          show="country_id"
        >
          <template slot-scope="row">
            <span>{{ $t('customer_address.country') }}</span>
            {{ row.country.name }}
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          :filterable="true"
          :label="$t('customer_address.state')"
          show="state_id"
        >
          <template slot-scope="row">
            <span>{{ $t('customer_address.state') }}</span>
            {{ row.state ? row.state.name : 'N/A' }}
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="true"
          :label="$t('customer_address.city')"
          show="city"
        />

        <sw-table-column :sortable="true" :label="$t('customer_address.type')">
          <template slot-scope="row">
            {{
              row.type == 'billing'
                ? $t('customer_address.billing')
                : $t('customer_address.services_address')
            }}
          </template>
        </sw-table-column>

        <sw-table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.action') }}</span>
            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item
                tag-name="router-link"
                :to="`${row.id}/edit-address`"
                v-if="permissionModule.update"
              >
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="avalara_module_backend && row.type && isAvalara"
                @click="showModalException(row)"
              >
                <book-open-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('avalara.exemptions') }}
              </sw-dropdown-item>
              <div v-if="permissionModule.delete">
                <sw-dropdown-item
                  v-if="row.type == 'services_address'"
                  @click="removeAddress(row.id)"
                >
                  <trash-icon class="h-5 mr-3 text-gray-600" />
                  {{ $t('general.delete') }}
                </sw-dropdown-item>
              </div>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
    <CustomerAvalaraCategoryExemptionsModalList
      ref="CustomerAvalaraCategoryExemptionsRef"
    />
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CapsuleIcon from '@/components/icon/CapsuleIcon'
import CustomerAvalaraCategoryExemptionsModalList from '@/components/base/modal/CustomerAvalaraCategoryExemptionsModalList.vue'
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  BookOpenIcon,
} from '@vue-hero-icons/solid'

export default {
  components: {
    CapsuleIcon,
    PlusIcon,
    PencilIcon,
    TrashIcon,
    BookOpenIcon,
    CustomerAvalaraCategoryExemptionsModalList,
  },

  data() {
    return {
      isRequestOngoing: true,
      avalara_module_backend: true,
      user_id: null,
      isAvalara: 0,
      permissionModule: {
        create: false,
        update: false,
        delete: false,
        read: false,
      },
    }
  },

  computed: {
    showEmptyScreen() {
      return !this.totalCustomerAddress && !this.isRequestOngoing
    },
    ...mapGetters('customer', ['customers']),

    ...mapGetters('customerAddress', [
      'totalCustomerAddress',
    ]),
  },
  async mounted() {
    this.user_id = this.$route.params.id
    let resCustomer = await this.fetchCustomer(this.$route.params)
    this.isAvalara = resCustomer.data.customer.avalara_bool
  },
  async created() {
    this.permissionsUserModule()
    window.hub.$on('showModalException_event', this.showModalExceptionList)
    this.fetchAvalaraModule()
  },
  methods: {
    ...mapActions('customerAddress', [
      'fetchCustomerAddresses',
      'deleteCustomerAddress',
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['getUserModules']),
    ...mapActions('customer', [ 'fetchCustomer' ]),

    showModalExceptionList(data) {
      this.$refs.CustomerAvalaraCategoryExemptionsRef.setData(data)
    },

    async fetchAvalaraModule() {
      let res = await window.axios.get('/api/v1/module/avalara')
      this.avalara_module_backend = res.data
    },

    async fetchData({ page, sort }) {
      this.isRequestOngoing = true
      let response = await this.fetchCustomerAddresses({
        customer_id: this.$route.params.id,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
        limit: 10,
      })
      this.isRequestOngoing = false
      return {
        data: response.data.customerAddress.data,
        pagination: {
          totalPages: response.data.customerAddress.last_page,
          currentPage: page,
          count: response.data.customerAddress.total,
          orderBy: sort.order || 'desc',
        },
      }
    },
    showModalException(billing) {
      var avalaralocationid = billing.user.avalara_location_id
      if (billing.avalaraLocation != null) {
        avalaralocationid = billing.avalaraLocation.id
      }
      const data = {
        billing: billing,
        userId: billing.user_id,
        avalara_location_id: avalaralocationid,
      }

      this.openModal({
        title: this.$t('avalara.category_exemptions'),
        componentName: 'CustomerAvalaraCategoryExemptionsModal',
        id: this.$route.params.id,
        data: data,
        variant: 'lg',
        company: 0,
      })
    },
    
    async removeAddress(id) {
      // Muestra una alerta de confirmación al usuario
      const willDelete = await swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customer_address.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      })

      // Si el usuario confirma la eliminación
      if (willDelete) {
        try {
          // Llama al método para eliminar la dirección
          const res = await this.deleteCustomerAddress({ id })

          // Si la eliminación fue exitosa, muestra un mensaje de éxito y refresca la tabla
          if (res.data.success) {
            window.toastr['success'](
              this.$tc('customer_address.deleted_message', 1)
            )
            this.$refs.table.refresh()
            return true
          }

          // Si hubo un error, muestra un mensaje de error
          window.toastr['error'](res.data.message)
          return false
        } catch (error) {
          // Maneja cualquier error que ocurra durante la solicitud
          window.toastr['error'](this.$t('general.error_occurred'))
          return false
        }
      }

      // Si el usuario cancela la eliminación, retorna false
      return false
    },

    async permissionsUserModule() {
      const data = {
        module: 'cust_address',
      }

      // Obtiene los permisos del usuario para el módulo especificado
      const permissions = await this.getUserModules(data)

      // Valida que el usuario tenga permiso para ingresar al módulo
      if (!permissions.super_admin) {
        if (
          !permissions.exist ||
          !permissions.permissions[0] ||
          permissions.permissions[0].access === 0
        ) {
          this.$router.push('/admin/dashboard')
          return
        }
      }

      // Establece los permisos de creación, lectura, actualización y eliminación
      if (permissions.super_admin) {
        this.permissionModule = {
          create: true,
          update: true,
          delete: true,
          read: true,
        }
      } else if (permissions.exist) {
        const modulePermissions = permissions.permissions[0]
        this.permissionModule = {
          create: modulePermissions.create === 1,
          update: modulePermissions.update === 1,
          delete: modulePermissions.delete === 1,
          read: modulePermissions.read === 1,
        }
      }
    },
  },
}
</script>