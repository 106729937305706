export const BOOTSTRAP_EXTENSIONS = 'BOOTSTRAP_EXTENSIONS'
export const ADD_EXTENSIONS = 'ADD_EXTENSIONS'
export const UPDATE_EXTENSIONS = 'UPDATE_EXTENSIONS'
export const DELETE_EXTENSIONS = 'DELETE_EXTENSIONS'
export const DELETE_MULTIPLE_ITEM_GROUPS = 'DELETE_MULTIPLE_ITEM_GROUPS'
export const SET_SELECTED_ITEM_GROUPS = 'SET_SELECTED_ITEM_GROUPS'
export const RESET_SELECTED_ITEM_GROUP = 'RESET_SELECTED_ITEM_GROUP'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_ITEM_GROUP = 'SET_SELECTED_VIEW_ITEM_GROUP'
export const SET_ITEM_UNITS = 'SET_ITEM_UNITS'
export const SET_SELECTED_EXTENSIONS = 'SET_SELECTED_EXTENSIONS'
export const RESET_EXTENSIONS = 'RESET_EXTENSIONS'
export const SET_EXTENSIONS = 'SET_EXTENSIONS'
export const SET_TOTAL_EXTENSIONS = 'SET_TOTAL_EXTENSIONS'