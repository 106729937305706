var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "overflow-auto",
      staticStyle: { height: "60vh" },
      attrs: { "data-modal-toggle": "large-modal" },
    },
    [
      _c(
        "form",
        {
          staticStyle: {
            "padding-top": "17.5px",
            "padding-right": "37.5px",
            "padding-left": "30px",
          },
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitTaxTypeData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-2 mb-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("tax_types.name"),
                    error: _vm.nameError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    ref: "name",
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("tax_types.percent"),
                    error: _vm.percentError,
                    required: "",
                  },
                },
                [
                  _c("sw-money", {
                    staticClass:
                      "relative w-full focus:border focus:border-solid focus:border-primary",
                    attrs: {
                      currency: _vm.defaultInput,
                      invalid: _vm.$v.formData.percent.$error,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.percent.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.percent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "percent", $$v)
                      },
                      expression: "formData.percent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("tax_types.description"),
                    error: _vm.descriptionError,
                  },
                },
                [
                  _c("sw-textarea", {
                    staticStyle: { height: "113%", width: "255%" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.description.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "relative mt-2 mb-1",
                  attrs: { label: _vm.$t("tax_types.compound_tax") },
                },
                [
                  _c("sw-switch", {
                    staticClass: "relative",
                    model: {
                      value: _vm.formData.compound_tax,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "compound_tax", $$v)
                      },
                      expression: "formData.compound_tax",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "relative mt-2 mb-1",
                  attrs: { label: _vm.$t("tax_types.for_cdr") },
                },
                [
                  _c("sw-switch", {
                    staticClass: "relative",
                    model: {
                      value: _vm.formData.for_cdr,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "for_cdr", $$v)
                      },
                      expression: "formData.for_cdr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c(
              "div",
              { staticClass: "col-span-12 md:col-span-12" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("tax_groups.title") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "relative w-full",
                      attrs: {
                        options: _vm.getTaxGroups,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        placeholder: _vm.$t("general.select_taxgroup"),
                        "track-by": "tax_group_id",
                        label: "tax_group_name",
                      },
                      model: {
                        value: _vm.formData.tax_groups,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "tax_groups", $$v)
                        },
                        expression: "formData.tax_groups",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("customers.category") },
                  },
                  [
                    _vm.formData.category
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            staticStyle: {
                              top: "10.55px",
                              right: "5px",
                              "z-index": "999999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCategory()
                              },
                            },
                          },
                          [_c("x-circle-icon", { staticClass: "h-5" })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoadingCategory
                      ? _c("sw-select", {
                          attrs: {
                            options: _vm.categories,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_category"),
                            label: "name",
                            "track-by": "id",
                            "z-index": "9999",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.categorySelected($event)
                            },
                          },
                          model: {
                            value: _vm.formData.category,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "category", $$v)
                            },
                            expression: "formData.category",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("customers.agency") },
                  },
                  [
                    _vm.formData.agency
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            staticStyle: {
                              top: "10.55px",
                              right: "5px",
                              "z-index": "999999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteAgency()
                              },
                            },
                          },
                          [_c("x-circle-icon", { staticClass: "h-5" })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoadingAgency
                      ? _c("sw-select", {
                          attrs: {
                            options: _vm.agencies,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_agency"),
                            label: "name",
                            "track-by": "id",
                            "z-index": "9999",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.agencySelected($event)
                            },
                          },
                          model: {
                            value: _vm.formData.agency,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "agency", $$v)
                            },
                            expression: "formData.agency",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("customers.country") },
                  },
                  [
                    _vm.formData.country
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            staticStyle: {
                              top: "10.55px",
                              right: "5px",
                              "z-index": "999999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCountry()
                              },
                            },
                          },
                          [_c("x-circle-icon", { staticClass: "h-5" })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoadingCountryState
                      ? _c("sw-select", {
                          attrs: {
                            options: _vm.countries,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": true,
                            placeholder: _vm.$t("general.select_country"),
                            label: "name",
                            "track-by": "id",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.countrySelected($event)
                            },
                          },
                          model: {
                            value: _vm.formData.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "country", $$v)
                            },
                            expression: "formData.country",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("customers.state") },
                  },
                  [
                    _vm.formData.state
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "absolute text-gray-400 cursor-pointer",
                            staticStyle: {
                              top: "10.55px",
                              right: "5px",
                              "z-index": "999999",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteState()
                              },
                            },
                          },
                          [_c("x-circle-icon", { staticClass: "h-5" })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoadingCountryState
                      ? _c("sw-select", {
                          attrs: {
                            options: _vm.states,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": false,
                            placeholder: _vm.$t("general.select_state"),
                            label: "name",
                            "track-by": "id",
                          },
                          on: {
                            select: function ($event) {
                              return _vm.stateSelected($event)
                            },
                          },
                          model: {
                            value: _vm.formData.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "state", $$v)
                            },
                            expression: "formData.state",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-2" },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "relative",
                  attrs: { label: _vm.$t("customers.county") },
                },
                [
                  _c("sw-input", {
                    ref: "county",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.county,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "county", $$v)
                      },
                      expression: "formData.county",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "relative",
                  attrs: { label: _vm.$t("customers.city") },
                },
                [
                  _c("sw-input", {
                    ref: "city",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.formData.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "city", $$v)
                      },
                      expression: "formData.city",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-solid border--200 border-modal-bg mt-8",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3 text-sm",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeTaxModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.isEdit
                          ? _vm.$t("general.save")
                          : _vm.$t("general.update")
                      ) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }