var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("corePbx.didFree.new_custom_did", 2),
                  to: "/admin/corePBX/billing-templates/did-free",
                },
              }),
              _vm._v(" "),
              _vm.$route.name === "corepbx.didFree.edit"
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.didFree.edit_did_free"),
                      to: "#",
                      active: "",
                    },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.didFree.add_did_free"),
                      to: "#",
                      active: "",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        [
          _c(
            "div",
            {
              staticClass:
                "grid gap-6 grid-col-1 md:grid-cols-2 border border-grey-700 rounded-lg p-5",
            },
            [
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$t("packages.status") } },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.status,
                      searchable: true,
                      "show-labels": false,
                      tabindex: 16,
                      "allow-empty": true,
                      placeholder: _vm.$t("general.select_status"),
                      label: "text",
                      "track-by": "value",
                    },
                    model: {
                      value: _vm.formData.statu,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "statu", $$v)
                      },
                      expression: "formData.statu",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("expenses.category"),
                    error: _vm.categoryError,
                    required: "",
                  },
                },
                [
                  _c(
                    "sw-select",
                    {
                      ref: "baseSelect",
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.categoriesTollFree,
                        invalid: _vm.$v.category.$error,
                        searchable: true,
                        "show-labels": false,
                        placeholder: _vm.$t(
                          "expenses.categories.select_a_category"
                        ),
                        label: "name",
                        "track-by": "id",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.category.$touch()
                        },
                      },
                      model: {
                        value: _vm.category,
                        callback: function ($$v) {
                          _vm.category = $$v
                        },
                        expression: "category",
                      },
                    },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass:
                            "flex items-center justify-center w-full px-4 py-3 bg-gray-200 border-none outline-none",
                          attrs: {
                            slot: "afterList",
                            type: "button",
                            variant: "gray-light",
                          },
                          on: { click: _vm.openCategoryModal },
                          slot: "afterList",
                        },
                        [
                          _c("shopping-cart-icon", {
                            staticClass: "h-5 text-center text-primary-400",
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "ml-2 text-xs leading-none text-primary-400",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "settings.expense_category.add_new_category"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.formData.multiple, function (prefix, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "grid gap-6 grid-col-1 md:grid-cols-2 mt-4 border border-grey-700 rounded-lg p-5",
              },
              [
                _c(
                  "sw-input-group",
                  {
                    attrs: {
                      label: _vm.$t("didFree.item.prefijo"),
                      error: _vm.prefixValidate(
                        _vm.$v.formData.multiple.$each[index].prefijo
                      ),
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("didFree.item.prefijo"),
                        focus: "",
                        type: "text",
                        name: "prefijo",
                        tabindex: "1",
                        placer: "",
                        invalid:
                          _vm.$v.formData.multiple.$each[index].prefijo.$error,
                      },
                      model: {
                        value: prefix.prefijo,
                        callback: function ($$v) {
                          _vm.$set(prefix, "prefijo", $$v)
                        },
                        expression: "prefix.prefijo",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    attrs: {
                      label: _vm.$t("didFree.price"),
                      error: _vm.ratePerError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-money", {
                      attrs: {
                        currency: _vm.defaultCurrencyForInput,
                        name: "rate_per_minutes",
                      },
                      model: {
                        value: prefix.rate_per_minutes,
                        callback: function ($$v) {
                          _vm.$set(prefix, "rate_per_minutes", $$v)
                        },
                        expression: "prefix.rate_per_minutes",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isEdit
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-span-1 md:col-span-2 flex justify-end",
                      },
                      [
                        _vm.formData.multiple.length - 1 == index
                          ? _c(
                              "sw-button",
                              {
                                attrs: {
                                  variant: `primary-outline`,
                                  size: "sm",
                                },
                                on: { click: _vm.addCustom },
                              },
                              [_c("PlusIcon", { staticClass: "h-4 w-4" })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.multiple.length - 1 !== index
                          ? _c(
                              "sw-button",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  variant: `danger-outline`,
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCustom(index)
                                  },
                                },
                              },
                              [_c("TrashIcon", { staticClass: "h-4 w-4" })],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-8 py-2 flex flex-col md:flex-row md:space-x-4" },
        [
          _c(
            "sw-button",
            {
              staticClass: "w-full md:w-auto",
              attrs: {
                loading: _vm.isLoading,
                type: "submit",
                variant: "primary",
                size: "lg",
              },
              on: { click: _vm.submitDID },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "w-6 h-6 mr-1 -ml-2 mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("didFree.update_did")
                      : _vm.$t("didFree.save_did")
                  ) +
                  "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "w-full md:w-auto mt-2 md:mt-0",
              attrs: { variant: "primary-outline", type: "button", size: "lg" },
              on: {
                click: function ($event) {
                  return _vm.cancelForm()
                },
              },
            },
            [
              _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }