var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxes.length && _vm.buttonBool
    ? _c(
        "td",
        {
          staticClass: "px-2 pb-2 pt-1 text-left align-top",
          attrs: { colspan: "4" },
        },
        _vm._l(_vm.taxes, function (tax) {
          return _c("avalara-tax-item", {
            key: tax.id,
            attrs: { currency: _vm.currency, "tax-data": tax },
          })
        }),
        1
      )
    : _vm.taxes.length && !_vm.buttonBool && _vm.IsEditAvalara
    ? _c(
        "td",
        {
          staticClass: "px-2 pb-2 pt-1 text-left align-top",
          attrs: { colspan: "4" },
        },
        _vm._l(_vm.taxes, function (tax) {
          return _c("avalara-tax-item", {
            key: tax.id,
            attrs: { currency: _vm.currency, "tax-data": tax },
          })
        }),
        1
      )
    : _c("td", [_c("p", { staticClass: "mb-10 py-10" })])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }