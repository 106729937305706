var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.$t("corePbx.menu_title.templates") } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$tc(
                            "corePbx.submenu_title.custom_app_rate"
                          ),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalCustomAppRate,
                          expression: "totalCustomAppRate",
                        },
                      ],
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                          attrs: {
                            "tag-name": "router-link",
                            to: "custom-app-rate/create",
                            variant: "primary",
                            size: "lg",
                          },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("general.add")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("general.name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen && _vm.customAppRate.length === 0,
                  expression: "showEmptyScreen && customAppRate.length === 0",
                },
              ],
              attrs: {
                title: _vm.$t("corePbx.no_custom_app_rate"),
                description: _vm.$t("corePbx.list_of_custom_app_rate"),
              },
            },
            [
              _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      attrs: {
                        slot: "actions",
                        "tag-name": "router-link",
                        to: "/admin/corePBX/billing-templates/custom-app-rate/create",
                        size: "lg",
                        variant: "primary-outline",
                      },
                      slot: "actions",
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("corePbx.add_custom_app_rate")) +
                          "\n    "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative table-container" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.customAppRate.length))]),
                    _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalCustomAppRate))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.isRequestOngoing ? _c("base-loader") : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("general.name"),
                      show: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.name"))),
                              ]),
                              _vm._v(" "),
                              _vm.permissionModule.read
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500",
                                      attrs: {
                                        to: {
                                          path: `custom-app-rate/${row.id}/view`,
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(row.name) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(row.name) +
                                        "\n          "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1714384820
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("items.added_on"),
                      "sort-as": "created_at",
                      show: "created_at",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("items.added_on"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("formatDate")(row.created_at)
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3006146487
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("items.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _vm.permissionModule.read
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            "tag-name": "router-link",
                                            to: `custom-app-rate/${row.id}/view`,
                                          },
                                        },
                                        [
                                          _c("eye-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.update
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          attrs: {
                                            to: `custom-app-rate/${row.id}/edit`,
                                            "tag-name": "router-link",
                                          },
                                        },
                                        [
                                          _c("pencil-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissionModule.delete
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCustomAppRate(
                                                row.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("trash-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4140031724
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }