var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end mt-8 lg:flex-nowrap" },
        [
          _c(
            "sw-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalModuleLogs,
                  expression: "totalModuleLogs",
                },
              ],
              attrs: { size: "lg", variant: "primary-outline" },
              on: { click: _vm.toggleFilter },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("general.filter")) +
                  "\n            "
              ),
              _c(_vm.filterIcon, {
                tag: "component",
                staticClass: "w-4 h-4 ml-2 -mr-1",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass:
                "relative grid grid-rows gap-4 p-4 bg-gray-100 rounded-lg w-full",
            },
            [
              _c(
                "div",
                { staticClass: "w-full grid grid-cols-1 md:grid-cols-3 gap-4" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.from") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "w-full",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("general.to") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "w-full",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("logs.module_logs.username") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "w-full",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.user,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "user", $$v)
                          },
                          expression: "filters.user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("logs.module_logs.module") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "w-full",
                        attrs: {
                          options: _vm.listModules,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "logs.module_logs.select_a_module"
                          ),
                          "allow-empty": false,
                          "track-by": "module",
                          label: "module",
                        },
                        model: {
                          value: _vm.filters.module,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "module", $$v)
                          },
                          expression: "filters.module",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: { label: _vm.$t("logs.module_logs.task") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "w-full",
                        attrs: {
                          options: _vm.listTasks,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("logs.module_logs.select_a_task"),
                          "allow-empty": false,
                          "track-by": "task",
                          label: "task",
                        },
                        model: {
                          value: _vm.filters.task,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "task", $$v)
                          },
                          expression: "filters.task",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                variant: "gray",
                "show-filter": false,
                data: _vm.fetchData,
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("logs.module_logs.module"),
                  show: "module",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("logs.module_logs.task"),
                  show: "task",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("logs.module_logs.slug"),
                  show: "slug",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("logs.module_logs.username"),
                  show: "username",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("logs.module_logs.created_at"),
                  "sort-as": "created_at",
                  show: "formattedCreatedAt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "py-4" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("logs.time_shown")))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }