var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-modal" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "px-8 py-10 sm:p-6 relative overflow-auto sw-scroll h-80 grid grid-cols-1 gap-4",
            },
            [
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$t("corePbx.items") } },
                [
                  _c("sw-select", {
                    ref: "baseSelect",
                    staticClass: "mt-1",
                    attrs: {
                      options: _vm.items,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("expenses.item_select"),
                      label: "name",
                      "track-by": "id",
                    },
                    on: { select: _vm.optionsSelect },
                    model: {
                      value: _vm.item,
                      callback: function ($$v) {
                        _vm.item = $$v
                      },
                      expression: "item",
                    },
                  }),
                  _vm._v(" "),
                  _c("em", { staticClass: "text-sm text-gray-600 mt-1 px-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("packages.warning_item")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4 pr-3",
                  attrs: { label: _vm.$t("items.name"), required: "" },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      focus: "",
                      type: "text",
                      name: "name",
                      tabindex: 1,
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4 pr-3",
                  attrs: { label: _vm.$t("users.description") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      focus: "",
                      type: "text",
                      name: "description",
                      tabindex: 1,
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "description",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-4 pr-3",
                  attrs: {
                    label: _vm.$t("items.price"),
                    variant: "vertical",
                    required: "",
                  },
                },
                [
                  _c("sw-money", {
                    staticClass:
                      "relative w-full focus:border focus:border-solid focus:border-primary-500",
                    attrs: {
                      currency: _vm.defaultCurrencyForInput,
                      tabindex: 2,
                    },
                    model: {
                      value: _vm.price,
                      callback: function ($$v) {
                        _vm.price = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex mb-4" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-switch", {
                      staticClass: "absolute",
                      staticStyle: { top: "-18px" },
                      model: {
                        value: _vm.formData.end_period_act,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "end_period_act", $$v)
                        },
                        expression: "formData.end_period_act",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass: "p-0 mb-1 text-base leading-snug text-black",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("packages.end_period_act")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("packages.end_period_act_desp")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.formData.end_period_act == true ||
              _vm.formData.end_period_act == 1
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-4 pr-3",
                      attrs: {
                        label: _vm.$t("packages.end_period_num"),
                        variant: "vertical",
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          type: "number",
                          min: "1",
                          small: "",
                          onkeydown:
                            "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                        },
                        model: {
                          value: _vm.formData.end_period_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "end_period_number", $$v)
                          },
                          expression: "formData.end_period_number",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeExtensionModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                { attrs: { variant: "primary", type: "submit" } },
                [
                  _c("save-icon", { staticClass: "mr-2" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }