var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
    _c("div", { staticClass: "border-b tab" }, [
      _c("div", { staticClass: "relative" }, [
        _c("input", {
          staticClass:
            "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
          attrs: { type: "checkbox", id: "chck4" },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass:
              "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
            attrs: { for: "chck4" },
          },
          [
            _c(
              "span",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("customers.services_pbx")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      "aria-hidden": "true",
                      "data-reactid": "266",
                      fill: "none",
                      height: "24",
                      stroke: "#606F7B",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      viewbox: "0 0 24 24",
                      width: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [_c("polyline", { attrs: { points: "6 9 12 15 18 9" } })]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-content-customer" },
          [
            _c(
              "div",
              { staticClass: "text-grey-darkest" },
              [
                _c(
                  "sw-tabs",
                  {
                    attrs: { "active-tab": _vm.activeServicesPbxTab },
                    on: { update: _vm.setServicesPbxStatusFilter },
                  },
                  [
                    _c("sw-tab-item", {
                      attrs: { title: _vm.$t("customers.active"), filter: "A" },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("customers.pending"),
                        filter: "P",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("customers.suspend"),
                        filter: "S",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-tab-item", {
                      attrs: {
                        title: _vm.$t("customers.cancelled"),
                        filter: "C",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-table-component",
              {
                ref: "services_pbx_table",
                attrs: {
                  "show-filter": false,
                  data: _vm.fetchPbxServicesData,
                  "table-class": "table",
                },
              },
              [
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("services.service_number"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("services.service_number"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "font-medium text-primary-500",
                              attrs: {
                                to:
                                  row.deleted_at == null
                                    ? {
                                        path: `/admin/customers/${row.customer_id}/pbx-service/${row.id}/view`,
                                      }
                                    : "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(row.pbx_services_number) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$tc("packages.package", 1),
                    show: "pbx_package.pbx_package_name",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customers.amount"),
                    "sort-as": "total",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("customers.amount"))),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.formatMoney(
                                  row.total,
                                  row.user.currency
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: { sortable: true, label: _vm.$t("customers.term") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("customers.term"))),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.capitalizeFirstLetter(row.term))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customers.activation_date"),
                    "sort-as": "activation_date",
                    show: "formattedActivationDate",
                  },
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customers.renewal_date"),
                    show: "formattedRenewalDate",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }