var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "relative payment-create" }, [
    _c(
      "form",
      {
        attrs: { action: "", id: "payment-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitPaymentData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/customer/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("payments.payment", 2),
                    to: "/customer/payments",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "payments.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("payments.edit_payment"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("payments.new_payment"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c("template", { slot: "actions" }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isShowIdentificationVerification
          ? _c(
              "div",
              [
                !_vm.isRequestOnGoing
                  ? _c(
                      "sw-card",
                      [
                        _c(
                          "div",
                          {
                            staticClass: "grid-cols-12 gap-8 mt-1 mb-2 lg:grid",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid grid-cols-1 col-span-12 gap-4 mt-8 lg:gap-4 lg:mt-0 lg:grid-cols-3",
                              },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("payments.date"),
                                      error: _vm.DateError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("base-date-picker", {
                                      staticClass: "mt-1",
                                      attrs: {
                                        invalid:
                                          _vm.$v.formData.payment_date.$error,
                                        "calendar-button": true,
                                        "calendar-button-icon": "calendar",
                                        disabled: true,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.payment_date.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.formData.payment_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "payment_date",
                                            $$v
                                          )
                                        },
                                        expression: "formData.payment_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-input-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("payments.invoice_title"),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "button-group" },
                                      [
                                        _c(
                                          "sw-button",
                                          {
                                            staticClass: "button-all",
                                            class: {
                                              "button-active":
                                                _vm.activeButton === "ALL",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.filtrarYSeleccionarInvoices(
                                                  "ALL"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.all"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "sw-button",
                                          {
                                            staticClass: "button-sent",
                                            class: {
                                              "button-active":
                                                _vm.activeButton === "SENT",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.filtrarYSeleccionarInvoices(
                                                  "SENT"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.sent"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "sw-button",
                                          {
                                            staticClass: "button-due",
                                            class: {
                                              "button-active":
                                                _vm.activeButton === "VIEWED",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.filtrarYSeleccionarInvoices(
                                                  "VIEWED"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.due"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "sw-button",
                                          {
                                            staticClass: "button-overdue",
                                            class: {
                                              "button-active":
                                                _vm.activeButton === "OVERDUE",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.filtrarYSeleccionarInvoices(
                                                  "OVERDUE"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.overdue"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("sw-select", {
                                      staticClass: "custom-sw-select mt-1",
                                      attrs: {
                                        options: _vm.invoiceList,
                                        multiple: true,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        placeholder: _vm.$t(
                                          "invoices.select_invoice"
                                        ),
                                        "custom-label": _vm.invoiceWithAmount,
                                        "track-by": "invoice_number",
                                        label: "invoice_number",
                                        "search-keys": ["invoice_number"],
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function ({
                                              option,
                                              selected,
                                              select,
                                            }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "custom-option",
                                                      "multiselect__tag",
                                                      _vm.getStatusClass(
                                                        option.status
                                                      ),
                                                      selected
                                                        ? "selected"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.selectedInvoices,
                                                          expression:
                                                            "selectedInvoices",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-checkbox",
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        value: option,
                                                        checked: Array.isArray(
                                                          _vm.selectedInvoices
                                                        )
                                                          ? _vm._i(
                                                              _vm.selectedInvoices,
                                                              option
                                                            ) > -1
                                                          : _vm.selectedInvoices,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                _vm.selectedInvoices,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = option,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.selectedInvoices =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.selectedInvoices =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.selectedInvoices =
                                                                $$c
                                                            }
                                                          },
                                                          function ($event) {
                                                            return select(
                                                              option
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.invoiceWithAmount(
                                                            option
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2721799734
                                      ),
                                      model: {
                                        value: _vm.selectedInvoices,
                                        callback: function ($$v) {
                                          _vm.selectedInvoices = $$v
                                        },
                                        expression: "selectedInvoices",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-input-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("payments.amount"),
                                      error: _vm.amountError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "relative w-full mt-1" },
                                      [
                                        _c("sw-money", {
                                          staticClass:
                                            "relative w-full focus:border focus:border-solid focus:border-primary-500",
                                          attrs: {
                                            currency: _vm.customerCurrency,
                                            invalid:
                                              _vm.$v.formData.amount.$error,
                                            disabled: _vm.isEdit,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.$v.formData.amount.$touch()
                                            },
                                          },
                                          model: {
                                            value: _vm.amount,
                                            callback: function ($$v) {
                                              _vm.amount = $$v
                                            },
                                            expression: "amount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("payments.fee")) +
                                          " : " +
                                          _vm._s(_vm.totalFeeAmount)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("payments.total_amount")
                                        ) +
                                          " : " +
                                          _vm._s(_vm.totalAmount)
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.creditv
                                  ? _c("sw-input-group", [
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex flex-wrap justify-start",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "payments.account_avalable_credit"
                                                  )
                                                ) + ":\n                  "
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "text-success text-xl ml-2",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      _vm.customer.balance *
                                                        100,
                                                      _vm.customer.currency
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-button",
                                            {
                                              staticClass:
                                                "flex flex-wrap justify-end",
                                              attrs: {
                                                loading: _vm.isLoading,
                                                disabled: _vm.isLoading,
                                                variant: "primary",
                                                type: "button",
                                              },
                                              on: {
                                                click:
                                                  _vm.paymentWithCustomerBalance,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "payments.apply_credit"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("sw-input-group"),
                                _vm._v(" "),
                                _c("sw-input-group"),
                                _vm._v(" "),
                                _c("sw-input-group"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("sw-divider", {
                              staticClass: "my-0 col-span-12 opacity-1",
                            }),
                            _vm._v(" "),
                            _vm.customerHasPaymentMethods
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 pb-4 grid grid-cols-1 col-span-12 gap-4 mt-8 lg:gap-4 lg:mt-0 lg:grid-cols-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "sw-input-group",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "payments.payment_mode"
                                              ),
                                            },
                                          },
                                          [
                                            _c("sw-select", {
                                              staticClass: "mt-1",
                                              attrs: {
                                                options:
                                                  _vm.options_payment_methods,
                                                searchable: true,
                                                "show-labels": false,
                                                placeholder: _vm.$t(
                                                  "payments.select_payment_mode"
                                                ),
                                                "max-height": 150,
                                                label: "name",
                                                error: _vm.paymentMethodError,
                                                required: "",
                                              },
                                              on: {
                                                input: _vm.PaymentModeSelected,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData.payment_method,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "payment_method",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.payment_method",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formData.payment_method &&
                                    _vm.formData.payment_method.paypal_button &&
                                    _vm.customer != null
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-5" },
                                          [
                                            _c("paypal", {
                                              attrs: {
                                                formData: _vm.formData,
                                                codePayment: _vm.codePayment,
                                                invoice_number:
                                                  _vm.invoice_number,
                                                customer: _vm.customer,
                                              },
                                              on: {
                                                paypalSuccess:
                                                  _vm.paypalSuccess,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mt-1 ml-12" }, [
                                      this.add_payment_gateway_select &&
                                      !this.isEdit &&
                                      !this.type_ach &&
                                      this.type_cc &&
                                      !_vm.isLoadingThePaymentAccount &&
                                      _vm.paymentAccountPreview != null &&
                                      !_vm.isIdentificationVerification
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "start",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm
                                                      .paymentAccountPreview
                                                      .src,
                                                    width:
                                                      _vm.paymentAccountPreview
                                                        .width,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      padding: "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm
                                                              .paymentAccountPreview
                                                              .credit_card
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payment_accounts.ending_in"
                                                          )
                                                        ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          _vm
                                                            .paymentAccountPreview
                                                            .card_number
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "start",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "0",
                                                      "padding-right": "0px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b",
                                                      {
                                                        staticStyle: {
                                                          color: "#3939ff",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "customers.billing_address"
                                                            )
                                                          ) + ":"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.billingAddressPreview
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      this.add_payment_gateway_select &&
                                      !this.isEdit &&
                                      this.type_ach &&
                                      !this.type_cc &&
                                      !_vm.isLoadingThePaymentAccount &&
                                      _vm.paymentAccountPreview != null &&
                                      !_vm.isIdentificationVerification
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "start",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm
                                                      .paymentAccountPreview
                                                      .src,
                                                    width:
                                                      _vm.paymentAccountPreview
                                                        .width,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      padding: "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm
                                                              .paymentAccountPreview
                                                              .account_number_name
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payment_accounts.ending_in"
                                                          )
                                                        ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          _vm
                                                            .paymentAccountPreview
                                                            .account_number_value
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "start",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "0",
                                                      "padding-right": "0px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b",
                                                      {
                                                        staticStyle: {
                                                          color: "#3939ff",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "customers.billing_address"
                                                            )
                                                          ) + ":"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.billingAddressPreview
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mx-0 mt-0 flex justify-end",
                                      },
                                      [
                                        _c(
                                          "sw-dropdown",
                                          { attrs: { position: "bottom-end" } },
                                          [
                                            !_vm.isIdentificationVerification
                                              ? _c(
                                                  "sw-button",
                                                  {
                                                    staticClass: "ml-4",
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: _vm.isLoading,
                                                      loading: _vm.isLoading,
                                                      variant: "primary",
                                                      type: "button",
                                                    },
                                                    slot: "activator",
                                                  },
                                                  [
                                                    _c("credit-card-icon", {
                                                      staticClass:
                                                        "h-6 mr-1 -ml-2 font-bold",
                                                    }),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payment_accounts.add_or_change"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                on: {
                                                  click: _vm.addAccountModal,
                                                },
                                              },
                                              [
                                                _c("plus-icon", {
                                                  staticClass:
                                                    "h-6 mr-3 text-gray-600",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "1%",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payment_accounts.add_account"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.isPaymentAccounts
                                              ? _c(
                                                  "sw-dropdown-item",
                                                  {
                                                    on: {
                                                      click:
                                                        _vm.changeAccountModal,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "switch-horizontal-icon",
                                                      {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "payment_accounts.change_account"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 pb-4 grid grid-cols-1 col-span-12 gap-4 mt-8 lg:gap-4 lg:mt-0 lg:grid-cols-1 text-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mt-12 mb-6" },
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            staticClass: "no_document",
                                            attrs: {
                                              "bg-color":
                                                _vm.$utils.getBadgeStatusColor(
                                                  "I"
                                                ).bgColor,
                                              color:
                                                _vm.$utils.getBadgeStatusColor(
                                                  "I"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "payment_accounts.no_has_payment_methods"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isIdentificationVerification
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pt-2 pb-4 grid grid-cols-1 col-span-12 gap-4 mt-8 lg:gap-4 lg:mt-0 lg:grid-cols-1",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-full flex justify-end" },
                                  [
                                    !_vm.notEditable
                                      ? _c(
                                          "sw-button",
                                          {
                                            staticClass: "sm:flex",
                                            attrs: {
                                              loading: _vm.isLoading,
                                              disabled:
                                                _vm.isLoading ||
                                                !_vm.customerHasPaymentMethods,
                                              variant: "primary",
                                              type: "submit",
                                              size: "lg",
                                            },
                                          },
                                          [
                                            !_vm.isLoading
                                              ? _c("save-icon", {
                                                  staticClass: "mr-2 -ml-1",
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.isEdit
                                                    ? _vm.$t(
                                                        "payments.update_payment"
                                                      )
                                                    : _vm.$t("invoices.pay_now")
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("sw-button", {
                                          staticStyle: { display: "none" },
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isIdentificationVerification
                          ? _c(
                              "sw-button",
                              {
                                staticClass: "flex md:mt-4",
                                attrs: {
                                  loading: _vm.isLoading,
                                  disabled: _vm.isLoading,
                                  variant: "primary",
                                  type: "submit",
                                  size: "lg",
                                },
                              },
                              [
                                !_vm.isLoading &&
                                !_vm.isIdentificationVerification
                                  ? _c("save-icon", {
                                      staticClass: "mr-2 -ml-1",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isLoading &&
                                _vm.isIdentificationVerification
                                  ? _c("ArrowRightIcon", {
                                      staticClass: "mr-2 -ml-1",
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.showTextButtonSubmit) +
                                    "\n        "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        this.paymentfeesenabled && this.payment_fees.length > 0
                          ? _c("div", { staticClass: "w-full md:pr-2" }, [
                              _c("div", [
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  { staticClass: "text-lg font-bold mb-4" },
                                  [_vm._v("Payment Fees")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-1 md:grid-cols-4 gap-4 text-sm",
                                  },
                                  _vm._l(
                                    _vm.payment_fees,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "p-2 border rounded",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                              _vm._v(
                                                "\n                  -\n                  "
                                              ),
                                              _c("p", [
                                                _vm._v(_vm._s(item.type)),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.type == "fixed"
                                            ? _c("p")
                                            : _vm._e(),
                                          item.type == "fixed"
                                            ? _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      item.amount,
                                                      _vm.defaultCurrency
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("p"),
                                          _vm._v(" "),
                                          item.type == "percentage"
                                            ? _c("p", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.amount / 100) +
                                                    " %\n\n                "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "mt-4 text-sm",
                                    staticStyle: { color: "rgb(197, 48, 48)" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              These payment fees will be applied at the time of charging.\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowIdentificationVerification
          ? _c(
              "div",
              [
                _c("IdentityVerification", {
                  attrs: {
                    customer: _vm.customer,
                    date: _vm.formData.payment_date,
                    paymentMethod: _vm.formData.payment_method,
                    paymentGateway: _vm.formData.payment_gateways,
                    isVerificationSuccessful: _vm.verificationSuccessful,
                  },
                  on: {
                    verificationSuccessful: function ($event) {
                      _vm.verificationSuccessful = true
                    },
                    goToPayment: _vm.submitPaymentData,
                    cancelValidateEvent: _vm.backToForm,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }