var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.corePbx"),
                  to: "/admin/corePBX",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.custom_did_groups.title"),
                  to: "/admin/corePBX/billing-templates/custom-did-groups",
                },
              }),
              _vm._v(" "),
              _vm.isEdit
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t(
                        "corePbx.custom_did_groups.edit_custom_did_group"
                      ),
                      to: "#",
                      active: "",
                    },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t(
                        "corePbx.custom_did_groups.new_custom_did_group"
                      ),
                      to: "#",
                      active: "",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitCustomDidGroup.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("corePbx.custom_did_groups.name"),
                    error: _vm.nameError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      focus: "",
                      type: "text",
                      name: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: { label: _vm.$t("corePbx.custom_did_groups.status") },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      options: _vm.statuses,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t(
                        "corePbx.custom_did_groups.select_a_status"
                      ),
                      label: "name",
                    },
                    model: {
                      value: _vm.formData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("corePbx.custom_did_groups.type"),
                    error: _vm.typeError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.type.$error,
                      options: _vm.types,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t(
                        "corePbx.custom_did_groups.select_a_type"
                      ),
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.type.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "type", $$v)
                      },
                      expression: "formData.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    label: _vm.$t("corePbx.custom_did_groups.description"),
                    error: _vm.descriptionError,
                  },
                },
                [
                  _c("sw-textarea", {
                    attrs: { rows: "2", name: "description" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.description.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("corePbx.custom_did_groups.custom_dids")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive-item2" }, [
                _c("div", { staticClass: "tablemin" }, [
                  _c(
                    "table",
                    { staticClass: "w-full text-center item-table mt-2" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "thead",
                        {
                          staticClass:
                            "bg-white border border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pl-12" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t("corePbx.custom_did_groups.prefix")
                                    ) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t("corePbx.custom_did_groups.category")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pr-5" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t("corePbx.custom_did_groups.price")
                                    ) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        {
                          staticClass: "item-body",
                          attrs: { tag: "tbody", handle: ".handle" },
                          model: {
                            value: _vm.formData.customDids,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "customDids", $$v)
                            },
                            expression: "formData.customDids",
                          },
                        },
                        _vm._l(_vm.formData.customDids, function (did, index) {
                          return _c("custom-did", {
                            key: did.id,
                            attrs: {
                              index: index,
                              "custom-did-data": did,
                              "custom-did-group": _vm.formData.customDids,
                            },
                            on: {
                              remove: _vm.removeCustomDid,
                              update: _vm.updateCustomDid,
                              checkExists: _vm.checkExistCustomDid,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                  on: { click: _vm.addCustomDid },
                },
                [
                  _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.custom_did_groups.add_custom_did")
                      ) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pt-8 py-2 flex flex-col md:flex-row md:space-x-4",
                },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto",
                      attrs: {
                        loading: _vm.isLoading,
                        type: "submit",
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("corePbx.custom_did_groups.update")
                              : _vm.$t("corePbx.custom_did_groups.save")
                          ) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto mt-2 md:mt-0",
                      attrs: {
                        variant: "primary-outline",
                        type: "button",
                        size: "lg",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancelForm()
                        },
                      },
                    },
                    [
                      _c("x-circle-icon", {
                        staticClass: "w-6 h-6 mr-1 -ml-2",
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "30%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }