var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("avalara.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("avalara.title", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalAvalaraConfigs,
                          expression: "totalAvalaraConfigs",
                        },
                      ],
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/avalara/config/logs",
                        variant: "primary",
                      },
                    },
                    [
                      _c("eye-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("avalara.logs")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/avalara/config/create",
                        variant: "primary",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("avalara.add_new_config")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "/admin/avalara/config/glossary",
                        variant: "primary",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("avalara.glossary")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("avalara.filter.conexion") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "conexion",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.conexion,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "conexion", $$v)
                          },
                          expression: "filters.conexion",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("avalara.filter.user_name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "user_name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.user_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "user_name", $$v)
                          },
                          expression: "filters.user_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("avalara.no_avalara_config"),
                description: _vm.$t("avalara.list_of_avalara_configs"),
              },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/avalara/config/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("avalara.add_new_config")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.avalaraConfigs.length))]),
                    _vm._v(
                      "\n\n        " +
                        _vm._s(_vm.$t("general.of")) +
                        "\n\n        "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.totalAvalaraConfigs))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-transition",
                    { attrs: { type: "fade" } },
                    [
                      _vm.selectedAvalaraConfigs.length
                        ? _c(
                            "sw-dropdown",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n            "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  on: {
                                    click: _vm.removeMultipleAvalaraConfigs,
                                  },
                                },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.connection"),
                      show: "conexion",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "            \n          " +
                                  _vm._s(
                                    row.conexion == "production"
                                      ? _vm.$t("avalara.conexion_production")
                                      : _vm.$t("avalara.conexion_sandbox")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3385336710
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.user_name"),
                      show: "sh",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: `/admin/avalara/config/${row.id}/edit`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " \n              " +
                                      _vm._s(row.user_name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2478604597
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("avalara.item.status"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.status == "A" ? "A" : "I"
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.status == "A" ? "A" : "I"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        row.status == "A"
                                          ? "Active"
                                          : "Inactive"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2223392044
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.client_id"),
                      show: "client_id",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.url"),
                      show: "url",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("avalara.item.default"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("sw-switch", {
                                staticClass: "hidden md:inline",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeStatus(row)
                                  },
                                },
                                model: {
                                  value: row.intDefault,
                                  callback: function ($$v) {
                                    _vm.$set(row, "intDefault", $$v)
                                  },
                                  expression: "row.intDefault",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      577686595
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("general.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        "tag-name": "router-link",
                                        to: `/admin/avalara/config/${row.id}/edit`,
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.testConection(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("save-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("avalara.test_conection")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAvalaraConfig(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2913817637
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }