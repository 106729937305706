var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.$tc("providers.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/providers",
                  title: _vm.$tc("providers.provider", 2),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/providers`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/providers/${_vm.$route.params.id}/edit`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                { attrs: { position: "bottom-end" } },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.new_transaction")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.createExpenses
                    ? _c(
                        "sw-dropdown-item",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: `/admin/expenses/create?provider=${_vm.$route.params.id}`,
                          },
                        },
                        [
                          _c("calculator-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("expenses.new_expense")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.permissionModule.delete
                ? _c(
                    "sw-button",
                    {
                      attrs: { slot: "activator", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.removeProvider(_vm.$route.params.id)
                        },
                      },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
        _c("div", { staticClass: "pt-6 mt-5" }, [
          _c("div", { staticClass: "col-span-12" }, [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("providers.basic_info")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.titl")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.formData.title) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.name")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.formData.first_name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.last_name")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.formData.last_name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.email")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.formData.email) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.country")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.country
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.country) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.not_selected")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.state")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.state
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.state) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.not_selected")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.city")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.city
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.city) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.street")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.street
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.street) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.zip_code")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.zip_code
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.zip_code) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.phone")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.phone
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.phone) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.mobile")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.mobile
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.mobile) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.webside")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.webside
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.webside) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.terms")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.terms
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.terms) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.account_no")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.account_no
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.account_no) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.business_no")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.business_no
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formData.business_no) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.track_payments")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.track_payments
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.yes")) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.no")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("providers.description")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.description
                    ? _c("p", {
                        staticClass: "text-sm leading-5 text-black non-italic",
                        domProps: {
                          innerHTML: _vm._s(_vm.formData.description),
                        },
                      })
                    : _c(
                        "p",
                        {
                          staticClass:
                            "text-sm leading-5 text-black non-italic",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("providers.empty")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.permissionModule.accessExpenses
        ? _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
            _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
              _c("div", { staticClass: "border-b tab" }, [
                _c("div", { staticClass: "relative" }, [
                  _c("input", {
                    staticClass:
                      "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                    attrs: { type: "checkbox", id: "chck5" },
                  }),
                  _vm._v(" "),
                  _c(
                    "header",
                    {
                      staticClass:
                        "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                      attrs: { for: "chck5" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-gray-500 uppercase sw-section-title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tc("expenses.expense", 2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                "aria-hidden": "true",
                                "data-reactid": "266",
                                fill: "none",
                                height: "24",
                                stroke: "#606F7B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                viewbox: "0 0 24 24",
                                width: "24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("polyline", {
                                attrs: { points: "6 9 12 15 18 9" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tab-content-customer" },
                    [
                      _c("div", { staticClass: "text-grey-darkest" }, [
                        _c("div", { staticClass: "flex base-tabs" }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "sw-table-component",
                        {
                          ref: "expenses_table",
                          attrs: {
                            "show-filter": false,
                            data: _vm.fetchExpensesData,
                            "table-class": "table",
                          },
                        },
                        [
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("expenses.date"),
                              "sort-as": "expense_date",
                              show: "formattedExpenseDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("expenses.expense_number"),
                              show: "expense_number",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("expenses.expense_number")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.permissionModule.updateExpenses
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "font-medium text-primary-500",
                                              attrs: {
                                                to: {
                                                  path: `/admin/expenses/${row.id}/edit`,
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(row.expense_number) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(row.expense_number) +
                                                "\n                  "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3147941447
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              label: _vm.$tc("expenses.subject"),
                              sortable: true,
                              "sort-as": "subject",
                              show: "subject",
                            },
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$tc("expenses.categories.category", 1),
                              "sort-as": "name",
                              show: "category.name",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tc(
                                              "expenses.categories.category",
                                              1
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(row.category.name) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              743959943
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("expenses.customer"),
                              "sort-as": "user_name",
                              show: "user_name",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("expenses.customer"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              row.user_name
                                                ? row.user_name
                                                : "Not selected"
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              955581425
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("expenses.amount"),
                              "sort-as": "amount",
                              show: "category.amount",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("expenses.amount"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.amount,
                                              _vm.currency
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3682967646
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("general.status"),
                              show: "status",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.status"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              row.status == "Active"
                                                ? _vm.$t(
                                                    "expenses.status.active"
                                                  )
                                                : _vm.$t(
                                                    "expenses.status.pending"
                                                  )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              286992071
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: false,
                              filterable: false,
                              "cell-class": "action-dropdown no-click",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("expenses.action"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown",
                                        [
                                          _c("dot-icon", {
                                            attrs: { slot: "activator" },
                                            slot: "activator",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                "tag-name": "router-link",
                                                to: {
                                                  path: `/admin/expenses/${row.id}/edit`,
                                                },
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                "tag-name": "router-link",
                                                to: {
                                                  path: `/admin/expenses/${row.id}/view`,
                                                },
                                              },
                                            },
                                            [
                                              _c("eye-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("invoices.view")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                "tag-name": "router-link",
                                                to: `/admin/expenses/${row.id}/docs`,
                                              },
                                            },
                                            [
                                              _c("document-text-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.docs")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeExpense(
                                                    row.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("trash-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1508886978
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }