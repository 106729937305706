var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: "View Note" } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelNote()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c("sw-card", [
              _c("div", [
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.$t("general.note_information")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-wrap mt-4 md:mt-6" }, [
                  _c("div", { staticClass: "w-full md:w-1/4" }, [
                    _c("div", { staticClass: "font-bold py-2" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.$t("general.reference")) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", { staticClass: "text-gray-700 text-sm" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.note.reference) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full md:w-1/4" }, [
                    _c("div", { staticClass: "font-bold py-2" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.$t("expenses.subject")) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", { staticClass: "text-gray-700 text-sm" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.note.subject) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full md:w-1/4" }, [
                    _c("div", { staticClass: "font-bold py-2" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.$t("general.datetime")) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", { staticClass: "text-gray-700 text-sm" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.note.date) +
                            " " +
                            _vm._s(_vm.note.time) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full md:w-1/4" }, [
                  _c("div", { staticClass: "font-bold py-2" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("customer_ticket.note_ticket_view")) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.note.public === 1 ? "YES" : "NOT") +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex flex-wrap mt-5 md:mt-7" }, [
                  _c("div", { staticClass: "w-full md:w-1/2" }, [
                    _c("p", { staticClass: "font-bold" }, [
                      _vm._v("  " + _vm._s(_vm.$t("general.message"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-gray-700 text-sm" }, [
                      _vm._v(_vm._s(_vm.note.message)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }