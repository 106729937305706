<template>
  <!-- Base  -->
  <base-page v-if="isSuperAdmin" class="option-group-create">
    <!--------- Form ---------->
    <form action="" @submit.prevent="submitCustomerContact">
      <!-- Header  -->
      <sw-page-header class="mb-3" :title="pageTitle">
        <template slot="actions">
          <sw-button
            class="mr-3 text-sm hidden sm:flex"
            variant="primary-outline"
            type="button"
            @click="cancelForm()"
          >
            <x-circle-icon v-if="!isLoading" class="mr-2 -ml-1" />
            {{ $t('general.cancel') }}
          </sw-button>

          <sw-button
            :loading="isLoading"
            variant="primary"
            type="submit"
            class="hidden sm:flex"
          >
            <save-icon class="mr-2" v-if="!isLoading" />
            {{ !isEdit ? $t('general.save') : $t('general.update') }}
          </sw-button>
        </template>
      </sw-page-header>
      <sw-card class="mb-8">
        <div class="grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5">
          <sw-input-group
            :label="$t('contacts.name')"
            :error="nameError"
            class="mt-4"
            required
            variant="horizontal"
          >
            <sw-input
              v-model="formData.name"
              :invalid="$v.formData.name.$error"
              name="formData.name"
              type="text"
              @input="$v.formData.name.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.last_name')"
            class="mt-4"
            variant="horizontal"
            :error="lastNameError"
            required
          >
            <sw-input
              v-model="formData.last_name"
              name="formData.last_name"
              type="text"
              :invalid="$v.formData.last_name.$error"
              @input="$v.formData.last_name.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.phone')"
            class="mt-4"
            :error="phoneError"
            required
            variant="horizontal"
          >
            <sw-input
              v-model="formData.phone"
              :invalid="$v.formData.phone.$error"
              name="formData.phone"
              type="text"
              @input="$v.formData.phone.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.position')"
            class="mt-4"
            variant="horizontal"
            :error="positionError"
            required
          >
            <sw-input
              v-model="formData.position"
              name="formData.position"
              type="text"
              :autocomplete="false"
              :invalid="$v.formData.position.$error"
              @input="$v.formData.position.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.email')"
            class="mt-4"
            :error="emailError"
            required
            variant="horizontal"
          >
            <sw-input
              v-model="formData.email"
              :invalid="$v.formData.email.$error"
              name="formData.email"
              type="text"
              @input="$v.formData.email.$touch()"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.allow_receive_emails')"
            class="mt-4"
            variant="horizontal"
          >
            <sw-switch
              v-model="formData.allow_receive_emails"
              name="formData.allow_receive_emails"
            />
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.type')"
            class="mt-4"
            variant="horizontal"
          >
            <!-- :class="{ error: $v.formData.currency.$error }" -->
            <!-- :custom-label="currencyNameWithCode" -->
            <sw-select
              v-model="formData.type"
              :options="types"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('contacts.select_type')"
              class="mt-2"
              label="option"
              track-by="id"
            >
            </sw-select>
          </sw-input-group>

          <sw-input-group
            :label="$t('contacts.log_in_credentials')"
            class="mt-4"
            variant="horizontal"
            v-if="false"
          >
            <sw-switch
              v-model="formData.log_in_credentials"
              name="formData.log_in_credentials"
            />
          </sw-input-group>
          <!-- {{this.formData.log_in_credentials}} -->
        </div>

        <!--  -->

        <h6
          v-if="isAllowReceiveEmails"
          class="col-span-5 sw-section-title lg:col-span-1 mt-3 mt-md-0"
        >
          {{ $t('contacts.allow_receive_emails') }}
        </h6>
        <div
          v-if="isAllowReceiveEmails"
          class="grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5"
        >
          <sw-input-group :label="$t('general.receive_email_estimates')">
            <sw-switch
              v-model="formData.email_estimates"
              name="formData.email_estimates"
            />
          </sw-input-group>

          <sw-input-group :label="$t('general.receive_email_invoices')">
            <sw-switch
              v-model="formData.email_invoices"
              name="formData.email_invoices"
            />
          </sw-input-group>

          <sw-input-group :label="$t('general.receive_email_payments')">
            <sw-switch
              v-model="formData.email_payments"
              name="formData.email_payments"
            />
          </sw-input-group>

          <sw-input-group :label="$t('general.receive_email_services')">
            <sw-switch
              v-model="formData.email_services"
              name="formData.email_services"
            />
          </sw-input-group>

          <sw-input-group :label="$t('general.receive_email_pbxservices')">
            <sw-switch
              v-model="formData.email_pbx_services"
              name="formData.email_pbx_services"
            />
          </sw-input-group>

          <sw-input-group :label="$t('general.receive_email_tickets')">
            <sw-switch
              v-model="formData.email_tickets"
              name="formData.email_tickets"
            />
          </sw-input-group>
        </div>

        <!--  -->

        <h6
          v-if="isLogInCredentials"
          class="col-span-5 sw-section-title lg:col-span-1 mt-3 mt-md-0"
        >
          {{ $t('customers.login') }}
        </h6>

        <div
          v-if="isLogInCredentials"
          class="grid md:grid-cols-2 lg:p-8 sm:p-1 col-span-5"
        >
          <sw-input-group :label="$t('contacts.password')" required>
            <sw-input
              v-model="formData.password"
              :invalid="$v.formData.password.$error"
              name="formData.password"
              :type="showPassword ? 'text' : 'password'"
              @input="$v.formData.password.$touch()"
            >
              <template v-slot:rightIcon>
                <eye-off-icon
                  v-if="showPassword"
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="showPassword = !showPassword"
                />
                <eye-icon
                  v-else
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="showPassword = !showPassword"
                />
              </template>
            </sw-input>
          </sw-input-group>

          <sw-input-group :label="$t('contacts.repeat_password')" required>
            <sw-input
              v-model="formData.repeat_password"
              :invalid="$v.formData.repeat_password.$error"
              name="formData.repeat_password"
              :type="showPassword ? 'text' : 'password'"
              @input="$v.formData.repeat_password.$touch()"
            >
              <template v-slot:rightIcon>
                <eye-off-icon
                  v-if="showPassword"
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="showPassword = !showPassword"
                />
                <eye-icon
                  v-else
                  class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
                  @click="showPassword = !showPassword"
                />
              </template>
            </sw-input>
            <!-- @change="checkRepeatPassword" -->
            <small v-if="errorPasswordsEquals"
              ><p style="color: red">
                {{ $t('customers.generate_password_must') }}
              </p></small
            >
          </sw-input-group>

          <sw-input-group :label="$t('contacts.generate_password')">
            <sw-button
              class="mr-3 text-sm"
              variant="primary-outline"
              type="button"
              @click="generate()"
            >
              {{ $t('customers.generate_password') }}
            </sw-button>
          </sw-input-group>

          <sw-input-group :label="$t('contacts.invoices')">
            <sw-switch v-model="formData.invoices" name="formData.invoices" />
          </sw-input-group>

          <sw-input-group :label="$t('contacts.estimates')" class="mt-4">
            <sw-switch v-model="formData.estimates" name="formData.estimates" />
          </sw-input-group>

          <sw-input-group :label="$t('contacts.payments')" class="mt-4">
            <sw-switch v-model="formData.payments" name="formData.payments" />
          </sw-input-group>

          <sw-input-group :label="$t('contacts.tickets')">
            <sw-switch v-model="formData.tickets" name="formData.tickets" />
          </sw-input-group>

          <sw-input-group :label="$t('contacts.payments_accounts')">
            <sw-switch
              v-model="formData.payments_accounts"
              name="formData.payments_accounts"
            />
          </sw-input-group>
        </div>

        <sw-button
          class="mr-3 flex w-full mt-4 sm:hidden md:hidden"
          variant="primary-outline"
          type="button"
          @click="cancelForm()"
        >
          <x-circle-icon v-if="!isLoading" class="mr-2 -ml-1" />
          {{ $t('general.cancel') }}
        </sw-button>

        <sw-button
          :loading="isLoading"
          variant="primary"
          type="submit"
          class="flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden"
        >
          <save-icon class="mr-2" v-if="!isLoading" />
          {{ !isEdit ? $t('general.save') : $t('general.update') }}
        </sw-button>
      </sw-card>
    </form>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  CheckIcon,
  EyeOffIcon,
  EyeIcon,
  XCircleIcon,
} from '@vue-hero-icons/solid'
// import { required, alphaNum, helpers } from '@vuelidate/validators'

const {
  required,
  helpers,
  numeric,
  email,
} = require('vuelidate/lib/validators')
// const number = helpers.regex(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)

export default {
  components: {
    XCircleIcon,
    CheckIcon,
    EyeOffIcon,
    EyeIcon,
  },
  props: {
    characters: {
      type: String,
      default: 'a-z,A-Z,0-9,#',
    },
    size: {
      type: String,
      default: '12',
    },
  },
  data() {
    return {
      // isEdit: false,
      isLoading: false,
      showPassword: false,
      bandGeneratePassword: false,
      characters: 'a-z,A-Z,0-9,#',
      size: 12,
      formData: {
        id: null,
        name: null,
        last_name: null,
        phone: null,
        email: null,
        position: null,
        allow_receive_emails: null,
        type: null,
        log_in_credentials: false,
        allow_receive_emails: false,
        password: null,
        repeat_password: null,
        invoices: true,
        estimates: true,
        payments: true,
        tickets: true,
        payments_accounts: true,
        reports: true,
        email_estimates: false,
        email_invoices: false,
        email_payments: false,
        email_services: false,
        email_pbx_services: false,
        email_tickets: false,
      },
      user_id: null,
      errorPasswordsEquals: false,
      types: [
        { id: 1, value: 'B', option: this.$t('contacts.types.billing') },
        { id: 2, value: 'S', option: this.$t('contacts.types.support') },
        { id: 3, value: 'R', option: this.$t('contacts.types.reports') },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),

    isSuperAdmin() {
      return this.currentUser.role == 'super admin'
    },
    isEdit() {
      if (this.$route.name === 'customers.edit-contact') {
        return true
      }
      return false
    },

    isLogInCredentials() {
      if (this.formData.log_in_credentials) {
        return true
      }
      return false
    },

    isAllowReceiveEmails() {
      if (this.formData.allow_receive_emails) {
        return true
      }
      return false
    },

    pageTitle() {
      if (this.$route.name === 'customers.edit-contact') {
        return this.$t('contacts.edit_contact')
      }
      return this.$t('contacts.add_new_contact')
    },
    nameError() {
      if (!this.$v.formData.name.$error) {
        return ''
      }
      if (!this.$v.formData.name.required) {
        return this.$tc('validation.required')
      }
    },
    lastNameError() {
      if (!this.$v.formData.last_name.$error) {
        return ''
      }
      if (!this.$v.formData.last_name.required) {
        return this.$tc('validation.required')
      }
    },
    phoneError() {
      if (!this.$v.formData.phone.$error) {
        return ''
      }
      if (!this.$v.formData.phone.required) {
        return this.$tc('validation.required')
      }
    },
    positionError() {
      if (!this.$v.formData.position.$error) {
        return ''
      }
      if (!this.$v.formData.position.required) {
        return this.$tc('validation.required')
      }
    },
    emailError() {
      if (!this.$v.formData.email.$error) {
        return ''
      }
      if (!this.$v.formData.email.required) {
        return this.$tc('validation.required')
      }
    },
    passwordError() {
      if (!this.$v.formData.password.$error) {
        return ''
      }
      if (!this.$v.formData.password.required) {
        return this.$tc('validation.required')
      }
    },
    repeatPasswordError() {
      if (!this.$v.formData.repeatPassword.$error) {
        return ''
      }
      if (!this.$v.formData.repeatPassword.required) {
        return this.$tc('validation.required')
      }
    },
  },
  beforeDestroy() {
    // this.unsubscribe()
  },
  created() {
    this.loadData()
    // this.subscribeAvalaraBillingInfo()
  },
  validations() {
    if (true) {
      return {
        formData: {
          name: {
            required,
          },
          last_name: {
            required,
          },
          phone: {
            required,
            numeric,
          },
          position: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
          },
          repeat_password: {
            required,
          },
        },
      }
    } else {
      return {
        formData: {
          name: {
            required,
          },
          phone: {
            required,
            numeric,
          },
          email: {
            required,
            email,
          },
        },
      }
    }
  },
  async mounted() {
    this.user_id = this.$route.params.id
  },
  watch: {
    'formData.repeat_password': {
      deep: true,
      handler: 'checkRepeatPassword',
    },
  },
  methods: {
    ...mapActions('customerContacts', [
      'addCustomerContact',
      'updateCustomerContact',
      'fetchCustomerContact',
    ]),
    ...mapActions('customer', [
      'fetchCustomer',
      'billingValidation',
      /* 'addCustomer',
      'updateCustomer', */
    ]),

    ...mapActions('user', ['getUserModules']),

    resetFormData() {
      this.$v.formData.$reset()
    },
    /**
     * Verifica si la contraseña y la repetición de la contraseña son iguales.
     * Actualiza el estado de error si las contraseñas no coinciden.
     */
    checkRepeatPassword() {
      // Compara la contraseña y la repetición de la contraseña
      if (this.formData.password !== this.formData.repeat_password) {
        // Establece el estado de error si las contraseñas no coinciden
        this.errorPasswordsEquals = true
      } else {
        // Restablece el estado de error si las contraseñas coinciden
        this.errorPasswordsEquals = false
      }
    },
    /**
     * Genera una contraseña aleatoria basada en los caracteres especificados.
     * Actualiza los campos de contraseña y repetir contraseña en el formulario.
     */
    generate() {
      // Indica que se ha generado una contraseña
      this.bandGeneratePassword = true

      // Divide la cadena de caracteres en un array
      let charactersArray = this.characters.split(',')

      // Inicializa el conjunto de caracteres y la contraseña
      let characterSet = ''
      let password = ''

      // Agrega caracteres al conjunto según las opciones seleccionadas
      if (charactersArray.includes('a-z')) {
        characterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.includes('A-Z')) {
        characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.includes('0-9')) {
        characterSet += '0123456789'
      }
      if (charactersArray.includes('#')) {
        characterSet += '!%&*$#@'
      }

      // Genera la contraseña aleatoria
      for (let i = 0; i < this.size; i++) {
        password += characterSet.charAt(
          Math.floor(Math.random() * characterSet.length)
        )
      }

      // Actualiza los campos de contraseña en el formulario
      this.formData.password = password
      this.formData.repeat_password = password
    },
    /**
     * Carga los datos necesarios y verifica los permisos del usuario para el módulo 'cust_contacts'.
     * Redirige al usuario al dashboard si no tiene los permisos adecuados.
     * Si está en modo edición, carga los datos del contacto.
     */
    async loadData() {
      const data = {
        module: 'cust_contacts',
      }

      try {
        const permissions = await this.getUserModules(data)

        // Verifica si el usuario no es super administrador
        if (!permissions.super_admin) {
          // Si el módulo no existe o no tiene permisos, redirige al dashboard
          if (!permissions.exist) {
            this.$router.push('/admin/dashboard')
            return
          }

          const modulePermissions = permissions.permissions[0]

          // Verifica los permisos de creación y edición
          if (
            (modulePermissions.create === 0 && !this.isEdit) ||
            (modulePermissions.update === 0 && this.isEdit)
          ) {
            this.$router.push('/admin/dashboard')
            return
          }
        }

        // Si está en modo edición, carga los datos del contacto
        if (this.isEdit) {
          await this.setData()
        }
      } catch (error) {
        console.error('Error al cargar los datos del módulo:', error)
        this.$router.push('/admin/dashboard')
      }
    },
    /**
     * Valida y envía los datos del contacto del cliente.
     * Muestra un cuadro de diálogo de confirmación antes de enviar.
     * Si la validación falla, muestra un mensaje de error.
     * @returns {boolean} - Retorna true si la validación falla.
     */
    async submitCustomerContact() {
      // Toca todos los campos del formulario para activar la validación
      this.$v.formData.$touch()

      // Función para validar el número de teléfono
      const validNumberPhone = (number) => {
        return /^\d+$/.test(this.formData.phone)
      }

      // Verifica si los campos del formulario son válidos
      if (
        this.$v.formData.email.$invalid ||
        this.$v.formData.name.$invalid ||
        this.$v.formData.phone.$invalid ||
        !validNumberPhone(this.formData.phone)
      ) {
        window.toastr['error']('Please fill in all required fields correctly.')
        return true
      }

      // Verifica si las credenciales de inicio de sesión y las contraseñas son válidas
      if (
        this.formData.log_in_credentials &&
        (this.$v.formData.password.$invalid ||
          this.$v.formData.repeat_password.$invalid)
      ) {
        window.toastr['error']('Please fill in the password fields correctly.')
        return true
      }

      // Determina el texto del cuadro de diálogo según el modo (crear o editar)
      let text = this.isEdit
        ? 'contacts.update_contact_text'
        : 'contacts.create_contact_text'

      // Muestra el cuadro de diálogo de confirmación
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc(text),
        icon: '/assets/icon/file-alt-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          // Indica que la solicitud está en curso
          this.isLoading = true

          let response
          this.formData.customer_id = this.user_id
          this.formData.type = this.formData.type?.value

          // Realiza la solicitud para agregar o actualizar el contacto
          if (!this.isEdit) {
            response = await this.addCustomerContact(this.formData)
          } else {
            this.formData.id = this.$route.params.idContact
            response = await this.updateCustomerContact(this.formData)
          }

          // Verifica la respuesta de la solicitud
          if (response.data) {
            if (!this.isEdit) {
              window.toastr['success'](this.$t('contacts.created_message'))
            } else {
              window.toastr['success'](this.$t('contacts.updated_message'))
            }

            // Redirige a la lista de contactos del cliente
            this.$router.push(
              `/admin/customers/${this.$route.params.id}/contacts`
            )
            this.isLoading = false
            return true
          }

          // Muestra un mensaje de error si la solicitud falla
          window.toastr['error'](
            response.data.error || 'Unspecified error, contact administrator'
          )
          this.isLoading = false
        }
      })
    },

    /**
     * Fetches customer contact data and sets the form data accordingly.
     * If the contact type is specified, it sets the type details.
     * If the fetch fails, it resets the form data to default values.
     */
    async setData() {
      // Indica que la solicitud está en curso
      this.isLoading = true

      try {
        // Realiza la solicitud para obtener los datos del contacto
        let res = await this.fetchCustomerContact(this.$route.params.idContact)

        // Verifica si la solicitud fue exitosa
        if (res.data.success) {
          const contact = res.data.contact

          // Establece los datos del formulario con los datos del contacto
          this.formData = {
            name: contact.name,
            last_name: contact.last_name,
            phone: contact.phone,
            position: contact.position,
            email: contact.email,
            allow_receive_emails: contact.allow_receive_emails,
            log_in_credentials: contact.log_in_credentials,
            password: contact.PasswordDecode,
            repeat_password: contact.PasswordDecode,
            invoices: contact.invoices,
            estimates: contact.estimates,
            payments: contact.payments,
            tickets: contact.tickets,
            payments_accounts: contact.payments_accounts,
            reports: contact.reports,
            email_estimates: contact.email_estimates,
            email_invoices: contact.email_invoices,
            email_payments: contact.email_payments,
            email_services: contact.email_services,
            email_pbx_services: contact.email_pbx_services,
            email_tickets: contact.email_tickets,
            type: this.getContactType(contact.type),
          }
        } else {
          // Restablece los datos del formulario a los valores predeterminados
          this.resetFormData()
        }
      } catch (error) {
        console.error('Error al obtener los datos del contacto:', error)
        // Restablece los datos del formulario a los valores predeterminados en caso de error
        this.resetFormData()
      } finally {
        // Indica que la solicitud ha finalizado
        this.isLoading = false
      }
    },

    /**
     * Devuelve los detalles del tipo de contacto basado en el tipo proporcionado.
     * @param {string} type - Tipo de contacto ('B', 'S', 'R').
     * @returns {Object} - Detalles del tipo de contacto.
     */
    getContactType(type) {
      let optionSelected = ''
      let idTypeSelected = ''
      let typeSelected = false

      switch (type) {
        case 'B':
          idTypeSelected = 1
          optionSelected = 'Billing'
          typeSelected = true
          break
        case 'S':
          idTypeSelected = 2
          optionSelected = 'Support'
          typeSelected = true
          break
        case 'R':
          idTypeSelected = 3
          optionSelected = 'Reports'
          typeSelected = true
          break
        default:
          break
      }

      return {
        id: idTypeSelected,
        value: type,
        option: optionSelected,
        selected: typeSelected,
      }
    },

    /**
     * Restablece los datos del formulario a los valores predeterminados.
     */
    resetFormData() {
      this.formData = {
        name: null,
        last_name: null,
        phone: null,
        position: null,
        email: null,
        allow_receive_emails: null,
        log_in_credentials: null,
        password: null,
        repeat_password: null,
        invoices: true,
        estimates: true,
        payments: true,
        tickets: true,
        payments_accounts: true,
        reports: true,
        email_estimates: false,
        email_invoices: false,
        email_payments: false,
        email_services: false,
        email_pbx_services: false,
        email_tickets: false,
        type: {},
      }
    },
    /**
     * Muestra un cuadro de diálogo de confirmación para cancelar el formulario.
     * Si el usuario confirma, navega a la página anterior.
     */
    async cancelForm() {
      // Muestra el cuadro de diálogo de confirmación
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.lose_unsaved_information'),
        icon: 'error',
        buttons: true,
        dangerMode: true,
      }).then(async (result) => {
        // Si el usuario confirma, navega a la página anterior
        if (result) {
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>

<style scoped></style>
