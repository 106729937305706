export const BOOTSTRAP_PAYMENT_FEES = 'BOOTSTRAP_PAYMENT_FEES'
export const SET_TOTAL_PAYMENT_FEES = 'SET_TOTAL_PAYMENT_FEES'
export const ADD_PAYMENT_FEE = 'ADD_PAYMENT_FEE'
export const UPDATE_PAYMENT_FEE = 'UPDATE_PAYMENT_FEE'
export const DELETE_PAYMENT_FEE = 'DELETE_PAYMENT_FEE'
export const DELETE_MULTIPLE_PAYMENT_FEES = 'DELETE_MULTIPLE_PAYMENT_FEES'
export const SET_SELECTED_PAYMENT_FEES = 'SET_SELECTED_PAYMENT_FEES'
export const RESET_SELECTED_PAYMENT_FEE = 'RESET_SELECTED_PAYMENT_FEE'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_PAYMENT_FEE = 'SET_SELECTED_VIEW_PAYMENT_FEE'
export const SET_PAYMENT_FEES = 'SET_PAYMENT_FEES'