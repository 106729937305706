var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.isBarHorizontal
        ? "graph-container-horizontal"
        : "graph-container",
    },
    [_c("canvas", { ref: "graph", attrs: { id: "graph" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }