var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-52": _vm.showSideBar } },
    [
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customer_ticket.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("customer_ticket.title", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end md:-mt-16" },
        [
          _c(
            "div",
            { staticClass: "mr-3 hidden xl:block" },
            [
              _c(
                "sw-button",
                {
                  attrs: { variant: "primary-outline" },
                  on: { click: _vm.toggleListCustomers },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("tickets.departaments.menu")) +
                      "\n        "
                  ),
                  _c(_vm.listIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalCustomerTickets,
                  expression: "totalCustomerTickets",
                },
              ],
              staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
              attrs: { variant: "primary-outline", size: "lg" },
              on: { click: _vm.toggleFilter },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.filter")) + "\n      "
              ),
              _c(_vm.filterIcon, {
                tag: "component",
                staticClass: "w-4 h-4 ml-2 -mr-1",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.permissionModule.create
            ? _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: "main/add-ticket",
                    variant: "primary",
                    size: "lg",
                  },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v("\n      " + _vm._s(_vm.$t("tickets.add")) + "\n    "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-100",
                  staticStyle: { "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0",
                      attrs: { label: _vm.$t("expenses.customer") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          options: _vm.users_select,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("expenses.customer"),
                          label: "name",
                        },
                        on: {
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.user,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "user", $$v)
                          },
                          expression: "filters.user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("customer_ticket.departament") } },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        staticStyle: { "min-width": "300px" },
                        attrs: {
                          options: _vm.departaments,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customer_ticket.departament"),
                          label: "name",
                        },
                        on: {
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.departament,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "departament", $$v)
                          },
                          expression: "filters.departament",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-50", staticStyle: { "margin-right": "1em" } },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("customer_ticket.assignedTo") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.users,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customer_ticket.assignedTo"),
                          label: "name",
                        },
                        on: {
                          click: function ($event) {
                            _vm.filter = !_vm.filter
                          },
                        },
                        model: {
                          value: _vm.filters.assignedTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "assignedTo", $$v)
                          },
                          expression: "filters.assignedTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: {
                        label: _vm.$t("tickets.departaments.default_priority"),
                      },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.default_prioritys,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 16,
                          "allow-empty": true,
                          placeholder: _vm.$t(
                            "tickets.departaments.default_priority"
                          ),
                          label: "text",
                          "track-by": "value",
                        },
                        model: {
                          value: _vm.filters.priority,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "priority", $$v)
                          },
                          expression: "filters.priority",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-50",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("expenses.from_date") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "mt-2",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("expenses.to_date") },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "mt-2",
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-25",
                  staticStyle: { "margin-left": "1em", "margin-right": "1em" },
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                      attrs: { label: _vm.$t("providers.status") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.status,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 16,
                          "allow-empty": true,
                          placeholder: _vm.$t("providers.status"),
                          label: "text",
                          "track-by": "value",
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmptyScreen
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("customer_ticket.no_customer_ticket"),
                description: _vm.$t("customer_ticket.list_of_customer_ticket"),
              },
            },
            [
              _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: `main/add-ticket`,
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "-mt-10 md:mt-0",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.ticket_number"),
                  show: "ticket_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("customer_ticket.ticket_number"))
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: `main/${row.user_id}/${row.id}/view-ticket`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.ticket_number) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(row.ticket_number)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.summary"),
                  show: "summary",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.summary"))),
                        ]),
                        _vm._v(" "),
                        _vm.permissionModule.read
                          ? _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: `main/${row.user_id}/${row.id}/view-ticket`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.summary) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(row.summary))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.departament"),
                  show: "departament",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.departament"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: {
                                path: `/admin/tickets/departaments/${row.dep_id}/view`,
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.departament) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.assignedTo"),
                  show: "assigned",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.assignedTo"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: {
                                path: `/admin/users/${row.assigned_id}/view`,
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.assigned) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.status"))),
                        ]),
                        _vm._v(" "),
                        row.status == "S"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "C"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("UNPAID")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("UNPAID")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "I"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "O"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "M"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("tickets.departaments.default_priority"),
                  show: "priority",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("tickets.departaments.default_priority")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        row.priority == "E" ||
                        row.priority == "C" ||
                        row.priority == "H"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getPriority(row.priority)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.priority == "M"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getPriority(row.priority)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.priority == "L"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getPriority(row.priority)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.date"),
                  "sort-as": "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.date")))]),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.date != "0000-00-00"
                                ? row.date + " " + row.time
                                : row.formattedCustomerNoteDate
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `main/${row.user_id}/${row.id}/view-ticket`,
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `main/${row.user_id}/${row.id}/edit-ticket`,
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePayment(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
      },
      [_c("p", { staticClass: "text-sm" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }