var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-end -mb-7" },
        [
          _c(
            "sw-button",
            {
              attrs: {
                "tag-name": "router-link",
                to: `/admin/corePBX/tenant/tenants-list`,
                variant: "primary-outline",
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.go_back")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-page-header", {
        staticClass: "mb-5",
        attrs: { title: _vm.$tc("corePbx.tenants.new_tenant") },
      }),
      _vm._v(" "),
      _c("sw-card", [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitTenant.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "flex justify-end" }, [
              _c(
                "label",
                {
                  staticClass:
                    "text-sm leading-snug text-gray-900 cursor-pointer",
                  on: { click: _vm.clearAll },
                },
                [_vm._v("\n          " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-5 col-span-6" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-6",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.pbx_server"),
                      error: _vm.pbxServerError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: _vm.serverPbxOptions,
                        invalid: _vm.$v.formData.pbxServer.$error,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 2,
                        "allow-empty": true,
                        label: "server_label",
                        "track-by": "id",
                      },
                      on: {
                        select: _vm.selectServerPbxMetod,
                        input: function ($event) {
                          return _vm.$v.formData.pbxServer.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.pbxServer,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "pbxServer",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.pbxServer",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-12 gap-4 gap-y-6" },
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-3",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.package"),
                      error: _vm.packageError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        disabled: !_vm.formData.pbxServer,
                        options: _vm.packagesOptions,
                        invalid: _vm.$v.formData.package.$error,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 2,
                        "allow-empty": true,
                        placeholder: _vm.$t("packages.select_package"),
                        label: "name",
                        "track-by": "code",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.package.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.package,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "package",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.package",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-3",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.country"),
                      error: _vm.countryError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        disabled: !_vm.formData.pbxServer,
                        invalid: _vm.$v.formData.country.$error,
                        options: _vm.countryOptions,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 2,
                        "allow-empty": true,
                        placeholder: _vm.$t("general.select_country"),
                        label: "name",
                        "track-by": "code",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.country.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.country,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "country",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.country",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-3",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.international"),
                      error: _vm.internationalError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("corePbx.tenants.international"),
                        invalid: _vm.$v.formData.international.$error,
                        disabled: !_vm.formData.pbxServer,
                        type: "text",
                        tabindex: "1",
                        placer: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.international.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.international,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "international",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.international",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-3",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.national"),
                      error: _vm.nationalError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("corePbx.tenants.national"),
                        invalid: _vm.$v.formData.national.$error,
                        disabled: !_vm.formData.pbxServer,
                        type: "text",
                        tabindex: "1",
                        placer: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.national.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.national,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "national",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.national",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-4",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.name"),
                      error: _vm.nameError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.formData.tenant_name.$error,
                        placeholder: _vm.$t("items.name"),
                        disabled: !_vm.formData.pbxServer,
                        focus: "",
                        type: "tex!",
                        name: "name",
                        tabindex: "1",
                        placer: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.tenant_name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.tenant_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "tenant_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.tenant_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "col-span-12 md:col-span-4",
                    attrs: {
                      label: _vm.$t("corePbx.tenants.code"),
                      error: _vm.codeError,
                      required: "",
                    },
                  },
                  [
                    _c("sw-input", {
                      attrs: {
                        placeholder: _vm.$t("corePbx.tenants.code"),
                        invalid: _vm.$v.formData.tenant_code.$error,
                        disabled: !_vm.formData.pbxServer,
                        type: "text",
                        tabindex: "1",
                        placer: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.tenant_code.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.tenant_code,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "tenant_code",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.tenant_code",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-span-12 md:col-span-4" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("corePbx.tenants.ext_length"),
                          error: _vm.ExtLengthError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          attrs: {
                            placeholder: _vm.$t("corePbx.tenants.ext_length"),
                            disabled: !_vm.formData.pbxServer,
                            invalid: _vm.$v.formData.ext_length.$error,
                            type: "number",
                            min: 2,
                            max: 16,
                            tabindex: "1",
                            placer: "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.ext_length.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.ext_length,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "ext_length",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.ext_length",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex flex-wrap items-center mt-8" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "w-full md:w-auto mb-2 md:mb-0",
                    attrs: {
                      variant: "primary-outline",
                      type: "button",
                      size: "lg",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancelForm()
                      },
                    },
                  },
                  [
                    _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                    attrs: {
                      loading: _vm.isLoading,
                      type: "submit",
                      variant: "primary",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", {
                          staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("corePbx.tenants.save_tenants_button")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }