var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        {
          staticClass: "mb-3",
          attrs: { title: _vm.$tc("core_pos.cash_register") },
        },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/dashboard",
                  title: _vm.$t("general.home"),
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/cash-register",
                  title: _vm.$tc("core_pos.cash_register", 2),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePOS/cash-register`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.back")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePOS/create-cash-register/${_vm.cash_register.id}/edit`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                { attrs: { position: "bottom-end" } },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.new_transaction")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.accessOpenCloseCashRegister
                    ? _c(
                        "div",
                        [
                          _vm.status_cash_register == 0
                            ? _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.cashRegisterModal } },
                                [
                                  _c("check-circle-icon", {
                                    staticClass: "h-5 mr-1 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("core_pos.open_cash_register")
                                      ) +
                                      "\n        "
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.cashRegisterModal } },
                                [
                                  _c("x-circle-icon", {
                                    staticClass: "h-5 mr-1 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("core_pos.close_cash_register")
                                      ) +
                                      "\n        "
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.status_cash_register &&
                  _vm.permissionModule.accessIncomeWithdrawalCashRegister
                    ? _c(
                        "sw-dropdown-item",
                        { on: { click: _vm.openModalIncomeCash } },
                        [
                          _c("trending-down-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("core_pos.title_income_cash")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.status_cash_register &&
                  _vm.permissionModule.accessIncomeWithdrawalCashRegister
                    ? _c(
                        "sw-dropdown-item",
                        { on: { click: _vm.openModalWithdrawalCash } },
                        [
                          _c("trending-up-icon", {
                            staticClass: "h-5 mr-3 text-gray-600",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("core_pos.title_withdrawal_cash")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePOS/cash-register/${this.$route.params.id}/report`,
                      },
                    },
                    [
                      _c("book-open-icon", {
                        staticClass: "h-5 mr-3 text-gray-600",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("core_pos.cash_register_report")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("sw-card", { staticClass: "flex flex-col mt-1" }, [
        _c("div", {}, [
          _c("div", { staticClass: "col-span-12" }, [
            _c(
              "p",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("core_pos.basic_info")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("core_pos.user_name")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.user.name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("core_pos.name")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.cash_register.name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("core_pos.device")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.cash_register.device) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("core_pos.description")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-sm leading-5 text-black non-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.cash_register.description) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-bold font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("core_pos.open_close_cash_modal.status")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "sw-badge",
                        {
                          attrs: {
                            "bg-color": _vm.getStatus(_vm.status_cash_register)
                              .bgColor,
                            color: _vm.getStatus(_vm.status_cash_register)
                              .color,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  _vm.getStatus(_vm.status_cash_register).text
                                )
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
        _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck5" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck5" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$tc("core_pos.cash_history", 2)) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content-customer" },
                [
                  _c("div", { staticClass: "text-grey-darkest" }, [
                    _c("div", { staticClass: "flex base-tabs" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchData,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.reference"
                          ),
                          filterable: true,
                          show: "ref",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.open_date"
                          ),
                          filterable: true,
                          show: "open_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.cash_received"
                          ),
                          filterable: true,
                          show: "cash_received",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.cash_received"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.cash_received * 100,
                                        _vm.user.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.initial_amount"
                          ),
                          filterable: true,
                          show: "initial_amount",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.initial_amount"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.initial_amount * 100,
                                        _vm.user.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.other_income"
                          ),
                          filterable: true,
                          show: "other_income",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.other_income"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.other_income * 100,
                                        _vm.user.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.close_date"
                          ),
                          filterable: true,
                          show: "close_date",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.close_date"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.close_date != null
                                        ? row.close_date
                                        : "N/A"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.final_amount"
                          ),
                          filterable: true,
                          show: "final_amount",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.final_amount"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.final_amount * 100,
                                        _vm.user.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.status"
                          ),
                          filterable: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.status"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                row.close_date == null
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            attrs: {
                                              "bg-color":
                                                _vm.getStatusHistory(
                                                  "INCOMPLETE"
                                                ).bgColor,
                                              color:
                                                _vm.getStatusHistory(
                                                  "INCOMPLETE"
                                                ).color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("core_pos.incomplete")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "sw-badge",
                                          {
                                            attrs: {
                                              "bg-color":
                                                _vm.getStatusHistory("COMPLETE")
                                                  .bgColor,
                                              color:
                                                _vm.getStatusHistory("COMPLETE")
                                                  .color,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("core_pos.complete")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("sw-card", { staticClass: "flex flex-col mt-3" }, [
        _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck5" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck5" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$tc("core_pos.income_withdrawal_cash", 2)
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content-customer" },
                [
                  _c("div", { staticClass: "text-grey-darkest" }, [
                    _c("div", { staticClass: "flex base-tabs" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchDataIncomeWithdrawalCash,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.reference"
                          ),
                          filterable: true,
                          show: "ref",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.created_at"
                          ),
                          filterable: true,
                          show: "created_at",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("core_pos.user_name"),
                          filterable: true,
                          show: "user_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("core_pos.amount"),
                          filterable: true,
                          show: "amount",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("core_pos.amount"))),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.amount,
                                        _vm.user.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "core_pos.open_close_cash_modal.status"
                          ),
                          filterable: true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "core_pos.open_close_cash_modal.status"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "sw-badge",
                                      {
                                        attrs: {
                                          "bg-color":
                                            _vm.getStatusIncomeWithdrawal(
                                              row.type
                                            ).bgColor,
                                          color: _vm.getStatusIncomeWithdrawal(
                                            row.type
                                          ).color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                _vm.getStatusIncomeWithdrawal(
                                                  row.type
                                                ).text
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }