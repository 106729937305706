var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { ref: "form", staticClass: "relative payment-create" },
    [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitPaymentData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-page-header",
            { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("general.home"),
                      to: "/admin/dashboard",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$tc("payments.payment", 2),
                      to: "/admin/payments",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$route.name === "payments.edit"
                    ? _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("payments.edit_payment"),
                          to: "#",
                          active: "",
                        },
                      })
                    : _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("payments.new_payment"),
                          to: "#",
                          active: "",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3 text-sm hidden sm:flex",
                      attrs: { variant: "primary-outline", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.formData.payment_method.paypal_button
                    ? _c(
                        "div",
                        [
                          !_vm.notEditable
                            ? _c(
                                "sw-button",
                                {
                                  staticClass: "hidden sm:flex",
                                  attrs: {
                                    loading: _vm.isLoading,
                                    disabled: _vm.isLoading,
                                    variant: "primary",
                                    type: "submit",
                                    size: "lg",
                                  },
                                },
                                [
                                  !_vm.isLoading
                                    ? _c("save-icon", {
                                        staticClass: "mr-2 -ml-1",
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.isEdit
                                          ? _vm.$t("payments.update_payment")
                                          : "Add Credit"
                                      ) +
                                      "\n          "
                                  ),
                                ],
                                1
                              )
                            : _c("sw-button", {
                                staticStyle: { display: "none" },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isRequestOnGoing
            ? _c("base-loader")
            : _c(
                "sw-card",
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid gap-6 grid-col-1 md:grid-cols-2 grid-col-1",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("payments.date"),
                            error: _vm.DateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-1",
                            attrs: {
                              invalid: _vm.$v.formData.payment_date.$error,
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                              disabled: _vm.isEdit,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.payment_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.payment_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "payment_date", $$v)
                              },
                              expression: "formData.payment_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("payments.payment_number"),
                            error: _vm.paymentNumError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-1",
                            attrs: {
                              prefix: `${_vm.paymentPrefix} - `,
                              invalid: _vm.$v.paymentNumAttribute.$error,
                              disabled: _vm.isEdit,
                              autocomplete: "off",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.paymentNumAttribute.$touch()
                              },
                            },
                            model: {
                              value: _vm.paymentNumAttribute,
                              callback: function ($$v) {
                                _vm.paymentNumAttribute =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "paymentNumAttribute",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("payments.customer"),
                            error: _vm.customerError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-1",
                            attrs: {
                              options: _vm.customers,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": false,
                              disabled: true,
                              placeholder: _vm.$t(
                                "customers.select_a_customer"
                              ),
                              label: "name",
                              "track-by": "id",
                            },
                            model: {
                              value: _vm.customer,
                              callback: function ($$v) {
                                _vm.customer = $$v
                              },
                              expression: "customer",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.creditv &&
                      _vm.formData.amount / 100 <= _vm.customer.balance &&
                      _vm.formData.payment_method == null
                        ? _c("sw-input-group", [
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap justify-between" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "flex flex-wrap justify-end" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "payments.account_avalable_credit"
                                        )
                                      ) + ":\n              "
                                    ),
                                    _c("div", {
                                      staticClass: "text-success text-xl ml-2",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.customer.balance * 100,
                                            _vm.customer.currency
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isEdit
                                  ? _c(
                                      "sw-button",
                                      {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          disabled: _vm.isLoading,
                                          variant: "primary",
                                          type: "submit",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("payments.apply_credit")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("payments.amount"),
                            error: _vm.amountError,
                            required: "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "relative w-full mt-1" },
                            [
                              _c("sw-money", {
                                staticClass:
                                  "relative w-full focus:border focus:border-solid focus:border-primary-500",
                                attrs: {
                                  currency: _vm.customerCurrency,
                                  invalid: _vm.$v.formData.amount.$error,
                                  disabled: _vm.isEdit,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.amount.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.amount,
                                  callback: function ($$v) {
                                    _vm.amount = $$v
                                  },
                                  expression: "amount",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        { attrs: { label: _vm.$t("payments.payment_mode") } },
                        [
                          !_vm.fetchingPaymentMethod
                            ? _c(
                                "sw-select",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    options: _vm.options_payment_methods,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "payments.select_payment_mode"
                                    ),
                                    "max-height": 150,
                                    label: "formattedNameLabel",
                                    error: _vm.paymentMethodError,
                                    disabled: _vm.isEdit,
                                    required: "",
                                  },
                                  on: { select: _vm.PaymentModeSelected },
                                  model: {
                                    value: _vm.formData.payment_method,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "payment_method",
                                        $$v
                                      )
                                    },
                                    expression: "formData.payment_method",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "afterList" },
                                      slot: "afterList",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "flex items-center justify-center w-full px-2 py-2 bg-gray-200 border-none outline-none text-primary-400",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addPaymentMode },
                                        },
                                        [
                                          _c("shopping-cart-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-primary-400",
                                          }),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "settings.customization.payments.add_payment_mode"
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-md-3",
                          attrs: {
                            label: _vm.$t("general.stay_on_page"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value: _vm.stayOnPage,
                              callback: function ($$v) {
                                _vm.stayOnPage = $$v
                              },
                              expression: "stayOnPage",
                            },
                          }),
                          _vm._v(" "),
                          _c("br"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.add_payment_gateway_select
                        ? _c(
                            "div",
                            [
                              _vm.isEdit
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mt-1",
                                      attrs: {
                                        label: _vm.$t("tax_groups.status"),
                                        error: _vm.statusError,
                                        required: "",
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        attrs: {
                                          invalid: _vm.$v.formData.status
                                            ? _vm.$v.formData.status.$error
                                            : false,
                                          options: _vm.status,
                                          disabled:
                                            _vm.isTransactionStatus ||
                                            this.isedtiablefalse,
                                          searchable: true,
                                          "show-labels": false,
                                          tabindex: 16,
                                          "allow-empty": true,
                                          placeholder:
                                            _vm.$t("tax_groups.status"),
                                          label: "text",
                                          "track-by": "value",
                                        },
                                        on: {
                                          select: _vm.transactionStatusSelected,
                                        },
                                        model: {
                                          value: _vm.formData.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "formData.status",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isEdit && _vm.voidStatusChange
                                ? _c("div", { staticClass: "flex mt-6" }, [
                                    _c(
                                      "div",
                                      { staticClass: "relative w-12" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value:
                                              _vm.formData.void_status_change,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "void_status_change",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.void_status_change",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-4" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                          staticStyle: { "max-width": "480px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.process_status_change"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isEdit && _vm.refundedStatusChange
                                ? _c("div", { staticClass: "flex mt-6" }, [
                                    _c(
                                      "div",
                                      { staticClass: "relative w-12" },
                                      [
                                        _c("sw-switch", {
                                          staticClass: "absolute",
                                          staticStyle: { top: "-20px" },
                                          model: {
                                            value:
                                              _vm.formData
                                                .refunded_status_change,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "refunded_status_change",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.refunded_status_change",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-4" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 m-0 text-xs leading-4 text-gray-500",
                                          staticStyle: { "max-width": "480px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.process_status_change"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.transactionStatusCheck
                                ? _c("div", { staticClass: "flex my-8" }, [
                                    _c(
                                      "div",
                                      { staticClass: "relative w-12" },
                                      [
                                        _c("sw-checkbox", {
                                          staticClass: "absolute",
                                          model: {
                                            value:
                                              _vm.formData
                                                .status_with_authorize,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "status_with_authorize",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.status_with_authorize",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-4" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "p-0 mb-1 text-base leading-snug text-black box-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "payments.transaction_status_authorize_message"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isEdit && !_vm.transactionStatusCheck
                                ? _c("sw-divider", {
                                    staticClass: "mt-1 opacity-0",
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formData.payment_method &&
                  _vm.formData.payment_method.paypal_button
                    ? _c(
                        "div",
                        [
                          _c("paypal", {
                            attrs: {
                              formData: _vm.formData,
                              codePayment: _vm.codePayment,
                              invoice_number: _vm.invoice_number,
                              customer: _vm.customer,
                            },
                            on: { paypalSuccess: _vm.paypalSuccess },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.type_ach
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-span-2 w-full flex flex-wrap mt-sm-6",
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "sw-section-title mb-2 mt-4" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("payment_accounts.bank_account_info")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          this.type_ach
                            ? _c(
                                "div",
                                { staticClass: "w-full flex flex-wrap" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-full mb-4 md:pr-2" },
                                    [
                                      _c(
                                        "sw-input-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "settings.payment_gateways.title"
                                            ),
                                          },
                                        },
                                        [
                                          _c("sw-select", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              options: _vm.payment_gateways_ach,
                                              searchable: true,
                                              "show-labels": false,
                                              "allow-empty": true,
                                              disabled:
                                                _vm.isEdit ||
                                                _vm.isFormDisabled,
                                              placeholder: _vm.$t(
                                                "items.select_a_type"
                                              ),
                                              "track-by": "id",
                                              label: "name",
                                            },
                                            on: {
                                              select:
                                                _vm.PaymentSelectedFeesAch,
                                            },
                                            model: {
                                              value:
                                                _vm.formData
                                                  .payment_gateways_ach,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "payment_gateways_ach",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.payment_gateways_ach",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.paymentFeesListACHflag &&
                          this.paymentFeesListACH.length > 0
                            ? _c("div", { staticClass: "w-full md:pr-2" }, [
                                _c("div", [
                                  _c(
                                    "h2",
                                    { staticClass: "text-lg font-bold mb-4" },
                                    [_vm._v("Payment Fees")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-1 md:grid-cols-4 gap-4 text-sm",
                                    },
                                    _vm._l(
                                      _vm.paymentFeesListACH,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "p-2 border rounded",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _vm._v(
                                                  "\n                  -\n                  "
                                                ),
                                                _c("p", [
                                                  _vm._v(_vm._s(item.type)),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.type == "fixed"
                                              ? _c("p")
                                              : _vm._e(),
                                            item.type == "fixed"
                                              ? _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$utils.formatMoney(
                                                        item.amount,
                                                        _vm.defaultCurrency
                                                      )
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("p"),
                                            _vm._v(" "),
                                            item.type == "percentage"
                                              ? _c("p", [
                                                  _vm._v(
                                                    "\n                 " +
                                                      _vm._s(
                                                        item.amount / 100
                                                      ) +
                                                      " %\n\n                "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mt-4 text-sm",
                                      staticStyle: {
                                        color: "rgb(197, 48, 48)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              These payment fees will be applied at the time of charging.\n            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-full flex flex-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                this.type_ach
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "payments.select_accounts"
                                          ),
                                        },
                                      },
                                      [
                                        _c("sw-select", {
                                          staticClass: "mt-1",
                                          attrs: {
                                            options: _vm.accountList,
                                            searchable: true,
                                            "show-labels": false,
                                            "allow-empty": false,
                                            disabled: _vm.isEdit,
                                            placeholder: _vm.$t(
                                              "payments.select_accounts"
                                            ),
                                            label: "name_account_number",
                                            "track-by": "id",
                                            loading: _vm.isLoadingPayments,
                                          },
                                          on: { select: _vm.selectItemAccount },
                                          model: {
                                            value: _vm.account,
                                            callback: function ($$v) {
                                              _vm.account = $$v
                                            },
                                            expression: "account",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:mr-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "payment_accounts.ACH_type"
                                      ),
                                      error: _vm.ACHTypeError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("sw-select", {
                                      attrs: {
                                        options: _vm.bank_account_type,
                                        invalid:
                                          _vm.$v.formData.ACH_type.$error,
                                        searchable: true,
                                        "show-labels": false,
                                        tabindex: 16,
                                        "allow-empty": true,
                                        label: "text",
                                        "track-by": "value",
                                      },
                                      model: {
                                        value: _vm.formData.ACH_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "ACH_type",
                                            $$v
                                          )
                                        },
                                        expression: "formData.ACH_type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:ml-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "payment_accounts.account_number"
                                      ),
                                      error: _vm.accountNumberError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        invalid:
                                          _vm.$v.formData.account_number.$error,
                                        focus: "",
                                        type: "password",
                                        name: "account_number",
                                        tabindex: "1",
                                        autocomplete: "off",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.account_number.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "rightIcon",
                                            fn: function () {
                                              return undefined
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4221658946
                                      ),
                                      model: {
                                        value: _vm.formData.account_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "account_number",
                                            $$v
                                          )
                                        },
                                        expression: "formData.account_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:mr-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "payment_accounts.routing_number"
                                      ),
                                      error: _vm.routingNumberError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        invalid:
                                          _vm.$v.formData.routing_number.$error,
                                        focus: "",
                                        type: "password",
                                        name: "routing_number",
                                        tabindex: "1",
                                        autocomplete: "off",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.routing_number.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "rightIcon",
                                            fn: function () {
                                              return undefined
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4221658946
                                      ),
                                      model: {
                                        value: _vm.formData.routing_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "routing_number",
                                            $$v
                                          )
                                        },
                                        expression: "formData.routing_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:ml-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "payment_accounts.bankname"
                                      ),
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        focus: "",
                                        type: "text",
                                        name: "bank_name",
                                        tabindex: "1",
                                        autocomplete: "off",
                                      },
                                      model: {
                                        value: _vm.formData.bank_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "bank_name",
                                            $$v
                                          )
                                        },
                                        expression: "formData.bank_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full md:w-1/2 mb-4 md:pl-2" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "md:mr-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "payment_accounts.num_check"
                                      ),
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        focus: "",
                                        type: "text",
                                        name: "num_check",
                                        tabindex: "1",
                                      },
                                      model: {
                                        value: _vm.formData.num_check,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "num_check",
                                            $$v
                                          )
                                        },
                                        expression: "formData.num_check",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("h6", { staticClass: "my-4" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("customers.billing_address")) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-divider", { staticClass: "w-full" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.customer_nameACH"),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      type: "text",
                                      name: "address_name",
                                      tabindex: "7",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                { attrs: { label: _vm.$t("customers.phone") } },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      type: "text",
                                      name: "phone",
                                      tabindex: "13",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.address"),
                                    error: _vm.billAddress1Error,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-textarea", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.authorize.address_street_1
                                          .$error,
                                      placeholder: _vm.$t("general.street_1"),
                                      type: "text",
                                      name: "billing_street1",
                                      rows: "3",
                                      tabindex: "11",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.address_street_1.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.authorize.address_street_1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "address_street_1",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.address_street_1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.address_2"),
                                    error: _vm.billAddress2Error,
                                  },
                                },
                                [
                                  _c("sw-textarea", {
                                    attrs: {
                                      placeholder: _vm.$t("general.street_2"),
                                      type: "text",
                                      name: "billing_street2",
                                      rows: "3",
                                      tabindex: "12",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.address_street_2.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.authorize.address_street_2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "address_street_2",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.address_street_2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.cityError,
                                    label: _vm.$t("customers.city"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      name: "city",
                                      type: "text",
                                      tabindex: "10",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.authorize, "city", $$v)
                                      },
                                      expression: "authorize.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.stateIdError,
                                    label: _vm.$t("customers.state"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      invalid: _vm.$v.authorize.state_id.$error,
                                      options: _vm.billing_states,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      tabindex: 8,
                                      placeholder: _vm.$t(
                                        "general.select_state"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    model: {
                                      value: _vm.billing_state,
                                      callback: function ($$v) {
                                        _vm.billing_state = $$v
                                      },
                                      expression: "billing_state",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.countryIdError,
                                    label: _vm.$t("customers.country"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.authorize.country_id.$error,
                                      options: _vm.countries,
                                      searchable: true,
                                      "show-labels": false,
                                      placeholder: _vm.$t(
                                        "general.select_country"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.countrySelected(
                                          $event,
                                          "billing"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.billing_country,
                                      callback: function ($$v) {
                                        _vm.billing_country = $$v
                                      },
                                      expression: "billing_country",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.zip_code"),
                                    error: _vm.zipError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      tabindex: "14",
                                      invalid: _vm.$v.authorize.zip.$error,
                                      type: "text",
                                      name: "zip",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.zip,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "zip",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.zip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full md:w-1/2 md:pr-2 flex flex-wrap mt-2 items-end justify-start mt-4",
                            },
                            [
                              _c("sw-switch", {
                                on: { change: _vm.Updateoptionchace },
                                model: {
                                  value: _vm.updatebillinginformation,
                                  callback: function ($$v) {
                                    _vm.updatebillinginformation = $$v
                                  },
                                  expression: "updatebillinginformation",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "leading-snug text-black box-title ml-4",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("customers.update_billing_info")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full md:w-1/2 md:pl-2 flex mt-2 items-end justify-start mt-4",
                            },
                            [
                              _c("sw-switch", {
                                on: { change: _vm.Createoptionchace },
                                model: {
                                  value: _vm.createaccount,
                                  callback: function ($$v) {
                                    _vm.createaccount = $$v
                                  },
                                  expression: "createaccount",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "leading-snug text-black box-title ml-4",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("customers.save_create_account")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass: "hidden sm:flex mt-4",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("payments.record_payment")) +
                                  "\n        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (this.is_authorize &&
                    this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_paypal &&
                    this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_auxVault &&
                    this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_authorize &&
                    !this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_paypal &&
                    !this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_auxVault &&
                    !this.isEdit &&
                    !this.type_ach &&
                    this.type_cc)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-full flex flex-wrap col-span-2 mt-5 mt-sm-6",
                        },
                        [
                          _c(
                            "h6",
                            {
                              staticClass: "sw-section-title mb-2 mt-4 mt-sm-6",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("payments.credit_card_information")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          this.add_payment_gateway_select &&
                          !this.type_ach &&
                          this.type_cc
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.$t(
                                      "settings.payment_gateways.title"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      options: _vm.payment_gateways,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      disabled: _vm.isEdit,
                                      placeholder: _vm.$t(
                                        "items.select_a_type"
                                      ),
                                      "track-by": "id",
                                      label: "name",
                                    },
                                    on: { select: _vm.PaymentSelectedFees },
                                    model: {
                                      value: _vm.formData.payment_gateways,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "payment_gateways",
                                          $$v
                                        )
                                      },
                                      expression: "formData.payment_gateways",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.paymentFeesListCCflag &&
                          this.paymentFeesListCC.length > 0
                            ? _c("div", { staticClass: "w-full md:pr-2" }, [
                                _c("div", [
                                  _c(
                                    "h2",
                                    { staticClass: "text-lg font-bold mb-4" },
                                    [_vm._v("Payment Fees")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-1 md:grid-cols-4 gap-4 text-sm",
                                    },
                                    _vm._l(
                                      _vm.paymentFeesListCC,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "p-2 border rounded",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _vm._v(
                                                  "\n                  -\n                  "
                                                ),
                                                _c("p", [
                                                  _vm._v(_vm._s(item.type)),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.type == "fixed"
                                              ? _c("p")
                                              : _vm._e(),
                                            item.type == "fixed"
                                              ? _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$utils.formatMoney(
                                                        item.amount,
                                                        _vm.defaultCurrency
                                                      )
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("p"),
                                            _vm._v(" "),
                                            item.type == "percentage"
                                              ? _c("p", [
                                                  _vm._v(
                                                    "\n                 " +
                                                      _vm._s(
                                                        item.amount / 100
                                                      ) +
                                                      " %\n\n                "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mt-4 text-sm",
                                      staticStyle: {
                                        color: "rgb(197, 48, 48)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              These payment fees will be applied at the time of charging.\n            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2" },
                            [
                              (this.is_authorize &&
                                this.isEdit &&
                                !this.type_ach &&
                                this.type_cc) ||
                              (this.is_paypal &&
                                this.isEdit &&
                                !this.type_ach &&
                                this.type_cc) ||
                              (this.is_auxVault &&
                                this.isEdit &&
                                !this.type_ach &&
                                this.type_cc) ||
                              (this.is_authorize &&
                                !this.isEdit &&
                                !this.type_ach &&
                                this.type_cc) ||
                              (this.is_paypal &&
                                !this.isEdit &&
                                !this.type_ach &&
                                this.type_cc) ||
                              (this.is_auxVault &&
                                !this.isEdit &&
                                !this.type_ach &&
                                this.type_cc)
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: _vm.$t("payments.select_cards"),
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.cardList,
                                          searchable: true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          disabled: _vm.isEdit,
                                          placeholder: _vm.$t(
                                            "payments.select_cards"
                                          ),
                                          label: "card_number_cvv",
                                          "track-by": "id",
                                          loading: _vm.isLoadingPayments,
                                        },
                                        on: { select: _vm.selectItemCard },
                                        model: {
                                          value: _vm.card,
                                          callback: function ($$v) {
                                            _vm.card = $$v
                                          },
                                          expression: "card",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          this.add_payment_gateway_select &&
                          this.isEdit &&
                          !this.type_ach &&
                          this.type_cc
                            ? _c(
                                "div",
                                { staticClass: "w-full md:w-1/2 md:pl-2" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: _vm.$t(
                                          "settings.payment_gateways.credit_cards"
                                        ),
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.credit_cards,
                                          searchable: true,
                                          "show-labels": false,
                                          "allow-empty": true,
                                          disabled: _vm.isEdit,
                                          placeholder: _vm.$t(
                                            "items.select_a_type"
                                          ),
                                          label: "name",
                                        },
                                        model: {
                                          value: _vm.formData.credit_cards,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "credit_cards",
                                              $$v
                                            )
                                          },
                                          expression: "formData.credit_cards",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.add_payment_gateway_select &&
                          !this.isEdit &&
                          !this.type_ach &&
                          this.type_cc
                            ? _c(
                                "div",
                                { staticClass: "w-full md:w-1/2 md:pl-2" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: _vm.$t(
                                          "settings.payment_gateways.credit_cards"
                                        ),
                                        required: "",
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.credit_cards,
                                          searchable: true,
                                          "show-labels": false,
                                          "allow-empty": true,
                                          disabled: _vm.isEdit,
                                          placeholder: _vm.$t(
                                            "items.select_a_type"
                                          ),
                                          label: "name",
                                        },
                                        model: {
                                          value: _vm.formData.credit_cards,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "credit_cards",
                                              $$v
                                            )
                                          },
                                          expression: "formData.credit_cards",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (this.is_authorize && !this.type_ach && this.type_cc) ||
                  (this.is_paypal &&
                    !this.isEdit &&
                    !this.type_ach &&
                    this.type_cc) ||
                  (this.is_auxVault &&
                    !this.isEdit &&
                    !this.type_ach &&
                    this.type_cc)
                    ? _c(
                        "div",
                        { staticClass: "w-full flex flex-wrap col-span-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mb-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("authorize.cc_number"),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      disabled: _vm.isEdit,
                                      focus: "",
                                      type: "password",
                                      name: "card_number",
                                      autocomplete: "off",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "rightIcon",
                                          fn: function () {
                                            return undefined
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4221658946
                                    ),
                                    model: {
                                      value: _vm.authorize.card_number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "card_number",
                                          $$v
                                        )
                                      },
                                      expression: "authorize.card_number",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mb-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("authorize.date"),
                                    error: _vm.expirationDateError,
                                    required: "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          placeholder: "MM",
                                          searchable: true,
                                          "allow-empty": false,
                                          options: _vm.monthsOptions,
                                        },
                                        model: {
                                          value: _vm.dateExpirationMonth,
                                          callback: function ($$v) {
                                            _vm.dateExpirationMonth = $$v
                                          },
                                          expression: "dateExpirationMonth",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("sw-select", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          placeholder: "YYYY",
                                          searchable: true,
                                          "allow-empty": false,
                                          options: _vm.yearsOptions,
                                        },
                                        model: {
                                          value: _vm.dateExpirationYear,
                                          callback: function ($$v) {
                                            _vm.dateExpirationYear = $$v
                                          },
                                          expression: "dateExpirationYear",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mb-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    label: _vm.$t("authorize.cvv"),
                                    error: _vm.CvVError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      invalid: _vm.$v.authorize.cvv.$error,
                                      focus: "",
                                      type: "password",
                                      name: "cvv",
                                      autocomplete: "off",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.cvv.$touch()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "rightIcon",
                                          fn: function () {
                                            return undefined
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4221658946
                                    ),
                                    model: {
                                      value: _vm.authorize.cvv,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.authorize, "cvv", $$v)
                                      },
                                      expression: "authorize.cvv",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mb-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    label: _vm.$t("authorize.email"),
                                    error: _vm.emailError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      invalid:
                                        _vm.$v.authorize.payer_email.$error,
                                      type: "text",
                                      name: "payer_email",
                                      tabindex: "3",
                                      autocomplete: "off",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.payer_email.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.authorize.payer_email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "payer_email",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.payer_email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h6", { staticClass: "w-full my-4" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("customers.billing_address")) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "customers.customer_addres_name"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      type: "text",
                                      name: "address_name",
                                      tabindex: "7",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                { attrs: { label: _vm.$t("customers.phone") } },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      type: "text",
                                      name: "phone",
                                      tabindex: "13",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pr-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.address"),
                                    error: _vm.billAddress1Error,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-textarea", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.authorize.address_street_1
                                          .$error,
                                      placeholder: _vm.$t("general.street_1"),
                                      type: "text",
                                      name: "billing_street1",
                                      rows: "3",
                                      tabindex: "11",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.address_street_1.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.authorize.address_street_1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "address_street_1",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.address_street_1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.address_2"),
                                    error: _vm.billAddress2Error,
                                  },
                                },
                                [
                                  _c("sw-textarea", {
                                    attrs: {
                                      placeholder: _vm.$t("general.street_2"),
                                      type: "text",
                                      name: "billing_street2",
                                      rows: "3",
                                      tabindex: "12",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.authorize.address_street_2.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.authorize.address_street_2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "address_street_2",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.address_street_2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.cityError,
                                    label: _vm.$t("customers.city"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      name: "city",
                                      type: "text",
                                      tabindex: "10",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.authorize, "city", $$v)
                                      },
                                      expression: "authorize.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.stateIdError,
                                    label: _vm.$t("customers.state"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      invalid: _vm.$v.authorize.state_id.$error,
                                      options: _vm.billing_states,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      tabindex: 8,
                                      placeholder: _vm.$t(
                                        "general.select_state"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    model: {
                                      value: _vm.billing_state,
                                      callback: function ($$v) {
                                        _vm.billing_state = $$v
                                      },
                                      expression: "billing_state",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    error: _vm.countryIdError,
                                    label: _vm.$t("customers.country"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.authorize.country_id.$error,
                                      options: _vm.countries,
                                      searchable: true,
                                      "show-labels": false,
                                      placeholder: _vm.$t(
                                        "general.select_country"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.countrySelected(
                                          $event,
                                          "billing"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.billing_country,
                                      callback: function ($$v) {
                                        _vm.billing_country = $$v
                                      },
                                      expression: "billing_country",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2 md:pl-2 mt-4" },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$t("customers.zip_code"),
                                    error: _vm.zipError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      tabindex: "14",
                                      invalid: _vm.$v.authorize.zip.$error,
                                      type: "text",
                                      name: "zip",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.authorize.zip,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authorize,
                                          "zip",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "authorize.zip",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full md:w-1/2 md:pr-2 flex flex-wrap mt-2 items-end justify-start mt-4",
                            },
                            [
                              _c("sw-switch", {
                                on: { change: _vm.Updateoptionchace },
                                model: {
                                  value: _vm.updatebillinginformation,
                                  callback: function ($$v) {
                                    _vm.updatebillinginformation = $$v
                                  },
                                  expression: "updatebillinginformation",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "leading-snug text-black box-title ml-4",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("customers.update_billing_info")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full md:w-1/2 md:pl-2 flex mt-2 items-end justify-start mt-4",
                            },
                            [
                              _c("sw-switch", {
                                on: { change: _vm.Createoptionchace },
                                model: {
                                  value: _vm.createaccount,
                                  callback: function ($$v) {
                                    _vm.createaccount = $$v
                                  },
                                  expression: "createaccount",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "leading-snug text-black box-title ml-4",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("customers.save_create_account")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              staticClass: "hidden sm:flex md:mt-4",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("payments.record_payment")) +
                                  "\n        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-popup",
                    {
                      ref: "notePopup",
                      staticClass:
                        "my-6 text-sm font-semibold leading-5 text-primary-400",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "float-right mt-1",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _vm._v(
                            "\n          + " +
                              _vm._s(_vm.$t("general.insert_note")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("note-select-popup", {
                        attrs: { type: "Payment" },
                        on: { select: _vm.onSelectNote },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-6 mb-4 mt-sm-6",
                      attrs: { label: _vm.$t("payments.note") },
                    },
                    [
                      _c("base-custom-input", {
                        staticClass: "mb-4 mt-sm-6",
                        attrs: { fields: _vm.PaymentFields },
                        model: {
                          value: _vm.formData.notes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "notes", $$v)
                          },
                          expression: "formData.notes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                      attrs: { variant: "primary-outline", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "flex w-full mt-4 sm:hidden md:hidden",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        variant: "primary",
                        type: "submit",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("payments.update_payment")
                              : _vm.$t("payments.save_payment")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }