var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitPackage.apply(null, arguments)
          },
        },
      },
      [
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "sw-page-header",
          { attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("packages.package_name", 2),
                    to: "/admin/packages",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "packages.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("packages.edit_package"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("packages.new_package"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 text-sm hidden sm:flex",
                    attrs: {
                      variant: "primary-outline",
                      type: "button",
                      size: "lg",
                    },
                    on: { click: _vm.cancelForm },
                  },
                  [
                    !_vm.isLoading
                      ? _c("x-circle-icon", {
                          staticClass: "w-6 h-6 mr-1 -ml-2",
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.cancel")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg",
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("packages.update_package")
                            : _vm.$t("packages.save_package")
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "sw-card",
          [
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.basic")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("h1", {
                    staticClass: "md:col-span-6",
                    domProps: {
                      textContent: _vm._s(_vm.$t("packages.lang_name")),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: {
                        label: _vm.$t("packages.name_package"),
                        error: _vm.nameError,
                        required: "",
                      },
                    },
                    [
                      !_vm.isCopy
                        ? _c("sw-input", {
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          })
                        : _c("sw-input", {
                            attrs: {
                              value: _vm.formData.name + " - Copy",
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: {
                        label: _vm.$t("corePbx.packages.type"),
                        error: _vm.statusPaymentError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.status_payment,
                          invalid: _vm.$v.formData.status_payment.$error,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 3,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_status"),
                          label: "text",
                          "track-by": "value",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.status_payment.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.status_payment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "status_payment",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.status_payment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.status") },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          invalid: _vm.$v.formData.status.$error,
                          options: _vm.status,
                          searchable: true,
                          "show-labels": false,
                          tabindex: 4,
                          "allow-empty": true,
                          placeholder: _vm.$t("general.select_status"),
                          label: "text",
                          "track-by": "value",
                        },
                        model: {
                          value: _vm.formData.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("customers.tax_type") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.apply_tax_type,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customers.select_a_tax_type"),
                          label: "name",
                          disabled: !_vm.tax_per_item_yes,
                        },
                        model: {
                          value: _vm.formData.apply_tax_type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "apply_tax_type",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.apply_tax_type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid grid-cols-5 gap-4 mb-8" },
              [
                _c(
                  "h6",
                  { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("packages.title_group")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "md:col-span-3",
                    attrs: { label: _vm.$t("packages.title_group") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.groupRight,
                        "track-by": "id",
                        searchable: true,
                        multiple: true,
                        "show-labels": false,
                        placeholder: _vm.$t("packages.select_packages"),
                        label: "name",
                      },
                      model: {
                        value: _vm.formData.groupLeft,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "groupLeft", $$v)
                        },
                        expression: "formData.groupLeft",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
            _vm._v(" "),
            false
              ? _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                  _c(
                    "h6",
                    {
                      staticClass: "col-span-5 sw-section-title lg:col-span-1",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("packages.title_tax_group")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                    },
                    [
                      _c("sw-divider", { staticClass: "col-span-12" }),
                      _vm._v(" "),
                      _c(
                        "sw-table-component",
                        {
                          ref: "table",
                          staticClass: "col-span-12",
                          attrs: {
                            "show-filter": false,
                            data: _vm.formData.groupLeftTax,
                            "table-class": "table",
                            variant: "gray",
                          },
                        },
                        [
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t("packages.packages_group_tax.name"),
                              show: "name",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "packages.packages_group_tax.name"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mt-6" }, [
                                        _vm._v(_vm._s(row.name)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              651350867
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t(
                                "packages.packages_group_tax.description"
                              ),
                              show: "description",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "packages.packages_group_tax.description"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mt-6" }, [
                                        _vm._v(_vm._s(row.description)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2303446451
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: {
                              sortable: true,
                              filterable: true,
                              label: _vm.$t(
                                "packages.packages_group_tax.status"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "packages.packages_group_tax.status"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "sw-badge",
                                        {
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status ? "YES" : "NO"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                row.status ? "YES" : "NO"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                row.status
                                                  ? "Active"
                                                  : "Unactive".replace("_", " ")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1207515310
                            ),
                          }),
                          _vm._v(" "),
                          _c("sw-table-column", {
                            attrs: { sortable: true, filterable: true },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeGroupTax(row)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2529836435
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-span-12" }),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "md:col-span-3",
                          attrs: {
                            label: _vm.$t("packages.member_tax_groups"),
                            required: "",
                          },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.groupLeftTax,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              placeholder: _vm.$tc("packages.add_group_tax"),
                              label: "name",
                              "track-by": "id",
                            },
                            on: { select: _vm.groupLeftTaxSeleted },
                            model: {
                              value: _vm.groupLeftTaxModel,
                              callback: function ($$v) {
                                _vm.groupLeftTaxModel = $$v
                              },
                              expression: "groupLeftTaxModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.title_discount")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  false
                    ? _c(
                        "div",
                        { staticClass: "col-span-6" },
                        [
                          _c("sw-divider", { staticClass: "col-span-12" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex mt-3 mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  attrs: { tabindex: "9" },
                                  on: { change: _vm.onNoneDiscounts },
                                  model: {
                                    value: _vm.packages_discount_none_status,
                                    callback: function ($$v) {
                                      _vm.packages_discount_none_status = $$v
                                    },
                                    expression: "packages_discount_none_status",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Apply none discount\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-3" }, [
                    _c("div", { staticClass: "flex mt-3 mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-20px" },
                            attrs: {
                              disabled: _vm.disableValueDiscounts,
                              tabindex: "10",
                            },
                            on: { change: _vm.onDiscounts },
                            model: {
                              value: _vm.formData.packages_discount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "packages_discount", $$v)
                              },
                              expression: "formData.packages_discount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("packages.apply_general_discount")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-3" }, [
                    _c("div", { staticClass: "flex mt-3 mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.title_discounts")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-select", {
                            attrs: {
                              disabled: _vm.showSelectdiscounts,
                              options: _vm.discounts,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 11,
                              "allow-empty": true,
                              placeholder: _vm.$t("packages.title_discounts"),
                              label: "text",
                              "track-by": "value",
                            },
                            model: {
                              value: _vm.formData.discounts,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "discounts", $$v)
                              },
                              expression: "formData.discounts",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "relative",
                          staticStyle: { width: "8em", "margin-left": "30px" },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("packages.value_discount")) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("sw-money", {
                            attrs: {
                              disabled: _vm.showSelectdiscounts,
                              currency: _vm.customPrefixDescount,
                              name: "qty",
                            },
                            model: {
                              value: _vm.value_discount,
                              callback: function ($$v) {
                                _vm.value_discount = $$v
                              },
                              expression: "value_discount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.taxes")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      staticClass: "md:col-span-6",
                      attrs: {
                        "show-filter": false,
                        data: _vm.paralelo,
                        "table-class": "table",
                        variant: "gray",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("settings.tax_types.tax_name"),
                          show: "name",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.tax_name")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "mt-6" }, [
                                  _vm._v(_vm._s(row.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.compound_tax"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.tax_types.compound_tax")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-badge",
                                  {
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          row.compound_tax ? "YES" : "NO"
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        row.compound_tax ? "YES" : "NO"
                                      ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          row.compound_tax
                                            ? "Yes"
                                            : "No".replace("_", " ")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          filterable: true,
                          label: _vm.$t("settings.tax_types.percent"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("settings.tax_types.percent"))
                                  ),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.percent) +
                                    " %\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: { sortable: true, filterable: true },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeTax(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.taxes") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.taxes,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.add_tax"),
                          label: "name_por",
                          "track-by": "id",
                        },
                        on: { select: _vm.taxSeleted },
                        model: {
                          value: _vm.tax,
                          callback: function ($$v) {
                            _vm.tax = $$v
                          },
                          expression: "tax",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.member_tax_groups") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.groupTaxes,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.add_group_tax"),
                          multiple: true,
                          label: "name",
                          "track-by": "id",
                        },
                        on: {
                          select: _vm.groupTaxSeleted,
                          remove: _vm.removeTaxGroup,
                        },
                        model: {
                          value: _vm.groupTax,
                          callback: function ($$v) {
                            _vm.groupTax = $$v
                          },
                          expression: "groupTax",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 gap-4 mb-8" }, [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("packages.packages_items")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.item_groups") },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.item_groups,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": true,
                          placeholder: _vm.$tc("packages.item_groups_select"),
                          label: "name",
                          "track-by": "id",
                        },
                        on: { select: _vm.itemGroupSelected },
                        model: {
                          value: _vm.item_group,
                          callback: function ($$v) {
                            _vm.item_group = $$v
                          },
                          expression: "item_group",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  undefined !== _vm.formData.item_groups &&
                  _vm.formData.item_groups.length > 0
                    ? _c("div", { staticClass: "md:col-span-6" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap justify-start" },
                          _vm._l(
                            _vm.formData.item_groups,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-indigo-100 bg-indigo-700 border border-indigo-700",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "text-xs text-base leading-none max-w-full flex-initial py-2 pl-2",
                                    domProps: {
                                      textContent: _vm._s(item.name),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-auto flex-row-reverse",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-6 h-4 ml-2 pr-1",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "100%",
                                              height: "100%",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              stroke: "currentColor",
                                              "stroke-width": "4",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItemGroup(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("line", {
                                              attrs: {
                                                x1: "18",
                                                y1: "6",
                                                x2: "6",
                                                y2: "18",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "6",
                                                y1: "6",
                                                x2: "18",
                                                y2: "18",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive-item2" }, [
              _c("div", { staticClass: "tablemin" }, [
                _c(
                  "table",
                  { staticClass: "w-full text-center item-table" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "40%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _vm.discountPerItem === "YES"
                        ? _c("col", { staticStyle: { width: "20%" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "thead",
                      {
                        staticClass:
                          "bg-white border border-gray-200 border-solid",
                      },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pl-12" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$tc("items.item", 1)) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("estimates.item.quantity")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("estimates.item.price")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.discountPerItem === "YES"
                            ? _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("estimates.item.discount")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "pr-10 column-heading" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("estimates.item.total")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "",
                        attrs: { tag: "tbody", handle: ".handle" },
                        model: {
                          value: _vm.formData.items,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "items", $$v)
                          },
                          expression: "formData.items",
                        },
                      },
                      _vm._l(_vm.formData.items, function (item, index) {
                        return _c("package-item", {
                          key: item.id,
                          attrs: {
                            index: index,
                            "item-data": item,
                            currency: _vm.currency,
                            isView: false,
                            isNoGeneralTaxes: _vm.isTaxPerItem,
                            "package-items": _vm.formData.items,
                            "tax-per-item": _vm.isTaxPerItem,
                            "discount-per-item": _vm.discountPerItem,
                          },
                          on: {
                            remove: _vm.removeItem,
                            update: _vm.updateItem,
                            itemValidate: _vm.checkItemsData,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                    on: { click: _vm.addItem },
                  },
                  [
                    _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("estimates.add_item")) +
                        "\n          "
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            false
              ? _c(
                  "div",
                  { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                  [
                    _c("sw-divider", { staticClass: "col-span-12" }),
                    _vm._v(" "),
                    _c("h6", {
                      staticClass: "col-span-5 sw-section-title lg:col-span-1",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6 flex wrap content-end justify-end",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded package-total lg:mt-0",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between w-full",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.sub_total"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex items-center justify-center m-0 text-lg text-black uppercase",
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.subtotal,
                                            _vm.currency
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            true
                              ? [
                                  false
                                    ? _vm._l(_vm.allTaxes, function (tax) {
                                        return _c(
                                          "div",
                                          {
                                            key: tax.tax_type_id,
                                            staticClass:
                                              "flex items-center justify-between w-full",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(tax.name) +
                                                    " - " +
                                                    _vm._s(tax.percent) +
                                                    "%\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                                                staticStyle: {
                                                  "font-size": "18px",
                                                },
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$utils.formatMoney(
                                                        tax.amount,
                                                        _vm.currency
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isTaxPerItem
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex items-center content-center justify-between w-full mt-2",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "packages.general_taxes"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.formData.taxes,
                                          function (tax, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "flex items-center content-center justify-between w-full mt-2",
                                              },
                                              [
                                                _c("label", {
                                                  staticClass:
                                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      `${tax.name} - ${tax.percent}%`
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "flex items-center justify-center m-0 text-lg text-black uppercase",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                    },
                                                  },
                                                  [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            _vm.calTax(
                                                              tax.percent,
                                                              _vm.subtotal
                                                            ),
                                                            _vm.currency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.showSelectdiscounts
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center content-center justify-between w-full mt-2",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "packages.general_discounts"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                                              staticStyle: {
                                                "font-size": "18px",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    `${parseFloat(
                                                      _vm.value_discount
                                                    ).toFixed(2)} ${
                                                      _vm.formData.discounts
                                                        .value == "fixed"
                                                        ? ""
                                                        : "%"
                                                    }`
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.total")) +
                                        "\n                " +
                                        _vm._s(_vm.$t("estimates.amount")) +
                                        ":"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex items-center justify-center text-lg uppercase text-primary-400",
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.total,
                                            _vm.currency
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                attrs: {
                  variant: "primary-outline",
                  type: "button",
                  size: "lg",
                },
                on: { click: _vm.cancelForm },
              },
              [
                !_vm.isLoading
                  ? _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass:
                  "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                  size: "lg",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("packages.update_package")
                        : _vm.$t("packages.save_package")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }