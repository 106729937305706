var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("corePbx.corePbx"),
                  to: "/admin/corePBX",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("corePbx.prefix_groups.prefix_group", 2),
                  to: "/admin/corePBX/billing-templates/prefix-groups",
                },
              }),
              _vm._v(" "),
              _vm.isEdit
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.prefix_groups.edit_prefix_group"),
                      to: "#",
                      active: "",
                    },
                  })
                : _vm.isCopy
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.prefix_groups.copy_prefix_group"),
                      to: "#",
                      active: "",
                    },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("corePbx.prefix_groups.new_prefix_group"),
                      to: "#",
                      active: "",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _vm.isEdit
                ? _c(
                    "sw-button",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        type: "submit",
                        variant: "primary",
                        size: "lg",
                      },
                      on: { click: _vm.submitForm },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2 mr-2",
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("corePbx.prefix_groups.update_prefix_group")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4 hidden sm:flex",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/billing-templates/prefix-groups`,
                    variant: "primary-outline",
                    type: "button",
                    size: "lg",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("x-circle-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c("div", { staticClass: "col-span-12" }, [
                _c(
                  "form",
                  {
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitPrefixGroup.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.name"),
                          error: _vm.nameError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.name.$error,
                            focus: "",
                            type: "text",
                            name: "name",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.status"),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.statuses,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "corePbx.prefix_groups.select_a_status"
                            ),
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.type"),
                          error: _vm.typeError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.type.$error,
                            options: _vm.types,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "corePbx.prefix_groups.select_a_type"
                            ),
                            label: "name",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.type.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.description"),
                          error: _vm.descriptionError,
                        },
                      },
                      [
                        _c("sw-textarea", {
                          attrs: { rows: "2", name: "description" },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.description.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-sm not-italic font-medium leading-5 text-primary-800 text-sm",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("corePbx.prefix_groups.prefixes")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-responsive-item2" }, [
                      _c("div", { staticClass: "tablemin" }, [
                        _c(
                          "table",
                          { staticClass: "w-full text-center item-table mt-2" },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "thead",
                              {
                                staticClass:
                                  "bg-white border border-gray-200 border-solid",
                              },
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c("span", { staticClass: "pl-12" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("corePbx.internacional.type")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c("span", { staticClass: "pl-12" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.prefix_groups.prefix_name"
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pl-12 whitespace-nowrap",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "corePbx.internacional.fromto"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("corePbx.prefix_groups.prefix")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-center text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "corePbx.prefix_groups.country"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _c("span", { staticClass: "pr-5" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t(
                                              "corePbx.prefix_groups.rate_per_minutes"
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              {
                                staticClass: "item-body",
                                attrs: { tag: "tbody", handle: ".handle" },
                                model: {
                                  value: _vm.formData.prefixes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "prefixes", $$v)
                                  },
                                  expression: "formData.prefixes",
                                },
                              },
                              _vm._l(
                                _vm.formData.prefixes,
                                function (prefix, index) {
                                  return _c("prefix", {
                                    key: prefix.id,
                                    attrs: {
                                      index: index,
                                      "prefix-data": prefix,
                                      "prefix-group": _vm.formData.prefixes,
                                    },
                                    on: {
                                      remove: _vm.removePrefix,
                                      update: _vm.updatePrefix,
                                      checkExists: _vm.checkExistPrefix,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                        on: { click: _vm.addPrefix },
                      },
                      [
                        _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("corePbx.prefix_groups.add_prefix")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pt-8 py-2 flex flex-col md:flex-row md:space-x-4",
                      },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "w-full md:w-auto",
                            attrs: {
                              loading: _vm.isLoading,
                              variant: "primary",
                              type: "submit",
                              size: "lg",
                            },
                          },
                          [
                            !_vm.isLoading
                              ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t(
                                        "corePbx.prefix_groups.update_prefix_group"
                                      )
                                    : _vm.$t(
                                        "corePbx.prefix_groups.save_prefix_group"
                                      )
                                ) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-button",
                          {
                            staticClass: "w-full md:w-auto mt-2 md:mt-0",
                            attrs: {
                              "tag-name": "router-link",
                              to: `/admin/corePBX/billing-templates/prefix-groups`,
                              variant: "primary-outline",
                              type: "button",
                              size: "lg",
                            },
                          },
                          [
                            !_vm.isLoading
                              ? _c("x-circle-icon", {
                                  staticClass: "w-6 h-6 mr-1 -ml-2",
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("general.cancel")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c("div", { staticClass: "col-span-12" }, [
                _c(
                  "form",
                  {
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitPrefixGroup.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.name"),
                          error: _vm.nameError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.name.$error,
                            focus: "",
                            type: "text",
                            name: "name",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.status"),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.statuses,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "corePbx.prefix_groups.select_a_status"
                            ),
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.type"),
                          error: _vm.typeError,
                          required: "",
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            invalid: _vm.$v.formData.type.$error,
                            options: _vm.types,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "corePbx.prefix_groups.select_a_type"
                            ),
                            label: "name",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.type.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("corePbx.prefix_groups.description"),
                          error: _vm.descriptionError,
                        },
                      },
                      [
                        _c("sw-textarea", {
                          attrs: { rows: "2", name: "description" },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.description.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("corePbx.prefix_groups.prefixes")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "float-right ml-4" },
                  [
                    _c(
                      "sw-dropdown",
                      {
                        staticStyle: {
                          "padding-top": "7px",
                          "text-align": "center",
                          border: "solid 1px black",
                          width: "80px",
                          height: "42px",
                          "border-radius": "10%",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm font-medium cursor-pointer select-none",
                            staticStyle: { "font-size": "16px !important" },
                            attrs: { slot: "activator" },
                            slot: "activator",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("general.actions")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          { on: { click: _vm.modifySelected } },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n              Modify Selected\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          { on: { click: _vm.modifyAll } },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v("\n              Modify All\n            "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    !_vm.showFilters
                      ? _c(
                          "sw-button",
                          {
                            staticClass: "ml-4",
                            attrs: { size: "lg", variant: "primary-outline" },
                            on: { click: _vm.addPrefixModal },
                          },
                          [
                            _c("plus-icon", {
                              staticClass: "w-6 h-6 mr-1 -ml-2",
                            }),
                            _vm._v("\n            Add a prefix\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "ml-4",
                        attrs: { size: "lg", variant: "primary-outline" },
                        on: { click: _vm.toggleFilter },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.filter")) +
                            "\n            "
                        ),
                        _c(_vm.filterIcon, {
                          tag: "component",
                          staticClass: "w-4 h-4 ml-2 -mr-1",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "float-left" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selected,
                          expression: "selected",
                        },
                      ],
                      staticClass: "border border-dark",
                      staticStyle: {
                        "text-align": "center",
                        border: "solid 1px black",
                        width: "77px",
                        height: "40px",
                        "border-radius": "10%",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.refreshTable()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.options, function (option) {
                      return _c(
                        "option",
                        {
                          staticStyle: { "text-align": "center" },
                          domProps: { value: option.value },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(option.name) +
                              "\n            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "slide-y-up-transition",
                      [
                        _c(
                          "sw-filter-wrapper",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showFilters,
                                expression: "showFilters",
                              },
                            ],
                            staticClass:
                              "relative grid grid-flow-col grid-rows",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "w-50",
                                staticStyle: {
                                  "margin-left": "1em",
                                  "margin-right": "1em",
                                },
                              },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { label: "Categories" },
                                  },
                                  [
                                    _c("sw-select", {
                                      attrs: {
                                        options: _vm.categories,
                                        "group-select": false,
                                        searchable: true,
                                        "show-labels": false,
                                        placeholder: "Select a category",
                                        "allow-empty": false,
                                        "group-values": "options",
                                        "group-label": "label",
                                        "track-by": "name",
                                        label: "name",
                                      },
                                      on: {
                                        remove: function ($event) {
                                          return _vm.clearStatusSearch()
                                        },
                                      },
                                      model: {
                                        value: _vm.filters.category,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "category", $$v)
                                        },
                                        expression: "filters.category",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "min-width": "275px" } },
                                  [
                                    _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { label: "Prefix Type" },
                                      },
                                      [
                                        _c("sw-select", {
                                          attrs: {
                                            options: _vm.prefix_type,
                                            "group-select": false,
                                            searchable: true,
                                            "show-labels": false,
                                            placeholder: "Select a prefix type",
                                            "allow-empty": false,
                                            "group-values": "options",
                                            "group-label": "label",
                                            "track-by": "name",
                                            label: "name",
                                          },
                                          on: {
                                            remove: function ($event) {
                                              return _vm.clearStatusSearch()
                                            },
                                          },
                                          model: {
                                            value: _vm.filters.prefix_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                "prefix_type",
                                                $$v
                                              )
                                            },
                                            expression: "filters.prefix_type",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "w-50",
                                staticStyle: {
                                  "margin-left": "1em",
                                  "margin-right": "1em",
                                },
                              },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: { "min-width": "275px" },
                                    attrs: { label: "Name" },
                                  },
                                  [
                                    _c("sw-input", {
                                      model: {
                                        value: _vm.filters.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filters, "name", $$v)
                                        },
                                        expression: "filters.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.filters.prefix_type.value == "P"
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "mt-2",
                                        staticStyle: { "min-width": "275px" },
                                        attrs: { label: "Prefix" },
                                      },
                                      [
                                        _c("sw-input", {
                                          model: {
                                            value: _vm.filters.prefix,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                "prefix",
                                                $$v
                                              )
                                            },
                                            expression: "filters.prefix",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.filters.prefix_type.value == "FT"
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "mt-2",
                                        staticStyle: { "min-width": "275px" },
                                        attrs: { label: "From" },
                                      },
                                      [
                                        _c("sw-input", {
                                          model: {
                                            value: _vm.filters.from,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filters, "from", $$v)
                                            },
                                            expression: "filters.from",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "w-50",
                                staticStyle: {
                                  "margin-left": "1em",
                                  "margin-right": "1em",
                                },
                              },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { label: "Countries" },
                                  },
                                  [
                                    _c("sw-select", {
                                      attrs: {
                                        options: _vm.countries,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": false,
                                        placeholder: _vm.$t(
                                          "general.select_country"
                                        ),
                                        label: "name",
                                        "track-by": "id",
                                      },
                                      model: {
                                        value: _vm.filters.country_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "country_id",
                                            $$v
                                          )
                                        },
                                        expression: "filters.country_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.filters.prefix_type.value == "FT"
                                  ? _c(
                                      "sw-input-group",
                                      {
                                        staticClass: "mt-2",
                                        staticStyle: { "min-width": "275px" },
                                        attrs: { label: "To" },
                                      },
                                      [
                                        _c("sw-input", {
                                          model: {
                                            value: _vm.filters.to,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filters, "to", $$v)
                                            },
                                            expression: "filters.to",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "absolute text-sm leading-snug text-black cursor-pointer",
                                staticStyle: { top: "20px", right: "30px" },
                                on: { click: _vm.clearFilter },
                              },
                              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                  },
                  [
                    _c("sw-checkbox", {
                      staticClass: "hidden md:inline",
                      attrs: { variant: "primary", size: "sm" },
                      on: { change: _vm.selectAllInternacionals },
                      model: {
                        value: _vm.selectAllFieldStatus,
                        callback: function ($$v) {
                          _vm.selectAllFieldStatus = $$v
                        },
                        expression: "selectAllFieldStatus",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-checkbox", {
                      staticClass: "md:hidden",
                      attrs: {
                        label: _vm.$t("general.select_all"),
                        variant: "primary",
                        size: "sm",
                      },
                      on: { change: _vm.selectAllInternacionals },
                      model: {
                        value: _vm.selectAllFieldStatus,
                        callback: function ($$v) {
                          _vm.selectAllFieldStatus = $$v
                        },
                        expression: "selectAllFieldStatus",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "sw-table-component",
                      {
                        ref: "table",
                        attrs: {
                          "show-filter": false,
                          data: _vm.fetchData,
                          "table-class": "table",
                        },
                      },
                      [
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            filterable: false,
                            "cell-class": "no-click",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return _c(
                                    "div",
                                    { staticClass: "relative block" },
                                    [
                                      _c("sw-checkbox", {
                                        attrs: {
                                          id: row.id,
                                          value: row.id,
                                          variant: "primary",
                                          size: "sm",
                                        },
                                        model: {
                                          value: _vm.selectField,
                                          callback: function ($$v) {
                                            _vm.selectField = $$v
                                          },
                                          expression: "selectField",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1238225993
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "Name",
                            "sort-as": "name",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.name) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1745425514
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "Prefix",
                            "sort-as": "prefix",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.prefix) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            879836061
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "From",
                            "sort-as": "from",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.from) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1923326523
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "To",
                            "sort-as": "to",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.to) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            423084310
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "Category",
                            "sort-as": "category",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    row.category == "C"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-sm leading-5 text-black non-italic",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Custom\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.category == "T"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-sm leading-5 text-black non-italic",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Toll Free\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.category == "I"
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-sm leading-5 text-black non-italic",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  International\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1833636215
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "Country",
                            "sort-as": "country_id",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.getCountry(
                                                row.country_id
                                                  ? row.country_id
                                                  : 0
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2258437763
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: {
                            sortable: false,
                            label: "Rate",
                            "sort-as": "rate_per_minute",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-sm leading-5 text-black non-italic",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.defaultCurrency.symbol +
                                                " " +
                                                row.rate_per_minute
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4096845378
                          ),
                        }),
                        _vm._v(" "),
                        _c("sw-table-column", {
                          attrs: { sortable: false, filterable: false },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (row) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("invoices.action"))),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown",
                                      [
                                        _c("dot-icon", {
                                          attrs: { slot: "activator" },
                                          slot: "activator",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editPrefixModal(
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("pencil-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n                      Edit\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deletePrefix(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("trash-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n                      Delete\n                    "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1599820836
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "25%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }