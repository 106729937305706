var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "note-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitNote.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "px-8 py-8 sm:p-6" }, [
          _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c(
                "sw-popup",
                {
                  ref: "notePopup",
                  staticClass:
                    "z-10 text-sm font-semibold leading-5 text-primary-400",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "float-right mt-1",
                      attrs: { slot: "activator" },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n            + " +
                          _vm._s(_vm.$t("general.insert_note")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("note-select-popup", {
                    attrs: { type: "Invoice" },
                    on: { select: _vm.onSelectNote },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticStyle: { "z-index": "1" },
                  attrs: { label: _vm.$t("invoices.notes") },
                },
                [
                  _c("base-custom-input", {
                    attrs: { fields: _vm.InvoiceFields },
                    on: { input: _vm.addNotes },
                    model: {
                      value: _vm.formData.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "notes", $$v)
                      },
                      expression: "formData.notes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end px-4 py-4 border-t border-solid border-gray-light",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-2",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeNoteModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  icon: "save",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }