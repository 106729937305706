var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "payments" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c("sw-page-header", {
            attrs: { title: _vm.$t("customer_ticket.title") },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/view`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_to_customer")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/tickets/main`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("tickets.title")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/add-ticket`,
                    variant: "primary",
                    size: "lg",
                  },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("customer_ticket.create_ticket")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmptyScreen
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("customer_ticket.no_customer_ticket"),
                description: _vm.$t("customer_ticket.list_of_customer_ticket"),
              },
            },
            [
              _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/add-ticket`,
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("customer_ticket.new_ticket")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [_c("p", { staticClass: "text-sm" })]
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.ticket_number"),
                  show: "ticket_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("customer_ticket.ticket_number"))
                          ),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.ticket_number) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.summary"),
                  show: "summary",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.summary"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: `/admin/tickets/main/${row.user_id}/${row.id}/view-ticket`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.summary) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.departament"),
                  show: "departament",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.departament"))),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(row.departament) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.assignedTo"),
                  show: "assigned",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.assignedTo"))),
                        ]),
                        _vm._v(
                          "\n          " + _vm._s(row.assigned) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customer_ticket.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_ticket.status"))),
                        ]),
                        _vm._v(" "),
                        row.status == "S"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "C"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("UNPAID")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("UNPAID")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "I"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("VIEWED")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "O"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor("SENT")
                                          .color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "M"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getStatus(row.status)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.date"),
                  "sort-as": "created_at",
                  show: "formattedCustomerNoteDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: `/admin/tickets/main/${row.user_id}/${row.id}/view-ticket`,
                                },
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.view")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: `${row.id}/edit-ticket`,
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removePayment(row.id)
                                  },
                                },
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }