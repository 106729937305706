var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.$t("corePbx.menu_title.templates") } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$tc("corePbx.menu_title.Internacional", 2),
                          active: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "float-left mr-4 w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selected,
                              expression: "selected",
                            },
                          ],
                          staticClass: "border border-dark",
                          staticStyle: {
                            "text-align": "center",
                            border: "solid 1px black",
                            width: "70px",
                            height: "43.5px",
                            "border-radius": "10%",
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selected = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.refreshTable()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.options, function (option) {
                          return _c(
                            "option",
                            {
                              staticStyle: { "text-align": "center" },
                              domProps: { value: option.value },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(option.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalInternacionals,
                          expression: "totalInternacionals",
                        },
                      ],
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "float-right ml-4 w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                    },
                    [
                      _c(
                        "sw-dropdown",
                        {
                          staticStyle: {
                            "padding-top": "7px",
                            "text-align": "center",
                            border: "solid 1px black",
                            width: "80px",
                            height: "42px",
                            "border-radius": "10%",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-sm font-medium cursor-pointer select-none",
                              staticStyle: { "font-size": "16px !important" },
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.modifySelected } },
                            [
                              _c("pencil-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.modify_select")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { size: "lg", variant: "primary-outline" },
                      on: { click: _vm.addPaymentMode },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("expenses.import")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/corePBX/billing-templates/prefix-groups`,
                        size: "lg",
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("corePbx.internacional.prefix_groups")
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: "international-rate/create",
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("corePbx.internacional.add_internacional")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "slide-y-up-transition",
                [
                  _c(
                    "sw-filter-wrapper",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showFilters,
                          expression: "showFilters",
                        },
                      ],
                      staticClass: "relative grid grid-flow-col grid-rows",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "w-50",
                          staticStyle: {
                            "margin-left": "1em",
                            "margin-right": "1em",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "min-width": "275px" } },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: _vm.$t("general.type") },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.prefix_type,
                                      "group-select": false,
                                      searchable: true,
                                      "show-labels": false,
                                      placeholder: "Select a prefix type",
                                      "allow-empty": false,
                                      "group-values": "options",
                                      "group-label": "label",
                                      "track-by": "name",
                                      label: "name",
                                    },
                                    on: {
                                      remove: function ($event) {
                                        return _vm.clearStatusSearch()
                                      },
                                    },
                                    model: {
                                      value: _vm.filters.prefix_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "prefix_type",
                                          $$v
                                        )
                                      },
                                      expression: "filters.prefix_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-2",
                              staticStyle: { "min-width": "275px" },
                              attrs: { label: "Name" },
                            },
                            [
                              _c("sw-input", {
                                model: {
                                  value: _vm.filters.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "name", $$v)
                                  },
                                  expression: "filters.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "w-50",
                          staticStyle: {
                            "margin-left": "1em",
                            "margin-right": "1em",
                          },
                        },
                        [
                          _vm.filters.prefix_type.value == "P"
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { "min-width": "275px" },
                                  attrs: {
                                    label: _vm.$t(
                                      "corePbx.custom_did_groups.prefix"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    model: {
                                      value: _vm.filters.prefix,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "prefix", $$v)
                                      },
                                      expression: "filters.prefix",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.filters.prefix_type.value == ""
                            ? _c("div", {
                                staticClass:
                                  "mt-9 not-italic font-normal leading-tight text-left outline-none rounded-md input-field box-border-2 placeholder-gray-400 text-black w-full h-10 px-3 py-2 text-sm",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.filters.prefix_type.value == "FT"
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { "min-width": "275px" },
                                  attrs: { label: "From" },
                                },
                                [
                                  _c("sw-input", {
                                    model: {
                                      value: _vm.filters.from,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "from", $$v)
                                      },
                                      expression: "filters.from",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                label: _vm.$t("CorePbx.internacional.country"),
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.countries,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  placeholder: _vm.$t("general.select_country"),
                                  label: "name",
                                  "track-by": "id",
                                },
                                model: {
                                  value: _vm.filters.country_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "country_id", $$v)
                                  },
                                  expression: "filters.country_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "w-50",
                          staticStyle: {
                            "margin-left": "1em",
                            "margin-right": "1em",
                          },
                        },
                        [
                          _vm.filters.prefix_type.value == "" ||
                          _vm.filters.prefix_type.value == "P"
                            ? _c("div", {
                                staticClass:
                                  "mt-9 not-italic font-normal leading-tight text-left outline-none rounded-md input-field box-border-2 placeholder-gray-400 text-black w-full h-10 px-3 py-2 text-sm",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.filters.prefix_type.value == "FT"
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { "min-width": "275px" },
                                  attrs: { label: "To" },
                                },
                                [
                                  _c("sw-input", {
                                    model: {
                                      value: _vm.filters.to,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "to", $$v)
                                      },
                                      expression: "filters.to",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "min-width": "275px" } },
                            [
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Custom Destination Group" },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      options: _vm.prefixrate_groups,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      placeholder: "Select Group",
                                      multiple: true,
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    model: {
                                      value: _vm.filters.prefixrate_groups_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "prefixrate_groups_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filters.prefixrate_groups_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "absolute text-sm leading-snug text-black cursor-pointer",
                          staticStyle: { top: "20px", right: "30px" },
                          on: { click: _vm.clearFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("corePbx.internacional.no_rate"),
                description: _vm.$t("corePbx.internacional.list_of_rate"),
              },
            },
            [
              _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/corePBX/billing-templates/international-rate/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("corePbx.internacional.add_new_rate")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.internacionals.length))]),
                    _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalInternacionals))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary", size: "sm" },
                    on: { change: _vm.selectAllInternacionals },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      label: _vm.$t("general.select_all"),
                      variant: "primary",
                      size: "sm",
                    },
                    on: { change: _vm.selectAllInternacionals },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isLoadingDelete ? _c("base-loader") : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              { staticClass: "custom-control custom-checkbox" },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.id,
                                    value: row.id,
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  model: {
                                    value: _vm.selectField,
                                    callback: function ($$v) {
                                      _vm.selectField = $$v
                                    },
                                    expression: "selectField",
                                  },
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3150036800
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.name"),
                      show: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.internacional.name"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: `international-rate/${row.id}/edit`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      99156848
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.type"),
                      show: "typecustom",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", { staticClass: "whitespace-nowrap" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.internacional.type"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "whitespace-nowrap" }, [
                                _vm._v(
                                  _vm._s(
                                    row.typecustom == "P"
                                      ? "Prefix"
                                      : "From / To"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3015758669
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.prefix"),
                      show: "prefix",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.internacional.prefix"))
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(row.prefix) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2499211430
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.fromto"),
                      show: "from",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", { staticClass: "whitespace-nowrap" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.internacional.fromto"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "whitespace-nowrap" }, [
                                _vm._v(
                                  _vm._s(
                                    `${row.from || ""}${row.to ? " / " : ""}${
                                      row.to || ""
                                    }`
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3444454122
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.country"),
                      show: "country_id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("corePbx.internacional.country")
                                  )
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.getCountry(
                                      row.country_id ? row.country_id : 0
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2565827264
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.rate_per_minute"),
                      show: "rate_per_minute",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "corePbx.internacional.rate_per_minute"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.defaultCurrency.symbol +
                                      " " +
                                      row.rate_per_minute
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4023440962
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("corePbx.internacional.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("corePbx.internacional.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              row.status == "A"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "COMPLETED"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("general.active")) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "I"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-3 py-1",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                "OVERDUE"
                                              ).color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("general.inactive")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4022021104
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      label: _vm.$t("general.groups_column"),
                      show: "prefixGroups",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.groups_column"))),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(row.FormattedPrefixGroups),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3556688480
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("items.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: `international-rate/${row.id}/edit`,
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeExtension(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3116742341
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }