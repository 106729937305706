var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.packages_prefix"),
                error: _vm.ExpensePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.expenses.packages_pbx_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenses.packages_pbx_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("EXPENSE")
                  },
                },
                model: {
                  value: _vm.expenses.packages_pbx_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenses, "packages_pbx_prefix", $$v)
                  },
                  expression: "expenses.packages_pbx_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.expenses.packages_prefix_general,
                    callback: function ($$v) {
                      _vm.$set(_vm.expenses, "packages_prefix_general", $$v)
                    },
                    expression: "expenses.packages_prefix_general",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("corePbx.customization.apply_general_prefix")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoading
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c("h1", {}, [
        _vm._v(_vm._s(_vm.$t("corePbx.customization.pbx_server_notification"))),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateServerNotification.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.server_notification"),
                error: _vm.ServerNotificationError,
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-6 mb-4",
                staticStyle: { "max-width": "30%" },
                attrs: {
                  invalid:
                    _vm.$v.server_notification.server_notification.$error,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.server_notification.server_notification.$touch()
                  },
                },
                model: {
                  value: _vm.server_notification.server_notification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.server_notification,
                      "server_notification",
                      $$v
                    )
                  },
                  expression: "server_notification.server_notification",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.pbx_server_down_subject"),
                error: _vm.subjectError,
              },
            },
            [
              _c("base-custom-input", {
                attrs: {
                  invalid: _vm.$v.server_notification.subject.$error,
                  fields: _vm.companyFields,
                  input: "$v.server_notification.subject.$touch()",
                },
                model: {
                  value: _vm.server_notification.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.server_notification, "subject", $$v)
                  },
                  expression: "server_notification.subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.pbx_server_down_body"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.server_notification.pbx_server_emailbody,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.server_notification,
                      "pbx_server_emailbody",
                      $$v
                    )
                  },
                  expression: "server_notification.pbx_server_emailbody",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("corePbx.customization.pbx_server_up_subject"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.server_notification.subject_up,
                  callback: function ($$v) {
                    _vm.$set(_vm.server_notification, "subject_up", $$v)
                  },
                  expression: "server_notification.subject_up",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t("corePbx.customization.pbx_server_up_body"),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.server_notification.pbx_server_emailbody_up,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.server_notification,
                      "pbx_server_emailbody_up",
                      $$v
                    )
                  },
                  expression: "server_notification.pbx_server_emailbody_up",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.pbx_extension_suspended_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyeXTFields },
                model: {
                  value: _vm.pbx_ext_subject_down,
                  callback: function ($$v) {
                    _vm.pbx_ext_subject_down = $$v
                  },
                  expression: "pbx_ext_subject_down",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.pbx_extension_suspended_body"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyeXTFields },
                model: {
                  value: _vm.pbx_ext_body_down,
                  callback: function ($$v) {
                    _vm.pbx_ext_body_down = $$v
                  },
                  expression: "pbx_ext_body_down",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.pbx_extension_active_subject"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyeXTFields },
                model: {
                  value: _vm.pbx_ext_subject_up,
                  callback: function ($$v) {
                    _vm.pbx_ext_subject_up = $$v
                  },
                  expression: "pbx_ext_subject_up",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "corePbx.customization.pbx_extension_active_body"
                ),
              },
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyeXTFields },
                model: {
                  value: _vm.pbx_ext_body_up,
                  callback: function ($$v) {
                    _vm.pbx_ext_body_up = $$v
                  },
                  expression: "pbx_ext_body_up",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  model: {
                    value: _vm.server_notification.activate_notification,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.server_notification,
                        "activate_notification",
                        $$v
                      )
                    },
                    expression: "server_notification.activate_notification",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "corePbx.customization.pbx_activation_notification"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permission.update
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoadingSV,
                    disabled: _vm.isLoadingSV,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  !_vm.isLoadingSV
                    ? _c("save-icon", { staticClass: "mr-2" })
                    : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("settings.customization.save")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }