var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _vm.isSuperAdmin
        ? _c("base-page", {}, [
            _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitPBX.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "sw-page-header",
                  {
                    staticClass: "mb-3",
                    attrs: {
                      title: _vm.$t(
                        "settings.customization.modules.add_server"
                      ),
                    },
                  },
                  [
                    _c(
                      "sw-breadcrumb",
                      { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                      [
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/dashboard",
                            title: _vm.$t("general.home"),
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/settings/modules",
                            title: _vm.$t(
                              "settings.customization.modules.title"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/settings/pbx",
                            title: _vm.$t(
                              "settings.customization.modules.edit_module"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t(
                              "settings.customization.modules.add_server"
                            ),
                            active: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "mr-3",
                            attrs: {
                              disabled: _vm.isLoading || _vm.isFormDisabled,
                              variant: "primary-outline",
                              type: "button",
                              size: "lg",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.cancelForm()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-button",
                          {
                            staticClass: "flex justify-center w-full md:w-auto",
                            attrs: {
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                              variant: "primary",
                              type: "submit",
                              size: "lg",
                            },
                          },
                          [
                            !_vm.isLoading
                              ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.modules.save_server"
                                  )
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "grid grid-cols-12" }, [
                  _c(
                    "div",
                    { staticClass: "col-span-12" },
                    [
                      _c(
                        "sw-card",
                        { staticClass: "mb-8" },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.server_label"
                                ),
                                error: _vm.serverlabelError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid: _vm.$v.formData.server_label.$error,
                                  focus: "",
                                  type: "text",
                                  name: "server_label",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.server_label.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.server_label,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "server_label",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.server_label",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.hostname"
                                ),
                                error: _vm.hostnameError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid: _vm.$v.formData.hostname.$error,
                                  focus: "",
                                  type: "text",
                                  name: "hostname",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.hostname.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.hostname,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "hostname",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.hostname",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.ssl_port"
                                ),
                                error: _vm.sslError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid: _vm.$v.formData.ssl_port.$error,
                                  placeholder: _vm.$t(
                                    "settings.customization.modules.443"
                                  ),
                                  focus: "",
                                  type: "text",
                                  name: "ssl_port",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.ssl_port.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.ssl_port,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "ssl_port",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.ssl_port",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.api_key"
                                ),
                                error: _vm.apikeyError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  focus: "",
                                  type: _vm.showApiKey ? "text" : "password",
                                  name: "api_key",
                                  invalid: _vm.$v.formData.api_key.$error,
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.api_key.$touch()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "rightIcon",
                                      fn: function () {
                                        return [
                                          _vm.showApiKey
                                            ? _c("eye-off-icon", {
                                                staticClass:
                                                  "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showApiKey =
                                                      !_vm.showApiKey
                                                  },
                                                },
                                              })
                                            : _c("eye-icon", {
                                                staticClass:
                                                  "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showApiKey =
                                                      !_vm.showApiKey
                                                  },
                                                },
                                              }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4230610132
                                ),
                                model: {
                                  value: _vm.formData.api_key,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "api_key",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.api_key",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t("tax_groups.country"),
                                error: _vm.countryError,
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.countries,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": true,
                                  tabindex: 8,
                                  placeholder: _vm.$t("general.select_country"),
                                  label: "name",
                                  "track-by": "id",
                                  invalid: _vm.$v.formData.countries.$error,
                                },
                                on: {
                                  change: _vm.countrySeleted,
                                  input: function ($event) {
                                    return _vm.$v.formData.countries.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.countries,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "countries", $$v)
                                  },
                                  expression: "formData.countries",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.timezone"
                                ),
                                required: "",
                              },
                            },
                            [
                              _c("div", { staticClass: "flex flex-wrap" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-2 md:mb-0 w-full md:w-1/2",
                                  },
                                  [
                                    _c("sw-select", {
                                      staticClass: "md:mr-2",
                                      attrs: {
                                        options: _vm.continentOptions,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        tabindex: 8,
                                        placeholder: _vm.$t(
                                          "settings.customization.modules.place_select_continent"
                                        ),
                                        invalid:
                                          _vm.$v.formData.timezone.$error,
                                      },
                                      model: {
                                        value: _vm.continent,
                                        callback: function ($$v) {
                                          _vm.continent = $$v
                                        },
                                        expression: "continent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mt-2 md:mt-0 w-full md:w-1/2",
                                  },
                                  [
                                    _c("sw-select", {
                                      staticClass: "md:ml-2",
                                      attrs: {
                                        options: _vm.timezonesOptiones,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        tabindex: 8,
                                        placeholder: _vm.$t(
                                          "settings.customization.modules.please_select_zone"
                                        ),
                                        label: "label",
                                        invalid:
                                          _vm.$v.formData.timezone.$error,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.timezone.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.timezone,
                                        callback: function ($$v) {
                                          _vm.timezone = $$v
                                        },
                                        expression: "timezone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.status"
                                ),
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: [],
                                  searchable: false,
                                  placeholder: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function () {
                                        return [
                                          _c("p", [
                                            _vm._v(_vm._s(_vm.labelStatusCrp)),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "beforeList",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex flex-wrap" },
                                            _vm._l(
                                              _vm.cdrStatusOptions,
                                              function (item, index) {
                                                return _c("sw-checkbox", {
                                                  key: index,
                                                  staticClass:
                                                    "w-full p-2 px-4 hover:bg-gray-100",
                                                  attrs: {
                                                    variant: "danger",
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                  on: {
                                                    change: _vm.changeStatus,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.cdrStatus,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "cdrStatus",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.cdrStatus",
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  80949993
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.national_dialing_code"
                                ),
                                error: _vm.nationalError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.national_dialing_code
                                      .$error,
                                  focus: "",
                                  type: "text",
                                  name: "national_dialing_code",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.national_dialing_code.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.national_dialing_code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "national_dialing_code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.national_dialing_code",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.international_dialing_code"
                                ),
                                error: _vm.internationalError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.international_dialing_code
                                      .$error,
                                  focus: "",
                                  type: "text",
                                  name: "international_dialing_code",
                                  autocomplete: "off",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.international_dialing_code.$touch()
                                  },
                                },
                                model: {
                                  value:
                                    _vm.formData.international_dialing_code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "international_dialing_code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "formData.international_dialing_code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }