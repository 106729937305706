var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoice
    ? _c(
        "base-page",
        { class: _vm.isSuperAdmin ? "xl:pl-96" : "xl:pl-100" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.pageTitle } },
            [
              !_vm.isArchived
                ? _c(
                    "template",
                    { slot: "actions" },
                    [
                      _vm.permissionModule.update
                        ? _c(
                            "div",
                            { staticClass: "mr-3 text-sm" },
                            [
                              _vm.invoice.status === "DRAFT"
                                ? _c(
                                    "sw-button",
                                    {
                                      attrs: {
                                        disabled: _vm.isMarkingAsSent,
                                        variant: "primary-outline",
                                      },
                                      on: { click: _vm.onMarkAsSent },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t("invoices.mark_as_sent")
                                          ) +
                                          "\n        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.permissionModule.update
                        ? _c(
                            "div",
                            { staticClass: "mr-3 text-sm" },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticClass: "text-sm",
                                  attrs: {
                                    disabled: _vm.isSendingEmail,
                                    variant: "primary",
                                  },
                                  on: { click: _vm.onSendInvoice },
                                },
                                [
                                  _vm.invoice.status == "DRAFT" ||
                                  _vm.invoice.status == "SAVE_DRAFT"
                                    ? _c("div", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("invoices.send_invoice")
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.invoice.status != "DRAFT" &&
                                  _vm.invoice.status != "SAVE_DRAFT"
                                    ? _c("div", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("invoices.resend_invoice")
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.permissionModule.createPayment
                        ? _c(
                            "div",
                            [
                              _vm.invoice.status === "SENT" ||
                              _vm.invoice.status === "OVERDUE" ||
                              _vm.invoice.status === "VIEWED"
                                ? _c(
                                    "sw-button",
                                    {
                                      staticClass: "text-sm",
                                      attrs: {
                                        to: `/admin/payments/${_vm.$route.params.id}/invoices`,
                                        "tag-name": "router-link",
                                        variant: "primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t("payments.record_payment")
                                          ) +
                                          "\n        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.permissionModule.createPayment
                        ? _c("div", { staticClass: "ml-3" }, [
                            _vm.activate_pay_button
                              ? _c(
                                  "div",
                                  [
                                    _vm.invoice.status === "SENT" ||
                                    _vm.invoice.status === "OVERDUE" ||
                                    _vm.invoice.status === "VIEWED"
                                      ? _c(
                                          "sw-button",
                                          {
                                            staticClass: "text-sm",
                                            attrs: {
                                              to: `/admin/payments/multiple/customer/${_vm.invoice.user_id}/invoice/${_vm.$route.params.id}/create`,
                                              "tag-name": "router-link",
                                              variant: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm.$t("invoices.pay_invoice")
                                                ) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSuperAdmin
                        ? _c(
                            "sw-dropdown",
                            { staticClass: "ml-3" },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticClass: "h-10",
                                  attrs: {
                                    slot: "activator",
                                    variant: "primary",
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c("dots-horizontal-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.copyPdfUrl } },
                                [
                                  _c("link-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("general.copy_pdf_url")) +
                                      "\n        "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.permissionModule.update
                                ? _c(
                                    "div",
                                    [
                                      !_vm.isProrate &&
                                      _vm.invoice.noeditable == 0
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                to: `/admin/invoices/${_vm.$route.params.id}/edit`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n          "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.update &&
                              (_vm.invoice.status == "DRAFT" ||
                                _vm.invoice.status == "SAVE_DRAFT")
                                ? _c(
                                    "sw-dropdown-item",
                                    { on: { click: _vm.onMarkAsSent } },
                                    [
                                      _c("check-circle-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t("invoices.mark_as_sent")
                                          ) +
                                          "\n        "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.invoice.status != "DRAFT"
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendSMSInvoice()
                                        },
                                      },
                                    },
                                    [
                                      _c("paper-airplane-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("invoices.send_invoice_sms")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.readCustomer
                                ? _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: `/admin/customers/${_vm.invoice.user_id}/view`,
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("users-icon", {
                                        staticClass:
                                          "h-5 mr-3 text-primary-800",
                                      }),
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$t("customers.customer_go")
                                          ) +
                                          "\n        "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.delete &&
                              _vm.invoice.inv_avalara_bool == 0
                                ? _c(
                                    "div",
                                    [
                                      _vm.invoice.status != "COMPLETED" &&
                                      _vm.invoice.paid_status === "UNPAID"
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeInvoice(
                                                    _vm.$route.params.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("trash-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n          "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.delete
                                ? _c("div", [
                                    _vm.invoice.inv_avalara_bool
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeInvoiceAvalara(
                                                      _vm.$route.params
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("trash-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "invoices.invoice_delete_avalara"
                                                      )
                                                    ) +
                                                    "\n            "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.codeservice != null ? _c("hr") : _vm._e(),
                              _vm._v(" "),
                              _vm.codeservice != null
                                ? _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.$t("navigation.services"))
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.readNormalServices
                                ? _c(
                                    "div",
                                    [
                                      _vm.codeservice != null &&
                                      _vm.invoice.customer_packages_id != null
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                to: `/admin/customers/${_vm.invoice.user_id}/service/${_vm.invoice.customer_packages_id}/view`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("credit-card-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-primary-800",
                                              }),
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("general.go_to")
                                                  ) +
                                                  ": " +
                                                  _vm._s(_vm.codeservice) +
                                                  "\n          "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.readPBXServices
                                ? _c(
                                    "div",
                                    [
                                      _vm.codeservice != null &&
                                      _vm.invoice.pbx_service_id != null
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              attrs: {
                                                to: `/admin/customers/${_vm.invoice.user_id}/pbx-service/${_vm.invoice.pbx_service_id}/view`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("credit-card-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-primary-800",
                                              }),
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("general.go_to")
                                                  ) +
                                                  ": " +
                                                  _vm._s(_vm.codeservice) +
                                                  "\n          "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _vm.permissionModule.readPayment
                                ? _c(
                                    "div",
                                    [
                                      _vm.payments.length != 0
                                        ? _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm.$t("navigation.payments")
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.payments,
                                        function (item, index) {
                                          return _c(
                                            "sw-dropdown-item",
                                            {
                                              key: index,
                                              attrs: {
                                                to: `/admin/payments/${item.id}/view`,
                                                "tag-name": "router-link",
                                              },
                                            },
                                            [
                                              _c("credit-card-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-primary-800",
                                              }),
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("general.go_to")
                                                  ) +
                                                  ": " +
                                                  _vm._s(item.payment_number) +
                                                  "\n          "
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isArchived &&
              _vm.isAvalaraInvoice == 1 &&
              _vm.statusAvalaraVoid != 2 &&
              _vm.statusAvalaraVoid != 3
                ? _c("template", { slot: "actions" }, [
                    _c(
                      "div",
                      { staticClass: "mr-3 text-sm" },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "text-sm",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.AvalaraVoid()
                              },
                            },
                          },
                          [_c("div", [_vm._v("Avalara Void")])]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isSuperAdmin
            ? _c(
                "div",
                {
                  staticClass:
                    "fixed top-0 left-0 hidden h-full pt-16 pb-5 ml-56 bg-white xl:ml-64 w-88 xl:block",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full",
                    },
                    [
                      _c(
                        "sw-input",
                        {
                          staticClass: "mb-6",
                          attrs: {
                            placeholder: _vm.$t("general.search"),
                            type: "text",
                            variant: "gray",
                          },
                          on: { input: _vm.onSearch },
                          model: {
                            value: _vm.searchData.searchText,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchData, "searchText", $$v)
                            },
                            expression: "searchData.searchText",
                          },
                        },
                        [
                          _c("search-icon", {
                            staticClass: "h-5",
                            attrs: { slot: "rightIcon" },
                            slot: "rightIcon",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex mb-6 ml-3",
                          attrs: { role: "group", "aria-label": "First group" },
                        },
                        [
                          _c(
                            "sw-dropdown",
                            {
                              attrs: {
                                "close-on-select": false,
                                align: _vm.left,
                                position: "bottom-start",
                              },
                            },
                            [
                              _c(
                                "sw-button",
                                {
                                  attrs: {
                                    slot: "activator",
                                    size: "md",
                                    variant: "gray-light",
                                  },
                                  slot: "activator",
                                },
                                [_c("filter-icon", { staticClass: "h-5" })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-2 py-1 mb-2 border-b border-gray-200 border-solid",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.sort_by")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  staticClass: "flex px-1 py-1 cursor-pointer",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "-mt-2 text-sm font-normal",
                                    },
                                    [
                                      _c("sw-radio", {
                                        attrs: {
                                          id: "filter_invoice_date",
                                          label: _vm.$t(
                                            "invoices.invoice_date"
                                          ),
                                          name: "filter",
                                          size: "sm",
                                          value: "invoice_date",
                                        },
                                        on: { change: _vm.onSearch },
                                        model: {
                                          value: _vm.searchData.orderByField,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchData,
                                              "orderByField",
                                              $$v
                                            )
                                          },
                                          expression: "searchData.orderByField",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  staticClass: "flex px-1 py-1 cursor-pointer",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    { staticClass: "-mt-2 font-normal" },
                                    [
                                      _c("sw-radio", {
                                        attrs: {
                                          id: "filter_due_date",
                                          label: _vm.$t("invoices.due_date"),
                                          name: "filter",
                                          size: "sm",
                                          value: "due_date",
                                        },
                                        on: { change: _vm.onSearch },
                                        model: {
                                          value: _vm.searchData.orderByField,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchData,
                                              "orderByField",
                                              $$v
                                            )
                                          },
                                          expression: "searchData.orderByField",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                {
                                  staticClass: "flex px-1 py-1 cursor-pointer",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    { staticClass: "-mt-2 font-normal" },
                                    [
                                      _c("sw-radio", {
                                        attrs: {
                                          id: "filter_invoice_number",
                                          label: _vm.$t(
                                            "invoices.invoice_number"
                                          ),
                                          size: "sm",
                                          type: "radio",
                                          name: "filter",
                                          value: "invoice_number",
                                        },
                                        on: { change: _vm.onSearch },
                                        model: {
                                          value: _vm.searchData.orderByField,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchData,
                                              "orderByField",
                                              $$v
                                            )
                                          },
                                          expression: "searchData.orderByField",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top-center",
                                  value: { content: _vm.getOrderName },
                                  expression: "{ content: getOrderName }",
                                  modifiers: { "top-center": true },
                                },
                              ],
                              staticClass: "ml-1",
                              attrs: { size: "md", variant: "gray-light" },
                              on: { click: _vm.sortData },
                            },
                            [
                              _vm.getOrderBy
                                ? _c("sort-ascending-icon", {
                                    staticClass: "h-5",
                                  })
                                : _c("sort-descending-icon", {
                                    staticClass: "h-5",
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isSearching
                    ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sw-scroll",
                        },
                        [
                          _vm._l(
                            _vm.invoices.slice(0, 10),
                            function (invoice, index) {
                              return _c(
                                "router-link",
                                {
                                  key: index,
                                  class: [
                                    "flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100  border-l-4 border-transparent",
                                    {
                                      "bg-gray-100 border-l-4 border-primary-500 border-solid":
                                        _vm.hasActiveUrl(invoice.id),
                                    },
                                  ],
                                  staticStyle: {
                                    "border-bottom":
                                      "1px solid rgba(185, 193, 209, 0.41)",
                                  },
                                  attrs: {
                                    to: `/admin/invoices/${invoice.id}/view`,
                                    id: "invoice-" + invoice.id,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pr-2 mb-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.formatName(
                                                  invoice.user.name
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-1 mb-2 text-xs not-italic font-medium leading-5 text-gray-600",
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(invoice.invoice_number) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-badge",
                                        {
                                          staticClass: "px-1 text-xs",
                                          attrs: {
                                            "bg-color":
                                              _vm.$utils.getBadgeStatusColor(
                                                invoice.status
                                              ).bgColor,
                                            color:
                                              _vm.$utils.getBadgeStatusColor(
                                                invoice.status
                                              ).color,
                                            "font-size":
                                              _vm.$utils.getBadgeStatusColor(
                                                invoice.status
                                              ).fontSize,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(invoice.status) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-1 whitespace-nowrap right",
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "mb-2 text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              invoice.due_amount,
                                              invoice.user.currency
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm not-italic font-normal leading-5 text-right text-gray-600",
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                invoice.formattedInvoiceDate
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          !_vm.invoices.length
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "flex justify-center px-4 mt-5 text-sm text-gray-600",
                                },
                                [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(
                                        _vm.$t("invoices.no_matching_invoices")
                                      ) +
                                      "\n      "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex flex-col min-h-0 mt-8 overflow-hidden",
              staticStyle: { height: "75vh" },
            },
            [
              _c("iframe", {
                staticClass:
                  "flex-1 border border-gray-400 border-solid rounded-md frame-style",
                attrs: {
                  id: "iframe",
                  name: "iframe",
                  src: `${_vm.shareableLink}`,
                },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }