var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("settings.mobile.login_logs.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "dashboard" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("settings.mobile.login_logs.title", 2),
                  to: "#",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalLogins,
                      expression: "totalLogins",
                    },
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: {
                    label: _vm.$tc("settings.mobile.login_logs.customer", 1),
                  },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "customer",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.customer,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "customer", $$v)
                      },
                      expression: "filters.customer",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-3 ml-0 lg:ml-6",
                  attrs: {
                    label: _vm.$t("settings.mobile.login_logs.login_date"),
                  },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.session_start,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "session_start", $$v)
                      },
                      expression: "filters.session_start",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("settings.mobile.login_logs.os") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "operating_system",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.operating_system,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "operating_system", $$v)
                      },
                      expression: "filters.operating_system",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("settings.mobile.login_logs.no_logins"),
            description: _vm.$t(
              "settings.mobile.login_logs.list_of_logins_desc"
            ),
          },
        },
        [_c("astronaut-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.toLogins))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalLogins))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
          }),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$tc("settings.mobile.login_logs.customer", 1),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$tc("settings.mobile.login_logs.customer", 1)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-medium text-primary-500 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.customerDetail(row.customer[0].id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.customer[0].customcode) +
                                " - " +
                                _vm._s(row.customer[0].name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("settings.mobile.login_logs.date"),
                  show: "session_start",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("settings.mobile.login_logs.date"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.session_start) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("settings.mobile.login_logs.system_name"),
                  show: "system_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.mobile.login_logs.system_name")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.system_name) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("settings.mobile.login_logs.device_name"),
                  show: "device_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.mobile.login_logs.device_name")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.device_name) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-modal", {
            ref: "seenNotificationModal",
            attrs: { variant: "primary" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute flex content-center justify-center w-5 cursor-pointer",
                        staticStyle: { top: "20px", right: "15px" },
                        on: { click: _vm.closeModal },
                      },
                      [_c("x-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("general.notification"))),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }