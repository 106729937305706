var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "option-group-create" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitData.apply(null, arguments)
          },
        },
      },
      [
        _c("sw-page-header", {
          staticClass: "mb-3",
          attrs: { title: _vm.title },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "grid grid-cols-12" }, [
          _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c(
                "sw-card",
                { staticClass: "mb-8" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: { label: _vm.$t("customer_ticket.reference") },
                    },
                    [
                      _c("sw-input", {
                        attrs: { type: "text", readonly: "" },
                        model: {
                          value: _vm.formData.reference,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "reference", $$v)
                          },
                          expression: "formData.reference",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("customer_ticket.subject"),
                        error: _vm.subjectError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: { type: "text" },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.subject.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "subject", $$v)
                          },
                          expression: "formData.subject",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("customer_ticket.message"),
                        error: _vm.messageError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-textarea", {
                        staticStyle: { resize: "none" },
                        attrs: { rows: "10", cols: "50" },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.message.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.message,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "message", $$v)
                          },
                          expression: "formData.message",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-18px" },
                          model: {
                            value: _vm.formData.public,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "public", $$v)
                            },
                            expression: "formData.public",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("customer_ticket.note_ticket_view")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("customer_ticket.note_ticket_view_desc")
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("general.datetime"),
                        error: _vm.dateError,
                        required: "",
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        staticStyle: { "max-width": "100%" },
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                          disabled: true,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.date.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "date", $$v)
                          },
                          expression: "formData.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("general.datetime"),
                        error: _vm.timeError,
                        required: "",
                        variant: "horizontal",
                      },
                    },
                    [
                      _c("base-time-picker", {
                        staticStyle: { "max-width": "100%" },
                        attrs: {
                          invalid: _vm.$v.formData.time.$error,
                          "calendar-button": true,
                          placeholder: "HH:mm",
                          "calendar-button-icon": "calendar",
                          disabled: true,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.time.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "time", $$v)
                          },
                          expression: "formData.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
                    },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-3",
                          attrs: { variant: "primary-outline", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.cancelNote()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.cancel")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2" })
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("general.save")) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }