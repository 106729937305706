var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    {
      staticClass:
        "absolute bottom-0 flex items-center justify-end w-full h-10 py-2 pr-8 text-sm font-normal text-gray-700 bg-white",
    },
    [
      _vm.footer.footer_text_value
        ? _c("div", [
            _c("p", { staticClass: "mb-3 ml-12" }, [
              _vm._v(_vm._s(_vm.footer.footer_text_value) + "\n      "),
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: _vm.footer.footer_url_value,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.footer.footer_url_name) + " ")]
              ),
              _vm._v(" "),
              _vm.footer.current_year == 1
                ? _c("span", [
                    _vm._v(
                      "\n        © " + _vm._s(_vm.footer_year) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _c("p", { staticClass: "mb-3 ml-12" }, [
            _vm._v(_vm._s(_vm.$t("layout_login.copyright_crater"))),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }