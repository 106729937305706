var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { class: { "xl:pl-96": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("corePbx.title") } },
        [
          _c("template", { slot: "actions" }, [
            _c(
              "div",
              {
                staticClass: "mr-3 hidden xl:block",
                staticStyle: { "margin-bottom": "10px" },
              },
              [
                _c(
                  "sw-button",
                  {
                    attrs: { variant: "primary-outline" },
                    on: { click: _vm.togglehidenmenu },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("corePbx.menu")) +
                        "\n          "
                    ),
                    _c(_vm.listIcon, {
                      tag: "component",
                      staticClass: "w-4 h-4 ml-2 -mr-1",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("customer-view-sidebar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pbx-stats"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
          staticStyle: { "border-top-color": "#f9fbff" },
        },
        [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck1" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck1" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("corePbx.dashboard.extencions")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content" },
                [
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchPackagesData,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.dashboard.name"),
                          show: "code",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.total"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
          staticStyle: { "border-top-color": "#f9fbff" },
        },
        [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck1" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck1" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("corePbx.dashboard.international_calls")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content" },
                [
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchPackagesData,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.dashboard.from"),
                          show: "code",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.to"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.date"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.totald"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
          staticStyle: { "border-top-color": "#f9fbff" },
        },
        [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck1" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck1" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("corePbx.dashboard.call_history")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content" },
                [
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchPackagesData,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.dashboard.from"),
                          show: "code",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.to"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.date"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.totald"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
          staticStyle: { "border-top-color": "#f9fbff" },
        },
        [
          _c("div", { staticClass: "border-b tab" }, [
            _c("div", { staticClass: "relative" }, [
              _c("input", {
                staticClass:
                  "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                attrs: { type: "checkbox", id: "chck1" },
              }),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass:
                    "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                  attrs: { for: "chck1" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-gray-500 uppercase sw-section-title" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("corePbx.dashboard.did")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            "aria-hidden": "true",
                            "data-reactid": "266",
                            fill: "none",
                            height: "24",
                            stroke: "#606F7B",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            viewbox: "0 0 24 24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "6 9 12 15 18 9" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content" },
                [
                  _c(
                    "sw-table-component",
                    {
                      ref: "table",
                      attrs: {
                        "show-filter": false,
                        data: _vm.fetchPackagesData,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("corePbx.dashboard.did_channel"),
                          show: "code",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$tc("corePbx.dashboard.provider"),
                          show: "package.name",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("general.actions"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown",
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
        _c("div", { staticClass: "border-b tab" }, [
          _c("div", { staticClass: "relative" }, [
            _c("input", {
              staticClass:
                "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
              attrs: { type: "checkbox", id: "chck4" },
            }),
            _vm._v(" "),
            _c(
              "header",
              {
                staticClass:
                  "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                attrs: { for: "chck4" },
              },
              [
                _c(
                  "span",
                  { staticClass: "text-gray-500 uppercase sw-section-title" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("customers.services_pbx")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          "aria-hidden": "true",
                          "data-reactid": "266",
                          fill: "none",
                          height: "24",
                          stroke: "#606F7B",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          viewbox: "0 0 24 24",
                          width: "24",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [_c("polyline", { attrs: { points: "6 9 12 15 18 9" } })]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _c(
                  "sw-table-component",
                  {
                    ref: "services_pbx_table",
                    attrs: {
                      "show-filter": false,
                      data: _vm.fetchPbxServicesData,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("services.service_number"),
                        show: "code",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$tc("packages.package", 1),
                        show: "pbxPackage.pbx_package_name",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customers.amount"),
                        "sort-as": "total",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("customers.amount"))),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.total,
                                      row.user.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customers.term"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("customers.term"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.capitalizeFirstLetter(row.term))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customers.activation_date"),
                        "sort-as": "date_begin",
                        show: "date_begin",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("customers.renewal_date"),
                        show: "renewal_date",
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown no-click",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("general.actions"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: ``,
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("calculator-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t("invoices.new_invoice")
                                          ) +
                                          "\n                    "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        to: ``,
                                        "tag-name": "router-link",
                                      },
                                    },
                                    [
                                      _c("cog-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$t("general.manage")) +
                                          "\n                    "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }