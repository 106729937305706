<template>
  <base-page>
    <!-- Header  -->
    <sw-page-header class="mb-3" :title="'View Note'">
      <template slot="actions">
        <!-- Go back -->
        <sw-button
          class="mr-3"
          variant="primary-outline"
          type="button"
          @click="cancelNote()"
        >
          {{ $t("general.go_back") }}
        </sw-button>

        <!-- editar -->
        <sw-button @click="editNote(note)" class="mr-3" variant="primary-outline">
          <pencil-icon class="h-5 mr-3 text-gray-600" />
          {{ $t("general.edit") }}
        </sw-button>

        <!-- eliminar -->
        <sw-button
          @click="deleteNote(note.id)"
          type="button"
          class="btn btn-primary"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <trash-icon class="h-5 mr-3 text-gray-600" />
          <span v-if="!isLoading">{{ $tc("general.delete") }}</span>
        </sw-button>
      </template>
    </sw-page-header>

    <div class="w-full">
      <div class="col-span-12">
        <sw-card>
          <div>
            <p class="text-gray-500 uppercase sw-section-title">
              {{ $t("general.note_information") }}
            </p>

            <div class="flex flex-wrap mt-4 md:mt-6">
              <div class="w-full md:w-1/4">
                <div class="font-bold py-2">
                  {{ $t("general.reference") }}
                </div>
                <div>
                  <p class="text-gray-700 text-sm">
                    {{ note.reference }}
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/4">
                <div class="font-bold py-2">
                  {{ $t("expenses.subject") }}
                </div>
                <div>
                  <p class="text-gray-700 text-sm">
                    {{ note.subject }}
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/4">
                <div class="font-bold py-2">
                  {{ $t("general.datetime") }}
                </div>
                <div>
                  <p class="text-gray-700 text-sm">{{ note.date }} {{ note.time }}</p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/4">
              <div class="font-bold py-2">
                {{ $t("customer_ticket.note_ticket_view") }}
              </div>
              <div>
                <p class="text-gray-700 text-sm">
                  {{ note.public === 1 ? "YES" : "NOT" }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap mt-5 md:mt-7">
              <div class="w-full md:w-1/2">
                <p class="font-bold">{{ $t("general.message") }}</p>
                <p class="text-gray-700 text-sm">{{ note.message }}</p>
              </div>
            </div>
          </div>
        </sw-card>
        <sw-card>
          <note-docs :note="note" @reloadDocuments="loadData" />
        </sw-card>
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NoteDocs from "./docs/NoteDocs.vue";
import moment from "moment";
const {
  required,
  minLength,
  numeric,
  maxLength,
  minValue,
  email,
} = require("vuelidate/lib/validators");
import {
  PencilIcon,
  TrashIcon,
  RefreshIcon,
  FilterIcon,
  XIcon,
  EyeIcon,
} from "@vue-hero-icons/outline";
export default {
  components: {
    PencilIcon,
    TrashIcon,
    RefreshIcon,
    FilterIcon,
    XIcon,
    EyeIcon,
    NoteDocs,
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      isRequestOnGoing: false,
      ticketUserId: 0,
      title: "",
      formData: {
        id: null,
        subject: "",
        message: "",
        reference: "",
        customer_ticket_id: null,
        date: null,
        time: null,
      },
      ticket: {},
      note: {},
      id: null,
      idTicket: null,
    };
  },
  validations: {
    formData: {
      subject: {
        required,
      },
      message: {
        required,
      },
      reference: {
        required,
      },
      date: {
        required,
      },
      time: {
        required,
      },
    },
  },

  mounted() {
    // this.note = this.$route.params.data.note
    // this.ticket = this.$route.params.data.ticket
    // this.id = this.$route.params.id
    // this.idTicket = this.$route.params.idTicket
    // this.formData.customer_ticket_id = this.$route.params.idTicket
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive", "refreshData"]),

    subjectError() {
      if (!this.$v.formData.subject.$error) {
        return "";
      }

      if (!this.$v.formData.subject.required) {
        return this.$tc("validation.required");
      }
    },
    messageError() {
      if (!this.$v.formData.message.$error) {
        return "";
      }

      if (!this.$v.formData.message.required) {
        return this.$tc("validation.required");
      }
    },
    dateError() {
      if (!this.$v.formData.date.$error) {
        return "";
      }
      if (!this.$v.formData.date.required) {
        return this.$t("validation.required");
      }
    },
    timeError() {
      if (!this.$v.formData.time.$error) {
        return "";
      }
      if (!this.$v.formData.time.required) {
        return this.$t("validation.required");
      }
    },
  },
  validations: {
    formData: {
      subject: {
        required,
      },
      message: {
        required,
      },
      date: {
        required,
      },
      time: {
        required,
      },
    },
  },

  methods: {
    ...mapActions("customerTicket", [
      "addNoteTicket",
      "fetchTicketNote",
      "deleteNoteTicket",
      "showCustomerTicketNotes",
    ]),

    async loadData() {
      // is edit name router is "note.edit"
      this.idNote = this.$route.params.id;

      try {
        let res = await this.showCustomerTicketNotes({ id: this.idNote });
        this.ticketUserId = res.data.ticketUserId;
        this.note = res.data.data;
        this.ticket = res.data.data.ticket;
        this.formData.subject = this.note.subject;
        this.formData.message = this.note.message;
        this.formData.reference = this.note.reference;
        this.formData.date = this.note.date;
        this.formData.time = this.note.time;
        this.formData.customer_ticket_id = this.note.customer_ticket_id;
        this.formData.id = this.note.id;
        console.log(res);
        console.log(this.formData);
        this.isEdit = true;
      } catch (error) {
        console.log('error details: ', error.reponse || error.message || error);
        window.toastr["error"](error);
      }
    },

    cancelNote() {
      this.$router.push(
        `/admin/tickets/main/${this.ticketUserId}/${this.note.customer_ticket_id}/view-ticket`
      );
    },

    editNote(note) {
      const data = {
        ticket: this.ticket,
        notes_quantity: this.notes_count,
        isEdit: true,
      };
      this.$router.push({
        name: "note.create",
        params: {
          id: note.user_id,
          id1: note.customer_ticket_id,
          note: {...note, note_id: note.id},
          data: data,
        },
      });
    },

    async deleteNote(id) {
      console.log(id)
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("customer_ticket.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        try {
          if (willDelete) {
            this.isLoading = false;
            let res = await this.deleteNoteTicket({ id: id });
            if (res.data.success) {
              window.toastr["success"](this.$tc("customer_ticket.deleted_message", 1));
              this.$router.push(
                `/admin/tickets/main/${this.ticketUserId}/${this.formData.customer_ticket_id}/view-ticket`
              );
              return true;
            }
          }
        } catch (e) {
          window.toastr["error"](res.data.message);
        } finally {
          this.isLoading = false;
        }
      });
    },
  },
};
</script>
