var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs mb-5 grid col-span-12 pt-6" }, [
    _c("div", { staticClass: "border-b tab" }, [
      _c("div", { staticClass: "relative" }, [
        _c("input", {
          staticClass:
            "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
          attrs: { type: "checkbox", id: "chck3" },
        }),
        _vm._v(" "),
        _c(
          "header",
          {
            staticClass:
              "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
            attrs: { for: "chck3" },
          },
          [
            _c(
              "span",
              { staticClass: "text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$tc("tickets.tickets_assigned")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      "aria-hidden": "true",
                      "data-reactid": "266",
                      fill: "none",
                      height: "24",
                      stroke: "#606F7B",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      viewbox: "0 0 24 24",
                      width: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [_c("polyline", { attrs: { points: "6 9 12 15 18 9" } })]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-content" },
          [
            _c(
              "sw-table-component",
              {
                ref: "table",
                attrs: {
                  data: _vm.fetchData,
                  "show-filter": false,
                  "table-class": "table",
                },
              },
              [
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customer_ticket.summary"),
                    show: "summary",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("customer_ticket.summary"))),
                          ]),
                          _vm._v(" "),
                          _vm.readTickets
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: `/admin/tickets/main/${row.user_id}/${row.id}/view-ticket`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.summary) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.summary) +
                                    "\n              "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customer_ticket.departament"),
                    show: "departament",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("customer_ticket.departament"))
                            ),
                          ]),
                          _vm._v(
                            "\n              " +
                              _vm._s(row.departament) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customer_ticket.assignedTo"),
                    show: "assigned",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("customer_ticket.assignedTo"))
                            ),
                          ]),
                          _vm._v(
                            "\n              " +
                              _vm._s(row.assigned) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("customer_ticket.status"),
                    show: "status",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("customer_ticket.status"))),
                          ]),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getStatus(row.status)) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("sw-table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("payments.date"),
                    "sort-as": "created_at",
                    show: "formattedCustomerNoteDate",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }