var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tax-type-modal",
      attrs: { "data-modal-toggle": "large-modal" },
    },
    [
      _c(
        "form",
        {
          staticClass: "mb-5",
          staticStyle: {
            "padding-top": "17.5px",
            "padding-right": "37.5px",
            "padding-left": "30px",
          },
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              staticClass: "md:col-span-3 mb-5",
              attrs: { label: _vm.$t("core_pos.cash_register"), required: "" },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.cash_register_options,
                  searchable: true,
                  "show-labels": false,
                  "allow-empty": false,
                  placeholder: _vm.$t("core_pos.select_cash_register"),
                  label: "name",
                  "track-by": "id",
                  tabindex: 11,
                },
                model: {
                  value: _vm.cash_register_selected,
                  callback: function ($$v) {
                    _vm.cash_register_selected = $$v
                  },
                  expression: "cash_register_selected",
                },
              }),
              _vm._v(" "),
              _vm.cash_register_required
                ? _c("p", { staticClass: "text-red-800 text-xs" }, [
                    _vm._v(_vm._s(_vm.$t("core_pos.cash_register_error"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-solid border--200 border-modal-bg mt-8",
            },
            [
              _c(
                "sw-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    variant: "primary",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.continue")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }