<template>
  <base-page class="customer-create">
    <form v-if="!initLoad" @submit.prevent="submitPaymentAccountData">
      <sw-page-header class="mb-5" :title="pageTitle">
        <sw-breadcrumb slot="breadcrumbs">
          <sw-breadcrumb-item
            to="/admin/dashboard"
            :title="$t('general.home')"
          />
          <sw-breadcrumb-item
            :to="`/admin/customers/${this.$route.params.id}/payment-accounts`"
            :title="$tc('payment_accounts.title', 2)"
          />
          <sw-breadcrumb-item
            v-if="
              $route.name === 'customers.payment-account.create.CC' ||
              $route.name === 'customers.payment-account.create.ACH'
            "
            to="#"
            :title="$t('payment_accounts.new_payment_account')"
            active
          />
          <sw-breadcrumb-item
            v-else
            to="#"
            :title="$t('payment_accounts.edit_payment_account')"
            active
          />
        </sw-breadcrumb>
        <template slot="actions">
          <sw-button
            class="mr-3 text-sm hidden sm:flex"
            variant="primary-outline"
            type="button"
            @click="cancelForm()"
          >
            <x-circle-icon v-if="!isLoading" class="mr-2 -ml-1" />
            {{ $t('general.cancel') }}
          </sw-button>

          <sw-button
            :loading="isLoading"
            :disabled="isLoading"
            variant="primary"
            type="submit"
            size="lg"
            class="hidden md:relative md:flex"
          >
            <save-icon v-if="!isLoading" class="mr-2 -ml-1" />

            {{
              isEditButton
                ? $t('payment_accounts.update_payment_account')
                : $t('payment_accounts.save_payment_account')
            }}
          </sw-button>
        </template>
      </sw-page-header>

      <sw-card variant="customer-card">
        <!-- Contact Info  -->
        <div class="grid grid-cols-5 gap-4 mb-8">
          <h6 class="col-span-5 sw-section-title lg:col-span-1">
            {{ $t('payment_accounts.contact_info') }}
          </h6>

          <div
            class="grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6"
          >
            <sw-input-group
              v-if="isCC || isEditCC"
              :label="$t('payment_accounts.name_on_card')"
              class="md:col-span-12"
              :error="displayFirstNameError"
              required
            >
              <sw-input
                :invalid="$v.formData.first_name.$error"
                v-model="formData.first_name"
                focus
                type="text"
                name="name"
                tabindex="1"
                @input="$v.formData.first_name.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              v-if="isACH || isEditACH"
              :label="$t('payment_accounts.name_on_account')"
              class="md:col-span-12"
              :error="displayFirstNameError"
              required
            >
              <sw-input
                :invalid="$v.formData.first_name.$error"
                v-model="formData.first_name"
                focus
                type="text"
                name="name"
                tabindex="1"
                @input="$v.formData.first_name.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.address_1')"
              class="md:col-span-4"
              :error="billAddress1Error"
              required
            >
              <sw-input
                :invalid="$v.formData.address_1.$error"
                v-model="formData.address_1"
                focus
                type="text"
                name="address_1"
                tabindex="1"
                @input="$v.formData.address_1.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.address_2')"
              class="md:col-span-8"
              :error="billAddress2Error"
            >
              <sw-input
                :invalid="$v.formData.address_2.$error"
                v-model="formData.address_2"
                focus
                type="text"
                name="address_1"
                tabindex="1"
                @input="$v.formData.address_2.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.city')"
              class="md:col-span-4"
              :error="cityError"
              required
            >
              <sw-input
                v-model="formData.city"
                :invalid="$v.formData.city.$error"
                name="formData.city"
                type="text"
                tabindex="1"
                @input="$v.formData.city.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :error="stateIdError"
              :label="$t('payment_accounts.state')"
              class="md:col-span-8"
              required
            >
              <sw-select
                v-model="billing_state"
                :invalid="$v.formData.state_id.$error"
                :options="billing_states"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                :tabindex="1"
                :placeholder="$t('general.select_state')"
                label="name"
                track-by="id"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.zip')"
              :error="zipError"
              class="md:col-span-4"
              required
            >
              <sw-input
                tabindex="1"
                v-model.trim="formData.zip"
                :invalid="$v.formData.zip.$error"
                type="text"
                name="zip"
                @input="$v.formData.zip.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :error="countryIdError"
              :label="$t('payment_accounts.country')"
              class="md:col-span-8"
              required
            >
              <sw-select
                v-model="billing_country"
                :invalid="$v.formData.country_id.$error"
                :options="countries"
                :searchable="true"
                :show-labels="false"
                :placeholder="$t('general.select_country')"
                label="name"
                track-by="id"
                :tabindex="1"
                @select="countrySelected($event, 'billing')"
              />
            </sw-input-group>

            <sw-input-group
              v-if="isEditCC || isEditACH"
              :label="$t('tax_groups.status')"
              class="md:col-span-3 mb-4"
              :error="statusError"
              required
            >
              <sw-select
                v-model="formData.status"
                :invalid="$v.formData.status.$error"
                :options="status"
                :searchable="true"
                :show-labels="false"
                :tabindex="1"
                :allow-empty="true"
                :placeholder="$t('tax_groups.status')"
                label="text"
                track-by="value"
              />
            </sw-input-group>

            <!-- Billing Address Copy Button  -->
            <div class="flex items-center justify-start mb-6 md:mb-0">
              <div class="p-1">
                <sw-button
                  ref="sameAddress"
                  variant="primary"
                  type="button"
                  class="h-8 px-3 py-1 mb-4"
                  @click="copyAddress(true)"
                >
                  <document-duplicate-icon class="h-4 mr-1 -ml-2" />
                  <span class="text-xs">
                    {{ $t('customers.copy_billing_address') }}
                  </span>
                </sw-button>
              </div>
            </div>
          </div>
        </div>

        <sw-divider class="mb-5 md:mb-8" />

        <!-- Bank Account Information  -->
        <div v-if="isACH || isEditACH" class="grid grid-cols-5 gap-4 mb-8">
          <h6 class="col-span-5 sw-section-title lg:col-span-1">
            {{ $t('payment_accounts.bank_account_info') }}
          </h6>

          <div
            class="grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6"
          >
            <sw-input-group
              :label="$t('payment_accounts.ACH_type')"
              class="md:col-span-3"
              :error="ACHTypeError"
              required
            >
              <sw-select
                v-model="formData.ACH_type"
                :options="bank_account_type"
                :invalid="$v.formData.ACH_type.$error"
                :searchable="true"
                :show-labels="false"
                :tabindex="1"
                :allow-empty="true"
                label="text"
                track-by="value"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.account_number')"
              class="md:col-span-3"
              :error="accountNumberError"
              required
            >
              <sw-input
                :invalid="$v.formData.account_number.$error"
                v-model="formData.account_number"
                focus
                type="password"
                name="account_number"
                tabindex="1"
                @input="$v.formData.account_number.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.routing_number')"
              class="md:col-span-3"
              :error="routingNumberError"
              required
            >
              <sw-input
                :invalid="$v.formData.routing_number.$error"
                v-model="formData.routing_number"
                focus
                type="password"
                name="routing_number"
                tabindex="1"
                @input="$v.formData.routing_number.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.bankname')"
              class="md:col-span-3"
            >
              <sw-input
                v-model="formData.bank_name"
                focus
                type="text"
                name="bank_name"
                tabindex="1"
                autocomplete="off"
              />
            </sw-input-group>
          </div>
        </div>

        <!-- Credit Card Information  -->
        <div v-if="isCC || isEditCC" class="grid grid-cols-5 gap-4 mb-8">
          <h6 class="col-span-5 sw-section-title lg:col-span-1">
            {{ $t('payment_accounts.credit_card_info') }}
          </h6>

          <div
            class="grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6"
          >
            <sw-input-group
              :label="$t('payment_accounts.card_number')"
              class="md:col-span-3"
              :error="cardNumberError"
              required
            >
              <sw-input
                :invalid="$v.formData.card_number.$error"
                v-model="formData.card_number"
                focus
                type="password"
                name="card_number"
                tabindex="1"
                @input="$v.formData.card_number.$touch()"
                autocomplete="off"
              />
            </sw-input-group>
            <sw-input-group
              :label="$t('settings.payment_gateways.credit_cards')"
              class="md:col-span-3"
              required
            >
              <sw-select
                v-model="formData.credit_cards"
                :options="credit_cards"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                :tabindex="1"
                :placeholder="$t('items.select_a_type')"
                :invalid="$v.formData.credit_cards.$error"
                class="mt-2"
                label="name"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payment_accounts.cvv')"
              class="md:col-span-3"
              :error="CvvError"
              required
            >
              <sw-input
                :invalid="$v.formData.cvv.$error"
                v-model="formData.cvv"
                focus
                type="password"
                name="cvv"
                tabindex="1"
                @input="$v.formData.cvv.$touch()"
                autocomplete="off"
              />
            </sw-input-group>

            <!-- <sw-input-group
              :label="$t('payment_accounts.expiration_date')"
              class="md:col-span-3"
              :error="DateError"
              required
            >
              <VueDatePicker
                v-model="formData.expiration_date"
                :invalid="$v.formData.expiration_date.$error"
                min-date="1900-1"
                max-date="2090-12"
                :locale="locale"
                type="month"
                @input="$v.formData.expiration_date.$touch()"
              />
            </sw-input-group> -->
            <creditCardExpirationDate
              v-model="formData.expiration_date"
              class="md:col-span-3"
              :invalid="$v.formData.expiration_date.$error"
            />
          </div>
        </div>

        <sw-button
          class="mr-3 flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden"
          variant="primary-outline"
          type="button"
          @click="cancelForm()"
        >
          <x-circle-icon v-if="!isLoading" class="mr-2 -ml-1" />
          {{ $t('general.cancel') }}
        </sw-button>

        <!-- Mobile Submit Button  -->
        <sw-button
          :disabled="isLoading"
          :loading="isLoading"
          variant="primary"
          type="submit"
          size="lg"
          class="flex w-full sm:hidden md:hidden mb-2 mb-md-0"
        >
          <save-icon v-if="!isLoading" class="mr-2 -ml-1" />
          {{
            isEditButton
              ? $t('payment_accounts.update_payment_account')
              : $t('payment_accounts.save_payment_account')
          }}
        </sw-button>
      </sw-card>
    </form>
    <base-loader v-else-if="initLoad"/>
  </base-page>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'
import { DocumentDuplicateIcon, XCircleIcon } from '@vue-hero-icons/solid'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import creditCardExpirationDate from '@/components/payments/creditCardExpirationDate.vue'
import { validateCreditCard, validateCardTypeAndNumber } from '@/helpers/creditCardHelper.js'

const {
  required,
  numeric,
  minValue,
  minLength,
  email,
  url,
  maxLength,
  sameAs,
} = require('vuelidate/lib/validators')

export default {
  components: {
    DocumentDuplicateIcon,
    VueDatePicker,
    creditCardExpirationDate,
    XCircleIcon,
  },
  data() {
    return {
      isCopyFromBilling: false,
      isLoading: false,
      initLoad: false,
      isEditButton: false,
      locale: { lang: 'en' },
      formData: {
        first_name: null,
        country_id: null,
        state_id: null,
        city: null,
        address_1: null,
        address_2: null,
        zip: null,
        payment_account_type: null,
        client_id: null,
        status: {
          value: 'A',
          text: 'Active',
        },

        card_number: null,
        isCardValid: true,
        cvv: null,
        expiration_date: new Date(),
        credit_cards: null,

        ACH_type: null,
        account_number: null,
        routing_number: null,
        bank_name: null,
      },
      status: [
        {
          value: 'A',
          text: 'Active',
        },
        {
          value: 'I',
          text: 'Inactive',
        },
      ],

      bank_account_type: [
        {
          value: 'Checking',
          text: 'Checking',
        },
        {
          value: 'Savings',
          text: 'Savings',
        },
      ],

      billing_state: null,
      billing_country: null,

      countries: [],
      billing_states: [],

      credit_cards: [
        { name: 'VISA', value: 'VISA' },
        { name: 'MASTERCARD', value: 'MASTERCARD' },
        { name: 'AMERICAN EXPRESS', value: 'AMERICAN EXPRESS' },
        { name: 'DISCOVER', value: 'DISCOVER' },
      ],
    }
  },
  validations() {
    if (this.isEditACH || this.isACH) {
      return {
        formData: {
          first_name: {
            required,
            minLength: minLength(3),
          },
          country_id: {
            required,
          },
          state_id: {
            required,
          },
          city: {
            required,
          },
          address_1: {
            required,
            maxLength: maxLength(255),
          },
          address_2: {
            maxLength: maxLength(255),
          },
          zip: {
            required,
          },
          status: {
            required,
          },
          ACH_type: {
            required,
          },
          account_number: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(17),
          },
          routing_number: {
            required,
            minLength: minLength(9),
            maxLength: maxLength(9),
          },
        },
      }
    } else if (this.isEditCC || this.isCC) {
      return {
        formData: {
          first_name: {
            required,
            minLength: minLength(3),
          },
          country_id: {
            required,
          },
          state_id: {
            required,
          },
          city: {
            required,
          },
          address_1: {
            required,
            maxLength: maxLength(255),
          },
          address_2: {
            maxLength: maxLength(255),
          },
          zip: {
            required,
          },
          status: {
            required,
          },
          card_number: {
            required,
            numeric,
            minLength: minLength(10),
            maxLength: maxLength(20),
            validateCardNumber: validateCreditCard,
          },
          credit_cards: {
            required,
          },
          cvv: {
            required,
            numeric,
            minLength: minLength(3),
            maxLength: maxLength(4),
          },
          expiration_date: {
            required,
          },
        },
      }
    }
  },
  computed: {
    isEditACH() {
      if (this.$route.name === 'customers.payment-account.edit.ACH') {
        this.isEditButton = true
        return true
      }
      return false
    },
    isEditCC() {
      if (this.$route.name === 'customers.payment-account.edit.CC') {
        this.isEditButton = true
        return true
      }
      return false
    },
    isCC() {
      if (this.$route.name === 'customers.payment-account.create.CC') {
        return true
      }
      return false
    },
    isACH() {
      if (this.$route.name === 'customers.payment-account.create.ACH') {
        return true
      }
      return false
    },
    pageTitle() {
      if (
        this.$route.name === 'customers.payment-account.edit.ACH' ||
        this.$route.name === 'customers.payment-account.edit.CC'
      ) {
        return this.$t('payment_accounts.edit_payment_account')
      }
      return this.$t('payment_accounts.new_payment_account')
    },
    hasBillingAdd() {
      let billing = this.formData
      if (
        billing.name ||
        billing.country_id ||
        billing.state ||
        billing.city ||
        billing.phone ||
        billing.zip ||
        billing.address_1 ||
        billing.address_2
      ) {
        return true
      }
      return false
    },
    displayFirstNameError() {
      if (!this.$v.formData.first_name.$error) {
        return ''
      }
      if (!this.$v.formData.first_name.required) {
        return this.$tc('validation.required')
      } else {
        return this.$tc(
          'validation.first_name_min_length',
          this.$v.formData.first_name.$params.minLength.min,
          { count: this.$v.formData.first_name.$params.minLength.min }
        )
      }
    },

    countryIdError() {
      if (!this.$v.formData.country_id.$error) {
        return ''
      }
      if (!this.$v.formData.country_id.required) {
        return this.$tc('validation.required')
      }
    },
    stateIdError() {
      if (!this.$v.formData.state_id.$error) {
        return ''
      }
      if (!this.$v.formData.state_id.required) {
        return this.$tc('validation.required')
      }
    },
    cityError() {
      if (!this.$v.formData.city.$error) {
        return ''
      }
      if (!this.$v.formData.city.required) {
        return this.$tc('validation.required')
      }
    },
    zipError() {
      if (!this.$v.formData.zip.$error) {
        return ''
      }
      if (!this.$v.formData.zip.required) {
        return this.$tc('validation.required')
      }
    },
    billAddress1Error() {
      if (!this.$v.formData.address_1.$error) {
        return ''
      }
      if (!this.$v.formData.address_1.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.address_1.maxLength) {
        return this.$t('validation.address_maxlength')
      }
    },
    billAddress2Error() {
      if (!this.$v.formData.address_2.$error) {
        return ''
      }
      if (!this.$v.formData.address_2.maxLength) {
        return this.$t('validation.address_maxlength')
      }
    },
    statusError() {
      if (!this.$v.formData.status.$error) {
        return ''
      }
      if (!this.$v.formData.status.required) {
        return this.$tc('validation.required')
      }
    },
    ACHTypeError() {
      if (!this.$v.formData.ACH_type.$error) {
        return ''
      }
      if (!this.$v.formData.ACH_type.required) {
        return this.$tc('validation.required')
      }
    },
    accountNumberError() {
      if (!this.$v.formData.account_number.$error) {
        return ''
      }
      if (!this.$v.formData.account_number.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.account_number.minLength) {
        return this.$t('validation.account_number_minLength')
      }
      if (!this.$v.formData.account_number.maxLength) {
        return this.$t('validation.account_number_maxLength')
      }
    },
    routingNumberError() {
      if (!this.$v.formData.routing_number.$error) {
        return ''
      }
      if (!this.$v.formData.routing_number.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.routing_number.minLength) {
        return this.$t('validation.routing_number_minLength')
      }
      if (!this.$v.formData.routing_number.maxLength) {
        return this.$t('validation.routing_number_maxLength')
      }
    },
    cardNumberError() {
      if (!this.$v.formData.card_number.$error) {
        return ''
      }
      if (!this.$v.formData.card_number.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.card_number.numeric) {
        return this.$tc('validation.numbers_only')
      }
      if (!this.$v.formData.card_number.minLength) {
        return this.$tc(
          'validation.name_min_length',
          this.$v.formData.card_number.$params.minLength.min,
          { count: this.$v.formData.card_number.$params.minLength.min }
        )
      }
      if (!this.$v.formData.card_number.maxLength) {
        return this.$t('authorize.cc_number_maxLength')
      }
      if (!this.validateCardNumber(this.formData.card_number)) {
        return this.$tc('validation.invalid_card_number')
      }
    },
    CvvError() {
      if (!this.$v.formData.cvv.$error) {
        return ''
      }
      if (!this.$v.formData.cvv.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.formData.cvv.numeric) {
        return this.$tc('validation.numbers_only')
      }
      if (!this.$v.formData.cvv.minLength) {
        return this.$tc(
          'validation.name_min_length',
          this.$v.formData.cvv.$params.minLength.min,
          { count: this.$v.formData.cvv.$params.minLength.min }
        )
      }
      if (!this.$v.formData.cvv.maxLength) {
        return this.$t('authorize.cvv_maxLength')
      }
    },
    DateError() {
      if (!this.$v.formData.expiration_date.$error) {
        return ''
      }
      if (!this.$v.formData.expiration_date.required) {
        return this.$t('validation.required')
      }
    },
  },
  watch: {
    billing_country(newCountry) {
      if (newCountry) {
        this.formData.country_id = newCountry.id
        this.isDisabledBillingState = false
      } else {
        this.formData.country_id = null
      }
    },
    billing_state(newState) {
      if (newState) {
        this.formData.state_id = newState.id
      } else {
        this.formData.state_id = null
      }
    },
  },
  created() {
    this.fetchInitData()
    if (this.isEditACH || this.isEditCC) {
      this.loadPaymentAccount()
      return true
    }
  },
  mounted() {},
  methods: {
    ...mapActions('customer', ['fetchCustomer']),
    ...mapActions('paymentAccounts', [
      'addPaymentAccount',
      'fetchPaymentAccount',
      'updatePaymentAccount',
    ]),
    ...mapActions('customFields', ['fetchCustomFields']),
    ...mapActions('user', ['getUserModules']),
    ...mapActions('user', ['fetchCurrentUser']),

    /**
     * Maneja la selección de un país y actualiza los estados correspondientes.
     *
     * @param {Object} country - El país seleccionado.
     * @param {String} type - El tipo de dirección (por ejemplo, 'billing').
     */
    async countrySelected(country, type) {
      // Indica que el proceso de carga ha comenzado
      this.isLoading = true

      try {
        // Si el tipo es 'billing', reinicia los estados de facturación
        if (type === 'billing') {
          this.billing_state = null
          this.billing_states = []
        }

        // Fetch states for the selected country
        const res = await window.axios.get(`/api/v1/states/${country.code}`)

        if (res && type === 'billing') {
          // Asigna la lista de estados de facturación
          this.billing_states = res.data.states
        }
      } catch (error) {
        // Manejo de errores
        console.error('Error fetching states:', error)
      } finally {
        // Indica que el proceso de carga ha terminado
        this.isLoading = false
      }
    },

    validateCardNumber(value) {
      return validateCreditCard(value);
    },

    /**
     * Carga la cuenta de pago y actualiza el formulario con los datos obtenidos.
     */
    async loadPaymentAccount() {
      try {
        // Obtener el ID de la cuenta de pago desde la ruta actual
        const id = this.$route.params.payment_account_id

        // Fetch de la cuenta de pago
        const response = await this.fetchPaymentAccount(id)
        const paymentAccount = response.data.payment_accounts

        // Actualizar los datos del formulario con la información de la cuenta de pago
        this.formData = { ...this.formData, ...paymentAccount }
        this.formData.credit_cards = {
          name: paymentAccount.credit_card,
          value: paymentAccount.credit_card,
        }

        // Formatear el tipo de ACH si está presente
        if (this.formData.ACH_type) {
          this.formData.ACH_type = {
            text: this.formData.ACH_type,
            value: this.formData.ACH_type,
          }
        }

        // Seleccionar el país de facturación si está presente
        if (paymentAccount.country_id) {
          this.billing_country = paymentAccount.country
          await this.countrySelected(this.billing_country, 'billing')
        }

        // Seleccionar el estado de facturación si está presente
        if (paymentAccount.state_id) {
          this.billing_state = paymentAccount.state
        }
      } catch (error) {
        // Manejo de errores
        console.error('Error loading payment account:', error)
      }
    },

    /**
     * Inicializa los datos necesarios para la vista.
     */
    async fetchInitData() {
      // Indica que el proceso de carga inicial ha comenzado
      this.initLoad = true

      try {
        // Fetch de países
        const res = await window.axios.get('/api/v1/countries')
        if (res) {
          this.countries = res.data.countries
        }

        // Fetch de datos del usuario actual
        const response = await this.fetchCurrentUser()
        const user = response.data.user

        if (user && !this.isEditACH && !this.isEditCC) {
          const userCompany = user.company
          this.billing_country = userCompany.address
            ? userCompany.address.country
            : ''
          if (this.billing_country) {
            await this.countrySelected(this.billing_country, 'billing')
          }
        }

        // Fetch de permisos del usuario
        const data = { module: 'cust_payment_acc' }
        const permissions = await this.getUserModules(data)

        // Validación de permisos para ingresar al módulo
        if (!permissions.super_admin) {
          if (!permissions.exist) {
            this.$router.push('/admin/dashboard')
          } else {
            const modulePermissions = permissions.permissions[0]
            const noCreatePermission = modulePermissions.create === 0
            const noUpdatePermission = modulePermissions.update === 0

            if (
              (noCreatePermission && (this.isACH || this.isCC)) ||
              (noUpdatePermission && (this.isEditACH || this.isEditCC))
            ) {
              this.$router.push('/admin/dashboard')
            }
          }
        }
      } catch (error) {
        // Manejo de errores
        console.error('Error fetching initial data:', error)
      } finally {
        // Indica que el proceso de carga inicial ha terminado
        this.initLoad = false
      }
    },

    /**
     * Copia la dirección de facturación del cliente al formulario.
     *
     * @param {boolean} val - Indica si se debe copiar la dirección.
     */
    async copyAddress(val) {
      // Verifica si el valor es verdadero, si no, sale del método
      if (!val) return

      try {
        // Obtener el ID del cliente desde la ruta actual
        const params = { id: this.$route.params.id }

        // Fetch customer data
        const response = await this.fetchCustomer(params)
        const customer = response.data.customer
        const billingAddress = customer.billing_address

        // Asignar los datos de la dirección de facturación del cliente al formulario
        Object.assign(this.formData, {
          first_name: customer.first_name,
          country_id: billingAddress.country_id,
          state_id: billingAddress.state_id,
          city: billingAddress.city,
          address_1: billingAddress.address_street_1,
          address_2: billingAddress.address_street_2,
          zip: billingAddress.zip,
        })

        // Asignar el país de facturación
        this.billing_country = billingAddress.country

        // Fetch states for the selected country
        const res = await window.axios.get(
          `/api/v1/states/${billingAddress.country.code}`
        )

        if (res) {
          // Asignar la lista de estados y el estado de facturación
          this.billing_states = res.data.states
          this.billing_state = this.billing_states.find(
            ({ id }) => id == billingAddress.state_id
          )
        }
      } catch (error) {
        // Manejo de errores
        console.error('Error fetching customer data:', error)
      }
    },

    /**
     * Envía los datos de la cuenta de pago.
     */
    async submitPaymentAccountData() {

      // Toca todos los campos del formulario para activar la validación
      this.$v.formData.$touch()

      // Si el formulario es inválido, retorna verdadero
      if (this.$v.$invalid) {
        return true
      }


      // Configura los datos del formulario según el tipo de cuenta de pago
      if (this.isEditACH || this.isACH) {
        this.formData.ACH_type = this.formData.ACH_type.value
        this.formData.expiration_date = null
        this.formData.payment_account_type = 'ACH'
      } else {
        this.formData.ACH_type = null
        this.formData.payment_account_type = 'CC'
        const result = validateCardTypeAndNumber(this.formData.card_number, this.formData.credit_cards);

       
        if (false) {
          let err = '';
          if(result.message === 1){
            err = this.$t('validateCard.number')
          }else if(result.message === 2){
            err = this.$t('validateCard.type')
          }
          window.toastr['error'](err);
          return;
        }
      }

      // Asigna el estado y el ID del cliente al formulario
      this.formData.status = this.formData.status.value
      this.formData.client_id = this.$route.params.id

      try {
        this.isLoading = true
        let response = null

        // Si se está editando una cuenta de pago
        if (this.isEditACH || this.isEditCC) {
          // Si el estado no es 'A', muestra una alerta de confirmación
          if (this.formData.status !== 'A') {
            const willDelete = await swal({
              title: this.$t('general.are_you_sure'),
              text: this.$tc('payment_accounts.change_status'),
              icon: 'warning',
              buttons: true,
            })

            if (willDelete) {
              response = await this.updatePaymentAccount(this.formData)
              this.handleResponse(response)
            }
          } else {
            response = await this.updatePaymentAccount(this.formData)
            this.handleResponse(response)
          }
        } else {
          // Si se está creando una nueva cuenta de pago
          console.log(this.formData)
          response = await this.addPaymentAccount(this.formData)
          if (response.status === 200) {
            this.$router.push(
              `/admin/customers/${this.$route.params.id}/payment-accounts/${response.data.payment_accounts.id}/view-${response.data.payment_accounts.payment_account_type}`
            )
            window.toastr['success'](this.$t('customers.created_message'))
          }
        }
      } catch (error) {
        console.error('Error submitting payment account data:', error)
      } 
      finally {
        this.isLoading = false
      }
    },

    /**
     * Maneja la respuesta del servidor después de actualizar o crear una cuenta de pago.
     *
     * @param {Object} response - La respuesta del servidor.
     */
    handleResponse(response) {
      if (response.status === 200) {
        this.$router.push(
          `/admin/customers/${this.$route.params.id}/payment-accounts/${response.data.payment_accounts.id}/view-${response.data.payment_accounts.payment_account_type}`
        )
        window.toastr['success'](this.$t('customers.updated_message'))
      }
      if (response.data.error) {
        window.toastr['error'](this.$t('validation.email_already_taken'))
      }
    },
    /**
     * Muestra una alerta de confirmación antes de cancelar el formulario.
     */
    cancelForm() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.lose_unsaved_information'),
        icon: 'error',
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>