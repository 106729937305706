var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", [
        _c(
          "div",
          { class: { "xl:pl-64": _vm.showSideBar } },
          [
            _c("div", { staticClass: "w-full flex justify-end" }, [
              _c(
                "div",
                { staticClass: "mb-3 hidden xl:block" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleSideBar },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("tickets.departaments.menu")) +
                          "\n          "
                      ),
                      _c(_vm.listIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "slide-x-left-transition",
              [
                _c("SidebartModules", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSideBar,
                      expression: "showSideBar",
                    },
                  ],
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }