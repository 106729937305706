var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-wizard-step",
    { attrs: { title: _vm.$t("customers.package_parameters") } },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12 mt-5" }, [
        _c(
          "div",
          { staticClass: "col-span-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$tc("packages.package_name", 1),
                  error: _vm.packageError,
                  required: "",
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.package.$error,
                    options: _vm.groups,
                    "group-select": false,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_package"),
                    "allow-empty": false,
                    "group-values": "options",
                    "group-label": "label",
                    "track-by": "package_name",
                    label: "package_name",
                    disabled: _vm.isEdit,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.package.$touch()
                    },
                    select: (item) => _vm.loadPackageData(item),
                  },
                  model: {
                    value: _vm.formData.package,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "package", $$v)
                    },
                    expression: "formData.package",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$tc("customers.status", 2) },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.status,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_status"),
                    label: "name",
                  },
                  on: { select: _vm.setBeginDate },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("customers.term") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.term,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_term"),
                    label: "name",
                  },
                  model: {
                    value: _vm.formData.term,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "term", $$v)
                    },
                    expression: "formData.term",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("customers.date_act"),
                      error: _vm.dateBeginError,
                      required: "",
                    },
                  },
                  [
                    _c("base-date-picker", {
                      ref: "dateBegin",
                      attrs: {
                        invalid: _vm.$v.formData.date_begin.$error,
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                        disabled: _vm.isEdit,
                      },
                      model: {
                        value: _vm.formData.date_begin,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "date_begin", $$v)
                        },
                        expression: "formData.date_begin",
                      },
                    }),
                    _vm._v(" "),
                    _vm.error_activation_date
                      ? _c("p", [
                          _c("small", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("general.warning_service2"))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isEdit
                  ? _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.renewal_date"),
                          required: "",
                        },
                      },
                      [
                        !_vm.formData.renewal_date_switch
                          ? _c("base-date-picker", {
                              ref: "RenewalDate",
                              attrs: {
                                "calendar-button": true,
                                "calendar-button-icon": "calendar",
                                disabled: _vm.isEdit,
                              },
                              model: {
                                value: _vm.formData.renewal_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "renewal_date", $$v)
                                },
                                expression: "formData.renewal_date",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isEdit
              ? _c("div", [
                  _c("div", { staticClass: "flex mt-5 mb-5" }, [
                    _c(
                      "div",
                      { staticClass: "relative w-12" },
                      [
                        _c("sw-switch", {
                          staticClass: "absolute",
                          staticStyle: { top: "-20px" },
                          on: { change: _vm.setDiscountParamsEdit },
                          model: {
                            value: _vm.formData.renewal_date_switch,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "renewal_date_switch", $$v)
                            },
                            expression: "formData.renewal_date_switch",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.warning_service3")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.formData.renewal_date_switch
                    ? _c(
                        "div",
                        [
                          _vm.isEdit
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    error: _vm.newEditRenewalDateError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("base-date-picker", {
                                    ref: "RenewalDateSwitch",
                                    attrs: {
                                      "calendar-button": true,
                                      "calendar-button-icon": "calendar",
                                      invalid:
                                        _vm.$v.formData.new_edit_renewal_date
                                          .$error,
                                    },
                                    model: {
                                      value: _vm.formData.new_edit_renewal_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "new_edit_renewal_date",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.new_edit_renewal_date",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("customers.tax_type") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.discount_type,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.select_a_tax_type"),
                    label: "name",
                    disabled: !_vm.tax_per_item_yes,
                  },
                  model: {
                    value: _vm.formData.tax_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "tax_type", $$v)
                    },
                    expression: "formData.tax_type",
                  },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "mt-2 ml-1 text-sm leading-snug text-gray-500",
                    staticStyle: { "max-width": "900px" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.warning_service")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4 relative",
                attrs: { label: _vm.$t("customers.address") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.addressOptions,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t("customers.address"),
                    label: "name",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "flex items-center" }, [
                            option.option.state
                              ? _c("div", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    _vm._s(
                                      option.option.state.name +
                                        " - " +
                                        option.option.address_street_1
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "flex items-center" }, [
                            option.option.state
                              ? _c("div", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    _vm._s(
                                      option.option.state.name +
                                        " - " +
                                        option.option.address_street_1
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.formData.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "address", $$v)
                    },
                    expression: "formData.address",
                  },
                }),
                _vm._v(" "),
                _vm.formData.address && _vm.formData.address.state
                  ? _c("x-circle-icon", {
                      staticClass: "h-5 absolute text-gray-400 cursor-pointer",
                      staticStyle: { top: "42px", right: "28px" },
                      on: {
                        click: function ($event) {
                          _vm.formData.address = {}
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    on: { change: _vm.setDiscountParams },
                    model: {
                      value: _vm.formData.allow_discount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "allow_discount", $$v)
                      },
                      expression: "formData.allow_discount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("customers.allow_discount")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.formData.allow_discount
              ? _c(
                  "div",
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$tc("customers.select_discount", 2),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.discount_type,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("customers.select_a_discount"),
                            label: "name",
                            disabled: true,
                          },
                          model: {
                            value: _vm.formData.discount_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount_type", $$v)
                            },
                            expression: "formData.discount_type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          label: _vm.$t("customers.discount_term_type"),
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.discount_term_type,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "customers.select_a_discount_term"
                            ),
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.discount_term_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount_term_type", $$v)
                            },
                            expression: "formData.discount_term_type",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.discountBetweenDates
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "relative grid grid-flow-col grid-rows",
                          },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  label: _vm.$t("general.from"),
                                  error: _vm.starDateError,
                                },
                              },
                              [
                                _c("base-date-picker", {
                                  ref: "dateFrom",
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.discount_start_date
                                        .$error,
                                    "calendar-button": true,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_start_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_start_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_start_date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "hidden w-8 h-0 ml-8 border border-gray-400 border-solid xl:block",
                              staticStyle: { "margin-top": "3.5rem" },
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  label: _vm.$t("general.to"),
                                  error: _vm.endDateError,
                                },
                              },
                              [
                                _c("base-date-picker", {
                                  ref: "dateTo",
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.discount_end_date.$error,
                                    "calendar-button": true,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.formData.discount_end_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_end_date",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_end_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.discountBetweenDates
                      ? _c(
                          "sw-input-group",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              label: _vm.$t("customers.time_unit_number"),
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex", attrs: { role: "group" } },
                              [
                                _c("sw-input", {
                                  staticClass:
                                    "border-r-0 rounded-tr-sm rounded-br-sm",
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.discount_time_units
                                        .$error,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.discount_time_units.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.discount_time_units,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_time_units",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_time_units",
                                  },
                                }),
                                _vm._v(" "),
                                _c("sw-select", {
                                  staticStyle: { "margin-left": "1em" },
                                  attrs: {
                                    options: _vm.discount_term,
                                    searchable: true,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "customers.select_a_term"
                                    ),
                                    label: "name",
                                  },
                                  model: {
                                    value: _vm.formData.discount_term,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "discount_term",
                                        $$v
                                      )
                                    },
                                    expression: "formData.discount_term",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex my-8 mb-4" }, [
              _c(
                "div",
                { staticClass: "relative w-12" },
                [
                  _c("sw-switch", {
                    staticClass: "absolute",
                    staticStyle: { top: "-20px" },
                    model: {
                      value: _vm.formData.service_auto_suspension,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "service_auto_suspension", $$v)
                      },
                      expression: "formData.service_auto_suspension",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "p-0 mb-1 text-base leading-snug text-black box-title",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("services.auto_suspension")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mt-4 pull-right",
                attrs: { loading: _vm.isLoading, variant: "primary" },
                on: { click: _vm.next },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("general.continue")) +
                    "\n        "
                ),
                _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }