var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoadedData && Object.keys(this.loggedInCustomer).length
      ? _c(
          "div",
          {
            staticClass: "mb-6 p-4 bg-blue-100 rounded relative",
            attrs: { role: "alert" },
          },
          [
            _c("p", [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$t("customer_profile.welcome", {
                      user: _vm.getUserName,
                    })
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("customer_profile.welcome_detail", {
                    balance: _vm.getBalanceDue,
                    credit: _vm.getCredit,
                  })
                ),
              },
            }),
            _vm._v(" "),
            _vm.getMakePayment == "YES"
              ? _c(
                  "sw-button",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      "tag-name": "router-link",
                      to: `/customer/payments/invoices`,
                      size: "md",
                      variant: "info",
                    },
                  },
                  [
                    _c("currency-dollar-icon", {
                      staticClass: "h-5 mr-1 -ml-2 font-bold",
                    }),
                    _vm._v("\n      Make Payment\n    "),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getAddcredit == "YES"
              ? _c(
                  "sw-button",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      "tag-name": "router-link",
                      to: `/customer/payments/credit`,
                      size: "md",
                      variant: "info",
                    },
                  },
                  [
                    _c("currency-dollar-icon", {
                      staticClass: "h-5 mr-1 -ml-2 font-bold",
                    }),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("customers.add_credit")) +
                        "\n    "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid gap-3 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8" },
      [
        _vm.showServices
          ? _c(
              "div",
              {
                staticClass:
                  "relative flex justify-between p-2 bg-white rounded shadow lg:col-span-3 xl:p-4",
                attrs: { slot: "item-title" },
                slot: "item-title",
              },
              [
                _c("div", [
                  true
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getCountServices) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [_vm._v("\n          Services\n        ")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center text-yellow-200" },
                  [_c("star-icon", { staticClass: "w-9 h-9 xl:w-12 xl:h-12" })],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showInvoices
          ? _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: { slot: "item-title", to: "/customer/invoices" },
                slot: "item-title",
              },
              [
                _c("div", [
                  true
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getCountInvoices) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("dashboard.cards.invoices")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("invoice-icon", {
                      staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showQuotes
          ? _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: { slot: "item-title", to: "/customer/estimates" },
                slot: "item-title",
              },
              [
                _c("div", [
                  true
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getCountEstimates) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("dashboard.cards.estimates")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center" },
                  [
                    _c("estimate-icon", {
                      staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showTickets
          ? _c(
              "router-link",
              {
                staticClass:
                  "relative flex justify-between p-2 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
                attrs: { slot: "item-title", to: "/customer/tickets" },
                slot: "item-title",
              },
              [
                _c("div", [
                  true
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-sm font-semibold leading-tight text-black xl:text-2xl",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getCountTickets) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "block mt-1 text-sm leading-tight text-gray-500 xl:text-lg",
                    },
                    [_vm._v("\n          Tickets\n        ")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex items-center text-green-200" },
                  [
                    _c("ticket-icon", {
                      staticClass: "w-9 h-9 xl:w-12 xl:h-12",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }