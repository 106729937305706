export const BOOTSTRAP_TAX_GROUPS = 'BOOTSTRAP_TAX_GROUPS'
export const ADD_TAX_GROUP = 'ADD_TAX_GROUP'
export const UPDATE_TAX_GROUP = 'UPDATE_TAX_GROUP'
export const DELETE_TAX_GROUP = 'DELETE_TAX_GROUP'
export const DELETE_MULTIPLE_TAX_GROUPS = 'DELETE_MULTIPLE_TAX_GROUPS'
export const SET_SELECTED_TAX_GROUPS = 'SET_SELECTED_TAX_GROUPS'
export const SET_TOTAL_TAX_GROUPS = 'SET_TOTAL_TAX_GROUPS'
export const RESET_SELECTED_TAX_GROUP = 'RESET_SELECTED_TAX_GROUP'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_TAX_GROUP = 'SET_SELECTED_VIEW_TAX_GROUP'
export const SET_GROUP_TAXES = 'SET_GROUP_TAXES'