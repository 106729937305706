var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting-main-container backup" },
    [
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", [
                _c("h6", { staticClass: "sw-section-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("settings.disk.title", 1)) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "mt-2 text-sm leading-snug text-gray-500",
                    staticStyle: { "max-width": "680px" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.disk.description")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
                [
                  _vm.permissionModule.create
                    ? _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline", size: "lg" },
                          on: { click: _vm.openCreateDiskModal },
                        },
                        [
                          _c("plus-icon", {
                            staticClass: "w-6 h-6 mr-1 -ml-2",
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("settings.disk.new_disk")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              staticClass: "mt-0 mb-3",
              attrs: {
                variant: "gray",
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table tax-table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  label: _vm.$t("settings.disk.disk_name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.disk.disk_name"))),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mt-6" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  label: _vm.$t("settings.disk.filesystem_driver"),
                  show: "driver",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  label: _vm.$t("settings.disk.disk_type"),
                  show: "type",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  label: _vm.$t("settings.disk.is_default"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.disk.is_default"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-badge",
                          {
                            attrs: {
                              "bg-color": _vm.$utils.getBadgeStatusColor(
                                row.set_as_default ? "YES" : "NO"
                              ).bgColor,
                              color: _vm.$utils.getBadgeStatusColor(
                                row.set_as_default ? "YES" : "NO"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.set_as_default
                                    ? "Yes"
                                    : "No".replace("_", " ")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.disk.action"))),
                        ]),
                        _vm._v(" "),
                        _vm.isShowAction(row)
                          ? _c("sw-dropdown", [
                              _c(
                                "a",
                                {
                                  attrs: { slot: "activator", href: "#" },
                                  slot: "activator",
                                },
                                [_c("dot-icon")],
                                1
                              ),
                              _vm._v(" "),
                              _vm.permissionModule.update
                                ? _c(
                                    "div",
                                    [
                                      !row.set_as_default
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setDefaultDiskData(
                                                    row.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("check-circle-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "settings.disk.set_default_disk"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.type !== "SYSTEM"
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditDiskModal(
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("pencil-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.permissionModule.delete
                                ? _c(
                                    "div",
                                    [
                                      row.type !== "SYSTEM" &&
                                      !row.set_as_default
                                        ? _c(
                                            "sw-dropdown-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeDisk(row.id)
                                                },
                                              },
                                            },
                                            [
                                              _c("trash-icon", {
                                                staticClass:
                                                  "h-5 mr-3 text-gray-600",
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-4" }),
          _vm._v(" "),
          _vm.permissionModule.update
            ? _c("div", [
                _c(
                  "h3",
                  { staticClass: "mb-5 text-lg font-medium text-black" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("settings.disk.disk_settings")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-18px" },
                        on: { change: _vm.setDiskSettings },
                        model: {
                          value: _vm.save_pdf_to_disk,
                          callback: function ($$v) {
                            _vm.save_pdf_to_disk = $$v
                          },
                          expression: "save_pdf_to_disk",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 mb-1 text-base leading-snug text-black",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("settings.disk.save_pdf_to_disk")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("settings.disk.disk_setting_description")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }