var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c("div", [
                  _c("h6", { staticClass: "sw-section-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("settings.notification.title")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text-sm leading-snug text-gray-500",
                      staticStyle: { "max-width": "680px" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("settings.notification.description")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-shrink-0 ml-4 justify-end" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3 hidden xl:block" },
                    [
                      _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline" },
                          on: { click: _vm.toggleSideBar },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tickets.departaments.menu")) +
                              "\n              "
                          ),
                          _c(_vm.listIcon, {
                            tag: "component",
                            staticClass: "w-4 h-4 ml-2 -mr-1",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveEmail()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "grid-cols-2 col-span-1" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "my-2",
                      attrs: {
                        label: _vm.$t("settings.notification.email"),
                        error: _vm.notificationEmailError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.notification_email.$error,
                          placeholder: _vm.$tc(
                            "settings.notification.please_enter_email"
                          ),
                          type: "text",
                          name: "notification_email",
                          icon: "envelope",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.notification_email.$touch()
                          },
                        },
                        model: {
                          value: _vm.notification_email,
                          callback: function ($$v) {
                            _vm.notification_email =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "notification_email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.permissionModule.update
                    ? _c(
                        "sw-button",
                        {
                          staticClass: "my-6",
                          attrs: {
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tc("settings.notification.save")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-1 mb-6" }),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  on: { change: _vm.setInvoiceViewd },
                  model: {
                    value: _vm.notify_invoice_viewed,
                    callback: function ($$v) {
                      _vm.notify_invoice_viewed = $$v
                    },
                    expression: "notify_invoice_viewed",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.notification.invoice_viewed")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
                  staticStyle: { "max-width": "480px" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.notification.invoice_viewed_desc")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-2" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  on: { change: _vm.setEstimateViewd },
                  model: {
                    value: _vm.notify_estimate_viewed,
                    callback: function ($$v) {
                      _vm.notify_estimate_viewed = $$v
                    },
                    expression: "notify_estimate_viewed",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.notification.estimate_viewed")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
                  staticStyle: { "max-width": "480px" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.notification.estimate_viewed_desc")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "mt-6",
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updateCustomerSetting.apply(null, arguments)
                },
              },
            },
            [
              _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
              _vm._v(" "),
              _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                _c("h6", { staticClass: "col-span-5 sw-section-title" }, [
                  _vm._v(
                    "\n                  " +
                      _vm._s(_vm.$t("customers.notices")) +
                      "\n              "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4",
                  },
                  [
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value:
                                _vm.notices
                                  .invoice_notices_settings_send_cancellation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.notices,
                                  "invoice_notices_settings_send_cancellation",
                                  $$v
                                )
                              },
                              expression:
                                "notices.invoice_notices_settings_send_cancellation",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(
                                  _vm.$t("customers.send_cancellation_notice")
                                ) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value:
                                _vm.notices
                                  .invoice_notices_settings_send_payment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.notices,
                                  "invoice_notices_settings_send_payment",
                                  $$v
                                )
                              },
                              expression:
                                "notices.invoice_notices_settings_send_payment",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(
                                  _vm.$t("customers.send_payment_notices")
                                ) +
                                "\n                          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 m-0 text-xs leading-4 text-gray-500",
                            staticStyle: { "max-width": "480px" },
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(
                                  _vm.$t(
                                    "customers.send_payment_notices_detail"
                                  )
                                ) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("sw-input-group", { staticClass: "md:col-span-3" }, [
                      _c(
                        "div",
                        { staticClass: "flex", attrs: { role: "group" } },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticStyle: { width: "40%" },
                              attrs: {
                                label: _vm.$t("customers.first_notice"),
                                error: _vm.notificationNotice1Error,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.notices
                                          .invoice_notices_settings_notice_1
                                          .$error,
                                      type: "number",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.notices.invoice_notices_settings_notice_1.$touch()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_1",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(
                                          _vm.$t("settings.notification.days")
                                        ) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                label: _vm.$t("customers.invoice_due_date"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      value: _vm.$t("customers.before"),
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_1_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_1_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_1_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.before")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-radio", {
                                    staticClass: "ml-4",
                                    attrs: { value: _vm.$t("customers.after") },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_1_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_1_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_1_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.after")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("sw-input-group", { staticClass: "md:col-span-3" }, [
                      _c(
                        "div",
                        { staticClass: "flex", attrs: { role: "group" } },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticStyle: { width: "40%" },
                              attrs: {
                                label: _vm.$t("customers.second_notice"),
                                error: _vm.notificationNotice2Error,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.notices
                                          .invoice_notices_settings_notice_2
                                          .$error,
                                      type: "number",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.notices.invoice_notices_settings_notice_2.$touch()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_2",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("customers.days")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                label: _vm.$t("customers.invoice_due_date"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      value: _vm.$t("customers.before"),
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_2_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_2_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_2_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.before")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-radio", {
                                    staticClass: "ml-4",
                                    attrs: { value: _vm.$t("customers.after") },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_2_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_2_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_2_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.after")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("sw-input-group", { staticClass: "md:col-span-3" }, [
                      _c(
                        "div",
                        { staticClass: "flex", attrs: { role: "group" } },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticStyle: { width: "40%" },
                              attrs: {
                                label: _vm.$t("customers.third_notice"),
                                error: _vm.notificationNotice3Error,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.notices
                                          .invoice_notices_settings_notice_3
                                          .$error,
                                      type: "number",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.notices.invoice_notices_settings_notice_3.$touch()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_3,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_3",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_3",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("customers.days")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                label: _vm.$t("customers.invoice_due_date"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-radio", {
                                    attrs: {
                                      value: _vm.$t("customers.before"),
                                    },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_3_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_3_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_3_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.before")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("sw-radio", {
                                    staticClass: "ml-4",
                                    attrs: { value: _vm.$t("customers.after") },
                                    model: {
                                      value:
                                        _vm.notices
                                          .invoice_notices_settings_notice_3_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notices,
                                          "invoice_notices_settings_notice_3_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notices.invoice_notices_settings_notice_3_type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-2 pt-2" }, [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(_vm.$t("customers.after")) +
                                        "\n                                  "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t("customers.auto_debit_pending_notice"),
                          error: _vm.notificationNotice4Error,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid:
                                  _vm.$v.notices
                                    .invoice_notices_settings_auto_debit_pending
                                    .$error,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.notices.invoice_notices_settings_auto_debit_pending.$touch()
                                },
                              },
                              model: {
                                value:
                                  _vm.notices
                                    .invoice_notices_settings_auto_debit_pending,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.notices,
                                    "invoice_notices_settings_auto_debit_pending",
                                    $$v
                                  )
                                },
                                expression:
                                  "notices.invoice_notices_settings_auto_debit_pending",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-2 pt-2" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("customers.days")) +
                                  "\n                          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        loading: _vm.isLoadingInvoice,
                        disabled: _vm.isLoadingInvoice,
                        variant: "primary",
                        type: "submit",
                      },
                    },
                    [
                      !_vm.isLoadingInvoice
                        ? _c("save-icon", { staticClass: "mr-2" })
                        : _vm._e(),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("settings.customization.save")) +
                          "\n          "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "mt-6 mb-4" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitConfigData.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "grid gap-4 mb-8" }, [
                _c(
                  "h6",
                  { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                  [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.$t("customers.invoice_charge_options_general")
                        ) +
                        "\n              "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                  },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t(
                            "customers.invoice_days_before_renewal"
                          ),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData.invoice_days_before_renewal
                                    .$error,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.invoice_days_before_renewal.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.invoice_days_before_renewal,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "invoice_days_before_renewal",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.invoice_days_before_renewal",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-2 pt-2" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("customers.days")) +
                                  "\n                          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t(
                            "customers.auto_debit_days_before_due_date"
                          ),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData.auto_debit_days_before_due
                                    .$error,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.auto_debit_days_before_due.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.auto_debit_days_before_due,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "auto_debit_days_before_due",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.auto_debit_days_before_due",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-2 pt-2" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("customers.days")) +
                                  "\n                          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t(
                            "customers.suspend_services_days_after_due"
                          ),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData
                                    .suspend_services_days_after_due.$error,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.suspend_services_days_after_due.$touch()
                                },
                              },
                              model: {
                                value:
                                  _vm.formData.suspend_services_days_after_due,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "suspend_services_days_after_due",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.suspend_services_days_after_due",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-2 pt-2" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("customers.days")) +
                                  "\n                          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "md:col-span-3",
                        attrs: {
                          label: _vm.$t("customers.auto_debit_attempts"),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex", attrs: { role: "group" } },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData.auto_debit_attempts.$error,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.auto_debit_attempts.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.auto_debit_attempts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "auto_debit_attempts",
                                    $$v
                                  )
                                },
                                expression: "formData.auto_debit_attempts",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value: _vm.formData.enable_auto_debit,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "enable_auto_debit", $$v)
                              },
                              expression: "formData.enable_auto_debit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.$t("customers.enable_auto_debit")) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value: _vm.formData.invoice_suspended_services,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "invoice_suspended_services",
                                  $$v
                                )
                              },
                              expression: "formData.invoice_suspended_services",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(
                                  _vm.$t("customers.invoice_suspended_services")
                                ) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value: _vm.formData.cancel_services,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "cancel_services", $$v)
                              },
                              expression: "formData.cancel_services",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.$t("customers.cancel_services")) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-checkbox", {
                            staticClass: "absolute",
                            on: { change: function ($event) {} },
                            model: {
                              value: _vm.formData.auto_apply_credits,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "auto_apply_credits",
                                  $$v
                                )
                              },
                              expression: "formData.auto_apply_credits",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black box-title",
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.$t("customers.auto_apply_credits")) +
                                "\n                          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        loading: _vm.isLoadingGeneralCustomer,
                        disabled: _vm.isLoadingGeneralCustomer,
                        variant: "primary",
                        type: "submit",
                      },
                    },
                    [
                      !_vm.isLoadingGeneralCustomer
                        ? _c("save-icon", { staticClass: "mr-2" })
                        : _vm._e(),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("settings.customization.save")) +
                          "\n          "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }