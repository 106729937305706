var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateSMSSetting.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-input-group",
          {
            staticClass: "md:col-span- mt-2",
            attrs: { label: _vm.$t("settings.customization.phonefrom") },
          },
          [
            _c("sw-input", {
              staticClass: "input-expand",
              staticStyle: { "max-width": "30%" },
              attrs: { type: "text", name: "phone" },
              model: {
                value: _vm.phoneFrom,
                callback: function ($$v) {
                  _vm.phoneFrom = $$v
                },
                expression: "phoneFrom",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: {
              label: _vm.$t("settings.customization.default_estimate_sms_body"),
            },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.mailFields },
              model: {
                value: _vm.default_estimate_sms_body,
                callback: function ($$v) {
                  _vm.default_estimate_sms_body = $$v
                },
                expression: "default_estimate_sms_body",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: {
              label: _vm.$t("settings.customization.default_invoice_sms_body"),
            },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.default_invoice_sms_body,
                callback: function ($$v) {
                  _vm.default_invoice_sms_body = $$v
                },
                expression: "default_invoice_sms_body",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: {
              label: _vm.$t("settings.customization.default_lead_sms_body"),
            },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.LeadMailFields },
              model: {
                value: _vm.default_lead_sms_body,
                callback: function ($$v) {
                  _vm.default_lead_sms_body = $$v
                },
                expression: "default_lead_sms_body",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4",
                attrs: { variant: "primary", type: "submit" },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }