var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("general.home"),
                  to: "/admin/dashboard",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("packages.package_name", 2),
                  to: "/admin/packages",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("packages.view_package"),
                  to: "#",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/packages`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.permissionModule.update
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/packages/${_vm.$route.params.id}/edit`,
                        variant: "primary-outline",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.edit")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permissionModule.delete
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: { slot: "activator", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.removePackage(_vm.$route.params.id)
                        },
                      },
                      slot: "activator",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-card",
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-5 gap-4 mb-8" },
            [
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("packages.basic")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("sw-divider", { staticClass: "col-span-12" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-3",
                      attrs: { label: _vm.$t("packages.name_package") },
                    },
                    [
                      _c("div", [
                        _c("p", {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                          domProps: { textContent: _vm._s(_vm.formData.name) },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-2",
                      attrs: { label: _vm.$t("corePbx.packages.type") },
                    },
                    [
                      _c("div", [
                        _vm.formData.status_payment.text == "Postpaid"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("packages.item.postpaid")) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.status_payment.text == "Prepaid"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-sm font-bold leading-5 text-black non-italic",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("packages.item.prepaid")) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-2",
                      attrs: { label: _vm.$t("packages.status") },
                    },
                    [
                      _c("div", [
                        _vm.formData.status.text == "Active"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "COMPLETED"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.active")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.status.text == "Inactive"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    staticClass: "px-3 py-1",
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).bgColor,
                                      color:
                                        _vm.$utils.getBadgeStatusColor(
                                          "OVERDUE"
                                        ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.inactive")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-divider", {
                    staticClass: "my-5 col-span-12 opacity-0",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "md:col-span-2",
                      attrs: { label: _vm.$t("customers.tax_type") },
                    },
                    [
                      _c("div", [
                        _c("p", {
                          staticClass:
                            "text-sm font-bold leading-5 text-black non-italic",
                          domProps: {
                            textContent: _vm._s(_vm.formData.apply_tax_type),
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "col-span-12 my-5" }),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
            _c(
              "h6",
              { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("packages.title_group")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6 mt-1",
              },
              [
                _c(
                  "ul",
                  { staticClass: "list-disc" },
                  _vm._l(_vm.groupLeft, function (item) {
                    return _c("div", { key: item.id }, [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "font-bold",
                              staticStyle: { cursor: "pointer" },
                              attrs: { to: `/admin/groups/${item.id}/view` },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "col-span-12 my-5" }),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
            _c(
              "h6",
              { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("packages.title_discount")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
              },
              [
                _c("div", { staticClass: "col-span-3" }, [
                  _c("div", { staticClass: "flex mt-3 mb-4" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("packages.apply_general_discount")
                              ) +
                              ":\n              "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-1 font-bold" }, [
                      _vm.checkonDiscounts
                        ? _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("packages.discount_enabled")) +
                                "\n              "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("packages.discount_disabled"))
                            ),
                          ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-3" }, [
                  _c("div", { staticClass: "flex mt-3 mb-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "relative",
                        staticStyle: { width: "8em" },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("packages.title_discounts"))
                          ),
                          _c("br"),
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.formData.discounts.text) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "relative",
                        staticStyle: { width: "8em", "margin-left": "30px" },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("packages.value_discount")) +
                              ":"
                          ),
                          _c("br"),
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.value_discount) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "col-span-12 my-5" }),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
            _c(
              "h6",
              { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("packages.taxes")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
              },
              [
                _c(
                  "sw-table-component",
                  {
                    ref: "table",
                    staticClass: "col-span-12",
                    attrs: {
                      "show-filter": false,
                      data: _vm.formData.taxes,
                      "table-class": "table",
                      variant: "gray",
                    },
                  },
                  [
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        label: _vm.$t("settings.tax_types.tax_name"),
                        show: "name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.tax_name"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "mt-6" }, [
                                _vm._v(_vm._s(row.name)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.compound_tax"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.tax_types.compound_tax")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.compound_tax ? "YES" : "NO"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        row.compound_tax
                                          ? "Yes"
                                          : "No".replace("_", " ")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("sw-table-column", {
                      attrs: {
                        sortable: true,
                        filterable: true,
                        label: _vm.$t("settings.tax_types.percent"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.tax_types.percent"))
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.percent) +
                                  " %\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-12" }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-5 gap-4 mb-8" },
            [
              _c("sw-divider", { staticClass: "col-span-12" }),
              _vm._v(" "),
              _c(
                "h6",
                { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("packages.packages_items")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                },
                [
                  _c("sw-input-group", {
                    staticClass: "md:col-span-3",
                    attrs: {
                      label: _vm.$t("packages.item_groups"),
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  undefined !== _vm.formData.item_groups &&
                  _vm.formData.item_groups.length > 0
                    ? _c("div", { staticClass: "col-span-12" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap justify-start" },
                          _vm._l(
                            _vm.formData.item_groups,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-indigo-100 bg-indigo-700 border border-indigo-700",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "text-xs text-base leading-none max-w-full flex-initial py-2 pl-2",
                                    domProps: {
                                      textContent: _vm._s(item.name),
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "table-responsive-item2" }, [
            _c("div", { staticClass: "tablemin" }, [
              _c(
                "table",
                { staticClass: "w-full text-center item-table" },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "40%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c("col", { staticStyle: { width: "15%" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "thead",
                    {
                      staticClass:
                        "bg-white border border-gray-200 border-solid",
                    },
                    [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$tc("items.item", 1)) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("packages.item.quantity")) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("packages.item.price")) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.discountPerItem === "YES"
                          ? _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("packages.item.discount")) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", { staticClass: "column-heading" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("packages.item.amount")) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.formData.items, function (item, index) {
                    return _c("package-item", {
                      key: item.id,
                      attrs: {
                        index: index,
                        "item-data": item,
                        currency: _vm.currency,
                        "package-items": _vm.formData.items,
                        "tax-per-item": _vm.taxPerItem,
                        "discount-per-item": _vm.discountPerItem,
                        isView: true,
                      },
                      on: {
                        remove: _vm.removeItem,
                        update: _vm.updateItem,
                        itemValidate: _vm.checkItemsData,
                      },
                    })
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.permissionModule.accessNormalServices
            ? _c(
                "div",
                {
                  staticClass:
                    "tabs mb-5 grid col-span-12 border-t-2 border-solid pt-6",
                  staticStyle: { "border-top-color": "#f9fbff" },
                },
                [
                  _c("div", { staticClass: "border-b tab" }, [
                    _c("div", { staticClass: "relative" }, [
                      _c("input", {
                        staticClass:
                          "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                        attrs: { type: "checkbox", id: "chck1" },
                      }),
                      _vm._v(" "),
                      _c(
                        "header",
                        {
                          staticClass:
                            "col-span-5 flex justify-between items-center py-3 cursor-pointer select-none tab-label",
                          attrs: { for: "chck1" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-gray-500 uppercase sw-section-title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("customers.services")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded-full border border-grey w-7 h-7 flex items-center justify-center test",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    "aria-hidden": "true",
                                    "data-reactid": "266",
                                    fill: "none",
                                    height: "24",
                                    stroke: "#606F7B",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    viewbox: "0 0 24 24",
                                    width: "24",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("polyline", {
                                    attrs: { points: "6 9 12 15 18 9" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tab-content-customer" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-grey-darkest" },
                            [
                              _c(
                                "sw-tabs",
                                {
                                  attrs: {
                                    "active-tab": _vm.activeTabServices,
                                  },
                                  on: { update: _vm.setStatusFilter },
                                },
                                [
                                  _c("sw-tab-item", {
                                    attrs: {
                                      title: _vm.$t("customers.active"),
                                      filter: "A",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-tab-item", {
                                    attrs: {
                                      title: _vm.$t("customers.pending"),
                                      filter: "P",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-tab-item", {
                                    attrs: {
                                      title: _vm.$t("customers.suspend"),
                                      filter: "S",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("sw-tab-item", {
                                    attrs: {
                                      title: _vm.$t("customers.cancelled"),
                                      filter: "C",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-table-component",
                            {
                              ref: "services_table",
                              attrs: {
                                "show-filter": false,
                                data: _vm.fetchPackagesServicesData,
                                "table-class": "table",
                              },
                            },
                            [
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("services.service_number"),
                                  show: "code",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "services.service_number"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm.permissionModule
                                            .readNormalServices
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-medium text-primary-500",
                                                  attrs: {
                                                    to: `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(row.code) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(row.code) +
                                                    "\n                  "
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3038074787
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$tc("packages.package", 1),
                                  show: "package.name",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.amount"),
                                  "sort-as": "total",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.amount"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  row.total,
                                                  row.user.currency
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4112549247
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.term"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.term"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(row.term)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  69880284
                                ),
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.activation_date"),
                                  "sort-as": "activation_date",
                                  show: "formattedActivationDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: true,
                                  label: _vm.$t("customers.renewal_date"),
                                  show: "formattedRenewalDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("sw-table-column", {
                                attrs: {
                                  sortable: false,
                                  filterable: false,
                                  "cell-class": "action-dropdown no-click",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (row) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.actions"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "sw-dropdown",
                                            [
                                              _c("dot-icon", {
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              }),
                                              _vm._v(" "),
                                              _vm.permissionModule
                                                .createInvoices
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "invoices.create",
                                                          query: {
                                                            from: "customer",
                                                            code: row.code,
                                                            customer_packages_id:
                                                              row.id,
                                                            customer_id:
                                                              row.customer_id,
                                                            package_id:
                                                              row.package_id,
                                                          },
                                                        },
                                                        "tag-name":
                                                          "router-link",
                                                      },
                                                    },
                                                    [
                                                      _c("calculator-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoices.new_invoice"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.permissionModule
                                                .readNormalServices
                                                ? _c(
                                                    "sw-dropdown-item",
                                                    {
                                                      attrs: {
                                                        to: `/admin/customers/${row.customer_id}/service/${row.id}/view`,
                                                        "tag-name":
                                                          "router-link",
                                                      },
                                                    },
                                                    [
                                                      _c("cog-icon", {
                                                        staticClass:
                                                          "h-5 mr-3 text-gray-600",
                                                      }),
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.manage"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2508433376
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }