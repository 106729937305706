var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      !_vm.initLoad
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitCustomerData.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-page-header",
                { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/dashboard",
                          title: _vm.$t("general.home"),
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/customers",
                          title: _vm.$tc("customers.customer", 2),
                        },
                      }),
                      _vm._v(" "),
                      _vm.$route.name === "customers.edit"
                        ? _c("sw-breadcrumb-item", {
                            attrs: {
                              to: "#",
                              title: _vm.$t("customers.edit_customer"),
                              active: "",
                            },
                          })
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              to: "#",
                              title: _vm.$t("customers.new_customer"),
                              active: "",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "hidden md:relative md:flex mr-2",
                          attrs: {
                            variant: "primary-outline",
                            type: "button",
                            size: "lg",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelForm()
                            },
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("x-circle-icon", {
                                staticClass: "w-6 h-6 mr-1 -ml-2",
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.cancel")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "hidden md:relative md:flex",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n\n          " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("customers.update_customer")
                                  : _vm.$t("customers.save_customer")
                              ) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "sw-card",
                { attrs: { variant: "customer-card" } },
                [
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-12 gap-4 mb-8" },
                    [
                      _c(
                        "h6",
                        { staticClass: "col-span-12 sw-section-title" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.basic_info")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.customer_number"),
                                error: _vm.customerNumError,
                                required: "",
                              },
                            },
                            [
                              _c(
                                "sw-input",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    prefix: `${_vm.customerPrefix} - `,
                                    invalid: _vm.$v.customerNumAttribute.$error,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.customerNumAttribute.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.customerNumAttribute,
                                    callback: function ($$v) {
                                      _vm.customerNumAttribute = $$v
                                    },
                                    expression: "customerNumAttribute",
                                  },
                                },
                                [
                                  _c("hashtag-icon", {
                                    staticClass: "h-4 ml-1 text-gray-500",
                                    attrs: { slot: "leftIcon" },
                                    slot: "leftIcon",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.customer_type"),
                              },
                            },
                            [
                              _c("sw-select", {
                                staticClass: "mt-2",
                                attrs: {
                                  options: _vm.types,
                                  searchable: true,
                                  "show-labels": false,
                                  label: "name",
                                },
                                on: { select: _vm.CutomerTypeSelected },
                                model: {
                                  value: _vm.customer_type_selected,
                                  callback: function ($$v) {
                                    _vm.customer_type_selected = $$v
                                  },
                                  expression: "customer_type_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t("tax_groups.status"),
                                error: _vm.statusCustomerError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  invalid:
                                    _vm.$v.formData.status_customer.$error,
                                  error: _vm.displayFirstNameError,
                                  "allow-empty": true,
                                  options: _vm.status,
                                  searchable: true,
                                  "show-labels": false,
                                  placeholder: _vm.$t("tax_groups.status"),
                                  label: "text",
                                },
                                model: {
                                  value: _vm.formData.status_customer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "status_customer",
                                      $$v
                                    )
                                  },
                                  expression: "formData.status_customer",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          this.isResidential && !this.isBusiness
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t("customers.first_name"),
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.formData.first_name.$error,
                                      focus: "",
                                      type: "text",
                                      name: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.first_name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.formData.first_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "first_name",
                                          $$v
                                        )
                                      },
                                      expression: "formData.first_name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.isResidential && !this.isBusiness
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t("customers.last_name"),
                                    error: _vm.displayLastNameError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid: _vm.$v.formData.last_name.$error,
                                      focus: "",
                                      type: "text",
                                      name: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.last_name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.formData.last_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "last_name", $$v)
                                      },
                                      expression: "formData.last_name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.isBusiness && !this.isResidential
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t("customers.display_name"),
                                    error: _vm.nameError,
                                    required: "",
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid: _vm.$v.formData.name.$error,
                                      focus: "",
                                      type: "text",
                                      name: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.formData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "name", $$v)
                                      },
                                      expression: "formData.name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.isBusiness && !this.isResidential
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t(
                                      "customers.primary_contact_name"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      label: _vm.$t("customers.contact_name"),
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.formData.contact_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "contact_name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.contact_name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("corePbx.packages.type"),
                                error: _vm.statusPaymentError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.status_payment,
                                  invalid:
                                    _vm.$v.formData.status_payment.$error,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": true,
                                  disabled: _vm.isServiceON,
                                  placeholder: _vm.$t("general.select_status"),
                                  label: "text",
                                  "track-by": "value",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.status_payment.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.status_payment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "status_payment",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.status_payment",
                                },
                              }),
                              _vm._v(" "),
                              _vm.isServiceON
                                ? _c(
                                    "p",
                                    { staticClass: "mt-1 text-danger text-sm" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "customers.customer_type_warning"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.email"),
                                error: _vm.emailError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  invalid: _vm.$v.formData.email.$error,
                                  type: "text",
                                  name: "email",
                                  placeholder: "example@example.com",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.email.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.email",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-xs mt-1 text-rose-600" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("customers.format_email")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: { label: _vm.$t("customers.phone") },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  type: "tel",
                                  placeholder: "1234567890",
                                  name: "phone",
                                  pattern: "^[0-9]+$",
                                },
                                model: {
                                  value: _vm.formData.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.phone",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-xs mt-1 text-rose-600" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("customers.format_phone_number")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.primary_currency"),
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.currencies,
                                  "custom-label": _vm.currencyNameWithCode,
                                  "allow-empty": false,
                                  searchable: true,
                                  "show-labels": false,
                                  placeholder: _vm.$t(
                                    "customers.select_currency"
                                  ),
                                  label: "name",
                                  "track-by": "id",
                                  disabled:
                                    _vm.billing_country == null ||
                                    _vm.billing_country.id == 231,
                                },
                                model: {
                                  value: _vm.currency,
                                  callback: function ($$v) {
                                    _vm.currency = $$v
                                  },
                                  expression: "currency",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.website"),
                                error: _vm.urlError,
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  placeholder: "http//::www.website.com",
                                  invalid: _vm.$v.formData.website.$error,
                                  type: "url",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.website.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.website,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "website", $$v)
                                  },
                                  expression: "formData.website",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-xs mt-1 text-rose-600" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("customers.format_web_site")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$tc("customers.primary_language"),
                                error: _vm.languageError,
                              },
                            },
                            [
                              _c("sw-select", {
                                staticClass: "mt-2",
                                class: {
                                  error: _vm.$v.formData.language.$error,
                                },
                                attrs: {
                                  options: _vm.getLanguages,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  placeholder: _vm.$tc(
                                    "settings.preferences.select_language"
                                  ),
                                  label: "name",
                                  "track-by": "code",
                                },
                                model: {
                                  value: _vm.formData.language,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "language", $$v)
                                  },
                                  expression: "formData.language",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.customization.modules.timezone"
                                ),
                                required: "",
                              },
                            },
                            [
                              _c("div", { staticClass: "flex flex-wrap" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-2 xl:mb-0 w-full xl:w-1/2",
                                  },
                                  [
                                    _c("sw-select", {
                                      staticClass: "xl:mr-2",
                                      attrs: {
                                        options: _vm.continentOptions,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        placeholder: _vm.$t(
                                          "settings.customization.modules.place_select_continent"
                                        ),
                                      },
                                      model: {
                                        value: _vm.continent,
                                        callback: function ($$v) {
                                          _vm.continent = $$v
                                        },
                                        expression: "continent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mt-2 xl:mt-0 w-full xl:w-1/2",
                                  },
                                  [
                                    _c("sw-select", {
                                      staticClass: "xl:ml-2",
                                      attrs: {
                                        options: _vm.timezonesOptiones,
                                        searchable: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        placeholder: _vm.$t(
                                          "settings.customization.modules.please_select_zone"
                                        ),
                                        label: "label",
                                        invalid:
                                          _vm.$v.formData.timezone.$error,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.timezone.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.timezone,
                                        callback: function ($$v) {
                                          _vm.timezone = $$v
                                        },
                                        expression: "timezone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-divider", {
                        staticClass: "mb-5 md:mb-8 col-span-12",
                      }),
                      _vm._v(" "),
                      _c(
                        "h6",
                        { staticClass: "col-span-12 sw-section-title ml-2" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("packages.tax")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3",
                              attrs: {
                                label: _vm.$t("customers.tax_id_vatin"),
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: { type: "text", name: "tax_id_vatin" },
                                model: {
                                  value: _vm.formData.billing.tax_id_vatin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.billing,
                                      "tax_id_vatin",
                                      $$v
                                    )
                                  },
                                  expression: "formData.billing.tax_id_vatin",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.setting.retention_active
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$tc("customers.type_vat_regime"),
                                    error: _vm.typeVatRegimeError,
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    staticClass: "mt-2",
                                    class: {
                                      error:
                                        _vm.$v.formData.type_vat_regime.$error,
                                    },
                                    attrs: {
                                      options: _vm.type_vat_regime_options,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": false,
                                      placeholder: _vm.$tc(
                                        "customers.type_vat_regime"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    on: {
                                      input: _vm.changeFiscalResponsibility,
                                    },
                                    model: {
                                      value: _vm.type_vat_regime,
                                      callback: function ($$v) {
                                        _vm.type_vat_regime = $$v
                                      },
                                      expression: "type_vat_regime",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.setting.retention_active
                            ? _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$tc(
                                      "customers.responsabilidad_fiscal"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex grid md:grid-cols-2" },
                                    [
                                      _c("sw-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: _vm.$t(
                                            "customers.great_contributor"
                                          ),
                                          disabled:
                                            _vm.formData
                                              .not_applicable_others ||
                                            this.type_vat_regime.value == "0",
                                        },
                                        on: {
                                          change:
                                            _vm.changeFiscalResponsibility,
                                        },
                                        model: {
                                          value: _vm.formData.great_contributor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "great_contributor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.great_contributor",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "mt-3 sm:ml-4" },
                                        [_vm._v(" 0 - 13 ")]
                                      ),
                                      _vm._v(" "),
                                      _c("sw-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: _vm.$t(
                                            "customers.self_retaining"
                                          ),
                                          disabled:
                                            _vm.formData
                                              .not_applicable_others ||
                                            this.type_vat_regime.value == "0",
                                        },
                                        on: {
                                          change:
                                            _vm.changeFiscalResponsibility,
                                        },
                                        model: {
                                          value: _vm.formData.self_retaining,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "self_retaining",
                                              $$v
                                            )
                                          },
                                          expression: "formData.self_retaining",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "mt-3 sm:ml-4" },
                                        [_vm._v(" 0 - 15 ")]
                                      ),
                                      _vm._v(" "),
                                      _c("sw-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: _vm.$t(
                                            "customers.vat_withholding_agent"
                                          ),
                                          disabled:
                                            _vm.formData
                                              .not_applicable_others ||
                                            this.type_vat_regime.value == "0",
                                        },
                                        on: {
                                          change:
                                            _vm.changeFiscalResponsibility,
                                        },
                                        model: {
                                          value:
                                            _vm.formData.vat_withholding_agent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "vat_withholding_agent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.vat_withholding_agent",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "mt-3 sm:ml-4" },
                                        [_vm._v(" 0 - 23 ")]
                                      ),
                                      _vm._v(" "),
                                      _c("sw-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: _vm.$t(
                                            "customers.simple_tax_regime"
                                          ),
                                          disabled:
                                            _vm.formData
                                              .not_applicable_others ||
                                            this.type_vat_regime.value == "0",
                                        },
                                        on: {
                                          change:
                                            _vm.changeFiscalResponsibility,
                                        },
                                        model: {
                                          value: _vm.formData.simple_tax_regime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "simple_tax_regime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.simple_tax_regime",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "mt-3 sm:ml-4" },
                                        [_vm._v(" 0 - 47 ")]
                                      ),
                                      _vm._v(" "),
                                      _c("sw-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: _vm.$t(
                                            "customers.not_applicable_others"
                                          ),
                                        },
                                        on: {
                                          change:
                                            _vm.changeFiscalResponsibility,
                                        },
                                        model: {
                                          value:
                                            _vm.formData.not_applicable_others,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "not_applicable_others",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.not_applicable_others",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "mt-3 sm:ml-4" },
                                        [_vm._v(" R - 99 - PN ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt-5" }, [
                                    _c("label", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$tc(
                                              "customers.responsabilidad_fiscal_desc"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-divider", {
                        staticClass: "mb-5 md:mb-8 col-span-12",
                      }),
                      _vm._v(" "),
                      _c(
                        "h6",
                        { staticClass: "col-span-12 sw-section-title ml-2" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("packages.options")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid col-span-12 gap-y-4 gap-x-4 md:grid-cols-6",
                        },
                        [
                          _vm.isAvalaraAvailable
                            ? _c("div", { staticClass: "flex md:col-span-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "relative w-12" },
                                  [
                                    _c("sw-switch", {
                                      staticClass: "absolute",
                                      staticStyle: { top: "-20px" },
                                      on: { change: _vm.slideChange },
                                      model: {
                                        value: _vm.formData.avalara_bool,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "avalara_bool",
                                            $$v
                                          )
                                        },
                                        expression: "formData.avalara_bool",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("customers.avalara_field")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex md:col-span-3" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  on: { change: _vm.prepaidOptionChange },
                                  model: {
                                    value: _vm.prepaid_option_status,
                                    callback: function ($$v) {
                                      _vm.prepaid_option_status = $$v
                                    },
                                    expression: "prepaid_option_status",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("customers.prepaid_options")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex md:col-span-3" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.formData.auto_suspension,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "auto_suspension",
                                        $$v
                                      )
                                    },
                                    expression: "formData.auto_suspension",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("customers.auto_suspension")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex md:col-span-3" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  on: { change: _vm.authenticationChange },
                                  model: {
                                    value: _vm.authentication_status,
                                    callback: function ($$v) {
                                      _vm.authentication_status = $$v
                                    },
                                    expression: "authentication_status",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("customers.authentication")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex md:col-span-3" }, [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.add_shipping_addre,
                                    callback: function ($$v) {
                                      _vm.add_shipping_addre = $$v
                                    },
                                    expression: "add_shipping_addre",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "customers.activate_shipping_address"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.isEdit == false
                            ? _c("div", { staticClass: "flex md:col-span-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "relative w-12" },
                                  [
                                    _c("sw-switch", {
                                      staticClass: "absolute",
                                      staticStyle: { top: "-20px" },
                                      model: {
                                        value: _vm.send_after_created_email,
                                        callback: function ($$v) {
                                          _vm.send_after_created_email = $$v
                                        },
                                        expression: "send_after_created_email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "p-0 mb-1 text-base leading-snug text-black box-title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "customers.send_after_created_emai"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isAddAvalara
                    ? _c("sw-divider", {
                        staticClass: "mb-5 md:mb-8 col-span-12",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAddAvalara
                    ? _c(
                        "div",
                        { staticClass: "grid col-span-12 gap-4 mb-8" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "col-span-12 sw-section-title lg:col-span-1",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("customers.avalara_options")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-12 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            [
                              _vm.isAddAvalara
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "md:col-span-3",
                                      attrs: {
                                        label: _vm.$t("customers.avalara_type"),
                                        error: _vm.avalaraTypeSelectedError,
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.avalara_types,
                                          searchable: true,
                                          "show-labels": false,
                                          invalid:
                                            _vm.$v.formData
                                              .avalara_type_selected.$error,
                                          label: "name",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.formData.avalara_type_selected.$touch()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.avalara_type_selected,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "avalara_type_selected",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.avalara_type_selected",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t("customers.sale_type"),
                                    error: _vm.statusPaymentError,
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.sale_type,
                                      invalid: _vm.$v.formData.sale_type.$error,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "general.select_status"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.sale_type.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.formData.sale_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "sale_type",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.sale_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: { label: _vm.$t("avalara.life_line") },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.lflnOptions,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": false,
                                      placeholder: _vm.$t("avalara.life_line"),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.formData.lfln,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "lfln",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.lfln",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: _vm.$t("avalara.incorporated"),
                                  },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.incorporatedOptions,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": false,
                                      placeholder: _vm.$t(
                                        "avalara.incorporated"
                                      ),
                                      label: "text",
                                      "track-by": "value",
                                    },
                                    model: {
                                      value: _vm.incorporated,
                                      callback: function ($$v) {
                                        _vm.incorporated =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "incorporated",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "ml-2 text-xs leading-none text-primary-400",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("avalara.avalara_message")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: { label: _vm.$t("avalara.pcode") },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      type: "text",
                                      name: "pcode_formData",
                                      disabled:
                                        _vm.billing_country == null ||
                                        _vm.billing_country.id == 231,
                                    },
                                    model: {
                                      value: _vm.formData.billing.pcode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.billing,
                                          "pcode",
                                          $$v
                                        )
                                      },
                                      expression: "formData.billing.pcode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "div",
                                    { staticClass: "md:col-span-3" },
                                    [
                                      _c(
                                        "sw-button",
                                        {
                                          attrs: { variant: "primary-outline" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCategoryExemptions()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("avalara.add_exemptions")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPrepaidOption
                    ? _c("sw-divider", { staticClass: "mb-5 md:mb-8" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPrepaidOption
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                        [
                          _c(
                            "h6",
                            { staticClass: "col-span-12 sw-section-title" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("customers.prepaid_options")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex md:col-span-3 my-8 mb-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "relative w-12" },
                                    [
                                      _c("sw-switch", {
                                        staticClass: "absolute",
                                        staticStyle: { top: "-20px" },
                                        on: { change: _vm.autoDebitChange },
                                        model: {
                                          value: _vm.auto_debit_status,
                                          callback: function ($$v) {
                                            _vm.auto_debit_status = $$v
                                          },
                                          expression: "auto_debit_status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-4" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "p-0 mb-1 text-base leading-snug text-black box-title",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("customers.auto_debit")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    error: _vm.emailLowBalanceNotificationError,
                                    label: _vm.$t(
                                      "customers.email_low_balance_notification"
                                    ),
                                  },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      invalid:
                                        _vm.$v.formData
                                          .email_low_balance_notification
                                          .$error,
                                      type: "text",
                                      name: "email_low_balance_notification",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.email_low_balance_notification.$touch()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData
                                          .email_low_balance_notification,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "email_low_balance_notification",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.email_low_balance_notification",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.auto_debit_status
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "md:col-span-3",
                                      attrs: {
                                        label: _vm.$t(
                                          "customers.minimun_balance"
                                        ),
                                        error: _vm.minimunBalanceError,
                                      },
                                    },
                                    [
                                      _c("sw-input", {
                                        attrs: {
                                          invalid:
                                            _vm.$v.formData.minimun_balance
                                              .$error,
                                          name: "minimun_balance",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.formData.minimun_balance.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.formData.minimun_balance,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "minimun_balance",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.minimun_balance",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.auto_debit_status
                                ? _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "md:col-span-3",
                                      attrs: {
                                        error: _vm.autoReplenishAmountError,
                                        label: _vm.$t(
                                          "customers.auto_replenish_amount"
                                        ),
                                      },
                                    },
                                    [
                                      _c("sw-input", {
                                        attrs: {
                                          invalid:
                                            _vm.$v.formData
                                              .auto_replenish_amount.$error,
                                          type: "text",
                                          name: "auto_replenish_amount",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.formData.auto_replenish_amount.$touch()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.auto_replenish_amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "auto_replenish_amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.auto_replenish_amount",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAuthentication
                    ? _c("sw-divider", { staticClass: "mb-5 md:mb-8" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAuthentication
                    ? _c("div", { staticClass: "flex flex-wrap" }, [
                        _c("h6", { staticClass: "w-full" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("customers.authentication")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-full" },
                          [
                            _c("div", { staticClass: "flex mt-2 w-full" }, [
                              _c(
                                "div",
                                { staticClass: "relative w-12" },
                                [
                                  _c("sw-switch", {
                                    staticClass: "absolute",
                                    staticStyle: { top: "-20px" },
                                    model: {
                                      value: _vm.username_status,
                                      callback: function ($$v) {
                                        _vm.username_status = $$v
                                      },
                                      expression: "username_status",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-4" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 mb-1 text-base leading-snug text-black box-title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("customers.specify_username")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 m-0 text-xs leading-4 text-gray-500 texto-customer",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "customers.specify_username_info"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.isUsername
                              ? _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "w-full md:w-1/2",
                                    attrs: {
                                      label: _vm.$t("customers.username"),
                                      error: _vm.usernameError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        invalid:
                                          _vm.$v.formData.customer_username
                                            .$error,
                                        focus: "",
                                        type: "text",
                                        name: "name",
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                        },
                                        input: function ($event) {
                                          return _vm.$v.formData.customer_username.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.formData.customer_username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "customer_username",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.customer_username",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.userView
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "mt-1 text-sm",
                                            class: {
                                              "text-success": _vm.userValid,
                                              "text-danger": !_vm.userValid,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(_vm.userValidText) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isUsername
                              ? _c("sw-divider", {
                                  staticClass: "my-0 col-span-12 opacity-0",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w-full flex flex-wrap" },
                              [
                                _c(
                                  "sw-input-group",
                                  {
                                    staticClass: "w-full md:w-1/2 mt-3",
                                    attrs: {
                                      label: _vm.$t("customers.password"),
                                      error: _vm.passwordError,
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("sw-input", {
                                      attrs: {
                                        invalid:
                                          _vm.$v.formData.password.$error,
                                        focus: "",
                                        type: _vm.showPassword
                                          ? "text"
                                          : "password",
                                        name: "name",
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                        },
                                        input: function ($event) {
                                          return _vm.$v.formData.password.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "rightIcon",
                                            fn: function () {
                                              return [
                                                _vm.showPassword
                                                  ? _c("eye-off-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showPassword =
                                                            !_vm.showPassword
                                                        },
                                                      },
                                                    })
                                                  : _c("eye-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showPassword =
                                                            !_vm.showPassword
                                                        },
                                                      },
                                                    }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1006372100
                                      ),
                                      model: {
                                        value: _vm.formData.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "formData.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full md:w-1/2 flex items-end",
                                  },
                                  [
                                    _c(
                                      "sw-button",
                                      {
                                        staticClass: "md:ml-2",
                                        attrs: {
                                          variant: "primary-outline",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.generate()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "customers.generate_password"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "w-full md:w-1/2 my-3",
                                attrs: {
                                  label: _vm.$t("customers.confirm_password"),
                                  error: _vm.confirmPasswordError,
                                  required: "",
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.confirm_password.$error,
                                    focus: "",
                                    type: _vm.showPassword
                                      ? "text"
                                      : "password",
                                    name: "name",
                                  },
                                  on: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "space",
                                          32,
                                          $event.key,
                                          [" ", "Spacebar"]
                                        )
                                      )
                                        return null
                                      $event.preventDefault()
                                    },
                                    input: function ($event) {
                                      return _vm.$v.formData.confirm_password.$touch()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "rightIcon",
                                        fn: function () {
                                          return [
                                            _vm.showPassword
                                              ? _c("eye-off-icon", {
                                                  staticClass:
                                                    "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showPassword =
                                                        !_vm.showPassword
                                                    },
                                                  },
                                                })
                                              : _c("eye-icon", {
                                                  staticClass:
                                                    "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showPassword =
                                                        !_vm.showPassword
                                                    },
                                                  },
                                                }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1006372100
                                  ),
                                  model: {
                                    value: _vm.formData.confirm_password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "confirm_password",
                                        $$v
                                      )
                                    },
                                    expression: "formData.confirm_password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("sw-divider", {
                              staticClass: "my-0 col-span-12 opacity-0",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "w-full md:w-1/2 my-3",
                                attrs: {
                                  label: _vm.$t("customers.security_pin"),
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: { name: "security_pin", type: "text" },
                                  model: {
                                    value: _vm.formData.security_pin,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "security_pin",
                                        $$v
                                      )
                                    },
                                    expression: "formData.security_pin",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isEdit && _vm.setPass
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex w-full md:w-1/2 my-7 mb-4",
                                  },
                                  [
                                    _c(
                                      "sw-button",
                                      {
                                        attrs: {
                                          disabled: _vm.isLoading,
                                          loading: _vm.isLoading,
                                          variant: "primary-outline",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sendPass()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("customers.send_password")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "mb-5 md:mb-8 col-span-12" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid col-span-12 gap-4 mb-8" }, [
                    _c("h6", { staticClass: "col-span-12 sw-section-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.billing_info")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid col-span-12 gap-y-6 gap-x-4 md:grid-cols-6",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.address_1"),
                                  error: _vm.billAddress1Error,
                                  required: "",
                                },
                              },
                              [
                                _c("sw-textarea", {
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.billing.address_street_1
                                        .$error,
                                    placeholder: _vm.$t("general.street_1"),
                                    type: "text",
                                    name: "billing_street1",
                                    rows: "1",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.billing.address_street_1.$touch()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.formData.billing.address_street_1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "address_street_1",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "formData.billing.address_street_1",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.address_2"),
                                  error: _vm.billAddress2Error,
                                },
                              },
                              [
                                _c("sw-textarea", {
                                  attrs: {
                                    placeholder: _vm.$t("general.street_2"),
                                    type: "text",
                                    name: "billing_street2",
                                    rows: "1",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.billing.address_street_2.$touch()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.formData.billing.address_street_2,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "address_street_2",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "formData.billing.address_street_2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.city"),
                                  error: _vm.cityError,
                                  required: "",
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    invalid:
                                      _vm.$v.formData.billing.city.$error,
                                    name: "formData.billing.city",
                                    type: "text",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.billing.city.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.billing.city,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "city",
                                        $$v
                                      )
                                    },
                                    expression: "formData.billing.city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              { attrs: { label: _vm.$t("customers.county") } },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    name: "formData.county",
                                    type: "text",
                                    autocomplete: false,
                                  },
                                  model: {
                                    value: _vm.formData.billing.county,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "county",
                                        $$v
                                      )
                                    },
                                    expression: "formData.billing.county",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: {
                              error: _vm.stateIdError,
                              label: _vm.$t("customers.state"),
                              required: "",
                            },
                          },
                          [
                            _c("sw-select", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData.billing.state_id.$error,
                                options: _vm.billing_states,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                placeholder: _vm.$t("general.select_state"),
                                label: "name",
                                "track-by": "id",
                              },
                              model: {
                                value: _vm.billing_state,
                                callback: function ($$v) {
                                  _vm.billing_state = $$v
                                },
                                expression: "billing_state",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: {
                              error: _vm.countryIdError,
                              label: _vm.$t("customers.country"),
                              required: "",
                            },
                          },
                          [
                            _c("sw-select", {
                              attrs: {
                                invalid:
                                  _vm.$v.formData.billing.country_id.$error,
                                options: _vm.countries,
                                searchable: true,
                                "show-labels": false,
                                placeholder: _vm.$t("general.select_country"),
                                label: "name",
                                "track-by": "id",
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.countrySelected($event, "billing")
                                },
                              },
                              model: {
                                value: _vm.billing_country,
                                callback: function ($$v) {
                                  _vm.billing_country = $$v
                                },
                                expression: "billing_country",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.zip_code"),
                                  error: _vm.zipError,
                                  required: "",
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    invalid: _vm.$v.formData.billing.zip.$error,
                                    type: "text",
                                    name: "zip",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.formData.billing.zip.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.billing.zip,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "zip",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formData.billing.zip",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: {
                              label: _vm.$tc("customers.delivery_methods"),
                            },
                          },
                          [
                            _c("sw-select", {
                              staticClass: "mt-2",
                              attrs: {
                                options: _vm.delivery_methods,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": false,
                                placeholder: _vm.$tc("customers.select_method"),
                                label: "name",
                                "track-by": "id",
                              },
                              model: {
                                value: _vm.billing_delivery_method,
                                callback: function ($$v) {
                                  _vm.billing_delivery_method = $$v
                                },
                                expression: "billing_delivery_method",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex md:col-span-3 mt-8 mb-4" },
                          [
                            _c(
                              "div",
                              { staticClass: "relative w-12" },
                              [
                                _c("sw-switch", {
                                  staticClass: "absolute",
                                  staticStyle: { top: "-20px" },
                                  model: {
                                    value: _vm.formData.billing.payment_notices,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.billing,
                                        "payment_notices",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.billing.payment_notices",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "p-0 mb-1 text-base leading-snug text-black box-title",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("customers.payment_notices")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.formData.avalara_bool
                          ? _c(
                              "div",
                              { staticClass: "flex w-full md:w-1/2 my-2" },
                              [
                                _c(
                                  "sw-button",
                                  {
                                    attrs: {
                                      variant: "primary-outline",
                                      size: "lg",
                                      type: "button",
                                    },
                                    on: { click: _vm.checkBilling },
                                  },
                                  [
                                    !_vm.isLoading
                                      ? _c("check-icon", {
                                          staticClass: "mr-2 -ml-1",
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("customers.billing_validation")
                                        ) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "mb-5 md:mb-8 col-span-12" }),
                  _vm._v(" "),
                  _vm.add_shipping_addre
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-start mb-6 md:justify-end md:mb-0",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "p-1" },
                            [
                              _c(
                                "sw-button",
                                {
                                  ref: "sameAddress",
                                  staticClass: "h-8 px-3 py-1 mb-4",
                                  attrs: { variant: "primary", type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyAddress(true)
                                    },
                                  },
                                },
                                [
                                  _c("document-duplicate-icon", {
                                    staticClass: "h-4 mr-1 -ml-2",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-xs" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "customers.copy_billing_address"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.add_shipping_addre
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "col-span-12 sw-section-title lg:col-span-1",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("customers.shipping_address")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-12 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "md:col-span-3" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("customers.address"),
                                      },
                                    },
                                    [
                                      _c("sw-textarea", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("general.street_1"),
                                          type: "text",
                                          name: "street_1",
                                          rows: "1",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.formData.shipping.address_street_1.$touch()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.shipping
                                              .address_street_1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.shipping,
                                              "address_street_1",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "formData.shipping.address_street_1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$v.formData.shipping.address_street_1
                                    .$error
                                    ? _c("div", [
                                        !_vm.$v.formData.shipping
                                          .address_street_1.maxLength
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-sm text-danger",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "validation.address_maxlength"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "md:col-span-3" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("customers.address"),
                                        error: _vm.billAddress2Error,
                                      },
                                    },
                                    [
                                      _c("sw-textarea", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "customers.address_2"
                                          ),
                                          type: "text",
                                          name: "street2",
                                          rows: "1",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.formData.shipping.address_street_2.$touch()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.shipping
                                              .address_street_2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.shipping,
                                              "address_street_2",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "formData.shipping.address_street_2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$v.formData.shipping.address_street_2
                                    .$error
                                    ? _c("div", [
                                        !_vm.$v.formData.shipping
                                          .address_street_2.maxLength
                                          ? _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "validation.address_maxlength"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: { label: _vm.$t("customers.city") },
                                },
                                [
                                  _c("sw-input", {
                                    attrs: {
                                      name: "formData.shipping.city",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.formData.shipping.city,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.shipping,
                                          "city",
                                          $$v
                                        )
                                      },
                                      expression: "formData.shipping.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: { label: _vm.$t("customers.state") },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.shipping_states,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "general.select_state"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    model: {
                                      value: _vm.shipping_state,
                                      callback: function ($$v) {
                                        _vm.shipping_state = $$v
                                      },
                                      expression: "shipping_state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-input-group",
                                {
                                  staticClass: "md:col-span-3",
                                  attrs: { label: _vm.$t("customers.country") },
                                },
                                [
                                  _c("sw-select", {
                                    attrs: {
                                      options: _vm.countries,
                                      searchable: true,
                                      "show-labels": false,
                                      "allow-empty": true,
                                      placeholder: _vm.$t(
                                        "general.select_country"
                                      ),
                                      label: "name",
                                      "track-by": "id",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.countrySelected(
                                          $event,
                                          "shipping"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.shipping_country,
                                      callback: function ($$v) {
                                        _vm.shipping_country = $$v
                                      },
                                      expression: "shipping_country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "md:col-span-3" },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("customers.zip_code"),
                                      },
                                    },
                                    [
                                      _c("sw-input", {
                                        attrs: { type: "text", name: "zip" },
                                        model: {
                                          value: _vm.formData.shipping.zip,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.shipping,
                                              "zip",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formData.shipping.zip",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customFields.length > 0
                    ? _c("sw-divider", { staticClass: "mb-5 md:mb-8" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customFields.length > 0
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "col-span-5 sw-section-title lg:col-span-1",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("settings.custom_fields.title")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            _vm._l(_vm.customFields, function (field, index) {
                              return _c(
                                "sw-input-group",
                                {
                                  key: index,
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: field.label,
                                    required: field.is_required ? true : false,
                                  },
                                },
                                [
                                  _c(field.type + "Field", {
                                    tag: "component",
                                    attrs: {
                                      type: field.type.label,
                                      field: field,
                                      isEdit: _vm.isEdit,
                                      "invalid-fields": _vm.invalidFields,
                                    },
                                    on: { update: _vm.setCustomFieldValue },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex space-x-2 mb-8" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "mr-2 text-sm px-3 py-2",
                          attrs: {
                            variant: "primary-outline",
                            type: "button",
                            size: "sm",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelForm()
                            },
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("x-circle-icon", {
                                staticClass: "w-4 h-4 mr-1 -ml-2",
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.cancel")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "text-sm px-3 py-2",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "sm",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n\n          " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("customers.update_customer")
                                  : _vm.$t("customers.save_customer")
                              ) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }