var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-date-picker", {
        ref: "BaseDatepicker",
        attrs: {
          value: this.value,
          config: _vm.config,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          invalid: _vm.invalid,
          name: _vm.name,
          tabindex: _vm.tabindex,
        },
        on: { input: _vm.onDateChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }