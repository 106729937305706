<style scoped>
.scrollable-div3 {
  overflow-x: auto; /* Habilita el desplazamiento horizontal si el contenido excede el ancho del div */
  width: 100%; /* El div toma el 100% del ancho de su contenedor */
  -webkit-overflow-scrolling: touch; /* Permite un desplazamiento táctil fluido en iOS */
  min-height: 400px; 
}
.scrollable-div3 table {
  width: 100%; /* Opcional: asegura que la tabla se expanda para llenar el div */
  /* Tus estilos de tabla aquí */
}

.table-wrapperestper {
  min-width: 600px; /* Establece un ancho mínimo de 800 píxeles para la tabla */
}
</style>

<template>
  <base-page class="item-create">
    <base-loader v-if="isRequestOnGoing" :show-bg-overlay="true" />
    <sw-page-header :title="$t('users.user_permisions')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="/admin/dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="/admin/users" :title="$tc('users.user', 2)" />
        <sw-breadcrumb-item
          :to="'/admin/users/' + idUser + '/view'"
          :title="userData.name"
        />
        <sw-breadcrumb-item to="#" :title="$t('roles.permissions')" active />
      </sw-breadcrumb>
    </sw-page-header>
    <div class="grid gap-4 grid-cols-12">
      <div class="col-span-12 xl:col-span-4">
        <sw-card>
          <div class="grid grid-cols-2 gap-4 xl:grid-cols-1">
            <div class="mb-5">
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.name') }}
              </p>
              <p class="text-sm font-bold leading-5 text-black non-italic">
                {{ userData.name }}
              </p>
            </div>
            <div>
              <p
                class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
              >
                {{ $t('users.role') }}
              </p>
              <p class="text-sm font-bold leading-5 text-black non-italic">
                {{ userData.role2 }}
              </p>
            </div>
          </div>
        </sw-card>
      </div>
      <div class="col-span-12 xl:col-span-8">
        <sw-card>
          <base-loader v-if="isRequestOnGoing" />
          <div
            class="flex flex-col md:flex-row md:justify-between items-center mb-5 md:mb-0"
          >
            <h6 class="sw-section-title mb-5 md:mb-0">
              {{ $t('roles.permissions') }}
            </h6>
            <div
              class="flex flex-wrap justify-center md:justify-end w-full md:w-auto"
            >
              <sw-button
                type="button"
                @click="cancelButton"
                variant="primary"
                class="mr-2 mb-2 md:mb-0 w-full md:w-auto"
              >
                {{ $t('roles.modules.cancel') }}
              </sw-button>
              <sw-button
                v-if="moduleOptionsFilter.length !== 0"
                @click="addAllPermissions"
                variant="primary-outline"
                class="mr-2 mb-2 md:mb-0 w-full md:w-auto"
              >
                {{ $t('roles.modules.add_all') }}
              </sw-button>
              <sw-button
                variant="primary"
                @click="updateUser()"
                class="mr-2 mb-2 md:mb-0 w-full md:w-auto"
              >
                <save-icon class="mr-2 -ml-1" />
                {{ $t('general.update') }}
              </sw-button>
            </div>
          </div>

          <div class="scrollable-div3">
            <table
              class="w-full text-center item-table table-permission table-wrapperestper"
            >
              <thead class="border border-gray-200 border-solid theadClass">
                <th style="width: 70%" class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.module') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.modules.access') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.modules.create') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.modules.read') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.modules.update') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700">
                  {{ $t('roles.modules.delete') }}
                </th>
                <th class="py-3 px-2 text-sm text-gray-700"></th>
              </thead>
              <tbody>
                <tr
                  v-for="(permission, indexTr) in userData.permissions"
                  :key="indexTr"
                  class="border border-gray-200 border-solid"
                >
                  <td>
                    <div class="flex justify-center items-center p-3">
                      <sw-input-group>
                        <sw-select
                          v-model="permission.module"
                          :options="moduleOptionsFilter"
                          :searchable="true"
                          :show-labels="false"
                          :placeholder="$t('roles.module')"
                          label="label"
                          @input="addPermission"
                        />
                      </sw-input-group>
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-center pb-6">
                      <sw-switch v-model="permission.access" />
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-center pb-6">
                      <sw-switch v-model="permission.create" />
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-center pb-6">
                      <sw-switch v-model="permission.read" />
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-center pb-6">
                      <sw-switch v-model="permission.update" />
                    </div>
                  </td>
                  <td>
                    <div class="flex justify-center pb-6">
                      <sw-switch v-model="permission.delete" />
                    </div>
                  </td>
                  <td>
                    <div
                      v-if="permission.module.value !== ''"
                      class="flex justify-center p-3 cursor-pointer"
                      @click="removeModule(indexTr)"
                    >
                      <trash-icon class="h-5 text-gray-700" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </sw-card>
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const {
  required,
  minLength,
  url,
  email,
  numeric,
  minValue,
  maxLength,
  requiredIf,
} = require('vuelidate/lib/validators')
import { TrashIcon, PlusIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    TrashIcon,
    PlusIcon,
  },
  data() {
    return {
      isRequestOnGoing: false,
      userData: {
        permissions: [],
      },
      moduleOptions: [
        {
          label: this.$t('roles.modules.customers'),
          value: 'customers',
        },
        {
          label: this.$t('roles.modules.leads'),
          value: 'lead',
        },
        {
          label: this.$t('roles.modules.lead_notes'),
          value: 'lead_notes',
        },
        {
          label: this.$t('roles.modules.providers'),
          value: 'providers',
        },
        {
          label: this.$t('roles.modules.estimates'),
          value: 'estimates',
        },
        {
          label: this.$t('roles.modules.invoices'),
          value: 'invoices',
        },
        {
          label: this.$t('roles.modules.payments'),
          value: 'payments',
        },
        {
          label: this.$t('roles.modules.items'),
          value: 'items',
        },
        {
          label: this.$t('roles.modules.expenses'),
          value: 'expenses',
        },
        {
          label: this.$t('roles.modules.packages'),
          value: 'packages',
        },
        {
          label: this.$t('roles.modules.corepbx'),
          value: 'corepbx',
        },
        {
          label: this.$t('roles.modules.tickets'),
          value: 'tickets',
        },
        {
          label: this.$t('roles.modules.users'),
          value: 'users',
        },
        {
          label: this.$t('roles.modules.reports'),
          value: 'reports',
        },
        {
          label: this.$t('roles.modules.settings'),
          value: 'settings',
        },
        {
          label: this.$t('roles.modules.account_settings'),
          value: 'account_settings',
        },
        {
          label: this.$t('roles.modules.company_info'),
          value: 'company_info',
        },
        {
          label: this.$t('roles.modules.preferences'),
          value: 'preferences',
        },
        {
          label: this.$t('roles.modules.customizations'),
          value: 'customizations',
        },
        {
          label: this.$t('roles.modules.notifications'),
          value: 'notifications',
        },
        {
          label: this.$t('roles.modules.tax_Groups'),
          value: 'tax_Groups',
        },
        {
          label: this.$t('roles.modules.tax_types'),
          value: 'tax_types',
        },
        {
          label: this.$t('roles.modules.payment_modes'),
          value: 'payment_modes',
        },
        {
          label: this.$t('roles.modules.custom_fields'),
          value: 'custom_fields',
        },
        {
          label: this.$t('roles.modules.notes'),
          value: 'notes',
        },
        {
          label: this.$t('roles.modules.expense_categories'),
          value: 'expense_categories',
        },
        {
          label: this.$t('roles.modules.mail_configuration'),
          value: 'mail_configuration',
        },
        {
          label: this.$t('roles.modules.file_disk'),
          value: 'file_disk',
        },
        {
          label: this.$t('roles.modules.backup'),
          value: 'backup',
        },
        {
          label: this.$t('roles.modules.logs'),
          value: 'logs',
        },
        {
          label: this.$t('roles.modules.Modules'),
          value: 'Modules',
        },

        {
          label: this.$t('roles.modules.roles'),
          value: 'roles',
        },
        {
          label: this.$t('roles.modules.payment_gateways'),
          value: 'payment_gateways',
        },
        {
          label: this.$t('roles.modules.Authorize'),
          value: 'Authorize',
        },
        {
          label: this.$t('roles.modules.Paypal'),
          value: 'Paypal',
        },
        {
          label: this.$t('roles.modules.services'),
          value: 'services',
        },
        {
          label: this.$t('roles.modules.pbx_services'),
          value: 'pbx_services',
        },
        {
          label: this.$t('roles.modules.services_normal'),
          value: 'services_normal',
        },
        {
          label: this.$t('roles.modules.retentions'),
          value: 'retentions',
        },
        {
          label: this.$t('roles.modules.pbx_packages'),
          value: 'pbx_packages',
        },
        {
          label: this.$t('roles.modules.pbx_extension'),
          value: 'pbx_extension',
        },
        {
          label: this.$t('roles.modules.pbx_did'),
          value: 'pbx_did',
        },
        {
          label: this.$t('roles.modules.pbx_app_rate'),
          value: 'pbx_app_rate',
        },
        {
          label: this.$t('roles.modules.pbx_custom_did'),
          value: 'pbx_custom_did',
        },
        {
          label: this.$t('roles.modules.pbx_custom_destination'),
          value: 'pbx_custom_destination',
        },
        {
          label: this.$t('roles.modules.pbx_customization'),
          value: 'pbx_customization',
        },
        {
          label: this.$t('roles.modules.pbx_report'),
          value: 'pbx_report',
        },
        {
          label: this.$t('roles.modules.pbx_tenant'),
          value: 'pbx_tenant',
        },
        {
          label: this.$t('roles.modules.tickets_depa'),
          value: 'tickets_depa',
        },
        {
          label: this.$t('roles.modules.tickets_email_temp'),
          value: 'tickets_email_temp',
        },
        {
          label: this.$t('roles.modules.cust_address'),
          value: 'cust_address',
        },
        {
          label: this.$t('roles.modules.cust_contacts'),
          value: 'cust_contacts',
        },
        {
          label: this.$t('roles.modules.cust_payment_acc'),
          value: 'cust_payment_acc',
        },
        {
          label: this.$t('roles.modules.cust_mnotes'),
          value: 'cust_mnotes',
        },
        {
          label: this.$t('roles.modules.open_close_cash_register'),
          value: 'open_close_cash_register',
        },
        {
          label: this.$t('roles.modules.income_withdrawal_cash'),
          value: 'income_withdrawal_cash',
        },
        {
          label: this.$t('roles.modules.assign_user_cash_register'),
          value: 'assign_user_cash_register',
        },
      ],
      permissionBlank: {
        module: {
          label: '',
          value: '',
        },
        access: 1,
        create: 1,
        read: 1,
        update: 1,
        delete: 1,
      },
    }
  },
  computed: {
    ...mapGetters('modules', ['modules']),

    /**
     * Filter module options based on user permissions.
     * Loads modules and filters out options that are not present in the user's permissions.
     *
     * @return {Array} The filtered array of module options.
     */
    moduleOptionsFilter() {
      // Load modules
      this.loadModules()

      // Assign module options and user permissions to local variables
      const moduleOptions = this.moduleOptions
      const permissions = this.userData.permissions

      // Filter module options that are not present in the user's permissions
      const moduleOptionsFilter = moduleOptions.filter((option) => {
        // Check if the permission for the current module option is absent
        const isPermissionAbsent =
          permissions.findIndex((permission) => {
            // Verify if the module in the permission is not null and not empty
            return permission.module && permission.module.value === option.value
          }) === -1

        return isPermissionAbsent
      })

      // Return the filtered array of module options
      return moduleOptionsFilter
    },

    idUser() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.loadUserData()
  },
  methods: {
    ...mapActions('users', ['showUserId', 'updatePermissionsUser']),
    ...mapActions('modules', ['getModules']),

    /**
     * Load available modules and update module options based on their status.
     * Fetches modules from the store and adds them to moduleOptions if they are active.
     *
     * @return {Promise<void>}
     */
    async loadModules() {
      try {
        // Retrieve modules from the store
        const modules = this.$store.state.modules.modulesData.modules

        // Iterate over each module to check its status and update module options
        for (const module of modules) {
          if (module.status === 'A') {
            // Check the module name and add to moduleOptions if not already present
            switch (module.name) {
              case 'PBXware':
                this.addModuleOption('PBXware', 'roles.modules.PBXware')
                break
              case 'Avalara':
                this.addModuleOption('Avalara', 'roles.modules.Avalara')
                break
              case 'BillPay':
                this.addModuleOption('BillPay', 'roles.modules.BillPay')
                break
              case 'corePOS':
                this.addModuleOption(
                  'corePOS_module',
                  'roles.modules.corePos_module'
                )
                this.addModuleOption(
                  'corePOS_index',
                  'roles.modules.corePos_index'
                )
                this.addModuleOption(
                  'corePOS_dashboard',
                  'roles.modules.corePos_dashboard'
                )
                break
              // Add more cases as needed
            }
          }
        }
      } catch (error) {
        console.error('Error loading modules:', error)
      }
    },

    /**
     * Add a module option to moduleOptions if it is not already present.
     *
     * @param {string} value - The value of the module option.
     * @param {string} labelKey - The translation key for the module option label.
     */
    addModuleOption(value, labelKey) {
      if (!this.moduleOptions.find((element) => element.value === value)) {
        this.moduleOptions.push({
          label: this.$t(labelKey),
          value: value,
        })
      }
    },
    /**
     * Load user data based on the provided user ID.
     * Fetches user data, maps permissions to corresponding modules, and handles loading state.
     *
     * @return {Promise<void>}
     */
    async loadUserData() {
      try {
        // Indicate that a request is ongoing
        this.isRequestOnGoing = true

        // Fetch user data based on the user ID
        const res = await this.showUserId(this.idUser)

        // Assign the fetched user data to the userData variable
        this.userData = res.data?.data

        // Map user permissions to corresponding modules
        this.userData.permissions.forEach((permission) => {
          let module = this.moduleOptions.find(
            (module) => module.value === permission.module
          )

          // If the module does not exist, create and assign it to the permission
          if (!module) {
            module = {
              id: permission.id,
              label: permission.module,
              value: permission.module,
            }
          }
          permission.module = module
        })

        // Add a blank permission if the number of permissions is less than or equal to 64
        if (this.userData.permissions.length <= 64) {
          this.addPermissionBlank()
        }
      } catch (error) {
        // Handle the error, for example, by showing a message to the user
        console.error('Error loading user data:', error)
      } finally {
        // Indicate that the request has finished
        this.isRequestOnGoing = false
      }
    },
    /**
     * Actualiza los permisos de un usuario.
     *
     * Este método realiza la actualización de los permisos de un usuario específico.
     * Primero, marca que una solicitud está en curso. Luego, prepara un payload con el ID del usuario
     * y los permisos filtrados para eliminar aquellos que no tienen un valor de módulo.
     * Después, llama a la función `updatePermissionsUser` para actualizar los permisos en el servidor.
     * Si la actualización es exitosa, redirige a la vista del usuario. En caso de error, lo registra en la consola.
     * Finalmente, marca que la solicitud ha finalizado.
     */
    async updateUser() {
      // Indica que la solicitud está en curso
      this.isRequestOnGoing = true
      try {
        // Prepara el payload con el ID del usuario y los permisos filtrados
        const payload = {
          id: this.$route.params.id,
          permissions: this.userData.permissions.filter(
            (permission) => permission.module.value !== ''
          ),
        }

        // Llama a la función para actualizar los permisos del usuario
        await this.updatePermissionsUser(payload)

        // Redirige a la vista del usuario después de la actualización
        this.$router.push({
          name: 'users.view',
          params: { idUser: this.idUser },
        })
      } catch (error) {
        // Maneja cualquier error que ocurra durante la actualización
        console.error('Error updating user permissions:', error)
      } finally {
        // Indica que la solicitud ha finalizado
        this.isRequestOnGoing = false
      }
    },

    /**
     * Añade permisos para todos los módulos disponibles.
     *
     * Este método elimina el último permiso en la lista de permisos del usuario y luego añade un permiso
     * para cada módulo disponible en `moduleOptionsFilter`. Cada permiso añadido es una copia del objeto
     * `permissionBlank` con el módulo correspondiente asignado.
     */
    addAllPermissions() {
      // Elimina el último permiso en la lista de permisos del usuario
      this.userData.permissions.pop()

      // Crea una copia profunda del objeto permissionBlank
      const permissionBlank = JSON.parse(JSON.stringify(this.permissionBlank))

      // Itera sobre cada módulo en moduleOptionsFilter
      this.moduleOptionsFilter.forEach((module) => {
        // Asigna el módulo actual al permiso en blanco
        permissionBlank.module = module

        // Añade una copia profunda del permiso modificado a la lista de permisos del usuario
        this.userData.permissions.push(
          JSON.parse(JSON.stringify(permissionBlank))
        )
      })
    },
    /**
     * Añade un permiso en blanco a la lista de permisos del usuario.
     *
     * Este método crea una copia profunda del objeto `permissionBlank` y lo añade a la lista de permisos
     * del usuario (`userData.permissions`).
     */
    addPermissionBlank() {
      // Crea una copia profunda del objeto permissionBlank
      const permissionBlank = JSON.parse(JSON.stringify(this.permissionBlank))

      // Añade el permiso en blanco a la lista de permisos del usuario
      this.userData.permissions.push(permissionBlank)
    },
    /**
     * Añade un permiso en blanco si no hay módulos vacíos en la lista de permisos del usuario.
     *
     * Este método busca en la lista de permisos del usuario (`userData.permissions`) un permiso con un módulo vacío.
     * Si no encuentra ninguno y hay opciones de módulos disponibles (`moduleOptionsFilter`), añade un permiso en blanco.
     */
    addPermission() {
      // Busca el índice de un permiso con un módulo vacío
      const moduleEmptyIndex = this.userData.permissions.findIndex(
        (permission) => permission.module.value === ''
      )

      // Si no se encuentra un módulo vacío y hay opciones de módulos disponibles, añade un permiso en blanco
      if (moduleEmptyIndex === -1 && this.moduleOptionsFilter.length !== 0) {
        this.addPermissionBlank()
      }
    },
    /**
     * Elimina un módulo de la lista de permisos del usuario y añade un permiso en blanco si es necesario.
     *
     * Este método elimina el permiso en la posición especificada por `index` en la lista de permisos del usuario
     * (`userData.permissions`). Después, verifica si es necesario añadir un permiso en blanco llamando al método `addPermission`.
     */
    removeModule(index) {
      // Elimina el permiso en la posición especificada por index
      this.userData.permissions.splice(index, 1)

      // Verifica si es necesario añadir un permiso en blanco
      this.addPermission()
    },

    cancelButton() {
      this.$utils.cancelFormOrBack(this, this.$router, 'cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.table-permission {
  .theadClass {
    position: sticky;
    top: -35px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
  }
}
</style>
