var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full mb-8 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
    },
    [
      _c("div", { staticClass: "heading-section" }, [
        _c(
          "p",
          {
            staticClass:
              "text-2xl not-italic font-semibold leading-7 text-black",
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("customers.package_details")) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-8" }, [
        _c(
          "table",
          { staticClass: "w-full text-center item-table" },
          [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "40%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "10%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
              _vm._v(" "),
              _vm.discountPerItem === "YES"
                ? _c("col", { staticStyle: { width: "15%" } })
                : _vm._e(),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
            ]),
            _vm._v(" "),
            _c(
              "thead",
              { staticClass: "bg-white border border-gray-200 border-solid" },
              [
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass:
                        "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                    },
                    [
                      _c("span", { staticClass: "pl-12" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$tc("items.item", 2)) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invoices.item.quantity")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invoices.item.price")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.discountPerItem === "YES"
                    ? _c(
                        "th",
                        {
                          staticClass:
                            "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("invoices.item.discount")) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                    },
                    [
                      _c("span", { staticClass: "pr-10" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("invoices.item.total")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "item-body",
                attrs: { tag: "tbody", handle: ".handle" },
                model: {
                  value: _vm.packageCustomer.items,
                  callback: function ($$v) {
                    _vm.$set(_vm.packageCustomer, "items", $$v)
                  },
                  expression: "packageCustomer.items",
                },
              },
              _vm._l(_vm.packageCustomer.items, function (item, index) {
                return _c("package-item", {
                  key: item.id,
                  attrs: {
                    index: index,
                    "item-data": item,
                    "invoice-items": _vm.packageCustomer.items,
                    currency: _vm.currency,
                    "tax-per-item": _vm.taxPerItem,
                    "discount-per-item": _vm.discountPerItem,
                  },
                  on: {
                    remove: _vm.removeItem,
                    update: _vm.updateItem,
                    itemValidate: _vm.checkItemsData,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
          on: { click: _vm.addItem },
        },
        [
          _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
          _vm._v("\n    " + _vm._s(_vm.$t("invoices.add_item")) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
        },
        [
          _c("div", { staticClass: "w-full lg:w-1/2" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center justify-between w-full" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("invoices.sub_total")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "flex items-center justify-center m-0 text-lg text-black uppercase",
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(_vm.subtotal, _vm.currency)
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.allTaxes, function (tax) {
                return _c(
                  "div",
                  {
                    key: tax.tax_type_id,
                    staticClass: "flex items-center justify-between w-full",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                      },
                      [
                        _vm._v(
                          _vm._s(tax.name) +
                            " - " +
                            _vm._s(tax.percent) +
                            "%\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                        staticStyle: { "font-size": "18px" },
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(tax.amount, _vm.currency)
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              (_vm.discountPerItem === "NO" || _vm.discountPerItem === null) &&
              _vm.parameters.allow_discount
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-between w-full mt-2",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-sm font-semibold leading-5 text-gray-500 uppercase",
                        },
                        [_vm._v(_vm._s(_vm.$t("invoices.discount")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { width: "105px" },
                          attrs: { role: "group" },
                        },
                        [
                          _c("sw-input", {
                            staticClass:
                              "border-r-0 rounded-tr-sm rounded-br-sm",
                            attrs: {
                              invalid:
                                _vm.$v.packageCustomer.discount_val.$error,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.packageCustomer.discount_val.$touch()
                              },
                            },
                            model: {
                              value: _vm.discount,
                              callback: function ($$v) {
                                _vm.discount = $$v
                              },
                              expression: "discount",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            { attrs: { position: "bottom-end" } },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticStyle: { height: "43px" },
                                  attrs: {
                                    slot: "activator",
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    size: "discount",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                    variant: "white",
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "flex" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.packageCustomer
                                              .discount_type === "fixed"
                                              ? _vm.currency.symbol
                                              : "%"
                                          ) +
                                          "\n                "
                                      ),
                                      _c("chevron-down-icon", {
                                        staticClass: "h-5",
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectFixed } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.fixed")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectPercentage } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.percentage")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.taxPerItem ? "NO" : null)
                ? _c(
                    "div",
                    _vm._l(_vm.packageCustomer.taxes, function (tax, index) {
                      return _c("tax", {
                        key: tax.id,
                        attrs: {
                          index: index,
                          total: _vm.subtotalWithDiscount,
                          tax: tax,
                          taxes: _vm.packageCustomer.taxes,
                          currency: _vm.currency,
                          "total-tax": _vm.totalSimpleTax,
                        },
                        on: {
                          remove: _vm.removeInvoiceTax,
                          update: _vm.updateTax,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.taxPerItem === "NO" || _vm.taxPerItem === null) &&
              _vm.parameters.tax_type.value === "G"
                ? _c(
                    "sw-popup",
                    {
                      ref: "taxModal",
                      staticClass:
                        "my-3 text-sm font-semibold leading-5 text-primary-400",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "float-right pt-2 pb-5",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _vm._v(
                            "\n          + " +
                              _vm._s(_vm.$t("invoices.add_tax")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("tax-select-popup", {
                        attrs: { taxes: _vm.packageCustomer.taxes },
                        on: { select: _vm.onSelectTax },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("invoices.total")) +
                          " " +
                          _vm._s(_vm.$t("invoices.amount")) +
                          ":\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "flex items-center justify-center text-lg uppercase text-primary-400",
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(_vm.total, _vm.currency)
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "sw-button",
            {
              staticClass: "flex justify-center w-auto align-bottom",
              attrs: { disabled: _vm.isLoading, variant: "primary-outline" },
              on: { click: _vm.back },
            },
            [
              _c("arrow-left-icon", { staticClass: "h-5 mr-2 -ml-1" }),
              _vm._v("\n      " + _vm._s(_vm.$t("general.back")) + "\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "flex justify-center w-auto align-bottom ml-4",
              attrs: { loading: _vm.isLoading, variant: "primary" },
              on: { click: _vm.next },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.continue")) + "\n      "
              ),
              _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }