var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "download-cdrs" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-12 p-8 gap-y-6 gap-x-4" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "text-sm not-italic font-medium leading-5 text-gray-500 col-span-12",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pbx_services.cdr_download_description")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4 col-span-12 md:col-span-6",
                  attrs: {
                    label: _vm.$t("pbx_services.start_date"),
                    error: _vm.startDateError,
                    required: "",
                  },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      invalid: _vm.$v.formData.start_date.$error,
                      "calendar-button": true,
                      "min-date": _vm.min_date,
                      "max-date": _vm.max_date,
                      "calendar-button-icon": "calendar",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.start_date.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.start_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "start_date", $$v)
                      },
                      expression: "formData.start_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-4 col-span-12 md:col-span-6",
                  attrs: {
                    label: _vm.$t("pbx_services.end_date"),
                    error: _vm.endDateError,
                    required: "",
                  },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      invalid: _vm.$v.formData.end_date.$error,
                      "calendar-button": true,
                      "min-date": _vm.min_date,
                      "max-date": _vm.max_date,
                      "calendar-button-icon": "calendar",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.end_date.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.end_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "end_date", $$v)
                      },
                      expression: "formData.end_date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "primary-outline", type: "button" },
                  on: { click: _vm.closeImportModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                { attrs: { variant: "primary", type: "submit" } },
                [
                  _c("download-icon", { staticClass: "h-4 mr-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.download")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }