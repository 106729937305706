var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid h-full grid-cols-12 overflow-y-hidden bg-gray-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center w-full max-w-sm col-span-12 p-4 mx-auto text-gray-900 md:p-8 md:col-span-6 lg:col-span-4 flex-2 md:pb-48 md:pt-40",
        },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.loadingInfo
                      ? "relative bg-indigo-100 rounded-xl px-20 py-10 mb-32  flex justify-center items-center"
                      : "",
                  ],
                },
                [
                  _vm.loadingInfo
                    ? _c("base-loader", { staticClass: "absolute m-auto" })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loadingInfo
                    ? _c("a", { attrs: { href: "/admin" } }, [
                        _vm.previewLogo
                          ? _c("img", {
                              staticClass:
                                "block w-48 h-auto max-w-full mb-32 text-primary-400",
                              attrs: { src: _vm.previewLogo },
                            })
                          : _c("img", {
                              staticClass:
                                "block w-48 h-auto max-w-full mb-32 text-primary-400",
                              attrs: {
                                src: "/assets/img/logo-corebill.png",
                                alt: "Crater Logo",
                              },
                            }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("router-view"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pt-24 mt-0 text-sm not-italic font-medium leading-relaxed text-left text-gray-500 md:pt-40",
                },
                [
                  _vm.footer_text_value
                    ? _c("div", [
                        _c("p", { staticClass: "mb-3 ml-12" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.footer_text_value) +
                              "\n            "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.footer_url_value,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.footer_url_name) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.current_year == 1
                            ? _c("span", [
                                _vm._v(" © " + _vm._s(_vm.footer_year) + " "),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _c("p", { staticClass: "mb-3 ml-12" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("layout_login.copyright_crater")) +
                            "\n        "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative flex-col items-center justify-center hidden w-full h-full pl-10 bg-no-repeat bg-cover md:col-span-6 lg:col-span-8 md:flex",
          style: _vm.wallpaperLogin,
        },
        [
          _c(
            "div",
            {
              class: [
                _vm.loadingInfo
                  ? "relative bg-indigo-100 rounded-xl px-20 py-10  flex justify-center items-center"
                  : "pl-20 xl:pl-0 w-1/2",
              ],
            },
            [
              _vm.loadingInfo
                ? _c("base-loader", { staticClass: "absolute m-auto" })
                : _vm._e(),
              _vm._v(" "),
              _vm.title_header == null && !_vm.loadingInfo
                ? _c(
                    "h1",
                    {
                      staticClass:
                        "hidden mb-3 text-3xl font-bold leading-normal text-white xl:text-5xl xl:leading-tight md:none lg:block",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("layout_login.super_simple_invoicing")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("layout_login.for_freelancer")) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("layout_login.small_businesses")) +
                          " "
                      ),
                      _c("br"),
                    ]
                  )
                : _c(
                    "h1",
                    {
                      staticClass:
                        "hidden mb-3 text-3xl font-bold leading-normal text-white xl:text-5xl xl:leading-tight md:none lg:block",
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.title_header) + "\n      "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "hidden text-sm not-italic font-normal leading-normal text-gray-100 xl:text-base xl:leading-6 md:none lg:block",
                },
                [
                  _vm.subtitle_header == null && !_vm.loadingInfo
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("layout_login.crater_help"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("layout_login.invoices_and_estimates")
                            )
                        ),
                        _c("br"),
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.subtitle_header) +
                            "\n        "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "absolute z-50 w-full bg-no-repeat content-bottom",
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }