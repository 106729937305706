var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.getDashboardDataLoaded ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-6 mt-10 xl:grid-cols-2" },
        [
          _vm.permissionModule.access
            ? _c(
                "div",
                { staticClass: "due-invoices" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative z-10 flex items-center justify-between",
                    },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "mb-0 text-xl font-semibold leading-normal",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("dashboard.recent_invoices_card.title")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: "/admin/invoices",
                            variant: "primary-outline",
                            size: "sm",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "dashboard.recent_invoices_card.view_all"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "inv_table",
                      staticClass: "tableWithOutPadding",
                      attrs: {
                        data: _vm.fetchDataInvoicesDue,
                        "show-filter": false,
                        size: "sm",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.due_on"
                          ),
                          "sort-as": "due_date",
                          show: "formattedDueDate",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_invoices_card.due_on"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(row.formattedDueDate)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1474054816
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.customer"
                          ),
                          "sort-as": "user_id",
                          show: "user.name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_invoices_card.customer"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500 text-sm md:text-base truncate md:overflow-visible md:whitespace-normal",
                                      attrs: {
                                        to: { path: `invoices/${row.id}/view` },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.user.name) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3279159323
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("invoices.status"),
                          "sort-as": "status",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("invoices.status"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-badge",
                                    {
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.status != "PARTIALLY_PAID"
                                              ? row.status
                                              : row.status.replace("_", " ")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4042390201
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "dashboard.recent_invoices_card.amount_due"
                          ),
                          show: "due_amount",
                          "sort-as": "due_amount",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_invoices_card.amount_due"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.due_amount,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1418339153
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class":
                            "action-dropdown dashboard-recent-invoice-options no-click",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return _c(
                                  "sw-dropdown",
                                  {},
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _vm.permissionModule.update
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                attrs: {
                                                  "tag-name": "router-link",
                                                  to: `invoices/${row.id}/edit`,
                                                },
                                              },
                                              [
                                                _c("pencil-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.$t("general.edit")
                                                    ) +
                                                    "\n              "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.read
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                attrs: {
                                                  "tag-name": "router-link",
                                                  to: `invoices/${row.id}/view`,
                                                },
                                              },
                                              [
                                                _c("eye-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.$t("invoices.view")
                                                    ) +
                                                    "\n              "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.permissionModule.delete
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "sw-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeInvoice(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("trash-icon", {
                                                  staticClass:
                                                    "h-5 mr-3 text-gray-600",
                                                }),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.$t("general.delete")
                                                    ) +
                                                    "\n              "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2216951253
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissionModule.accessEstimates
            ? _c(
                "div",
                { staticClass: "recent-estimates" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative z-10 flex items-center justify-between",
                    },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "mb-0 text-xl font-semibold leading-normal",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("dashboard.recent_estimate_card.title")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            "tag-name": "router-link",
                            to: "/admin/estimates",
                            variant: "primary-outline",
                            size: "sm",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "dashboard.recent_estimate_card.view_all"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-table-component",
                    {
                      ref: "est_table",
                      staticClass: "tableWithOutPadding",
                      attrs: {
                        data: _vm.fetchDataRecentEstimates,
                        "show-filter": false,
                        "table-class": "table",
                      },
                    },
                    [
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("dashboard.recent_estimate_card.date"),
                          show: "formattedExpiryDate",
                          "sort-as": "expiry_date",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_estimate_card.date"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(row.formattedExpiryDate)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1686480785
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        staticClass: "py-5 w-full",
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "dashboard.recent_estimate_card.customer"
                          ),
                          "sort-as": "user_id",
                          show: "user.name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_estimate_card.customer"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "font-medium text-primary-500 text-sm md:text-base truncate md:overflow-visible md:whitespace-normal",
                                      attrs: {
                                        to: {
                                          path: `estimates/${row.id}/view`,
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.user.name) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2551129928
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t("estimates.status"),
                          show: "status",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("estimates.status"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "sw-badge",
                                    {
                                      staticClass: "px-3 py-1",
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            row.status
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          row.status
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.status) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3837781073
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: true,
                          label: _vm.$t(
                            "dashboard.recent_estimate_card.amount_due"
                          ),
                          show: "total",
                          "sort-as": "total",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dashboard.recent_estimate_card.amount_due"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1388988000
                        ),
                      }),
                      _vm._v(" "),
                      _c("sw-table-column", {
                        attrs: {
                          sortable: false,
                          filterable: false,
                          "cell-class": "action-dropdown no-click",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (row) {
                                return _c(
                                  "sw-dropdown",
                                  {},
                                  [
                                    _c("dot-icon", {
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        attrs: {
                                          "tag-name": "router-link",
                                          to: `estimates/${row.id}/edit`,
                                        },
                                      },
                                      [
                                        _c("pencil-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("general.edit")) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        attrs: {
                                          "tag-name": "router-link",
                                          to: `estimates/${row.id}/view`,
                                        },
                                      },
                                      [
                                        _c("eye-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("general.view")) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.convertInToinvoice(
                                              row.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("document-text-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "estimates.convert_to_invoice"
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onMarkAsSent(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("check-circle-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("estimates.mark_as_sent")
                                            ) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    row.status !== "ACCEPTED"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMarkAsAccepted(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("check-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_accepted"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status !== "REJECTED"
                                      ? _c(
                                          "sw-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMarkAsRejected(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("x-circle-icon", {
                                              staticClass:
                                                "h-5 mr-3 text-gray-600",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_rejected"
                                                  )
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "sw-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeEstimate(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("trash-icon", {
                                          staticClass: "h-5 mr-3 text-gray-600",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            "\n            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3637251740
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }