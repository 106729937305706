var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _vm.isSuperAdmin
        ? _c("base-page", { staticClass: "tax-group-create" }, [
            _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitTaxGroup.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "sw-page-header",
                  { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
                  [
                    _c(
                      "sw-breadcrumb",
                      { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                      [
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/dashboard",
                            title: _vm.$t("general.home"),
                          },
                        }),
                        _vm._v(" "),
                        _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "/admin/settings/tax-groups",
                            title: _vm.$t("tax_groups.tax_group"),
                          },
                        }),
                        _vm._v(" "),
                        _vm.$route.name === "tax-groups.edit"
                          ? _c("sw-breadcrumb-item", {
                              attrs: {
                                to: "#",
                                title: _vm.$t("tax_groups.edit_tax_group"),
                                active: "",
                              },
                            })
                          : _c("sw-breadcrumb-item", {
                              attrs: {
                                to: "#",
                                title: _vm.$t("tax_groups.new_tax_group"),
                                active: "",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass:
                              "flex justify-center w-full md:w-auto mr-2",
                            attrs: {
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                              variant: "primary-outline",
                              type: "button",
                              size: "lg",
                            },
                            on: { click: _vm.cancelButton },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-button",
                          {
                            staticClass: "flex justify-center w-full md:w-auto",
                            attrs: {
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                              variant: "primary",
                              type: "submit",
                              size: "lg",
                            },
                          },
                          [
                            !_vm.isLoading
                              ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t("tax_groups.update_tax_group")
                                    : _vm.$t("tax_groups.save_tax_group")
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "grid grid-cols-12" }, [
                  _c(
                    "div",
                    { staticClass: "col-span-12" },
                    [
                      _c(
                        "sw-card",
                        { staticClass: "mb-8" },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t("tax_groups.name"),
                                error: _vm.nameError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  invalid: _vm.$v.formData.name.$error,
                                  focus: "",
                                  type: "text",
                                  name: "name",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.$t("tax_groups.description"),
                                error: _vm.descriptionError,
                              },
                            },
                            [
                              _c("sw-textarea", {
                                attrs: {
                                  placeholder: _vm.$t("tax_groups.description"),
                                  type: "text",
                                  name: "description",
                                  rows: "3",
                                  tabindex: "11",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.description.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "description",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: {
                                label: _vm.$t("tax_groups.status"),
                                error: _vm.statusError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  invalid: _vm.$v.formData.status.$error,
                                  options: _vm.status,
                                  searchable: true,
                                  "show-labels": false,
                                  tabindex: 16,
                                  "allow-empty": true,
                                  placeholder: _vm.$t("tax_groups.status"),
                                  label: "text",
                                  "track-by": "value",
                                },
                                model: {
                                  value: _vm.formData.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "status", $$v)
                                  },
                                  expression: "formData.status",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: { label: _vm.$t("tax_groups.country") },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.countries,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": true,
                                  tabindex: 8,
                                  placeholder: _vm.$t("general.select_country"),
                                  label: "name",
                                  "track-by": "id",
                                },
                                on: { select: _vm.countrySeleted },
                                model: {
                                  value: _vm.formData.countries,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "countries", $$v)
                                  },
                                  expression: "formData.countries",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "md:col-span-3 mb-4",
                              attrs: { label: _vm.$t("tax_groups.state") },
                            },
                            [
                              _c("sw-select", {
                                attrs: {
                                  options: _vm.states,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": true,
                                  tabindex: 8,
                                  placeholder: _vm.$t("general.select_state"),
                                  label: "name",
                                  "track-by": "id",
                                  select: "stateSeleted",
                                },
                                model: {
                                  value: _vm.formData.states,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "states", $$v)
                                  },
                                  expression: "formData.states",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: { label: _vm.$t("customers.county") },
                            },
                            [
                              _c("sw-input", {
                                ref: "county",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.formData.county,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "county", $$v)
                                  },
                                  expression: "formData.county",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mb-4",
                              attrs: { label: _vm.$t("customers.city") },
                            },
                            [
                              _c("sw-input", {
                                ref: "city",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.formData.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "city", $$v)
                                  },
                                  expression: "formData.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid col-span-12 gap-y-1 gap-x-4 md:grid-cols-6",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    {
                                      staticClass: "md:col-span-3",
                                      attrs: {
                                        label: _vm.$t("packages.taxes"),
                                        error: _vm.taxesError,
                                        required: "",
                                      },
                                    },
                                    [
                                      _c("sw-select", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          options: _vm.taxes,
                                          searchable: true,
                                          "show-labels": false,
                                          "allow-empty": true,
                                          placeholder:
                                            _vm.$tc("packages.add_tax"),
                                          label: "name_por",
                                          "track-by": "id",
                                          tabindex: 9,
                                          name: "taxes",
                                          invalid: _vm.$v.formData.taxes.$error,
                                        },
                                        on: { select: _vm.taxSeleted },
                                        model: {
                                          value: _vm.tax,
                                          callback: function ($$v) {
                                            _vm.tax = $$v
                                          },
                                          expression: "tax",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-span-12" }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-table-component",
                                    {
                                      ref: "table",
                                      staticClass: "col-span-12",
                                      attrs: {
                                        "show-filter": false,
                                        data: _vm.paralelo,
                                        "table-class": "table",
                                        variant: "gray",
                                      },
                                    },
                                    [
                                      _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          label: _vm.$t(
                                            "settings.tax_types.tax_name"
                                          ),
                                          show: "name",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (row) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "settings.tax_types.tax_name"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "mt-6" },
                                                    [_vm._v(_vm._s(row.name))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3194549487
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          filterable: true,
                                          label: _vm.$t(
                                            "settings.tax_types.compound_tax"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (row) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "settings.tax_types.compound_tax"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "sw-badge",
                                                    {
                                                      attrs: {
                                                        "bg-color":
                                                          _vm.$utils.getBadgeStatusColor(
                                                            row.compound_tax
                                                              ? "YES"
                                                              : "NO"
                                                          ).bgColor,
                                                        color:
                                                          _vm.$utils.getBadgeStatusColor(
                                                            row.compound_tax
                                                              ? "YES"
                                                              : "NO"
                                                          ).color,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            row.compound_tax
                                                              ? "Yes"
                                                              : "No".replace(
                                                                  "_",
                                                                  " "
                                                                )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3499610837
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          filterable: true,
                                          label: _vm.$t(
                                            "settings.tax_types.percent"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (row) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "settings.tax_types.percent"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(row.percent) +
                                                      " %\n                    "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          470661731
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("sw-table-column", {
                                        attrs: {
                                          sortable: true,
                                          filterable: true,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (row) {
                                                return [
                                                  _c("trash-icon", {
                                                    staticClass:
                                                      "h-5 mr-3 text-gray-600",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeTax(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3841190124
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }