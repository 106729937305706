<template>
  <form id="">
    <div class="col-span-1">
          <paypal
            :formData="formData"
            :codePayment="codePayment"
            :invoice_number="invoice_number"
            :customer="customer"
            @paypalSuccess="paypalSuccess"
          ></paypal>
        </div>
  </form>
</template>

<script >
import { EyeIcon, EyeOffIcon } from "@vue-hero-icons/outline";
import creditCardExpirationDate from "@/components/payments/creditCardExpirationDate.vue";
import Paypal from "../../payments/Paypal.vue";

export default {
  components: {
  Paypal
  },
  data() {
    return {
      formData: {
        card_holder: "",
        card_number: "",
        expiration_date: "",
        cvv: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      isShowNumber: false,
      isShowRouting: false,
    };

  },
  computed: {
     getInputType() {
      if (this.isShowNumber) {
        return "text";
      }
      return "password";
    },
    getInputType2() {
      if (this.isShowRouting) {
        return "text";
      }
      return "password";
    },
  },
  methods: {

  },
}
</script>
