var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-1 text-sm" },
    [
      _vm.customDid.custom_did_id
        ? _c(
            "div",
            {
              staticClass:
                "relative flex items-center h-10 pl-2 bg-gray-100 border border-gray-200 border-solid rounded",
            },
            [
              _vm._v("\n    " + _vm._s(_vm.customDid.prefijo) + "\n\n    "),
              _c(
                "span",
                {
                  staticClass: "absolute text-gray-400 cursor-pointer",
                  staticStyle: { top: "8px", right: "10px" },
                  on: { click: _vm.unselectCustomDId },
                },
                [_c("x-circle-icon", { staticClass: "h-5" })],
                1
              ),
            ]
          )
        : _c(
            "sw-select",
            {
              ref: "baseSelect",
              staticClass: "multi-select-item",
              attrs: {
                options: _vm.tollfree,
                loading: _vm.loading,
                "show-labels": false,
                "preserve-search": true,
                "initial-search": _vm.customDid.prefijo,
                invalid: _vm.invalid,
                placeholder: _vm.$t(
                  "corePbx.custom_did_groups.select_a_custom_did"
                ),
                label: "prefijo",
              },
              on: { value: _vm.onTextChange, select: _vm.onSelect },
              model: {
                value: _vm.customDidSelect,
                callback: function ($$v) {
                  _vm.customDidSelect = $$v
                },
                expression: "customDidSelect",
              },
            },
            [
              _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "flex items-center justify-center w-full p-3 bg-gray-200 border-none outline-none",
                    attrs: { type: "button" },
                    on: { click: _vm.openCustomModal },
                  },
                  [
                    _c("shopping-cart-icon", {
                      staticClass:
                        "h-5 mr-2 -ml-2 text-center text-primary-400",
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "ml-2 text-sm leading-none text-primary-400",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "corePbx.custom_did_groups.add_new_custom_did"
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }