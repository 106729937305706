var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "xl:pl-64": _vm.showSideBar } },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("corePbx.menu_title.tenants") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("corePbx.menu_title.tenants"),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/corePBX/packages`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.go_back")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-3 hidden xl:block" },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary-outline" },
                      on: { click: _vm.toggleListCustomers },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("tickets.departaments.menu")) +
                          "\n          "
                      ),
                      _c(_vm.listIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-x-left-transition",
        [
          _c("Sidebart-departaments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSideBar,
                expression: "showSideBar",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen && _vm.tenantsList.length === 0,
              expression: "showEmptyScreen && tenantsList.length === 0",
            },
          ],
          attrs: {
            title: _vm.$t("corePbx.no_custom_app_rate"),
            description: _vm.$t("corePbx.list_of_custom_app_rate"),
          },
        },
        [
          _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/corePBX/billing-templates/custom-app-rate/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("corePbx.add_custom_app_rate")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative table-container" },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.tenantsList.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalTenants))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.isRequestOngoing ? _c("base-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("general.name"),
                  show: "tenantid",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("general.name")))]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "font-medium text-primary-500" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.tenant.name) +
                                " (" +
                                _vm._s(row.code) +
                                ")\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("users.added_on"),
                  show: "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("users.added_on")))]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("formatDate")(row.created_at)) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("users.last_updated"),
                  show: "updated_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("users.last_updated"))),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("formatDate")(row.updated_at)) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("general.status"),
                  show: "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("general.status")))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _vm.permissionModule.update
                              ? _c("sw-checkbox", {
                                  staticClass: "mr-1",
                                  attrs: { "true-value": 1, "false-value": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateStatus(
                                        row.id,
                                        row.status
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.status,
                                    callback: function ($$v) {
                                      _vm.$set(row, "status", $$v)
                                    },
                                    expression: "row.status",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.status
                                    ? _vm.$t("general.recording")
                                    : _vm.$t("general.suspended")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "div",
                                  [
                                    row.status
                                      ? _c(
                                          "sw-button",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              variant: "primary-outline",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickImport(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.import")
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }