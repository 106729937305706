var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("roles.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "/" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("roles.title"), to: "/admin/roles" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("general.create"),
                  to: "/admin/roles/create",
                  active: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid gap-4 grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12 xl:col-span-4" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitRole.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-card",
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("roles.name"),
                        error: _vm.nameError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: { label: _vm.$t("items.description") },
                    },
                    [
                      _c("sw-textarea", {
                        attrs: { rows: "2", name: "description" },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.description.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-6 mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "flex justify-center w-full md:w-auto",
                          attrs: {
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          _c("save-icon", { staticClass: "mr-2 -ml-1" }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("general.update")
                                  : _vm.$t("general.save")
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-span-12 xl:col-span-8" },
          [
            _c("sw-card", [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-between items-center" },
                [
                  _c("h6", { staticClass: "sw-section-title mb-5" }, [
                    _vm._v(_vm._s(_vm.$t("roles.permissions"))),
                  ]),
                  _vm._v(" "),
                  _vm.moduleOptionsFilter.length !== 0
                    ? _c(
                        "sw-button",
                        {
                          attrs: { variant: "primary-outline", size: "sm" },
                          on: { click: _vm.addAllPermissions },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("roles.modules.add_all")) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticClass: "w-full text-center item-table table-permission",
                },
                [
                  _c(
                    "thead",
                    {
                      staticClass:
                        "border border-gray-200 border-solid theadClass",
                    },
                    [
                      _c(
                        "th",
                        {
                          staticClass: "py-3 px-2 text-sm text-gray-700",
                          staticStyle: { width: "70%" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.module")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "py-3 px-2 text-sm text-gray-700" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.modules.access")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "py-3 px-2 text-sm text-gray-700" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.modules.create")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "py-3 px-2 text-sm text-gray-700" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.modules.read")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "py-3 px-2 text-sm text-gray-700" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.modules.update")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "py-3 px-2 text-sm text-gray-700" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("roles.modules.delete")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", {
                        staticClass: "py-3 px-2 text-sm text-gray-700",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.formData.permissionss,
                      function (permission, indexTr) {
                        return _c(
                          "tr",
                          {
                            key: indexTr,
                            staticClass: "border border-gray-200 border-solid",
                          },
                          [
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center items-center p-3",
                                },
                                [
                                  _c(
                                    "sw-input-group",
                                    [
                                      _c("sw-select", {
                                        attrs: {
                                          options: _vm.moduleOptionsFilter,
                                          searchable: true,
                                          "show-labels": false,
                                          placeholder: _vm.$t("roles.module"),
                                          label: "label",
                                        },
                                        on: { input: _vm.addPermision },
                                        model: {
                                          value: permission.module,
                                          callback: function ($$v) {
                                            _vm.$set(permission, "module", $$v)
                                          },
                                          expression: "permission.module",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "flex justify-center pb-6" },
                                [
                                  _c("sw-switch", {
                                    model: {
                                      value: permission.access,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "access", $$v)
                                      },
                                      expression: "permission.access",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "flex justify-center pb-6" },
                                [
                                  _c("sw-switch", {
                                    model: {
                                      value: permission.create,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "create", $$v)
                                      },
                                      expression: "permission.create",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "flex justify-center pb-6" },
                                [
                                  _c("sw-switch", {
                                    model: {
                                      value: permission.read,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "read", $$v)
                                      },
                                      expression: "permission.read",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "flex justify-center pb-6" },
                                [
                                  _c("sw-switch", {
                                    model: {
                                      value: permission.update,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "update", $$v)
                                      },
                                      expression: "permission.update",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "flex justify-center pb-6" },
                                [
                                  _c("sw-switch", {
                                    model: {
                                      value: permission.delete,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "delete", $$v)
                                      },
                                      expression: "permission.delete",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              permission.module.value !== ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-center p-3 cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeModule(indexTr)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 text-gray-700",
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }