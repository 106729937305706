var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid gap-8 md:grid-cols-12" }, [
    _c(
      "div",
      { staticClass: "col-span-8 mt-12 md:col-span-4" },
      [
        _c(
          "div",
          { staticClass: "grid grid-cols-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: {
                  label: _vm.$t("reports.taxes.date_range"),
                  error: _vm.dateRangeError,
                },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.dateRange,
                    "allow-empty": false,
                    "show-labels": false,
                  },
                  on: { input: _vm.onChangeDateRange },
                  model: {
                    value: _vm.selectedRange,
                    callback: function ($$v) {
                      _vm.selectedRange = $$v
                    },
                    expression: "selectedRange",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2" },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("reports.taxes.from_date"),
                  error: _vm.fromDateError,
                },
              },
              [
                _c("sw-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.from_date.$error,
                    config: {
                      altInput: true,
                      enableTime: false,
                      time_24hr: false,
                      altFormat: "m/d/Y",
                      dateFormat: "Y-m-d",
                    },
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.from_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.from_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "from_date", $$v)
                    },
                    expression: "formData.from_date",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5 md:mt-0",
                attrs: {
                  label: _vm.$t("reports.taxes.to_date"),
                  error: _vm.toDateError,
                },
              },
              [
                _c("sw-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.to_date.$error,
                    config: {
                      altInput: true,
                      enableTime: false,
                      time_24hr: false,
                      altFormat: "m/d/Y",
                      dateFormat: "Y-m-d",
                    },
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.to_date.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.to_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "to_date", $$v)
                    },
                    expression: "formData.to_date",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", {
          staticClass: "mb-5 md:mb-8 gap-4",
          staticStyle: { "margin-top": "1em" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mt-6 md:mt-8" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: { label: _vm.$t("reports.sales.report_type") },
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.reportTypes,
                    "allow-empty": false,
                    "show-labels": false,
                    placeholder: _vm.$t("reports.sales.report_type"),
                  },
                  model: {
                    value: _vm.selectedType,
                    callback: function ($$v) {
                      _vm.selectedType = $$v
                    },
                    expression: "selectedType",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8 mt-5",
                attrs: { label: _vm.$t("reports.taxes.include_cdr") },
              },
              [
                _c("sw-checkbox", {
                  staticClass: "hidden md:inline",
                  attrs: { variant: "primary", size: "sm" },
                  model: {
                    value: _vm.include_cdr,
                    callback: function ($$v) {
                      _vm.include_cdr = $$v
                    },
                    expression: "include_cdr",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", {
          staticClass: "mb-5 md:mb-8 gap-4",
          staticStyle: { "margin-top": "1em" },
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "text-sm leading-snug text-black cursor-pointer",
            staticStyle: { float: "right", "margin-bottom": "1em" },
            on: { click: _vm.clearFilter },
          },
          [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.country") },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.countries,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_country"),
                    label: "name",
                    "track-by": "id",
                  },
                  on: {
                    select: function ($event) {
                      return _vm.countrySelected($event)
                    },
                  },
                  model: {
                    value: _vm.country,
                    callback: function ($$v) {
                      _vm.country = $$v
                    },
                    expression: "country",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.state") },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.states,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_state"),
                    label: "name",
                    "track-by": "id",
                  },
                  on: {
                    select: function ($event) {
                      return _vm.stateSelected($event)
                    },
                  },
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.county") },
              },
              [
                _c("sw-input", {
                  attrs: { name: "county", type: "text", autocomplete: false },
                  model: {
                    value: _vm.county,
                    callback: function ($$v) {
                      _vm.county = $$v
                    },
                    expression: "county",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.city") },
              },
              [
                _c("sw-input", {
                  attrs: { name: "city", type: "text", autocomplete: false },
                  model: {
                    value: _vm.city,
                    callback: function ($$v) {
                      _vm.city = $$v
                    },
                    expression: "city",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.category") },
              },
              [
                _vm.category
                  ? _c(
                      "span",
                      {
                        staticClass: "absolute text-gray-400 cursor-pointer",
                        staticStyle: {
                          top: "523px",
                          right: "28px",
                          "z-index": "999999",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCategory()
                          },
                        },
                      },
                      [_c("x-circle-icon", { staticClass: "h-5" })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("sw-select", {
                  attrs: {
                    options: _vm.categories,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_category"),
                    label: "name",
                    "track-by": "id",
                    "z-index": "9999",
                  },
                  on: {
                    select: function ($event) {
                      return _vm.categorySelected($event)
                    },
                  },
                  model: {
                    value: _vm.category,
                    callback: function ($$v) {
                      _vm.category = $$v
                    },
                    expression: "category",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.allow_invoice_form_pos
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "md:col-span-3",
                    attrs: { label: _vm.$t("core_pos.store") },
                  },
                  [
                    _c("sw-select", {
                      staticClass: "mt-2",
                      attrs: {
                        options: _vm.stores,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        "track-by": "id",
                        label: "name",
                        tabindex: 1,
                      },
                      model: {
                        value: _vm.stores_selected,
                        callback: function ($$v) {
                          _vm.stores_selected = $$v
                        },
                        expression: "stores_selected",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "md:col-span-3",
                attrs: { label: _vm.$t("customers.agency") },
              },
              [
                _vm.agency
                  ? _c(
                      "span",
                      {
                        staticClass: "absolute text-gray-400 cursor-pointer",
                        staticStyle: {
                          top: "81.1%",
                          right: "28px",
                          "z-index": "999999",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAgency()
                          },
                        },
                      },
                      [_c("x-circle-icon", { staticClass: "h-5" })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("sw-select", {
                  attrs: {
                    options: _vm.agencies,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": true,
                    placeholder: _vm.$t("general.select_agency"),
                    label: "name",
                    "track-by": "id",
                    "z-index": "9999",
                  },
                  on: {
                    select: function ($event) {
                      return _vm.agencySelected($event)
                    },
                  },
                  model: {
                    value: _vm.agency,
                    callback: function ($$v) {
                      _vm.agency = $$v
                    },
                    expression: "agency",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", {
          staticClass: "mb-5 md:mb-8 gap-4",
          staticStyle: { "margin-top": "1em" },
        }),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "content-center hidden mt-0 w-md md:flex md:mt-8",
            attrs: { variant: "primary-outline" },
            on: {
              click: function ($event) {
                return _vm.getReports()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("reports.update_report")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-span-8 mt-0" }, [
      _c(
        "div",
        { staticClass: "flex flex-nowrap justify-end w-full py-4" },
        [
          _c(
            "sw-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onDownloadCsv()
                },
              },
            },
            [
              _c("download-icon", { staticClass: "h-5 mr-1" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("general.download_csv")) +
                  "\n      "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("iframe", {
        staticClass:
          "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
        attrs: { src: _vm.getReportUrl },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500",
          on: { click: _vm.viewReportsPDF },
        },
        [
          _c("document-text-icon"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }