var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "option-group-create" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitItemGroup.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "sw-page-header",
              { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
              [
                _c(
                  "sw-breadcrumb",
                  { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                  [
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/dashboard",
                        title: _vm.$t("general.home"),
                      },
                    }),
                    _vm._v(" "),
                    _c("sw-breadcrumb-item", {
                      attrs: {
                        to: "/admin/item-groups",
                        title: _vm.$t("item_groups.item_group"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.$route.name === "item-groups.edit"
                      ? _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("item_groups.edit_items_group"),
                            active: "",
                          },
                        })
                      : _c("sw-breadcrumb-item", {
                          attrs: {
                            to: "#",
                            title: _vm.$t("item_groups.new_items_group"),
                            active: "",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "actions" },
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass: "mr-3 text-sm hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary-outline",
                          type: "button",
                          size: "lg",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelForm()
                          },
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.cancel")) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "hidden sm:flex",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                          tabindex: "6",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("item_groups.update_items_group")
                                : _vm.$t("item_groups.save_items_group")
                            ) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-12" }, [
              _c(
                "div",
                { staticClass: "col-span-12" },
                [
                  _c(
                    "sw-card",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("item_groups.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              tabindex: "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("general.category") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getItemCategories,
                              searchable: true,
                              "show-labels": false,
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select an Item Category",
                              tabindex: 10,
                              multiple: true,
                            },
                            model: {
                              value: _vm.formData.item_categories,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "item_categories", $$v)
                              },
                              expression: "formData.item_categories",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
                      _vm._v(" "),
                      _c(
                        "h6",
                        {
                          staticClass:
                            "col-span-5 sw-section-title lg:col-span-1",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("items.section_pos")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-switch", {
                              staticClass: "absolute",
                              staticStyle: { top: "-20px" },
                              attrs: { tabindex: 6 },
                              model: {
                                value: _vm.formData.allow_pos,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "allow_pos", $$v)
                                },
                                expression: "formData.allow_pos",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("items.allow_pos")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("core_pos.store") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.store_options,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "id",
                              label: "name",
                              tabindex: 1,
                            },
                            model: {
                              value: _vm.formData.item_store,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "item_store", $$v)
                              },
                              expression: "formData.item_store",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$tc("items.image") },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                              attrs: { id: "logo-box" },
                            },
                            [
                              _vm.previewPicture
                                ? _c("img", {
                                    staticClass:
                                      "absolute opacity-100 preview-logo",
                                    staticStyle: {
                                      "max-height": "80%",
                                      animation: "fadeIn 2s ease",
                                    },
                                    attrs: { src: _vm.previewPicture },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-col items-center",
                                    },
                                    [
                                      _c("cloud-upload-icon", {
                                        staticClass:
                                          "h-5 mb-2 text-xl leading-6 text-gray-400",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-xs leading-4 text-center text-gray-400",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Drag a file here or\n                  "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "cursor-pointer text-primary-500",
                                              attrs: { id: "pick-avatar" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    browse\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                  to choose a file\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("sw-avatar", {
                            attrs: {
                              trigger: "#logo-box",
                              "preview-avatar": _vm.previewPicture,
                            },
                            on: {
                              changed: _vm.onChange,
                              uploadHandler: _vm.onUploadHandler,
                              handleUploadError: _vm.onHandleUploadError,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("cloud-upload-icon", {
                                        staticClass:
                                          "h-5 mb-2 text-xl leading-6 text-gray-400",
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2788123755
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("item_groups.description"),
                            error: _vm.descriptionError,
                          },
                        },
                        [
                          _c("sw-editor", {
                            attrs: {
                              "set-editor": _vm.formData.description,
                              rows: "2",
                              name: "description",
                              tabindex: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex my-8" }, [
                        _c(
                          "div",
                          { staticClass: "relative w-12" },
                          [
                            _c("sw-checkbox", {
                              staticClass: "absolute",
                              attrs: { tabindex: "3" },
                              model: {
                                value: _vm.formData.no_taxable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "no_taxable", $$v)
                                },
                                expression: "formData.no_taxable",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 mb-1 text-base leading-snug text-black box-title",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("items.no_taxable")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "p-0 m-0 text-xs leading-4 text-gray-500",
                              staticStyle: { "max-width": "480px" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("items.no_tax_description")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    { staticClass: "w-full text-center item-table" },
                    [
                      _c("colgroup", [
                        _c("col", { staticStyle: { width: "60%" } }),
                        _vm._v(" "),
                        _c("col", { staticStyle: { width: "40%" } }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "thead",
                        {
                          staticClass:
                            "bg-white border border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pl-12" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$tc("items.item", 2)) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pr-10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("item_groups.item.price")) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "draggable",
                        {
                          staticClass: "item-body",
                          attrs: { tag: "tbody", handle: ".handle" },
                          model: {
                            value: _vm.formData.items,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "items", $$v)
                            },
                            expression: "formData.items",
                          },
                        },
                        _vm._l(_vm.formData.items, function (item, index) {
                          return _c("items-group-item", {
                            key: item.id,
                            attrs: {
                              index: index,
                              "item-data": item,
                              "group-items": _vm.formData.items,
                              currency: _vm.currency,
                            },
                            on: {
                              remove: _vm.removeItem,
                              update: _vm.updateItem,
                              itemValidate: _vm.checkItemsData,
                              checkExists: _vm.checkExistItem,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                      on: { click: _vm.addItem },
                    },
                    [
                      _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("item_groups.add_item")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary-outline",
                  type: "button",
                  size: "lg",
                },
                on: {
                  click: function ($event) {
                    return _vm.cancelForm()
                  },
                },
              },
              [
                !_vm.isLoading
                  ? _c("x-circle-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.cancel")) +
                    "\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                staticClass:
                  "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                  size: "lg",
                  tabindex: "6",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("item_groups.update_items_group")
                        : _vm.$t("item_groups.save_items_group")
                    ) +
                    "\n        "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }