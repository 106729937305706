export const SET_EXPENSES = 'SET_EXPENSES'
export const ADD_EXPENSE = 'ADD_EXPENSE'
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE'
export const SET_SELECT_ALL = 'SET_SELECT_ALL'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'
export const DELETE_MULTIPLE_EXPENSES = 'DELETE_MULTIPLE_EXPENSES'
export const SET_SELECTED_EXPENSES = 'SET_SELECTED_EXPENSES'
export const SET_TOTAL_EXPENSES = 'SET_TOTAL_EXPENSES'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_VALID_PAYMENT_METHODS = 'SET_VALID_PAYMENT_METHODS'
