var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex items-center justify-between mb-3" }, [
    _c(
      "div",
      { staticClass: "flex items-center", staticStyle: { flex: "4" } },
      [
        _c("sw-select", {
          attrs: {
            options: _vm.retentionsOptions,
            "allow-empty": false,
            "show-labels": false,
            placeholder: _vm.$t("settings.retentions.title"),
            "track-by": "id",
            label: "label",
          },
          on: { select: (val) => _vm.onSelectRetention(val) },
          scopedSlots: _vm._u([
            {
              key: "singleLabel",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "flex items-center" }, [
                    option.option.concept
                      ? _c("div", { staticClass: "text-sm" }, [
                          _vm._v(
                            _vm._s(
                              option.option.concept +
                                " " +
                                option.option.percentage +
                                "%"
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "flex items-center" }, [
                    option.option
                      ? _c("div", { staticClass: "text-sm" }, [
                          _vm._v(
                            _vm._s(
                              option.option.concept +
                                " " +
                                option.option.percentage +
                                "%"
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectedRetention,
            callback: function ($$v) {
              _vm.selectedRetention = $$v
            },
            expression: "selectedRetention",
          },
        }),
        _vm._v(" "),
        _c("br"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "text-sm text-right",
      staticStyle: { flex: "3" },
      domProps: {
        innerHTML: _vm._s(
          _vm.$utils.formatMoney(
            _vm.selectedRetention.retention_amount,
            _vm.currency
          )
        ),
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
      },
      [
        _vm.selectedRetention.id
          ? _c("trash-icon", {
              staticClass: "h-5 text-gray-700",
              on: { click: _vm.removeRetention },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }