var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "bg-white" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitConfigData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { attrs: { title: _vm.$t("customers.customer_config") } },
          [
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "mr-3 text-sm hidden sm:flex",
                    attrs: {
                      "tag-name": "router-link",
                      to: `/admin/customers/${_vm.$route.params.id}/view`,
                      variant: "primary-outline",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.back")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                    },
                  },
                  [
                    _c("save-icon", { staticClass: "mr-2 -ml-1" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.save")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c("customer-info"),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "my-6" }),
        _vm._v(" "),
        _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
          _c(
            "h6",
            { staticClass: "col-span-5 sw-section-title lg:col-span-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("customers.invoice_charge_options")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6 sm:grid-cols-1",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("customers.invoice_days_before_renewal"),
                    error: _vm.daysBeforeRenewal,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid:
                            _vm.$v.formData.invoice_days_before_renewal.$error,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.invoice_days_before_renewal.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.invoice_days_before_renewal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "invoice_days_before_renewal",
                              $$v
                            )
                          },
                          expression: "formData.invoice_days_before_renewal",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("customers.auto_debit_days_before_due_date"),
                    error: _vm.daysBeforeDue,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid:
                            _vm.$v.formData.auto_debit_days_before_due.$error,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.auto_debit_days_before_due.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.auto_debit_days_before_due,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "auto_debit_days_before_due",
                              $$v
                            )
                          },
                          expression: "formData.auto_debit_days_before_due",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("customers.suspend_services_days_after_due"),
                    error: _vm.suspendServiceDaysAfterDue,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid:
                            _vm.$v.formData.suspend_services_days_after_due
                              .$error,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.suspend_services_days_after_due.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.suspend_services_days_after_due,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "suspend_services_days_after_due",
                              $$v
                            )
                          },
                          expression:
                            "formData.suspend_services_days_after_due",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "md:col-span-3",
                  attrs: {
                    label: _vm.$t("customers.auto_debit_attempts"),
                    error: _vm.autoDebitAttemtps,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex", attrs: { role: "group" } },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.formData.auto_debit_attempts.$error,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.auto_debit_attempts.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.auto_debit_attempts,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "auto_debit_attempts", $$v)
                          },
                          expression: "formData.auto_debit_attempts",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-checkbox", {
                      staticClass: "absolute",
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.formData.enable_auto_debit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "enable_auto_debit", $$v)
                        },
                        expression: "formData.enable_auto_debit",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("customers.enable_auto_debit")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-checkbox", {
                      staticClass: "absolute",
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.formData.invoice_suspended_services,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "invoice_suspended_services",
                            $$v
                          )
                        },
                        expression: "formData.invoice_suspended_services",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("customers.invoice_suspended_services")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-checkbox", {
                      staticClass: "absolute",
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.formData.cancel_services,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "cancel_services", $$v)
                        },
                        expression: "formData.cancel_services",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("customers.cancel_services")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex md:col-span-3 my-4" }, [
                _c(
                  "div",
                  { staticClass: "relative w-12" },
                  [
                    _c("sw-checkbox", {
                      staticClass: "absolute",
                      on: { change: function ($event) {} },
                      model: {
                        value: _vm.formData.auto_apply_credits,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "auto_apply_credits", $$v)
                        },
                        expression: "formData.auto_apply_credits",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "p-0 mb-1 text-base leading-snug text-black box-title",
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("customers.auto_apply_credits")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "mr-3 flex w-full mt-4 sm:hidden md:hidden",
            attrs: {
              "tag-name": "router-link",
              to: `/admin/customers/${_vm.$route.params.id}/view`,
              variant: "primary-outline",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.back")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "flex w-full mt-4 mb-2 mb-md-0 sm:hidden md:hidden",
            attrs: {
              loading: _vm.isLoading,
              variant: "primary",
              type: "submit",
            },
          },
          [
            _c("save-icon", { staticClass: "mr-2 -ml-1" }),
            _vm._v("\n      " + _vm._s(_vm.$t("general.save")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }