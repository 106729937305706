var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("reports.cash_register.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "/admin/corePOS" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("core_pos.cash_registers"),
                  to: "cash-register",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "/admin/corePOS/cash-register",
                    size: "lg",
                    variant: "primary",
                  },
                },
                [
                  _c("plus-sm-icon", {
                    staticClass: "h-6 mr-1 -ml-2 font-bold",
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.back")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "col-span-8 mt-12 md:col-span-4" }, [
          _c(
            "div",
            { staticClass: "col-span-8 mt-12 md:col-span-4" },
            [
              _c(
                "div",
                { staticClass: "grid grid-cols-12 mb-2" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "col-span-12 md:col-span-8",
                      attrs: {
                        label: _vm.$t(
                          "reports.cash_register.report_cash_register"
                        ),
                      },
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-2",
                        attrs: {
                          options: _vm.cash_histories,
                          "allow-empty": false,
                          "show-labels": false,
                          "track-by": "id",
                          label: "formattedSelectReport",
                          multiple: false,
                        },
                        model: {
                          value: _vm.cash_historie_id,
                          callback: function ($$v) {
                            _vm.cash_historie_id = $$v
                          },
                          expression: "cash_historie_id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("reports.cash_register.info_select"))),
              ]),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass:
                    "content-center hidden mt-0 w-md md:flex md:mt-8",
                  attrs: { variant: "primary-outline" },
                  on: {
                    click: function ($event) {
                      return _vm.getReports()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("reports.update_report")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-span-8 mt-0 md:mt-12" }, [
          _c("iframe", {
            staticClass:
              "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
            attrs: { src: _vm.getReportUrl },
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500",
              on: { click: _vm.viewReportsPDF },
            },
            [
              _c("document-text-icon"),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }