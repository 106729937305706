var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "relative invoice-create-page" },
    [
      !_vm.isLoadingInvoice && !_vm.isLoadingData
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.pageTitle } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("general.home"),
                          to: "/admin/dashboard",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$tc("invoices.invoice", 2),
                          to: "/admin/invoices",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$route.name === "invoice.edit"
                        ? _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("invoices.edit_invoice"),
                              to: "#",
                              active: "",
                            },
                          })
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("invoices.new_invoice"),
                              to: "#",
                              active: "",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _vm.$route.name === "invoices.edit" &&
                      _vm.newInvoice.pbx_service_id != null &&
                      _vm.newInvoice.pbx_service_id != 0
                        ? _c(
                            "sw-button",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                disabled: _vm.isLoading,
                                href: `/admin/customers/${_vm.newInvoice.user_id}/pbx-service/${_vm.newInvoice.pbx_service_id}/view`,
                                "tag-name": "a",
                                variant: "primary-outline",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.pbx_service")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.name === "invoices.edit" &&
                      _vm.newInvoice.customer_packages_id != null &&
                      _vm.newInvoice.customer_packages_id != 0
                        ? _c(
                            "sw-button",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                disabled: _vm.isLoading,
                                href: `/admin/customers/${_vm.newInvoice.user_id}/service/${_vm.newInvoice.customer_packages_id}/view`,
                                "tag-name": "a",
                                variant: "primary-outline",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.service")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.name === "invoices.edit"
                        ? _c(
                            "sw-button",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                disabled: _vm.isLoading,
                                href: `/invoices/pdf/${_vm.newInvoice.unique_hash}`,
                                "tag-name": "a",
                                variant: "primary-outline",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.view_pdf")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          staticClass: "hidden md:relative md:flex",
                          class: !_vm.newInvoice.avalara_bool ? "mr-3" : "mr-4",
                          attrs: {
                            variant: "primary-outline",
                            type: "button",
                            size: "lg",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelForm()
                            },
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("x-circle-icon", {
                                staticClass: "w-6 h-6 mr-1 -ml-2",
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.cancel")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool
                        ? _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full lg:w-auto",
                              attrs: {
                                size: "lg",
                                variant: "primary-outline",
                                type: "button",
                              },
                              on: { click: _vm.changeButtonBool },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("invoices.invoices_avalara_tax")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool && _vm.buttonBool
                        ? _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full lg:w-auto ml-4",
                              style: _vm.newInvoice.save_as_draft
                                ? "background-color: #EC1111 !important;"
                                : "",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    !_vm.newInvoice.save_as_draft
                                      ? _vm.$t("invoices.save_invoice")
                                      : _vm.$t("invoices.save_draft_invoice")
                                  ) +
                                  "\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.newInvoice.avalara_bool
                        ? _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full lg:w-auto",
                              style: _vm.newInvoice.save_as_draft
                                ? "background-color: #EC1111 !important;"
                                : "",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    !_vm.newInvoice.save_as_draft
                                      ? _vm.$t("invoices.save_invoice")
                                      : _vm.$t("invoices.save_draft_invoice")
                                  ) +
                                  "\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activate_pay_button &&
                      !_vm.newInvoice.avalara_bool &&
                      !_vm.newInvoice.save_as_draft
                        ? _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full lg:w-auto ml-2",
                              style: _vm.newInvoice.save_as_draft
                                ? "background-color: #EC1111 !important;"
                                : "",
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                variant: "primary",
                                type: "button",
                                size: "lg",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.submitPayInvoice.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("invoices.pay_invoice")) +
                                  "\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-cols-12 gap-8 mt-6 mb-8 lg:grid" },
                [
                  _c("customer-select", {
                    staticClass: "col-span-5 pr-0",
                    attrs: {
                      valid: _vm.$v.selectedCustomer,
                      "customer-id": _vm.customerId,
                      avalara_bool: _vm.newInvoice.avalara_bool,
                      "is-avalara-invoice-active": _vm.isAvalaraInvoiceActive,
                      "is-edit-invoice": _vm.isEditInvoice,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 col-span-7 gap-4 mt-8 lg:gap-6 lg:mt-0 lg:grid-cols-2",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("invoices.invoice_date"),
                            error: _vm.invoiceDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newInvoice.invoice_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newInvoice.invoice_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "invoice_date", $$v)
                              },
                              expression: "newInvoice.invoice_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("invoices.due_date"),
                            error: _vm.dueDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.newInvoice.due_date.$error,
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newInvoice.due_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newInvoice.due_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "due_date", $$v)
                              },
                              expression: "newInvoice.due_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("invoices.invoice_number"),
                            error: _vm.invoiceNumError,
                            required: "",
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                prefix: `${_vm.invoicePrefix} - `,
                                invalid: _vm.$v.invoiceNumAttribute.$error,
                                disabled: _vm.isEdit,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.invoiceNumAttribute.$touch()
                                },
                              },
                              model: {
                                value: _vm.invoiceNumAttribute,
                                callback: function ($$v) {
                                  _vm.invoiceNumAttribute = $$v
                                },
                                expression: "invoiceNumAttribute",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("invoices.ref_number"),
                            error: _vm.referenceError,
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                invalid:
                                  _vm.$v.newInvoice.reference_number.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.newInvoice.reference_number.$touch()
                                },
                              },
                              model: {
                                value: _vm.newInvoice.reference_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newInvoice,
                                    "reference_number",
                                    $$v
                                  )
                                },
                                expression: "newInvoice.reference_number",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex gap-10" }, [
                _vm.isAvalaraAvailable
                  ? _c("div", [
                      _vm.isEnabledSwitchAvalara
                        ? _c("div", [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "relative w-12" },
                                [
                                  _c("sw-switch", {
                                    staticClass: "absolute",
                                    staticStyle: { top: "-18px" },
                                    attrs: {
                                      disabled: _vm.isAvalaraInvoiceActive == 1,
                                    },
                                    model: {
                                      value: _vm.newInvoice.avalara_bool,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newInvoice,
                                          "avalara_bool",
                                          $$v
                                        )
                                      },
                                      expression: "newInvoice.avalara_bool",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml-15" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 mb-1 text-base leading-snug text-black",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.avalara.switch_bool_name"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-0 m-0 text-xs leading-tight text-gray-500",
                                    staticStyle: { "max-width": "480px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.avalara.switch_bool_description"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "relative w-12" },
                    [
                      _c("sw-switch", {
                        staticClass: "absolute",
                        staticStyle: { top: "-18px" },
                        model: {
                          value: _vm.newInvoice.send_email,
                          callback: function ($$v) {
                            _vm.$set(_vm.newInvoice, "send_email", $$v)
                          },
                          expression: "newInvoice.send_email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-15" }, [
                    !_vm.isEdit
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.send_invoice")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.resend_invoice")) +
                                "\n          "
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 m-0 text-xs leading-tight text-gray-500",
                        staticStyle: { "max-width": "480px" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("invoices.confirm_send_invoice")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isSaveAsDraft
                  ? _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-18px" },
                            model: {
                              value: _vm.newInvoice.save_as_draft,
                              callback: function ($$v) {
                                _vm.$set(_vm.newInvoice, "save_as_draft", $$v)
                              },
                              expression: "newInvoice.save_as_draft",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-15" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "invoices.invoices_save_permanent_draft"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 m-0 text-xs leading-tight text-gray-500",
                            staticStyle: { "max-width": "480px" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "invoices.invoices_save_permanent_draft_desp"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasStatusCompleted
                  ? _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-18px" },
                            model: {
                              value: _vm.newInvoice.not_charge_automatically,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newInvoice,
                                  "not_charge_automatically",
                                  $$v
                                )
                              },
                              expression: "newInvoice.not_charge_automatically",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-15" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("invoices.invoices_nocharges")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 m-0 text-xs leading-tight text-gray-500",
                            staticStyle: { "max-width": "480px" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("invoices.invoices_nocharges_desp")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.isEdit &&
              _vm.newInvoice.avalara_bool &&
              !_vm.invoice_type_pbx_service
                ? _c("div", { staticClass: "flex gap-10" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "ml-15 py-2" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("invoices.invoices_avalara_warning")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isEdit &&
              _vm.invoice_type_pbx_service &&
              _vm.isAvalaraBundleActive
                ? _c("div", { staticClass: "flex gap-10" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "ml-15 py-2" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("invoices.invoices_avalara_bundle")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isEdit &&
              _vm.invoice_type_pbx_service &&
              !_vm.isAvalaraBundleActive &&
              !_vm.isAvalaraItemsActive
                ? _c("div", { staticClass: "flex gap-10" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "ml-15 py-2" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("invoices.invoices_avalara_items")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isEdit &&
              _vm.invoice_type_pbx_service &&
              !_vm.isAvalaraBundleActive &&
              _vm.isAvalaraItemsActive
                ? _c("div", { staticClass: "flex gap-10" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "ml-15 py-2" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "p-0 mb-1 text-base leading-snug text-black",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("invoices.invoices_avalara_warning2")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticStyle: { "min-width": "50rem" } }, [
                _c(
                  "table",
                  { staticClass: "w-full text-center item-table" },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "40%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                      _vm._v(" "),
                      _vm.discountPerItem === "YES"
                        ? _c("col", { staticStyle: { width: "15%" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("col", { staticStyle: { width: "15%" } }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "thead",
                      {
                        staticClass:
                          "bg-white border border-gray-200 border-solid",
                      },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pl-12" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$tc("items.item", 2)) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("invoices.item.quantity")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("invoices.item.price")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.discountPerItem === "YES"
                            ? _c(
                                "th",
                                {
                                  staticClass:
                                    "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("invoices.item.discount")) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                            },
                            [
                              _c("span", { staticClass: "pr-10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("invoices.item.total")) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "item-body",
                        attrs: { tag: "tbody", handle: ".handle" },
                        model: {
                          value: _vm.newInvoice.items,
                          callback: function ($$v) {
                            _vm.$set(_vm.newInvoice, "items", $$v)
                          },
                          expression: "newInvoice.items",
                        },
                      },
                      _vm._l(_vm.newInvoice.items, function (item, index) {
                        return _c("invoice-item", {
                          key: item.id,
                          attrs: {
                            "retention-bool": _vm.isRetentionInvoice,
                            index: index,
                            "item-data": item,
                            "invoice-items": _vm.newInvoice.items,
                            currency: _vm.currency,
                            "tax-per-item": _vm.taxPerItem,
                            retentionActive: _vm.retentionActive,
                            "avalara-is-taxable": _vm.newInvoice.avalara_bool,
                            "button-bool": _vm.buttonBool,
                            "is-edit-avalara": _vm.IsEditAvalara,
                            "invoice-pbx-service": _vm.invoice_type_pbx_service,
                            "discount-per-item": _vm.discountPerItem,
                            user: _vm.selectedCustomer,
                            "ban-type": _vm.newInvoice.pbx_service_id,
                          },
                          on: {
                            updatedButtonBool:
                              _vm.updatedButtonBoolCreateComponent,
                            remove: _vm.removeItem,
                            update: _vm.updateItem,
                            itemValidate: _vm.checkItemsData,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                  on: { click: _vm.addItem },
                },
                [
                  _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("invoices.add_item")) + "\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isEdit && _vm.isInvoiceLateFees
                ? _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("h3", { staticClass: "sw-page-title text-center" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("invoices.late_fee_title")) +
                            "\n      "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("sw-input-group", {
                        staticClass: "mb-1",
                        attrs: { label: _vm.$t("invoices.late_fee_warning") },
                      }),
                      _vm._v(" "),
                      _c(
                        "table",
                        { staticClass: "w-full text-center item-table" },
                        [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "15%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "10%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._v(" "),
                            _c("col", { staticStyle: { width: "10%" } }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "thead",
                            {
                              staticClass:
                                "bg-white border border-gray-200 border-solid",
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_og_amount")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_type")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_notice")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_subtotal")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.invoices_amounttaxes"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_total")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "py-2 px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("invoices.invoices_delete")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            { staticClass: "bg-white" },
                            _vm._l(
                              _vm.invoice_late_fees,
                              function (late_fee, i) {
                                return _c(
                                  "tr",
                                  { key: i, staticClass: "py-3" },
                                  [
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(late_fee.value) +
                                          "\n            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            late_fee.type == 0
                                              ? "Percentage"
                                              : "Fixed"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    late_fee.notice == "invoice_late_fee_one"
                                      ? _c("td", { staticClass: "px-5 py-2" }, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("invoices.invoices_one")
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    late_fee.notice == "invoice_late_fee_two"
                                      ? _c("td", { staticClass: "px-5 py-2" }, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("invoices.invoices_two")
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    late_fee.notice == "invoice_late_fee_three"
                                      ? _c("td", { staticClass: "px-5 py-2" }, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.invoices_three"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "px-5 py-2" },
                                      [
                                        _c("sw-money", {
                                          attrs: {
                                            value: _vm.formatSubtotal(
                                              late_fee.subtotal
                                            ),
                                            currency:
                                              _vm.defaultCurrencyForInput,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.updateSubtotal(
                                                i,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              late_fee.tax_amount,
                                              _vm.currency
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              late_fee.total,
                                              _vm.currency
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                                        },
                                        [
                                          _c("trash-icon", {
                                            staticClass: "h-5 text-gray-700",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeLateFee(
                                                  late_fee.id
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              this.newInvoice.pbx_service_id != null
                ? _c(
                    "div",
                    {
                      staticClass: "w-full item-table mt-8",
                      staticStyle: { "min-width": "50rem" },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "bg-white rounded shadow px-4 py-5 sm:px-8 sm:py-4",
                          },
                          [
                            _c(
                              "h3",
                              { staticClass: "sw-page-title text-center" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "invoices.pbx_services.title_service_detail"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("sw-input-group", {
                              staticClass: "mb-1",
                              attrs: {
                                label: _vm.$t("invoices.pbx_services.package"),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "w-full text-center item-table" },
                              [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "25%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "25%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "25%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "25%" } }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "bg-white border border-gray-200 border-solid",
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "invoices.pbx_services.service",
                                                    2
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.pbx_services.service_id"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("invoices.item.price")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.item.inclusive_minutes"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", { staticClass: "py-3" }, [
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            this.info_pbx_pack.pbx_package_name
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            this.info_pbx_pack.packages_number
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "px-10 py-2" },
                                      [
                                        _c("sw-money", {
                                          staticClass:
                                            "focus:border focus:border-solid focus:border-primary",
                                          attrs: {
                                            currency:
                                              _vm.defaultCurrencyForInput,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.modifManual()
                                            },
                                          },
                                          model: {
                                            value: _vm.packagesPrice,
                                            callback: function ($$v) {
                                              _vm.packagesPrice = _vm._n($$v)
                                            },
                                            expression: "packagesPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "px-5 py-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            this.info_pbx_pack.inclusive_minutes
                                              ? _vm.is_decimal(
                                                  this.info_pbx_pack
                                                    .inclusive_minutes
                                                )
                                              : 0
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isAvalaraBundleActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: { label: "Avalara Bundle" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_status"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_enable"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.bundleTransaction
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.bundleService
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAvalaraServicePriceItemActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: { label: "Service Price Item" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_name"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.service_price_item_name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_type_item_service
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_service_type_item_service
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("sw-input-group", {
                              staticClass: "mb-1",
                              attrs: {
                                label: _vm.$t(
                                  "invoices.pbx_services.extension_templates"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "w-full text-center item-table" },
                              [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "40%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "40%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "bg-white border border-gray-200 border-solid",
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "invoices.pbx_services.name",
                                                    2
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("invoices.item.price")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("invoices.total")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    !this.info_pbx_pack.profile_extensions
                                      ? _c("tr", { staticClass: "py-3" }, [
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.info_exts,
                                          function (item, i) {
                                            return _c(
                                              "tr",
                                              { key: i, staticClass: "py-3" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "flex px-5 py-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(`${item[4]} (`) +
                                                        "\n                  "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "20%",
                                                        },
                                                      },
                                                      [
                                                        _c("sw-input", {
                                                          attrs: {
                                                            step: "any",
                                                            type: "number",
                                                            min: "0",
                                                            onkeydown:
                                                              "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              _vm.editExtensionsDetail(
                                                                i,
                                                                1,
                                                                item[1],
                                                                /^[0-9]+([,.][0-9]+)?$/g
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item[1],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                1,
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "item[1]",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(`)`) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-20 py-2" },
                                                  [
                                                    _c("sw-input", {
                                                      attrs: {
                                                        step: "any",
                                                        type: "number",
                                                        min: "0",
                                                        onkeydown:
                                                          "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.editExtensionsDetail(
                                                            i,
                                                            2,
                                                            item[2],
                                                            /^[0-9]+([.][0-9]+)?$/g
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item[2],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            2,
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression: "item[2]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.defaultCurrency
                                                            .symbol
                                                        ) +
                                                        "\n                  " +
                                                        _vm._s(
                                                          _vm.is_decimal(
                                                            item[3]
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isAvalaraExtensionActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        label:
                                          "Avalara Extension Configuration",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_name"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.extension_item_name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_type_item_extension
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_service_type_item_extension
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("sw-input-group", {
                              staticClass: "mb-1",
                              attrs: {
                                label: _vm.$t(
                                  "invoices.pbx_services.did_templates"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "w-full text-center item-table" },
                              [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "40%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "40%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "bg-white border border-gray-200 border-solid",
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "invoices.pbx_services.name",
                                                    2
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("invoices.item.price")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("invoices.total")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    !this.info_pbx_pack.profile_did &&
                                    !this.info_did
                                      ? _c("tr", { staticClass: "py-3" }, [
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.info_did,
                                          function (item, i) {
                                            return _c(
                                              "tr",
                                              { key: i, staticClass: "py-3" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "flex px-5 py-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(`${item[4]} (`) +
                                                        "\n                  "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "20%",
                                                        },
                                                      },
                                                      [
                                                        _c("sw-input", {
                                                          attrs: {
                                                            step: "any",
                                                            type: "number",
                                                            min: "0",
                                                            onkeydown:
                                                              "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              _vm.editServicesDetail(
                                                                i,
                                                                1,
                                                                item[1],
                                                                /^[0-9]+([,.][0-9]+)?$/g
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item[1],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                1,
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "item[1]",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(`)`) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-20 py-2" },
                                                  [
                                                    _c("sw-input", {
                                                      attrs: {
                                                        step: "any",
                                                        type: "number",
                                                        min: "0",
                                                        onkeydown:
                                                          "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.editServicesDetail(
                                                            i,
                                                            2,
                                                            item[2],
                                                            /^[0-9]+([.][0-9]+)?$/g
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item[2],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            2,
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression: "item[2]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.defaultCurrency
                                                            .symbol
                                                        ) +
                                                        "\n                  " +
                                                        _vm._s(
                                                          _vm.is_decimal(
                                                            item[3]
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isAvalaraDidActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        label: "Avalara Did Configuration",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_name"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.did_item_name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_type_item_did
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_service_type_item_did
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("sw-input-group", {
                              staticClass: "mb-1",
                              attrs: {
                                label: _vm.$t(
                                  "invoices.pbx_services.addicional_charges"
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "table",
                              { staticClass: "w-full text-center item-table" },
                              [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "30%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "10%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _vm._v(" "),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "bg-white border border-gray-200 border-solid",
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "invoices.pbx_services.name",
                                                    2
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.pbx_services.type"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoices.pbx_services.quantity"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("invoices.item.price")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("invoices.item.total")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    !this.addicional_charges_extension
                                      ? _c("tr", { staticClass: "py-3" }, [
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "px-5 py-2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s("None") +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._l(
                                          this.addicional_charges_extension,
                                          function (d) {
                                            return _c(
                                              "tr",
                                              {
                                                key: d.id,
                                                staticClass: "py-3",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          d.additional_charge_name
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          d.additional_charge_type
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(d.qty) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _c("sw-input", {
                                                      attrs: {
                                                        step: "any",
                                                        type: "number",
                                                        min: "0",
                                                        onkeydown:
                                                          "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.editadditionalCharges(),
                                                            _vm.modifManual()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          d.additional_charge_amount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            d,
                                                            "additional_charge_amount",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "d.additional_charge_amount",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-5 py-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.defaultCurrency
                                                            .symbol
                                                        ) +
                                                        "\n                  " +
                                                        _vm._s(
                                                          d.qty *
                                                            d.additional_charge_amount
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isAvalaraAdditionalChargesActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        label:
                                          "Avalara Additional Charges Configuration",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_name"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.additional_charges_item_name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_type_item_additional_charges
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_service_type_item_additional_charges
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-4" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.pbx_services.rating_duration"
                                    )
                                  ) + ":"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(_vm._s(this.totalDuration)),
                              ]),
                              _vm._v(" "),
                              _vm.isAvalaraCallRatingActive
                                ? _c("span", { staticClass: "ml-4" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.invoices_avalara_warning3"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm.pbxServiceAllowCustomapp
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mb-4 bg-white border border-gray-200 border-solid rounded p-8 relative package-details",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(_vm._s("Custom App Rate")),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "sw-table-component",
                                      {
                                        ref: "table",
                                        attrs: {
                                          data: _vm.pbxServicesAppRate,
                                          "show-filter": false,
                                          "table-class": "table",
                                        },
                                      },
                                      [
                                        _c("sw-table-column", {
                                          attrs: {
                                            sortable: true,
                                            label: "APPS",
                                            show: "app_name",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (row) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s("APPS")),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(row.app_name) +
                                                          "\n                  "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            749759583
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("sw-table-column", {
                                          attrs: {
                                            sortable: true,
                                            label: "QUANTITY",
                                            show: "quantity",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (row) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s("QUANTITY")
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("sw-input", {
                                                      staticClass:
                                                        "rounded-tr-sm rounded-br-sm",
                                                      staticStyle: {
                                                        width: "100px",
                                                      },
                                                      attrs: {
                                                        type: "number",
                                                        min: "0",
                                                        max: row.quantityDefault,
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateQuantityCustomApp(
                                                            row
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: row.quantity,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "quantity",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.quantity",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1165341127
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("sw-table-column", {
                                          attrs: {
                                            sortable: true,
                                            label: "PRICE",
                                            show: "price",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (row) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s("PRICE")),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("sw-money", {
                                                      staticClass:
                                                        "focus:border focus:border-solid focus:border-primary",
                                                      attrs: {
                                                        currency:
                                                          _vm.defaultCurrencyForInput,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.updatePriceCustomApp(
                                                            row
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: row.price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "price",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "row.price",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            831571816
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("sw-table-column", {
                                          attrs: {
                                            sortable: true,
                                            label: "AMOUNT",
                                            show: "costo",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (row) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s("AMOUNT")),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$utils.formatMoney(
                                                            row.costo,
                                                            _vm.defaultCurrency
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2793394166
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAvalaraCustomAppRateActive
                              ? _c(
                                  "div",
                                  [
                                    _c("sw-input-group", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        label:
                                          "Avalara Custom App Rate Configuration",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "w-full text-center item-table",
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "40%" },
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "20%" },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "thead",
                                          {
                                            staticClass:
                                              "bg-white border border-gray-200 border-solid",
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.invoices_name"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_transaction_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoices.invoices_service_number"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", { staticClass: "py-3" }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.custom_app_rate_item_name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_type_item_custom_app_rate
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "px-5 py-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.avalara_service_type_item_custom_app_rate
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isEdit &&
                            _vm.buttonBool &&
                            _vm.avalara_taxes.length > 0
                              ? _c("div", [
                                  _c("hr", { staticClass: "mt-3" }),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass: "items-table mt-2",
                                      attrs: {
                                        width: "100%",
                                        cellspacing: "0",
                                        border: "0",
                                      },
                                    },
                                    [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "item-table-title",
                                            attrs: { colspan: "5" },
                                          },
                                          [
                                            _c("sw-input-group", {
                                              staticClass: "mb-1",
                                              attrs: {
                                                label:
                                                  "Avalara taxes for this service",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tr",
                                        {
                                          staticClass:
                                            "item-table-heading-row bg-white border border-gray-200 border-solid",
                                        },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-4 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                              attrs: { width: "2%" },
                                            },
                                            [_c("span", [_vm._v(" # ")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid pl-0 text-left item-table-heading",
                                              attrs: { width: "75%" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("general.taxes")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid text-center item-table-heading",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("general.price")
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.avalara_taxes,
                                        function (taxes, i) {
                                          return _c(
                                            "tr",
                                            { key: i, staticClass: "item-row" },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-11 pr-12 text-right item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(i + 1) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-0 text-left item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(taxes.ref) +
                                                        " "
                                                    ),
                                                    _c("br"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    taxes.txs,
                                                    function (tx) {
                                                      return taxes.txs.length >
                                                        0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-sm not-italic font-medium leading-5 text-gray-700",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    tx.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$utils.formatMoney(
                                                          taxes.total * 100,
                                                          _vm.currency
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isEdit &&
                            _vm.avalara_taxes.length > 0 &&
                            _vm.buttonBool
                              ? _c("div", [
                                  _c("hr", { staticClass: "mt-3" }),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass: "items-table mt-2",
                                      attrs: {
                                        width: "100%",
                                        cellspacing: "0",
                                        border: "0",
                                      },
                                    },
                                    [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "item-table-title",
                                            attrs: { colspan: "5" },
                                          },
                                          [
                                            _c("sw-input-group", {
                                              staticClass: "mb-1",
                                              attrs: {
                                                label:
                                                  "Avalara taxes for this service",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tr",
                                        {
                                          staticClass:
                                            "item-table-heading-row bg-white border border-gray-200 border-solid",
                                        },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid",
                                              attrs: { width: "2%" },
                                            },
                                            [_c("span", [_vm._v(" # ")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-4 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid pl-0 text-left item-table-heading",
                                              attrs: { width: "75%" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("general.taxes")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "px-5 text-sm not-italic font-medium leading-5 text-gray-700 border-t border-b border-gray-200 border-solid text-center item-table-heading",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("general.price")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.avalara_taxes,
                                        function (taxes, i) {
                                          return _c(
                                            "tr",
                                            { key: i, staticClass: "item-row" },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-11 pr-12 text-right item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(i + 1) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-0 text-left item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(taxes.ref) +
                                                        " "
                                                    ),
                                                    _c("br"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    taxes.txs,
                                                    function (tx) {
                                                      return taxes.txs.length >
                                                        0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-sm not-italic font-medium leading-5 text-gray-700",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    tx.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center item-cell",
                                                  staticStyle: {
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$utils.formatMoney(
                                                          taxes.total * 100,
                                                          _vm.currency
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.allow_invoice_form_pos
                ? _c("div", { staticClass: "flex justify-end my-8" }, [
                    _c(
                      "div",
                      { staticClass: "w-12" },
                      [
                        _c("sw-checkbox", {
                          attrs: { tabindex: "5" },
                          model: {
                            value: _vm.newInvoice.is_pdf_pos,
                            callback: function ($$v) {
                              _vm.$set(_vm.newInvoice, "is_pdf_pos", $$v)
                            },
                            expression: "newInvoice.is_pdf_pos",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "p-0 mb-1 text-base leading-snug text-black box-title",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("invoices.generate_pdf_pos")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.allow_invoice_form_pos
                ? _c("div", { staticClass: "flex justify-end my-8" }, [
                    _c("div", { staticClass: "items-center w-60 mt-2" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-xs font-semibold leading-5 text-gray-500 uppercase",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("core_pos.tips")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { width: "100%" },
                          attrs: { role: "group" },
                        },
                        [
                          _c("sw-input", {
                            staticClass:
                              "border-r-0 rounded-tr-sm rounded-br-sm",
                            model: {
                              value: _vm.tip,
                              callback: function ($$v) {
                                _vm.tip = $$v
                              },
                              expression: "tip",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown",
                            { attrs: { position: "bottom-end" } },
                            [
                              _c(
                                "sw-button",
                                {
                                  staticStyle: { height: "43px" },
                                  attrs: {
                                    slot: "activator",
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    size: "tip",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                    variant: "white",
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "flex" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.newInvoice.tip_type == "fixed"
                                              ? "$"
                                              : "%"
                                          ) +
                                          "\n                "
                                      ),
                                      _c("chevron-down-icon", {
                                        staticClass: "h-5",
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectFixedTip } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.fixed")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.selectPercentageTip } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("general.percentage")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "block my-10 invoice-foot lg:justify-between lg:flex lg:items-start",
                },
                [
                  _c("div", { staticClass: "w-full lg:w-1/2" }, [
                    _c(
                      "div",
                      { staticClass: "mb-6" },
                      [
                        _c(
                          "sw-popup",
                          {
                            ref: "notePopup",
                            staticClass:
                              "z-10 text-sm font-semibold leading-5 text-primary-400",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "float-right mt-1",
                                attrs: { slot: "activator" },
                                slot: "activator",
                              },
                              [
                                _vm._v(
                                  "\n              + " +
                                    _vm._s(_vm.$t("general.insert_note")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("note-select-popup", {
                              attrs: { type: "Invoice" },
                              on: { select: _vm.onSelectNote },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          { attrs: { label: _vm.$t("invoices.notes") } },
                          [
                            _c("base-custom-input", {
                              attrs: { fields: _vm.InvoiceFields },
                              model: {
                                value: _vm.newInvoice.notes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newInvoice, "notes", $$v)
                                },
                                expression: "newInvoice.notes",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.customFields.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grid gap-x-4 gap-y-2 md:gap-x-8 md:gap-y-4 grid-col-1 md:grid-cols-2",
                          },
                          _vm._l(_vm.customFields, function (field, index) {
                            return _c(
                              "sw-input-group",
                              {
                                key: index,
                                attrs: {
                                  label: field.label,
                                  required: field.is_required ? true : false,
                                },
                              },
                              [
                                _c(field.type + "Field", {
                                  tag: "component",
                                  attrs: {
                                    type: field.type.label,
                                    field: field,
                                    "is-edit": _vm.isEdit,
                                    "invalid-fields": _vm.invalidFields,
                                  },
                                  on: { update: _vm.setCustomFieldValue },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0",
                    },
                    [
                      _vm.packagesPrice > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("invoices.packages_price")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.packagesPrice * 100,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.TotalItem > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("customers.total_items")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.TotalItem,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.TotalServicesExtension > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("invoices.total_extension")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.TotalServicesExtension,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.TotalServicesDid > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total_did")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.TotalServicesDid,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.TotalAddicionalCharges > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("invoices.addicional_charges")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.TotalAddicionalCharges,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pbxServiceAllowCustomapp
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s("Custom App Rate") +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.totalAppRate,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.call_detail_register_total > 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between w-full",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.pbx_services.total_cdr"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex items-center justify-center m-0 text-lg text-black uppercase",
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.newInvoice
                                              .call_detail_register_total * 100,
                                            _vm.currency
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-start w-full mb-2",
                              },
                              [
                                _c(
                                  "sw-badge",
                                  {
                                    attrs: {
                                      "bg-color":
                                        _vm.$utils.getBadgeStatusColor(
                                          _vm.isCustomerPostpaid ? "A" : "I"
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        _vm.isCustomerPostpaid ? "A" : "I"
                                      ).color,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.isCustomerPostpaid
                                            ? _vm.$t(
                                                "invoices.pbx_services.including"
                                              )
                                            : _vm.$t(
                                                "invoices.pbx_services.no_including"
                                              )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("invoices.sub_total")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.subtotalWithTotalCDR,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.allTaxes, function (tax) {
                        return _c(
                          "div",
                          {
                            key: tax.tax_type_id,
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(tax.name) +
                                    " - " +
                                    _vm._s(tax.percent) +
                                    "%\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        tax.amount,
                                        _vm.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      _vm.buttonBool &&
                      this.selectedCustomer != null &&
                      !_vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v("Avalara Tax\n          ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                  staticStyle: { "font-size": "18px" },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.totalAvalaraTax,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      _vm.buttonBool &&
                      this.selectedCustomer != null &&
                      _vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v("Avalara Tax\n          ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                  staticStyle: { "font-size": "18px" },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.totalAvalaraTax * 100,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.discountPerItem === "NO" ||
                      _vm.discountPerItem === null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mt-2",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.discount")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  staticStyle: { width: "105px" },
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass:
                                      "border-r-0 rounded-tr-sm rounded-br-sm",
                                    attrs: {
                                      invalid:
                                        _vm.$v.newInvoice.discount_val.$error,
                                      onkeydown:
                                        "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.newInvoice.discount_val.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.discount,
                                      callback: function ($$v) {
                                        _vm.discount = $$v
                                      },
                                      expression: "discount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown",
                                    { attrs: { position: "bottom-end" } },
                                    [
                                      _c(
                                        "sw-button",
                                        {
                                          ref: "button",
                                          staticStyle: { height: "43 px" },
                                          attrs: {
                                            slot: "activator",
                                            type: "button",
                                            "data-toggle": "dropdown",
                                            size: "discount",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false",
                                            variant: "white",
                                            tabindex: "0",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dropdownSelect("click")
                                            },
                                            focusout: function ($event) {
                                              return _vm.dropdownSelect(
                                                "focusOut"
                                              )
                                            },
                                          },
                                          slot: "activator",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "flex" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.newInvoice
                                                      .discount_type == "fixed"
                                                      ? _vm.currency.symbol
                                                      : "%"
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("chevron-down-icon", {
                                                staticClass: "h-5",
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown-item",
                                        { on: { click: _vm.selectFixed } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("general.fixed")) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown-item",
                                        { on: { click: _vm.selectPercentage } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("general.percentage")
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("invoices.discount_app"))),
                      ]),
                      _vm._v(" "),
                      _vm.discount > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mt-2 flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                  staticStyle: { "font-style": "italic" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "invoices.invoices_discountapplied"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    staticClass: "mr-1",
                                    staticStyle: { "font-style": "italic" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.newInvoice.discount_val,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          style: _vm.activeDropdownDiscount
                            ? "margin-top: 30%"
                            : "",
                        },
                        [
                          (_vm.taxPerItem ? "NO" : null)
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.newInvoice.taxes,
                                  function (tax, index) {
                                    return _c("tax", {
                                      key: tax.id,
                                      attrs: {
                                        index: index,
                                        total: _vm.subtotalWithDiscount,
                                        tax: tax,
                                        taxes: _vm.newInvoice.taxes,
                                        currency: _vm.currency,
                                        "total-tax": _vm.totalSimpleTax,
                                      },
                                      on: {
                                        remove: _vm.removeInvoiceTax,
                                        update: _vm.updateTax,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                            ? _c(
                                "sw-popup",
                                {
                                  ref: "taxModal",
                                  staticClass:
                                    "my-3 text-sm font-semibold leading-5 text-primary-400",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "float-right pt-2 pb-5",
                                      attrs: { slot: "activator" },
                                      slot: "activator",
                                    },
                                    [
                                      _vm._v(
                                        "\n              + " +
                                          _vm._s(_vm.$t("invoices.add_tax")) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("tax-select-popup", {
                                    attrs: { taxes: _vm.newInvoice.taxes },
                                    on: { select: _vm.onSelectTax },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.newInvoice.tax_types_cdr &&
                      _vm.newInvoice.tax_types_cdr.length > 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between w-full",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("invoices.tax_CDR")) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-start w-full mb-2",
                                },
                                [
                                  _c(
                                    "sw-badge",
                                    {
                                      attrs: {
                                        "bg-color":
                                          _vm.$utils.getBadgeStatusColor(
                                            _vm.isCustomerPostpaid ? "A" : "I"
                                          ).bgColor,
                                        color: _vm.$utils.getBadgeStatusColor(
                                          _vm.isCustomerPostpaid ? "A" : "I"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.isCustomerPostpaid
                                              ? _vm.$t(
                                                  "invoices.pbx_services.including"
                                                )
                                              : _vm.$t(
                                                  "invoices.pbx_services.no_including"
                                                )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.newInvoice.tax_types_cdr,
                                function (tax, indexTr) {
                                  return _c(
                                    "div",
                                    {
                                      key: indexTr,
                                      staticClass:
                                        "flex items-center justify-between w-full",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(tax.name) +
                                              " - " +
                                              _vm._s(tax.percent) +
                                              "%\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "flex items-center justify-center m-0 text-lg text-black uppercase",
                                          staticStyle: { "font-size": "18px" },
                                        },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  tax.amount,
                                                  _vm.currencyFiveDecimal
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.retentionsTotal > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.retentions_total"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.retentionsTotal,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      this.late_fee_total > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.late_fee_total")) +
                                      ":"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center m-0 text-lg text-black uppercase",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          this.late_fee_total,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.newInvoice.avalara_bool && !_vm.buttonBool
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total")) +
                                      " " +
                                      _vm._s(_vm.$t("invoices.amount")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      !_vm.buttonBool &&
                      !_vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total")) +
                                      " " +
                                      _vm._s(_vm.$t("invoices.amount")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      _vm.buttonBool &&
                      !_vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total")) +
                                      " " +
                                      _vm._s(_vm.$t("invoices.amount")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total + _vm.totalAvalaraTax,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      !_vm.buttonBool &&
                      _vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total")) +
                                      " " +
                                      _vm._s(_vm.$t("invoices.amount")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newInvoice.avalara_bool &&
                      _vm.buttonBool &&
                      _vm.invoice_type_pbx_service
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("invoices.total")) +
                                      " " +
                                      _vm._s(_vm.$t("invoices.amount")) +
                                      ":\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex items-center justify-center text-lg uppercase text-primary-400",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          _vm.total + _vm.totalAvalaraTax * 100,
                                          _vm.currency
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }