var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "payments" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
        [
          _c("sw-page-header", {
            attrs: { title: _vm.$t("customer_notes.title") },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                  attrs: {
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/view`,
                    variant: "primary-outline",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("customer_notes.clientgoback")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.permissionModule.create
                ? _c(
                    "sw-button",
                    {
                      staticClass: "w-full md:w-auto md:ml-4 mb-2 md:mb-0",
                      attrs: {
                        "tag-name": "router-link",
                        to: `/admin/customers/${_vm.$route.params.id}/add-note`,
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("customer_notes.create_note")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmptyScreen
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("customer_notes.no_customer_notes"),
                description: _vm.$t("customer_notes.list_of_customer_notes"),
              },
            },
            [
              _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: `/admin/customers/${_vm.$route.params.id}/add-note`,
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("customer_notes.new_note")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [_c("p", { staticClass: "text-sm" })]
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  label: _vm.$t("customer_notes.summary"),
                  show: "summary",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_notes.summary"))),
                        ]),
                        _vm._v(
                          "\n          " + _vm._s(row.summary) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  label: _vm.$t("customer_notes.creator"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_notes.creator"))),
                        ]),
                        _vm._v(
                          "\n          " + _vm._s(row.name) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  label: _vm.$t("customer_notes.pinned"),
                  show: "stiky",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customer_notes.pinned"))),
                        ]),
                        _vm._v(" "),
                        row.stiky === 1
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-green-100 text-green-800 text-center px-2 py-1 rounded-full inline-block",
                              },
                              [_vm._v("Yes")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.stiky === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-red-100 text-red-800 text-center px-2 py-1 rounded-full inline-block",
                              },
                              [_vm._v("No")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: false,
                  label: _vm.$t("payments.date"),
                  "sort-as": "created_at",
                  show: "formattedCustomerNoteDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _vm.permissionModule.update
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      "tag-name": "router-link",
                                      to: `${row.id}/edit-note`,
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.read
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    attrs: {
                                      to: `${row.id}/view-note`,
                                      "tag-name": "router-link",
                                    },
                                  },
                                  [
                                    _c("eye-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.permissionModule.delete
                              ? _c(
                                  "sw-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePayment(row.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "h-5 mr-3 text-gray-600",
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }