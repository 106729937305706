var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("base-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "p-6 grid md:grid-cols-2 gap-4 mb-1" }, [
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.country")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.dataModal.country ? _vm.dataModal.country.name : ""
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.state")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.dataModal.state ? _vm.dataModal.state.name : "") +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.company_info.city")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.dataModal.city || "") + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "mb-1 text-sm font-bold" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("avalara.pcode")) + "\n      "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.dataModal.pcode || "") + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "sw-button",
              {
                attrs: { variant: "primary" },
                on: { click: _vm.showModalException },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("avalara.add_exemptions")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "overflow-auto h-80" },
        [
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.exemptionsCategorys,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$tc("avalara.exemption_name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("avalara.exemption_name"))),
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              row.exemption_name !== null
                                ? row.exemption_name
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("avalara.exemption_scope"),
                  show: "scp",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("avalara.exemption_scope"))),
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              row.scp !== null ? _vm.scpOptions[row.scp] : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  label: _vm.$t("avalara.exempt_non-billable"),
                  show: "exnb",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("avalara.exempt_non-billable"))),
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              row.exnb !== null ? _vm.exnbOptions[row.exnb] : ""
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: false, label: _vm.$t("general.actions") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("general.actions")))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("sw-switch", {
                              on: {
                                change: function ($event) {
                                  return _vm.changeExemptionStatus(row)
                                },
                              },
                              model: {
                                value: row.enable,
                                callback: function ($$v) {
                                  _vm.$set(row, "enable", $$v)
                                },
                                expression: "row.enable",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg",
        },
        [
          _c(
            "sw-button",
            {
              staticClass: "mr-3 text-sm",
              attrs: { type: "button", variant: "primary-outline" },
              on: { click: _vm.closeAvalaraLocationsModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.close")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CustomerAvalaraCategoryExemptionsModalList", {
        ref: "CustomerAvalaraCategoryExemptionsRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }