<template>
  <div>
    <sw-card>
      <div class="grid grid-cols-12 gap-4 mt-1 mb-2">
        <div class="col-span-7">
          <span class="bold">{{ $t('payments.select_invoices_to_pay') }}</span>
        </div>
        <div class="col-span-5">
          <sw-input-group>
            <sw-select
              v-model="formDataStatus.status"
              :options="statusOption"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('payments.payment_method_type')"
              label="name"
              class="mt-1 w-full"
              @input="filterInvoiceStatus"
              required
            >
            </sw-select>
          </sw-input-group>
        </div>
      </div>

      <div class="col-span-12">
        <div class="space-y-2 max-h-40 overflow-y-auto">
          <div
            v-for="(invoice, index) in showInvoiceList"
            :key="invoice.id"
            :class="[
              'flex items-center p-2 text-sm hover:bg-gray-100',
              index % 2 === 0 ? 'bg-gray-50' : '',
            ]"
          >
            <sw-checkbox
              variant="primary"
              :id="invoice.id"
              size="sm"
              v-model="invoice.selected"
              class="mr-2"
              @change="updateSubTotal"
            />
            <label :for="invoice.id" class="flex-grow">{{
              invoice.invoice_number
            }}</label>
            <span class="font-semibold mr-2">{{
              (invoice.due_amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }}</span>
            <span :class="getStatusClass(invoice.status)">{{
              invoice.status
            }}</span>
          </div>
        </div>
      </div>
    </sw-card>
    <br />
    <sw-card>
      <h2 class="text-xl font-semibold mb-6">Invoice Payment Summary</h2>
      <div class="space-y-4">
        <!-- Subtotal -->
        <div class="flex justify-between items-center">
          <span class="text-gray-600">Subtotal</span>
          <span class="font-medium">${{ subTotal.toFixed(2) }}</span>
        </div>

        <!-- Fees -->
        <div v-if="fees.length > 0" class="space-y-2">
          <div
            v-for="fee in fees"
            :key="fee.id"
            class="flex justify-between items-center text-sm w-full"
          >
            <div
              v-if="fee.type == 'fixed'"
              class="flex justify-between items-center w-full"
            >
              <span class="text-gray-600 text-left">{{ fee.name }}</span>
              <div
                v-if="fee.type == 'fixed'"
                v-html="$utils.formatMoney(fee.amount, defaultCurrency)"
                class="text-right"
              ></div>
            </div>
            <div
              v-if="fee.type == 'percentage'"
              class="flex justify-between items-center w-full"
            >
              <span class="text-gray-600 text-left">{{ fee.name }}</span>
              <span class="font-medium text-right"
                >{{ fee.amount / 100 }} %</span
              >
            </div>
          </div>
        </div>

        <!-- Taxes -->
        <div v-if="taxes > 0" class="flex justify-between items-center">
          <span class="text-gray-600">Taxes</span>
          <span class="font-medium">${{ formatCurrency(taxes) }}</span>
        </div>

        <!-- Total -->
        <div
          class="flex justify-between items-center text-lg font-semibold pt-2 border-t"
        >
          <span>Total Fees</span>
          <span>{{ totalDue }}</span>
        </div>

        <!-- Available Credit -->
        <div v-if="customer && customer.balance > 1" class="mt-4">
          <h3 class="font-semibold mb-2">Apply Credit</h3>
          <div class="space-y-2">
            <label class="flex items-center">
              <sw-radio
                variant="primary"
                size="sm"
                v-model="creditOption"
                value="none"
                class="mr-2"
                style="margin-top: -2em absolute"
              />
              <span class="align-middle">Don't apply credit</span>
            </label>
            <label class="flex items-center">
              <sw-radio
                variant="primary"
                size="sm"
                v-model="creditOption"
                value="all"
                class="mr-2"
                style="margin-top: -2em absolute"
              />
              <span class="align-middle">
                Apply available credit ({{
                  customer.balance.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                }})
              </span>
            </label>
          </div>
        </div>

        <!-- Credit Applied -->
        <div
          v-if="creditOption === 'all'"
          class="flex justify-between items-center text-green-600"
        >
          <span>Credit Applied</span>
          <span
            >-{{
              appliedCredit.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }}</span
          >
        </div>

        <!-- Amount to Pay -->
        <div
          class="flex justify-between items-center text-lg font-semibold pt-2 border-t"
        >
          <span>Amount to Pay</span>
          <span>{{ amountToPay }}</span>
        </div>

        <!-- Mostrar la información de la tarjeta guardada -->
        <div
          v-if="cardSaveText && Last4digittext"
          class="card-info border border-gray-300 rounded-lg p-2 flex items-center"
        >
          <img
          v-if="creditCardImages[cardSaveText]"
            :src="creditCardImages[cardSaveText].src"
            :style="{ width: creditCardImages[cardSaveText].width, height: creditCardImages[cardSaveText].height }"
            class="h-5 mr-2"
            alt="Credit Card Logo"
          />
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="24px"
            height="24px"
            class="h-5 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
            />
          </svg>
          <span>{{ cardSaveText }} ending in {{ Last4digittext }}</span>
        </div>

        <!-- Botón que cambia según el checkbox -->
        <sw-button
          :disabled="isPayDisabled"
          @click="handleButtonClick"
          variant="primary-outline"
          class="w-full"
        >
          {{ buttonText }}
        </sw-button>

        <!-- Modal for ID Verification -->
        <Modal v-if="showModal" @close="closeModal">
          <h1>Hello World</h1>
        </Modal>
      </div>
    </sw-card>
  </div>
</template>

<script>
import { EventBus } from './event-bus.js'
import Modal from './Modal.vue'
import { mapActions, mapState, mapGetters } from 'vuex/dist/vuex.common.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  components: {
    Modal,
  },
  props: {
    fees: Array,
    disablePay: Boolean,
    isidentificationverification: Boolean,
    formData: {
      type: Object,
      required: true,
    },
    defaultPaymentdisplay: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
    },
    defaultPaymentGateway: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
    cardSaveText: {
      type: String,
      required: true,
    },
    Last4digittext: {
      type: String,
      required: true,
    },
    creditCardId: {
      type: Number,
      default: null,
      required: true,
    },
    achAccountId: {
      type: Number,
      default: null,
      required: true,
    },
    invoiceObject: {
      type: Object,
      required: true,
      default: null,
    },
    idCurrenUserGeneral: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      taxes: [],
      requireIdVerification: false,
      showModal: false,
      totalAmount: 0,
      payTotalAmount: 0,
      subTotal: 0,
      totalDue: 0,
      totalDueAmount: 0,
      creditToApply: 0,
      feeSum: 0,
      feelist: [],

      // customer: {},
      classDisabled: 'secondary',
      invoiceList: [],
      showInvoiceList: [],
      customCreditAmount: 0,
      creditOption: 'none',
      formDisablePay: this.disablePay,
      cardSaved: false,
      cardType: null,
      last4Digits: null,
      paymentfeesenabledinvoices: false,
      paymentfeesenabled: false,
      statusOption: [
        { name: this.$t('general.all'), value: 'all' },
        { name: this.$t('general.sent'), value: 'SENT' },
        { name: this.$t('general.due'), value: 'DUE' },
        { name: this.$t('general.overdue'), value: 'OVERDUE' },
      ],
      formDataStatus: {
        status: { name: this.$t('general.all'), value: 'all' },
      },
      creditCardImages: {
        VISA: { src: '/images/visa.png', width: '50px', height: '30px' },
        MASTERCARD: { src: '/images/mastercard.png', width: '40px', height: '20px' },
        'AMERICAN EXPRESS': {
          src: '/images/american_express.png',
          width: '45px',
          height: '20px'
        },
        DISCOVER: { src: '/images/discover.png', width: '50px', height: '20px' },
      },
    }
  },
  watch: {
    totalFeeAmount(newValue) {
      this.totalDue = newValue
    },
    disablePay(newValue) {
      this.formDisablePay = newValue
    },
    formDisablePay(newValue) {
      if (newValue === true) {
        this.classDisabled = 'secondary'
      } else {
        if (this.subTotal > 0) {
          this.classDisabled = 'primary'
          this.formDisablePay = false
        } else {
          this.classDisabled = 'secondary'
          this.formDisablePay = true
        }
      }
    },
    subTotal(newValue) {
      if (newValue > 0) {
        this.formDisablePay = false
      } else {
        this.formDisablePay = true
      }
    },
    async customer(newValue) {
      await this.fetchCustomerInvoices(newValue.id)
    },
    creditOption(val) {
      if (val === 'all') {
        if (this.customer.balance > this.totalDueAmount) {
          this.creditToApply = this.totalDueAmount
        } else {
          this.creditToApply = this.customer.balance
        }
      } else if (val === 'none') {
        this.creditToApply = 0
      } else if (val === 'custom') {
        this.creditToApply = this.customCreditAmount
      }
    },
    fee_amount(newValue) {
      this.feeSum = newValue
    },
    formData(newVal){
      console.log('data en invoices: ', newVal)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData()
    })
    console.log('customer select in invoices: ', this.customer)
  },
  computed: {
    buttonText() {
      console.log(this.isidentificationverification)
      return this.requireIdVerification ? 'ID Verification' : 'Pay Invoice'
    },
    isPayDisabled() {
      return this.formDisablePay || this.subTotal <= 0
    },
    ...mapState('user', ['currentUser', 'settingsCompany']),
    ...mapGetters('company', ['defaultCurrencyForInput', 'defaultCurrency']),
    // ...mapGetters('customer', ['customers']),
    updateSubTotal() {
      this.subTotal = this.invoiceList
        .filter((invoice) => invoice.selected)
        .reduce((total, invoice) => total + invoice.due_amount / 100, 0)

      this.totalAmount = this.totalDue - this.creditToApply
    },
    updateCustomCredit() {
      this.creditToApply = this.customCreditAmount
    },
    amountToPay() {
      this.payTotalAmount = Math.max(
        0,
        this.totalDueAmount - this.creditToApply
      )
      return Math.max(
        0,
        this.totalDueAmount - this.creditToApply
      ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    },
    appliedCredit() {
      if (this.payTotalAmount < this.customer.balance) {
        return this.totalDueAmount
      } else {
        return this.customer.balance
      }
    },
    totalFeeAmount() {
      let total = 0
      this.fees.forEach((fee) => {
        if (fee.type == 'fixed') {
          total += parseFloat(fee.amount)
        } else if (fee.type == 'percentage') {
          total += this.subTotal * parseFloat(fee.amount / 100)
        }
      })
      const formattedTotal = (total / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      this.fee_amount = total / 100
      this.totalDue = (this.subTotal + this.fee_amount).toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        }
      )
      this.totalDueAmount = this.subTotal + this.fee_amount
      return formattedTotal
    },
  },
  created() {
    EventBus.$on('verification-changed', (newValue) => {
      this.requireIdVerification = newValue
    })

    // Escuchar el evento cardSaved
    EventBus.$on('cardSaved', (status) => {
      this.cardSaved = status
    })
  },
  methods: {
    ...mapActions('paymentAccounts', [
      'fetchPaymentAccounts',
      'fetchPaymentAccount',
      'addPaymentAccount',
    ]),

    ...mapActions('invoice', ['fetchInvoicesCustomerPayments']),
    ...mapActions('payment', [
      'addPayment',
      'updatePayment',
      'fetchPayment',
      'fetchPaymentModes',
      'resetSelectedNote',
      'processPayment',
      'paymentsMethodActiveCustomerCredit',
      'paymentAsociated',
    ]),

    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'sent':
          return 'text-blue-600'
        case 'due':
          return 'text-yellow-600'
        case 'overdue':
          return 'text-red-600'
        default:
          return ''
      }
    },
    async handleButtonClick() {
      const isValid = await this.Validation()
      if (isValid) {
        if (this.requireIdVerification) {
          this.showModal = true // Mostrar el modal
        } else {
          this.payInvoice()
        }
      }
    },
    closeModal() {
      this.showModal = false // Cerrar el modal
    },

    async loadData() {
      if (this.customer) {
        this.fetchCustomerInvoices(this.customer.id)
      }
      

      return true
    },
    showIdVerificationModal() {
      this.showModal = true
    },

    payInvoice() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('payments.create_payment'),
        icon: 'warning',
        buttons: true,
      }).then((value) => {
        if (value) {
          console.log('formdata')
          console.log(this.formData)
          console.log('subtotal')
          console.log(this.subTotal)
          console.log('Fees')
          console.log(this.totalDue)
          console.log('creditToApply')
          console.log(this.creditToApply)
          console.log('defaultPaymentdisplay')
          console.log(this.defaultPaymentdisplay)
          console.log('defaultPaymentGateway')
          console.log(this.defaultPaymentGateway)
          console.log('defaultPaymentGateway')
          console.log(this.paymentType)

          let invoicelist2 = this.invoiceList.filter(
            (invoice) => invoice.selected
          )
          console.log('invoice list')
          console.log(invoicelist2)

          if (this.creditToApply > 0) {
            this.handlePaymentcuadro()
          } else {
            if (this.formData) {
              // Validar y asignar paymentType
              if (!this.formData.paymentType) {
                this.$set(this.formData, 'paymentType', this.paymentType)
              }

              // Validar y asignar defaultPaymentdisplay
              if (!this.formData.defaultPaymentdisplay) {
                this.$set(
                  this.formData,
                  'defaultPaymentdisplay',
                  this.defaultPaymentdisplay
                )
              }

              // Validar y asignar paymentGateway
              if (!this.formData.paymentGateway) {
                this.$set(
                  this.formData,
                  'paymentGateway',
                  this.defaultPaymentGateway
                )
              }
              this.handlePaymentcuadro2()
            }
          }
        } else {
          return false
        }
      })
    },

    async handlePaymentcuadro() {
      Swal.fire({
        title: 'Payment Processing',
        text: 'Payment is Processing, wait a few seconds...',
        icon: 'warning',
        buttons: false,
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })

      try {
        const response = await this.paymentWithCustomerBalance()
        Swal.close() // Cierra el cuadro de diálogo al completar el proceso
        // Maneja la respuesta exitosa aquí
      } catch (error) {
        Swal.close() // Cierra el cuadro de diálogo si hay un error
        // Maneja el error aquí
      }
    },

    async handlePaymentcuadro2() {
      Swal.fire({
        title: 'Payment Processing',
        text: 'Payment is Processing, wait a few seconds...',
        icon: 'warning',
        buttons: false,
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      })

      try {
        const response = await this.processPaymentvue()
        Swal.close() // Cierra el cuadro de diálogo al completar el proceso
        // Maneja la respuesta exitosa aquí
      } catch (error) {
        Swal.close() // Cierra el cuadro de diálogo si hay un error
        // Maneja el error aquí
      }
    },

    /**
     * Procesa el pago basado en el tipo de pago seleccionado.
     * Si el tipo de pago es 'saved', utiliza el método processPaymentvueSave.
     * Si el tipo de pago es 'one-time', utiliza el método processPaymentvueOneTime.
     * Muestra mensajes de depuración en la consola para seguimiento.
     */
    async processPaymentvue() {
      console.log('----process payment----')
      console.log(this.formData)

      console.log('fees:', this.fees)
      console.log('customer:', this.customer)

      // Verificar el tipo de pago
      if (this.formData.paymentType === 'saved') {
        // Si el tipo de pago es 'saved' y hay un método de pago predeterminado
        if (this.formData.defaultPaymentdisplay) {
          const response = await this.processPaymentvueSave()
          return true
        }
      } else {
        // Si el tipo de pago es 'one-time'
        console.log('----process payment one-time ----')
        console.log(this.formData)
        if(this.formData.payment_account_type === 'NG'){
          const response = await this.processPaymentSimple();
        }else{
          const response = await this.processPaymentvueOneTime()
        }
        console.log('----process payment one-time ----')
        return true
      }

      console.log('----process payment----')
      return true
    },

    /**
     * Procesa el pago y guarda la información de pago.
     * Filtra las facturas seleccionadas, crea el objeto de datos de pago,
     * y envía la solicitud de pago al servidor. Maneja la respuesta del servidor
     * y muestra mensajes de éxito o error según corresponda.
     */
    async processPaymentvueSave() {
      console.log('----process payment saved----')
      console.log('defaultPaymentdisplay:', this.formData.defaultPaymentdisplay)

      // Filtrar las facturas seleccionadas
      let selectedInvoices = this.invoiceList.filter(
        (invoice) => invoice.selected
      )

      console.log('selectedInvoices:', selectedInvoices)
      console.log('customer:', this.customer)
      console.log('fees:', this.fees)

      // Crear el objeto DataPayment con los datos necesarios
      let DataPayment = {
        payer_email: this.customer.email,
        payer_id: this.idCurrenUserGeneral,
        payment_account_type:
          this.formData.defaultPaymentdisplay.payment_account_type,
        user_id: this.formData.user_id,
        client_id: this.customer.id,
        payment_number: 'pay-0009',
        company_id: this.customer.company_id,
        customcode: this.customer.customcode,
        status: 'A',
        payment_date: moment().format('YYYY-MM-DD'),
        date: moment().format('YYYY-MM-DD'),
        company_name: this.customer.first_name || this.customer.contact_name,
        name: this.customer.last_name || this.customer.name,
        payment_gateway_id: this.formData.paymentGateway.id,
        country_id: this.formData.defaultPaymentdisplay.country_id,
        state_id: this.formData.defaultPaymentdisplay.state_id,
        city: this.formData.defaultPaymentdisplay.city,
        address_1: this.formData.defaultPaymentdisplay.address_1,
        address_2: this.formData.defaultPaymentdisplay.address_2,
        zip: this.formData.defaultPaymentdisplay.zip,
        nameOnAccount: this.customer.last_name || this.customer.name,
        admin: 'cust',
        has_fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' ? 1 : 0,
        fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' &&
          this.fees.length > 0
            ? this.fees.map((fee) => fee.id)
            : [],
        invoices: [],
        amount: 0,
        phone: null,
        country: this.formData.defaultPaymentdisplay.country_name,
        state: this.formData.defaultPaymentdisplay.state_name,
        type: 'billing',
        first_name: this.customer.name,
        last_name: this.customer.name,
        email: this.customer.email,
        invoice_id: null,
      }

      // Limitar la longitud de ciertos campos
      ;['name', 'nameOnAccount', 'company_name'].forEach((field) => {
        if (DataPayment[field]) {
          DataPayment[field] = DataPayment[field].substring(0, 21)
        }
      })

      // Agregar facturas seleccionadas y calcular el monto total
      DataPayment.invoice_list = selectedInvoices
      selectedInvoices.forEach((invoice) => {
        DataPayment.invoices.push(invoice.id)
        DataPayment.amount += invoice.due_amount
      })

      // Configurar datos adicionales según el tipo de cuenta de pago
      if (DataPayment.payment_account_type === 'CC') {
        Object.assign(DataPayment, {
          credit_card: this.formData.defaultPaymentdisplay.credit_card,
          cvv: this.formData.defaultPaymentdisplay.cvv,
          card_number: this.formData.defaultPaymentdisplay.card_number,
          expiration_date: this.formData.defaultPaymentdisplay.expiration_date,
          payment_method_id: this.creditCardId,
        })
      } else {
        Object.assign(DataPayment, {
          ACH_type:
            this.formData.defaultPaymentdisplay.ACH_type.toString().toLowerCase(),
          account_number: this.formData.defaultPaymentdisplay.account_number,
          routing_number: this.formData.defaultPaymentdisplay.routing_number,
          bank_name: this.formData.defaultPaymentdisplay.bank_name,
          payment_method_id: this.achAccountId,
        })
      }

      console.log('DataPayment:', DataPayment)

      try {
        const response = await this.processPayment(DataPayment)
        // Mostrar mensaje de éxito o error según la respuesta del servidor
        if (response.data.success) {
          window.toastr['success'](this.$t('payments.created_message'))
          this.$router.push(`/admin/payments/${response.data.payment_id}/view`)
          this.isLoading = false
          console.log('----process payment saved exito----')
          return true
        } else {
          window.toastr['error'](response.data.message)
          this.isLoading = false
          console.log('----process payment saved exito fracaso----')
          return false
        }
      } catch (error) {
        // Manejar errores
        this.isLoading = false

        if (error.response && error.response.status === 422) {
          // Manejar errores de validación
          if (error.response.data.hasOwnProperty('errors')) {
            for (let key in error.response.data.errors) {
              let message = key + ': ' + error.response.data.errors[key][0]
              window.toastr['error'](message)
            }
          } else if (error.response.data.hasOwnProperty('data')) {
            if (typeof error.response.data.data === 'string') {
              let message = error.response.data.data
              window.toastr['error'](message)
            } else if (Array.isArray(error.response.data.data)) {
              for (let key in error.response.data.data) {
                let message = key + ': ' + error.response.data.data[key][0]
                window.toastr['error'](message)
              }
            }
          }
        } else {
          // Manejar otros tipos de errores
          window.toastr['error']('Unknow error, contact administration')
          return false
        }

        return false
      }
    },

    /**
     * Processes a one-time payment by gathering necessary data from the form and customer,
     * organizing it into a DataPayment object, and then attempting to process the payment.
     * Displays success or error messages based on the server response.
     * @returns {Promise<boolean>} - Returns true if the payment is processed successfully, otherwise false.
     */
    async processPaymentvueOneTime() {
      console.log('----process payment onetime metodo----')
      console.log('formdat:', this.formData.defaultPaymentdisplay)
      console.log('formdata:', this.formData)
      console.log('customer:', this.customer)
      console.log('fees:', this.fees)

      let selectedInvoices = this.invoiceList.filter(
        (invoice) => invoice.selected
      )
      // Crear el objeto DataPayment con los datos necesarios
      const DataPayment = {
        payment_account_type: this.formData.payment_account_type,
        payer_email: this.customer.email,
        payer_id: this.idCurrenUserGeneral,
        user_id: this.customer.id,
        client_id: this.customer.id,
        payment_number: 'pay-0009',
        company_id: this.customer.company_id,
        customcode: this.customer.customcode,
        status: 'A',
        payment_date: moment().format('YYYY-MM-DD'),
        date: moment().format('YYYY-MM-DD'),
        company_name: this.customer.first_name || this.customer.contact_name,
        name: this.customer.last_name || this.customer.name,
        payment_gateway_id: this.formData.paymentGateway.id,
        country_id: this.formData.country.id,
        state_id: this.formData.state.id,
        city: this.formData.city,
        address_1: this.formData.address_1,
        address_2: this.formData.address_2,
        zip: this.formData.zip,
        nameOnAccount: this.customer.last_name || this.customer.name,
        admin: 'cust',
        has_fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' ? 1 : 0,
        fees:
          this.formData.paymentGateway.IsPaymentFeeActive === 'YES' &&
          this.fees.length > 0
            ? this.fees.map((fee) => fee.id)
            : [],
        invoices: [],
        amount: 0,
        phone: null,
        country: this.formData.country.name,
        state: this.formData.state.name,
        type: 'billing',
        first_name: this.customer.name,
        last_name: this.customer.name,
        email: this.customer.email,
        invoice_id: null,
        payment_method_id: this.formData.payment_method.id,
      }

      // Limitar la longitud de los nombres si es necesario
      DataPayment.name = DataPayment.name?.substring(0, 21)
      DataPayment.company_name = DataPayment.company_name?.substring(0, 21)

      // Agregar detalles específicos según el tipo de cuenta de pago
      if (DataPayment.payment_account_type === 'CC') {
        Object.assign(DataPayment, {
          credit_card: this.formData.credit_card.value,
          cvv: this.formData.cvv,
          card_number: this.formData.card_number,
          expiration_date: `${this.formData.expiration_year}-${this.formData.expiration_month}`,
          nameOnAccount: this.formData.card_holder?.substring(0, 21),
        })
      } else {
        Object.assign(DataPayment, {
          ACH_type: this.formData.ACH_type.name.toString().toLowerCase(),
          account_number: this.formData.account_number,
          routing_number: this.formData.routing_number,
          bank_name: this.formData.bank_name,
          nameOnAccount: this.formData.account_holder?.substring(0, 21),
        })
      }

      selectedInvoices.forEach((invoice) => {
        DataPayment.invoices.push(invoice.id)
        DataPayment.amount += invoice.due_amount
      })

      console.log('DataPayment:', DataPayment)

      try {
        // Intentar procesar el pago
        const response = await this.processPayment(DataPayment)
        // Mostrar mensaje de éxito o error según la respuesta del servidor
        if (response.data.success) {
          window.toastr['success'](this.$t('payments.created_message'))

          if (this.formData.saveForFutureUse) {
            const DataPayamentAccount = {
              first_name: DataPayment.nameOnAccount,
              country_id: DataPayment.country_id,
              state_id: DataPayment.state_id,
              city: DataPayment.city,
              address_1: DataPayment.address_1,
              address_2: DataPayment.address_2,
              zip: DataPayment.zip,
              payment_account_type: DataPayment.payment_account_type,
              client_id: DataPayment.client_id,
              status: 'A',
              nameOnAccount: DataPayment.nameOnAccount,
              main_account: this.formData.saved_pay
                ? this.formData.saved_pay === true
                  ? 1
                  : 0
                : 0,
              ...(DataPayment.payment_account_type === 'CC'
                ? {
                    credit_cards: this.formData.credit_card,
                    cvv: this.formData.cvv,
                    card_number: this.formData.card_number,
                    expiration_date: DataPayment.expiration_date,
                  }
                : {
                    ACH_type: this.formData.ACH_type.name,
                    account_number: DataPayment.account_number,
                    routing_number: DataPayment.routing_number,
                    bank_name: DataPayment.bank_name,
                  }),
            }

            try {
              const responsePaymentaccount = await this.addPaymentAccount(
                DataPayamentAccount
              )
              if (responsePaymentaccount.status === 200) {
                window.toastr['success']('Payment Account Added')
              } else {
                console.log(responsePaymentaccount)
                window.toastr['error']('Failed to add payment account')
              }
            } catch (error) {
              console.log(responsePaymentaccount)
              window.toastr['error']('Failed to add payment account')
            }
          }
          this.$router.push(`/admin/payments/${response.data.payment_id}/view`)
          this.isLoading = false
          console.log('----process payment saved exito----')
          return true
        } else {
          window.toastr['error'](response.data.message)
          this.isLoading = false
          console.log('----process payment saved exito fracaso----')
          return false
        }
      } catch (error) {
        // Manejar errores
        this.isLoading = false

        if (error.response && error.response.status === 422) {
          // Manejar errores de validación
          const errors = error.response.data.errors || error.response.data.data
          if (errors) {
            for (let key in errors) {
              let message = `${key}: ${errors[key][0]}`
              window.toastr['error'](message)
            }
          }
        } else {
          // Manejar otros tipos de errores
          window.toastr['error']('Unknown error, contact administration')
        }

        return false
      }
    },

    async processPaymentSimple() {
      console.log('Iniciando método processPaymentSimple')

      // Inicializar las facturas procesadas y filtrar las facturas seleccionadas
      let processedInvoices = []
      let selectedInvoices = this.invoiceList.filter(
        (invoice) => invoice.selected
      )
      console.log('Facturas procesadas:', processedInvoices)
      console.log('Facturas seleccionadas:', selectedInvoices)

      // Mostrar información del cliente
      console.log('Cliente:', this.customer)

      // Iterar sobre las facturas seleccionadas
      for (let i = 0; i < selectedInvoices.length; i++) {
        console.log('Iteración:', i)
        console.log('Factura seleccionada:', selectedInvoices[i])

        // Preparar los datos del pago
        let data = {
          user_id: this.customer.id,
          amount: selectedInvoices[i].due_amount,
          invoice_id: selectedInvoices[i].id,
          payment_number: 'pay-0002',
          payment_method_id: this.formData.payment_method.id,
          payment_date: moment(this.formData.payment_date).format('YYYY-MM-DD'),
          transaction_status: 'Approved',
          customer_credit: true,
        }

        // Realizar el pago
        let response = await this.addPayment(data)
        let successResponse = response.data.success

        if (successResponse) {
          // Añadir la factura procesada a la lista
          processedInvoices.push(selectedInvoices[i].invoice_number)
          console.log('Facturas procesadas hasta ahora:', processedInvoices)


          // Verificar si se debe romper el bucle
          if (i === selectedInvoices.length - 1) {
            console.log('Condición de ruptura alcanzada en la iteración:', i)
            this.$router.push(
              `/admin/payments/${response.data.payment.id}/view`
            )
            let successMessage = `${this.$t(
              'payments.created_message'
            )}. ${this.$t(
              'general.processed_invoices'
            )}: ${processedInvoices.join(', ')}`
            window.toastr['success'](successMessage)
            return true
          }
        } else {
          // Manejar errores de pago
          if (response.data.error === 'invalid_amount') {
            window.toastr['error'](this.$t('invalid_amount_message'))
            console.error('Invalid amount error')
            return false
          }

          window.toastr['error'](response.data.error)
          console.error('General error:', response.data.error)
          this.isLoading = false
          this.$router.push('/admin/payments')
          return false
        }
      }

      console.log('Fin método paymentWithCustomerBalance')
    },

    /**
     * Procesa el pago utilizando el balance del cliente.
     * Filtra las facturas seleccionadas y realiza pagos hasta que el balance del cliente se agote.
     * Muestra mensajes de éxito o error según corresponda.
     */
    async paymentWithCustomerBalance() {
      console.log('Iniciando método paymentWithCustomerBalance')

      // Inicializar las facturas procesadas y filtrar las facturas seleccionadas
      let processedInvoices = []
      let selectedInvoices = this.invoiceList.filter(
        (invoice) => invoice.selected
      )
      console.log('Facturas procesadas:', processedInvoices)
      console.log('Facturas seleccionadas:', selectedInvoices)

      // Mostrar información del cliente
      console.log('Cliente:', this.customer)

      // Inicializar el balance restante del cliente
      let remainingBalance = this.customer.balance
      console.log('Balance inicial:', remainingBalance)

      // Iterar sobre las facturas seleccionadas
      for (let i = 0; i < selectedInvoices.length; i++) {
        console.log('Iteración:', i)
        console.log('Factura seleccionada:', selectedInvoices[i])
        console.log('Balance dentro del for:', remainingBalance)

        // Preparar los datos del pago
        let data = {
          user_id: this.customer.id,
          amount: selectedInvoices[i].due_amount,
          invoice_id: selectedInvoices[i].id,
          payment_number: 'pay-0002',
          payment_method_id: null,
          payment_date: moment(this.formData.payment_date).format('YYYY-MM-DD'),
          transaction_status: 'Approved',
          customer_credit: true,
        }

        console.log(
          'Datos preparados antes de la verificación del balance:',
          data
        )

        // Ajustar el monto si el balance restante es menor que el monto debido
        if (data.amount / 100 > remainingBalance) {
          data.amount = remainingBalance * 100
          console.log('Monto ajustado debido al balance restante:', data.amount)
        }

        console.log(
          'Datos preparados después de la verificación del balance:',
          data
        )

        // Realizar el pago
        let response = await this.addPayment(data)
        let successResponse = response.data.success

        if (successResponse) {
          // Añadir la factura procesada a la lista
          processedInvoices.push(selectedInvoices[i].invoice_number)
          console.log('Facturas procesadas hasta ahora:', processedInvoices)

          // Actualizar el balance restante
          remainingBalance -= data.amount / 100
          console.log(
            'Balance restante después del descuento:',
            remainingBalance
          )

          // Verificar si se debe romper el bucle
          if (i === selectedInvoices.length - 1 || remainingBalance <= 1) {
            console.log('Condición de ruptura alcanzada en la iteración:', i)
            this.$router.push(
              `/admin/payments/${response.data.payment.id}/view`
            )
            let successMessage = `${this.$t(
              'payments.created_message'
            )}. ${this.$t(
              'general.processed_invoices'
            )}: ${processedInvoices.join(', ')}`
            window.toastr['success'](successMessage)
            return true
          }
        } else {
          // Manejar errores de pago
          if (response.data.error === 'invalid_amount') {
            window.toastr['error'](this.$t('invalid_amount_message'))
            console.error('Invalid amount error')
            return false
          }

          window.toastr['error'](response.data.error)
          console.error('General error:', response.data.error)
          this.isLoading = false
          this.$router.push('/admin/payments')
          return false
        }
      }

      console.log('Fin método paymentWithCustomerBalance')
    },
    beforeDestroy() {
      EventBus.$off('verification-changed')
      EventBus.$off('cardSaved')
    },
    formatCurrency(value) {
      return (Math.round(value * 100) / 100).toFixed(2)
    },
    /**
     * Calcula la tarifa basada en el tipo de tarifa proporcionado.
     * Si la tarifa es de tipo 'percentage', calcula el porcentaje del subtotal.
     * Si la tarifa es de tipo 'fixed', devuelve el monto fijo.
     *
     * @param {Object} fee - El objeto de tarifa que contiene el tipo y el valor de la tarifa.
     * @returns {Number} - El monto calculado de la tarifa.
     */
    calculateFee(fee) {
      // Verificar si la tarifa es de tipo 'percentage'
      if (fee.type === 'percentage') {
        // Calcular y devolver el porcentaje del subtotal
        return this.subTotal * (fee.value / 100)
      }
      // Verificar si la tarifa es de tipo 'fixed'
      else if (fee.type === 'fixed') {
        // Devolver el monto fijo de la tarifa
        return fee.amount
      }
      // Devolver 0 si no se cumple ninguna condición
      return 0
    },
    /**
     * Obtiene las facturas del cliente según el ID del usuario.
     * Filtra las facturas no pagadas y establece la lista de facturas para mostrar.
     *
     * @param {Number} userId - El ID del usuario para obtener las facturas.
     */
    async fetchCustomerInvoices(userId) {
      // Crear el objeto de datos para la solicitud
      let data = {
        customer_id: userId,
      }

      // Realizar la solicitud para obtener las facturas del cliente
      let response = await this.fetchInvoicesCustomerPayments(data)

      // Iterar sobre las facturas obtenidas y establecer 'selected' en false
      response.data.invoices.forEach((invoice) => {
        invoice.selected = false
      })

      // Verifica si this.invoiceObject es válido
      if (
        this.invoiceObject &&
        typeof this.invoiceObject === 'object' &&
        this.invoiceObject.id
      ) {
        console.log('invoiceObject es válido:', this.invoiceObject)

        // Establece el campo selected en true
        this.invoiceObject.selected = true

        // Coloca this.invoiceObject al principio del array response.data.invoices
        response.data.invoices.unshift(this.invoiceObject)

        console.log('Array de facturas actualizado:', response.data.invoices)
      }

      // Asignar las facturas obtenidas a la lista de facturas
      this.invoiceList = [...response.data.invoices]
      this.showInvoiceList = this.invoiceList
    },
    /**
     * Filtra la lista de facturas según el estado proporcionado.
     * Si el estado es 'all', muestra todas las facturas.
     * Si el estado es específico, filtra las facturas que coinciden con ese estado.
     *
     * @param {Object} status - El estado por el cual filtrar las facturas.
     */
    filterInvoiceStatus(status) {
      // Verificar si el estado es 'all'
      if (status.value === 'all') {
        // Mostrar todas las facturas
        this.showInvoiceList = this.invoiceList
      } else {
        // Filtrar las facturas según el estado proporcionado
        this.showInvoiceList = this.invoiceList.filter(
          (invoice) => invoice.status === status.value
        )
      }
    },

    /**
     * Validates various properties of the component to ensure they have valid values.
     * If any validation fails, an error message is displayed and the execution is stopped.
     * @returns {boolean} - Returns true if all validations pass, otherwise false.
     */
    async Validation() {
      if (this.creditToApply > 0) {
        return true
      }
      // Función auxiliar para mostrar mensajes de error y retornar false
      const showError = (message, errorType) => {
        window.toastr['error'](`${message} (Error: ${errorType})`)
        return false
      }

      // Validar que defaultPaymentGateway sea un objeto válido
      if (
        this.defaultPaymentGateway === null ||
        this.defaultPaymentGateway === undefined ||
        typeof this.defaultPaymentGateway !== 'object'
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'defaultPaymentGateway'
        )
      }

      // Validar que paymentType sea 'saved' o 'oneTime'
      if (
        this.paymentType === null ||
        this.paymentType === undefined ||
        (this.paymentType !== 'saved' && this.paymentType !== 'oneTime')
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'paymentType'
        )
      }

      // Validar que customer no sea null ni undefined
      if (this.customer === null || this.customer === undefined) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'customer'
        )
      }

      // Validar que formData no sea null ni undefined
      if (this.formData === null || this.formData === undefined) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'formData'
        )
      }

      // Validar que payment_account_type en formData sea 'CC' o 'ACH' y que paymentType sea 'oneTime'
      if (
        this.paymentType === 'oneTime' &&
        (this.formData.payment_account_type === undefined ||
          this.formData.payment_account_type === null ||
          (this.formData.payment_account_type !== 'CC' &&
            this.formData.payment_account_type !== 'ACH' &&
            this.formData.payment_account_type !== 'NG'))
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'payment_account_type'
        )
      }

      console.log('formData', this.formData)
      console.log(this.paymentType)
      console.log(this.defaultPaymentdisplay)

      // Validar que defaultPaymentdisplay no sea null ni undefined si paymentType es 'saved'
      if (
        this.paymentType === 'saved' &&
        (this.defaultPaymentdisplay === null ||
          this.defaultPaymentdisplay === undefined)
      ) {
        return showError(
          'Please ensure the form is properly filled out. If the problem persists, refresh the page or contact the system administrator.',
          'defaultPaymentdisplay'
        )
      }

      // Validar campos específicos si paymentType es 'oneTime' y payment_account_type existe
      if (
        this.paymentType === 'oneTime' &&
        this.formData.payment_account_type && 
        this.formData.payment_account_type !== 'NG'
      ) {
        if (this.formData.payment_account_type === 'CC') {
          // Validar campos de tarjeta de crédito
          const ccFields = [
            'credit_card.value',
            'cvv',
            'card_number',
            'expiration_year',
            'expiration_month',
            'card_holder',
          ]
          for (const field of ccFields) {
            if (!this.getNestedValue(this.formData, field)) {
              return showError(
                'Please ensure all credit card details are filled. If the problem persists, refresh the page or contact the system administrator.',
                'credit card details'
              )
            }
          }
        } else if (this.formData.payment_account_type === 'ACH') {
          // Validar campos de ACH
          const achFields = [
            'ACH_type',
            'account_number',
            'routing_number',
            'account_holder',
          ]
          for (const field of achFields) {
            if (!this.formData[field]) {
              return showError(
                'Please ensure all ACH details are filled. If the problem persists, refresh the page or contact the system administrator.',
                'ACH details'
              )
            }
          }
        }

        // Validar campos adicionales si paymentType es 'oneTime'
        const requiredFields = ['address_1', 'zip', 'state', 'country', 'city']
        for (const field of requiredFields) {
          if (
            this.formData[field] === null ||
            this.formData[field] === undefined
          ) {
            return showError(
              `Please ensure the ${field} is filled. If the problem persists, refresh the page or contact the system administrator.`,
              field
            )
          }
        }

        // Validar que state y country sean objetos si paymentType es 'oneTime'
        if (
          typeof this.formData.state !== 'object' ||
          typeof this.formData.country !== 'object'
        ) {
          return showError(
            'Please ensure the state and country fields are valid objects. If the problem persists, refresh the page or contact the system administrator.',
            'state or country'
          )
        }
      }

      // Si todas las validaciones pasan, retornar true
      return true
    },

    /**
     * Retrieves the value of a nested property within an object.
     * @param {Object} obj - The object to retrieve the value from.
     * @param {string} path - The path to the nested property (e.g., 'credit_card.value').
     * @returns {*} - The value of the nested property, or undefined if it doesn't exist.
     */
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    },
  },
}
</script>

<style scoped>
.text-blue-600 {
  color: #3b82f6;
}

.text-yellow-600 {
  color: #f59e0b;
}

.text-red-600 {
  color: #ef4444;
}

.card-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1rem;
}

.card-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  color: #4a5568;
}

.align-middle {
  display: inline-flex;
  align-items: center;
  line-height: 1.5; /* Ajusta este valor según sea necesario */
  margin-top: 0.5rem;
}
</style>
