var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid h-full grid-cols-12 overflow-y-hidden bg-gray-100" },
    [
      _c("div", {
        staticClass:
          "relative flex-col items-center justify-center hidden w-full h-full pl-10 bg-no-repeat bg-cover md:col-span-4 lg:col-span-4 md:flex content-box",
        style: _vm.wallpaperLogin,
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center w-full mx-auto text-gray-900 col-span-12 md:col-span-8 px-2 md:px-5 text-center",
        },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _vm.loading
                ? _c("base-loader", { attrs: { "show-bg-overlay": false } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h1",
                { staticClass: "text-4xl font-bold text-primary my-5" },
                [_vm._v("\n        " + _vm._s(_vm.message) + "\n      ")]
              ),
              _vm._v(" "),
              _vm.login && _vm.success
                ? _c(
                    "sw-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "login-customer" })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("login.log_in")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }