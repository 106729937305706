export const BOOTSTRAP_INTERNACIONALS = 'BOOTSTRAP_INTERNACIONALS'
export const ADD_INTERNACIONAL = 'ADD_INTERNACIONAL'
export const UPDATE_INTERNACIONAL = 'UPDATE_INTERNACIONAL'
export const DELETE_INTERNACIONAL = 'DELETE_INTERNACIONAL'
export const SET_TOTAL_INTERNACIONALS = 'SET_TOTAL_INTERNACIONALS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_SELECTED_VIEW_INTERNACIONAL = 'SET_SELECTED_VIEW_INTERNACIONAL'
export const RESET_SELECTED_INTERNACIONAL = 'RESET_SELECTED_INTERNACIONAL'
export const SET_SELECTED_INTERNACIONALS = 'SET_SELECTED_INTERNACIONALS'
export const SET_INTERNATIONALS = 'SET_INTERNATIONALS'
