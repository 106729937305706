var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-card", { staticClass: "mt-3" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "grid grid-cols-12 gap-4 mt-3 text-center" },
            [
              _c("div", { staticClass: "col-span-6 md:col-span-2" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-bold text-black non-italic" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payments.customer_name")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-2 text-sm font-normal non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.customer?.name) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-span-6 md:col-span-2" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-bold text-black non-italic" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payments.date")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-2 text-sm font-normal non-italic text-primary-800",
                  },
                  [_vm._v("\n            " + _vm._s(_vm.date) + "\n          ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-span-6 md:col-span-3" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-bold text-black non-italic" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payments.payment_method")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-2 text-sm font-normal non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.paymentMethod?.name) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-span-6 md:col-span-3" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-bold text-black non-italic" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payments.Payment_manager")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-2 text-sm font-normal non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.paymentGateway?.name) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-span-6 md:col-span-2" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-bold text-black non-italic" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("payments.bills_credit")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _vm.invoiceCredit?.length > 0
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "mt-2 text-sm font-normal non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("payments.invoice_title")) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "mt-2 text-sm font-normal non-italic text-primary-800",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("payments.credit")) +
                              "\n            "
                          ),
                        ]
                      ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "sw-card",
        { staticClass: "mt-4 pb-6" },
        [
          !_vm.isVerificationSuccessful
            ? _c("div", [
                _c("div", { staticClass: "page-header mb-4" }, [
                  _c("div", { staticClass: "text-xl font-bold mb-2" }, [
                    _vm._v("Capture photos for verification"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-sm font-normal" }, [
                    _vm._v(
                      "\n          Use your mobile device to automatically capture photos of your ID and yourself\n          to submit for verification.\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tabs mb-5 grid col-span-12 pt-6" },
                  _vm._l(_vm.stepsVerificationOptions, function (step, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "tab border-b" },
                      [
                        _c("div", { staticClass: "relative" }, [
                          _c("input", {
                            staticClass:
                              "w-full absolute z-10 cursor-pointer opacity-0 h-5 top-4",
                            attrs: { type: "checkbox", id: "chck" + index },
                            domProps: { checked: index === _vm.stepWizard },
                            on: {
                              change: function ($event) {
                                return _vm.toggleTab(index)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "header",
                            {
                              staticClass:
                                "col-span-5 flex justify-between items-center py-3 px-2 cursor-pointer select-none tab-label",
                              class: {
                                "bg-gray-200 rounded": index === _vm.stepWizard,
                              },
                              attrs: { for: "chck" + index },
                            },
                            [
                              _c("div", { staticClass: "flex flex-col" }, [
                                _c(
                                  "span",
                                  { staticClass: "sw-section-title" },
                                  [_vm._v(_vm._s(step.title))]
                                ),
                                _vm._v(" "),
                                !_vm.is_valid_document && step.id === "ic_front"
                                  ? _c(
                                      "span",
                                      { staticClass: "text-red-500 text-sm" },
                                      [
                                        _vm._v(
                                          "\n                  Document is not valid\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.is_valid_selfie && step.id === "selfie"
                                  ? _c(
                                      "span",
                                      { staticClass: "text-red-500 text-sm" },
                                      [
                                        _vm._v(
                                          "\n                  Selfie is not valid\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              step.showCheck
                                ? _c("BadgeCheckIcon", {
                                    staticClass: "w-7 h-7 text-green-500",
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index === _vm.stepWizard,
                                  expression: "index === stepWizard",
                                },
                              ],
                              staticClass: "tab-content-customer",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 border-l-2 border-r-2 border-b-2 border-gray-200",
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "list-disc list-inside" },
                                    _vm._l(
                                      step.subtitles,
                                      function (subtitle, subIndex) {
                                        return _c(
                                          "li",
                                          {
                                            key: subIndex,
                                            staticClass:
                                              "text-sm font-normal mb-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(subtitle) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _vm.stepWizard !== null
                                    ? _c(
                                        "sw-button",
                                        {
                                          staticClass: "w-full my-4 md:w-auto",
                                          attrs: { size: "sm", type: "button" },
                                          on: { click: _vm.takeImage },
                                        },
                                        [
                                          _c("CameraIcon", {
                                            staticClass: "w-4 h-4 mr-2",
                                          }),
                                          _vm._v(
                                            "\n                  Launch Camera\n                "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "sw-button",
                      {
                        staticClass:
                          "w-full mb-4 md:w-auto hidden md:inline-block mr-4",
                        attrs: {
                          size: "sm",
                          variant: "primary-outline",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelValidate()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c("XIcon", { staticClass: "w-4 h-4 mr-2" }),
                            _vm._v("\n            Cancel\n          "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.dataBase64IcFront !== "" &&
                    _vm.dataBase64IcBack !== "" &&
                    _vm.dataBase64Selfie !== ""
                      ? _c(
                          "sw-button",
                          {
                            staticClass: "w-full mb-4 md:w-auto",
                            attrs: {
                              size: "sm",
                              type: "button",
                              loading: _vm.loading,
                            },
                            on: { click: _vm.validateDocumentationMethod },
                          },
                          [
                            !_vm.loading
                              ? _c("CloudUploadIcon", {
                                  staticClass: "w-4 h-4 mr-2",
                                })
                              : _vm._e(),
                            _vm._v("\n          Validate\n        "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "sw-button",
                      {
                        staticClass: "w-full mb-4 md:w-auto md:hidden",
                        attrs: {
                          size: "sm",
                          variant: "primary-outline",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelValidate()
                          },
                        },
                      },
                      [
                        _c("XIcon", { staticClass: "w-4 h-4 mr-2" }),
                        _vm._v("\n          Cancel\n        "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowTakeModal
            ? _c("takePhoto", {
                attrs: {
                  typePhoto: _vm.validarIdCardStep,
                  isShowTakeModal: _vm.isShowTakeModal,
                },
                on: {
                  close: _vm.closeTakeModal,
                  saveImage: _vm.saveImageWebcam,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isVerificationSuccessful
            ? _c("div", [
                _c("div", { staticClass: "page-header text-center mb-4" }, [
                  _c("div", { staticClass: "text-xl font-bold" }, [
                    _vm._v("Verification Successful"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content flex justify-center mb-4" },
                  [
                    _c("lottie-player", {
                      staticStyle: { width: "300px", height: "300px" },
                      attrs: {
                        src: "/images/lottie/verification_successful.json",
                        background: "transparent",
                        speed: "1",
                        loop: "",
                        autoplay: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "page-footer" }, [
                  _c("div", { staticClass: "default-container p-4 mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c(
                          "sw-button",
                          {
                            staticClass: "mx-4",
                            attrs: { type: "button", loading: _vm.isLoading },
                            on: { click: _vm.goToPayment },
                          },
                          [
                            _c("CreditCardIcon", {
                              staticClass: "w-4 h-4 mr-2",
                            }),
                            _vm._v("\n              Pay\n            "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }