var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateCustomerSetting.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-input-group",
          {
            staticClass: "md:col-span- mt-2",
            attrs: {
              label: _vm.$t("settings.customization.customer_bcc_email"),
              error: _vm.emailError,
            },
          },
          [
            _c("sw-input", {
              staticClass: "input-expand",
              staticStyle: { "max-width": "30%" },
              attrs: {
                invalid: _vm.$v.email.$error,
                type: "text",
                name: "email",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.email.$touch()
                },
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "md:col-span-3",
            attrs: { label: _vm.$t("customers.customer_type_default") },
          },
          [
            _c("sw-select", {
              staticClass: "mt-2",
              staticStyle: { "max-width": "30%" },
              attrs: {
                options: _vm.types,
                searchable: true,
                "show-labels": false,
                label: "name",
              },
              on: { select: _vm.CutomerTypeSelected },
              model: {
                value: _vm.customer_type_selected,
                callback: function ($$v) {
                  _vm.customer_type_selected = $$v
                },
                expression: "customer_type_selected",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("settings.customization.customer_customer_creation"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_customer_creation_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_customer_creation_subject",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_customer_creation_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFieldsCreation },
              model: {
                value: _vm.emailCustomer.customer_customer_creation,
                callback: function ($$v) {
                  _vm.$set(_vm.emailCustomer, "customer_customer_creation", $$v)
                },
                expression: "emailCustomer.customer_customer_creation",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(
              _vm.$t("settings.customization.customer_account_registration")
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_account_registration_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_account_registration_subject",
                    $$v
                  )
                },
                expression:
                  "emailCustomer.customer_account_registration_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_account_registration,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_account_registration",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_account_registration",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("settings.customization.customer_password_reset"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_password_reset_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_password_reset_subject",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_password_reset_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_password_reset,
                callback: function ($$v) {
                  _vm.$set(_vm.emailCustomer, "customer_password_reset", $$v)
                },
                expression: "emailCustomer.customer_password_reset",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("settings.customization.customer_email_verification"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_email_verification_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_email_verification_subject",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_email_verification_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_email_verification,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_email_verification",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_email_verification",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(
              _vm.$t("settings.customization.customer_forgetting_username")
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_forgetting_username_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_forgetting_username_subject",
                    $$v
                  )
                },
                expression:
                  "emailCustomer.customer_forgetting_username_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_forgetting_username,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_forgetting_username",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_forgetting_username",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("settings.customization.customer_email_notification"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("settings.customization.customer_subject") },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.emailCustomer.customer_email_notification_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_email_notification_subject",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_email_notification_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: { label: _vm.$t("settings.customization.customer_body") },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFieldsCreation },
              model: {
                value: _vm.emailCustomer.customer_email_notification,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_email_notification",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_email_notification",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-8" }),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("settings.customization.customer_lead_notification"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-2",
            attrs: {
              label: _vm.$t("settings.customization.customer_lead_subject"),
            },
          },
          [
            _c("base-custom-input", {
              attrs: { fields: _vm.LeadMailFields },
              model: {
                value: _vm.emailCustomer.customer_lead_notification_subject,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_lead_notification_subject",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_lead_notification_subject",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mt-6 mb-4",
            attrs: {
              label: _vm.$t("settings.customization.customer_lead_body"),
            },
          },
          [
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.LeadMailFields },
              model: {
                value: _vm.emailCustomer.customer_lead_notification_body,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.emailCustomer,
                    "customer_lead_notification_body",
                    $$v
                  )
                },
                expression: "emailCustomer.customer_lead_notification_body",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4",
                attrs: {
                  loading: _vm.isLoadingCustomer,
                  disabled: _vm.isLoadingCustomer,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoadingCustomer
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updatePrefix.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-input-group",
          {
            attrs: { label: _vm.$t("settings.customization.customer_prefix") },
          },
          [
            _c("sw-input", {
              staticStyle: { "max-width": "30%" },
              model: {
                value: _vm.customers.customers_prefix,
                callback: function ($$v) {
                  _vm.$set(_vm.customers, "customers_prefix", $$v)
                },
                expression: "customers.customers_prefix",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex mt-3 mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-20px" },
                model: {
                  value: _vm.customers.customers_prefix_general,
                  callback: function ($$v) {
                    _vm.$set(_vm.customers, "customers_prefix_general", $$v)
                  },
                  expression: "customers.customers_prefix_general",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              {
                staticClass:
                  "p-0 mb-1 text-base leading-snug text-black box-title",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.items.apply_general_prefix"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            attrs: {
              label: _vm.$t("settings.customization.service_prefix"),
              error: _vm.servicePrefixError,
            },
          },
          [
            _c("sw-input", {
              staticStyle: { "max-width": "30%" },
              attrs: { invalid: _vm.$v.customers.service_prefix.$error },
              on: {
                input: function ($event) {
                  return _vm.$v.customers.service_prefix.$touch()
                },
                keyup: function ($event) {
                  return _vm.changeToUppercase()
                },
              },
              model: {
                value: _vm.customers.service_prefix,
                callback: function ($$v) {
                  _vm.$set(_vm.customers, "service_prefix", $$v)
                },
                expression: "customers.service_prefix",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex mt-3 mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-20px" },
                model: {
                  value: _vm.customers.general_service_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.customers, "general_service_prefix", $$v)
                  },
                  expression: "customers.general_service_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("settings.customization.general_service_prefix")
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateScheduling.apply(null, arguments)
          },
        },
      },
      [
        _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("settings.customization.customer.services_renewal")
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: { change: (option) => _vm.slideRenewalDate(option) },
                model: {
                  value: _vm.allow_renewal_date_job,
                  callback: function ($$v) {
                    _vm.allow_renewal_date_job = $$v
                  },
                  expression: "allow_renewal_date_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.allow_renewal_date_job"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.renewal_date_job_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mb-4",
            attrs: {
              label: _vm.$t(
                "settings.customization.customer.time_run_renewal_date_job"
              ),
              error: _vm.timeJobError,
              required: "",
            },
          },
          [
            _c("base-time-picker", {
              staticStyle: { "max-width": "30%" },
              attrs: {
                "calendar-button": true,
                placeholder: "HH:mm",
                "calendar-button-icon": "calendar",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.time_run_renewal_date_job.$touch()
                },
              },
              model: {
                value: _vm.time_run_renewal_date_job,
                callback: function ($$v) {
                  _vm.time_run_renewal_date_job = $$v
                },
                expression: "time_run_renewal_date_job",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("settings.customization.customer.save_renewal")
                    ) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("sw-divider", { staticClass: "mt-6 mb-4" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateAutoDebitScheduling.apply(null, arguments)
          },
        },
      },
      [
        _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("settings.customization.customer.customer_autodebit")
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: { change: (option) => _vm.slideAutoDebitDate(option) },
                model: {
                  value: _vm.allow_autodebit_customer_job,
                  callback: function ($$v) {
                    _vm.allow_autodebit_customer_job = $$v
                  },
                  expression: "allow_autodebit_customer_job",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.allow_autodebit_date_job"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.autodebit_date_job_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "sw-input-group",
          {
            staticClass: "mb-4",
            attrs: {
              label: _vm.$t(
                "settings.customization.customer.time_run_autodebit_date_job"
              ),
              error: _vm.timeAutoDebitJobError,
              required: "",
            },
          },
          [
            _c("base-time-picker", {
              staticStyle: { "max-width": "30%" },
              attrs: {
                invalid: _vm.$v.time_run_autodebit_customer_job.$error,
                "calendar-button": true,
                placeholder: "HH:mm",
                "calendar-button-icon": "calendar",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.time_run_autodebit_customer_job.$touch()
                },
              },
              model: {
                value: _vm.time_run_autodebit_customer_job,
                callback: function ($$v) {
                  _vm.time_run_autodebit_customer_job = $$v
                },
                expression: "time_run_autodebit_customer_job",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4 margin-buttonl",
                attrs: {
                  loading: _vm.isLoadingAutoDebit,
                  disabled: _vm.isLoadingAutoDebit,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoadingAutoDebit
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("settings.customization.customer.auto_debit")
                    ) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updatePaymentCustomerOption.apply(null, arguments)
          },
        },
      },
      [
        _c("h3", { staticClass: "mb-5 text-lg font-medium text-black" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(
                  "settings.customization.customer.customer_payment_option"
                )
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: { change: (option) => _vm.slideEnableMakePayment(option) },
                model: {
                  value: _vm.enable_make_customer,
                  callback: function ($$v) {
                    _vm.enable_make_customer = $$v
                  },
                  expression: "enable_make_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_make_payment"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_make_payment_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableAddCreditPayment(option),
                },
                model: {
                  value: _vm.enable_credit_customer,
                  callback: function ($$v) {
                    _vm.enable_credit_customer = $$v
                  },
                  expression: "enable_credit_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_addcredit_payment"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_addcredit_payment_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableInvoiceCustomer(option),
                },
                model: {
                  value: _vm.enable_invoice_customer,
                  callback: function ($$v) {
                    _vm.enable_invoice_customer = $$v
                  },
                  expression: "enable_invoice_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_invoice_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_invoice_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableQuotesCustomer(option),
                },
                model: {
                  value: _vm.enable_quotes_customer,
                  callback: function ($$v) {
                    _vm.enable_quotes_customer = $$v
                  },
                  expression: "enable_quotes_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_quotes_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_quotes_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnablePaymentCustomer(option),
                },
                model: {
                  value: _vm.enable_payment_customer,
                  callback: function ($$v) {
                    _vm.enable_payment_customer = $$v
                  },
                  expression: "enable_payment_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_payment_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_payment_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableReportCustomer(option),
                },
                model: {
                  value: _vm.enable_report_customer,
                  callback: function ($$v) {
                    _vm.enable_report_customer = $$v
                  },
                  expression: "enable_report_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_report_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_report_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableServiceCustomer(option),
                },
                model: {
                  value: _vm.enable_service_customer,
                  callback: function ($$v) {
                    _vm.enable_service_customer = $$v
                  },
                  expression: "enable_service_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_service_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_service_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnableTicketCustomer(option),
                },
                model: {
                  value: _vm.enable_tickets_customer,
                  callback: function ($$v) {
                    _vm.enable_tickets_customer = $$v
                  },
                  expression: "enable_tickets_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_tickets_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_tickets_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) =>
                    _vm.slideEnablePaymentAccountCustomer(option),
                },
                model: {
                  value: _vm.enable_paymentaccount_customer,
                  callback: function ($$v) {
                    _vm.enable_paymentaccount_customer = $$v
                  },
                  expression: "enable_paymentaccount_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_paymentaccount_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_paymentaccount_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mb-4" }, [
          _c(
            "div",
            { staticClass: "relative w-12" },
            [
              _c("sw-switch", {
                staticClass: "absolute",
                staticStyle: { top: "-18px" },
                on: {
                  change: (option) => _vm.slideEnablePbxServiceCustomer(option),
                },
                model: {
                  value: _vm.enable_pbxservice_customer,
                  callback: function ($$v) {
                    _vm.enable_pbxservice_customer = $$v
                  },
                  expression: "enable_pbxservice_customer",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-4" }, [
            _c(
              "p",
              { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_pbxservice_customer"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "max-w-lg p-0 m-0 text-xs leading-tight text-gray-500",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "settings.customization.customer.enable_pbxservice_customer_desc"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.permission.update
          ? _c(
              "sw-button",
              {
                staticClass: "mt-4 margin-buttonl",
                attrs: {
                  loading: _vm.isLoadingAutoDebit,
                  disabled: _vm.isLoadingAutoDebit,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoadingAutoDebit
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }